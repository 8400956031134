import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import tm from "components/TranslationManager";
import { getVisitorStatusColor } from './Utils';

const GridCellVisitorStatus = ({ rowData }) => {
  // const [age, setAge] = useState(rowData.visitorStatus || ''); // Assuming 'age' is a property in rowData

  const [isReadOnly, setIsReadOnly] = useState(true); // Control read-only state

  const handleChange = (event) => {
    // setAge(event.target.value);
  };

  const selectStyles = getVisitorStatusColor(rowData.visitorStatus);



  return (
    <FormControl sx={{ width: '100%', margin: '1px', height: '38px' }}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        readOnly= {isReadOnly}
        IconComponent={isReadOnly ? () => null : undefined} // Conditionally remove the icon
        value={rowData.visitorStatus}
        onChange={handleChange}
        style={selectStyles}
        sx={{ width: '100%', height: '100%', padding: '8px' }} // Adjust height here
      >

          {rowData.visitStatus === "pre_registered" && (
              <MenuItem value={"checked_in"} >{tm.translate("lblQuickStart", "Quick-Start")}</MenuItem>
          )}
        <MenuItem value={"checked_in"} >{tm.translate("lblCheckedIn", "Checked-in")}</MenuItem>
        <MenuItem value={"checked_out"} >{tm.translate("lblCheckedOut", "Checked-out")}</MenuItem>
        <MenuItem value={"pre_registered"}>{tm.translate("lblPreRegistered", "Invited")}</MenuItem>
        <MenuItem value={"pre_checked_in"}>{tm.translate("lblPreCheckedIn", "Waiting")}</MenuItem>
        <MenuItem value={"confirmed"}>{tm.translate("lblConfirmed", "Confirmed")}</MenuItem>
        <MenuItem value={"rejected"}>{tm.translate("lblRejected", "Rejected")}</MenuItem>
        <MenuItem value={"under_review"}>{tm.translate("lblUnderReview", "Under Review")}</MenuItem>
        <MenuItem value={"denied"}>{tm.translate("lblDenied", "Denied")}</MenuItem>

        
      </Select>
    </FormControl>
  );
};

export default GridCellVisitorStatus;
