import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import DialogYesNo from "components/dialog/DialogYesNo";
import {  useNavigate } from "react-router-dom";


const AdminConfiguration = () => {
  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const theme = useTheme();

  const handleExecute = async (values) => {



      

  };

  const buttons = [
    // { label: "Button 1" },
    { label: "Start Sync" , onClick: () => setConfirmationOpen(true) , iconName: 'sync'},

  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Admins" subtitle="List of admins" buttons={buttons} />
      <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleExecute}
        theme={theme}
      />
      {/* </Box> */}
      Coming soon
    </Box>
  );
};

export default AdminConfiguration;