import { Typography, Box, useTheme, Button, Autocomplete, TextField } from "@mui/material";
import React from "react";
import Icon from '@mui/material/Icon';
import { ArrowCircleLeftOutlined, Search } from "@mui/icons-material";
import { CssTextField, ReadOnlyLabel } from "./StyledComponents";
import { useNavigate } from "react-router-dom";

const Header = ({ title, subtitle, elements, backbutton }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          {backbutton && (
            <Button
              selenium_id={"btnBack"}
              onClick={() => {
                if (typeof backbutton !== "boolean") {
                  navigate(backbutton);
                } else {
                  window.history.back();
                }
              }}
              sx={{
                color: theme.palette.secondary[300],
                marginLeft: '-20px',
                padding: 0,
              }}
            >
              <ArrowCircleLeftOutlined sx={{ color: theme.palette.secondary[300], fontSize: '30px' }} />
            </Button>
          )}
          <Typography
            variant="h2"
            component="h2"
            color={theme.palette.secondary[100]}
            fontWeight="bold"
          >
            {title}
          </Typography>
        </Box>
        <Typography variant="h5" component="div" color={theme.palette.secondary[300]}>
          {subtitle}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        {elements &&
          elements.map((element, index) => (
            // Check the type of element
            element.type === 'search' ? (
              <CssTextField
                key={index}
                label={element.label}
                selenium_id={element.searchElementId ? element.searchElementId : undefined} // Custom attribute for Selenium
                onChange={element.onChange}
                sx={{ mr: 2 }}
                InputProps={{
                  endAdornment: (
                    <Search />
                  ),
                }}
                theme={theme}
              />
            ) : element.type === 'readonly' ? (
              <ReadOnlyLabel key={index}>
                Read Only
              </ReadOnlyLabel>
            ) : element.type === 'button' ? (
              <Button
                key={index}
                variant="contained"
                selenium_id={element.btnId ? element.btnId : undefined} // Custom attribute for Selenium
                onClick={element.onClick}
                sx={{
                  backgroundColor: theme.palette.secondary.light,
                  color: theme.palette.background.alt,
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "12px 20px",
                  m: "0rem 0.5rem 0rem 0rem",
                  "&:hover": { color: theme.palette.secondary.dark },
                }}
              >
                {element.iconName && <Icon>{element.iconName}</Icon>}
                {element.label}
              </Button>
            ) : element.type === 'combo' ? (
              <Autocomplete
                key={index}
                disablePortal
                options={[
                  { label: 'All Visitor Types', year: 1994 },
                  { label: 'The Godfather', year: 1972 },
                  { label: 'The Godfather: Part II', year: 1974 },
                  { label: 'The Dark Knight', year: 2008 }
                ]}
                sx={{
                  width: 200,
                  padding: "5px 5px",
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '32px',
                  },
                  '& .MuiInputBase-root': {
                    position: 'relative',
                  },
                  '& .MuiInputBase-input': {
                    paddingRight: '2.5rem',
                  },
                  '& .MuiInputAdornment-root': {
                    position: 'absolute',
                    right: '0.5rem',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  },
                  "& label.Mui-focused": {
                    color: theme.palette.secondary[200],
                  },
                  "&:hover label": {
                    color: theme.palette.secondary[300]
                  },
                  "& .MuiFormLabel-root.MuiInputLabel-root": {
                    color: theme.palette.secondary[300]
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={element.label}
                    sx={{
                      width: 200,
                      padding: "5px 5px",
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '32px',
                      },
                      '& .MuiInputBase-root': {
                        position: 'relative',
                      },
                      '& .MuiInputBase-input': {
                        paddingRight: '2.5rem',
                      },
                      '& .MuiInputAdornment-root': {
                        position: 'absolute',
                        right: '0.5rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      },
                      "& label.Mui-focused": {
                        color: theme.palette.secondary[200],
                      },
                      "&:hover label": {
                        color: theme.palette.secondary[300]
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root": {
                        color: theme.palette.secondary[300]
                      },
                    }}
                  />
                )}
              />
            ) : null // Handle other cases
          ))}
      </Box>
    </Box>
  );
};

export default Header;
