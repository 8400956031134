import React, { useEffect, useState } from 'react';
import { Box, Typography,  FormControlLabel, useTheme,  Divider, IconButton, ListItemIcon, Button, Menu } from '@mui/material';
import IOSSwitch from 'components/IOSSwitch';
import { hideLoading, showLoading } from 'state/loadingSlicer';
import { useDispatch } from 'react-redux';
import {  changeWorkflowElementsVisitorDataOrder, deleteWorkflowElementVisitorData, getWorkflowEngineAllUserDefinedFields, getWorkflowEngineType, updateorkflowElementVisitorDataHidePreReg, updateorkflowElementVisitorDataRequired, updateWorkflowElementVisitorData } from 'hooks/HttpRequests';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ArrowDownward, Clear, DragHandle, KeyboardArrowDown } from '@mui/icons-material';
import DialogYesNo from 'components/dialog/DialogYesNo';
import { useFrontDeskManager } from 'components/FrontDeskManager';
import MenuItemsAddWorkflowElementVisitorData from 'components/MenuItemsAddWorkflowElementVisitorData';
import DialogEditCustomField from 'components/dialog/DialogEditCustomField';


const VisitorDataConfig = ({ visitorType,frontDeskEntityId,initialValues, onSave, visitorTypeEntity }) => {

    const theme = useTheme();
    const {palette} = useTheme();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loadingComplete, setLoadingComplete] = useState(false);
    const [loadedFormData, setLoadedFormData] = useState(null);
    const [loadedAllUserdefinedFieldsData, setLoadedAllUserdefinedFieldsData] = useState(null);

    const [selectedStep, setSelectedStep] = useState(null); // Track selected step

    const [editCustomFieldOpen, seteEditCustomFieldOpen] = useState(false);
    const [editCustomFieldEntity, setEditCustomFieldEntity] = useState(null)


    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [yesNoMessage, setYesNoMessage] = useState("");
    const [yesNoDialogEntity, setYesNoDialogEntity] = useState({})

    const { selectedFrontDesk, frontDeskLoading } = useFrontDeskManager();

    const [triggerRefresh, setTriggerRefresh] = useState(false);

    useEffect(() => {
        loadData(visitorType);
        loadDataAvailableUserdefinedFields();
    }, []);

    useEffect(() => {
        loadData(visitorType);
        loadDataAvailableUserdefinedFields();
    }, [triggerRefresh]);

    const commonStyles = {
        bgcolor: theme.palette.primary[600],
        m: "1rem 0rem 1rem 0rem",
        borderRadius: 1,
        borderColor: theme.palette.secondary[100],
      };

      ///////////////////////////////////////////////
    //  Add Element
    ///////////////////////////////////////////////

    const [anchorElVisitStatus, setAnchorElVisitStatus] = useState(null);
    const openMenuVisitStatus = Boolean(anchorElVisitStatus);
    // const handleClick = (event: MouseEvent<HTMLElement>) => {
    // //   setAnchorEl(event.currentTarget);
    // };
    const handleClick = (event) => {
      setAnchorElVisitStatus(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorElVisitStatus(null);
    };


    const loadData = async (visitorType) => {

        dispatch(showLoading());

        getWorkflowEngineType(navigate)(frontDeskEntityId, initialValues.entityId)
        .then((result) => {

            // Use the result.data as needed
            if(result.success){
                var tmp = JSON.parse(result.data.configuration)
                var additionalField = tmp.additionalTerminalFields;
                const updatedAdditionalField = additionalField.map(field => {
                    if (!field.hasOwnProperty('hidePreReg')) {
                    return { ...field, hidePreReg: false };
                    }
                    return field;
                });
                setLoadedFormData(updatedAdditionalField)

            }
        setLoadingComplete(true)
            dispatch(hideLoading());


        })
        .catch((error) => {

                setLoadingComplete(true)
            dispatch(hideLoading());

        });

    }

    const loadDataAvailableUserdefinedFields = async () => {

        dispatch(showLoading());

        getWorkflowEngineAllUserDefinedFields(navigate)(frontDeskEntityId)
        .then((result) => {

            // Use the result.data as needed
            if(result.success){
                setLoadedAllUserdefinedFieldsData(result.data)

            }
        setLoadingComplete(true)
            dispatch(hideLoading());


        })
        .catch((error) => {

                setLoadingComplete(true)
            dispatch(hideLoading());

        });

    }

    function handleDragEnd(result, test) {

        if (!result.destination) return;
        
        // Offset for fixed elements at the beginning of the array
        const offset = 0; // Adjust this based on the number of fixed elements

        // Calculate the actual source and destination indices in loadedFormData
        const sourceIndex = result.source.index + offset;
        const destinationIndex = result.destination.index + offset;

        const draggedItem = loadedFormData[result.source.index+ offset];
        const dropTargetItem = loadedFormData[result.destination.index+ offset];

        const dropPosition = result.destination.index > result.source.index ? 'after' : 'before';


        // Reorder items in the state based on the adjusted indices
        const items = Array.from(loadedFormData);
        const [reorderedItem] = items.splice(sourceIndex, 1); // Remove the item
        items.splice(destinationIndex, 0, reorderedItem); // Insert the item

        // Update the state with the new order
        setLoadedFormData(items);

        // Extract required data for the backend
        const draggedVisitorType = draggedItem.fieldId;
        const dropTargetVisitorType = dropTargetItem.fieldId

        changeWorkflowElementsVisitorDataOrder(navigate)(selectedFrontDesk.entityId , draggedVisitorType, dropPosition , dropTargetVisitorType, visitorTypeEntity.visitorType)
            .then((result) => {

                // Use the result.data as needed
                if(result.success){

                }
                
                // setGridLoading(false)

            })
            .catch((error) => {

                console.log(error);

                // setGridLoading(false)
            });
    
      }

      const handleElementClick = (step) => {
        console.log("Element clicked:", step);
        // Perform desired action, e.g., navigate to another page or open a modal
        setSelectedStep(step); // Update the selected step

    };

    const handleSwitchChange = (values) => {
        // const updatedData = loadedFormData.map((item) =>
        //     item.apiKey === step.apiKey
        //         ? { ...item, required: !item.required }
        //         : item
        // );
        // setLoadedFormData(updatedData);

        dispatch(showLoading(""))

        var submitJson = {}
        submitJson["saveToAll"] = false 
        submitJson["visitorType"] = visitorTypeEntity

        values["entityId"] = initialValues.entityId

        submitJson["data"] = values 

        

        updateorkflowElementVisitorDataRequired(navigate)(selectedFrontDesk.entityId, values.fieldId, !values.required,submitJson)
            .then((result) => {
            // Use the result.data as needed      
            dispatch(hideLoading());
                if(result.success){
                    
                enqueueSnackbar({
                    message: "Was updated successfully",
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                    
                });

                setTriggerRefresh(!triggerRefresh);

                }else{



                enqueueSnackbar({
                    message: "Something went wrong during request",
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                    
                });
                }


        })
        .catch((error) => {
            dispatch(hideLoading());

            if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
                navigate("/");
            }else if(error.action == "force_delete"){
                console.log("force delete")
                setConfirmationOpen(true);
                setYesNoMessage("This entity has still referecnes to visits. Do you want to force the delete?")
                yesNoDialogEntity["action"] = "force_delete";
                setYesNoDialogEntity(yesNoDialogEntity)
            }
            }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
                message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
                
            });
            }

        });

    };
    
    const handleSwitchChangeHidePreReg = (values) => {
        // const updatedData = loadedFormData.map((item) =>
        //     item.apiKey === step.apiKey
        //         ? { ...item, required: !item.required }
        //         : item
        // );
        // setLoadedFormData(updatedData);

        dispatch(showLoading(""))

        var submitJson = {}
        submitJson["saveToAll"] = false 
        submitJson["visitorType"] = visitorTypeEntity

        values["entityId"] = initialValues.entityId

        submitJson["data"] = values 

        

        updateorkflowElementVisitorDataHidePreReg(navigate)(selectedFrontDesk.entityId, values.fieldId, !values.hidePreReg, submitJson)
            .then((result) => {
            // Use the result.data as needed      
            dispatch(hideLoading());
                if(result.success){
                    
                enqueueSnackbar({
                    message: "Was updated successfully",
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                    
                });

                setTriggerRefresh(!triggerRefresh);

                }else{



                enqueueSnackbar({
                    message: "Something went wrong during request",
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                    
                });
                }


        })
        .catch((error) => {
            dispatch(hideLoading());

            if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
                navigate("/");
            }else if(error.action == "force_delete"){
                console.log("force delete")
                setConfirmationOpen(true);
                setYesNoMessage("This entity has still referecnes to visits. Do you want to force the delete?")
                yesNoDialogEntity["action"] = "force_delete";
                setYesNoDialogEntity(yesNoDialogEntity)
            }
            }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
                message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
                
            });
            }

        });

    };

    const handleDeleteClick = (element) => {

        // Handle delete action
        setConfirmationOpen(true);
        setYesNoMessage("Do you really want to delete this entity?")
        setYesNoDialogEntity(element)

            
      }

    const handleExecuteDelete = async (values) => {

        setConfirmationOpen(false)

        dispatch(showLoading(""))

        var submitJson = {}
        submitJson["saveToAll"] = false 
        submitJson["visitorType"] = visitorTypeEntity

        values["entityId"] = initialValues.entityId

        submitJson["data"] = values 

        

        deleteWorkflowElementVisitorData(navigate)(selectedFrontDesk.entityId, values.fieldId, submitJson)
            .then((result) => {
            // Use the result.data as needed      
            dispatch(hideLoading());
                if(result.success){
                    
                enqueueSnackbar({
                    message: "Was deleted successfully",
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                    
                });

                setTriggerRefresh(!triggerRefresh);

                }else{



                enqueueSnackbar({
                    message: "Something went wrong during request",
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                    
                });
                }


        })
        .catch((error) => {
            dispatch(hideLoading());

            if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
                navigate("/");
            }else if(error.action == "force_delete"){
                console.log("force delete")
                setConfirmationOpen(true);
                setYesNoMessage("This entity has still referecnes to visits. Do you want to force the delete?")
                yesNoDialogEntity["action"] = "force_delete";
                setYesNoDialogEntity(yesNoDialogEntity)
            }
            }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
                message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
                
            });
            }

        });

        
    }
    
    const handleAddVisitorDataElement = async (values) => {

        setAnchorElVisitStatus(null);

        console.log(values)


        // dispatch(showLoading(""))

        var submitJson = {}
        submitJson["saveToAll"] = false 
        submitJson["visitorType"] = visitorTypeEntity

        var data = {};

        data["entityId"] = initialValues.entityId


        var selections = loadedFormData.map((element) => ({
            fieldId: element.fieldId,
            required: element.required, // Assuming `required` is a property
          }));

          selections.push({
            fieldId: values.fieldId,
            required: false, // Assuming `required` is a property
          })

        data["selections"] = selections;

        submitJson["data"] = data 

        

        updateWorkflowElementVisitorData(navigate)(selectedFrontDesk.entityId, submitJson)
            .then((result) => {
            // Use the result.data as needed      
            dispatch(hideLoading());
                if(result.success){
                    
                enqueueSnackbar({
                    message: "Was updated successfully",
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                    
                });

                setTriggerRefresh(!triggerRefresh);

                }else{



                enqueueSnackbar({
                    message: "Something went wrong during request",
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                    
                });
                }


        })
        .catch((error) => {
            dispatch(hideLoading());

            if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
                navigate("/");
            }else if(error.action == "force_delete"){
                console.log("force delete")
                setConfirmationOpen(true);
                setYesNoMessage("This entity has still referecnes to visits. Do you want to force the delete?")
                yesNoDialogEntity["action"] = "force_delete";
                setYesNoDialogEntity(yesNoDialogEntity)
            }
            }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
                message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
                
            });
            }

        });

        
    }

    useEffect(() => {
        if (editCustomFieldEntity) {
          seteEditCustomFieldOpen(true);
        }
      }, [editCustomFieldEntity]);

    if (!loadingComplete || !loadedFormData || !selectedFrontDesk || !loadedAllUserdefinedFieldsData) {
        return <div>Loading...</div>;
    }

    return (
        <Box sx={{ ...commonStyles,  border: 1 , p : 5}}>
            <DialogYesNo
                open={confirmationOpen}
                onClose={() => setConfirmationOpen(false)}
                onConfirm={handleExecuteDelete}
                theme={theme}
                message={yesNoMessage}
                entity={yesNoDialogEntity}
            />
            <DialogEditCustomField
                open={editCustomFieldOpen}
                onClose={() => {
                    seteEditCustomFieldOpen(false);
                    setEditCustomFieldEntity(null); // Clear entity after closing
                  }}
                  onSave={(entity) => {   
                    seteEditCustomFieldOpen(false);
                    setEditCustomFieldEntity(null); // Clear entity after closing
                  }}
                theme={theme}
                currentEntity ={initialValues}
                customFieldEntity={editCustomFieldEntity}
            />
             {/* MENU SECTION */}
             <Box>
                    <Button
                        id="demo-customized-button"
                        aria-controls={openMenuVisitStatus ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenuVisitStatus ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDown />}
                        sx={{
                            p: "1rem",
                            backgroundColor: palette.secondary.light,
                            color: palette.background.alt,
                            fontWeight: "bold",
                            "&:hover": {
                                color: palette.primary.main,
                                backgroundColor: palette.secondary.light,
                                fontWeight: "bold",
                            },
                        }}
                    >
                        Add Element
                    </Button>
                    <Menu
                        id="demo-customized-menu-bla"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorElVisitStatus}
                        open={openMenuVisitStatus}
                        onClose={handleClose}
                        sx={{
                            '& .MuiMenu-list': {
                                padding: '4px 0',
                                backgroundColor: palette.secondary.light,
                            },
                            '& .MuiDivider-root': {
                                backgroundColor: palette.primary.main,
                                color: palette.primary.main,
                            },
                            '& .MuiMenuItem-root': {
                                backgroundColor: palette.secondary.light,
                                color: palette.primary.main,
                                '& .MuiSvgIcon-root': {
                                    fontSize: 18,
                                    color: palette.primary.main,
                                    backgroundColor: palette.secondary.light,
                                    marginRight: 2,
                                },
                            },
                        }}
                    >
                        <MenuItemsAddWorkflowElementVisitorData
                            allAvailableFields = {loadedAllUserdefinedFieldsData}
                            usedFields = {loadedFormData}
                            handleAddVisitorDataElement={handleAddVisitorDataElement}
                        />
                    </Menu>
                </Box>
            {/* FIRSTNAME SECTION */}
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ ...commonStyles }}
            >
                {/* Main Content */}
                <Box sx={{ flexGrow: 1, border: 1 }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem" }}>
                    <Box
                        display="flex"
                        flexDirection="row" // Render elements horizontally
                        alignItems="center"
                    >
                        <Typography
                        variant="h5"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        >
                        First Name
                        </Typography>
                    
                    </Box>
                    </Box>
                    <Box
                    display="flex"
                    flexDirection="row" // Render elements horizontally
                    alignItems="center"
                    >
                    </Box>
                </Box>
                </Box>
            
                {/* ListItemIcon (Drag Handle) */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexShrink: 0,
                        justifyContent: "flex-end",
                        width: "auto",
                        minWidth: "24px", // Match size of dynamic ListItemIcon
                    }}
                    />
            </Box>
            {/* LASTNAME SECTION */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                    width: '100%', // Full width
                    my: 1, // Add vertical margin
                }}
            >
                <ArrowDownward sx={{ fontSize: 20, color: theme.palette.secondary[300] }} />
            </Box>
             {/* --------------------------- */}
            {/* LASTNAME SECTION */}
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ ...commonStyles }}
            >
                {/* Main Content */}
                <Box sx={{ flexGrow: 1, border: 1 }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem" }}>
                    <Box
                        display="flex"
                        flexDirection="row" // Render elements horizontally
                        alignItems="center"
                    >
                        <Typography
                        variant="h5"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        >
                        Last Name
                        </Typography>
                    
                    </Box>
                    </Box>
                    <Box
                    display="flex"
                    flexDirection="row" // Render elements horizontally
                    alignItems="center"
                    >
                    </Box>
                </Box>
                </Box>
            
                {/* ListItemIcon (Drag Handle) */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexShrink: 0,
                        justifyContent: "flex-end",
                        width: "auto",
                        minWidth: "24px", // Match size of dynamic ListItemIcon
                    }}
                    />
            </Box>
             {/* --------------------------- */}
             {loadedFormData && loadedFormData.length > 0 && (
                    <>
                        <Divider sx={{ my: 0.5 }} />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center', // Center horizontally
                                alignItems: 'center', // Center vertically
                                width: '100%', // Full width
                                my: 1, // Add vertical margin
                            }}
                        >
                            <ArrowDownward sx={{ fontSize: 20, color: theme.palette.secondary[300] }} />
                        </Box>
                    </>
                )}


                {/* -------------------------- */}
                {/* START DYNAMIC DRAG DROP*/}
                <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="visitorDataSteps">
                    {(provided) => (
                        <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column', // Stack elements vertically
                            }}
                        >
                    {loadedFormData
                        .map((step, index) => (
                            <Box>
                                <Draggable
                                    key={`${step.apiKey}-${index}`}
                                    draggableId={`${step.apiKey}-${index}`}                        
                                    index={index}
                                >
                                        {(provided) => (
                                                
                                                <Box
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                  display="flex"
                                                  flexDirection="row"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                  
                                                  sx={{ ...commonStyles, cursor: "pointer", }}
                                                >
                                                  {/* Main Content */}
                                                  <Box sx={{ flexGrow: 1, border: 1 }}>
                                                    <Box
                                                      display="flex"
                                                      justifyContent="space-between"
                                                      alignItems="center"
                                                    >
                                                      <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem" }}>
                                                        <Box
                                                          display="flex"
                                                          flexDirection="row" // Render elements horizontally
                                                          alignItems="center"
                                                        >
                                                          <Typography
                                                            variant="h4"
                                                            color={theme.palette.secondary[100]}
                                                            fontWeight="bold"
                                                          >
                                                            {step.fieldName}
                                                          </Typography>
                                                        
                                                        </Box>
                                              
                                                        <Typography variant="h5" color={theme.palette.secondary[300]}>
                                                          {step.description}
                                                        </Typography>
                                                        <FormControlLabel
                                                          control={
                                                            <IOSSwitch
                                                                fullWidth
                                                                sx={{ m: 1 }}
                                                                checked={step.required}
                                                                onChange={() => handleSwitchChange(step)} // Toggle the switch
                                                            />
                                                          }
                                                          label={
                                                            <span style={{ color: theme.palette.secondary.light }}>
                                                              required
                                                            </span>
                                                          } // Change label color to theme's secondary light color
                                                        />
                                                        <FormControlLabel
                                                          control={
                                                            <IOSSwitch
                                                                fullWidth
                                                                sx={{ m: 1 }}
                                                                checked={step.hidePreReg}
                                                                onChange={() => handleSwitchChangeHidePreReg(step)} // Toggle the switch
                                                            />
                                                          }
                                                          label={
                                                            <span style={{ color: theme.palette.secondary.light }}>
                                                              Hide From Invite
                                                            </span>
                                                          } // Change label color to theme's secondary light color
                                                        />
                                                      </Box>
                                                      <Box
                                                        display="flex"
                                                        flexDirection="row" // Render elements horizontally
                                                        alignItems="center"
                                                      >
                                                        {["visitor_c_1", "visitor_c_2", "visitor_c_3", "visitor_c_4", "visitor_c_5"].includes(step.apiKey) && (
                                                        <Button
                                                          variant="contained"
                                                          onClick={() => {

                                                            setEditCustomFieldEntity(step);

                                                          }}
                                                          sx={{
                                                            backgroundColor: theme.palette.secondary.light,
                                                            color: theme.palette.background.alt,
                                                            fontSize: "14px",
                                                            fontWeight: "bold",
                                                            padding: "10px 20px",
                                                            m: "0rem 1rem 0rem 0rem",
                                                            "&:hover": { color: theme.palette.secondary.alt },
                                                          }}
                                                        >
                                                          {"EDIT"}
                                                        </Button>
                                                        )}
                                                        <IconButton size="small" onClick={() => handleDeleteClick(step)}>
                                                            <Clear />
                                                        </IconButton>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                              
                                                  {/* ListItemIcon (Drag Handle) */}
                                                  <Box
                                                        sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        flexShrink: 0, // Prevent shrinking
                                                        justifyContent: "flex-end", // Align content to the right
                                                        width: "auto", // Ensure it does not take excessive space
                                                        }}
                                                    >
                                                        <ListItemIcon
                                                        sx={{
                                                            color: theme.palette.secondary[300],
                                                            minWidth: 0, // Remove extra padding inside ListItemIcon
                                                            padding: "0px 0px 0px 10px", // Ensure compact size
                                                        }}
                                                        >
                                                        <DragHandle />
                                                        </ListItemIcon>
                                                    </Box>
                                                </Box>
                                        )}
                                    </Draggable>
                                    {/* Conditionally render the ArrowDownward only if it's not the last item */}
                                        {index < loadedFormData.length - 1 && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center', // Center horizontally
                                                    alignItems: 'center', // Center vertically
                                                    width: '100%', // Full width
                                                    my: 1, // Add vertical margin
                                                }}
                                            >
                                                <ArrowDownward sx={{ fontSize: 20, color: theme.palette.secondary[300] }} />
                                            </Box>
                                        )}
                                    </Box>
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>

                {/* END DYNAMIC DRAG DROP*/}
                {/* -------------------------- */}
        </Box>
    );
};

export default VisitorDataConfig;
