import React, { useEffect, useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Diversity3,
  EmojiPeople,
  DirectionsRun,
  Group,
  FileDownload,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Avatar,
  List,
  ListItemText,
  ListItem,
  ListItemButton,
} from "@mui/material";
import BreakdownChart from "components/BreakdownChart";
import OverviewChart from "components/OverviewChart";
import StatBox from "components/StatBox";
import { getAnalyticsVisitor, getFrontDeskTop10Companies, getFrontDeskTop10Hosts, getFrontDeskTop10Visitors, getListValuesPaging, getSessionToken, getUri, getVisitorDashboardPDF, putVisualCompliance } from "hooks/HttpRequests";
import { CssDateRangePicker, stringAvatar } from "components/StyledComponents";
import TreeMapChart from "components/charts/TreeMapChart";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import dayjs from 'dayjs';
import { enqueueSnackbar } from "notistack";

const GlobalVisitorsAnalytics = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const { selectedFrontDesk, setSelectedFrontDeskEntityId } = useFrontDeskManager();
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const location = useLocation(); // To get the current location and query parameters

  const [validFromDayJS, setValidFromDayJS] = useState(dayjs().startOf("month").set("hour", 9).startOf("hour"));
  const [validToDayJS, setValidToDayJS] = useState(dayjs().endOf("month").set("hour", 12).startOf("hour"));

  const [totalInvites, setTotalInvites] = useState(0);
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [totalCanceled, setTotalCanceled ] = useState(0);
  const [totalRejected, setTotalRejected] = useState(0);

  /////////////////////////////////////////
  //  TOP 10 Visitors
  /////////////////////////////////////////

  const [top10VisitorsData, setTop10VisitorsData] = useState([]);
  const [top10VisitorsLoading, setTop10VisitorsLoading] = useState(false);

  useEffect(() => {
   
    const queryParams = new URLSearchParams(location.search);
    const validFrom = queryParams.get("valid_from");
    const validTo = queryParams.get("valid_to");
    const frontDesk = queryParams.get("front_desk");

    if(validFrom != null && validTo != null ){

      const parsedDatFrom = dayjs(validFrom);
      setValidFromDayJS(parsedDatFrom)
      const parsedDatTo = dayjs(validTo);
      setValidToDayJS(parsedDatTo)
      if(selectedFrontDesk.entityId != frontDesk){
        setSelectedFrontDeskEntityId(frontDesk)
      }

    }else{
      loadData()
    }
    // setSelectedFrontDeskEntityId


  }, [selectedFrontDesk, triggerRefresh]);

  const loadData = async () => {

      loadTop10VisitorsData()
      loadTop10HostsData()
      loadTop10CompaniesData()
      loadDashboardVisitorsStatistics();

  }

  const loadTop10VisitorsData = async () => {

    setTop10VisitorsLoading(true);

    try {
      const result = await getFrontDeskTop10Visitors(navigate)(selectedFrontDesk.entityId, validFromDayJS.valueOf(), validToDayJS.valueOf());
    
      var withData = {}
      withData["data"] = data;

      setTop10VisitorsData(result);
      setTop10VisitorsLoading(false);

    } catch (error) {
      if (error?.action === 'relogin') {
        navigate('/');
      }
      console.error(error);
    } finally {
      setTop10VisitorsLoading(false);
    }
  };

  /////////////////////////////////////////
  //  TOP 10 Hosts
  /////////////////////////////////////////

  const [top10HostsData, setTop10HostsData] = useState([]);
  const [top10HostsLoading, setTop10HostsLoading] = useState(false);

  const loadTop10HostsData = async () => {

    setTop10HostsLoading(true);

    try {
      const result = await getFrontDeskTop10Hosts(navigate)(selectedFrontDesk.entityId, validFromDayJS.valueOf(), validToDayJS.valueOf());
    
      var withData = {}
      withData["data"] = data;

      setTop10HostsData(result);
      setTop10HostsLoading(false);

    } catch (error) {

      
    } finally {
      setTop10HostsLoading(false);
    }
  };

  /////////////////////////////////////////
  //  TOP 10 Companies
  /////////////////////////////////////////

  const [top10CompaniesData, setTop10CompaniesData] = useState([]);
  const [top10CompaniesLoading, setTop10CompaniesLoading] = useState(false);

  const loadTop10CompaniesData = async () => {

    setTop10CompaniesLoading(true);

    try {
      const result = await getFrontDeskTop10Companies(navigate)(selectedFrontDesk.entityId, validFromDayJS.valueOf(), validToDayJS.valueOf());
    
      var withData = {}
      withData["data"] = data;

      setTop10CompaniesData(result);
      setTop10CompaniesLoading(false);

    } catch (error) {
      
    } finally {
      setTop10CompaniesLoading(false);
    }
  };

  const loadDashboardVisitorsStatistics = async () => {
  
  
      getAnalyticsVisitor(navigate)(selectedFrontDesk.entityId, validFromDayJS.valueOf(), validToDayJS.valueOf())
        .then((result) => {
  
          // setData(result)
  
          if(result.success){
            setTotalInvites(result.data.invited)
            setTotalVisitors(result.data.checked_in)
            setTotalCanceled(result.data.canceled)
            setTotalRejected(result.data.rejected)
          }else{
            setTotalInvites(0)
            setTotalVisitors(0)
            setTotalCanceled(0)
            setTotalRejected(0)
          }
          
  
        })
        .catch((error) => {
          
        });
  
    }
  

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [],
    query:[]
  })


  useEffect(() => {
    // This code will run every time paginationModel changes
    loadGridDataMyVisits();

  }, [paginationModel]);

  const loadGridDataMyVisits = async () => {

    console.log("loadGridData()")
    setGridLoading(true)

    var extraFilter = "";
    // if(topSearchFilter.length > 0){
    //   extraFilter = "&extraFilter="+topSearchFilter
    // }

    getListValuesPaging(navigate)(paginationModel, "host", extraFilter)
      .then((result) => {

        setData(result)
        setGridLoading(false)

      })
      .catch((error) => {

        setGridLoading(false)
        
      });

  }

  const handleValidTimeRangeDateChange = (item) => {

    
    setValidFromDayJS(item[0])
    setValidToDayJS(item[1])
    setTriggerRefresh((prev) => !prev);
  }

  const handleFormSubmit = (values) => {
  
      var submitData = {};
      submitData = values;
  
      dispatch(showLoading(""))
  
      getVisitorDashboardPDF(navigate)(selectedFrontDesk.entityId,validFromDayJS.valueOf(), validToDayJS.valueOf())
        .then((result) => {
          if (result.success) {
            enqueueSnackbar({
              message: "We have send the report to your email",
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
          }
          dispatch(hideLoading());
        })
        .catch((error) => {
          
          dispatch(hideLoading());
        });
    };

    if (!selectedFrontDesk) {
      return <div>Loading...</div>;
    }

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Visitor Analytics" subtitle="Welcome to your dashboard" />

        <Box  sx={{ display: 'flex', flexDirection: 'row'}}>
          <CssDateRangePicker 
            theme={theme}
            defaultValue={[validFromDayJS, validToDayJS]}
            value={[validFromDayJS, validToDayJS]}
            onChange={handleValidTimeRangeDateChange}
            />
          <Button
            onClick={handleFormSubmit}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              m: "0rem 0rem 0rem 0.75rem" ,
              "&:hover": { color: theme.palette.secondary.alt },
            }}
          >
            <FileDownload sx={{ mr: "10px" }} />
            Export
          </Button>
        </Box>
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
          <StatBox
            id={"chart_total_visits"}
            title="Total Visitors"
            value={totalVisitors}
            increase="+14%"
            description="Since last month"
            icon={
              <Diversity3
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        <StatBox
          id={"chart_total_visitors"}
          title="Invited Visitors"
          value={totalInvites}
          increase="+21%"
          description="Since last month"
          icon={
            <EmojiPeople
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Visitors By Month
          </Typography>
          <OverviewChart id={"chart_visitor_by_month"}view="sales" isDashboard={true} />
        </Box>
        <StatBox
          id={"chart_upcoming_visits"}
          title="Canceled Visits"
          value={totalCanceled}
          increase="-5%"
          description="Since last month"
          icon={
            <DirectionsRun
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          id={"chart_current_visits"}
          title="Rejected Visits"
          value={totalRejected}
          increase="-43%"
          description="Since last month"
          icon={
            <Group
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
          {/* ROW 2 */}
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Visitors By type
          </Typography>
          <BreakdownChart isDashboard={true} validFrom={validFromDayJS} validTo={validToDayJS} triggerRefresh={triggerRefresh}/>
        </Box>
        <Box
          gridColumn="span 8"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Check-In Per device
          </Typography>
          <TreeMapChart isDashboard={true} validFrom={validFromDayJS} validTo={validToDayJS} triggerRefresh={triggerRefresh}/>
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          id={"chart_top_10_visitors"}
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Top 10 Visitors
          </Typography>
          {
              top10VisitorsLoading ? (
                  "Loading..."
              ) : (
                top10VisitorsData.data && top10VisitorsData.data.length > 0 ? (
                    <List dense sx={{ 
                        gridColumn: "span 4" , 
                        // bgcolor: 'background.paper',
                        margin: '15px 0px 0px 0px',
                        overflowY: 'auto', // Add this to enable vertical scrolling
                        // maxHeight: '320px', // Set a maximum height for the container
                        flex:1
                        
                    }}>
                        {top10VisitorsData.data.map((value, index) => {

                            const labelId = `checkbox-list-visitor-label-${index}`;
                            const isLoggedInUser = typeof value.isLoggedInUser !== "undefined" && value.isLoggedInUser;
                            const picturePath = value.picturePath ? value.picturePath : "";

                            return (
                              <ListItem
                                  key={labelId}
                                  disablePadding
                                  disableGutters
                                  secondaryAction={
                                    <>
                                    <Typography variant="h5">
                                          {value.visitCount} {"visits"}
                                          </Typography>
                                    </>
                                  }
                                >
                                    <ListItemButton role={undefined} dense>

                                      <Avatar {...stringAvatar(value.fullName)} alt={value.fullName} src={`${getUri(getSessionToken())}/host/${value.entityId}/picture/${value.picturePath}`}   sx={{ width: 36, height: 36, mr:2 }}       />
                                      <ListItemText>
                                      <Box sx={{display:"flex",flexDirection:"row"}}>
                                          <Typography variant="h5" fontWeight="bold">
                                              {value.fullName}
                                          </Typography>
                                          </Box>
                                      </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                ) : (
                    
                  <Typography sx={{ mt: 3 }}>No data</Typography>

                )
            )
        }
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          id={"chart_top_10_hosts"}
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Top 10 Hosts
          </Typography>
          {
              top10HostsLoading ? (
                  "Loading..."
              ) : (
                top10HostsData.data && top10HostsData.data.length > 0 ? (
                    <List dense sx={{ 
                        gridColumn: "span 4" , 
                        // bgcolor: 'background.paper',
                        margin: '15px 0px 0px 0px',
                        overflowY: 'auto', // Add this to enable vertical scrolling
                        // maxHeight: '320px', // Set a maximum height for the container
                        flex:1
                        
                    }}>
                        {top10HostsData.data.map((value, index) => {

                            const labelId = `checkbox-list-visitor-label-${index}`;
                            const isLoggedInUser = typeof value.isLoggedInUser !== "undefined" && value.isLoggedInUser;
                            const picturePath = value.picturePath ? value.picturePath : "";

                            return (
                              <ListItem
                                  key={labelId}
                                  disablePadding
                                  disableGutters
                                  secondaryAction={
                                    <>
                                    <Typography variant="h5">
                                          {value.visitCount} {"visits"}
                                          </Typography>
                                    </>
                                  }
                                >
                                    <ListItemButton role={undefined} dense>

                                      <Avatar {...stringAvatar(value.fullName)} alt={value.fullName} src={`${getUri(getSessionToken())}/host/${value.entityId}/picture/${value.picturePath}`}   sx={{ width: 36, height: 36, mr:2 }}       />
                                      <ListItemText>
                                      <Box sx={{display:"flex",flexDirection:"row"}}>
                                          <Typography variant="h5" fontWeight="bold">
                                              {value.fullName}
                                          </Typography>
                                          </Box>
                                      </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                ) : (
                    
                  <Typography sx={{ mt: 3 }}>No data</Typography>

                )
            )
        }
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          id={"chart_top_10_companies"}
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Top 10 Companies
          </Typography>
          {
              top10CompaniesLoading ? (
                  "Loading..."
              ) : (
                top10CompaniesData.data && top10CompaniesData.data.length > 0 ? (
                    <List dense sx={{ 
                        gridColumn: "span 4" , 
                        // bgcolor: 'background.paper',
                        margin: '15px 0px 0px 0px',
                        overflowY: 'auto', // Add this to enable vertical scrolling
                        // maxHeight: '320px', // Set a maximum height for the container
                        flex:1
                        
                    }}>
                        {top10CompaniesData.data.map((value, index) => {

                            const labelId = `checkbox-list-visitor-label-${index}`;
                            const isLoggedInUser = typeof value.isLoggedInUser !== "undefined" && value.isLoggedInUser;
                            const picturePath = value.picturePath ? value.picturePath : "";

                            return (
                              <ListItem
                                  key={labelId}
                                  disablePadding
                                  disableGutters
                                  secondaryAction={
                                    <>
                                    <Typography variant="h5">
                                      {value.visitCount} {"visits"}
                                          </Typography>
                                    </>
                                  }
                                >
                                    <ListItemButton role={undefined} dense>

                                      <ListItemText>
                                      <Box sx={{display:"flex",flexDirection:"row"}}>
                                          <Typography variant="h5" fontWeight="bold">
                                              {value.fullName}
                                          </Typography>
                                          </Box>
                                      </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                ) : (
                    
                  <Typography sx={{ mt: 3 }}>No data</Typography>

                )
            )
        }
        </Box>
        
        {/* <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Check-In Per device
          </Typography>
          <SunBurstChart isDashboard={true} />
        </Box> */}
      </Box>
    </Box>
  );
};

export default GlobalVisitorsAnalytics;