import React, { useState, useEffect } from 'react';
import { MenuItem, Divider } from '@mui/material';
import PlayCircle from '@mui/icons-material/PlayCircle';
import StopCircle from '@mui/icons-material/StopCircle';
import PersonOff from '@mui/icons-material/PersonOff';
import DoNotDisturb from '@mui/icons-material/DoNotDisturb';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { Redo } from '@mui/icons-material';
import tm from 'components/TranslationManager';

const MenuItemsVisitStatus = ({ visitStatus, handleStartVisit, handleFinishVisit, handleNoShowVisit, handleCancelVisit, handleDeleteVisit, handleReOpenVisit }) => {
  const [visitStatusMenuStarted, setVisitStatusMenuStarted] = useState(false);
  const [visitStatusMenuFinished, setVisitStatusMenuFinished] = useState(false);
  const [visitStatusMenuNoShow, setVisitStatusMenuNoShow] = useState(false);
  const [visitStatusMenuCancel, setVisitStatusMenuCancel] = useState(false);
  const [visitStatusMenuDelete, setVisitStatusMenuDelete] = useState(false);
  const [visitStatusMenuReOpen, setVisitStatusMenuReOpen] = useState(true);

  useEffect(() => {
    switch (visitStatus) {
      case "pre_registered":
        setVisitStatusMenuStarted(false);
        setVisitStatusMenuFinished(true);
        setVisitStatusMenuNoShow(false);
        setVisitStatusMenuCancel(false);
        setVisitStatusMenuDelete(false);
        setVisitStatusMenuReOpen(true);
        break;
      case "started":
        setVisitStatusMenuStarted(true);
        setVisitStatusMenuFinished(false);
        setVisitStatusMenuNoShow(true);
        setVisitStatusMenuCancel(true);
        setVisitStatusMenuDelete(true);
        setVisitStatusMenuReOpen(true);
        break;
      case "finished":
      case "canceled":
      case "no_show":
      case "expired":
        setVisitStatusMenuStarted(true);
        setVisitStatusMenuFinished(true);
        setVisitStatusMenuNoShow(true);
        setVisitStatusMenuCancel(true);
        setVisitStatusMenuDelete(true);
        setVisitStatusMenuReOpen(false);
        break;
      case "rejected":
        setVisitStatusMenuStarted(true);
        setVisitStatusMenuFinished(true);
        setVisitStatusMenuNoShow(true);
        setVisitStatusMenuCancel(true);
        setVisitStatusMenuDelete(true);
        setVisitStatusMenuReOpen(true);
        break;
      default:
        setVisitStatusMenuStarted(true);
        setVisitStatusMenuFinished(true);
        setVisitStatusMenuNoShow(true);
        setVisitStatusMenuCancel(true);
        setVisitStatusMenuDelete(true);
        break;
    }
  }, [visitStatus]);

  return (
    <div>
      <MenuItem disabled={visitStatusMenuStarted} onClick={() => {
          handleStartVisit("started"); // Execute the function
        }} disableRipple>
        <PlayCircle />
        {tm.translate('btnStartVisit', "Start")} 
      </MenuItem>
      <MenuItem disabled={visitStatusMenuFinished} onClick={handleFinishVisit} disableRipple>
        <StopCircle />
        {tm.translate('btnFinishVisit', "Finish")} 
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      {/* <MenuItem disabled={visitStatusMenuNoShow} onClick={handleNoShowVisit} disableRipple>
        <PersonOff />
        No Show
      </MenuItem> */}
      <MenuItem disabled={visitStatusMenuCancel} onClick={handleCancelVisit} disableRipple>
        <DoNotDisturb />
        {tm.translate('btnCancelVisit', "Cancel")} 
      </MenuItem>
      <MenuItem disabled={visitStatusMenuDelete} onClick={handleDeleteVisit} disableRipple>
        <DeleteOutline />
        {tm.translate('btnDeleteVisit', "Delete")}
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem disabled={visitStatusMenuReOpen} onClick={handleFinishVisit} disableRipple>
        <Redo />
        Re-Open
      </MenuItem>
    </div>
  );
};

export default MenuItemsVisitStatus;
