// // color design tokens export
// export const tokensDark = {
//     grey: {
//       0: "#ffffff", // manually adjusted
//       10: "#f6f6f6", // manually adjusted
//       50: "#f0f0f0", // manually adjusted
//       100: "#e0e0e0",
//       200: "#c2c2c2",
//       300: "#a3a3a3",
//       400: "#858585",
//       500: "#666666",
//       600: "#525252",
//       700: "#3d3d3d",
//       800: "#292929",
//       900: "#141414",
//       1000: "#000000", // manually adjusted
//     },
//     primary: {
//       100: "#d0d1d5",
//       200: "#a1a4ab",
//       300: "#727681",
//       400: "#1F2A40",
//       500: "#141b2d",
//       600: "#101624",
//       700: "#0c101b",
//       800: "#080b12",
//       900: "#040509",
//     },
//     secondary: {
//       // yellow
//       50: "#f0f0f0", // manually adjusted
//       100: "#fff6e0",
//       200: "#ffedc2",
//       300: "#ffe3a3",
//       400: "#ffda85",
//       500: "#ffd166",
//       600: "#cca752",
//       700: "#997d3d",
//       800: "#665429",
//       900: "#332a14",
      
//     },
//   };
  
//   // function that reverses the color palette
//   function reverseTokens(tokensDark) {
//     const reversedTokens = {};
//     Object.entries(tokensDark).forEach(([key, val]) => {
//       const keys = Object.keys(val);
//       const values = Object.values(val);
//       const length = keys.length;
//       const reversedObj = {};
//       for (let i = 0; i < length; i++) {
//         reversedObj[keys[i]] = values[length - i - 1];
//       }
//       reversedTokens[key] = reversedObj;
//     });
//     return reversedTokens;
//   }
//   export const tokensLight = reverseTokens(tokensDark);
  
  // mui theme settings
  export const themeSettings = (mode, tokensDark, tokensLight) => {
    return {
      palette: {
        mode: mode,
        ...(mode === "dark"
          ? {
              // palette values for dark mode
              primary: {
                ...tokensDark.primary,
                main: tokensDark.primary[400],
                light: tokensDark.primary[400],
              },
              secondary: {
                ...tokensDark.secondary,
                main: tokensDark.secondary[300],
              },
              neutral: {
                ...tokensDark.grey,
                main: tokensDark.grey[500],
              },
              background: {
                default: tokensDark.primary[600],
                alt: tokensDark.primary[500],
              },
            }
          : {
              // palette values for light mode
              primary: {
                ...tokensLight.primary,
                main: tokensDark.grey[50],
                light: tokensDark.grey[100],
              },
              secondary: {
                ...tokensLight.secondary,
                main: tokensDark.secondary[600],
                light: tokensDark.secondary[700],
              },
              neutral: {
                ...tokensLight.grey,
                main: tokensDark.grey[500],
              },
              background: {
                default: tokensDark.grey[0],
                alt: tokensDark.grey[50],
              },
            }),
      },
      components: {
        MuiGridToolbarColumnsButton: {
          styleOverrides: {
            root: {
              // Add your GridToolbarColumnsButton overrides here
            },
          },
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              border: "none",
              "& .Mui-checked.MuiCheckbox-root": { // front column to multi select checkbox
                color: mode === "dark" ? `${tokensDark.secondary[200]} !important` : tokensDark.secondary[600],
              },
              "& .MuiLink-root.MuiLink-underlineAlways": { //make color white of those link cell values
                color: mode === "dark" ? tokensDark.grey[100] : tokensDark.grey[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": { // those buttons to hide columns and define a columns filter
                color: `${tokensDark.secondary[200]} !important`,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: mode === "dark" ? tokensDark.primary[500] : tokensDark.grey[50], //definition of grid footer, i did use background.alt from above
                color: tokensDark.secondary[100],
                borderTop: "none",
              },
            },
            cell: {
              borderBottom: "none",
            },
            columnHeaders: {
              backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.primary[500],
              color: mode === "dark" ? tokensDark.secondary[100] : tokensDark.secondary[200],
              borderBottom: "none",
            },
            virtualScroller: {
              backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.primary[500],
            },
              //filter and so below
            footerContainer: {
              backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.primary[500],
              color: mode === "dark" ? tokensDark.secondary[100] : tokensDark.secondary[200],
              borderTop: "none",
            },
            panelContent:{
              backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.grey[500],
            },
            panelHeader:{
              backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.grey[500],
              "& .MuiFormLabel-root": {
                backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.grey[500],
                color: mode === "dark" ? tokensDark.secondary[100] : tokensDark.grey[200],
              },
            },
            columnsPanel:{
              backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.grey[500],
            },
            panelFooter:{
              backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.grey[500],
              "& .MuiButtonBase-root": {
                backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.grey[500],
                color: mode === "dark" ? tokensDark.secondary[100] : tokensDark.grey[200],
              },
            },
            columnsPanelRow:{
              "& .MuiSwitch-switchBase.Mui-checked": {
                backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.grey[500],
                color: mode === "dark" ? tokensDark.secondary[300] : tokensDark.grey[200],
              },
              "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                backgroundColor: mode === "dark" ? tokensDark.secondary[200] : tokensDark.grey[500],
              },
              
            }
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              border: "none",
              "& .MuiTab-textColorPrimary.Mui-selected": {
                color: `${tokensDark.secondary[200]} !important`,
                fontWeight: 'bold'
              },
            },
          },
        },
       
        // MuiBox: {
        //   styleOverrides: {
        //     root: {
        //       backgroundColor: mode === "dark" ? `${tokensDark.secondary[200]} !important` : tokensDark.secondary[600],
        //     },
        //   }
        // },
        //needed by datetimepicker
        MuiPickersDay: {
          styleOverrides: {
            root: {
              backgroundColor: mode === "dark" ? tokensDark.primary[300] : tokensDark.primary[500],
              '&.Mui-selected': {
                backgroundColor: mode === "dark" ? tokensDark.primary[700] : tokensDark.secondary[200], // Add border color for selected day
              },
            },
            today: {
              backgroundColor: mode === "dark" ? tokensDark.primary[400] : tokensDark.primary[500],
              '&.MuiPickersDay-root': {
                borderColor: mode === "dark" ? tokensDark.primary[500] : tokensDark.secondary[200], // Add border color
              },
            },
          }
        },
        //needed by datetimepicker
        MuiPickersToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.primary[500],
              color: mode === "dark" ? tokensDark.secondary[100] : tokensDark.secondary[200],
            },
            "& .MuiButtonBase-root": {
              backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.grey[500],
              color: mode === "dark" ? tokensDark.secondary[100] : tokensDark.grey[200],
            },
          }
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.primary[500],
              "& .MuiButton-root.MuiButton-textPrimary": {
                color: mode === "dark" ? `${tokensDark.primary[100]} !important` : `${tokensDark.grey[200]} !important`,
              },
            },
            
          }
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              // backgroundColor: mode === "dark" ? tokensDark.primary[600] : tokensDark.primary[500],
              // color: mode === "dark" ? tokensDark.secondary[100] : tokensDark.secondary[200],
              '&.Mui-disabled': {
                // Add your custom styles here...
                color: mode === "dark" ? `${tokensDark.grey[300]} !important` : `${tokensDark.grey[200]} !important`,
              },
              '&.Mui-disabled .MuiButton-startIcon': {
                // Add your custom styles here...
                color: mode === "dark" ? `${tokensDark.grey[300]} !important` : `${tokensDark.grey[200]} !important`,
              },
            },
            '&.Mui-checked': {
              // Add your custom styles here...
              color: mode === "dark" ? tokensDark.secondary[100] : tokensDark.secondary[200],
            },
            
           
          }
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              '&.Mui-selected': {
                // Add your custom styles here...
                backgroundColor: mode === "dark" ? tokensDark.primary[400] : tokensDark.secondary[800],
                color: mode === "dark" ? tokensDark.secondary[100] : tokensDark.secondary[200],
                "&:hover": {
                  color: tokensDark.primary.main,
                  backgroundColor: tokensDark.secondary.light,
                  fontWeight: "bold",
                  },
              },
              
            },
            
            
          }
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              '&.Mui-checked': {
                // Add your custom styles here...
                color: mode === "dark" ? tokensDark.secondary[100] : tokensDark.secondary[300],
              },
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              '&.Mui-checked': {
                // Add your custom styles here...
                color: mode === "dark" ? tokensDark.secondary[100] : tokensDark.secondary[300],
              },
              '&.Mui-selected': {
                // Add your custom styles here...
                backgroundColor: mode === "dark" ? tokensDark.primary[300] : tokensDark.secondary[800],
                color: mode === "dark" ? tokensDark.secondary[100] : tokensDark.secondary[200],
                "&:hover": {
                  backgroundColor: mode === "dark" ? tokensDark.primary[300] : tokensDark.secondary[800],
                  color: mode === "dark" ? tokensDark.secondary[100] : tokensDark.secondary[200],
                  fontWeight: "bold",
                  },
              }
            },
          },
        },
        
        
      },
      typography: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 12,
        h1: {
          fontFamily: ["Inter", "sans-serif"].join(","),
          fontSize: 40,
        },
        h2: {
          fontFamily: ["Inter", "sans-serif"].join(","),
          fontSize: 32,
        },
        h3: {
          fontFamily: ["Inter", "sans-serif"].join(","),
          fontSize: 24,
        },
        h4: {
          fontFamily: ["Inter", "sans-serif"].join(","),
          fontSize: 20,
        },
        h5: {
          fontFamily: ["Inter", "sans-serif"].join(","),
          fontSize: 16,
        },
        h6: {
          fontFamily: ["Inter", "sans-serif"].join(","),
          fontSize: 14,
        },
        iconSizes: {
          large: 24, // Define your large icon size here
          xl: 36,    // Define your extra large icon size here
        },
        fontSizes: {
          medium: 18,
          large: 24, 
          xl: 36,
        },
      },
    };
  };