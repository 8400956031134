import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, FormControlLabel, useTheme, Radio, Autocomplete } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import IOSSwitch from 'components/IOSSwitch';
import { hideLoading, showLoading } from 'state/loadingSlicer';
import { useDispatch } from 'react-redux';
import { getWorkflowEngineType, putWorkflowEngineType } from 'hooks/HttpRequests';
import { useNavigate } from 'react-router-dom';
import { commonStyles, CssRadioGroup, CssTextField } from 'components/StyledComponents';
import { enqueueSnackbar } from 'notistack';
import tm from 'components/TranslationManager';


const HostLookUpConfig = ({ visitorType,frontDeskEntityId,initialValues, onSave, visitorTypeEntity }) => {

    const theme = useTheme();
    const {palette} = useTheme();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loadingComplete, setLoadingComplete] = useState(false);
    const [loadedFormData, setLoadedFormData] = useState(null);

// "{\"selectionType\":\"single_field\",\"autoCompleteCount\":3,\"autoCompleteExtraField\":\"none\",\"showHostLookUp\":true,\"defaultHostLookupEmail\":\"\",\"defaultHostLookupEntityId\":-1}"

    const defaultLookupMode = { label: "Single Field", value: "single_field" };
    const [selectedLookupMode, setSelectedLookupMode] = useState("single_field"); // Default value

    const lookupModes = [
        defaultLookupMode,
        { label: 'First Name vs Last Name', value: "firstname_vs_lastname" },
    ]

    const getValidationSchema = (showHostLookUp) => {
        return yup.object().shape({
            showHostLookUp: yup.boolean(),
            defaultHostLookupEmail: showHostLookUp
                ? yup.string().nullable() // Not required if `showHostLookUp` is true
                : yup.string().email('Invalid email').required('Email is required'),
            autoCompleteCount: showHostLookUp
                ? yup.number().required('Number of characters is required').min(1, 'Must be at least 1 character')
                : yup.number().nullable(), // Not required if `showHostLookUp` is false
            autoCompleteExtraField: showHostLookUp
                ? yup.string().required('Please select an extra field for autocomplete')
                : yup.string().nullable(),
        });
    };


    useEffect(() => {
        loadData(visitorType);
    }, []);

    const handleSubmit = (values) => {

        // onSave(values); // Call the onSave function passed as a prop
        var submitJson = {}
        submitJson["saveToAll"] = false 
        submitJson["visitorType"] = visitorTypeEntity

        values["entityId"] = initialValues.entityId

        submitJson["data"] = values 

        console.log('Saving Take Picture Config:', submitJson);

        putWorkflowEngineType(navigate)(frontDeskEntityId, "hostlookup", submitJson)


            .then((result) => {
            // Use the result.data as needed
            if (result.success) {
            
                if(result.success){
                    //   setCopmanyName("- "+result.data.name);
                    // setLoadedFormData(result.data)

                    enqueueSnackbar({
                        message: "Entity was saved",
                          variant: 'success',
                          anchorOrigin: {
                            vertical: 'top', // 'top', 'bottom'
                            horizontal: 'center', // 'left', 'center', 'right'
                          },
                          autoHideDuration: 3000, 
                          // persist: true
                        
                      });

                }else{
                    enqueueSnackbar({
                        message: "Error "+result.message,
                            variant: 'error',
                            anchorOrigin: {
                            vertical: 'top', // 'top', 'bottom'
                            horizontal: 'center', // 'left', 'center', 'right'
                            },
                            autoHideDuration: 3000, 
                            // persist: true
                        
                        });
                }
    
            }
            })
            .catch((error) => {
            dispatch(hideLoading());
    
            if (typeof error.action !== "undefined") {
                if (error.action == "relogin") {
                navigate("/");
                }
            }else if (error.hasOwnProperty('success')) {
                enqueueSnackbar({
                message: "Error "+error.message,
                    variant: 'error',
                    anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                
                });
            }
    
            });

    };

    const loadData = async (visitorType) => {

        // setGridLoading(true)
        dispatch(showLoading());
        console.log(visitorType)

        getWorkflowEngineType(navigate)(frontDeskEntityId, initialValues.entityId)
        .then((result) => {

            // Use the result.data as needed
            if(result.success){
                var tmp = JSON.parse(result.data.configuration)
                if(typeof tmp.showHostLookUp == "undefined"){
                    tmp["showHostLookUp"] = true;
                }
                if(typeof tmp.defaultHostLookupEmail == "undefined"){
                    tmp["defaultHostLookupEmail"] = "";
                }
                if(typeof tmp.defaultHostLookupEntityId == "undefined"){
                    tmp["defaultHostLookupEntityId"] = -1;
                }
                setLoadedFormData(tmp)

            }
        setLoadingComplete(true)
            dispatch(hideLoading());


        })
        .catch((error) => {

                setLoadingComplete(true)
            dispatch(hideLoading());

        });

    }

    if (!loadingComplete || !loadedFormData) {
        return <div>Loading...</div>;
    }

    return (
        <Box sx={{ ...commonStyles,  border: 1 , p : 5}}>
        <Formik
            initialValues={loadedFormData}
            validationSchema={getValidationSchema(loadedFormData?.showHostLookUp)}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue}) => (
                <form onSubmit={handleSubmit}>
                    <Typography variant="h5" gutterBottom>
                        Host lookup Configuration
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <FormControlLabel
                        sx={{ mb: 2, gridColumn: "span 4", backgroundColor: 'transparent' }}
                        fullWidth
                        control={
                            <IOSSwitch
                            fullWidth
                            sx={{ m: 1 }}
                            checked={values.showHostLookUp}
                            onChange={(e) => setFieldValue("showHostLookUp", e.target.checked)}
                            />
                        }
                        label={<span style={{ color: theme.palette.secondary.light }}>Show Host Lookup</span>}
                    />
                    </Box>
                    {!values.showHostLookUp ? (
                    <CssTextField
                        label="Email"
                        name="defaultHostLookupEmail"
                        type="email"
                        required
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.defaultHostLookupEmail}
                        error={Boolean(touched.defaultHostLookupEmail) && Boolean(errors.defaultHostLookupEmail)}
                        helperText={touched.defaultHostLookupEmail && errors.defaultHostLookupEmail}
                        variant="outlined"
                        fullWidth
                        theme={theme}
                        sx={{ mb: 2 }}
                    />
                    ) : (
                        <>
                        <Autocomplete
                                disablePortal
                                disableClearable
                                name="selectionType"
                                options={lookupModes}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) => option.value === value}
                                onChange={(event, newValue) => {
                                    setFieldValue('selectionType', newValue.value);
                                }}
                                sx={{ 
                                    width: 250,
                                    mb: 2,
                                    gridColumn: "span 4",
                                    "& label.Mui-focused": {
                                        color: theme.palette.secondary[200],
                                    },
                                    "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
                                        color: theme.palette.secondary[300],
                                    }
                                }}
                                value={lookupModes.find((lookupType) => lookupType.value === values.selectionType) || defaultLookupMode}
                                renderInput={(params) => (
                                    <CssTextField
                                        {...params}
                                        label={tm.translate('dspAuthType', 'Authentication Type')}
                                        theme={theme}
                                    />
                                )}
                            />
                            {values.selectionType == 'single_field' && (
                                <CssTextField
                                    name="autoCompleteCount"
                                    label="Number of characters before host auto complete starts"
                                    type="number"
                                    required
                                    value={values.autoCompleteCount}
                                    onChange={handleChange}
                                    error={touched.autoCompleteCount && Boolean(errors.autoCompleteCount)}
                                    helperText={touched.validDays && errors.autoCompleteCount}
                                    fullWidth
                                    theme={theme}
                                    margin="normal"
                                />
                            )}
                   
                   <CssRadioGroup
                    name="autoCompleteExtraField"
                    value={values.autoCompleteExtraField}
                    theme={theme}
                    onChange={(e) => {
                        console.log('RadioGroup onChange triggered');
                        console.log('Event:', e);
                        console.log('e.target:', e.target);
                        console.log('e.target.value:', e.target?.value); // Log the value
                        setFieldValue('autoCompleteExtraField', e.target.value);
                    }}
                >
                    <FormControlLabel value="none" control={<Radio />} label="None" />
                    <FormControlLabel value="email" control={<Radio />} label="Email" />
                    <FormControlLabel value="department" control={<Radio />} label="Department" />
                    <FormControlLabel value="companyName" control={<Radio />} label="Company Name" />
                </CssRadioGroup>

                        </>
                    )}
                    
                    <Button
                        fullWidth
                        type="submit"
                        sx={{
                            mt: 2,
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.dark,
                            },
                        }}
                    >
                        Save Changes
                    </Button>
                </form>
            )}
        </Formik>
        </Box>
    );
};

export default HostLookUpConfig;
