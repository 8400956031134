import {   AccessTime, Apartment, AttachFile, Badge, Brush, CameraAlt, DoneAll, Email, FlightLand, FlightTakeoff, HelpOutline, OndemandVideo, PersonAdd, PlayCircleOutline, QrCode, RadioButtonUnchecked, Warning, Webhook } from "@mui/icons-material";
import { Box, Paper,Typography,useTheme } from "@mui/material";
import React from 'react'
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import dayjs from "dayjs";
import { useFrontDeskManager } from "./FrontDeskManager";


const TimeLineItemVisitHistory = ({ index, entity, isLastElement }) => {

  const theme = useTheme();

  const { convertToFrontDeskTimeZoneDateTimeString } = useFrontDeskManager();

  // Function to select icon based on action
  const getIcon = (action) => {
    switch (action) {
      case 'add_host_to_visit':
        return <PersonAdd sx={{ color: theme.palette.primary.main }} />;
      case 'changed_visit_time_range':
        return <AccessTime sx={{ color: theme.palette.primary.main }} />;
      case 'visit_started':
        return <PlayCircleOutline sx={{ color: theme.palette.primary.main }} />;
      case 'visit_finished':
        return <DoneAll sx={{ color: theme.palette.success.main }} />;
      case 'checked_in':
        return <FlightLand sx={{ color: theme.palette.primary.main }} />;
      case 'checked_out':
        return <FlightTakeoff sx={{ color: theme.palette.primary.main }} />;
      case 'location_changed':
        return <Apartment sx={{ color: theme.palette.primary.main }} />;
      case 'workflow_video':
        return <OndemandVideo sx={{ color: theme.palette.primary.main }} />;
      case 'visitor_arrived_notify':
        return <Email sx={{ color: theme.palette.primary.main }} />;
      case 'webhook':
          return <Webhook sx={{ color: theme.palette.primary.main }} />;
      case 'send_qr_code':
          return <QrCode sx={{ color: theme.palette.primary.main }} />;
      case 'send_qr_code_no':
        return <Warning sx={{ color: theme.palette.primary.main }} />;
      case 'visitor_picture':
        return <CameraAlt sx={{ color: theme.palette.primary.main }} />;
      case 'assgin_access_card':
        return <Badge sx={{ color: theme.palette.primary.main }} />;
      case 'unassign_access_card':
        return <Badge sx={{ color: theme.palette.primary.main }} />;
      case 'changed_access_levels':
          return <Badge sx={{ color: theme.palette.primary.main }} />;  
      case 'attached_file':
        return <AttachFile sx={{ color: theme.palette.primary.main }} />;
      case 'visitor_signed':
        return <Brush sx={{ color: theme.palette.primary.main }} />;
      case 'confirm_visitor':
        return <RadioButtonUnchecked sx={{ color: theme.palette.primary.main }} />;
      case 'visitor_question_yes_no':
        return <HelpOutline sx={{ color: theme.palette.primary.main }} />;
      case 'visitor_q_multiple':
        return <HelpOutline sx={{ color: theme.palette.primary.main }} />;
      default:
        return <RadioButtonUnchecked sx={{ color: theme.palette.primary.main }} />;
    }
  };


  const localDateTime = convertToFrontDeskTimeZoneDateTimeString(entity.whenCreated)
  // const localDateTime = dayjs.utc(utcDateTime).tz('America/Los_Angeles').format('DD.MM.YYYY HH:mm');

  const description = entity.description.replace(/##/g, '')


  return (
    <TimelineItem key={entity.id}>
      <TimelineOppositeContent sx={{ maxWidth: 150, alignItems: "center"}}>
        <Typography variant="body2" color="textSecondary">
          {localDateTime}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot sx={{ backgroundColor: theme.palette.secondary.light }}>
          {getIcon(entity.action)}
        </TimelineDot>
        {isLastElement ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} sx={{ backgroundColor: theme.palette.background.alt, padding: '6px 16px' }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.secondary.main }}>
            {entity.action}
            </Typography>
            <Typography variant="h6">
              {description}
            </Typography>
            </Box>
            {entity.picture && (
              entity.action === "visitor_signed" ? (
                <img
                  src={`data:image/jpeg;base64,${entity.picture}`}
                  alt="entity-picture"
                  height="150"
                  width="600"
                />
              ) : entity.action === "id_document_color" ? ( // Else if entity.action is "id_document_color"
                <img
                  src={`data:image/jpeg;base64,${entity.picture}`}
                  alt="entity-picture"
                  height="189"
                  width="275"
                />

              ) : entity.action === "confirm_visitor" ? ( // Else if entity.action is "id_document_color"
                <img
                  src={`data:image/jpeg;base64,${entity.picture}`}
                  alt="entity-picture"
                  height="150"
                  width="600"
                />
                
              ) : ( // Default case (else)
                <img
                  src={`data:image/jpeg;base64,${entity.picture}`}
                  alt="entity-picture"
                  height="100"
                  width="100"
                />
              )
            )}            
          </Box>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

export default TimeLineItemVisitHistory

