import {  Save } from "@mui/icons-material";
import {  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Paper, useMediaQuery, useTheme } from "@mui/material";
import React, {  useEffect, useRef } from 'react'
import { useState } from "react";
import {  postViitorType, postVisitor } from "hooks/HttpRequests";
import { CssTextField } from "components/StyledComponents";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import tm from 'components/TranslationManager';
import * as yup from "yup";
import { useFrontDeskManager } from "components/FrontDeskManager";
import IOSSwitch from "components/IOSSwitch";
import { useDispatch } from "react-redux";


const DialogAddVisitorType = ({ open, onClose, onSave, currentEntity, entityTypeParam, visitId = -1, onScanPassport  }) => {

  const { palette } = useTheme();
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const [entityType, setEntityType] = useState(entityTypeParam);;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef();
  
  const { selectedFrontDesk, selectedFrontDeskTerminalSettings} = useFrontDeskManager();

  const [validationSchema, setValidationSchema] = useState(yup.object());
  
  const [additionalFields, setAdditionalFields] = useState([]);

  const initialValuesRegister = {
    active: true,
    globalType: false,
    frontDeskEntityId: 0,
    idx: 0,
    visitorType: "",
    description: "",
    defaultType: false,
    accessLevels: ""
    
  };

  const handleSubmit = (values) => {



    if(visitId == -1){
      //IF VISIT WAS NIT SAVED YET, just create visitor or lookup visitorId
      var submitData= {}
      values.visitorType = values.visitorType.trim()
      submitData["data"]= values;
  
      postViitorType(navigate)(selectedFrontDesk.entityId, submitData)
        .then((result) => {
          // Use the result.data as needed
          if (result.success) {
        
            var data = result.data;
            onSave()

          }
        })
        .catch((error) => {
          if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
              navigate("/");
            }
          }
        });

    }else{
      var submitData= {}
      submitData["data"]= currentEntity;
  
      postVisitor(navigate)(submitData)
        .then((result) => {
          // Use the result.data as needed
          if (result.success) {
        
              var data = result.data;
          }
        })
        .catch((error) => {
          if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
              navigate("/");
            }
          }
        });
    }
    

  };

  useEffect(() => {

    
    

  }, [open]);

  ///////////////////////////////


  ///////////////////////////////


  const handleSave = () => {

        // const file = selectedFile

        // // Create FormData object
        // const formData = new FormData();
        // formData.append('file', file);

        var base64Image = ""

      

     
  }


  const handleClose = () => {

      onClose();
  }

  const registerSchema = yup.object().shape({
    firstname: yup.string().required("First name is required"),
    lastname: yup.string().required("Last name is required"),
    visitorType: yup.string().required("Visitor type is required"),
    ...additionalFields.reduce((schema, field) => {
      schema[field.apiKey] = field.required
        ? yup.string().required(`${field.fieldName} is required`)
        : yup.string();
      return schema;
    }, {}),
  });



  return (
    <Dialog open={open} onClose={onClose}>
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>{tm.translate("btnNewVisitorType", 'New Visitor Type')}</DialogTitle>
        <DialogContent>
       
        <Box sx={{ 
                    m: "1rem 0rem 0rem 0rem",
                    borderRadius: 1,
                    borderColor: theme.palette.secondary[100],
                    border: 0 , p: 5,
                    backgroundColor:theme.palette.background.alt,
             }}> 
          <Formik
                      onSubmit={handleSubmit}
                      initialValues={initialValuesRegister}
                      validationSchema={validationSchema}
                      enableReinitialize={true}
                      validateOnChange={false} // Disable validation on change
                      validateOnBlur={false}   // Disable validation on blur
                      innerRef={formikRef}
                  >
                  {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      resetForm,
                      }) => (
                      <form onSubmit={handleSubmit}>
                  <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)'  }}
                      sx={{
                          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                      >
                        <FormControlLabel
                          sx={{ mb: 2, gridColumn: "span 4", backgroundColor: 'transparent', width: "100%"  }}
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.defaultType}
                              onChange={(e) => setFieldValue("defaultType", e.target.checked)}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>{tm.translate('btnDefault', "Default")}</span>}
                        />
                        <FormControlLabel
                          sx={{ mb: 2, gridColumn: "span 4", backgroundColor: 'transparent', width: "100%"  }}
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.active}
                              onChange={(e) => setFieldValue("active", e.target.checked)}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>{tm.translate('btnShowOnKiosk', "Show on Check-In Kiosk")}</span>}
                        />
              
           
                        <CssTextField
                          label={tm.translate('dspName', 'Name')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          // value={loadedFormData?.name || values.name}
                          value={values.visitorType} // Use values from Formik
                          name="visitorType"
                          error={ Boolean(touched.visitorType) && Boolean(errors.visitorType)}
                          helperText={touched.visitorType && errors.visitorType}
                          sx={{ mb: 2 , gridColumn: "span 4" }}
                          theme={theme} 
                          fullWidth
                      />
                      <CssTextField
                          label={tm.translate('dspDescription', 'Description')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          // value={loadedFormData?.name || values.name}
                          value={values.description} // Use values from Formik
                          name="description"
                          error={ Boolean(touched.description) && Boolean(errors.description)}
                          helperText={touched.description && errors.description}
                          sx={{ mb: 2, gridColumn: "span 4" }}
                          theme={theme} 
                          fullWidth
                      />
                      <CssTextField
                          label={tm.translate('dspAccessLevels', 'Access Levels')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          // value={loadedFormData?.name || values.name}
                          value={values.accessLevels} // Use values from Formik
                          name="accessLevels"
                          error={ Boolean(touched.accessLevels) && Boolean(errors.accessLevels)}
                          helperText={touched.accessLevels && errors.accessLevels}
                          sx={{ mb: 2, gridColumn: "span 4" }}
                          theme={theme} 
                          fullWidth
                      />

    
                      <Grid container spacing={1}>
                      <Grid item xs={12}>
                          <Button
                          fullWidth
                          startIcon={<Save />}
                          type="submit"
                          sx={{
                              p: "1rem",
                              backgroundColor: palette.secondary.light,
                              color: palette.background.alt,
                              fontWeight: "bold",
                              "&:hover": {
                              color: palette.primary.main,
                              backgroundColor: palette.secondary.light,
                              fontWeight: "bold",
                              },
                          }}
                          >
                          {tm.translate('btnSave', 'Save')}
                          </Button>
                      </Grid>
                     
                      </Grid>
                      {/* Add more text fields as needed */}
                  </Paper>
                      </form>
                  )}
              </Formik>
          </Box>
         

          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {/* Are you sure you want to execute this action? */}
        
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}> 
          {/* <Button onClick={onClearImage} color="primary">remove photo</Button> */}

          <div>
            
      
            <Button onClick={handleClose} color="primary" sx={{ color: theme.palette.background.alt }}>
              {tm.translate('btnCancel', 'Cancel')}
            </Button>
          </div>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogAddVisitorType