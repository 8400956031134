// src/config/ConfigData.js

const ConfigData = {
    attachmentTables: {
        ATTACHMENT_TABLE_VISIT: 'visit',
        ATTACHMENT_TABLE_EXTERNAL_DEVICES: 'external_devices',
        ATTACHMENT_TABLE_VISITOR: 'visitor',
        ATTACHMENT_TABLE_FRONT_DESK: 'front_desk',
        ATTACHMENT_TABLE_SOFTWARE_VERSION: 'software_version',
        ATTACHMENT_TABLE_CUSTOMER: 'customer',
        ATTACHMENT_TABLE_COMPANY: 'company',
        ATTACHMENT_TABLE_WORKFLOW: 'workflow',
        ATTACHMENT_TABLE_WORKFLOW_TRANSLATE: 'workflow_translate',
        ATTACHMENT_TABLE_HOST: 'host'
    },

    attachmentKeynames: {
        ATTACHMENT_KEYNAME_VISIT_ATTACHMENT: 'visit_attachment',
        ATTACHMENT_KEYNAME_VISIT_NOTE: 'visit_note',
        ATTACHMENT_KEYNAME_VISITOR_ATTACHMENT: 'visitor_attachment',
        ATTACHMENT_KEYNAME_VISITOR_DOCUMENT: 'visitor_document',
        ATTACHMENT_KEYNAME_VISITOR_ID_DOCUMENT: 'visitor_id_document',
        ATTACHMENT_KEYNAME_VISITOR_NOTE: 'visitor_note',
        ATTACHMENT_KEYNAME_TERMINAL_LOGO: 'terminal_logo',
        ATTACHMENT_KEYNAME_TERMINAL_BACKGROUND: 'terminal_background',
        ATTACHMENT_KEYNAME_TERMINAL_BACKGROUND_HOME: 'terminal_background_home',
        ATTACHMENT_KEYNAME_GOOGLE_CLOUD_KEY: 'google_cloud_key',
        ATTACHMENT_KEYNAME_SOFTWARE_VERSION: 'software_version',
        ATTACHMENT_KEYNAME_CUSTOMER_ATTACHMENT: 'customer_attachment',
        ATTACHMENT_KEYNAME_PRE_REG_ATTACHMENT: 'pre_reg_attachment',
        ATTACHMENT_KEYNAME_COMPANY_ATTACHMENT: 'company_attachment',
        ATTACHMENT_KEYNAME_GENERAL_INFO: 'general_info',
        ATTACHMENT_KEYNAME_CUSTOM_BUTTON: 'custom_button',
        ATTACHMENT_KEYNAME_WORKFLOW_VIDEO: 'workflow_video',
        ATTACHMENT_KEYNAME_HOST: 'host_attachment',
        ATTACHMENT_KEYNAME_EXTERNAL_DEVICE_ATTACHMENT: 'ext_device_attachment',
        ATTACHMENT_KEYNAME_EXTERNAL_DEVICE_NOTE: 'ext_device_note'
    }
};

export default ConfigData;
