import React, { useEffect, useState } from 'react';
import { Box, Button, useTheme, Grid, Paper, useMediaQuery, Avatar } from '@mui/material';
import { CameraAlt, Save } from '@mui/icons-material';
import {  getSessionToken, getUri, putUserProfileHost } from 'hooks/HttpRequests';
import { CssMuiPhoneNumber, CssTextField, stringAvatar} from "components/StyledComponents";
import * as yup from "yup";
import { Formik } from 'formik';
import tm from 'components/TranslationManager';
import DialogTakePicture from 'components/dialog/DialogTakePicture';
import { useUserProfileManager } from 'components/UserProfileProvider';
import ProfileChangeUserPassword from './profileChangeUserPassword';
import { enqueueSnackbar } from 'notistack';


const ProfileDetailGeneral = ({globalType, navigate, id }) => {

const [confirmationOpen, setConfirmationOpen] = useState(false);
const [authType, setAuthType] = useState("app");


const theme = useTheme();
const {palette} = useTheme();
const isNonMobile = useMediaQuery("(min-width:600px)");
const defaultCategory = { label: "Airline", value: "airline" };

const [gridLoading, setGridLoading] = useState(false);

const [showReviewers, setShowReviewers] = useState(false);
const defaultAuthType = { label: "Application", value: "app" };
const { userProfile, userProfleLoading, fetchUserProfilekData } = useUserProfileManager();


const toggleReviewers = () => {
    setShowReviewers(!showReviewers);
};

useEffect(() => {
    
    loadData()

  }, [userProfile]);

  const loadData = async () => {

        // getHost(navigate)(id)
        // .then((result) => {

        // console.log(result)
        // if(result.success){

            userProfile.session.user["entityId"] = userProfile.session.user.userId;
            if(typeof userProfile.session.user.poNumber === "undefined" || userProfile.session.user.poNumber  == null || userProfile.session.user.poNumber  == " " ){
                userProfile.session.user.poNumber = " "
            }
            setLoadedFormData(userProfile.session.user)
            setAuthType(userProfile.session.user.authType)
        // }

        // })
        // .catch((error) => {

        //     console.log(error);

        // setGridLoading(false)
        // });
  };

    const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const registerSchema = yup.object().shape({
    lastname: yup.string().required("required"),
    firstname: yup.string().required("required"),

  });

  const initialValuesRegister = {
    lastname: "",
    firstname: "",
    email: "",
    poNumber: "",
    department: "",
    phone: "",
    mobile: ""
  };

  const [loadedFormData, setLoadedFormData] = useState(null);


  const handleSubmit = (values) => {

    loadedFormData["firstname"] = values.firstname
    loadedFormData["lastname"] = values.lastname
    loadedFormData["email"] = values.email
    loadedFormData["department"] = values.department
    loadedFormData["poNumber"] = values.poNumber
    loadedFormData["phone"] = values.phone
    loadedFormData["mobile"] = values.mobile


    var submitData= {}
    submitData["data"]= loadedFormData;

    putUserProfileHost(navigate)(submitData)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {
      
            if(result.success){
                //   setCopmanyName("- "+result.data.name);
                // setLoadedFormData(result.data)

                enqueueSnackbar({
                    message: "Success",
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });

            }

        }
      })
      .catch((error) => {
        
        
      });

  };

  const handlePhoneChange = (setFieldValue, fieldName) => (value) => {
    // const dialCode = MuiPhoneNumber.getDialCode(value);
    // const phoneNumber = value.replace(dialCode, '');
    // const fullPhoneNumber = `${dialCode}${phoneNumber}`;
    setFieldValue(fieldName, value);
  };

  // Render loading state if loadedFormData is null
  if (userProfleLoading && loadedFormData != null) {
    return <div>Loading...</div>;
  }


  return (

    <Box>
        <DialogTakePicture
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            onSave={(entity) => {
                    setConfirmationOpen(false);  
                    loadedFormData["picturePath"] = entity.data.picturePath; 
                    setLoadedFormData(loadedFormData);
                    fetchUserProfilekData();
                }}
            theme={theme}
            currentEntity ={loadedFormData}
            entityTypeParam={"host"}
        />
        <Box sx={{ ...commonStyles, border: 1 }}>
            
        <Box>
            {/* Top Row */}
            {/* <Box display="flex" alignItems="center" mb={1} ml={1} mt={3}>

                <Box ml={2}>
                <Typography variant="h3" fontWeight="bold" color={theme.palette.secondary[100]}>
                    General Information
                </Typography>
                <Typography variant="h5" color={theme.palette.secondary[300]} >
                    Choose a graphic to appear on your welcome screen in the background.
                </Typography>
                </Box>
            </Box> */}

            {/* Bottom Rows */}
            <Grid container spacing={2} style={{ backgroundColor: 'transparent', padding: "2px 0px 2px 0px" }} >
                {/* Left Column */}
                
                    <Grid item xs={isNonMobile ? 8 : 12} style={{ padding: 20, backgroundImage: 'transparent' }}>
                        <Paper elevation={3} style={{ padding: 20, backgroundImage: 'none',   boxShadow: 'none',  backgroundColor: 'transparent'}}>
                                        <Button
                                            variant="outlined"
                                            disableTouchRipple
                                            // onClick={handleAvatarClick}
                                            onClick={() => setConfirmationOpen(true)}

                                            sx={{
                                                position: 'relative',
                                                border: 0,
                                                "&:hover": { 
                                                    border: 0, 
                                                    color: theme.palette.secondary.light, 
                                                    backgroundImage: 'none',  
                                                    boxShadow: 'none', 
                                                    cursor: 'pointer',
                                                    backgroundColor: 'transparent', // Change background color to transparent
                                                }
                                            }}
                                        >
                                            <Avatar
                                                {...stringAvatar(loadedFormData && loadedFormData.fullName)} 
                                                sx={{ width: 250, height: 250, position: 'relative', zIndex: 0}} // Ensure Avatar is positioned relatively
                                                // src={getUri(getSessionToken())`/host/${loadedFormData.entityId}/picture/${loadedFormData.picturePath}`}
                                                src={loadedFormData && loadedFormData.fullName && loadedFormData.entityId && loadedFormData.picturePath ? `${getUri(getSessionToken())}host/${loadedFormData.entityId}/picture/${loadedFormData.picturePath}` : undefined}
                                                />
                                            {/* Semi-transparent layer for top part */}
                                        
                                            {/* Semi-transparent layer for bottom part */}
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    background: 'rgba(0, 0, 0, 0)',
                                                    zIndex: 1,
                                                    clipPath: 'ellipse(47% 50% at 50% 50%)',
                                                    WebkitClipPath: 'ellipse(45% 48% at 50% 50%)',
                                                    backgroundImage: 'linear-gradient(to bottom, transparent 80%, rgba(0,0,0,0.5) 60%)',
                                                    // backgroundClip: 'padding-box', // This property ensures that the background is clipped to the padding box
                                                }}
                                            />
                                            
                                            <Box sx={{ position: 'absolute', bottom: 3, left: '50%', transform: 'translateX(-50%)', zIndex: 2 }}>
                                                <CameraAlt sx={{ fontSize: '30px', color: theme.palette.secondary.light }} />
                                            </Box>
                                        </Button>
                    </Paper>
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={loadedFormData || initialValuesRegister}
                        validationSchema={registerSchema}
                        enableReinitialize={true}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            resetForm,
                            }) => (
                            <form onSubmit={handleSubmit}>
                        <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)'  }}
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                              }}
                            >
                        <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        width="100%"
                        m= "0rem 0rem 1.0rem 0rem"
                        >
                            <CssTextField
                                label="First Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                // value={loadedFormData?.name || values.name}
                                value={values.firstname} // Use values from Formik
                                name="name"
                                error={ Boolean(touched.firstname) && Boolean(errors.firstname)}
                                helperText={touched.firstname && errors.firstname}
                                sx={{ mb: 2,
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          border: 'none',
                                        },
                                      } 
                                    
                                    }} 
                                theme={theme} 
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <CssTextField
                                label="Last Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                // value={loadedFormData?.name || values.name}
                                value={values.lastname} // Use values from Formik
                                name="name"
                                error={ Boolean(touched.lastname) && Boolean(errors.lastname)}
                                helperText={touched.lastname && errors.lastname}
                                sx={{ mb: 2,
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          border: 'none',
                                        },
                                      } 
                                    
                                    }} 
                                theme={theme} 
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                width="100%"
                                m= "0rem 0rem 1.0rem 0rem"
                                >
                                <CssTextField
                                    label="Email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    // value={loadedFormData?.name || values.name}
                                    value={values.email} // Use values from Formik
                                    name="name"
                                    error={ Boolean(touched.email) && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                    sx={{ mb: 2,
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                            border: 'none',
                                            },
                                        } 
                                        
                                        }} 
                                    theme={theme} 
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <CssTextField
                                    label="Staff ID"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    // value={loadedFormData?.name || values.name}
                                    value={values.poNumber} // Use values from Formik
                                    name="name"
                                    error={ Boolean(touched.poNumber) && Boolean(errors.poNumber)}
                                    helperText={touched.poNumber && errors.poNumber}
                                    sx={{ mb: 2,
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                            border: 'none',
                                            },
                                        } 
                                        
                                        }} 
                                    theme={theme} 
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Box>
                            <CssTextField 
                                name="department" 
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.department} // Use values from Formik
                                label="Department" 
                                variant="outlined" 
                                fullWidth 
                                theme={theme} sx={{ mb: 2 }} />
                            <CssMuiPhoneNumber
                                defaultCountry={'us'}
                                preferredCountries={["us"]}
                                onBlur={handleBlur}
                                onChange={handlePhoneChange(setFieldValue, 'mobile')}
                                value={values.mobile}
                                label="Mobile"
                                variant="outlined"
                                fullWidth
                                theme={theme}
                                sx={{ mb: 2 }}
                                />
                            <CssMuiPhoneNumber
                                defaultCountry={'us'}
                                preferredCountries={["us"]}
                                onBlur={handleBlur}
                                onChange={handlePhoneChange(setFieldValue, 'phone')}
                                value={values.phone}
                                label="Phone"
                                variant="outlined"
                                fullWidth
                                theme={theme}
                                sx={{ mb: 2 }}
                                />

                            <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Button
                                fullWidth
                                startIcon={<Save />}
                                type="submit"
                                sx={{
                                    p: "1rem",
                                    backgroundColor: palette.secondary.light,
                                    color: palette.background.alt,
                                    fontWeight: "bold",
                                    "&:hover": {
                                    color: palette.primary.main,
                                    backgroundColor: palette.secondary.light,
                                    fontWeight: "bold",
                                    },
                                }}
                                >
                                Save
                                </Button>
                            </Grid>
                  
                            </Grid>
                            {/* Add more text fields as needed */}
                        </Paper>
                            </form>
                        )}
                    </Formik>

                </Grid>

                {/* Right Column */}
                <Grid item xs={6} sx={{ backgroundColor: 'transparent', backgroundImage: 'none',}}>
                   
                   
                </Grid>
            </Grid>
            
        </Box>
        {/*     */}
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >


            </Box>

        </Box>

        {/*  -------------------------------   */}

        <ProfileChangeUserPassword />

        {/*  -------------------------------   */}


                

    </Box>
  );
};

export default ProfileDetailGeneral;
