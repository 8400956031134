import React, { useEffect, useState } from "react";
import {  Box, Typography, useTheme } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Header from "components/Header";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import WelcomeScreenDesign from "scenes/visitors/settings/welcomescreen/welcomescreenDesign";
import { getCompany } from "hooks/HttpRequests";
import CompanyDetailGeneral from "./details/companyDetailGeneral";
import CompanyDocuments from "./details/companyDocuments";
import CompanyEmployees from "./details/companyEmployees";


function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CompanyDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [copmanyName, setCopmanyName] = useState("");

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [visitorTypes, updateVisitorTypes] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);
  const { id } = useParams(); // Accessing the id parameter from the URL

  useEffect(() => {
    
    getCompany(navigate)(id)
    .then((result) => {

      console.log(result)
      if(result.success){
          setCopmanyName("- "+result.data.name);
      }
      // setData(result)
      // setGridLoading(false)

    })
    .catch((error) => {

      console.log(error);

      setGridLoading(false)
    });

  }, [id]);

  

  const tabs = [
    { label: 'General', path: '/general', id: "tabCopmayGeneral" },
    { label: 'Authorized Signatories', path: '/signatories', id: "tabCopmaySignatories" },
    { label: 'Badging', path: '/badging' , id: "tabCopmayBadging"},
    { label: 'Employees', path: '/employees' , id: "tabCopmayEmployees"},
    { label: 'History', path: '/history' , id: "tabCopmayHistory"},
    { label: 'Documents', path: '/documents' , id: "tabDocuments"},

  ];

  const currentPath = location.pathname;
  const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
  const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;

  const [value, setValue] = useState(selectedTabIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTab = tabs[newValue];
    navigate(`/identity/company/${id}${selectedTab.path}`);
  };

  useEffect(() => {
    const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
    const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;
    setValue(selectedTabIndex);
  }, [currentPath, tabs]);

  return (
    <Box m="0rem 2rem">
      <Header title={`Company Details ${copmanyName}`} subtitle="" elements={null} backbutton={"/identity/company"} />
      {/* <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        theme={theme}
      /> */}
      <Box mt="10px" height="85vh" sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiDataGrid-panel .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
         "& .MuiDataGrid-panelWrapper .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiTab-root.MuiTab-textColorPrimary.Mui-selected": {
            color: `${theme.palette.secondary[200]} !important`,
            fontWeight: 'bold'
          },
          
        }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.id === 'tabCopmayGeneral' && (
              <CompanyDetailGeneral
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                // theme={theme}
              />
            )}
            {tab.id === 'tabCopmaySignatories' && (
              // <Typography>This is the Sign-In Workflow tab content</Typography>
              
              <CompanyDetailGeneral
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={3}
                // theme={theme}
              />

            )}
            {tab.id === 'tabCopmayEmployees' && (
              // <Typography>This is the Security tab content</Typography>
              <CompanyEmployees
                visitorType="Employees"
                description="All employees of this company"
                globalType={true}
                navigate={navigate}
                id={3}
                // theme={theme}
              />
            )}
            {tab.id === 'tabCopmayBadging' && (
              // <Typography>This is the Security tab content</Typography>
              <WelcomeScreenDesign
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={3}
                // theme={theme}
              />
            )}
            {tab.id === 'tabCopmayHistory' && (
              // <Typography>This is the Security tab content</Typography>
              <WelcomeScreenDesign
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={3}
                // theme={theme}
              />
            )}
            {tab.id === 'tabDocuments' && (
              // <Typography>This is the Security tab content</Typography>
              <CompanyDocuments
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={3}
                // theme={theme}
              />
            )}
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default CompanyDetail;

