import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, useTheme } from "@mui/material";
import React, { useEffect } from 'react'
import { useState } from "react";
import { assigneBadge } from "hooks/HttpRequests";
import { CssTextFieldReverted } from "components/StyledComponents";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";


const DialogAssignBadge = ({ open, onClose, onSave, entityTypeParam, currentEntity  }) => {

  const theme = useTheme();

  const [entityType, setEntityType] = useState(entityTypeParam);
  const [badgeIdInput, setBadgeIdInput] = useState("");
  const { selectedFrontDesk, frontDeskLoading, selectedFrontDeskTerminalSettings } = useFrontDeskManager();


  const [validFromDayJS, setValidFromDayJS] = useState(dayjs().set("hour", 9).startOf("hour"));
  const [validToDayJS, setValidToDayJS] = useState(dayjs().set("hour", 12).add(1, 'year'));

  useEffect(() => {

    if(currentEntity != null){

      
      
    }
    

  }, [open]);

  const handleInputChange = (e) => {

    // Update the state based on the previous state
    setBadgeIdInput(e.target.value);
    // // Perform other actions if needed
    // loadGridData()

  };

  ///////////////////////////////



  const handleSave = () => {

    var values = {}

    var entityId = 0

    if(entityType == "visitor"){
        entityId = currentEntity.visitorId;
    }else{
        entityId = currentEntity.entityId;
    }

    values["visit_id"] = 36;

    values["card_id"] = badgeIdInput;


    values["key_name"] = entityType;
    values["table_name"] = entityType;
    values["entity_id_pointer"] = entityId;
    values["front_desk_entity_id"] = selectedFrontDesk.entityId;

    assigneBadge()(values)
    .then((result) => {
      // Use the result.data as needed
      if (result.success) {

          onClose();
      }
        
      //   var user = {};

      //   onSubmitProps.resetForm();
      //   dispatch(hideLoading());



      //     dispatch(
      //       setLogin({
      //         user: user,
      //         token: result.session_id,
      //       })
      //     );


          

      //     fetchFrontDeskData(); // Call this function whenever you want to trigger a reload of data

      //     fetchMenuData();

      //     navigate("/dashboard");
          

      // }else{
      //   enqueueSnackbar('We have a problem!',{ 
      //     variant: 'error', anchorOrigin: {
      //       vertical: 'top', // 'top', 'bottom'
      //       horizontal: 'center', // 'left', 'center', 'right'
      //     },
      //     autoHideDuration: 5000, // 5000 milliseconds = 5 seconds
      //     // persist: true
      //   })

      // }

      
      // dispatch(hideLoading());

    })
    .catch((error) => {
      // if (typeof error.action !== "undefined") {
      //   if (error.action == "relogin") {
      //     navigate("/");
      //   }
      // }
      // if (typeof error.userMessage !== "undefined") {
      //   enqueueSnackbar({
      //     message: error.userMessage,
      //       variant: 'error',
      //       anchorOrigin: {
      //         vertical: 'top', // 'top', 'bottom'
      //         horizontal: 'center', // 'left', 'center', 'right'
      //       },
      //       autoHideDuration: 3000, // 5000 milliseconds = 5 seconds
      //       // persist: true
          
      //   });
      // }
      // dispatch(hideLoading());

    });

      // if((readFile != null && readFile != "") || pressedRemovePicture){
      //   // const file = selectedFile

      //   // // Create FormData object
      //   // const formData = new FormData();
      //   // formData.append('file', file);

      //   var base64Image = ""

      //   if(readFile != null && readFile != ""){
      //       base64Image = readFile;
      //       if(readFile.indexOf("base64,") > 0){
      //         base64Image = readFile.substr(readFile.indexOf("base64,")+7);
      //       }
      //   }

      //   var data = {};
      //   data["entityId"] = currentEntity.entityId;
      //   data["fileType"] = "png";
      //   data["picture"] = base64Image;

      //   // Send the file to the server
      //   axios.post(getUri(getSessionToken())+'/'+entityType+'/picture', data)
      //     .then((response) => {
      //       // File upload successful
      //       console.log(response.data);
      //       onSave(response.data);
      //     })
      //     .catch((error) => {
      //       // File upload failed
      //       console.log(error);
      //   });
      // }else{
      //   onClose();
      // }
  }

  const handleClose = () => {

      // onClearImage()
      onClose();
  }



  const handleValidFromDateChange = (item) => {
    setValidFromDayJS(item)
    }
    
    const handleValidToDateChange = (item) => {
    setValidToDayJS(item)
    }


  return (
    <Dialog open={open} onClose={onClose}>
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>Assign Access Card</DialogTitle>
        <DialogContent>

        <Grid container spacing={2} style={{ backgroundColor: 'transparent', padding: "2px 0px 2px 0px" }} >
          {/* Left Column */}
          {/* <Grid item xs={6} style={{ padding: 20, backgroundImage: 'transparent' }}> */}
            <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none'  }}>
            <CssTextFieldReverted
                    label={"Type Badge ID"}
                    onChange={handleInputChange}
                    sx={{ mr: 2 }}
                    theme={theme}
                />
                <DateTimePicker onChange={handleValidFromDateChange}
                                defaultValue={validFromDayJS}
                                label="Valid From"
                                sx={{ padding: "5px 5px" }} 
                                />
                <DateTimePicker onChange={handleValidToDateChange}
                                defaultValue={validToDayJS}
                                label="Valid To" 
                                sx={{ padding: "5px 5px" }} 
                                />
                 
            </Paper>
          {/* </Grid> */}

          {/* Right Column */}
          {/* <Grid item xs={6} sx={{ backgroundColor: 'transparent', backgroundImage: 'none',}}>
            <Paper elevation={3} style={{ padding: 20, backgroundImage: 'none',   boxShadow: 'none',  backgroundColor: 'transparent'}}>
              <Box sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary, p: "1rem 1rem 1rem 1rem", border: 1, borderRadius: 1, borderColor: theme.palette.primary.dark }}>

                  <div>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={onFileChange}
                    />
                    <label htmlFor="fileInput">
                        <Button
                            variant="contained"
                            component="span"
                            fullWidth
                        >
                            SELECT FILE
                        </Button>
                    </label>
                  </div>
                  <Typography
                    variant="h6"
                    color={theme.palette.primary[600]}
                    fontWeight="bold"
                    mt="3rem"
                    >
                      {selectedFile ? `${selectedFile.name}` : 'No file selected'}
                  </Typography>
                </Box>
                <Divider style={{ margin: '20px 0', height: '1px', backgroundColor: theme.palette.primary.dark }} />
                  <Box sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary, p: "1rem 1rem 1rem 1rem", border: 1, borderRadius: 1, borderColor: theme.palette.primary.dark }}>
                    <Typography
                      variant="h6"
                      color={theme.palette.primary[600]}
                      fontWeight="bold"
                      mt="3rem"
                      >
                        Take a picture with your connected camera
                        {webcamError && <div style={{ color: 'red' }}>Error: {webcamError.message}</div>}
                    </Typography>
                        <Button onClick={activateTakePicture} color="primary" fullWidth autoFocus sx={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.text.primary }}  >
                        Activate Camera
                    </Button>
                  </Box>
              </Paper>
          </Grid> */}
        </Grid>

          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {/* Are you sure you want to execute this action? */}
        
          </DialogContentText>
        </DialogContent>
        <DialogActions >  

            <Box>
                <Button onClick={handleClose} color="primary" sx={{ color: theme.palette.background.alt }}>
                  Cancel
                </Button>
                <Button onClick={handleSave} color="primary" autoFocus sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
                  Assign Access Card
                </Button>
            </Box>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogAssignBadge