import {  CameraAlt } from "@mui/icons-material";
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useRef } from 'react'
import { useState } from "react";
import Webcam from "react-webcam";
import { getSessionToken, getUri } from "hooks/HttpRequests";
import tm from 'components/TranslationManager';


const DialogTakePicture = ({ open, onClose, onSave, theme, currentEntity, entityTypeParam, visitId = -1  }) => {

  const [picuteFromEntity, setPictureFromEntity] = useState("");
  const [entityType, setEntityType] = useState(entityTypeParam);



  useEffect(() => {
    
    setReadFile(null)
    setSelectedFile(null)
    setTakePictureMode(false);

    var localEntityId = "host";

    if(typeof entityTypeParam !== "undefined"){
      localEntityId = entityTypeParam;
    }

    if(typeof currentEntity !== "undefined" && currentEntity != null){
      if (currentEntity.hasOwnProperty('visitorId')) {
          currentEntity["entityId"] = currentEntity.visitorId;
          localEntityId = "visitor"
      }

      setEntityType(localEntityId);

      if(currentEntity.picturePath != null && currentEntity.picturePath != ""){

        setReadFile(getUri(getSessionToken())+'/'+localEntityId+'/'+currentEntity.entityId+'/picture/'+currentEntity.picturePath)
        setPictureFromEntity(getUri(getSessionToken())+'/'+localEntityId+'/'+currentEntity.entityId+'/picture/'+currentEntity.picturePath)
      }
    }
    

  }, [open]);

  ///////////////////////////////

  const webcamRef = useRef(null);
  const [webcamError, setWebcamError] = useState(null);

  const capture = useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setReadFile(imageSrc)
      setTakePictureMode(false);

    },
    [webcamRef]
  );

  const onCameraError = (error) => {
      setWebcamError(error)
      setTakePictureMode(!takePictureMode);
  }

  ///////////////////////////////
  const [selectedFile, setSelectedFile] = useState(null);
  const [readFile, setReadFile] = useState(null);
  const [takePictureMode, setTakePictureMode] = useState(false);
  const [pressedRemovePicture, setPressedRemovePicture] = useState(false);


  // file uploade

  const onFileChange = event => {

    const file = event.target.files[0];

    if( typeof file === "undefined"){
      return
    }

    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      alert('Please select a PNG or JPEG image file.');
      return;
    }

    if (file.size > 1024 * 1024) {
      alert('File size should not exceed 1MB.');
      return;
    }

    setSelectedFile(file)

    const reader = new FileReader();
        reader.onload = () => {
          setReadFile(reader.result);
        };
    reader.readAsDataURL(file);

  };

  const handleSave = () => {

      if((readFile != null && readFile != "") || pressedRemovePicture){
        // const file = selectedFile

        // // Create FormData object
        // const formData = new FormData();
        // formData.append('file', file);

        var base64Image = ""

        if(readFile != null && readFile != ""){
            base64Image = readFile;
            if(readFile.indexOf("base64,") > 0){
              base64Image = readFile.substr(readFile.indexOf("base64,")+7);
            }
        }

        var data = {};
        data["entityId"] = currentEntity.entityId;
        data["fileType"] = "png";
        data["picture"] = base64Image;

        if(visitId > 0){
          data["visitId"] = base64Image;
        }

        // Send the file to the server
        axios.post(getUri(getSessionToken())+'/'+entityType+'/picture', data)
          .then((response) => {
            // File upload successful
            console.log(response.data);
            onSave(response.data);
          })
          .catch((error) => {
            // File upload failed
            console.log(error);
        });
      }else{
        onClose();
      }
  }

  const handleClose = () => {

      onClearImage()
      onClose();
  }

  const onClearImage = () => {

    setReadFile(null)
    setSelectedFile(null)
    setTakePictureMode(false);

    setPressedRemovePicture(true);

    // setReadFile(picuteFromEntity)
    // setPictureFromEntity(picuteFromEntity)

  }

  const activateTakePicture = () => {
    setTakePictureMode(!takePictureMode);
    if(!takePictureMode){
      setReadFile(null)
      setSelectedFile(null)
    }
  }


  return (
    <Dialog open={open} onClose={onClose}>
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>{tm.translate('lblUploadPicture', "Upload Picture")}</DialogTitle>
        <DialogContent>

        <Grid container spacing={2} style={{ backgroundColor: 'transparent', padding: "2px 0px 2px 0px" }} >
          {/* Left Column */}
          <Grid item xs={6} style={{ padding: 20, backgroundImage: 'transparent' }}>
            <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none'  }}>
            {takePictureMode ? (
              <>
                <Webcam
                    audio={false}
                    width={250}
                    height={250}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{
                      width: 250,
                      height: 250,
                      facingMode: "user"
                    }}
                    onUserMediaError={onCameraError}
                  />
                  <Button onClick={capture} color="primary" fullWidth autoFocus sx={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.text.primary }}  startIcon={<CameraAlt />} >
                      {tm.translate('btnTakePicture', "TAKE PICTURE")} 
                  </Button>
                </>
              ) : (
                  <Avatar
                    sx={{ width: 250, height: 250, position: 'relative', zIndex: 0 }} // Ensure Avatar is positioned relatively
                    src={readFile}
                />
              )}
                
                 
            </Paper>
          </Grid>

          {/* Right Column */}
          <Grid item xs={6} sx={{ backgroundColor: 'transparent', backgroundImage: 'none',}}>
            <Paper elevation={3} style={{ padding: 20, backgroundImage: 'none',   boxShadow: 'none',  backgroundColor: 'transparent'}}>
              <Box sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary, p: "1rem 1rem 1rem 1rem", border: 1, borderRadius: 1, borderColor: theme.palette.primary.dark }}>

                  <div>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={onFileChange}
                    />
                    <label htmlFor="fileInput">
                        <Button
                            variant="contained"
                            component="span"
                            fullWidth
                        >
                            {tm.translate('lblSelectFile', "SELECT FILE")}
                        </Button>
                    </label>
                  </div>
                  <Typography
                    variant="h6"
                    color={theme.palette.primary[600]}
                    fontWeight="bold"
                    mt="3rem"
                    >
                    {selectedFile ? `${selectedFile.name}` : tm.translate('lblNoFileSelect', "No file selected")}
                  </Typography>
                </Box>
                <Divider style={{ margin: '20px 0', height: '1px', backgroundColor: theme.palette.primary.dark }} />
                  <Box sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary, p: "1rem 1rem 1rem 1rem", border: 1, borderRadius: 1, borderColor: theme.palette.primary.dark }}>
                    <Typography
                      variant="h6"
                      color={theme.palette.primary[600]}
                      fontWeight="bold"
                      mt="3rem"
                      >
                        {tm.translate('lblTakePicture', "Take a picture with your connected camera")}
                        {webcamError && <div style={{ color: 'red' }}>Error: {webcamError.message}</div>}
                    </Typography>
                        <Button onClick={activateTakePicture} color="primary" fullWidth autoFocus sx={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.text.primary }}  >
                        {tm.translate('lblActivateCamera', "Activate Camera")}
                    </Button>
                  </Box>
              </Paper>
          </Grid>
        </Grid>

          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {/* Are you sure you want to execute this action? */}
        
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>  
          <Button onClick={onClearImage} color="primary">{tm.translate('btnDeletePicture', "Delete Picture")}</Button>

          <div>
            <Button onClick={handleClose} color="primary" sx={{ color: theme.palette.background.alt }}>
              {tm.translate('btnCancel', "Cancel")}
            </Button>
            <Button onClick={handleSave} color="primary" autoFocus sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
              {tm.translate('btnSave', "Save")}
            </Button>
          </div>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogTakePicture