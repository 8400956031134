

export const sadevioColors = {

    //visitor statys
    hexColorInvited: "#5fa2dd",
    hexColorWaiting: "#ffc107",
    hexColorCheckedIn: "#4caf50",
    hexColorCheckedOut: "#e91e63",
    hexColorConfirmed: "#9b59b6",
    hexColorRejected: "#D32F2F",
    hexColorUnderReview: "#D32F2F",
    hexColorDenied: "#FF4C4C",
    hexColorOk: "#4caf50",
    hexColorGray: "#8B8F9D",

    // visit statys
    hexColorVisitInvited: "#1E90FF",
    hexColorVisitStarted: "#FFA500",
    hexColorVisitFinished: "#28A745",
    hexColorVisitCanceled: "#DC3545",
    hexColorVisitNoShow: "#6C757D",
    hexColorVisitExpired: "#6C757D",

    hexColorKioskGreen: "#28A745",
    hexColorKioskOrange: "#FFA500",
    hexColorKioskRed: "#DC3545",
    hexColorKioskGray: "#6C757D"
  };