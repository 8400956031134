import React, { useEffect, useState } from "react";
import FlexBetween from "components/FlexBetween";
import { useNavigate } from "react-router-dom";
import {
  Clear,
  Badge,
  DoorSliding,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Avatar,
  Link,
  List,
  ListItem,
  ListItemButton,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getAssignedDoorLevels, getBadgeProcessingStatus, getSessionToken, getUri, unAssigneBadge, unassigneBadgeDoorLevels } from "hooks/HttpRequests";
import {  stringAvatar } from "components/StyledComponents";
import tm from "components/TranslationManager";
import GridCellVisitorStatus from "components/GridCellVisitorStatus";
import dayjs from "dayjs";
import DialogAssignBadgeOld from "components/badge/DialogAssignBadgeOld";
import DialogYesNo from "components/dialog/DialogYesNo";
import DialogAssignDoorLevelOld from "./DialogAssignDoorLevelOld";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { enqueueSnackbar } from "notistack";


const BadgeHandlingOld = ({ isDialog = false, currentEntity, entityTypeParam, visitInformation = null, onSave}) => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [entityId, setEntityId] = useState(-1);
  const [currentEntityParam, setCurrentEntityParam] = useState(currentEntity);
  const [visitInformationParam, setVisitInformationParam] = useState(visitInformation);

  const [searchBadge, setSearchBadge] = useState("");
  const [selectedBadgeItem, setSelectedBadgeItem] = useState(null);
  const [selectedBadgeItemIndex, setSelectedBadgeItemIndex] = useState(null);

  
  const [loadedFormData, setLoadedFormData] = useState({});

  useEffect(() => {
    
    if(entityTypeParam == "visitor"){
      setEntityId(currentEntity.visitorId);
      setCurrentEntityParam(currentEntity);
      setVisitInformationParam(visitInformation)

    }else{
      setEntityId(currentEntity.entityId);
    }

  }, []);

  const [entityType, setEntityTypeParam] = useState(entityTypeParam);

  const [triggerLoad, setTriggerLoad] = useState(false);

  const handleTriggerLoad = () => {
    setTriggerLoad(!triggerLoad);
  };

  useEffect(() => {
    
    // This code will run every time paginationModel changes
    // loadAssignedBadges();

    if(currentEntity.accessCardId != ""){

        setAssignedBadges([])
        
        var assignCard = []

        var card = {}
        card["cardId"] = currentEntity.accessCardId 

        assignCard.push(card)

        setAssignedBadges(assignCard)


        handleListItemClick(0);

        setAssignedDoorLevels([])

        var assignAccessLevels = []

        const accessLevelsArray = currentEntity.accessLevels.split(";");

        for (const level of accessLevelsArray) {

          if(level != ""){
            var accesslevel = {}
            accesslevel["accessProfileId"] = level
            assignAccessLevels.push(accesslevel)
          }
          
        }

        setAssignedDoorLevels(assignAccessLevels)

    }

  }, [entityId, triggerLoad]);

  ////////////////////////////////////////////////
  // ASSIGN BADGE PART
  ////////////////////////////////////////////////
  const [openDialogAssigneBadge, setOpenDialogAssigneBadge] = useState(false);
  const [onConfirmDeleteBadgeOpen, setOnConfirmDeleteBadgeOpen] = useState(false);

  ////////////////////////////////////////////////
  // ASSIGN DOOR LEVEL PART
  ////////////////////////////////////////////////
  const [openDialogAssigneDoorLevel, setOpenDialogAssigneDoorLevel] = useState(false);
  const [onConfirmDeleteDoorLevel, setOnConfirmDeleteDoorLevel] = useState(false);

  const [doorLevelToRemove, setDoorLevelToRemove] = useState(null);

  const defaultBadgeStatus = { label: 'Active', value: "active" };

  const defaultBadgeType = { label: 'Default', value: "default" };

  const [validFromDayJS, setValidFromDayJS] = useState(dayjs().set("hour", 9).startOf("hour"));
  const [validToDayJS, setValidToDayJS] = useState(dayjs().set("hour", 12).add(1, 'year'));



  ////////////////////////////////////////////////
  // ACCESS CARD HISTORY PART
  ////////////////////////////////////////////////

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [],
    query:[]
  })
  const [selectedHosts, setSelectedHosts] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [assignedBadges, setAssignedBadges] = useState([]);

  const [assignedDoorLevels, setAssignedDoorLevels] = useState([]);
  const [doorLevelsLoading, setDoorLevelsLoading] = useState(false);

  const columns = [
    {
      field: "entityId",
      headerName: "Entity Id",
      flex: 1,
      type: 'number'
    },
    {
      field: 'picturePath',
      headerName: 'Picture',
      width: 100,
      renderCell: (params) => (
        params.row.picturePath != null ? (
          <Avatar {...stringAvatar(params.row.fullName)} alt={params.row.fullName} src={`${getUri(getSessionToken())}/host/${params.row.entityId}/picture/${params.row.picturePath}`}          />

        ) : (
          <Avatar {...stringAvatar(params.row.fullName)} />
        )
      ),
    },   
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/employees/${params.row.entityId}`)
          }}
        >
        {params.row.fullName}
      </Link>
      ),
    },
    {
      field: "visitStatus",
      headerName: tm.translate('clmVisitStatus', "Visit Status") ,
      flex: "auto",
      minWidth: 120,
    },
    {
      field: "visitorStatus",
      headerName: tm.translate('clmVisitorStatus', "Visitor Status"),
      minWidth: 220,
      renderCell: (params) => (
        <GridCellVisitorStatus rowData={params.row} />
      ),
    },
  ];

  useEffect(() => {
    
    if(assignedBadges.length > 0){
      // loadAssignedDoorLevels(assignedBadges[selectedBadgeItemIndex].entityId);
    }

  }, [selectedBadgeItemIndex]);

  const loadAssignedDoorLevels = async (entityId) => {

    setDoorLevelsLoading(true)

    getAssignedDoorLevels(navigate)( entityId)
      .then((result) => {

        setAssignedDoorLevels(result.data)
        if(result.total_count > 0){
          // setSelectedBadgeItemIndex(0);
          // setSelectedBadgeItem(result.data[0]);
        }
        setDoorLevelsLoading(false)

      })
      .catch((error) => {

        console.log(error);

        setDoorLevelsLoading(false)
      });

  }

  const handleListItemClick = (index) => {
      
    setSelectedBadgeItemIndex(index);
    var tmpCard = assignedBadges[index];
    setSelectedBadgeItem(assignedBadges[index])

    // loadAssignedDoorLevels(tmpCard.entityId)

  };


  const handleExecuteDeleteDoorLevel = (doorLevel) => {

    const { index, value } = doorLevel; // Extract the index and value
    const updatedDoorLevels = assignedDoorLevels.filter((_, i) => i !== index); // Remove the item by index
  
    const updatedDoorLevelsString = updatedDoorLevels
    .map((level) => level.accessProfileId?.trim()) // Get the `name` and trim whitespace
    .filter((accessProfileId) => accessProfileId) // Remove empty or undefined `name` values
    .join(";"); // Join the names with `;`

    var values = {}

    values["accessLevels"] = updatedDoorLevelsString;


    unassigneBadgeDoorLevels(navigate)( visitInformation.visitId, currentEntity.visitorId, values )
      .then((result) => {

        // handleTriggerLoad();
        setOnConfirmDeleteDoorLevel(false)

        setAssignedDoorLevels(updatedDoorLevels)

        var updatedDoorLevelsString = updatedDoorLevels
        .map((level) => level.accessProfileId?.trim()) // Get the `name` and trim whitespace
        .filter((accessProfileId) => accessProfileId) // Remove empty or undefined `name` values
        .join(";"); 

        currentEntity.accessLevels = updatedDoorLevelsString
        enqueueSnackbar({
          message: "Door levels updated",
            variant: 'success',
            anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
            },
            autoHideDuration: 3000, 
            // persist: true
          
        });

      })
      .catch((error) => {

        console.log(error);

        setDoorLevelsLoading(false)
      });

  };


  const handleExecuteDeleteBadge = () => {

    var values = {}

    let assignAccessLevelsArray = "";


    const defaultProfiles = assignedDoorLevels
      .map((level) => level.accessProfileId);

    // Join the IDs with `;`
    assignAccessLevelsArray = defaultProfiles.join(";");
    

    values["access_card"] = assignedBadges[0].cardId;
    values["access_levels"] = assignAccessLevelsArray;
    values["visit_id"] = visitInformation.visitId;
    values["visitor_id"] = currentEntity.visitorId;
    values["self_check_in"] = false;

    

    unAssigneBadge()(values)
    .then((result) => {
      // Use the result.data as needed
      if (result.success) {

        getProcessingStatus(0, result.data.entity_id);
      }else{
          enqueueSnackbar({
              message: result.message,
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "center" },
              autoHideDuration: 3000,
            });
        }
      

    })
    .catch((error) => {
      if (typeof error.action !== "undefined") {
        if (error.action == "relogin") {
          // navigate("/");
        }
      }else if (error.hasOwnProperty('success')) {
        enqueueSnackbar({
          message: "Error "+error.message,
            variant: 'error',
            anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
            },
            autoHideDuration: 4000, 
            // persist: true
          
        });
      }
      if (typeof error.userMessage !== "undefined") {
        enqueueSnackbar({
          message: error.userMessage,
            variant: 'error',
            anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
            },
            autoHideDuration: 4000, // 5000 milliseconds = 5 seconds
            // persist: true
          
        });
      }
      dispatch(hideLoading());

    });

  }

  const getProcessingStatus = (n, entityId) => {

      var endTime = 0;

      if(n == 0){
        dispatch(showLoading(""));
        var t = new Date();
        t.setSeconds(t.getSeconds() + 20);
        endTime = t.getTime();
      }else{
          endTime = n
      }
      
          var now = new Date();

          if(now.getTime() < endTime){
    
            getBadgeProcessingStatus()(visitInformation.visitId, entityId)
            .then((result) => {
              // Use the result.data as needed
              if (result.success) {
        
                if(result.data != null && result.data.entityId > 0){

                  if(result.data.processingStatus.toLowerCase() === "finished"){

                    var accessSystemResponse = JSON.parse(result.data.processingData);
                            
                    if(accessSystemResponse != null && accessSystemResponse.success){

                      if(result.data.action.toLowerCase() === 'assign'){

                        dispatch(hideLoading());
                        setOnConfirmDeleteBadgeOpen(false)
                        setAssignedBadges([])
                        setAssignedDoorLevels([])
                        //was assigned
                        // onClose();

                      }else if(result.data.action.toLowerCase() === 'unassign'){

                        dispatch(hideLoading());
                        setOnConfirmDeleteBadgeOpen(false)
                        setAssignedBadges([])
                        setAssignedDoorLevels([])
                        onSave();

                      }else{
                        //something wrong
                        dispatch(hideLoading());

                      }
                      
                    }else{
                      //something wrong
                      dispatch(hideLoading());
                      enqueueSnackbar({
                            message: `${accessSystemResponse.error_code} - ${accessSystemResponse.message}`,
                              variant: 'error',
                              anchorOrigin: {
                                vertical: 'top', // 'top', 'bottom'
                                horizontal: 'center', // 'left', 'center', 'right'
                              },
                              autoHideDuration: 3000, // 5000 milliseconds = 5 seconds
                              // persist: true
                            
                          });

                    }
                  }else{
                      //wait a second and then call function again

                      setTimeout(() => getProcessingStatus(endTime, entityId), 1000);
                    }

                }else{
                  // Something went wrong
                  dispatch(hideLoading());

                }
                  // onClose();
              }else{
                dispatch(hideLoading());

              }
              
        
            })
            .catch((error) => {
              // if (typeof error.action !== "undefined") {
              //   if (error.action == "relogin") {
              //     navigate("/");
              //   }
              // }
              // if (typeof error.userMessage !== "undefined") {
              //   enqueueSnackbar({
              //     message: error.userMessage,
              //       variant: 'error',
              //       anchorOrigin: {
              //         vertical: 'top', // 'top', 'bottom'
              //         horizontal: 'center', // 'left', 'center', 'right'
              //       },
              //       autoHideDuration: 3000, // 5000 milliseconds = 5 seconds
              //       // persist: true
                  
              //   });
              // }
              // dispatch(hideLoading());
        
            });

          }else{
            // msg : 'Access system took to long \n',
            dispatch(hideLoading());
            enqueueSnackbar({
              message: "Access System did not finish in time.",
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "center" },
              autoHideDuration: 3000,
            });
          }
  }

  const onSaveAssignDoorLevel = (entities) => {

    setAssignedDoorLevels((prevAssignedDoorLevels) => {
      // Convert entities string into an array of accessProfileId values
      const newAccessProfileIds = entities
        .split(";")
        .map((id) => id.trim()) // Remove any extra spaces
        .filter((id) => id); // Remove empty values
  
      // Create a Set of existing accessProfileIds to avoid duplicates
      const existingAccessProfileIds = new Set(
        prevAssignedDoorLevels.map((item) => item.accessProfileId)
      );
  
      // Filter out accessProfileIds that are already in the assignedDoorLevels
      const newDoorLevels = newAccessProfileIds
        .filter((id) => !existingAccessProfileIds.has(id))
        .map((id) => ({ accessProfileId: id, name: `Door Level ${id}` })); // Create new objects (add appropriate name logic if needed)
  
      // Merge the new door levels with the existing ones
      return [...prevAssignedDoorLevels, ...newDoorLevels];
    });

    currentEntity.accessLevels = entities;
  
    // Close the dialog
    setOpenDialogAssigneDoorLevel(false);

    handleTriggerLoad();
  };  
  
  const onSaveAssignBadge = (entity) => {


    setAssignedBadges([])
    setAssignedDoorLevels([])


    var access_card = entity.access_card
    var access_levels = entity.access_levels
    currentEntity.accessCardId = access_card;
    currentEntity.accessLevels = access_levels;
  
    // Close the dialog
    setOpenDialogAssigneBadge(false);

    handleTriggerLoad();
  }; 
  
  return (
    <Box m="0.5rem 0.5rem">

        <DialogAssignBadgeOld
            open={openDialogAssigneBadge}
            onClose={(entity) => { 
                            handleTriggerLoad();
                            setOpenDialogAssigneBadge(false)
                              }}
            onSave={(entity) => { 
                             onSaveAssignBadge(entity);

                          }}
            entityTypeParam={entityType}
            currentEntity ={currentEntityParam}
            visitInformation = {visitInformationParam}
        />
        <DialogAssignDoorLevelOld
            open={openDialogAssigneDoorLevel}
            onClose={(entity) =>  { handleTriggerLoad();
                                    setOpenDialogAssigneDoorLevel(false)}}
            onSave={(entity) => { 
                                    onSaveAssignDoorLevel(entity);
                                }}
            theme={theme}
            entityTypeParam={entityType}
            currentEntity ={currentEntityParam}
            cardInformation ={selectedBadgeItem}
            visitInformation = {visitInformationParam}
        />
        <DialogYesNo
          open={onConfirmDeleteBadgeOpen}
          onClose={() => setOnConfirmDeleteBadgeOpen(false)}
          onConfirm={() => handleExecuteDeleteBadge()}
          theme={theme}
          message={tm.translate('msgUnassignBadge', "Do you want to unassign the badge?")}
        />
        <DialogYesNo
          open={onConfirmDeleteDoorLevel}
          onClose={() => setOnConfirmDeleteDoorLevel(false)}
          onConfirm={handleExecuteDeleteDoorLevel}
          theme={theme}
          message={tm.translate('msgUnassignDoorLevel', "Do you want to unassign the door level?")}
          entity={doorLevelToRemove}
        />

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        

        {/* --- LEFT START ---*/}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
          display="flex"
          flexDirection="column"
        >

          <Box
              display="flex"
              flexDirection="column"
              sx={{ 
              m: "0rem 0rem 0rem 0rem",
              minHeight: "60px",
              borderRadius: 1,
              borderColor: theme.palette.secondary[100],
              border: 0 , p: 2,
              backgroundColor:theme.palette.background.alt,
            }}> 
        <FlexBetween>
          {assignedBadges.length < 1 && (
            <Button
                variant="contained"
                onClick={() => setOpenDialogAssigneBadge(true)}
                sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                "&:hover": { color: theme.palette.secondary.dark },
                }}
            >
              {tm.translate('btnAddBadge', "Add Badge")}
            </Button>)}
            {/* <Box>
                <CssTextField
                    label={"Serach Badge"}
                //   onChange={elemet.onChange}
                    sx={{ mr: 2 }}
                    InputProps={{
                    endAdornment: (
                        <Search />
                    ),
                    }}
                    theme={theme}
                />
            </Box> */}
        </FlexBetween>
        </Box>
      
      {
        gridLoading ? (
            "Loading..."
        ) : (
            assignedBadges && assignedBadges.length > 0 ? (
                <List dense sx={{ 
                    gridColumn: "span 4" , 
                    // bgcolor: 'background.paper',
                    margin: '15px 0px 0px 0px',
                    overflowY: 'auto', // Add this to enable vertical scrolling
                    // maxHeight: '320px', // Set a maximum height for the container
                    flex:1
                    
                }}>
                    {assignedBadges.map((value, index) => {
                        const labelId = `checkbox-list-secondary-label-${index}`;
                        const isLoggedInUser = typeof value.isLoggedInUser !== "undefined" && value.isLoggedInUser;
                        const handlePrint = () => {
                            // const updatedHosts = [...assignedBadges.slice(0, index), ...assignedBadges.slice(index + 1)];
                            // setSelectedHosts(updatedHosts);

                            // const updatedValue = [...selectedValues.slice(0, index), ...selectedValues.slice(index + 1)];
                            // setSelectedValues(updatedValue);

                            setOnConfirmDeleteBadgeOpen(true)


                        };
                        const picturePath = value.picturePath ? value.picturePath : "";

                        return (
                            <ListItem key={labelId} 
                                    disablePadding
                                    selected={selectedBadgeItemIndex === index}
                                    onClick={() => handleListItemClick(index)}
                                    >
                         
                                <ListItemButton>
                                    <ListItemAvatar>
                                    <Badge sx={{ color: theme.palette.secondary[100], fontSize: 24 }}/>
                                    </ListItemAvatar>
                                    <ListItemText>
                                            <Typography variant="h5" fontWeight="bold">
                                                {value.cardId}
                                            </Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                {/* <IconButton onClick={handlePrint}>
                                    <Print style={{ color: theme.palette.secondary[100] }}/>
                                </IconButton> */}
                                <Tooltip title={
                                        <Typography variant="body1" style={{ fontSize: '16px' }}>
                                          {tm.translate('lblUnassignBadge', "unassign access card")}
                                        </Typography>
                                    }>
                                    <IconButton onClick={() => setOnConfirmDeleteBadgeOpen(true)}>
                                        <Clear style={{ color: theme.palette.secondary[100]}}/>
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        );
                    })}
                </List>
            ) : (
                
              <Typography sx={{ mt: 3 }}>{tm.translate('lblNoBadgeAssigned', "No access card has been assigned")}</Typography>

            )
        )
    }
          
        </Box>
        {/* --- LEFT END ---*/}

        {/* --- RIGHT START ---*/}

        <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            display="flex"
            flexDirection="column"
            >
    

        {/* door levels section */}
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ 
                    m: "0rem 0rem 0rem 0rem",
                    minHeight: "60px",
                    borderRadius: 1,
                    borderColor: theme.palette.secondary[100],
                    border: 0 , p: 2,
                    backgroundColor:theme.palette.background.alt,
                 }}>   

                <FlexBetween>
                  {assignedBadges.length > 0 && (
                    <Button
                        variant="contained"
                        onClick={() => setOpenDialogAssigneDoorLevel(true)}
                        sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        "&:hover": { color: theme.palette.secondary.dark },
                        }}
                    >
                      {tm.translate('btnAddDoorLevel', "Add Door Level")}
                    </Button>
                  )}
                    {/* <Box>
                        <CssTextField
                            label={"Serach Door Level"}
                        //   onChange={elemet.onChange}
                            sx={{ mr: 2 }}
                            InputProps={{
                            endAdornment: (
                                <Search />
                            ),
                            }}
                            theme={theme}
                        />
                    </Box> */}
                </FlexBetween>
                {
        doorLevelsLoading ? (
            "Loading..."
        ) : (
            assignedDoorLevels && assignedDoorLevels.length > 0 ? (
                <List dense sx={{ 
                        gridColumn: "span 4" , 
                        margin: '15px 0px 0px 0px',
                        overflowY: 'auto', // Add this to enable vertical scrolling
                        flex: 1 
                       // maxHeight: '310px', // Set a maximum height for the container 
                        
                    }}>
                    {assignedDoorLevels.map((value, index) => {
                        const labelId = `checkbox-list-secondary-label-${index}`;

                        const handleRemoveDoorLevel = () => {
                          setDoorLevelToRemove({ index, value }); // Set the entity to remove
                          setOnConfirmDeleteDoorLevel(true); // Open the dialog
                        };

                    return (
                        <ListItem
                                key={labelId}
                              
                                disablePadding
                            >
                                <ListItemButton>
                                <ListItemAvatar>
                                  <DoorSliding sx={{ color: theme.palette.secondary[100], fontSize: 24 }}/>
                                </ListItemAvatar>
                                <ListItemText primary={value.accessProfileId} 
                                              // secondary={value.description} 
                                            />
                                </ListItemButton>
                                <Tooltip title={
                                      <Typography variant="body1" style={{ fontSize: '16px' }}>
                                      {tm.translate('lblRemoveDoorLevel', "Remove Door Level")}
                                      </Typography>
                                  }>
                                    <div>
                                    <IconButton onClick={handleRemoveDoorLevel}>
                                      <Clear style={{ color: theme.palette.secondary[100] }} />
                                    </IconButton>
                                    </div>
                                </Tooltip>
                            </ListItem>
                            );
                        })}
                        </List>
                        ) : (
                          
                          <Typography sx={{ mt: 3 }}>{tm.translate('lblNoDoorLevel', "No door levels assigned")}</Typography>
                 
                          )
                      )
                  }
                    
                </Box>
                {/*  ----------------   */}
        </Box>
        {/* --- LEFT END ---*/}

        {/* BOTTOM START */}
        {!isDialog && (
        <Box
        
          gridColumn="span 12"
          gridRow="span 2"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
        <Typography variant="h5" color={theme.palette.secondary[300]}>
            {"history"}
        </Typography>
          <DataGrid
            loading={gridLoading || !data}
            initialState={{
              pagination: { paginationModel: { pageSize: paginationModel.limit } },
            }}
            columnVisibilityModel={columnVisibilityModel}
            getRowId={(row) => `${row.entityId}`}
            rows={data?.data || []}
            rowCount={(data && data.total_count) || 0}
            rowsPerPageOptions={[25, 50, 100]}
            columns={columns}
          />
        </Box>
        )}
      </Box>
        
    </Box>
  );
};

export default BadgeHandlingOld;