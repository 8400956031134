import React, { useEffect, useState } from "react";
import { Box, Button, FormControlLabel, ListItemIcon, Tooltip, Typography, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import DialogYesNo from "components/dialog/DialogYesNo";
import {  changeVisitorTypesOrder, deleteVisitorType, getVisitorTypes, updateVisitorTypesActive } from "../../../hooks/HttpRequests";
import {  useNavigate } from "react-router-dom";
import IOSSwitch from "components/IOSSwitch";
import { DragHandle, Public } from "@mui/icons-material";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFrontDeskManager } from "components/FrontDeskManager";
import DialogAddVisitorType from "components/dialog/DialogAddVisitorType";

const VisitorsTypes = () => {
  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedFrontDesk, frontDeskLoading} = useFrontDeskManager();
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const theme = useTheme();

  const [visitorTypes, updateVisitorTypes] = useState([]);

  useEffect(() => {
    // This code will run every time paginationModel changes
    loadVisitorTypes();

  }, []);


  useEffect(() => {
    // This code will run every time paginationModel changes
    loadVisitorTypes();

  }, [selectedFrontDesk, triggerRefresh]);

  ////////////////////////////////////////////////
  //DELETE VISITOR TYPE
  ////////////////////////////////////////////////
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [visitorTypeToDelete, setVisitorTypeToDelete] = useState("");


  /////////////// GRID /////////////
  // values to be sent to the backend

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });


  const loadVisitorTypes = async () => {

    setGridLoading(true)
    dispatch(showLoading());

    getVisitorTypes(navigate)(selectedFrontDesk.entityId)
      .then((result) => {

        // Use the result.data as needed
        if(result.success){

         //need to set the id for drag and drop
          
         for (var i = 0; i < result.data.length; i++) { result.data[i]["id"] = getRandomInt(0, 1000).toString(); }

          updateVisitorTypes(result.data)
        }
        setGridLoading(false)
        dispatch(hideLoading());


      })
      .catch((error) => {

        setGridLoading(false)
        dispatch(hideLoading());

      });

  }

    function handleOnDragEnd(result, test) {

      if (!result.destination) return;

      // Extract the dragged and drop target items before reordering the array
      const draggedItem = visitorTypes[result.source.index];
      const dropTargetItem = visitorTypes[result.destination.index];

      const dropPosition = result.destination.index > result.source.index ? 'after' : 'before';

      // Rearrange visitorTypes to reflect the new order
      const items = Array.from(visitorTypes);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      // Update the state with the new order
      updateVisitorTypes(items);

      // Extract required data for the backend
      const draggedVisitorType = draggedItem.visitorType;
      const dropTargetVisitorType = dropTargetItem.visitorType;


      changeVisitorTypesOrder(navigate)(selectedFrontDesk.entityId , draggedVisitorType, dropPosition , dropTargetVisitorType)
      .then((result) => {

        // Use the result.data as needed
        if(result.success){

        }
         
        setGridLoading(false)

      })
      .catch((error) => {

        console.log(error);

        setGridLoading(false)
      });

    }


  const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const buttons = [
    // { label: "Button 1" },
    { type:'button',label: "Add Visitor Type" , onClick: () => setConfirmationOpen(true) , iconName: ''},

  ];

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleSwitchChange = async (newActiveState, id, visitorType) => {
    // Update the local state immediately for a responsive UI
    updateVisitorTypes((prevVisitorTypes) =>
      prevVisitorTypes.map((type) =>
        type.id === id ? { ...type, active: newActiveState } : type
      )
    );
  
    // Perform the backend call
    dispatch(showLoading());
    
    console.log(newActiveState)
    console.log(visitorType)

    

    updateVisitorTypesActive(navigate)(selectedFrontDesk.entityId , visitorType, newActiveState )
      .then((result) => {

        // Use the result.data as needed
        if(result.success){

        }
        dispatch(hideLoading());
        setGridLoading(false)

      })
      .catch((error) => {

        console.log(error);
        dispatch(hideLoading());
        setGridLoading(false)
      });
    
  };

  const handleExecuteDeleteVisitorType = async () => {

    if(visitorTypeToDelete != ""){
    setOpenDialogDelete(false)
    
    // Perform the backend call
    dispatch(showLoading());
  

    deleteVisitorType(navigate)(selectedFrontDesk.entityId , visitorTypeToDelete )
      .then((result) => {

        // Use the result.data as needed
        if(result.success){
            setTriggerRefresh(!triggerRefresh)
        }
        dispatch(hideLoading());
        setGridLoading(false)

      })
      .catch((error) => {

        console.log(error);
        dispatch(hideLoading());
        setGridLoading(false)
      });

    }
    
  };
  

  

  /////////////////////////////////////////

  if (frontDeskLoading) {
    return <div>Loading...</div>;
  }

  
  return (
    <Box m="1rem 2rem">
      <Header title="Sign-In workflows" subtitle="List of visitor types" elements={buttons} />
      <DialogAddVisitorType
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onSave={() => {
          setTriggerRefresh(!triggerRefresh)
          setConfirmationOpen(false)
        }}
        theme={theme}
      />
      <DialogYesNo
          open={openDialogDelete}
          onClose={() => {
            setVisitorTypeToDelete("");
            setOpenDialogDelete(false)
          }}
          onConfirm={() => handleExecuteDeleteVisitorType()}
          theme={theme}
          message={"Do you want to delete?"}
        />
      {/* </Box> */}
      <Box
        mt="10px"
        height="85vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiDataGrid-panel .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
         "& .MuiDataGrid-panelWrapper .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          
        }}
      >


<DragDropContext onDragEnd={handleOnDragEnd}>
  <Droppable droppableId="characters">
      {(provided) => (
      <ul className="characters" {...provided.droppableProps} ref={provided.innerRef} style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
        {visitorTypes.map(({id, active, visitorType, description, globalType},index) => {
      return (
        <Draggable key={"drag_it_"+visitorType} draggableId={"drag_it_"+visitorType} index={index}>
        {(provided) => (
        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ ...commonStyles }}
            >
              {/* Main Content */}
              <Box sx={{ flexGrow: 1, border: 1 }}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem" }}>
                    <Box
                      display="flex"
                      flexDirection="row" // Render elements horizontally
                      alignItems="center"
                    >
                      <Typography
                        variant="h2"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                      >
                        {visitorType}
                      </Typography>
                      {globalType && (
                        <Tooltip
                          title={
                            <Typography
                              variant="body1"
                              style={{ fontSize: "16px" }}
                            >
                              Global Visitor Type
                            </Typography>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              color: theme.palette.secondary[500],
                              display: "flex",
                              alignItems: "center",
                              margin: "0rem 0rem 0rem 1rem",
                            }}
                          >
                            <Public />
                          </ListItemIcon>
                        </Tooltip>
                      )}
                    </Box>
          
                    <Typography variant="h5" color={theme.palette.secondary[300]}>
                      {description}
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={(event) =>
                            handleSwitchChange(
                              event.target.checked,
                              id,
                              visitorType
                            )
                          }
                          checked={active}
                        />
                      }
                      label={
                        <span style={{ color: theme.palette.secondary.light }}>
                          Show on Check-In Kiosk
                        </span>
                      } // Change label color to theme's secondary light color
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row" // Render elements horizontally
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        const selectedVisitorType = visitorTypes.find(
                          (type) => type.id === id
                        );
                        console.log(
                          "Navigating to visitor type:",
                          selectedVisitorType
                        );
                        navigate(
                          `/visit/settings/visitor-types/${selectedVisitorType.visitorType}/workflow`
                        );
                      }}
                      sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        m: "0rem 1rem 0rem 0rem",
                        "&:hover": { color: theme.palette.secondary.light },
                      }}
                    >
                      {"EDIT"}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setVisitorTypeToDelete(visitorType);
                        setOpenDialogDelete(true)
                      }}
                      sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        m: "0rem 1rem 0rem 0rem",
                        "&:hover": { color: theme.palette.secondary.light },
                      }}
                    >
                      {"DELETE"}
                    </Button>
                  </Box>
                </Box>
              </Box>
          
              {/* ListItemIcon (Drag Handle) */}
              <ListItemIcon
                sx={{
                  color: theme.palette.secondary[300],
                  marginLeft: "1rem",
                }}
              >
                <DragHandle />
              </ListItemIcon>
            </Box>
          </li>
      
        )}
        </Draggable>
      );
    })}
    {provided.placeholder}
  </ul>
  )}
  </Droppable>
</DragDropContext>

      </Box>
    </Box>
  );
};

export default VisitorsTypes;