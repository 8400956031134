// HttpRequests.js

import axios from 'axios';
import { withErrorHandling } from './HttpRequestsErrorHandling';
import { setAuthenticatedOutside } from '../components/UserProfileProvider';
import tm from 'components/TranslationManager';

export const getBaseUrl = () => {  
  return "/api/localhost/";
};


export const getUri = (token) => {
  // Implementation for generating the base URL based on the token
  // Replace this with your actual implementation

  var url_rewrite = "";
  if (process.env.NODE_ENV == 'production') {
    url_rewrite = "/sadevio_module";
  }

  let URI = url_rewrite+getBaseUrl()+((token != null && token != '') ? token+"/":'')+"";

  
  return URI;
};

const getUserAgent = () => {
  // Implementation for getting the user agent
  // Replace this with your actual implementation
  return 'Your User Agent';
};

const commonHeaders = {
  'Content-Type': 'application/json',
};

// Error handling function

export const handleRequestError = (error, reject, navigate) => {

  
  if (axios.isAxiosError(error) && error.response) {
    const { status } = error.response;
    if (status === 400) {
      setSessionToken("");
      console.error('Bad Request:', error.response.data);
      setAuthenticatedOutside(false);
      if(navigate){
          navigate('/');
      }
      reject(error.response.data);

    }else if (status === 403) {

    } else {
      reject(error.response.data);
    }
  }else if (typeof error.action !== 'undefined' && error.action === 'relogin' && navigate) {
      setSessionToken("");
      setAuthenticatedOutside(false);
      window.location.reload();
  }else if (typeof error.action !== 'undefined' && error.action === 'relogin') {
    setSessionToken("");
    setAuthenticatedOutside(false);
  } else {
    reject(error);
  }
};

export const getRequestParameters = (requestParams) => {
  // Implementation for getting the user agent
  // Replace this with your actual implementation
  var params = "";

  try{
    
    params = "?start=".concat(requestParams.page * requestParams.limit).concat("&page=").concat(requestParams.page).concat("&limit=").concat(requestParams.limit)

    if(requestParams.sort.length > 0){

      const sortJsonString = JSON.stringify(requestParams.sort);
      params = params.concat("&sort=").concat(encodeURIComponent(sortJsonString));

    }
    if(requestParams.query.length > 0){

      const queryJsonString = JSON.stringify(requestParams.query);

      console.log(queryJsonString)
      params = params.concat("&query=").concat(encodeURIComponent(queryJsonString));

    }

  }catch(error){

  }


  return params;
};


export const getSessionToken = () => {

  const token = localStorage.getItem('token');

  return token;

}

export const setSessionToken = (sessionToken) => {

  localStorage.setItem('token', sessionToken);

  console.log("-------------------------------------------");
  console.log("-------------------------------------------");
  console.log("-------------------------------------------");
  console.log("-------------setSessionToken---------------");
  console.log("-------------------------------------------");
  console.log("-------------------------------------------");
  console.log("-------------------------------------------");

  return sessionToken;

}

export const restSessionToken = () => {

  localStorage.setItem('token', '');

}


////////////////////////////////////////////////////////////////

export const authenticateUserAPI = (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

 
      const url = getUri() + "authentication";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const authenticateUser = (navigate) => withErrorHandling(authenticateUserAPI, navigate);

export const forgotPasswordUserAPI = (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

 
      const url = getUri('') + "authentication/host/forgot";

      const config = {
        headers: 'application/x-www-form-urlencoded',
      };

      // Convert submitData to form-urlencoded format
      const formData = new URLSearchParams();
      for (const key in submitJSON) {
        formData.append(key, submitJSON[key]);
      }

      const response = await axios.post(url, formData.toString(), config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const forgotPasswordUser = (navigate) => withErrorHandling(forgotPasswordUserAPI, navigate);


export const resetPasswordUserAPI = (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

 
      const url = getUri('') + "authentication/host/reset";

      const config = {
        headers: 'application/x-www-form-urlencoded',
      };

      // Convert submitData to form-urlencoded format
      const formData = new URLSearchParams();
      for (const key in submitJSON) {
        formData.append(key, submitJSON[key]);
      }

      const response = await axios.post(url, formData.toString(), config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const resetPasswordUser = (navigate) => withErrorHandling(resetPasswordUserAPI, navigate);

export const authenticationType = (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

 
      const url = getUri('') + "authentication/user/authtype";

      const config = {
        headers: 'application/x-www-form-urlencoded',
      };

      // Convert submitData to form-urlencoded format
      const formData = new URLSearchParams();
      for (const key in submitJSON) {
        formData.append(key, submitJSON[key]);
      }

      const response = await axios.post(url, formData.toString(), config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

// Function to fetch location info
const fetchLocationInfo = async () => {
  try {
    const response = await axios.get('https://ipinfo.io', {
      params: {
        format: 'json'
      },
    });

    // Handle success
    const { data } = response;
    const jsonString = JSON.stringify(data); // Convert JSON object to string
    return jsonString;
  } catch (error) {
    // Handle failure
    console.error('Error fetching location info:', error);

    // If fetching fails, you can handle it here
    // For example, you can return a default value or throw an error
    return null; // Or throw new Error('Failed to fetch location info');
  }
};



////////////////////////////////////////////////////////////////


export const getRewardAnyAdvertisersAPI = async (requestParams) => {

  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `rewardany` + getRequestParameters(requestParams);

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getRewardAnyAdvertisers = (navigate) => withErrorHandling(getRewardAnyAdvertisersAPI, navigate);


export const getRewardAnyClickReport = withErrorHandling( async (requestParams) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `rewardany/click_report` + getRequestParameters(requestParams);

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
});

export const getRewardAnyTransactions = withErrorHandling( async (requestParams) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `rewardany/transactions` + getRequestParameters(requestParams);

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
});


// Add more reusable functions as needed
export const getVisitorTypesAPI = async (frontDeskId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `frontdesk/workflow/${frontDeskId}/types`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getVisitorTypes = (navigate) => withErrorHandling(getVisitorTypesAPI, navigate);

export const changeVisitorTypesOrderAPI = async (frontDeskId, draggedVisitorType, dropPosition, dropTargetVisitorType) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `frontdesk/${frontDeskId}/workflow/sort/visitortype/${draggedVisitorType}/${dropPosition}/${dropTargetVisitorType}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};


export const changeVisitorTypesOrder = (navigate) => withErrorHandling(changeVisitorTypesOrderAPI, navigate);

export const changeWorkflowElementsOrderAPI = async (frontDeskId, draggedVisitorType, dropPosition, dropTargetVisitorType, visitorType) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `frontdesk/workflow/${frontDeskId}/sort/${draggedVisitorType}/${dropPosition}/${dropTargetVisitorType}/visitor_check_in/${visitorType}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};


export const changeWorkflowElementsOrder = (navigate) => withErrorHandling(changeWorkflowElementsOrderAPI, navigate);

export const changeWorkflowElementsVisitorDataOrderAPI = async (frontDeskId, draggedVisitorType, dropPosition, dropTargetVisitorType, visitorType) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `frontdesk/${frontDeskId}/workflow/sort/visitordata/${draggedVisitorType}/${dropPosition}/${dropTargetVisitorType}/visitor_check_in/${visitorType}`;

      console.log(url)

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};


export const changeWorkflowElementsVisitorDataOrder = (navigate) => withErrorHandling(changeWorkflowElementsVisitorDataOrderAPI, navigate);

export const updateVisitorTypesActiveAPI = async (frontDeskId, visitorType, active) => {


  return new Promise(async (resolve, reject) => {
    try {

 

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${frontDeskId}/workflow/visitortype/${visitorType}/active/${active}`;

      const config = {
        headers: commonHeaders,
      };

      var submitJSON = {}

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};


export const updateVisitorTypesActive = (navigate) => withErrorHandling(updateVisitorTypesActiveAPI, navigate);


export const postViitorTypeAPI = async (frontDeskId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      //frontdesk/9/terminalsettings/meetingroom
      const url = getUri(token) + `frontdesk/${frontDeskId}/terminalsettings/visitortypes`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const postViitorType = (navigate) => withErrorHandling(postViitorTypeAPI, navigate);

export const deleteVisitorTypeAPI = async (frontDeskId, typeName) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/workflow/${frontDeskId}/type/${typeName}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteVisitorType = (navigate) => withErrorHandling(deleteVisitorTypeAPI, navigate);


// Add more reusable functions as needed
export const getVisitorTypesWorkflowAPI = async (frontDeskId, visitorType) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `frontdesk/workflow/${frontDeskId}/workflowtype/visitor_check_in/workflowtypesub/${visitorType}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getVisitorTypesWorkflow = (navigate) => withErrorHandling(getVisitorTypesWorkflowAPI, navigate);

// Add more reusable functions as needed
export const getVisitorTypeWorkflowAPI = async (frontDeskId, visitorType) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `frontdesk/workflow/${frontDeskId}/visitortype/${visitorType}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getVisitorTypeWorkflow = (navigate) => withErrorHandling(getVisitorTypeWorkflowAPI, navigate);

export const getWorkflowEngineTypeAPI = async (frontDeskId, engineTypeId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `frontdesk/workflow/${frontDeskId}/engine_type/${engineTypeId}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getWorkflowEngineType = (navigate) => withErrorHandling(getWorkflowEngineTypeAPI, navigate);


export const postWorkflowEngineTypeAPI = async (frontDeskId, engineType, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      //frontdesk/9/terminalsettings/meetingroom
      const url = getUri(token) + `frontdesk/${frontDeskId}/terminalsettings/${engineType}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const postWorkflowEngineType = (navigate) => withErrorHandling(postWorkflowEngineTypeAPI, navigate);

export const putWorkflowEngineTypeAPI = async (frontDeskId, engineType, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      //frontdesk/9/terminalsettings/meetingroom
      const url = getUri(token) + `frontdesk/${frontDeskId}/terminalsettings/${engineType}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putWorkflowEngineType = (navigate) => withErrorHandling(putWorkflowEngineTypeAPI, navigate);


export const deleteWorkflowElementAPI = async (frontDeskId, elementId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      ////frontdesk/workflow/9/engine_type/92
      const url = getUri(token) + `frontdesk/workflow/${frontDeskId}/engine_type/${elementId}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteWorkflowElement = (navigate) => withErrorHandling(deleteWorkflowElementAPI, navigate);

export const getWorkflowConfigurationAPI = async (frontDeskId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      //frontdesk/9/terminalsettings/meetingroom
      const url = getUri(token) + `frontdesk/terminalsettings/${frontDeskId}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getWorkflowConfiguration = (navigate) => withErrorHandling(getWorkflowConfigurationAPI, navigate);


export const putDesignConfiurationAPI = async (frontDeskId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      //frontdesk/9/terminalsettings/meetingroom
      const url = getUri(token) + `frontdesk/${frontDeskId}/terminalsettings/design`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putDesignConfiuration = (navigate) => withErrorHandling(putDesignConfiurationAPI, navigate);

export const getWorkflowEngineAllUserDefinedFieldsAPI = async (frontDeskId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `frontdesk/${frontDeskId}/usedfields`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getWorkflowEngineAllUserDefinedFields = (navigate) => withErrorHandling(getWorkflowEngineAllUserDefinedFieldsAPI, navigate);

export const getWorkflowElementVisitorDataCustomAPI = async (frontDeskId, workFlowId, apiKey) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `frontdesk/${frontDeskId}/workflow/${workFlowId}/visitordata/custom/${apiKey}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getWorkflowElementVisitorDataCustom = (navigate) => withErrorHandling(getWorkflowElementVisitorDataCustomAPI, navigate);

export const putWorkflowElementVisitorDataCustomAPI = async (frontDeskId, workFlowId, apiKey, jsonObject) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `frontdesk/${frontDeskId}/workflow/${workFlowId}/visitordata/custom/${apiKey}`;

      const config = {
        headers: commonHeaders,
        data: jsonObject, // Add the JSON object here
      };

      const response = await axios.put(url, jsonObject,config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putWorkflowElementVisitorDataCustom = (navigate) => withErrorHandling(putWorkflowElementVisitorDataCustomAPI, navigate);

export const updateWorkflowElementVisitorDataAPI = async (frontDeskId, jsonObject) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      const url = getUri(token) + `frontdesk/${frontDeskId}/terminalsettings/visitordata`;

      const config = {
        headers: commonHeaders,
        data: jsonObject, // Add the JSON object here
      };

      const response = await axios.put(url,jsonObject, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const updateWorkflowElementVisitorData = (navigate) => withErrorHandling(updateWorkflowElementVisitorDataAPI, navigate);

export const deleteWorkflowElementVisitorDataAPI = async (frontDeskId, elementId, jsonObject) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      ////{frontDeskEntityId}/terminalsettings/visitordata/{fieldId}
      const url = getUri(token) + `frontdesk/${frontDeskId}/terminalsettings/visitordata/${elementId}`;

      const config = {
        headers: commonHeaders,
        data: jsonObject, // Add the JSON object here
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteWorkflowElementVisitorData = (navigate) => withErrorHandling(deleteWorkflowElementVisitorDataAPI, navigate);

export const updateorkflowElementVisitorDataRequiredAPI = async (frontDeskId, elementId, isRequired, jsonObject) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      ////{        // @Path("{frontDeskEntityId}/terminalsettings/visitordata/{fieldId}/required/{isRequired}")
      const url = getUri(token) + `frontdesk/${frontDeskId}/terminalsettings/visitordata/${elementId}/required/${isRequired}`;

      const config = {
        headers: commonHeaders,
        data: jsonObject, // Add the JSON object here
      };

      const response = await axios.put(url,jsonObject, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const updateorkflowElementVisitorDataRequired = (navigate) => withErrorHandling(updateorkflowElementVisitorDataRequiredAPI, navigate);

export const updateorkflowElementVisitorDataHidePreRegdAPI = async (frontDeskId, elementId, isRequired, jsonObject) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      ////{        // @Path("{frontDeskEntityId}/terminalsettings/visitordata/{fieldId}/required/{isRequired}")
      const url = getUri(token) + `frontdesk/${frontDeskId}/terminalsettings/visitordata/${elementId}/hideprereg/${isRequired}`;

      const config = {
        headers: commonHeaders,
        data: jsonObject, // Add the JSON object here
      };

      const response = await axios.put(url,jsonObject, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const updateorkflowElementVisitorDataHidePreReg = (navigate) => withErrorHandling(updateorkflowElementVisitorDataHidePreRegdAPI, navigate);

// Add more reusable functions as needed
export const getWebUsersAPI = async () => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `user`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getWebUsers = (navigate) => withErrorHandling(getWebUsersAPI, navigate);


// Add more reusable functions as needed
export const getTranslateAPI = async () => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `translate?application=main`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getTranslate = (navigate) => withErrorHandling(getTranslateAPI, navigate);


export const getConfigDataAPI = async () => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `configData`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getConfigData = (navigate) => withErrorHandling(getConfigDataAPI, navigate);


export const getMenuAPI = async (frontDeskId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `menu/v2/${frontDeskId}`;

      const config = {
        headers: {
          ...commonHeaders,
          userLang: 'EN',
        },
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data.children);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getMenu = (navigate) => withErrorHandling(getMenuAPI, navigate);



export const getLastVisitorChangesAPI = async (frontDeskId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `visit/dashboardall/lastchanges?frontDeskFilter=`+frontDeskId;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getLastVisitorChanges = (navigate) => withErrorHandling(getLastVisitorChangesAPI, navigate);


export const getCurrentVisitorStatsAPI = async (frontDeskId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `statistics/${frontDeskId}/currentvisitorstats`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getCurrentVisitorStats = (navigate) => withErrorHandling(getCurrentVisitorStatsAPI, navigate);


export const getVisitDashboardAllAPI = async ( entityId, requestParams, filterVisitorStatus, selectedVisitorType) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();


       ///////// VISITOR TYPE
       var visitorType= "&VT=".concat(selectedVisitorType.value)

      ///////// VISITOR STATUS

      // { filterInvited: true, filterWaiting: true, filterConfirmed: true, filterCheckedIn : true, filterCheckedOut: true }
      //visitor status - VS
      // invited - I
      // WAITING - W
      //Confirmed - C
      //Check-In - IN
      //Check-OUT - OUT
      //Under Review - R
      var visitorStatus = "&VS=I$".concat(filterVisitorStatus.filterInvited).concat("-W$").concat(filterVisitorStatus.filterWaiting).concat("-C$").concat(filterVisitorStatus.filterConfirmed).concat("-IN$").concat(filterVisitorStatus.filterCheckedIn).concat("-OUT$").concat(filterVisitorStatus.filterCheckedOut).concat("-R$").concat(filterVisitorStatus.filterUnderReview).concat("-RJ$").concat(filterVisitorStatus.filterRejected).concat("-DN$").concat(filterVisitorStatus.filterDenied)
      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      // [{"property":"visitorStatus","direction":"ASC"}]
      if(requestParams.sort.length == 0){

          var sortByWhenModified = {}
          sortByWhenModified["property"]= "visitWhenModified"
          sortByWhenModified["direction"]= "DESC"
          requestParams.sort.push(sortByWhenModified)
      }
      const url = getUri(token) + `visit/dashboardall` + getRequestParameters(requestParams)+"&frontDeskFilter=".concat(entityId).concat(visitorStatus).concat(visitorType);

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getVisitDashboardAll = (navigate) => withErrorHandling(getVisitDashboardAllAPI, navigate);


export const autocompleteHostAPI = async (hostsName) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
    
      // const url = getUri(token) + `host/autocomplete_terminal?query=${hostsName}&limit=30&_dc=1713483320889&page=1&start=0&limit=100&sort=%5B%7B%22property%22%3A%22lastname%22%2C%22direction%22%3A%22ASC%22%7D%5D`
      const url = getUri(token) + `host/autocomplete?query=${hostsName}&limit=30`

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const autocompleteHost = (navigate) => withErrorHandling(autocompleteHostAPI, navigate);

export const autocompleteVisitorAPI = async (hostsName) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `visitor/autocomplete_terminal/lookup?query=${hostsName}&limit=30&_dc=1713483320889&page=1&start=0&limit=100&sort=%5B%7B%22property%22%3A%22lastname%22%2C%22direction%22%3A%22ASC%22%7D%5D`

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const autocompleteVisitor = (navigate) => withErrorHandling(autocompleteVisitorAPI, navigate);

export const getAllFrontTesksAPI = withErrorHandling( async (requestParams) => {

  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `frontdesk` + getRequestParameters(requestParams);

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });

});

export const getAllFrontTesks = (navigate) => withErrorHandling(getAllFrontTesksAPI, navigate);




export const getListValuesPagingAPI =  async (requestParams, urlParm, extraFilterValues) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          // reject( {"success" : false, "action" : "relogin"});
          const error = new Error("Something went wrong");
          error.success = false;
          error.action = "relogin"
          throw error;
      }
 
      const url = getUri(token) + urlParm + getRequestParameters(requestParams)+extraFilterValues;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      // handleRequestError(error, reject);
      reject(error)
      
    }
  });
};

export const getListValuesPaging = (navigate) => withErrorHandling(getListValuesPagingAPI, navigate);


export const getCompanyAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "company/" + entityId;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getCompany = (navigate) => withErrorHandling(getCompanyAPI, navigate);


export const postCompanyAPI = async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "company";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const postCompany = (navigate) => withErrorHandling(postCompanyAPI, navigate);


export const putCompanyAPI = async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "company";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putCompany = (navigate) => withErrorHandling(putCompanyAPI, navigate);

export const deleteCompanyAPI = async (id) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "company/"+id;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteCompany = (navigate) => withErrorHandling(deleteCompanyAPI, navigate);


export const getHostAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "host/" + entityId;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getHost = (navigate) => withErrorHandling(getHostAPI, navigate);

export const postHostAPI = withErrorHandling( async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "host";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
});

export const postHost = (navigate) => withErrorHandling(postHostAPI, navigate);

export const putHostAPI = withErrorHandling( async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "host";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
});

export const putHost = (navigate) => withErrorHandling(putHostAPI, navigate);

export const deleteHostAPI = async (id) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "host/"+id;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteHost = (navigate) => withErrorHandling(deleteHostAPI, navigate);

export const deleteHostForceAPI = async (id) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "host/force/"+id;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteHostForce = (navigate) => withErrorHandling(deleteHostForceAPI, navigate);


/////////////////////////////////////////
///////// FRONT DESK
/////////////////////////////////////////

export const getFrontDeskAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "frontdesk/" + entityId;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getFrontDesk = (navigate) => withErrorHandling(getFrontDeskAPI, navigate);


export const putFrontDeskAPI =  async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "frontdesk";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putFrontDesk = (navigate) => withErrorHandling(putFrontDeskAPI, navigate);


export const getFrontDeskTerminalSettingsAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "frontdesk/" + entityId+"/terminalsettings";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getFrontDeskTerminalSettings = (navigate) => withErrorHandling(getFrontDeskTerminalSettingsAPI, navigate);


/////////////////////////////////////////
///////// VISITOR
/////////////////////////////////////////

export const getVisitorAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "visitor/" + entityId;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getVisitor = (navigate) => withErrorHandling(getVisitorAPI, navigate);

export const postVisitorAPI = async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "visitor";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const postVisitor = (navigate) => withErrorHandling(postVisitorAPI, navigate);

export const putVisitorAPI = async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "visitor";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putVisitor = (navigate) => withErrorHandling(putVisitorAPI, navigate);

export const deleteVisitorAPI = async (id) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "visitor/"+id;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteVisitor = (navigate) => withErrorHandling(deleteVisitorAPI, navigate);

export const deleteVisitorForceAPI = async (id) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "visitor/force/"+id;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteVisitorForce = (navigate) => withErrorHandling(deleteVisitorForceAPI, navigate);

export const inviteVisitorAPI = withErrorHandling( async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "visit/preregister";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
});

export const inviteVisitor = (navigate) => withErrorHandling(inviteVisitorAPI, navigate);

export const sendVisitorIncitationAPI = async (visitorId, visitId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `visitor/${visitorId}/prereg/${visitId}/send`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const sendVisitorIncitation = (navigate) => withErrorHandling(sendVisitorIncitationAPI, navigate);

export const getVisitorIdDocumentsAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `visitor/${entityId}/iddocuments`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getVisitorIdDocuments = (navigate) => withErrorHandling(getVisitorIdDocumentsAPI, navigate);

export const getUserRoleAPI =  async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "userrole/" + entityId;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getUserRole = (navigate) => withErrorHandling(getUserRoleAPI, navigate);

export const getUserRoleHasPermissionAPI =  async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "userrole/" + entityId+"/permissions";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getUserRoleHasPermission = (navigate) => withErrorHandling(getUserRoleHasPermissionAPI, navigate);




////////////////

export const deleteVisitAPI = async (id) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "visit/"+id;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteVisit = (navigate) => withErrorHandling(deleteVisitAPI, navigate);


export const cancelVisitAPI =  async (id, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "visit/"+id+"/cancel";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const cancelVisit = (navigate) => withErrorHandling(cancelVisitAPI, navigate);


export const fileUploadAPI =  async  (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "fileupload";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const fileUpload = (navigate) => withErrorHandling(fileUploadAPI, navigate);



/////////////////////////////////////////
///////// VISIT
/////////////////////////////////////////


export const getVisitAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "visit/" + entityId;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getVisit = (navigate) => withErrorHandling(getVisitAPI, navigate);



export const putVisitTimeChangeAPI =  async (entityId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "visit/"+entityId+"/changetimerange";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putVisitTimeChange = (navigate) => withErrorHandling(putVisitTimeChangeAPI, navigate);



/////////////////////////////////////////
///////// Access Cards
/////////////////////////////////////////

export const getAssignedBadgesAPI =  async (entityTable, entityKeyname, entityIdPointer) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "access_card/" + entityTable+"/"+entityKeyname+"/"+entityIdPointer;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const getAssignedBadges = (navigate) => withErrorHandling(getAssignedBadgesAPI, navigate);


export const deleteBadgeAPI = async (id) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "access_card/"+id;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteBadge = (navigate) => withErrorHandling(deleteBadgeAPI, navigate);


export const getAssignedDoorLevelsAPI = async (accessCardEntityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "access_card/" + accessCardEntityId+"/doorlevels";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getAssignedDoorLevels = (navigate) => withErrorHandling(getAssignedDoorLevelsAPI, navigate);



////////////////////////////////////////////////////////////////

export const assigneBadgeAPI = async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

 
      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + "visit/accesscard/assign";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const assigneBadge = (navigate) => withErrorHandling(assigneBadgeAPI, navigate);

export const unAssigneBadgeAPI = async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

 
      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + "visit/accesscard/unassign";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const unAssigneBadge = (navigate) => withErrorHandling(unAssigneBadgeAPI, navigate);

export const getBadgeProcessingStatusAPI = async (visitId, entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

 
      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `access_system/processing/${visitId}/${entityId}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getBadgeProcessingStatus = (navigate) => withErrorHandling(getBadgeProcessingStatusAPI, navigate);




export const assigneBadgeDoorLevelsAPI = async (visitId, visitorId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

 
      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `visit/${visitId}/accesscardprofile/${visitorId}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const assigneBadgeDoorLevels = (navigate) => withErrorHandling(assigneBadgeDoorLevelsAPI, navigate);


export const unassigneBadgeDoorLevelsAPI = async (visitId, visitorId, submitJSON)  => {


  return new Promise(async (resolve, reject) => {
    try {

 
      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `visit/${visitId}/accesscardprofile/${visitorId}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const unassigneBadgeDoorLevels = (navigate) => withErrorHandling(unassigneBadgeDoorLevelsAPI, navigate);

export const postAttachmentAPI = async (entityPointerId, entityTable, entityKeyname, selectedFile ) => {


  return new Promise(async (resolve, reject) => {
    try {

      const formData = new FormData();
       formData.append('attachmentId', 0);
        formData.append('entityId', entityPointerId);
        formData.append('entityTable', entityTable); 
        formData.append('entityKeyname',  entityKeyname); 
        formData.append('file', selectedFile);

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "attachment";

      const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
      };

      const response = await axios.post(url, formData, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const postAttachment = (navigate) => withErrorHandling(postAttachmentAPI, navigate);

export const deleteAttachmentAPI = async (id) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "attachment/"+id;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const deleteAttachment = (navigate) => withErrorHandling(deleteAttachmentAPI, navigate);



export const addHostToVisitAPI = async (visitId, hostId, frontDeskEntityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      const url = getUri(token) + "visit/" + visitId +"/host/"+hostId+"/addhost";

      const config = {
        headers: commonHeaders,
      };

      var submitJSON = {};
      submitJSON["frontDeskEntityId"] = frontDeskEntityId;

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const addHostToVisit = (navigate) => withErrorHandling(addHostToVisitAPI, navigate);


export const removeHostToVisitAPI = async (visitId, hostId, frontDeskEntityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      const url = getUri(token) + "visit/" + visitId +"/host/"+hostId+"/removehost";

      const config = {
        headers: commonHeaders,
      };

      var submitJSON = {};
      submitJSON["frontDeskEntityId"] = frontDeskEntityId;

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const removeHostToVisit = (navigate) => withErrorHandling(removeHostToVisitAPI, navigate);

export const addVisitorToVisitAPI = async (visitId, visitorId, frontDeskEntityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      const url = getUri(token) + "visit/" + visitId +"/visitor/"+visitorId+"/addvisitor";

      const config = {
        headers: commonHeaders,
      };

      var submitJSON = {};
      submitJSON["frontDeskEntityId"] = frontDeskEntityId;

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const addVisitorToVisit = (navigate) => withErrorHandling(addVisitorToVisitAPI, navigate);

export const removeVisitorToVisitAPI = async (visitId, hostId, frontDeskEntityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      const url = getUri(token) + "visit/" + visitId +"/visitor/"+hostId+"/removevisitor";

      const config = {
        headers: commonHeaders,
      };

      var submitJSON = {};
      submitJSON["frontDeskEntityId"] = frontDeskEntityId;

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const removeVisitorToVisit = (navigate) => withErrorHandling(removeVisitorToVisitAPI, navigate);

export const printLabelVisitorAPI = async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      const url = getUri(token) + "badgedesign/visit";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const printLabelVisitor = (navigate) => withErrorHandling(printLabelVisitorAPI, navigate);

export const postVisitAPI = async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      const url = getUri(token) + "visit";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url,submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const postVisit = (navigate) => withErrorHandling(postVisitAPI, navigate);

export const visitFinishAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      const url = getUri(token) + "visit/"+entityId+"/finish";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const visitFinish = (navigate) => withErrorHandling(visitFinishAPI, navigate);

export const visitDeleteAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      const url = getUri(token) + "visit/"+entityId;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const visitDelete = (navigate) => withErrorHandling(visitDeleteAPI, navigate);


export const visitCancelAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      const url = getUri(token) + "visit/"+entityId+"/cancel";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const visitCancel = (navigate) => withErrorHandling(visitCancelAPI, navigate);


export const visitNowShowAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      const url = getUri(token) + "visit/"+entityId+"/noshow";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const visitNowShow = (navigate) => withErrorHandling(visitNowShowAPI, navigate);



/////////////////////////
// statistics 


export const getFrontDeskVisitsPerMonthAPI = async ( frontDeskEntityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();


      const url = getUri(token) + `statistics/frontdesk/${frontDeskEntityId}/checkins/permonth`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getFrontDeskVisitsPerMonth = (navigate) => withErrorHandling(getFrontDeskVisitsPerMonthAPI, navigate);


export const getFrontDeskTop10VisitorsAPI = async ( frontDeskEntityId, validFrom, validTo) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();


      const url = getUri(token) + `statistics/frontdesk/${frontDeskEntityId}/top10/visitors?valid_from=${validFrom}&valid_to=${validTo}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getFrontDeskTop10Visitors = (navigate) => withErrorHandling(getFrontDeskTop10VisitorsAPI, navigate);


export const getFrontDeskTop10HostsAPI = async ( frontDeskEntityId, validFrom, validTo) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();


      const url = getUri(token) + `statistics/frontdesk/${frontDeskEntityId}/top10/hosts?valid_from=${validFrom}&valid_to=${validTo}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getFrontDeskTop10Hosts = (navigate) => withErrorHandling(getFrontDeskTop10HostsAPI, navigate);


export const getFrontDeskTop10CompaniesAPI = async ( frontDeskEntityId, validFrom, validTo) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();


      const url = getUri(token) + `statistics/frontdesk/${frontDeskEntityId}/top10/companies?valid_from=${validFrom}&valid_to=${validTo}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getFrontDeskTop10Companies = (navigate) => withErrorHandling(getFrontDeskTop10CompaniesAPI, navigate);


export const getCheckInsPerLocationsPerDeviceAPI = async  (validFrom, validTo) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();


      const url = getUri(token) + `statistics/checkins/locations/device?valid_from=${validFrom}&valid_to=${validTo}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getCheckInsPerLocationsPerDevice = (navigate) => withErrorHandling(getCheckInsPerLocationsPerDeviceAPI, navigate);

export const getCheckInsPerLocationPerDeviceAPI = async  (frontDeskEntityId, validFrom, validTo) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();


      const url = getUri(token) + `statistics/frontdesk/${frontDeskEntityId}/checkins/device?valid_from=${validFrom}&valid_to=${validTo}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getCheckInsPerLocationPerDevice = (navigate) => withErrorHandling(getCheckInsPerLocationPerDeviceAPI, navigate);



export const getFrontDeskCheckInsPerVisitorTypeAPI = async ( frontDeskEntityId, validFrom, validTo) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();


      const url = getUri(token) + `statistics/frontdesk/${frontDeskEntityId}/checkins/visitortype?valid_from=${validFrom}&valid_to=${validTo}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getFrontDeskCheckInsPerVisitorType = (navigate) => withErrorHandling(getFrontDeskCheckInsPerVisitorTypeAPI, navigate);

export const getDashboardVisitorsStatisticsAPI = async ( frontDeskEntityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();


      const url = getUri(token) + `statistics/${frontDeskEntityId}/getdashboardcounts`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getDashboardVisitorsStatistics = (navigate) => withErrorHandling(getDashboardVisitorsStatisticsAPI, navigate);

export const putVisitVisitorCheckInAPI = async (visitId, visitorId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `visit/${visitId}/visitor/${visitorId}/checkin`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putVisitVisitorCheckIn = (navigate) => withErrorHandling(putVisitVisitorCheckInAPI, navigate);

export const putVisitVisitorCheckOutAPI = async (visitId, visitorId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `visit/${visitId}/visitor/${visitorId}/checkout`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putVisitVisitorCheckOut = (navigate) => withErrorHandling(putVisitVisitorCheckOutAPI, navigate);


export const getSilentAlarmConfigAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/silentalarm/config`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getSilentAlarmConfig = (navigate) => withErrorHandling(getSilentAlarmConfigAPI, navigate);

export const putSilentAlarmConfigAPI = async (entityId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/silentalarm/config`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putSilentAlarmConfig = (navigate) => withErrorHandling(putSilentAlarmConfigAPI, navigate);

export const getSilentAlarmExecuteTriggerAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/silentalarm/trigger`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getSilentAlarmExecuteTrigger = (navigate) => withErrorHandling(getSilentAlarmExecuteTriggerAPI, navigate);

export const getVisualComplianceConfigAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/visualcompliance/config`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getVisualComplianceConfig = (navigate) => withErrorHandling(getVisualComplianceConfigAPI, navigate);

export const putVisualComplianceAPI = async (entityId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/visualcompliance/config`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putVisualCompliance = (navigate) => withErrorHandling(putVisualComplianceAPI, navigate);

export const getDataPrivacyConfigAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/dataprivacy`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getDataPrivacyConfig = (navigate) => withErrorHandling(getDataPrivacyConfigAPI, navigate);

export const putDataPrivacyConfigAPI = async (entityId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/dataprivacy`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putDataPrivacyConfig = (navigate) => withErrorHandling(putDataPrivacyConfigAPI, navigate);

export const getDeniedPersonConfigAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/deniedpersonsettings`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getDeniedPersonConfig = (navigate) => withErrorHandling(getDeniedPersonConfigAPI, navigate);

export const putDeniedPersonConfigAPI = async (entityId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/deniedpersonsettings`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putDeniedPersonConfig = (navigate) => withErrorHandling(putDeniedPersonConfigAPI, navigate);

export const getPreRegSettingsConfigAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/preregsettings`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getPreRegSettingsConfig = (navigate) => withErrorHandling(getPreRegSettingsConfigAPI, navigate);

export const putPreRegSettingsConfigAPI = async (entityId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/preregsettings/generalsettings`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putPreRegSettingsConfig = (navigate) => withErrorHandling(putPreRegSettingsConfigAPI, navigate);

export const getPreRegManagerApprovalConfigAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/preregsettings/managerapproval`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getPreRegManagerApprovalConfig = (navigate) => withErrorHandling(getPreRegManagerApprovalConfigAPI, navigate);

export const putPreRegManagerApprovalConfigAPI = async (entityId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/preregsettings/managerapproval`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putPreRegManagerApprovalConfig = (navigate) => withErrorHandling(putPreRegManagerApprovalConfigAPI, navigate);

export const getEmergencyListConfigAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/emergencylist/config`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getEmergencyListConfig = (navigate) => withErrorHandling(getEmergencyListConfigAPI, navigate);

export const putEmergencyListConfigAPI = async (entityId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/emergencylist/config`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putEmergencyListConfig = (navigate) => withErrorHandling(putEmergencyListConfigAPI, navigate);

export const getEmergencyListDetailsAPI = async (entityId, emergencyListEntityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/emergencylist/${emergencyListEntityId}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getEmergencyListDetails = (navigate) => withErrorHandling(getEmergencyListDetailsAPI, navigate);

export const getEmergencyListTriggerAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/emergencylist/trigger`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getEmergencyListTrigger = (navigate) => withErrorHandling(getEmergencyListTriggerAPI, navigate);

export const getEmergencyListFinishAPI = async (frontDeskEntityId,entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${frontDeskEntityId}/emergencylist/${entityId}/finish`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getEmergencyListFinish = (navigate) => withErrorHandling(getEmergencyListFinishAPI, navigate);

export const getEmergencyListVisitorSafeAPI = async (frontDeskEntityId, entityId, visitorEntityId, status) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `frontdesk/${frontDeskEntityId}/emergencylist/${entityId}/visitor/${visitorEntityId}/safe/${status}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getEmergencyListVisitorSafe = (navigate) => withErrorHandling(getEmergencyListVisitorSafeAPI, navigate);


export const putUserProfileHostAPI = withErrorHandling( async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "host/profile";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
});

export const putUserProfileHost = (navigate) => withErrorHandling(putUserProfileHostAPI, navigate);

export const putUserProfileHostPasswordAPI = withErrorHandling( async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "host/profile/password";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
});

export const putUserProfileHostPassword = (navigate) => withErrorHandling(putUserProfileHostPasswordAPI, navigate);


export const putVisitBulkCheckInAPI = async (visitId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `visit/${visitId}/visitor/bulkcheckin`;

      const config = {
        headers: commonHeaders
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putVisitBulkCheckIn = (navigate) => withErrorHandling(putVisitBulkCheckInAPI, navigate);

export const putVisitBulkCheckOutAPI = async (visitId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `visit/${visitId}/visitor/bulkcheckout`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putVisitBulkCheckOut= (navigate) => withErrorHandling(putVisitBulkCheckOutAPI, navigate);

export const putVisitMeetingRoomAPI = async (visitId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `visit/${visitId}/meetingroom`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putVisitMeetingRoom = (navigate) => withErrorHandling(putVisitMeetingRoomAPI, navigate);

export const checkDeniedPersonAPI = async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      const url = getUri(token) + "denied_person/check";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const checkDeniedPerson = (navigate) => withErrorHandling(checkDeniedPersonAPI, navigate);

export const getSessionFromTmpTokenAPI = async ( tmpToken) => {


  return new Promise(async (resolve, reject) => {
    try {


      const url = getUri() + `authentication/host/tmp_token/${tmpToken}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getSessionFromTmpToken = (navigate) => withErrorHandling(getSessionFromTmpTokenAPI, navigate);

export const getApplicationGlobalAPI = async () => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      //frontdesk/9/terminalsettings/meetingroom
      const url = getUri(token) + `applications/global`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getApplicationGlobal = (navigate) => withErrorHandling(getApplicationGlobalAPI, navigate);

export const getApplicationsLocalAPI = async () => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      // const url = getUri(token) + `applications/local/${frontDeskId}`;
      const url = getUri(token) + `applications/local`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getApplicationsLocal = (navigate) => withErrorHandling(getApplicationsLocalAPI, navigate);

export const getApplicationLocalAPI = async (frontDeskEntityId, applicationId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      // const url = getUri(token) + `applications/local/${frontDeskId}`;
      const url = getUri(token) + `applications/local/${applicationId}/installed/frontdesk/${frontDeskEntityId}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getApplicationLocal = (navigate) => withErrorHandling(getApplicationLocalAPI, navigate);

export const postApplicationLocalInstallAPI = async (frontDeskEntityId, applicationId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      // const url = getUri(token) + `applications/local/${frontDeskId}`;
      const url = getUri(token) + `applications/local/${applicationId}/install/frontdesk/${frontDeskEntityId}`;

      const config = {
        headers: commonHeaders,
      };

      var submitJSON = {};

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const postApplicationLocalInstall = (navigate) => withErrorHandling(postApplicationLocalInstallAPI, navigate);

export const deleteApplicationLocalUninstallAPI = async (frontDeskEntityId, applicationId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      // const url = getUri(token) + `applications/local/${frontDeskId}`;
      const url = getUri(token) + `applications/local/${applicationId}/install/frontdesk/${frontDeskEntityId}`;

      const config = {
        headers: commonHeaders,
      };

      var submitJSON = {};

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteApplicationLocalUninstall = (navigate) => withErrorHandling(deleteApplicationLocalUninstallAPI, navigate);

export const getTextFieldRulesFrontDeskAPI = async (frontDeskEntityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      // const url = getUri(token) + `applications/local/${frontDeskId}`;
      const url = getUri(token) + `textfieldrule/frontdesk/${frontDeskEntityId}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getTextFieldRulesFrontDesk = (navigate) => withErrorHandling(getTextFieldRulesFrontDeskAPI, navigate);

export const getTextFieldRuleAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      // const url = getUri(token) + `applications/local/${frontDeskId}`;
      const url = getUri(token) + `textfieldrule/${entityId}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getTextFieldRule = (navigate) => withErrorHandling(getTextFieldRuleAPI, navigate);

export const postTextFieldRuleAPI = async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "textfieldrule";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const postTextFieldRule = (navigate) => withErrorHandling(postTextFieldRuleAPI, navigate);

export const putTextFieldRuleAPI = async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "textfieldrule";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putTextFieldRule = (navigate) => withErrorHandling(putTextFieldRuleAPI, navigate);

export const deleteTextFieldRuleAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      // const url = getUri(token) + `applications/local/${frontDeskId}`;
      const url = getUri(token) + `textfieldrule/${entityId}`;

      const config = {
        headers: commonHeaders,
      };

      var submitJSON = {};

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteTextFieldRule = (navigate) => withErrorHandling(deleteTextFieldRuleAPI, navigate);


export const getHIDAtomReaderConfigAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/hidatomreader/config`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getHIDAtomReaderConfig = (navigate) => withErrorHandling(getHIDAtomReaderConfigAPI, navigate);

export const putHIDAtomReaderConfigAPI = async (entityId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `frontdesk/${entityId}/hidatomreader/config`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putHIDAtomReaderConfig = (navigate) => withErrorHandling(putHIDAtomReaderConfigAPI, navigate);


export const putAtomReaderDocumentUpdateVisitorAPI = async (frontDeskEntityId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      // const url = getUri(token) + `applications/local/${frontDeskId}`;
      const url = getUri(token) + `frontdesk/${frontDeskEntityId}/hidatomreader/visitor`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putAtomReaderDocumentUpdateVisitor = (navigate) => withErrorHandling(putAtomReaderDocumentUpdateVisitorAPI, navigate);

export const postAtomReaderDocumentUpdateVisitorAPI = async (frontDeskEntityId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      // const url = getUri(token) + `applications/local/${frontDeskId}`;
      const url = getUri(token) + `frontdesk/${frontDeskEntityId}/hidatomreader/visitor`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const postAtomReaderDocumentUpdateVisitor = (navigate) => withErrorHandling(postAtomReaderDocumentUpdateVisitorAPI, navigate);


export const getVisitInvitationApproveVisitorAPI =  async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `invitation_approval/${entityId}/approve`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getVisitInvitationApproveVisitor = (navigate) => withErrorHandling(getVisitInvitationApproveVisitorAPI, navigate);

export const getVisitInvitationDenyVisitorAPI =  async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `invitation_approval/${entityId}/deny`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getVisitInvitationDenyVisitor = (navigate) => withErrorHandling(getVisitInvitationDenyVisitorAPI, navigate);


export const getVisitorDashboardPDFAPI = async (front_desk_entity_id, validFrom, validTo) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `statistics/frontdesk/${front_desk_entity_id}/visitor/dashboard/pdf?valid_from=${validFrom}&valid_to=${validTo}`;
      // const url = getUri(token) + `${front_desk_entity_id}/visitor/dashboard/pdf?valid_from=${validFrom}&valid_to=${validTo}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getVisitorDashboardPDF = (navigate) => withErrorHandling(getVisitorDashboardPDFAPI, navigate);

export const getVisitorVisitCountAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `visitor/${entityId}/visitcount`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getVisitorVisitCount = (navigate) => withErrorHandling(getVisitorVisitCountAPI, navigate);

export const getVisitorVisitHistoryAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `visitor/${entityId}/visits/20`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getVisitorVisitHistory = (navigate) => withErrorHandling(getVisitorVisitHistoryAPI, navigate);

export const getHostVisitHistoryAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `host/${entityId}/visits/20`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getHostVisitHistory = (navigate) => withErrorHandling(getHostVisitHistoryAPI, navigate);

export const getHostLocationFilterAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `host/${entityId}/preregfrontdeskvisibility`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getHostLocationFilter = (navigate) => withErrorHandling(getHostLocationFilterAPI, navigate);

export const putHostLocationFilterAPI = async (entityId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `host/${entityId}/preregfrontdeskvisibility`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON,config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const putHostLocationFilter = (navigate) => withErrorHandling(putHostLocationFilterAPI, navigate);


export const postHostNotificationAPI = async (hostId, visitId, submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `host/${hostId}/visit/${visitId}/send/visitorarrived`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const postHostNotification = (navigate) => withErrorHandling(postHostNotificationAPI, navigate);


export const getMeetingRoomsAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `meetingroom/frontdesk/${entityId}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getMeetingRooms = (navigate) => withErrorHandling(getMeetingRoomsAPI, navigate);


export const getAnalyticsVisitorAPI = async ( frontDeskEntityId, validFrom, validTo) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();


      const url = getUri(token) + `statistics/${frontDeskEntityId}/analytics/visitor?valid_from=${validFrom}&valid_to=${validTo}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getAnalyticsVisitor = (navigate) => withErrorHandling(getAnalyticsVisitorAPI, navigate);


export const getVisitHostsAPI = withErrorHandling( async (visitId) => {

  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `visit/${visitId}/hosts`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });

});

export const getVisitHosts = (navigate) => withErrorHandling(getVisitHostsAPI, navigate);

export const getDeniedPersonAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "denied_person/" + entityId;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getDeniedPerson = (navigate) => withErrorHandling(getDeniedPersonAPI, navigate);

export const postDeniedPersonAPI = withErrorHandling( async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "denied_person";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
});

export const postDeniedPerson = (navigate) => withErrorHandling(postDeniedPersonAPI, navigate);

export const putDeniedPersonAPI = withErrorHandling( async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "denied_person";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
});

export const putDeniedPerson = (navigate) => withErrorHandling(putDeniedPersonAPI, navigate);

export const deleteDeniedPersonAPI = async (id) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "denied_person/"+id;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteDeniedPerson = (navigate) => withErrorHandling(deleteDeniedPersonAPI, navigate);

export const getDeviceAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "device/" + entityId;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getDevice = (navigate) => withErrorHandling(getDeviceAPI, navigate);

export const postDeviceAPI = withErrorHandling( async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "device";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
});

export const postDevice = (navigate) => withErrorHandling(postDeviceAPI, navigate);

export const putDeviceAPI = withErrorHandling( async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "device";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
});

export const putDevice = (navigate) => withErrorHandling(putDeviceAPI, navigate);

export const deleteDeviceAPI = async (id) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "device/"+id;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteDevice = (navigate) => withErrorHandling(deleteDeviceAPI, navigate);

export const getHostVisitDashboardAPI = async (type, frontDeskEntityId, requestParams) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      var url = "";

      if( typeof requestParams !== "undefined"){
        url = getUri(token) + 'host/visit/status'+getRequestParameters(requestParams)+'&visit_type='+type+'&front_desk_entity_id='+frontDeskEntityId;
      }else{
        url = getUri(token) + `host/visit/status?visit_type=${type}&front_desk_entity_id=${frontDeskEntityId}`;
      }


      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getHostVisitDashboard = (navigate) => withErrorHandling(getHostVisitDashboardAPI, navigate);

export const getLicenseAPI = async () => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      //frontdesk/9/terminalsettings/meetingroom
      const url = getUri(token) + `license`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getLicense = (navigate) => withErrorHandling(getLicenseAPI, navigate);

export const postVisitNoteAPI = async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
      const url = getUri(token) + "visitnote";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url,submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {

      handleRequestError(error, reject);
      
    }
  });
};

export const postVisitNote = (navigate) => withErrorHandling(postVisitNoteAPI, navigate);

export const getExternalDeviceAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "device/" + entityId;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getExternalDevice = (navigate) => withErrorHandling(getExternalDeviceAPI, navigate);

export const postExternalDeviceAPI = withErrorHandling( async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "device";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.post(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
});

export const postExternalDevice = (navigate) => withErrorHandling(postExternalDeviceAPI, navigate);

export const putExternalDeviceAPI = withErrorHandling( async (submitJSON) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "device";

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.put(url, submitJSON, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
});

export const putExternalDevice = (navigate) => withErrorHandling(putExternalDeviceAPI, navigate);

export const deleteExternalDeviceAPI = async (id) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "device/"+id;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.delete(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const deleteExternalDevice = (navigate) => withErrorHandling(deleteExternalDeviceAPI, navigate);

export const getApplicationTokenAPI = async (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + "application_token/" + entityId;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getApplicationToken = (navigate) => withErrorHandling(getApplicationTokenAPI, navigate);

export const getOverRideSettingsAPI = async (targetDeviceId, sourceDeviceId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }
 
      const url = getUri(token) + `device/${targetDeviceId}/settings/override/${sourceDeviceId}`;

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      
      handleRequestError(error, reject);
      
    }
  });
};

export const getOverRideSettings = (navigate) => withErrorHandling(getOverRideSettingsAPI, navigate);

