import {  CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme, useTheme } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Products from "scenes/products";
import Customers from "scenes/customers";
import Transactions from "scenes/transactions";
import Geography from "scenes/geography";
import Overview from "scenes/overview";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown";
import Admin from "scenes/admin";
import Performance from "scenes/performance";
import TransactionsInfo from "scenes/transactions/info";
import LoginPage from "scenes/login";
import ResetPage from "scenes/resetpassword";
import Contacts from "scenes/contacts";
import { LicenseInfo } from '@mui/x-license-pro';
import VisitorsVisits from "scenes/visitors/visits";
import VisitorsTypes from "scenes/visitors/settings/visitortypes";
import WelcomeScreen from "scenes/visitors/settings/welcomescreen";
import VisitorSecurity from "scenes/visitors/settings/security";
import UserProfile from "scenes/profile";
import Companies from "scenes/company/companies";
import CompanyDetail from "scenes/company/companyDetail";
import GlobalLocations from "scenes/globalsettings/locations";
import EmployeeDirectory from "scenes/employees";
import EmployeeDetail from "scenes/employees/employeeDetail";
import LocationDetailGeneral from "scenes/location/details/locationDetailGeneral";
import VisitorsDirectory from "scenes/visitors/visitors";
import VisitorDetail from "scenes/visitors/visitors/visitorDetail";
import UserRoles from "scenes/manage/userRoles";
import UserRolesDetail from "scenes/manage/userRolesDetail";
import * as locales from '@mui/material/locale';
import { useFrontDeskManager } from "components/FrontDeskManager";
import VisitDetail from "scenes/visitors/visit/visitDetail";
import AppsOverview from "scenes/apps";
import AppDetail from "scenes/apps/details";
import GlobalVisitorsAnalytics from "scenes/analytics/global/GlobablVisitorsAnalytics";
import GlobalEmployeesAnalytics from "scenes/analytics/global/GlobablEmployeesAnalytics";
import Approvals from "scenes/visitors/settings/approvals";
import VisitNotifications from "scenes/visitors/settings/notifications";
import AdminConfiguration from "scenes/visitors/settings/admins";
import { useUserProfileManager } from "components/UserProfileProvider";
import LoadingApplication from "components/LoadingApplication";
import CompanyRequests from "scenes/company/companyRequests";
import EmployeeBadge from "scenes/employees/employeeBadge";
import VisitNewInvitation from "scenes/visitors/visitNew";
import WorkflowSettingsGeneral from "scenes/visitors/settings/workflow/workflowSettings";

import translationManager from "components/TranslationManager";
import InvitationScreen from "scenes/visitors/settings/invitationscreen";
import { useMenuManager } from "components/MenuManager";
import TextFieldRulesConfig from "scenes/apps/configurations/TextFieldRulesConfig";
import EmergencyListConf from "scenes/apps/configurations/EmergencyListConfig";
import EmergencyList from "scenes/emergenclist";
import EmergencyListDetail from "scenes/emergenclist/details";
import SilentAlarmConfig from "scenes/apps/configurations/SilentAlarmConfig";
import HidAtomReaderConfig from "scenes/apps/configurations/HidAtomReaderConfig";
import VisitorEmailTemplate from "scenes/visitors/settings/visitorsemailtemplates";
import ForgotPage from "scenes/forgotpassword";
import VisualComplianceConfig from "scenes/apps/configurations/VisualComplianceConfig";
import DeniedPerson from "scenes/visitors/settings/deniedperson";
import DeniedPersonDetail from "scenes/visitors/settings/deniedperson/deniedPersonDetail";
import Devices from "scenes/devices";
import DeviceDetail from "scenes/devices/daviceDetail";
import License from "scenes/license/License";
import SystemLog from "scenes/systemlog";

const getCountryCode = () => {
  const browserLanguages = navigator.languages || [navigator.language];
  const primaryLanguage = browserLanguages[0]; // Use the first preferred language
  const parts = primaryLanguage.split("-"); // Split by "-"
  
  // Return the second part (country code) or fallback to default
  return parts[0] ? parts[0].toUpperCase() : "EN"; // Default to "US" if no country code
};


function App() {
  
  const location = useLocation(); // To get the current location and query parameters
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true); // Loading state for token validation
  const { fetchFrontDeskData, getLastSelectedFrontDesk } = useFrontDeskManager();

  const { selectedFrontDesk, selectedFrontDeskTerminalSettings, frontDeskLoading, allAvailableFrontDesks } = useFrontDeskManager();
  const { isAuth,userProfile, userProfleLoading } = useUserProfileManager();

  const dispatch = useDispatch();
  const mode = useSelector((state) => state.global.mode);
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  // const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  // const [locale, setLocale] = useState('deDE');
  var localLanguage = getCountryCode();
  const [locale, setLocale] = useState(localLanguage);

  const theme = useTheme();

  // color design tokens export
 const tokensDark = {
  grey: {
    0: "#ffffff", // manually adjusted
    10: "#f6f6f6", // manually adjusted
    50: "#f0f0f0", // manually adjusted
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414",
    1000: "#000000", // manually adjusted
  },
  primary: {
    100: "#d0d1d5",
    200: "#a1a4ab",
    300: "#727681",
    400: "#1F2A40",
    500: "#141b2d",
    600: "#101624",
    700: "#0c101b",
    800: "#080b12",
    900: "#040509",
  },
  secondary: {
    // yellow
    50: "#f0f0f0", // manually adjusted
    100: "#fff6e0",
    200: "#ffedc2",
    300: "#ffe3a3",
    400: "#ffda85",
    500: "#ffd166",
    600: "#cca752",
    700: "#997d3d",
    800: "#665429",
    900: "#332a14",
    
  },
};

// function that reverses the color palette
function reverseTokens(tokensDark) {
  const reversedTokens = {};
  Object.entries(tokensDark).forEach(([key, val]) => {
    const keys = Object.keys(val);
    const values = Object.values(val);
    const length = keys.length;
    const reversedObj = {};
    for (let i = 0; i < length; i++) {
      reversedObj[keys[i]] = values[length - i - 1];
    }
    reversedTokens[key] = reversedObj;
  });
  return reversedTokens;
}
 const tokensLight = reverseTokens(tokensDark);

 const { fetchMenuData } = useMenuManager();


  const themeWithLocale = useMemo(() => createTheme(themeSettings(mode, tokensDark, tokensLight), locales[locale]),[mode, locale, theme],);

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get("action");
  
    if (action === "reset_password") {
      navigate(`/reset${location.search}`, { replace: true });
    }
  }, [location, navigate]);

    useEffect(() => {

      // if(!loading){

      //   if (!frontDeskLoading) {

      //   }
        if(selectedFrontDesk){
              // translationManager.init(locale, selectedFrontDesk.entityId);
              setTranslationsLoaded(false);
              loadTranslations(selectedFrontDesk.entityId);
        }else{
            setTranslationsLoaded(false);
            // setTranslationsLoaded(true);
            loadTranslations(0);

        }
      // }
    }, [frontDeskLoading, selectedFrontDesk]);

    const loadTranslations = async (frontDeskEntityId) => {
      try {
        if(frontDeskEntityId > 0){
          await translationManager.init(locale, frontDeskEntityId); 
          setTranslationsLoaded(true); // Mark translations as loaded
        }else{
          
          await translationManager.initPreregister(locale, frontDeskEntityId); 
          setTranslationsLoaded(true); // Mark translations as loaded
        }
        
      } catch (error) {
        console.error("Error loading translations:", error);
        // Handle fallback or error state
        setTranslationsLoaded(true); // Mark translations as loaded
      }
    }

    // Show loading until translations are ready
  if (!translationsLoaded) {
    return (
      <ThemeProvider theme={themeWithLocale}>
        <CssBaseline />
        <LoadingApplication />
      </ThemeProvider>
    );
  }


  // if (loading){
  //   return (
  //     <div className="apploading">
  //         <ThemeProvider theme={themeWithLocale}>
  //           <LoadingApplication />
  //         </ThemeProvider>
  //         </div>
  //         );
  // }

  if (userProfleLoading && userProfile == null){
    return (
      <div className="apploading">
          <ThemeProvider theme={themeWithLocale}>
            <LoadingApplication />
          </ThemeProvider>
          </div>
          );
  }
    
   

  if(isAuth){

    if(frontDeskLoading){
      if ( (selectedFrontDesk == null || selectedFrontDeskTerminalSettings  == null)){
        // dispatch(showLoading("init application"))
        return (
          <div className="apploading">
              <ThemeProvider theme={themeWithLocale}>
                <LoadingApplication />
              </ThemeProvider>
              </div>
              );
      }
    }else{
      // fetchMenuData();
    }
  
  
  }


  return (
    <div className="app">
        <ThemeProvider theme={themeWithLocale}>
          <CssBaseline />
          <Routes>

            <Route path="/" element={!isAuth ? <LoginPage /> : <Navigate to="/dashboard"  />} />
            <Route path="/reset" element={<ResetPage />} />
            <Route path="/forgot" element={<ForgotPage />} />

            <Route element={<Layout />}>

              <Route path="/dashboard" element={isAuth ? <Dashboard /> : <Navigate to="/"  />} />
              <Route path="/profile" element={isAuth ? <UserProfile /> : <Navigate to="/"  />} />
              <Route path="/profile/*" element={isAuth ? <UserProfile /> : <Navigate to="/"  />} />

              
              {/* ----------------- VISITORS ---------------------- */}
              <Route path="/visit" element={isAuth ? <VisitorsVisits /> : <Navigate to="/" />} />
              <Route path="/visit/logs" element={ isAuth ? <VisitorsVisits route="logs"/> : <Navigate to="/" />} />
              <Route path="/visit/:paramVisitId/visitor/:paramVisitorId/*" element={isAuth ? <VisitDetail /> : <Navigate to="/" />} />

              <Route path="/visit/:paramVisitId" element={ isAuth ? <VisitDetail /> : <Navigate to="/" />} /> 
              <Route path="/visit/new/invitation" element={ isAuth ? <VisitNewInvitation /> : <Navigate to="/" />} /> 

              <Route path="/visit/approvals" element={isAuth ? <Approvals />: <Navigate to="/" />} />

              
              <Route path="/visit/settings" element={isAuth ? <WelcomeScreen />: <Navigate to="/" />} />
              <Route path="/visit/settings/welcomescreen/*" element={isAuth ? <WelcomeScreen />: <Navigate to="/" />} />
              <Route path="/visit/settings/notifications" element={isAuth ? <VisitNotifications />: <Navigate to="/" />} />
              <Route path="/visit/settings/visitor-types" element={isAuth ? <VisitorsTypes />: <Navigate to="/" />} />
              {/* <Route path="/visit/settings/visitor-types/:id" element={isAuth ? <VisitorsTypesEdit />: <Navigate to="/" />} /> */}
              <Route path="/visit/settings/visitor-types/:id/workflow" element={isAuth ? <WorkflowSettingsGeneral />: <Navigate to="/" />} />
              <Route path="/visit/settings/security" element={isAuth ? <VisitorSecurity />: <Navigate to="/" />} />
              <Route path="/visit/settings/emailtemplate" element={isAuth ? <VisitorEmailTemplate />: <Navigate to="/" />} />

              <Route path="/visit/settings/deniedperson" element={isAuth ? <DeniedPerson />: <Navigate to="/" />} />
              <Route path="/visit/settings/deniedperson/new" element={isAuth ? <DeniedPersonDetail route="new"/>: <Navigate to="/" />} />
              <Route path="/visit/settings/deniedperson/:id/*" element={isAuth ? <DeniedPersonDetail route="update" />: <Navigate to="/" />} />
              
              <Route path="/visit/settings/invitation/*" element={isAuth ? <InvitationScreen />: <Navigate to="/" />} />


              {/* ----------------- Identity ---------------------- */}
              <Route path="/identity/my" element={isAuth ? <EmployeeBadge />: <Navigate to="/" />} />
              <Route path="/identity/company" element={isAuth ? <Companies />: <Navigate to="/" />} />
              <Route path="/identity/company/new" element={isAuth ? <Companies route="new"/>: <Navigate to="/" />} />
              <Route path="/identity/company/:id/*" element={isAuth ? <CompanyDetail/>: <Navigate to="/" />} />
              <Route path="/identity/requests" element={isAuth ? <CompanyRequests />: <Navigate to="/" />} />

              {/* ----------------- Delviveries ---------------------- */}
              <Route path="/deliveries/log" element={isAuth ? <Contacts />: <Navigate to="/" />} />
              <Route path="/deliveries/settings/area" element={isAuth ? <Contacts />: <Navigate to="/" />} />
              <Route path="/deliveries/settings/notifications" element={isAuth ? <Contacts />: <Navigate to="/" />} />

              {/* ----------------- Analytics ---------------------- */}
              <Route path="/analytics" element={isAuth ? <Contacts />: <Navigate to="/" />} />
              <Route path="/analytics/visitors" element={isAuth ? <GlobalVisitorsAnalytics />: <Navigate to="/" />} />
              <Route path="/analytics/employees" element={isAuth ? <GlobalEmployeesAnalytics />: <Navigate to="/" />} />
              <Route path="/analytics/deliveries" element={isAuth ? <Contacts />: <Navigate to="/" />} />
              <Route path="/analytics/reports" element={isAuth ? <Contacts />: <Navigate to="/" />} />

              <Route path="/global/analytics" element={isAuth ? <GlobalVisitorsAnalytics />: <Navigate to="/" />} />
              <Route path="/global/analytics/visitors" element={isAuth ? <GlobalVisitorsAnalytics />: <Navigate to="/" />} />
              <Route path="/global/analytics/employees" element={isAuth ? <GlobalEmployeesAnalytics />: <Navigate to="/" />} />
              <Route path="/global/analytics/deliveries" element={isAuth ? <Contacts />: <Navigate to="/" />} />
              <Route path="/global/analytics/reports" element={isAuth ? <Contacts />: <Navigate to="/" />} />
              

              {/* ----------------- Visitors ---------------------- */}
              <Route path="/visit/visitors" element={isAuth ? <VisitorsDirectory />: <Navigate to="/" />} />
              <Route path="/visitors/new" element={isAuth ? <VisitorDetail route="new"/>: <Navigate to="/" />} />
              <Route path="/visitors/:id/*" element={isAuth ? <VisitorDetail route="update" />: <Navigate to="/" />} />

              {/* ----------------- Emergency List ---------------------- */}
              <Route path="/emergencylist" element={isAuth ? <EmergencyList />: <Navigate to="/" />} />
              <Route path="/emergencylist/list" element={isAuth ? <EmergencyList />: <Navigate to="/" />} />
              <Route path="/emergencylist/:id" element={isAuth ? <EmergencyListDetail />: <Navigate to="/" />} />

              {/* ----------------- Employees ---------------------- */}
              <Route path="/employees" element={isAuth ? <EmployeeDirectory />: <Navigate to="/" />} />
              <Route path="/employees/new" element={isAuth ? <EmployeeDetail route="new"/>: <Navigate to="/" />} />
              <Route path="/employees/:id/*" element={isAuth ? <EmployeeDetail route="update" />: <Navigate to="/" />} />


               {/* ----------------- Apps ---------------------- */}
               <Route path="/apps" element={isAuth ? <AppsOverview />: <Navigate to="/" />} />
               <Route path="/apps/:id" element={isAuth ? <AppDetail />: <Navigate to="/" />} />
               <Route path="/apps/:id/textfieldrulesconf" element={isAuth ? <TextFieldRulesConfig />: <Navigate to="/" />} />
               <Route path="/apps/:id/emergencylistconf" element={isAuth ? <EmergencyListConf />: <Navigate to="/" />} />
               <Route path="/apps/:id/hidatomconf" element={isAuth ? <HidAtomReaderConfig />: <Navigate to="/" />} />
               <Route path="/apps/:id/silentalarmconf" element={isAuth ? <SilentAlarmConfig />: <Navigate to="/" />} />
               <Route path="/apps/:id/visualcomplianceconf" element={isAuth ? <VisualComplianceConfig />: <Navigate to="/" />} />


               {/* ----------------- settings ---------------------- */}
               <Route path="/manage" element={isAuth ? <Contacts />: <Navigate to="/" />} />
               <Route path="/manage/admins" element={isAuth ? <AdminConfiguration />: <Navigate to="/" />} />
               <Route path="/manage/roles" element={isAuth ? <UserRoles />: <Navigate to="/" />} />
               <Route path="/manage/roles/:id" element={isAuth ? <UserRolesDetail />: <Navigate to="/" />} />
               <Route path="/manage/location" element={isAuth ? <LocationDetailGeneral />: <Navigate to="/" />} />


               {/* ----------------- GLOBAL ---------------------- */}
               <Route path="/global/locations" element={isAuth ? <GlobalLocations />: <Navigate to="/" />} />
               <Route path="/global/devices" element={isAuth ? <Devices />: <Navigate to="/" />} />
               <Route path="/global/devices/new" element={isAuth ? <DeviceDetail route="new"/>: <Navigate to="/" />} />
               <Route path="/global/devices/:id/*" element={isAuth ? <DeviceDetail route="update" />: <Navigate to="/" />} />
               <Route path="/global/license" element={isAuth ? <License />: <Navigate to="/" />} />
               <Route path="/global/system/log" element={isAuth ? <SystemLog />: <Navigate to="/" />} />


              {/* <Route path="/locations" element={isAuth ? <Locations /> : <Navigate to="/"  />} /> */}
          
              <Route path="/users" element={isAuth ? <Customers />: <Navigate to="/" />} />

              

              <Route path="/visits" element={isAuth ? <Contacts />: <Navigate to="/" />} />
              

              <Route path="/products" element={isAuth ? <Products />: <Navigate to="/" />} />
              <Route path="/customers" element={isAuth ? <Customers />: <Navigate to="/" />} />
              <Route path="/transactions" element={isAuth ? <Transactions />: <Navigate to="/" />} />
              <Route path="/transactions/:id" element={isAuth ? <TransactionsInfo />: <Navigate to="/" />} />
              <Route path="/geography" element={isAuth ? <Geography />: <Navigate to="/" />} />
              <Route path="/overview" element={isAuth ? <Overview />: <Navigate to="/" />} />
              <Route path="/daily" element={isAuth ? <Daily />: <Navigate to="/" />} />
              <Route path="/monthly" element={isAuth ? <Monthly />: <Navigate to="/" />} />
              <Route path="/breakdown" element={isAuth ? <Breakdown />: <Navigate to="/" />} />
              <Route path="/admin" element={isAuth ? <Admin />: <Navigate to="/" />} />
              <Route path="/performance" element={isAuth ? <Performance />: <Navigate to="/" />} />
            </Route>
          </Routes>
        </ThemeProvider>
    </div>
  );
}

export default App;