import React, { useEffect, useState } from 'react';
import { Paper, Grid, FormControlLabel, Button, useTheme,MenuItem, FormControl } from '@mui/material';
import { Save, DeleteOutline } from '@mui/icons-material';
import { Formik, Form } from 'formik';
import { CssDatePicker, CssInputLabel, CssSelect, CssTextField } from "components/StyledComponents";
import IOSSwitch from 'components/IOSSwitch';
import * as yup from "yup";
import dayjs from 'dayjs';
import { useFrontDeskManager } from 'components/FrontDeskManager';
import tm from 'components/TranslationManager';
import { useUserProfileManager } from 'components/UserProfileProvider';
import { transformApiKeyToCamelCase, transformApiKeyToCamelCaseUpperCase } from 'components/Utils';

const VisitorForm = ({
  initialValues = {}, 
  onSubmit, 
  loadedFormData = {},  
  onDeleteClicked,
  isNonMobile,
  isAddMode = false
}) => {

  const theme = useTheme();

  const { selectedFrontDesk, selectedFrontDeskTerminalSettings} = useFrontDeskManager();
  const { userProfile, userProfleLoading, hasPermission } = useUserProfileManager();

  const [visitorTypes, setVisitorTypes] = useState([]);

  //used for custom labels for custom fields
  const [additionalField, setAdditionalField] = useState([]);

  useEffect(() => {
    // This code will run every time paginationModel changes
    setVisitorTypes(selectedFrontDesk.frontDeskTerminalSettings.visitorTypes)

        const selectedValue = initialValuesRegister?.visitorType || "Visitor";

        console.log(initialValuesRegister)
        const workflowItem = selectedFrontDeskTerminalSettings.checkInWorkflow.find(
          (item) => item.visitorType === selectedValue
        );
    
        if (workflowItem) {
          // Extract visitor_data workflow elements
          const visitorDataElements = workflowItem.workflowElements.filter(
            (element) => element.engineType === "visitor_data"
          );
        
          // Parse additional terminal fields from configuration
          const additionalFieldsConfig = visitorDataElements.flatMap((element) => {
            const config = JSON.parse(element.configuration);
            return config?.additionalTerminalFields || [];
          });
        
          // Transform apiKey and prepare the final additionalFields array
          const transformedFields = additionalFieldsConfig.map((field) => {
            const updatedField = {
              ...field,
              apiKey: transformApiKeyToCamelCase(field.apiKey), // Transform apiKey
              fieldName: tm.translate("dsp" + transformApiKeyToCamelCaseUpperCase(field.apiKey, true), 'Field') === 'Field'
                ? field.fieldName
                : tm.translate("dsp" + transformApiKeyToCamelCaseUpperCase(field.apiKey, true), 'Field'),   
            };
        
            // Update fieldName for specific apiKeys
            if (["c_1", "c_2", "c_3", "c_4", "c_5"].includes(updatedField.apiKey)) {
              updatedField.fieldName = tm.translate(
                `${visitorDataElements[0].hash}_visitor_${updatedField.apiKey}_title`,
                `Custom ${updatedField.apiKey.slice(-1)}` // Default to "Custom <number>"
              );
            }
        
            return updatedField;
          });

          setAdditionalField(transformedFields)

        }

  }, [selectedFrontDesk]);

  // Merge loadedFormData with initialValues, using fallback defaults
  const initialValuesRegister = {
    visitorType: loadedFormData.visitorType || initialValues.visitorType || "Visitor",
    firstname: loadedFormData.firstname || initialValues.firstname || "",
    lastname: loadedFormData.lastname || initialValues.lastname || "",
    birthday: loadedFormData.birthday ? dayjs.utc(loadedFormData.birthday) : null,
    email: loadedFormData.email || initialValues.email || "",
    companyName: loadedFormData.companyName || initialValues.companyName || "",
    mobile: loadedFormData.mobile || initialValues.mobile || "",
    phone: loadedFormData.phone || initialValues.phone || "",
    licensePlate: loadedFormData.licensePlate || initialValues.licensePlate || "",
    deniedPerson: loadedFormData.deniedPerson !== undefined ? loadedFormData.deniedPerson : initialValues.deniedPerson || false,
    idNumber: loadedFormData.idNumber || initialValues.idNumber || "",
    idKey: loadedFormData.idKey || initialValues.idKey || "",
    c_1: loadedFormData.c_1 || initialValues.c_1 || "",
    c_2: loadedFormData.c_2 || initialValues.c_2 || "",
    c_3: loadedFormData.c_3 || initialValues.c_3 || "",
    c_4: loadedFormData.c_4 || initialValues.c_4 || "",
    c_5: loadedFormData.c_5 || initialValues.c_5 || "",
  };

  // Validation schema for required fields
  const registerSchema = yup.object().shape({
    firstname: yup.string().required("First name is required"),
    lastname: yup.string().required("Last name is required"),
  });

  const getFieldName = (apiKey) => {
    const field = additionalField.find((field) => field.apiKey === apiKey);
    // return field ? field.fieldName : `Custom ${apiKey.replace("c_", "")}`;
    return field ? field.fieldName :  tm.translate("dspCustom"+apiKey.replace("c_", ""), `Custom ${apiKey.replace("c_", "")}`);
  };

  if (!selectedFrontDesk) {
    return <div>Loading...</div>;
}

  return (
    <Formik
      initialValues={initialValuesRegister}
      validationSchema={registerSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
      }) => (
        <Form>
          <Paper
            elevation={0}
            style={{ padding: 20, backgroundColor: 'transparent', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}
            sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
          >
            {/* Active Switch */}
            <FormControlLabel
              sx={{ mb: 2, gridColumn: "span 4", backgroundColor: 'transparent' }}
              control={
                <IOSSwitch
                  name = "deniedPerson"
                  sx={{ m: 1 }}
                  checked={values.deniedPerson}
                  onChange={(e) => setFieldValue("deniedPerson", e.target.checked)}
                />
              }
              label={<span style={{ color: theme.palette.secondary.light }}>{tm.translate('btnDeniedPerson', "Denied Person")}</span>}
            />

            <FormControl
              sx={{
                width: "100%",
                "& .MuiInputLabel-root.Mui-focused": {
                  color: theme.palette.secondary[300],
                },
                "&:hover .MuiInputLabel-root": {
                  color: theme.palette.secondary[300],
                },
              }}
            >        
            <CssInputLabel id="demo-multiple-checkbox-label" theme={theme}>{tm.translate('dspVisitorType', 'Visitor Type')}</CssInputLabel>
            <CssSelect
              label={tm.translate('dspVisitorType', 'Visitor Type')}
              labelId="demo-customized-select-label"
              name="visitorType"
              value={values.visitorType}
              onChange={(e) => setFieldValue(`visitorType`, e.target.value)}
              fullWidth
              displayEmpty
              theme={theme}
              error={Boolean(touched.visitorType) && Boolean(errors.visitorType)}
              sx={{ mb: 2 }}
              >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              {visitorTypes.map((element) => (
                <MenuItem key={element.visitorType} value={element.visitorType}>
                  {element.visitorType}
                </MenuItem>
              ))}
            </CssSelect>
            </FormControl>

         
            {/* Email Field */}
            <CssTextField
              label={tm.translate("dspEmal", "Email")}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              variant="outlined"
              fullWidth
              theme={theme}
              sx={{ mb: 2 }}
            />

            {/* First and Last Name Fields */}
            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssTextField
                  label={tm.translate("dspFirstName", "First Name")}
                  name="firstname"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstname}
                  error={Boolean(touched.firstname) && Boolean(errors.firstname)}
                  helperText={touched.firstname && errors.firstname}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                />
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  label={tm.translate("dspLastName", "Last Name")}
                  name="lastname"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastname}
                  error={Boolean(touched.lastname) && Boolean(errors.lastname)}
                  helperText={touched.lastname && errors.lastname}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

            {/* Company and Department Fields */}
            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssTextField
                  label={tm.translate("dspCompanyName", "Company")}
                  name="companyName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyName}
                  error={Boolean(touched.companyName) && Boolean(errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                />
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  label={tm.translate("dspLicensePlate", "License Plate")}
                  name="licensePlate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.licensePlate}
                  error={Boolean(touched.licensePlate) && Boolean(errors.licensePlate)}
                  helperText={touched.licensePlate && errors.licensePlate}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

            

            {/* Mobile and Phone Fields */}
            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssTextField
                  label={tm.translate("dspMobile", "Mobile")}
                  name="mobile"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mobile}
                  error={Boolean(touched.mobile) && Boolean(errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                />
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  label={tm.translate("dspPhone", "Phone")}
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  error={Boolean(touched.phone) && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                
              <CssDatePicker
                label={tm.translate("dspBirthday", "Date of Birth")}
                name="birthday"
                onBlur={handleBlur}
                onChange={(value) => {
                  setFieldValue("birthday", value ? value.valueOf() : null);
                }}
                value={values.birthday ? dayjs(values.birthday) : null}
                fullWidth
                theme={theme}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    error={Boolean(touched.birthday) && Boolean(errors.birthday)}
                    helperText={touched.birthday && errors.birthday}
                    fullWidth
                    theme={theme}
                  />
                )}
                sx={{ width: '95%' }} 
              />
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  label={tm.translate("dspIdNumber", "ID Document")}
                  name="idNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.idNumber}
                  error={Boolean(touched.idNumber) && Boolean(errors.idNumber)}
                  helperText={touched.idNumber && errors.idNumber}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssTextField
                  label={getFieldName("c_1")}
                  name="c_1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.c_1}
                  error={Boolean(touched.c_1) && Boolean(errors.c_1)}
                  helperText={touched.c_1 && errors.c_1}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                />
              </Grid>
              <Grid item xs={6}>
              <CssTextField
                  label={getFieldName("c_2")}
                  name="c_2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.c_2}
                  error={Boolean(touched.c_2) && Boolean(errors.c_2)}
                  helperText={touched.c_2 && errors.c_2}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssTextField
                  label={getFieldName("c_3")}
                  name="c_3"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.c_3}
                  error={Boolean(touched.c_3) && Boolean(errors.c_3)}
                  helperText={touched.c_3 && errors.c_3}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                />
              </Grid>
              <Grid item xs={6}>
              <CssTextField
                  label={getFieldName("c_4")}
                  name="c_4"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.c_4}
                  error={Boolean(touched.c_4) && Boolean(errors.c_4)}
                  helperText={touched.c_4 && errors.c_4}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssTextField
                  label={getFieldName("c_5")}
                  name="c_5"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.c_5}
                  error={Boolean(touched.c_5) && Boolean(errors.c_5)}
                  helperText={touched.c_5 && errors.c_5}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                />
              </Grid>
              <Grid item xs={6}>
              {/* <CssTextField
                  label="Custom 4"
                  name="c4"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.c4}
                  error={Boolean(touched.c4) && Boolean(errors.c4)}
                  helperText={touched.c4 && errors.c4}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                /> */}
              </Grid>
            </Grid>


            {/* Kiosk Visibility Switch */}
            {/* <FormControlLabel
              sx={{ mb: 2, gridColumn: "span 4", backgroundColor: 'transparent' }}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={!values.hideKiosk}
                  onChange={(e) => setFieldValue("hideKiosk", !e.target.checked)}
                />
              }
              label={<span style={{ color: theme.palette.secondary.light }}>Show employee as a host on the visitor kiosk</span>}
            />

            <FormControlLabel
              sx={{ mb: 2, gridColumn: "span 4", backgroundColor: 'transparent' }}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={values.remoteConfirmation}
                  onChange={(e) => setFieldValue("remoteConfirmation", e.target.checked)}
                />
              }
              label={<span style={{ color: theme.palette.secondary.light }}>Allow employee to remote confirm visitors</span>}
            /> */}

        

            {/* Save and Delete Buttons */}
            <Grid container spacing={1} sx={{ mt: 2 }}>
              <Grid item xs={9}>
              { hasPermission("system.visitor.edit") && (

                <Button
                  fullWidth
                  startIcon={<Save />}
                  type="submit" // Submits the form and triggers validation
                  sx={{
                    p: "1rem",
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontWeight: "bold",
                    "&:hover": {
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.secondary.light,
                      fontWeight: "bold",
                    },
                  }}
                >
                  {tm.translate("btnSave", "Save")}
                </Button>
              )}
              </Grid>
              { !isAddMode && (
                <Grid item xs={3}>
                { hasPermission("system.visitor.delete") && (
                  <Button
                    fullWidth
                    startIcon={<DeleteOutline />}
                    onClick={onDeleteClicked} // Trigger delete action
                    sx={{
                      p: "1rem",
                      backgroundColor: theme.palette.secondary.light,
                      color: theme.palette.background.alt,
                      fontWeight: "bold",
                      "&:hover": {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.secondary.light,
                        fontWeight: "bold",
                      },
                    }}
                  >
                    {tm.translate("btnDelete", "Delete")}
                  </Button>
                  )}
                </Grid>
              )}
              
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default VisitorForm;
