import {   DoorSliding } from "@mui/icons-material";
import {  Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper,Typography, useTheme } from "@mui/material";
import React, {  useEffect } from 'react'
import { useState } from "react";
import FlexBetween from "components/FlexBetween";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { assigneBadgeDoorLevels, getBadgeProcessingStatus } from "hooks/HttpRequests";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import tm from "components/TranslationManager";


const DialogAssignDoorLevelOld = ({ open, onClose, onSave, cardInformation, entityTypeParam, currentEntity, visitInformation  }) => {

  const theme = useTheme();
  const dispatch = useDispatch();

  const [entityType, setEntityType] = useState(entityTypeParam);
  const [badgeIdInput, setBadgeIdInput] = useState("");

  const { selectedFrontDeskTerminalSettings } = useFrontDeskManager();
  const [selectedDoorLevels, setSelectedDoorLevels] = useState([]);

  const [assignedDoorLevels, setaAssignedDoorLevels] = useState([]);
  const [doorLevelsLoading, setDoorLevelsLoading] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 200,
    sort: [],
    query:[]
  })

  const loadAssignedDoorLevels = async () => {
    setDoorLevelsLoading(true);
  
    const accessProfiles = selectedFrontDeskTerminalSettings.accessProfiles;
    const assignedAccessLevels = currentEntity.accessLevels
      ? currentEntity.accessLevels.split(";").filter((level) => level.trim() !== "")
      : [];
  
    if (Array.isArray(accessProfiles) && accessProfiles.length > 0) {
      // Filter to show only unassigned access profiles
      const unassignedAccessLevels = accessProfiles.filter(
        (profile) => !assignedAccessLevels.includes(profile.accessProfileId)
      );
  
      setaAssignedDoorLevels(unassignedAccessLevels);
    } else {
      setaAssignedDoorLevels([]); // No profiles available
    }
  
    setDoorLevelsLoading(false);
  };
  

  useEffect(() => {
    

    if(open){
      loadAssignedDoorLevels();
      console.log(visitInformation)
      if(currentEntity != null){

        // if (currentEntity.hasOwnProperty('visitorId')) {
        //     currentEntity["entityId"] = currentEntity.visitorId;
        //     setEntityType("visitor")
        // }

      }
    }

  }, [open]);

  ///////////////////////////////

  const handleSave = () => {

    const selectedDoorLevelString = selectedDoorLevels
    .map((level) => level.accessProfileId?.trim()) // Use `name` from the selected items
    .filter((accessProfileId) => accessProfileId) // Remove empty or undefined `name` values
    .join(";"); // Join the names with `;`


    if(currentEntity.accessLevels != ""){
      currentEntity.accessLevels = currentEntity.accessLevels+ ";"+selectedDoorLevelString
    }else{
      currentEntity.accessLevels = selectedDoorLevelString
    }

    const values = {
      accessLevels: currentEntity.accessLevels,
    };

    assigneBadgeDoorLevels()(visitInformation.visitId, currentEntity.visitorId, values)
    .then((result) => {
      // Use the result.data as needed
      if (result.success) {

          // getProcessingStatus(0, result.data.entity_id, values);
          onSave(currentEntity.accessLevels);
          enqueueSnackbar({
            message: "Door levels updated",
              variant: 'success',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
      }
       

    })
    .catch((error) => {
     

    });

  }

  const getProcessingStatus = (n, entityId, values) => {

      var endTime = 0;

      if(n == 0){
        dispatch(showLoading(""));
        var t = new Date();
        t.setSeconds(t.getSeconds() + 20);
        endTime = t.getTime();
      }else{
          endTime = n
      }
      
          var now = new Date();

          if(now.getTime() < endTime){
    
            getBadgeProcessingStatus()(visitInformation.visitId, entityId)
            .then((result) => {
              // Use the result.data as needed
              if (result.success) {
        
                if(result.data != null && result.data.entityId > 0){

                  if(result.data.processingStatus.toLowerCase() === "finished"){

                    var accessSystemResponse = JSON.parse(result.data.processingData);
                            
                    if(accessSystemResponse != null && accessSystemResponse.success){

                      if(result.data.action.toLowerCase() === 'assign'){

                        dispatch(hideLoading());
                        //was assigned
                        onSave(values);

                      }else if(result.data.action.toLowerCase() === 'unassign'){

                        dispatch(hideLoading());

                      }else if(result.data.action.toLowerCase() === 'update'){

                        dispatch(hideLoading());

                      }else{
                        //something wrong
                        dispatch(hideLoading());

                      }
                      
                    }else{
                      //something wrong
                      dispatch(hideLoading());

                    }
                  }else{
                      //wait a second and then call function again

                      setTimeout(() => getProcessingStatus(endTime, entityId, values), 1000);
                    }

                }else{
                  // Something went wrong
                  dispatch(hideLoading());

                }
                  // onClose();
              }else{
                dispatch(hideLoading());

              }
              
        
            })
            .catch((error) => {
              // if (typeof error.action !== "undefined") {
              //   if (error.action == "relogin") {
              //     navigate("/");
              //   }
              // }
              // if (typeof error.userMessage !== "undefined") {
              //   enqueueSnackbar({
              //     message: error.userMessage,
              //       variant: 'error',
              //       anchorOrigin: {
              //         vertical: 'top', // 'top', 'bottom'
              //         horizontal: 'center', // 'left', 'center', 'right'
              //       },
              //       autoHideDuration: 3000, // 5000 milliseconds = 5 seconds
              //       // persist: true
                  
              //   });
              // }
              // dispatch(hideLoading());
        
            });

          }else{
            // msg : 'Access system took to long \n',
          }
    }

  const handleClose = () => {

      onClose();
  }


  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" 
    >
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>  {tm.translate('lblAssignDoorLevel', "Assign Door Levels")} 
        </DialogTitle>
        <DialogContent>

        <Grid container spacing={2} style={{ backgroundColor: theme.palette.background.alt, padding: "2px 0px 2px 0px" }} >
          {/* Left Column */}
            <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none'  }}>

            {
        doorLevelsLoading ? (
            "Loading..."
        ) : (
            assignedDoorLevels && assignedDoorLevels.length > 0 ? (
                <List dense sx={{ 
                        gridColumn: "span 4" , 
                        margin: '15px 0px 0px 0px',
                        overflowY: 'auto', // Add this to enable vertical scrolling
                        flex: 1 
                       // maxHeight: '310px', // Set a maximum height for the container 
                        
                    }}>
                        {assignedDoorLevels.map((value, index) => {
                            const labelId = `checkbox-list-secondary-label-${index}`;
                            const isChecked = selectedDoorLevels.includes(value); // Check if the item is selected

                            var isLoggedInUser = false
                            if(typeof value.isLoggedInUser !== "undefined"){
                                isLoggedInUser = true;
                            }

                            const handleDoorLevelSelected = (value) => {
                              setSelectedDoorLevels((prevSelected) => {
                                // Check if the accessProfileId exists in the selected list
                                const isAlreadySelected = prevSelected.some(
                                  (item) => item.accessProfileId === value.accessProfileId
                                );
                            
                                if (isAlreadySelected) {
                                  // If already selected, remove it
                                  return prevSelected.filter(
                                    (item) => item.accessProfileId !== value.accessProfileId
                                  );
                                } else {
                                  // If not selected, add it
                                  return [...prevSelected, value];
                                }
                              });
                            };
                            

                          return (
                            <ListItem
                                        key={labelId}
                                        // secondaryAction={
                                        // <IconButton>
                                        //     <Clear style={{ color: sadevioColors.hexColorInvited }}/> 
                                        // </IconButton>
                                        // }
                                        disablePadding
                                    >
                                      <Checkbox
                                        edge="start"
                                        disableRipple
                                        checked={isChecked}
                                        onChange={() => handleDoorLevelSelected(value)}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        sx={{ 
                                          m: "0rem 0rem 0rem 0.5rem",
                                          }}
                                      />
                                    <ListItemButton onClick={() => handleDoorLevelSelected(value)}>
                                        <ListItemAvatar>
                                          <DoorSliding sx={{ color: theme.palette.secondary.light, fontSize: 24 }}/>
                                        </ListItemAvatar>
                                        <ListItemText 
                                                primary={value.accessProfileId} 
                                                secondary={value.description} 
                                          sx={{
                                            '& .MuiTypography-root': {
                                              color: theme.palette.secondary.light, // Change to the color you prefer
                                            },
                                          }}
                                        />
                                        </ListItemButton>
                                    </ListItem>
                                    );
                                })}
                        </List>
                        ) : (
                
                         <Typography sx={{ color: theme.palette.primary[100], mt: 3 }}>{tm.translate('lblNoDoorLevel', "No door levels available to assign")}</Typography>
                          )
                      )
                  }
                
                 
            </Paper>
          
        </Grid>

          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {/* Are you sure you want to execute this action? */}
        
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>  
        <FlexBetween>


            <Box>
                <Button onClick={handleClose} color="primary" sx={{ color: theme.palette.background.alt }}>
                  {tm.translate('btnCancel', "Cancel")}
                </Button>
                {assignedDoorLevels && assignedDoorLevels.length > 0 && (
                <Button onClick={handleSave} color="primary" autoFocus 
                        // disabled={!(assignedDoorLevels && assignedDoorLevels.length > 0)} // Disable if no levels assigned
                        sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
                  {tm.translate('btnAssignDoorLevel', "Assign Selected Door Levels")}
                </Button>
                )}
            </Box>
        </FlexBetween>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogAssignDoorLevelOld