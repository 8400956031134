import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPasswordUser } from "hooks/HttpRequests";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { enqueueSnackbar } from "notistack";

const ForgotForm = () => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const loginSchema = yup.object().shape({
    email: yup.string().email("invalid email").required("required"),
  });
  
  
  const initialValuesLogin = {
    email: "",
  };  

  const login = async (values, onSubmitProps) => {
  
        // var submitJson = {};
  
        dispatch(showLoading( ""))
  
  
        values["application"] = "main";
  
  
        forgotPasswordUser()(values)
        .then((result) => {
          // Use the result.data as needed
          if (result.success) {
            
            enqueueSnackbar({
              message: result.message,
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 7000, 
                // persist: true
              
            });
              navigate("/dashboard");
              
  
          }else{
            enqueueSnackbar('We have a problem!',{ 
              variant: 'error', anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 5000, // 5000 milliseconds = 5 seconds
              // persist: true
            })
  
          }
  
          
          dispatch(hideLoading());
  
        })
        .catch((error) => {
  
          
          if (typeof error.userMessage !== "undefined") {
            enqueueSnackbar({
              message: error.userMessage,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, // 5000 milliseconds = 5 seconds
                // persist: true
              
            });
          }
          dispatch(hideLoading());
  
        });
        
    };

  const handleFormSubmit = async (values, onSubmitProps) => {
      await login(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={ initialValuesLogin }
      validationSchema={ loginSchema}
    >
      {({ 
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
           
            <TextField
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              name="email"
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              sx={{ gridColumn: "span 4" }}
            />
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "2rem 0",
                p: "1rem",
                backgroundColor: palette.secondary.light,
                color: palette.background.alt,
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: palette.secondary.light,
                  color: palette.background.alt,
                  fontWeight: 'bold'
                },
              }}
            >
             RESET PASSWORD
            </Button>
            <Typography
              onClick={() => {
                resetForm();
                navigate("/")
              }}
              sx={{
                textDecoration: "underline",
                color: palette.secondary.light,
                "&:hover": {
                  cursor: "pointer",
                  color: palette.secondary.light,
                  fontWeight: 'bold'
                },
              }}
            >
              Remember password? Login now.
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ForgotForm;