import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import tm from "components/TranslationManager";
import { getEmergencyListStatusColor } from './Utils';

const GridCellEmergencyStatus = ({ rowData }) => {

  // const [age, setAge] = useState(rowData.status || ''); // Assuming 'age' is a property in rowData

  const [isReadOnly, setIsReadOnly] = useState(true); // Control read-only state

  const handleChange = (event) => {
    // setAge(event.target.value);
  };

  const selectStyles = getEmergencyListStatusColor(rowData.status);



  return (
    <FormControl sx={{ width: '100%', margin: '1px', height: '38px' }}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        readOnly= {isReadOnly}
        IconComponent={isReadOnly ? () => null : undefined} // Conditionally remove the icon
        value={rowData.status}
        onChange={handleChange}
        style={selectStyles}
        sx={{ width: '100%', height: '100%', padding: '8px' }} // Adjust height here
      >

        <MenuItem value={"active"} >{tm.translate("lblActive", "Active")}</MenuItem>
        <MenuItem value={"finished"} >{tm.translate("lblFinished", "Finished")}</MenuItem>


        
      </Select>
    </FormControl>
  );
};

export default GridCellEmergencyStatus;
