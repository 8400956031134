import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Box, Typography, useTheme } from "@mui/material";
import { getFrontDeskCheckInsPerVisitorType } from "hooks/HttpRequests";
import { useFrontDeskManager } from "./FrontDeskManager";
import { useNavigate } from "react-router-dom";

const BreakdownChart = ({ isDashboard = false, validFrom, validTo, triggerRefresh  }) => {
  // const { data, isLoading } = useGetSalesQuery();
  const theme = useTheme();
  const navigate = useNavigate();
  const { selectedFrontDesk } = useFrontDeskManager();

  /////////////////////////////////////////
  // Visitor Types
  /////////////////////////////////////////

  const [totalVisits, setTotalVisits] = useState(0);


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [colors, setColors] = useState([
    theme.palette.secondary[500],
    theme.palette.secondary[300],
    theme.palette.secondary[300],
    theme.palette.secondary[500],
  ]);

  useEffect(() => {
   
    loadData()

  }, []);

  useEffect(() => {
  
      loadData()
    
  }, [triggerRefresh]);

  const loadData = async () => {

    setLoading(true);

    try {
      const result = await getFrontDeskCheckInsPerVisitorType(navigate)(selectedFrontDesk.entityId, validFrom.valueOf(), validTo.valueOf());
    

      const numberOfProperties = Object.keys(result.data).length;

      const localColors = [];

      for (let i = 0; i < numberOfProperties; i++) {
          if (i % 2 === 0) {
            localColors.push(theme.palette.secondary[500]);
          } else {
            localColors.push(theme.palette.secondary[300]);
          }
      }

      setColors(localColors)

      var totalVisits = 0;
      var visitorTypes = [];
      var n = 0;
      for (const key in result.data) {
          if (result.data.hasOwnProperty(key)) {
              // console.log(`${key}: ${result.data[key]}`);
              var tmp = {};
              tmp["id"] = key;;
              tmp["label"] = key;
              tmp["value"] = result.data[key];
              tmp["color"] = localColors[n];

              totalVisits = totalVisits+result.data[key];

              visitorTypes.push(tmp);
              n++;
          }
      }

      setTotalVisits(totalVisits)


      setData(visitorTypes);
      setLoading(false);

    } catch (error) {
      if (error?.action === 'relogin') {
        // navigate('/');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (!data || loading) return "Loading...";


  return (
    <Box
      height={isDashboard ? "400px" : "100%"}
      width={undefined}
      minHeight={isDashboard ? "325px" : undefined}
      minWidth={isDashboard ? "325px" : undefined}
      position="relative"
      id="visitor_by_type"
    >
      {totalVisits > 0 ? (
      <ResponsivePie
        data={data}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: theme.palette.secondary[200],
              },
            },
            legend: {
              text: {
                fill: theme.palette.secondary[200],
              },
            },
            ticks: {
              line: {
                stroke: theme.palette.secondary[200],
                strokeWidth: 1,
              },
              text: {
                fill: theme.palette.secondary[200],
              },
            },
          },
          legends: {
            text: {
              fill: theme.palette.secondary[200],
            },
          },
          tooltip: {
            container: {
              color: theme.palette.primary.main,
            },
          },
        }}
        colors={{ datum: "data.color" }}
        margin={
          isDashboard
            ? { top: 40, right: 80, bottom: 100, left: 50 }
            : { top: 40, right: 80, bottom: 80, left: 80 }
        }
        sortByValue={true}
        innerRadius={0.45}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        enableArcLinkLabels={!isDashboard}
        arcLinkLabelsTextColor={theme.palette.secondary[200]}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: isDashboard ? 20 : 0,
            translateY: isDashboard ? 50 : 56,
            itemsSpacing: 0,
            itemWidth: 85,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: theme.palette.primary[500],
                },
              },
            ],
          },
        ]}
      />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography variant="h6" color={theme.palette.text.secondary}>
            No visits
          </Typography>
        </Box>
      )}
      <Box
        position="absolute"
        top="50%"
        left="50%"
        color={theme.palette.secondary[400]}
        textAlign="center"
        pointerEvents="none"
        sx={{
          transform: isDashboard
            ? "translate(-95%, -170%)"
            : "translate(-50%, -100%)",
        }}
      >
        <Typography variant="h6">
          { (!isDashboard) && "Total Visitors:"} {totalVisits}
        </Typography>
      </Box>
    </Box>
  );
};

export default BreakdownChart;