import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Header from "components/Header";
import { useNavigate } from "react-router-dom";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { getApplicationsLocal } from "hooks/HttpRequests";

const Product = ({
  _id,
  name,
  description,
  logo,
  price,
  rating,
  category,
  supply,
  stat,
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
      onClick={() => {
        // Handle click event here
        console.log(_id)
        navigate("/apps/"+_id)

      }}
    >
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
        "&:hover": { color: theme.palette.secondary.light },
      }}
    >
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color={theme.palette.secondary[700]}
          gutterBottom
        >
          {category}
        </Typography>
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
            m= "0rem 0rem 1.0rem 0rem"
            >
          <img
            src={ `${logo}`} 
            alt="App Logo"
            style={{
              width: '100px',
              height: '100px',
              borderRadius: '10%',
              background: theme.palette.text.secondary,
            }}
          />
        <Typography variant="h3" component="div" sx={{
              fontSize: theme.typography.fontSize.medium ,
              m: "0rem 0rem 0rem 1.0rem" ,
              "&:hover": { color: theme.palette.secondary.light },
              }}>
          {name}
        </Typography>
        </Box>

        <Typography variant="body" sx={{
              fontSize: theme.typography.fontSize.medium ,
              m: "2.0rem 0rem 0rem 0rem" ,
              "&:hover": { color: theme.palette.secondary.light },
              }}>{description}
          </Typography>
      </CardContent>
      {/* <CardActions>
        <Button
          variant="primary"
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          See More
        </Button>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <Typography>id: {_id}</Typography>
          <Typography>Supply Left: {supply}</Typography>
          <Typography>Yearly Sales This Year: </Typography>
          <Typography>Yearly Units Sold This Year: </Typography>
        </CardContent>
      </Collapse> */}
    </Card>
    </Box>
  );
};

const AppsOverview = () => {
  
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const { selectedFrontDesk, selectedFrontDeskTerminalSettings, frontDeskLoading, convertToFrontDeskTimeZone } = useFrontDeskManager();
  const [loading, setLoading] = useState(true);
  const [loadedFormData, setLoadedFormData] = useState(null);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  // useEffect(() => {
  //   setGridLoading(true);

  //   var tmpData = { data: [], total_count: 0 };

  //   var tmp = {};
  //   tmp["_id"] = 12;
  //   tmp["name"] = "Identiv Velocity";
  //   tmp["description"] = "REST API integration to Tdentiv Velocity";
  //   tmp["logo"] = "identiv_logo.png";
  //   tmp["category"] = "access_control";
  //   tmp["price"] = 12;
  //   tmp["supply"] = 12;
  //   tmp["stat"] = 12;

  //   tmpData.data.push(tmp);

  //   var tmp = {};
  //   tmp["_id"] = 13;
  //   tmp["name"] = "Siemens Siport";
  //   tmp["description"] = "REST API integration to Siemens Siport";
  //   tmp["logo"] = "siemens_logo.png";
  //   tmp["category"] = "access_control";
  //   tmp["price"] = 12;
  //   tmp["supply"] = 12;
  //   tmp["stat"] = 12;

  //   tmpData.data.push(tmp);

  //   var tmp = {};
  //   tmp["_id"] = 14;
  //   tmp["name"] = "Genetec";
  //   tmp["description"] = "REST API integration to Genetec";
  //   tmp["logo"] = "genetec_logo.png";
  //   tmp["category"] = "access_control";
  //   tmp["price"] = 12;
  //   tmp["supply"] = 12;
  //   tmp["stat"] = 12;

  //   tmpData.data.push(tmp);

  //   var tmp = {};
  //   tmp["_id"] = 14;
  //   tmp["name"] = "Text Field Rules";
  //   tmp["description"] = "Define custom rules";
  //   tmp["logo"] = "genetec_logo.png";
  //   tmp["category"] = "sadevio";
  //   tmp["price"] = 12;
  //   tmp["supply"] = 12;
  //   tmp["stat"] = 12;

  //   tmpData.data.push(tmp);

  //   tmpData.total_count = 3;

  //   setData(tmpData);
  //   setGridLoading(false);

  // }, []);

  useEffect(() => {

    if (selectedFrontDesk) {
      loadData();
    }
  }, [selectedFrontDesk, triggerRefresh]);

  const loadData = async () => {

    setLoading(true)

    //do an autocomplete function

    getApplicationsLocal(navigate)()
      .then((result) => {

        // Use the result.data as needed
        if(result.success){

          setLoadedFormData(result.data)

        }
        setLoading(false)

      })
      .catch((error) => {

        console.log(error);

        setLoading(false)
      });

  }

  if ( loading || !loadedFormData || !selectedFrontDesk ) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Apps" subtitle="All of our integrations ready to use." />
      {loadedFormData || !loading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {loadedFormData?.map(
            ({
              entityId,
              applicationName,
              shortDescription,
              iconUrl,
              price,
              rating,
              category,
              supply,
              stat,
            }) => (
              <Product
                key={entityId}
                _id={entityId}
                name={applicationName}
                description={shortDescription}
                logo={iconUrl}
                price={price}
                rating={rating}
                category={category}
                supply={supply}
                stat={stat}
              />
            )
          )}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default AppsOverview;
