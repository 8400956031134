import React from 'react';
import { Box, Button, List, ListItem, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@emotion/react';
import { CssTextField } from './StyledComponents';

const PhoneNumberManager = ({ title, value, onChange, sx }) => {
  const [inputPhone, setInputPhone] = React.useState('');
  const theme = useTheme();

  const handleAddPhone = () => {
    if (!inputPhone.trim() || !isValidPhoneNumber(inputPhone.trim())) return;
    const phoneList = value.split(';').filter(phone => phone.trim() !== '');
    phoneList.push(inputPhone.trim());
    onChange(phoneList.join(';'));
    setInputPhone('');
  };

  const handleRemovePhone = (phoneToRemove) => {
    const phoneList = value.split(';').filter(phone => phone.trim() !== phoneToRemove);
    onChange(phoneList.join(';'));
  };

  const isValidPhoneNumber = (phone) => {
    // Basic validation for phone numbers (e.g., allow digits, spaces, dashes, parentheses, +)
    const phoneRegex = /^[+]?[\d\s\-().]+$/;
    return phoneRegex.test(phone);
  };

  return (
    <Box sx={{ width: '100%', margin: 'auto', padding: 2, border: '1px solid #ccc', borderRadius: 2, ...sx }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {title}
      </Typography>

      <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
        <CssTextField
          fullWidth
          label="Add Phone Number"
          value={inputPhone}
          onChange={(e) => setInputPhone(e.target.value)}
          size="small"
          error={!!inputPhone && !isValidPhoneNumber(inputPhone)} // Show error if invalid
          helperText={
            !!inputPhone && !isValidPhoneNumber(inputPhone)
              ? 'Enter a valid phone number'
              : ''
          }
          theme={theme}
        />
        <Button variant="contained" onClick={handleAddPhone} disabled={!inputPhone.trim() || !isValidPhoneNumber(inputPhone.trim())}>
          Add
        </Button>
      </Box>

      <List>
        {(value || '') // Fallback to an empty string if value is undefined
          .split(';')
          .filter((phone) => phone.trim() !== '')
          .map((phone, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" onClick={() => handleRemovePhone(phone)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              {phone}
            </ListItem>
          ))}
      </List>
    </Box>
  );
};

export default PhoneNumberManager;
