import React from 'react';
import { Paper, Grid, Button, useTheme } from '@mui/material';
import { Save, DeleteOutline } from '@mui/icons-material';
import { Formik, Form } from 'formik';
import { CssDatePicker, CssTextField } from "components/StyledComponents";
import * as yup from "yup";
import dayjs from 'dayjs';

const DeniedPersonForm = ({
  initialValues = {}, 
  onSubmit, 
  loadedFormData = {},  
  onDeleteClicked,
  isNonMobile,
  isAddMode = false
}) => {

  const theme = useTheme();

  // Merge loadedFormData with initialValues, using fallback defaults
  const formInitialValues = {
    name: loadedFormData.name || initialValues.name || "",
    address: loadedFormData.address || initialValues.address || "",
    birthday: loadedFormData.birthday ? dayjs.utc(loadedFormData.birthday) : null,
    city: loadedFormData.city || initialValues.city || "",
    stateCode: loadedFormData.stateCode || initialValues.stateCode || "",
    zip: loadedFormData.zip || initialValues.zip || "",
    countryCode: loadedFormData.countryCode || initialValues.countryCode || "",
    company: loadedFormData.company || initialValues.company || "",
    gender: loadedFormData.gender || initialValues.gender || "",
    reason: loadedFormData.reason || initialValues.reason || "",
    notes: loadedFormData.notes || initialValues.notes || "",
    source: loadedFormData.source || initialValues.source || "",

  };

  // Validation schema for required fields
  const registerSchema = yup.object().shape({
    name: yup.string().required("First name is required"),
    reason: yup.string().required("Reason is required"),
    source: yup.string().required("Source is required"),

  });

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={registerSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
      }) => (
        <Form>
          <Paper
            elevation={0}
            style={{ padding: 20, backgroundColor: 'transparent', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}
            sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
          >


            {/* Email Field */}
            <CssTextField
              label="name"
              name="name"
              required
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              error={Boolean(touched.name) && Boolean(errors.name)}
              helperText={touched.email && errors.name}
              variant="outlined"
              fullWidth
              theme={theme}
              sx={{ mb: 2 }}
            />
            
            <CssTextField
              label="address"
              name="address"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.address}
              error={Boolean(touched.address) && Boolean(errors.address)}
              helperText={touched.address && errors.address}
              variant="outlined"
              fullWidth
              theme={theme}
              sx={{ mb: 2 }}
            />
            {/* First and Last Name Fields */}
            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssTextField
                  label="City"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.city}
                  error={Boolean(touched.city) && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                />
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  label="State"
                  name="stateCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.stateCode}
                  error={Boolean(touched.stateCode) && Boolean(errors.stateCode)}
                  helperText={touched.stateCode && errors.stateCode}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssTextField
                  label="Zip"
                  name="zip"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.zip}
                  error={Boolean(touched.zip) && Boolean(errors.zip)}
                  helperText={touched.zip && errors.zip}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                />
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  label="Country"
                  name="countryCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.countryCode}
                  error={Boolean(touched.countryCode) && Boolean(errors.countryCode)}
                  helperText={touched.countryCode && errors.countryCode}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

            {/* Company and Department Fields */}
            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssTextField
                  label="Company"
                  name="company"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company}
                  error={Boolean(touched.company) && Boolean(errors.company)}
                  helperText={touched.company && errors.company}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                />
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  label="Gender"
                  name="gender"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.gender}
                  error={Boolean(touched.gender) && Boolean(errors.gender)}
                  helperText={touched.gender && errors.gender}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

            

            <CssTextField
              label="reason"
              name="reason"
              required
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.reason}
              error={Boolean(touched.reason) && Boolean(errors.reason)}
              helperText={touched.reason && errors.reason}
              variant="outlined"
              fullWidth
              theme={theme}
              sx={{ mb: 2 }}
            />

            <CssTextField
              label="notes"
              name="notes"
              onBlur={handleBlur}
              onChange={handleChange}
              multiline
              rows={4} // Set the number of visible rows
              value={values.notes}
              error={Boolean(touched.notes) && Boolean(errors.notes)}
              helperText={touched.notes && errors.notes}
              variant="outlined"
              fullWidth
              theme={theme}
              sx={{ mb: 2 }}
            />

            {/* Mobile and Phone Fields */}
            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssDatePicker
                  label="Date of Birth"
                  name="birthday"
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue("birthday", value ? value.valueOf() : null);
                  }}
                  value={values.birthday ? dayjs(values.birthday) : null}
                  fullWidth
                  theme={theme}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      error={Boolean(touched.birthday) && Boolean(errors.birthday)}
                      helperText={touched.birthday && errors.birthday}
                      fullWidth
                      theme={theme}
                    />
                  )}
                  sx={{ width: '95%' }} 
                />
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  label="Source"
                  name="source"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.source}
                  error={Boolean(touched.source) && Boolean(errors.source)}
                  helperText={touched.source && errors.source}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

        

            {/* Save and Delete Buttons */}
            <Grid container spacing={1} sx={{ mt: 2 }}>
              <Grid item xs={9}>
                <Button
                  fullWidth
                  startIcon={<Save />}
                  type="submit" // Submits the form and triggers validation
                  sx={{
                    p: "1rem",
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontWeight: "bold",
                    "&:hover": {
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.secondary.light,
                      fontWeight: "bold",
                    },
                  }}
                >
                  Save
                </Button>
              </Grid>
              { !isAddMode && (
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    startIcon={<DeleteOutline />}
                    onClick={onDeleteClicked} // Trigger delete action
                    sx={{
                      p: "1rem",
                      backgroundColor: theme.palette.secondary.light,
                      color: theme.palette.background.alt,
                      fontWeight: "bold",
                      "&:hover": {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.secondary.light,
                        fontWeight: "bold",
                      },
                    }}
                  >
                    DELETE
                  </Button>
                </Grid>
              )}
              
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default DeniedPersonForm;
