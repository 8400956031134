import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, useTheme } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useRef } from 'react'
import { useState } from "react";
import { getSessionToken, getUri } from "hooks/HttpRequests";
import EmployeeForm from "scenes/employees/details/employeeForm";



const DialogAddEmployee = ({ open, onClose, onSave, currentEntity, entityTypeParam, visitId = -1  }) => {

  const [picuteFromEntity, setPictureFromEntity] = useState("");
  const [entityType, setEntityType] = useState(entityTypeParam);

  const theme = useTheme();
  const {palette} = useTheme();

  useEffect(() => {
    
    setReadFile(null)
    setSelectedFile(null)
    setTakePictureMode(false);

    var localEntityId = "host";

    if(typeof currentEntity !== "undefined" && currentEntity != null){
      if (currentEntity.hasOwnProperty('visitorId')) {
          currentEntity["entityId"] = currentEntity.visitorId;
          localEntityId = "visitor"
      }

      setEntityType(localEntityId);

      if(currentEntity.picturePath != null && currentEntity.picturePath != ""){

        setReadFile(getUri(getSessionToken())+'/'+localEntityId+'/'+currentEntity.entityId+'/picture/'+currentEntity.picturePath)
        setPictureFromEntity(getUri(getSessionToken())+'/'+localEntityId+'/'+currentEntity.entityId+'/picture/'+currentEntity.picturePath)
      }
    }
    

  }, [open]);

  ///////////////////////////////

  const webcamRef = useRef(null);
  const [webcamError, setWebcamError] = useState(null);

  const capture = useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setReadFile(imageSrc)
      setTakePictureMode(false);

    },
    [webcamRef]
  );

  const onCameraError = (error) => {
      setWebcamError(error)
      setTakePictureMode(!takePictureMode);
  }

  ///////////////////////////////
  const [selectedFile, setSelectedFile] = useState(null);
  const [readFile, setReadFile] = useState(null);
  const [takePictureMode, setTakePictureMode] = useState(false);
  const [pressedRemovePicture, setPressedRemovePicture] = useState(false);


  const handleSave = () => {

      if((readFile != null && readFile != "") || pressedRemovePicture){
        // const file = selectedFile

        // // Create FormData object
        // const formData = new FormData();
        // formData.append('file', file);

        var base64Image = ""

        if(readFile != null && readFile != ""){
            base64Image = readFile;
            if(readFile.indexOf("base64,") > 0){
              base64Image = readFile.substr(readFile.indexOf("base64,")+7);
            }
        }

        var data = {};
        data["entityId"] = currentEntity.entityId;
        data["fileType"] = "png";
        data["picture"] = base64Image;

        if(visitId > 0){
          data["visitId"] = base64Image;
        }

        // Send the file to the server
        axios.post(getUri(getSessionToken())+'/'+entityType+'/picture', data)
          .then((response) => {
            // File upload successful
            console.log(response.data);
            onSave(response.data);
          })
          .catch((error) => {
            // File upload failed
            console.log(error);
        });
      }else{
        onClose();
      }
  }

  const handleClose = () => {

      onClearImage()
      onClose();
  }

  const onClearImage = () => {

    setReadFile(null)
    setSelectedFile(null)
    setTakePictureMode(false);

    setPressedRemovePicture(true);

    // setReadFile(picuteFromEntity)
    // setPictureFromEntity(picuteFromEntity)

  }

  const activateTakePicture = () => {
    setTakePictureMode(!takePictureMode);
    if(!takePictureMode){
      setReadFile(null)
      setSelectedFile(null)
    }
  }


  return (
    <Dialog open={open} onClose={onClose}>
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>New Employee</DialogTitle>
        <DialogContent>
        <Box sx={{ 
                    m: "0rem 0rem 0rem 0rem",
                    borderRadius: 1,
                    borderColor: theme.palette.secondary[100],
                    border: 0 , p: 5,
                    backgroundColor:theme.palette.background.alt,
             }}> 
            <EmployeeForm
              // initialValues={initialFormValues}
              // validationSchema={formSchema}
              // onSubmit={handleFormSubmit}
              // theme={theme}
              // loadedFormData={currentEntity}
            />
          </Box>


          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {/* Are you sure you want to execute this action? */}
        
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>  
          {/* <Button onClick={onClearImage} color="primary">remove photo</Button> */}

          <div>
            <Button onClick={handleClose} color="primary" sx={{ color: theme.palette.background.alt }}>
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" autoFocus sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
              Save
            </Button>
          </div>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogAddEmployee