import {  Add, Save } from "@mui/icons-material";
import { Autocomplete, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, ListItemAvatar, ListItemText, Paper, useMediaQuery, useTheme } from "@mui/material";
import React, {  useEffect, useRef } from 'react'
import { useState } from "react";
import { addVisitorToVisit, autocompleteVisitor, getSessionToken, getUri, postVisitor } from "hooks/HttpRequests";
import { CssTextField, stringAvatar } from "components/StyledComponents";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import tm from 'components/TranslationManager';
import * as yup from "yup";
import { useFrontDeskManager } from "components/FrontDeskManager";
import IOSSwitch from "components/IOSSwitch";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { transformApiKeyToCamelCase, transformApiKeyToCamelCaseUpperCase } from "components/Utils";
import { FaPassport } from "react-icons/fa";


const DialogAddVisitor = ({ open, onClose, onSave, currentEntity, entityTypeParam, visitId = -1, onScanPassport  }) => {

  const { palette } = useTheme();
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const [picuteFromEntity, setPictureFromEntity] = useState("");
  const [entityType, setEntityType] = useState(entityTypeParam);;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef();
  
  const { selectedFrontDesk, selectedFrontDeskTerminalSettings} = useFrontDeskManager();

  const [validationSchema, setValidationSchema] = useState(yup.object());

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedVisitors, setSelectedVisitors] = useState([]);

  const defaultVisitorType = { label: 'Visitor', value: "Visitor" };
  const [allVisitorTypes, setAllVisitorTypes] = useState([]);

  const [selectedVisitorType, setSelectedVisitorType] = useState(defaultVisitorType);
  
  const [additionalFields, setAdditionalFields] = useState([]);

  const initialValuesRegister = {
    visitorType: "Visitor",
    lastname: "",
    firstname: "",
    birthday:  null,
    email: "",
    department: "",
    companyName: "",
    mobile: "",
    phone: "",
    licensePlate: "",
    deniedPerson: false,
    idNumber: "",
    idKey: "",
    c_1: "",
    c_2: "",
    c_3: "",
    c_4: "",
    c_5: "",
    
  };

  const handleSubmit = (values) => {


    var currentEntity = {};

    currentEntity["deniedPerson"] = values.deniedPerson

    currentEntity["visitorType"] = values.visitorType

    currentEntity["email"] = values.email

    currentEntity["firstname"] = values.firstname
    currentEntity["lastname"] = values.lastname
    currentEntity["department"] = values.department
    currentEntity["mobile"] = values.mobile
    currentEntity["phone"] = values.phone
    currentEntity["companyName"] = values.companyName
    currentEntity["idNumber"] = values.idNumber
    
    currentEntity["licensePlate"] = values.licensePlate    

    currentEntity["c_1"] = values.c_1
    currentEntity["c_2"] = values.c_2
    currentEntity["c_3"] = values.c_3
    currentEntity["c_4"] = values.c_4
    currentEntity["c_5"] = values.c_5


    if(visitId ==-1){
      //IF VISIT WAS NIT SAVED YET, just create visitor or lookup visitorId
      var submitData= {}
      submitData["data"]= currentEntity;
  
      postVisitor(navigate)(submitData)
        .then((result) => {
          // Use the result.data as needed
          if (result.success) {
        
            var data = result.data;
            addVisitorToVisitRequest(data);
          }
        })
        .catch((error) => {
          if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
              navigate("/");
            }
          }
        });

    }else{
      var submitData= {}
      submitData["data"]= currentEntity;
  
      postVisitor(navigate)(submitData)
        .then((result) => {
          // Use the result.data as needed
          if (result.success) {
        
              var data = result.data;
              addVisitorToVisitRequest(data);
          }
        })
        .catch((error) => {
          if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
              navigate("/");
            }
          }
        });
    }
    

  };

  useEffect(() => {

    var visitorTypes = [];
    // visitorTypes.push(defaultVisitorType)
    for (const item of selectedFrontDeskTerminalSettings.checkInWorkflow) {
      var tmp = { label: item.visitorType, value: item.visitorType };
      visitorTypes.push(tmp)
      if(item.defaultType){
        const defaultVisitorType = { label: item.visitorType, value: item.visitorType };
        setSelectedVisitorType(defaultVisitorType)
        initialValuesRegister["visitorType"]= item.visitorType
      }
    }

    setAllVisitorTypes(visitorTypes);
    var value = {}
    value["value"] = defaultVisitorType.visitorType
    handleVisitorTypeSelectChange(null, value)


    var visitorModel = {}

    var uiFields = []
            
    var visitor_first_name = {};			
    visitorModel["visitor_first_name"] = "";
    visitor_first_name["apiKey"] = "visitor_first_name";
    visitor_first_name["entityType"] = "visitor";
    visitor_first_name["fieldName"] = "First name";
    visitor_first_name["fieldType"] = "string";
    visitor_first_name["maxFieldLength"] = 250;
    visitor_first_name["required"] = true;
    
    uiFields.push(visitor_first_name);
    
    var visitor_last_name = {};			
    visitorModel["visitor_last_name"] = "";
    visitor_last_name["apiKey"] = "visitor_last_name";
    visitor_last_name["entityType"] = "visitor";
    visitor_last_name["fieldName"] = "Last name";
    visitor_last_name["fieldType"] = "string";
    visitor_last_name["maxFieldLength"] = 250;
    visitor_last_name["required"] = true;
    
    uiFields.push(visitor_last_name);

    var visitor_id = {};
    visitor_id["apiKey"] = "visitorId";
    visitor_id["entityType"] = "visitor";
    visitor_id["fieldName"] = "Visitor id";
    visitor_id["fieldType"] = "visitor_id";
    visitor_id["maxFieldLength"] = 250;
    visitor_id["required"] = false;

    uiFields.push(visitor_id);

    var visitor_visitor_type = {};
    visitorModel["visitor_visitor_type"] = defaultVisitorType.value;
    visitor_visitor_type["apiKey"] = "visitor_visitor_type";
    visitor_visitor_type["entityType"] = "visitor";
    visitor_visitor_type["fieldName"] = "visitor_visitor_type id";
    visitor_visitor_type["fieldType"] = "visitor_visitor_type";
    visitor_visitor_type["maxFieldLength"] = 250;
    visitor_visitor_type["required"] = false;

    uiFields.push(visitor_visitor_type);

    const validationSchemaTmp = generateValidationSchema(uiFields);
    setValidationSchema(validationSchemaTmp)

  }, [open]);

  ///////////////////////////////

  const handleVisitorTypeSelectChange = (event, value) => {
    // Your existing logic
  
    const selectedValue = value?.value || "Visitor";
    setSelectedVisitorType(selectedValue);

    const workflowItem = selectedFrontDeskTerminalSettings.checkInWorkflow.find(
      (item) => item.visitorType === selectedValue
    );

    if (workflowItem) {
      // Extract visitor_data workflow elements
      const visitorDataElements = workflowItem.workflowElements.filter(
        (element) => element.engineType === "visitor_data"
      );
    
      // Parse additional terminal fields from configuration
      const additionalFieldsConfig = visitorDataElements.flatMap((element) => {
        const config = JSON.parse(element.configuration);
        return config?.additionalTerminalFields || [];
      });
    
      // Transform apiKey and prepare the final additionalFields array
      const transformedFields = additionalFieldsConfig.map((field) => {
        const updatedField = {
          ...field,
          apiKey: transformApiKeyToCamelCase(field.apiKey), // Transform apiKey
          fieldName: tm.translate("dsp" + transformApiKeyToCamelCaseUpperCase(field.apiKey, true), 'Field') === 'Field'
            ? field.fieldName
            : tm.translate("dsp" + transformApiKeyToCamelCaseUpperCase(field.apiKey, true), 'Field'),   
        };
    
        // Update fieldName for specific apiKeys
        if (["c_1", "c_2", "c_3", "c_4", "c_5"].includes(updatedField.apiKey)) {
          updatedField.fieldName = tm.translate(
            `${visitorDataElements[0].hash}_visitor_${updatedField.apiKey}_title`,
            `Custom ${updatedField.apiKey.slice(-1)}` // Default to "Custom <number>"
          );
        }
    
        return updatedField;
      });

 
    
      // Update state
      setAdditionalFields(transformedFields);
    } else {
      // Fallback if no workflowItem is available
      setAdditionalFields([]);
    }
    
  };

  ///////////////////////////////


  const handleSave = () => {

        // const file = selectedFile

        // // Create FormData object
        // const formData = new FormData();
        // formData.append('file', file);

        var base64Image = ""

      

     
  }

  const handleReset = () => {

    setSearchResults([]);
    setSearchQuery("");

  }

  const handleClose = () => {

      onClose();
  }

  const registerSchema = yup.object().shape({
    firstname: yup.string().required("First name is required"),
    lastname: yup.string().required("Last name is required"),
    visitorType: yup.string().required("Visitor type is required"),
    ...additionalFields.reduce((schema, field) => {
      schema[field.apiKey] = field.required
        ? yup.string().required(`${field.fieldName} is required`)
        : yup.string();
      return schema;
    }, {}),
  });

  // Function to handle adding an item to selectedItems
  const handleAddItem = (item) => {
    setSelectedItems([...selectedItems, item]);
    // You may also want to update the server/database with the added item
  };

  const handleSelectOption = (newValue) => {
    if (newValue != null) {
     
      setSearchQuery("");
      
      handleReset()
      addVisitorToVisitRequest(newValue)

    } else {
      setSearchQuery("");
    }
  };

  const addVisitorToVisitRequest = (addElement) => {

    var visitorId = addElement.visitorId

    if(visitId == -1){
      //load fill visitor
      onSave(addElement)
 
    }else{
      dispatch(showLoading(""))

        addVisitorToVisit(navigate)(visitId, visitorId, selectedFrontDesk.entityId)
          .then((result) => {
          // Use the result.data as needed      
          dispatch(hideLoading());
              if(result.success){
                  
                enqueueSnackbar({
                  message: "Visitor was added from visit",
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });

                onClose()

              }else{
                enqueueSnackbar({
                  message: "Something went wrong during adding the entity",
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
              }


        })
        .catch((error) => {
          dispatch(hideLoading());

          if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
              navigate("/");
            }
          }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
              message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
          }

        });
      }

  };

  const handleSearchQueryChange = (event, newValue) => {

    if(typeof newValue !== 'undefined' && newValue != null){

      autocompleteVisitor(navigate)(newValue)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {

          if(result.total_count > 0){
            setSearchResults(result.data);
          }else{
            setSearchResults([]);
          }
        }
      })
      .catch((error) => {
        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }
      });

    }else{
      setSearchQuery(""); // Clear searchQuery when newValue is undefined
      setSearchResults([]); // Clear searchResults as well
    }

    
  };

  const generateValidationSchema = (fields) => {


    var visitorValidation = {};

      // Loop through each field and define its validation rules
    fields.forEach((field) => {

      if(field.required && field.fieldType == "email"){
        visitorValidation[field.apiKey] = yup.string().email("invalid email").required('required');
      }else if(field.required){
        visitorValidation[field.apiKey] = yup.string().required('required');
      }else if(field.fieldType == "email"){
        visitorValidation[field.apiKey] = yup.string().email("invalid email");
      }
    });

    const validationSchema = yup.object().shape({
      visitors: yup.array().of(
        yup.object().shape(visitorValidation)
      ),
    });

    return validationSchema;
};

  const handleScanPassport = () => {
    const visitorData = { 
        firstname: formikRef.current?.values.firstname,
        lastname: formikRef.current?.values.lastname,
        visitorType: formikRef.current?.values.visitorType,
        idNumber: formikRef.current?.values.idNumber
    };

    if (onScanPassport) {
        onScanPassport(visitorData);
    }
  };


  return (
    <Dialog open={open} onClose={onClose}>
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>{tm.translate("btnNewVisitor", 'New Visitor')}</DialogTitle>
        <DialogContent>
        <Box 
              sx={{ 
                    m: "0rem 0rem 0rem 0rem",
                    borderRadius: 1,
                    borderColor: theme.palette.secondary[100],
                    border: 0 , p: 5,
                    backgroundColor:theme.palette.background.alt,
             }}>  
            
            <Autocomplete
                  freeSolo
                  options={!searchResults ? [{label:"Loading...", value:0}] : searchResults }
                  // inputValue={searchQuery}
                  value={searchQuery || null}
                  // isOptionEqualToValue={(option, value) => option.value === value.value}
                  isOptionEqualToValue={(option, value) => {
                      if (value === null || value === undefined) {
                          // Handle null or undefined values
                          return option.value === null || option.value === undefined;
                      }
                      return option.value === value.value;
                  }}
                  onChange={(event, selectedOption) =>
                    handleSelectOption(selectedOption)
                  }
                  renderInput={(params) => (
                    <CssTextField {...params} label= {tm.translate('lblSearchVisitor', 'Search visitor...')} theme={theme}/>
                  )}
                  getOptionLabel={(option) =>
                    option.fullName + " " + option.email
                  }
                  onInputChange={handleSearchQueryChange}
                  sx={{ gridColumn: "span 4" }}
                  renderOption={(props, option) => (
                    <Box
                      {...props}
                      key={`${option.visitorId}`}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <ListItemAvatar>
                        <Avatar
                        {...stringAvatar(option.fullName)} 
                        sx={{ width: 40, height: 40, position: 'relative', zIndex: 0 }} // Ensure Avatar is positioned relatively
                        src={option.fullName && option.visitorId && option.picturePath ? `${getUri(getSessionToken())}visitor/${option.visitorId}/picture/${option.picturePath}` : undefined}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${option.fullName} ${option.email ? `(${option.email})` : ""} ${option.companyName || ""}`}
                      />
                      <IconButton
                        onClick={() => handleAddItem(option)}
                        edge="end"
                        aria-label="add"
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  )}
                  clearOnBlur={true}
                />
            </Box>
        <Box sx={{ 
                    m: "1rem 0rem 0rem 0rem",
                    borderRadius: 1,
                    borderColor: theme.palette.secondary[100],
                    border: 0 , p: 5,
                    backgroundColor:theme.palette.background.alt,
             }}> 
          <Formik
                      onSubmit={handleSubmit}
                      initialValues={initialValuesRegister}
                      validationSchema={validationSchema}
                      enableReinitialize={true}
                      validateOnChange={false} // Disable validation on change
                      validateOnBlur={false}   // Disable validation on blur
                      innerRef={formikRef}
                  >
                  {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      resetForm,
                      }) => (
                      <form onSubmit={handleSubmit}>
                  <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)'  }}
                      sx={{
                          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                      >
                        <FormControlLabel
                          sx={{ mb: 2, gridColumn: "span 4", backgroundColor: 'transparent', width: "100%"  }}
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.deniedPerson}
                              onChange={(e) => setFieldValue("deniedPerson", e.target.checked)}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>{tm.translate('btnDeniedPerson', "Denied Person")}</span>}
                        />
                        <Autocomplete
                          fullWidth
                          disablePortal
                          disableClearable
                          disableInput
                          autoSelect
                          // onChange={(event, value) => setFieldValue('visitorType', value ? value.value : '')}
                          onChange={(event, value) => {
                            // Update Formik field value
                            setFieldValue('visitorType', value ? value.value : '');
                        
                            // Call another function after setting the value
                            handleVisitorTypeSelectChange(event, value);
                          }}
                          value={allVisitorTypes.find(type => type.value === values.visitorType) || null}
                          options={allVisitorTypes}
                          getOptionLabel={(option) => option.label || ''}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          renderInput={(params) => (
                            <CssTextField
                              {...params}
                              required
                              name="visitorType"
                              label={tm.translate('dspVisitorType', 'Visitor Type')}
                              theme={theme}
                              sx={{
                                mb: 2, mr: 1, gridColumn: "span 4",
                                "& label.Mui-focused": {
                                  color: theme.palette.secondary[200],
                                },
                                "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
                                  color: theme.palette.secondary[300]
                                },
                              }}
                            />
                          )}
                        />
                        <Box
                            display="flex"
                            flexDirection="row"
                            width="100%"
                      
                            >
                        <CssTextField
                          label={tm.translate('dspFirstName', 'First Name')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          // value={loadedFormData?.name || values.name}
                          value={values.firstname} // Use values from Formik
                          name="firstname"
                          error={ Boolean(touched.firstname) && Boolean(errors.firstname)}
                          helperText={touched.firstname && errors.firstname}
                          sx={{ mb: 2 , mr:1 , gridColumn: "span 4" }}
                          theme={theme} 
                          fullWidth
                      />
                      <CssTextField
                          label={tm.translate('dspLastName', 'Last Name')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          // value={loadedFormData?.name || values.name}
                          value={values.lastname} // Use values from Formik
                          name="lastname"
                          error={ Boolean(touched.lastname) && Boolean(errors.lastname)}
                          helperText={touched.lastname && errors.lastname}
                          sx={{ mb: 2, ml:1 , gridColumn: "span 4" }}
                          theme={theme} 
                          fullWidth
                      />
                      </Box>

                      {additionalFields.map((item) => (
                        <CssTextField
                          key={item.apiKey}
                          label={item.fieldName}
                          name={`${item.apiKey}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          required={item.required}
                          sx={{ mb: 2, gridColumn: "span 4" }}
                          theme={theme}
                          value={values[item.apiKey] || ""}
                          error={Boolean(touched[item.apiKey] && errors[item.apiKey])}
                          helperText={touched[item.apiKey] && errors[item.apiKey]}
                        />
                      ))}
                      <Grid container spacing={1}>
                      <Grid item xs={12}>
                          <Button
                          fullWidth
                          startIcon={<Save />}
                          type="submit"
                          sx={{
                              p: "1rem",
                              backgroundColor: palette.secondary.light,
                              color: palette.background.alt,
                              fontWeight: "bold",
                              "&:hover": {
                              color: palette.primary.main,
                              backgroundColor: palette.secondary.light,
                              fontWeight: "bold",
                              },
                          }}
                          >
                          {tm.translate('btnSave', 'Save')}
                          </Button>
                      </Grid>
                     
                      </Grid>
                      {/* Add more text fields as needed */}
                  </Paper>
                      </form>
                  )}
              </Formik>
          </Box>
          <Box 
              sx={{ 
                    m: "1rem 0rem 0rem 0rem",
                    borderRadius: 1,
                    borderColor: theme.palette.secondary[100],
                    border: 0 , p: 5,
                    backgroundColor:theme.palette.background.alt,
             }}>  
            
                <Button
                    fullWidth
                    startIcon={<FaPassport />}
                    onClick={handleScanPassport}
                    type="submit"
                    sx={{
                        p: "1rem",
                        backgroundColor: palette.secondary.light,
                        color: palette.background.alt,
                        fontWeight: "bold",
                        mb: 1,
                        ".MuiButton-startIcon": {
                          color: palette.background.alt,
                        },
                        "&:hover": {
                        color: palette.primary.main,
                        backgroundColor: palette.secondary.light,
                        fontWeight: "bold",
                        },
                    }}
                    >
                    {tm.translate('btnScannPassport', "ID SCAN")}
                </Button>
            </Box>

          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {/* Are you sure you want to execute this action? */}
        
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}> 
          {/* <Button onClick={onClearImage} color="primary">remove photo</Button> */}

          <div>
            
      
            <Button onClick={handleClose} color="primary" sx={{ color: theme.palette.background.alt }}>
              {tm.translate('btnCancel', 'Cancel')}
            </Button>
          </div>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogAddVisitor