import React, { useEffect, useState } from "react";
import {  Box, Button, Typography, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import tm from 'components/TranslationManager';
import { CssTextField } from "./StyledComponents";
import { getListValuesPaging, postVisit, postVisitNote } from "hooks/HttpRequests";
import { Add, DeleteOutline, TireRepair } from "@mui/icons-material";
import TimeLineItemVisitHistory from "./TimeLineItemVisitHistory";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { enqueueSnackbar } from "notistack";
import TimeLineItemMessage from "./TimeLineItemMessage";
import { Timeline } from "@mui/lab";


const ApplicationMessages = ({globalType, navigate, id, entityTable, entityKeyname }) => {

  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const theme = useTheme();

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [isLoading, setGridLoading] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const [messageToSave, setMessageToSave] = useState("");


  useEffect(() => {
    setMessageToSave("")
    loadGridData();
  }, []);

  useEffect(() => {
    setMessageToSave("")
    loadGridData();
  }, [triggerRefresh]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [{"property":"visitNoteId","direction":"DESC"}],
    query:[]
  })

  const loadGridData = async () => {


      setGridLoading(true)
      
      // const extraFilter = searchFilter ? `&extraFilter=${searchFilter}` : "";
      const extraFilter = "";

      getListValuesPaging(navigate)(paginationModel, "visitnote/"+id+ "/"+ entityTable+ "/"+entityKeyname, extraFilter)      .then((result) => {

          console.log(result.data)
          if(result.success){
            setData(result.data)
          }
          setGridLoading(false)
  
        })
        .catch((error) => {
  
          console.log(error);
          setGridLoading(false)
        });

  }

  const handleSaveNote= () => {      

      if(messageToSave != ""){

        var submitJson = {};

        var dataJson = {}
        dataJson["content"] = messageToSave;
        dataJson["entityId"] = id;
        dataJson["entityKeyname"] = entityKeyname;
        dataJson["entityTable"] = entityTable;

        submitJson["visitnote"] = dataJson;

        
        dispatch(showLoading(""))

        postVisitNote(navigate)(submitJson)
          .then((result) => {
          // Use the result.data as needed      
          dispatch(hideLoading());
              if(result.success){
                  
                enqueueSnackbar({
                  message: "Message was submitted",
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });

                setTriggerRefresh(!triggerRefresh);

                

              }else{
                enqueueSnackbar({
                  message: "Something went wrong/",
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
              }

              setMessageToSave("")


        })
        .catch((error) => {
          dispatch(hideLoading());

          if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
              navigate("/");
            }
          }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
              message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
          }

        });
    }

    };

  if (!data || isLoading) return "Loading...";

  return (
      <Box m="0rem 2rem">
        {/* <Header title="History" subtitle="" elements={buttons} /> */}
        {/* </Box> */}
        <Box display="flex" gap={2} alignItems="center">
          <CssTextField
            label="Add new entry"
            fullWidth
            required
            theme={theme}
            value={messageToSave}
            onChange={(e) => setMessageToSave(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent default behavior (e.g., form submission)
                handleSaveNote();
              }
            }}
          />
          <Button
            startIcon={<Add />}
            onClick={handleSaveNote} // Trigger save action
            sx={{
              p: "1rem",
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontWeight: "bold",
              "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.light,
                fontWeight: "bold",
              },
            }}
          >
            Add
          </Button>
        </Box>

        <Box
          mt="0px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
          }}
        >
          <Timeline align="alternate">
              {data && data.length > 0 ? (
                    data.map((value, index) => (
                      <TimeLineItemMessage
                          key={index} 
                          entity={value} 
                          isLastElement={index === data.length - 1} //is neeeded for the last element to dont add another connector line
                      />
                    ))
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      No records available
                    </Typography>
                  )}

            </Timeline>
        </Box>
      </Box>
    );
};

export default ApplicationMessages;