import React, { useEffect, useState } from "react";
import {  Box, Typography, useTheme } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Header from "components/Header";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import {  getVisit } from "hooks/HttpRequests";
import VisitDetailGeneral from "./visitDetailGeneral";
import VisitDocuments from "./visitDocuments";
import VisitHistory from "./visitHistory";
import tm from 'components/TranslationManager';
import { useUserProfileManager } from "components/UserProfileProvider";
import ApplicationMessages from "components/ApplicationMessages";
import ConfigData from "components/ConfigData";



function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const VisitDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [copmanyName, setCopmanyName] = useState("");
  const { userProfile, userProfleLoading, hasPermission } = useUserProfileManager();


  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [visitorTypes, updateVisitorTypes] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);
  const { paramVisitId, paramVisitorId } = useParams(); // Accessing the id parameter from the URL
  const isNewVisit = paramVisitId === "-1";

  useEffect(() => {
    
    getVisit(navigate)(paramVisitId)
    .then((result) => {

      if(result.success){
          setCopmanyName("- "+result.data.fullName);
      }
      // setData(result)
      // setGridLoading(false)

    })
    .catch((error) => {

      console.log(error);

      setGridLoading(false)
    });

  }, [paramVisitId]);

  

  const tabs = [
    { label: 'General', path: '/general', id: "tabGeneral" },
    ...(!isNewVisit && hasPermission("system.visit.admin")
      ? [
          { label: tm.translate('pnlVisitHistory', 'History'), path: '/history', id: "tabHistory" },
          { label: 'Documents', path: '/documents', id: "tabDocuments" },
          { label: 'Notes', path: '/messages', id: "tabMessages" },
        ]
      : []), // Include tabs only if it's not a new visit and the user has permission
  ];
  
  

  const currentPath = location.pathname;
  const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
  const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;

  const [value, setValue] = useState(selectedTabIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTab = tabs[newValue];
    navigate(`/visit/${paramVisitId}/visitor/${paramVisitorId}${selectedTab.path}`);
  };

  useEffect(() => {
    const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
    const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;
    setValue(selectedTabIndex);
  }, [currentPath, tabs]);

  return (
    <Box m="0rem 2rem">
      <Header title={`Visit`} subtitle="" elements={null} backbutton />
      {/* <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        theme={theme}
      /> */}
      <Box mt="0px" height="85vh" sx={{
       
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiDataGrid-panel .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
         "& .MuiDataGrid-panelWrapper .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiTab-root.MuiTab-textColorPrimary.Mui-selected": {
            color: `${theme.palette.secondary[200]} !important`,
            fontWeight: 'bold'
          },
          
        }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.id === 'tabGeneral' && (
              <VisitDetailGeneral
                visitorType="Visitor Type"
                description="Description"
                globalType={isNewVisit ? "new" : "edit"}
                navigate={navigate}
                id={paramVisitId}
                visitorId={paramVisitorId || null}
                // theme={theme}
              />
            )}
            { (tab.id === 'tabHistory') && (
              // <Typography>This is the Security tab content</Typography>
              <VisitHistory
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={paramVisitId}
                // theme={theme}
              />
            )}
            {tab.id === 'tabDocuments' && (
              // <Typography>This is the Security tab content</Typography>
              <VisitDocuments
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={paramVisitId}
                // theme={theme}
              />
            )}
            {tab.id === 'tabMessages' && (
              // <Typography>This is the Security tab content</Typography>
              <ApplicationMessages
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={paramVisitId}
                entityKeyname = {ConfigData.attachmentKeynames.ATTACHMENT_KEYNAME_VISIT_NOTE}
                entityTable = {ConfigData.attachmentTables.ATTACHMENT_TABLE_VISIT}
                // theme={theme}
              />
            )}

          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default VisitDetail;

