import React, { useEffect, useState } from "react";
import {  Box,  useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import DialogYesNo from "components/dialog/DialogYesNo";
import {  useNavigate } from "react-router-dom";
import tm from "components/TranslationManager";
import { deleteDeniedPerson, } from "hooks/HttpRequests";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { enqueueSnackbar } from "notistack";
import SadevioDataGridPro from "components/SadevioDataGridPro";
import dayjs from "dayjs";



const SystemLog = () => {
  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { convertToFrontDeskTimeZoneDateTimeString } = useFrontDeskManager();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [yesNoMessage, setYesNoMessage] = useState("");
  const [yesNoDialogEntity, setYesNoDialogEntity] = useState({});

  const [topSearchFilter, setTopSearchFilter] = useState("");

  const theme = useTheme();

  /////////////// GRID /////////////
  // values to be sent to the backend

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const [search, setSearch] = useState("");

  /////////////// USE EFFECT /////////////


  useEffect(() => {
    // This code will run every time paginationModel changes
    setTriggerRefresh(!triggerRefresh)

  }, []);


  const handleOptionCellActionClick = (id, action, row) => {
    if (action === 'edit') {
      // Handle edit action
      console.log('Edit record:', row);
      navigate(`/visit/settings/deniedperson/${row.entityId}`)
    } else if (action === 'delete') {
      // Handle delete action
      // console.log('Delete record:', row);
      setConfirmationOpen(true);
      setYesNoMessage("Do you really want to delete this entity?")
      row["action"] = "delete";
      setYesNoDialogEntity(row)
      
    }
  };

  const columns = [
    
    {
      field: "entityId",
      headerName: "Entity Id",
      filterable: false,
      type: 'number'
    },
    {
      field: 'eventId',
      width: 140,
      headerName: 'Event ID',
    },
    {
      field: "description",
      flex: 1,
      headerName: "Description",
      filterable: true,

    },
    {
      field: 'whenCreated',
      filterable: true,
      width: 140,
      headerName: 'When Created',
      type:'date',
      valueGetter: (params) => {
        // Ensure the value is transformed into a Date object
        return new Date(params.value);
      },
      valueSetter: (params) => {
        // Convert selected date to YYYY-MM-DD for server requests
        return { ...params.row, visitValidFrom: dayjs(params.value).format("YYYY-MM-DD") };
      },
      renderCell: (params) => {
        const utcDateTime = params.value; // Assuming params.value contains the UTC timestamp
        // const localDateTime = dayjs.utc(utcDateTime).local().format('DD.MM.YYYY HH:mm'); // Convert UTC to local and format
        const localDateTime = convertToFrontDeskTimeZoneDateTimeString(utcDateTime)
        return <span>{localDateTime}</span>;
      }
    },
    {
      field: 'whoCreated',
      filterable: true,
      headerName: 'Who Created',
    },
    
  ];

  const handleExecute = async (values) => {
  
  
        setConfirmationOpen(false)
    
        dispatch(showLoading(""))
    
        if(values.action == "delete"){
  
          deleteDeniedPerson(navigate)(values.entityId)
            .then((result) => {
            // Use the result.data as needed      
            dispatch(hideLoading());
                if(result.success){
                    
                  enqueueSnackbar({
                    message: "Was deleted successfully",
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
    
                  setTriggerRefresh(!triggerRefresh);
    
                }else{
  
  
  
                  enqueueSnackbar({
                    message: "Something went wrong during request",
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
                }
    
    
          })
          .catch((error) => {
            dispatch(hideLoading());
    
            if (typeof error.action !== "undefined") {
              if (error.action == "relogin") {
                navigate("/");
              }else if(error.action == "force_delete"){
                  console.log("force delete")
                  setConfirmationOpen(true);
                  setYesNoMessage("This entity has still referecnes to visits. Do you want to force the delete?")
                  yesNoDialogEntity["action"] = "force_delete";
                  setYesNoDialogEntity(yesNoDialogEntity)
              }
            }else if (error.hasOwnProperty('success')) {
              enqueueSnackbar({
                message: "Error "+error.message,
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }
    
          });
        }
    
          
    
      };


  const btnNewVisit = tm.translate('btnNewDeniedPerson', 'New Denied Person');
  
  const buttons = [
    // // { type:'search',label: "Search employees..." , onChange: handleSearchInputChange, iconName: ''},
    // { type:'button',label: btnNewVisit , onClick: () => {
    //           // openDrawer()
    //           navigate("/visit/settings/deniedperson/new") 
    //           // setAddEmployeeOpen(true);
    //         }, iconName: '', btnId: 'btnNewDeniedPerson'},

  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Devices" subtitle="List of all of your devices" elements={buttons} />
      <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleExecute}
        theme={theme}
        message={yesNoMessage}
        entity={yesNoDialogEntity}
      />
      <SadevioDataGridPro columns ={columns} url={'systemlog'} triggerRefresh= {triggerRefresh} />
    </Box>
  );
};

export default SystemLog;