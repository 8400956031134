import React, { useEffect, useMemo, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { Box, useTheme } from "@mui/material";
import { useFrontDeskManager } from "./FrontDeskManager";
import { getFrontDeskVisitsPerMonth } from "hooks/HttpRequests";
import { Navigate, useNavigate } from "react-router-dom";

const OverviewChart = ({ isDashboard = false, view, id }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);
  const { selectedFrontDesk } = useFrontDeskManager();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setGridLoading(true);
    try {
      const result = await getFrontDeskVisitsPerMonth(navigate)(selectedFrontDesk.entityId);
      const formattedData = result.data.map(item => ({
        // month: new Date(2000, item.month - 1).toLocaleString('default', { month: 'long' }),
        month: item.month,
        totalSales: item.data1, // Adjust these mappings as per your actual data requirements
        totalUnits: item.data2,
      }));
      setData(formattedData);
    } catch (error) {
      if (error?.action === 'relogin') {
        Navigate('/');
      }
      console.error(error);
    } finally {
      setGridLoading(false);
    }
  };

  const [totalSalesLine, totalUnitsLine] = useMemo(() => {
    const totalSalesLine = {
      id: "totalSales",
      color: theme.palette.secondary.main,
      data: data.map(({ month, totalSales }) => ({ x: month, y: totalSales })),
    };
    const totalUnitsLine = {
      id: "totalUnits",
      color: theme.palette.secondary[600],
      data: data.map(({ month, totalUnits }) => ({ x: month, y: totalUnits })),
    };

    return [[totalSalesLine], [totalUnitsLine]];
  }, [data, theme.palette.secondary]);

  if (gridLoading) return "Loading...";

  return (
    <Box
      height={isDashboard ? "300px" : "80%"}
      width={undefined}
      minHeight={isDashboard ? "300px" : undefined}
      minWidth={isDashboard ? "325px" : undefined}
      position="relative"
      id={"visitors_pre_month"}
    >
    <ResponsiveLine
      id={id}
      data={view === "sales" ? totalSalesLine : totalUnitsLine}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: theme.palette.secondary[200],
            },
          },
          legend: {
            text: {
              fill: theme.palette.secondary[200],
            },
          },
          ticks: {
            line: {
              stroke: theme.palette.secondary[200],
              strokeWidth: 1,
            },
            text: {
              fill: theme.palette.secondary[200],
            },
          },
        },
        legends: {
          text: {
            fill: theme.palette.secondary[200],
          },
        },
        tooltip: {
          container: {
            background: 'white', // Apply background color here
            color: theme.palette.primary.main, // Apply text color here
            padding: "8px",
            borderRadius: "4px",
            boxShadow: "0px 2px 5px rgba(10, 01, 0, 0.15)", // Ensures styling applies
          },
        },
      }}
      margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
      // xScale={{ type: "point" }}
      xScale={{
        type: "time",
        format: "%Y-%m",
        useUTC: false,
        precision: "month",
      }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="catmullRom"
      axisTop={null}
      axisRight={null}
      // axisBottom={{
      //   // format: (v) => {
      //   //   if (isDashboard) return v.slice(0, 3);
      //   //   return v;
      //   // },
      //   orient: "bottom",
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: isDashboard ? "" : "Month",
      //   legendOffset: 36,
      //   legendPosition: "middle",
      // }}
      axisBottom={{
        format: "%b %Y", // Displays like "May 2024"
        tickValues: "every 1 month",
        legend: isDashboard ? "" : "Month",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickValues: 5,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard
          ? ""
          : `Total ${view === "sales" ? "Revenue" : "Units"} for Year`,
        legendOffset: -60,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={
        !isDashboard
          ? [
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 30,
                translateY: -40,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : undefined
      }
      tooltip={({ point }) => (
        <div
          style={{
            background: 'white',
            color: theme.palette.primary.main,
            padding: "8px",
            borderRadius: "4px",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
          }}
        >
          X: {new Date(point.data.x).toLocaleDateString("en-US", { year: "numeric", month: "long" })} <br />
          Y: {point.data.yFormatted}
        </div>
      )}
    />
    </Box>
  );
};

export default OverviewChart;
