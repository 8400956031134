import React, { useState } from "react";
import {  Box, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import ConfigData from "components/ConfigData";
import DialogUploadAttachment from "components/dialog/DialogUploadAttachment";
import ListAttachments from "components/ListAttachment";


const EmployeeDocuments = ({globalType, navigate, id }) => {

  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const [topSearchFilter, setTopSearchFilter] = useState("");

  const theme = useTheme();

  /////////////// UPLOAD ATTACHMENT /////////////
  const [openUploadDialog, setOpenUploadDialog] = useState(false);


  const handleSearchInputChange = (e) => {

    // Update the state based on the previous state
    setTopSearchFilter(e.target.value);
    // // Perform other actions if needed
    // loadGridData()

  };  

  const onCLoseUploadDocument = (e) => {


    setTriggerLoad(!triggerLoad);

    setOpenUploadDialog(false)


  }; 

  const [triggerLoad, setTriggerLoad] = useState(false);

  const handleTriggerLoad = () => {
    setTriggerLoad(!triggerLoad);
  };

  const buttons = [
    { type:'search',label: "Search document..." , onChange: handleSearchInputChange, iconName: ''},
    { type:'button',label: "Upload Document" , onClick: () => { setOpenUploadDialog(true) }, iconName: ''},

  ];

  return (
    <Box m="0rem 2rem">
      <Header title="" subtitle="" elements={buttons} />
      <DialogUploadAttachment
          open={openUploadDialog}
          onClose={() => onCLoseUploadDocument()}
          onSave={(entity) => {setOpenUploadDialog(false);    }}
          entityPointerId = {id}
          entityKeyname = {ConfigData.attachmentKeynames.ATTACHMENT_KEYNAME_HOST}
          entityTable = {ConfigData.attachmentTables.ATTACHMENT_TABLE_HOST}
      />
      {/* </Box> */}
      <Box
        mt="0px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
        }}
      >
          <ListAttachments 
              onTriggerLoad={triggerLoad} 
              entityIdPointer={id} 
              entityTable={ConfigData.attachmentTables.ATTACHMENT_TABLE_HOST} 
              entityKeyname={ConfigData.attachmentKeynames.ATTACHMENT_KEYNAME_HOST}
              searchFilter={topSearchFilter}
              />
      </Box>
    </Box>
  );
};

export default EmployeeDocuments;