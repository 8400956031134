import React, { useEffect, useState } from "react";
import {  Avatar, Box, Button, Drawer, Link, useTheme } from "@mui/material";
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from "react-redux";
import DialogYesNo from "components/dialog/DialogYesNo";
import { getListValuesPaging, getSessionToken, getUri } from "../../../hooks/HttpRequests";
import {  useNavigate, useParams } from "react-router-dom";
import tm from "components/TranslationManager";
import CompanyNew from "scenes/company/companyNew";
import { stringAvatar } from "components/StyledComponents";
import ActionColumn from "components/ActionColumn";


const CompanyEmployees = ({ route }) => {

  const navigate = useNavigate();
  const theme = useTheme();
  const {palette} = useTheme();

  const { id } = useParams(); // Accessing the id parameter from the URL


  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [topSearchFilter, setTopSearchFilter] = useState("");


  /////////////// GRID /////////////
  // values to be sent to the backend

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });

  const [search, setSearch] = useState("");

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [],
    query:[]
  })

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton color="neutral" />
        <GridToolbarFilterButton  style={{ color: '#3f51b5' }}  />
      </GridToolbarContainer>
    );
  }

  
    useEffect(() => {
      // This code will run every time paginationModel changes
      loadGridData();

    }, [paginationModel]);

    useEffect(() => {

      if(route == "new"){
        openDrawer();
      }else if(route == "details"){
        openDrawer();
      }

    }, [route]); 

  const handleFilterModelChange = (filterModel) => {
    // Update the filter model state when it changes

    console.log("handleFilterModelChange()")

    // Convert to the desired structure
    const desiredStructure = filterModel.items.map(item => {
      let mappedOperator = item.operator;
    
      let field = columns.find(column => column.field === item.field);
      var fieldType = "string";

      if(typeof field.type !== 'undefined'){
        fieldType = field.type;
      }

      // Map operators as needed
      if (item.operator === 'equals') {
        mappedOperator = 'eq';
      } else if (item.operator === 'contains') {
        mappedOperator = 'like';
      } // Add more conditions as needed
    
      return {
        property: item.field,
        value: item.value,
        type: fieldType, // You might need to adjust this based on your data types
        operator: mappedOperator
      };
    });

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      query: desiredStructure,
      page: 0,
    }));

  };

  const handleSortModelChange = (sortModel) => {
    // Handle sorting changes and fetch data from the server
    //sort: [{"property":"lastname","direction":"ASC"}]

    console.log("handleSortModelChange()")

    const sortObject = sortModel.map(item => ({
      property: item.field,
      direction: item.sort.toUpperCase() // Assuming you want direction in uppercase (ASC or DESC)
    }));

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      sort: sortObject,
      page: 0,
    }));

      console.log(paginationModel)
  };

  const handlePageChange = (newPage) => {
    // Handle page changes and fetch data from the server

    console.log("handlePageChange()")

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPage.page,
      limit: newPage.pageSize,
    }));

  };

  const loadGridData = async () => {

    console.log("loadGridData()")
    setGridLoading(true)

    var extraFilter = "";
    if(topSearchFilter.length > 0){
      extraFilter = "&extraFilter="+topSearchFilter
    }

    getListValuesPaging(navigate)(paginationModel, "company/"+id+"/employees", extraFilter)
      .then((result) => {

        setData(result)
        setGridLoading(false)

      })
      .catch((error) => {

        setGridLoading(false)
      });

  }

  const handleExecute = async (values) => {


      setConfirmationOpen(false);

      

  };

  const handleActionClick = (action, record) => {
    if (action === 'edit') {
      // Handle edit action
      console.log('Edit record:', record);
    } else if (action === 'delete') {
      // Handle delete action
      console.log('Delete record:', record);
     
      
    }
  };


  

  const columns = [
    
    {
      field: "entityId",
      headerName: "Entity Id",
      flex: 1,
      type: 'number'
    },
    {
      field: 'picturePath',
      headerName: 'Picture',
      width: 100,
      renderCell: (params) => (
        params.row.picturePath != null ? (
          <Avatar {...stringAvatar(params.row.fullName)} alt={params.row.fullName} src={`${getUri(getSessionToken())}/host/${params.row.entityId}/picture/${params.row.picturePath}`}          />

        ) : (
          <Avatar {...stringAvatar(params.row.fullName)} />
        )
      ),
    },   
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/employees/${params.row.entityId}`)
          }}
        >
        {params.row.fullName}
      </Link>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/employees/${params.row.entityId}`)
          }}
        >
        {params.row.email}
      </Link>
      ),
    },
    {
      field: "poNumber",
      headerName: tm.translate('clmPoNUmber', "Staff Id") ,
      flex: "auto",
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
       // flex: 1,
      width: 80,
      sortable: false,
      renderCell: (params) => (
        <ActionColumn record={params} handleActionClick={handleActionClick} />
      ),
    },
  ];

  const handleSearchInputChange = (e) => {

    // Update the state based on the previous state
    setTopSearchFilter(e.target.value);
    // // Perform other actions if needed
    loadGridData()

  };

  const btnNewVisit = tm.translate('btnNewEmployee', 'New Employee');
  
  const buttons = [
    { type:'search',label: "Search employees..." , onChange: handleSearchInputChange, iconName: ''},
    { type:'button',label: btnNewVisit , onClick: () => { console.log("open"); 
              // openDrawer()
              navigate("/identity/employee/new") }, iconName: ''},

  ];

  const [drawerOpen, setDrawerOpen] = useState(false);

  // Function to handle opening the Drawer
  const openDrawer = () => {
    setDrawerOpen(true);
  };

  // Function to handle closing the Drawer
  const closeDrawer = () => {
    setDrawerOpen(false);
    navigate("/identity/company")
  };


  return (
    <Box m="0rem 0rem">    

    {/* -------- HEADER  START ------------------*/}
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        {/* <Box display="flex" flexDirection="row" alignItems="center">
          <Typography
            variant="h2"
            color={theme.palette.secondary[100]}
            fontWeight="bold"
          >
            {"asf"}
          </Typography>
        </Box> */}
        {/* <Typography variant="h5" color={theme.palette.secondary[300]}>
          {"subtitle"}
        </Typography> */}
      </Box>
          {/* -------- HEADER  RIGHT ------------------*/}
      <Box display="flex" flexDirection="row" alignItems="center">
        
      <Button
          fullWidth
          type="submit"
          sx={{
              m: "0rem 0",
              p: "1rem",
              backgroundColor: palette.secondary.light,
              color: palette.background.alt,
              fontWeight: "bold",
              "&:hover": {
                  color: palette.primary.main,
                  backgroundColor: palette.secondary.light,
                  fontWeight: "bold",
              },
          }}
      >
          Add Employee
      </Button>

      </Box>
    </Box>
              {/* -------- HEADER  END ------------------*/}

      <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={() => handleExecute()}
        theme={theme}
      />
      {/* </Box> */}
      <Box
        mt="0px"
        height="80vh"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiDataGrid-panel .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
         "& .MuiDataGrid-panelWrapper .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiLink-root.MuiLink-underlineAlways": {
            color: `#ffffff!important`,
          },
        }}
      >
        <DataGridPro
          loading={gridLoading || !data}
          initialState={{
            pagination: { paginationModel: { pageSize: paginationModel.limit } },
          }}
          // getRowId={(row) => row.entityId}
          getRowId={(row) => `${row.entityId}`}
          rows={data?.data || []}
          columns={columns}
          rowCount={(data && data.total_count) || 0}
          rowsPerPageOptions={[25, 50, 100]}
          columnVisibilityModel={columnVisibilityModel}
          // onColumnVisibilityChange={handleColumnVisibilityChange}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }

          checkboxSelection

          filterMode="server"
          onFilterModelChange={handleFilterModelChange}

          sortingMode="server"
          onSortModelChange={handleSortModelChange}

          paginationMode="server"
          pagination
          page={paginationModel.page}
          pageSize={paginationModel.limit}
          onPaginationModelChange={(paginationModel) => handlePageChange(paginationModel)} //setPageSize(paginationModel.pageSize)

          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            filterPanel: {
              // Force usage of "And" operator
              logicOperators: [],
              // Display columns by ascending alphabetical order
              columnsSort: 'asc',
              
              sx: {
                '& .MuiButtonBase-root': { color: `${theme.palette.secondary[200]} !important` },
                '& .MuiFormLabel-root': { color: `${theme.palette.secondary[200]} !important` },
              },
            },
          }}
        />
      </Box>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={closeDrawer}
        // Adjust the width based on your requirement (2/3 of the space)
        sx={{   
          width: '66vw', 
          flexShrink: 0,
        }}
        PaperProps={{
          sx: {
            backgroundColor: `${theme.palette.primary[500]} !important`,
            backgroundImage: "none",
            borderLeft: 1
            // Other Paper styles if needed
          },
        }}
        
        variant="temporary"
      >
        <CompanyNew />
        {/* Content of the Drawer */}
        {/* Add your detailed/edit view components here */}
        {/* For example: <EditForm /> */}
      </Drawer>
    </Box>
  );
};

export default CompanyEmployees;