import React, { useState, useEffect } from "react";
import {
  Box,
  useTheme,
  useMediaQuery,
  Paper,
  FormControlLabel,
  Button,
} from "@mui/material";
import IOSSwitch from "components/IOSSwitch";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { getHIDAtomReaderConfig, putHIDAtomReaderConfig } from "hooks/HttpRequests";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "components/Header";
import { enqueueSnackbar } from "notistack";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { Save } from "@mui/icons-material";

const HidAtomReaderConfig = () => {

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const { selectedFrontDesk } = useFrontDeskManager();
  const [loading, setLoading] = useState(true);
  const [loadedFormData, setLoadedFormData] = useState({ active: false, saveColor: false, saveUv: false, saveIr: false, saveFaceNfc: false, saveFace: false });
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const registerSchema = yup.object().shape({
    // usersToNotify: yup.string().required("required"),
  });

  const initialValuesRegister = {
    phoneNumbers: "",
    emailAddresses: "",
    active: false,
  };

  useEffect(() => {
    if (selectedFrontDesk) {
      loadData();
    }
  }, [selectedFrontDesk, triggerRefresh]);

  const loadData = async () => {
    setLoading(true);

    getHIDAtomReaderConfig(navigate)(selectedFrontDesk.entityId)
      .then((result) => {
        if (result.success) {
          setLoadedFormData(result.data)
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleFormSubmit = (values) => {

    var submitData = {};
    submitData = values;

    dispatch(showLoading(""))

    putHIDAtomReaderConfig(navigate)(selectedFrontDesk.entityId, submitData)
      .then((result) => {
        if (result.success) {
          setLoadedFormData(result.data);

          enqueueSnackbar({
            message: "Config updated",
              variant: 'success',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }
        dispatch(hideLoading());
      })
      .catch((error) => {
        
        dispatch(hideLoading());
      });
  };

  const buttons = [
    // { type:'button',label: `Add Rule` , onClick: () => { openRuleEditor() }, iconName: ''},

  ];

  if (loading || !selectedFrontDesk) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="1rem 2rem" >
    <Header title={`HID Atom Passport Reader`} subtitle={`Scan identification documents`} backbutton={true} elements={buttons}/>
      <Box mt="20px">
      <Formik
                onSubmit={handleFormSubmit}
                initialValues={loadedFormData || initialValuesRegister}
                validationSchema={registerSchema}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Paper
                      elevation={3}
                      style={{
                        padding: 20,
                        backgroundColor: theme.palette.background.alt,
                        backgroundImage: "none",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2, // Adds spacing between switches
                        }}
                      >
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.active}
                              name="active"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>Active</span>}
                        />

                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.saveColor}
                              name="saveColor"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <span style={{ color: theme.palette.secondary.light }}>
                              Save Colored Document Picture
                            </span>
                          }
                        />

                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.saveUv}
                              name="saveUv"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <span style={{ color: theme.palette.secondary.light }}>
                              Save Ultraviolet Document Picture
                            </span>
                          }
                        />

                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.saveIr}
                              name="saveIr"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <span style={{ color: theme.palette.secondary.light }}>
                              Save Infrared Document Picture
                            </span>
                          }
                        />

                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.saveFaceNfc}
                              name="saveFaceNfc"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <span style={{ color: theme.palette.secondary.light }}>
                              Save NFC Face Picture (if available)
                            </span>
                          }
                        />

                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.saveFace}
                              name="saveFace"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <span style={{ color: theme.palette.secondary.light }}>
                              Save Face Picture
                            </span>
                          }
                        />
                      </Box>
                    </Paper>

                    
                        <Button
                          startIcon={<Save />}
                          type="submit"
                          sx={{
                            p: "1rem",
                            m: "1rem 0rem 0rem 0rem",
                            backgroundColor: palette.secondary.light,
                            color: palette.background.alt,
                            fontWeight: "bold",
                            "&:hover": {
                              color: palette.primary.main,
                              backgroundColor: palette.secondary.dark,
                              fontWeight: "bold",
                            },
                          }}
                        >
                          Save
                        </Button>
                  </form>
                )}
              </Formik>
      </Box>
      
    </Box>
  );
};

export default HidAtomReaderConfig;
