import { useState } from "react";

const { MoreVert } = require("@mui/icons-material");
const { IconButton, Menu, MenuItem } = require("@mui/material");

const GridCellVisitsActions = ({ id, row,  onOptionClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  
    const handleOptionClick = (option) => {
      console.log('Option clicked:', option);
      onOptionClick(id, option, row);
      handleMenuClose();
    };
  
    return (
      <div>
        <IconButton
          aria-controls={`actions-menu-${id}`}
          aria-haspopup="true"
          onClick={handleMenuOpen}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id={`actions-menu-${id}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {row.visitStatus === "pre_registered" && (
            <MenuItem onClick={() => handleOptionClick('delete_visit')}>Delete Visit</MenuItem>
          )}
          {row.visitStatus === "pre_registered" && (
            <MenuItem onClick={() => handleOptionClick('cancel_visit')}>Cancel Visit</MenuItem>
          )}
          {row.visitStatus === "started" && (
            <MenuItem onClick={() => handleOptionClick('finish_visit')}>Finish Visit</MenuItem>
          )}
          <MenuItem onClick={() => handleOptionClick('show_details')}>Details</MenuItem>
        </Menu>
      </div>
    );
  };
  
  export default GridCellVisitsActions;
