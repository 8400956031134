import React, { useEffect, useState } from "react";
import {  Box,  Typography, useMediaQuery, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch} from "react-redux";
import { getVisitorIdDocuments } from "hooks/HttpRequests";
import { useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useFrontDeskManager } from "components/FrontDeskManager";


const VisitorIDDocuments = ({globalType, id }) => {

  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { convertToUTCDateString } = useFrontDeskManager();

  const [topSearchFilter, setTopSearchFilter] = useState("");


  const [data, setData] = useState([]);
  const [isLoading, setGridLoading] = useState(false);
  
  useEffect(() => {
    // This code will run every time paginationModel changes
    loadGridData();

  }, []);

  const loadGridData = async () => {

    dispatch(showLoading(""));
    setGridLoading(true)

    getVisitorIdDocuments(navigate)( id)
      .then((result) => {

        setData(result.data)
        setGridLoading(false)
        dispatch(hideLoading());

      })
      .catch((error) => {
        console.log(error);
        setGridLoading(false)
        dispatch(hideLoading());
      });

  }

  /////////////// UPLOAD ATTACHMENT /////////////
  const [openUploadDialog, setOpenUploadDialog] = useState(false);


  const handleSearchInputChange = (e) => {

    // Update the state based on the previous state
    setTopSearchFilter(e.target.value);
    // // Perform other actions if needed
    // loadGridData()

  };  

  const onCLoseUploadDocument = (e) => {


    setTriggerLoad(!triggerLoad);

    setOpenUploadDialog(false)


  }; 

  const [triggerLoad, setTriggerLoad] = useState(false);

  const handleTriggerLoad = () => {
    setTriggerLoad(!triggerLoad);
  };

  const buttons = [
    // { type:'search',label: "Search document..." , onChange: handleSearchInputChange, iconName: ''},
  ];

  if (!data || isLoading) return "Loading...";

  return (
    <Box m="0rem 2rem">
      <Header title="" subtitle="" elements={buttons} />
      {/* </Box> */}
      <Box
        mt="0px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
        }}
      >
        {!data || data.length === 0 ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="textSecondary">
                  No documents available
                </Typography>
              </Box>
            ) : (
        <Box
          display="grid"
          gridTemplateColumns={isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"}
          gap={2}
        >
          
          {data.map((visitorWrapper) => {
            const { entityId, visitor, status, confirmedBy, documentType } = visitorWrapper;
            const isSafe = status === "safe";
            const expiryConverted = convertToUTCDateString(visitorWrapper.expiryDate)
            var isExpired = false;
            if (visitorWrapper.expiryDate < Date.now()) {
              isExpired = true;
            }
          
            return (
              <Box
                key={entityId}
                p={2}
                border={`1px solid ${theme.palette.grey[300]}`}
                borderRadius="8px"
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                    sx={{
                      fontWeight: isSafe ? 'bold' : 'normal',
                      fontSize: 25,
                      color: isSafe ? theme.palette.success.main : undefined,
                    }}
                  >
                    <strong>Document Type:</strong> {visitorWrapper.documentType}</Typography>
                <Typography
                    sx={{
                      fontWeight:  'bold' ,
                      fontSize: 20,
                    }}
                  ><strong>Document ID:</strong> {visitorWrapper.documentId}</Typography>
                <Typography><strong>Issuer:</strong> {visitorWrapper.issuer}</Typography>
                <Typography
                    sx={{
                      fontWeight: isExpired ? 'bold' : 'normal',
                      fontSize: 15,
                      color: isExpired ? theme.palette.error.main : theme.palette.success.main,
                    }}
                  >
                    <strong>Expiry Date:</strong> {expiryConverted}
                  </Typography>
              {visitorWrapper.picColor &&( 
                <Box
                  key={"pic_color"}
                  border={1}
                  borderColor="grey.500"
                  width={275}
                  height={189}
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the event from propagating to the parent dialog
                    // handleImageClick(imageData[type]);
                  }}
                >
                    <img
                      src={`data:image/jpeg;base64,${visitorWrapper.picColor}`}
                      alt={`Color Image`}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      // onError={(e) => {
                      //   e.target.style.display = "none"; // Hide the image if it can't load
                      // }}
                    />
                
                </Box>
              )}

              {visitorWrapper.picUv &&( 
                <Box
                  key={"pic_uv"}
                  border={1}
                  borderColor="grey.500"
                  width={275}
                  height={189}
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the event from propagating to the parent dialog
                    // handleImageClick(imageData[type]);
                  }}
                >
                    <img
                      src={`data:image/jpeg;base64,${visitorWrapper.picUv}`}
                      alt={`UV Image`}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      // onError={(e) => {
                      //   e.target.style.display = "none"; // Hide the image if it can't load
                      // }}
                    />
                
                </Box>
              )}

              {visitorWrapper.picIr &&( 
                <Box
                  key={"pic_ir"}
                  border={1}
                  borderColor="grey.500"
                  width={275}
                  height={189}
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the event from propagating to the parent dialog
                    // handleImageClick(imageData[type]);
                  }}
                >
                    <img
                      src={`data:image/jpeg;base64,${visitorWrapper.picIr}`}
                      alt={`IR Image`}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      // onError={(e) => {
                      //   e.target.style.display = "none"; // Hide the image if it can't load
                      // }}
                    />
                
                </Box>
                )}
              </Box>
            );
          })}
        </Box>)}
      </Box>
    </Box>
  );
};

export default VisitorIDDocuments;