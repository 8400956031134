import React, { useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const CSVFileUpload = ({ onFileChange, buttonText, values, setFieldValue }) => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { palette } = theme;

  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      onFileChange({ target: { files: [file] } }, values, setFieldValue);
    }
  };

  const handleInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onFileChange(event, values, setFieldValue);
    }
  };

  return (
    <Box
      sx={{
        border: `2px dashed ${isDragging ? palette.primary.main : palette.grey[500]}`,
        borderRadius: '8px',
        padding: '1rem',
        textAlign: 'center',
        backgroundColor: isDragging ? palette.grey[200] : palette.background.default,
        cursor: 'pointer',
        width: isNonMobile ? '500px' : '100%',
        height: isNonMobile ? '200px' : '100%',
        margin: '1rem auto',
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={() => document.getElementById('fileInput').click()}
    >
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleInputChange}
      />
      <Typography variant="h6" color="textSecondary">
        Drop a file here or click to browse
      </Typography>
    </Box>
  );
};

export default CSVFileUpload;
