import React from 'react';
import { MenuItem } from '@mui/material';
import PlayCircle from '@mui/icons-material/PlayCircle';
import tm from 'components/TranslationManager';

const MenuItemsVisitorNotification = ({ handleSendInvitation }) => {
  
  

  return (
    <div>
      <MenuItem onClick={handleSendInvitation} disableRipple>
        <PlayCircle />
        {tm.translate('btnSendInvitation', "Send Invitation")} 
      </MenuItem>
    </div>
  );
};

export default MenuItemsVisitorNotification;
