import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  useMediaQuery,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "state/auth";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { authenticateUser, authenticationType} from "hooks/HttpRequests";
import {CssTextField} from "components/StyledComponents";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useSnackbar } from "notistack";
import { setAuthenticatedOutside } from "components/UserProfileProvider";

const loginSchemaPassword = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
});

const initialValuesLogin = {
  email: "",
  password: "",
};

const Form = () => {
  const [pageType, setPageType] = useState("login");
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false)
  const [showPasswordField, setShowPasswordField] = useState(false)
  const [validationSchema, setValidationSchema] = useState(yup.object().shape({email: yup.string().email("invalid email").required("required")}));
  const passwordFieldRef = useRef(null); // Create a ref for the password field

  const { fetchFrontDeskData } = useFrontDeskManager();

  const theme = useTheme();

  useEffect(() => {
    // Focus the password field when showPasswordField becomes true
    if (showPasswordField && passwordFieldRef.current) {
      passwordFieldRef.current.focus();
    }
  }, [showPasswordField]);

  const login = async (values, onSubmitProps) => {

      // var submitJson = {};

      dispatch(showLoading( ""))


			values["application"] = "main";


      authenticateUser()(values)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {
          
          var user = {};

          onSubmitProps.resetForm();
          dispatch(hideLoading());
  
  
          localStorage.setItem('token', result.session_id);
          setAuthenticatedOutside(true);

            dispatch(
              setLogin({
                user: user,
                token: result.session_id,
              })
            );
  
            fetchFrontDeskData(); // Call this function whenever you want to trigger a reload of data
  
            // fetchMenuData();

            navigate("/dashboard");
            

        }else{
          enqueueSnackbar('We have a problem!',{ 
            variant: 'error', anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
            },
            autoHideDuration: 5000, // 5000 milliseconds = 5 seconds
            // persist: true
          })

        }

        
        dispatch(hideLoading());

      })
      .catch((error) => {

        
        if (typeof error.userMessage !== "undefined") {
          enqueueSnackbar({
            message: error.userMessage,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, // 5000 milliseconds = 5 seconds
              // persist: true
            
          });
        }
        dispatch(hideLoading());

      });
      

      // if(loggedIn.success){

        
      
      // }else{
      //   dispatch(hideLoading());
      // }
  };

  const getAuthType = async (values, onSubmitProps) => {

      dispatch(showLoading( ""))

      values["application"] = "main";

      authenticationType(values)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {
          
          //do something cool herer

        }
        setValidationSchema(loginSchemaPassword)
        setShowPasswordField(true)
        dispatch(hideLoading());

      })
      .catch((error) => {
        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }
        dispatch(hideLoading());

      });
  };

  const handleFormSubmit = async (values, onSubmitProps) => {

      if(showPasswordField){
        await login(values, onSubmitProps);
      }else{
        await getAuthType(values, onSubmitProps);
      }

  };


  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesLogin }
      validationSchema={validationSchema }
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            
            <CssTextField
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              name="email"
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              // sx={{ gridColumn: "span 4" }}
              sx={{
                gridColumn: "span 4" ,
              }}
              theme={theme} 
            />
            {showPasswordField && (

                <CssTextField
                label="Password"
                type="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={Boolean(touched.password) && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 4" }}
                theme={theme} 
                inputRef={passwordFieldRef}
                />
            )}
           
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "2rem 0",
                p: "1rem",
                backgroundColor: palette.secondary.light,
                color: palette.background.alt,
                "&:hover": { 
                  color: palette.primary.main,
                  backgroundColor: palette.secondary.light,
                  fontWeight: 'bold'
                },
              }}
            >
              {showPasswordField
                  ? "Login"
                  : "Continue"}
            </Button>
            {/* <Typography
              onClick={() => {
                setPageType(isLogin ? "register" : "login");
                resetForm();
              }}
              sx={{
                textDecoration: "underline",
                color: palette.secondary.light,
                "&:hover": {
                  cursor: "pointer",
                  color: palette.secondary.light,
                  fontWeight: 'bold'
                },
              }}
            >
              {isLogin
                ? "Don't have an account? Sign Up here."
                : "Already have an account? Login here."}
            </Typography> */}

            <Box>
              <Typography
                onClick={() => {
                  resetForm();
                  navigate("forgot")
                }}
                sx={{
                  textDecoration: "underline",
                  color: palette.secondary.light,
                  "&:hover": {
                    cursor: "pointer",
                    color: palette.secondary.light,
                    fontWeight: 'bold'
                  },
                }}
              >
                {"Forgot Password?"}
              </Typography>
              {/* <Typography
                onClick={() => {
                  resetForm();
                  navigate("reset")
                }}
                sx={{
                  textDecoration: "underline",
                  color: palette.secondary.light,
                  "&:hover": {
                    cursor: "pointer",
                    color: palette.secondary.light,
                    fontWeight: 'bold'
                  },
                }}
              >
                {isLogin
                  ? "Request Access"
                  : ""}
              </Typography> */}
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Form;