import React, { useEffect, useState } from 'react';
import { Box, useTheme} from '@mui/material';
import { getWebUsers } from 'hooks/HttpRequests';
import InvitationReviewApproval from './invitationReviewApproval';
import InvitationGeneralSettings from './invitationGeneralSettings';



const InvitationGeneral = ({globalType, navigate, id }) => {

const theme = useTheme();
const [availableEmployes, setAvailableEmployes] = useState([]);
const [gridLoading, setGridLoading] = useState(false);

const [showReviewers, setShowReviewers] = useState(false);

const toggleReviewers = () => {
    setShowReviewers(!showReviewers);
};

// BACKGROUND COLOR
const [showBackGroundColorConf, setShowBackGroundColorConf] = useState(false);

const toggleBackGroundColorConf = () => {
    setShowBackGroundColorConf(!showBackGroundColorConf);
};

const [displayBackgroundColorPicker, setDisplayBackgroundColorPicker] = useState(false);
const [backgroundColor, setBackgroundColor] = useState("#ffffff");

  const handleBackgroundColorClick = () => {
    setDisplayBackgroundColorPicker(!displayBackgroundColorPicker)
  };

  const  handleBackgroundColorClose = () => {
    setDisplayBackgroundColorPicker(false)
  };

  const handleBackgroundColorChange = (color, event) => {
    setBackgroundColor(color.hex)
  };

// FONT COLOR
const [showFontColorConf, setShowFontColorConf] = useState(false);

const toggleFontColorConf = () => {
    setShowFontColorConf(!showFontColorConf);
};

const [displayFontColorPicker, setDisplayFontColorPicker] = useState(false);
const [fontColor, setFontColor] = useState("#ffffff");

  const handleFontColorClick = () => {
    setDisplayFontColorPicker(!displayFontColorPicker)
  };

  const  handleFontColorClose = () => {
    setDisplayFontColorPicker(false)
  };

  const handleFontColorChange = (color, event) => {
    setFontColor(color.hex)
  };
  

// ACCENT COLOR
const [showAccentColorConf, setShowAccentColorConf] = useState(false);

const toggleAccentColorConf = () => {
    setShowAccentColorConf(!showAccentColorConf);
};

const [displayAccentColorPicker, setDisplayAccentColorPicker] = useState(false);
const [accentColor, setAccentColor] = useState("#ffffff");

  const handleAccentColorClick = () => {
    setDisplayAccentColorPicker(!displayAccentColorPicker)
  };

  const  handleAccentColorClose = () => {
    setDisplayAccentColorPicker(false)
  };

  const handleAccentColorChange = (color, event) => {
    setAccentColor(color.hex)
  };

//////////////////////
  useEffect(() => {
    
    loadVisitorTypes();

  }, []); // Empty dependency array to fetch options only once on component mount


  const handleInputChange = (event, value) => {
    
    console.log("handleInputChange "+value)
    
  };

  const loadVisitorTypes = async () => {

    setGridLoading(true)

    //do an autocomplete function

    getWebUsers(navigate)(1)
      .then((result) => {

        // Use the result.data as needed
        if(result.success){
          console.log(result)

        

         setAvailableEmployes(result.data)
        }
        setGridLoading(false)

      })
      .catch((error) => {

        console.log(error);

        setGridLoading(false)
      });

  }

const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  return (
    <Box>
        
        
        {/*  -------------------------------   */}

            <InvitationGeneralSettings />
        {/*  -------------------------------   */}

            <InvitationReviewApproval />
        {/*  -------------------------------   */}

    </Box>
  );
};

export default InvitationGeneral;
