import React from 'react';
import { Box, Button,  List, ListItem, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@emotion/react';
import { CssTextField } from './StyledComponents';

const EmailManager = ({ title, value, onChange, sx, fieldTitle = "Add Email" }) => {
  const [inputEmail, setInputEmail] = React.useState('');
  const theme = useTheme();

  const handleAddEmail = () => {
    if (!inputEmail.trim()) return;
    const emailList = value.split(';').filter(email => email.trim() !== '');
    emailList.push(inputEmail.trim());
    onChange(emailList.join(';'));
    setInputEmail('');
  };

  const handleRemoveEmail = (emailToRemove) => {
    const emailList = value.split(';').filter(email => email.trim() !== emailToRemove);
    onChange(emailList.join(';'));
  };

  return (
    <Box sx={{ width: '100%', margin: 'auto', padding: 2, border: '1px solid #ccc', borderRadius: 2, ...sx }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {title}
      </Typography>

      <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
        <CssTextField
          fullWidth
          name="emailManagerInput"
          label={fieldTitle}
          value={inputEmail}
          onChange={(e) => setInputEmail(e.target.value)}
          size="small"
          theme={theme}
        />
        <Button variant="contained" selenium_id={"btnEmailManagerAdd"} onClick={handleAddEmail} disabled={!inputEmail.trim() }>
          Add
        </Button>
      </Box>

      <List>
        {(value || '') // Fallback to an empty string if value is undefined
          .split(';')
          .filter((email) => email.trim() !== '')
          .map((email, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" onClick={() => handleRemoveEmail(email)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              {email}
            </ListItem>
          ))}
      </List>
    </Box>
  );
};

export default EmailManager;
