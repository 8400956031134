import React from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ArrowDropDownOutlined,
  Check,
  Public,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFrontDeskManager } from "./FrontDeskManager";
import { useMenuManager } from "components/MenuManager";
import { useUserProfileManager } from "./UserProfileProvider";

const menuItemsDefinition = [ ];

const Sidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {


  const { isAuth,userProfile, userProfleLoading, hasPermission } = useUserProfileManager();
  const location = useLocation();
  const [showLocationHelp, setShowLocationHelp] = useState(false);
  const [showLocationHelpBorder, setShowLocationHelpBorder] = useState(false);

  const [menuItems, setMenuItem] = useState(menuItemsDefinition);

  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  const { selectedFrontDesk, frontDeskLoading, allAvailableFrontDesks, setSelectedFrontDeskEntityId, externalLocationTooltipTrigger } = useFrontDeskManager();


  useEffect(() => {
    const shownCount = parseInt(localStorage.getItem("locationHelpShownCount") || "0", 10);
  
    if (shownCount < 3) {
      setShowLocationHelp(true);
      setShowLocationHelpBorder(true)
      const timer = setTimeout(() => {
        setShowLocationHelp(false);
        setShowLocationHelpBorder(false)
        localStorage.setItem("locationHelpShownCount", (shownCount + 1).toString());
      }, 5000); // Auto-close after 5 seconds
  
      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {

    
      setShowLocationHelpBorder(true);
  
      const timer = setTimeout(() => {
        setShowLocationHelpBorder(false);
      }, 5000);
  
      return () => clearTimeout(timer);
    
  }, [externalLocationTooltipTrigger]);

  
  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);


  const [seleectedFrontDeskName, setSeleectedFrontDeskName] = useState("");
  const [topLeftLogo, setTopLeftLogo] = useState("https://www.sadevio.com/wp-content/uploads/2020/03/Sadevio-Logo-white_letters-220x53.png");


  const { menuData, menuDataLoading, fetchMenuData } = useMenuManager();

  useEffect(() => {
    if (!frontDeskLoading) {
        if(selectedFrontDesk != null){
          setSeleectedFrontDeskName(selectedFrontDesk.name)
          fetchMenuData()
        }
    }
  }, [frontDeskLoading, selectedFrontDesk]);

  useEffect(() => {
    if (userProfile != null) {
      setTopLeftLogo(userProfile.companyLogoPath)
      
    }
  }, [userProfile]);

    useEffect(() => {
  
      if (!menuDataLoading) {
          if(menuData != null){
            if (pathname.startsWith("/global/")) {
              setMenuItem(menuData.global);
            } else {
              setMenuItem(menuData.local);
            }
          }
      }
      }, [menuDataLoading, menuData]);


  useEffect(() => {

      function createExpandMenuForPath(path) {
        const expandMenu = {};
        const segments = path.split('/').filter(segment => segment);
      
        let currentPath = '';
        // 0,3 only the first 3 elements are for the menu
        segments.slice(0, 3).forEach((segment, index) => {
          currentPath += (index > 0 ? '/' : '') + segment;
          expandMenu[currentPath] = true;

        });
      
        return expandMenu;
      }

      var test = createExpandMenuForPath(location.pathname);
      setExpandedMenus(test)

  }, [location]);

  ///////////////
  // top menu for locations
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = (item) => {

    if(item != null){
      setSelectedFrontDeskEntityId(item.entityId);
      setMenuItem(menuData.local)
      navigate("/")
    }

    setAnchorEl(null);

  };

  ///////////////

  const [expandedMenus, setExpandedMenus] = useState({});

  // useEffect(() => {

  //   console.info("-----------expandedMenus -----------")


  //   console.log(expandedMenus)


  // }, [expandedMenus]);

  const toggleMenu = (menuItem, menuDepth) => {

    if(menuDepth == 0){
      setExpandedMenus({})
    }

    var childrenObject = null;

    if(menuItem.children.length > 0){
      childrenObject = menuItem.children[0];
    }

    if(childrenObject != null){
      navigate(`/${childrenObject.link}`);
      setActive(childrenObject.link);
    }else{
      navigate(`/${menuItem.link}`);
      setActive(menuItem.link);
    }

    setExpandedMenus((prevMenus) => ({
      ...prevMenus,
      // [menuItem.path]: !prevMenus[menuItem.path],
      [menuItem.link]: true,
    }));

    //if it is mobile we want to close the menu automatically
    if(!isNonMobile){
      setIsSidebarOpen(false)
    }

  };

  const globalSettings = () =>{
    
    navigate("/global/locations")
    setMenuItem(menuData.global)
    setAnchorEl(null);
  }

  // Render loading state if loadedFormData is null
  if (!menuItems || !menuData || menuDataLoading) {
    return <div>Loading...</div>;
  }


  const renderMenuItem = (menuItem) => {
    const hasChildren = menuItem.children && menuItem.children.length > 0;

    return (
      <div key={menuItem.text} className="menu-item">
        <ListItem disablePadding>
          <ListItemButton onClick={() => toggleMenu(menuItem, 0)}
              sx={{
                backgroundColor:
                  active === menuItem.link
                    ? theme.palette.secondary[300]
                    : "transparent",
                color:
                  active === menuItem.link
                    ? theme.palette.primary[600]
                    : theme.palette.secondary[100],
              }}
          >
            <ListItemIcon sx={{
                minWidth: 'auto', paddingRight: '15px',
                color:
                  active === menuItem.link
                    ? theme.palette.primary[600]
                    : theme.palette.secondary[100],
              }} >
                {/* {menuItem.icon} */}
                <Icon>{menuItem.iconCls}</Icon> {/* Render icon dynamically */}
                {/* <Icon>{menuItem.icon}</Icon> Render icon dynamically */}
              </ListItemIcon>
            <ListItemText primary={
                <Typography variant="h6" fontWeight="bold">
                  {menuItem.text}
                </Typography>
              }sx={{
                paddingLeft: '0px', // Adjust the padding as needed
            }}/>
          </ListItemButton>
        </ListItem>
        {hasChildren && expandedMenus[menuItem.link] && (
          <List disablePadding>
            {menuItem.children.map((child) => (
              <div key={child.text}>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => toggleMenu(child, 1)} sx={{
                        backgroundColor:
                          active === child.link
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color:
                          active === child.link
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}>
                      <ListItemText primary={child.text} sx={{
                            fontWeight: 'bold',
                            paddingLeft: '50px', // Adjust the padding as needed
                        }}/>
                        {/* <Badge badgeContent={4} color="primary" /> */}
                             
                  </ListItemButton>
              
                </ListItem>
                {child.children && child.children.length > 0 && expandedMenus[child.link] && (
                  <List disablePadding>
                    {child.children.map((grandChild) => (
                      <ListItem key={grandChild.text} disablePadding>
                        <ListItemButton onClick={() => toggleMenu(grandChild, 2)} sx={{
                            backgroundColor:
                              active === grandChild.link
                                ? theme.palette.secondary[300]
                                : "transparent",
                            color:
                              active === grandChild.link
                                ? theme.palette.primary[600]
                                : theme.palette.secondary[100],
                          }}>
                            <ListItemText primary={grandChild.text} sx={{
                                  fontWeight: 'bold',
                                  paddingLeft: '70px', // Adjust the padding as needed
                              }}/>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                )}
              </div>
            ))}
          </List>
        )}
      </div>
    );
  };

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
                <Box
                      style={{
                          margin: '1.0rem 2rem 1rem 0rem',
                          width: '100%',
                          height: '50px',
                          backgroundImage: `url(${topLeftLogo})`,
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          color: theme.palette.secondary.main,
                      }}
                  >
                  {/* No need for the Box component */}
                  {!isNonMobile && (
                      <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                          <ChevronLeft />
                      </IconButton>
                  )}
              </Box>

              <Tooltip
                  title="Select your location here"
                  open={showLocationHelp}
                  onClose={() => {
                    setShowLocationHelp(false);
                    localStorage.setItem("locationHelpShown", "true");
                  }}
                  placement="top"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: theme.palette.secondary[300],      // custom background color
                        color: theme.palette.primary[300],                   // text color
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        padding: "12px 16px",
                        borderRadius: "8px",
                        maxWidth: "220px",               // optional limit
                        boxShadow: 3,
                      },
                      arrow: {
                        sx: {
                          backgroundColor: theme.palette.secondary[300], // arrow color (matches tooltip background)
                        },
                      },
                    },
                  }}
                >
              <Button
                  onClick={handleClick}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between", // push icon to the right
                    textTransform: "none",
                    paddingY: "0.5rem",
                    padding: "1rem",
                    border: showLocationHelpBorder ? "2px dashed " + theme.palette.secondary[100] : "",
                    width: "100%",
                    height: "48px", // optional fixed height for consistent centering
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    fontSize="1rem"
                    sx={{ 
                      color: theme.palette.secondary[100],
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {seleectedFrontDeskName}
                  </Typography>

                  <ArrowDropDownOutlined
                    sx={{ 
                      color: theme.palette.secondary[300],
                      fontSize: "24px",
                      flexShrink: 0 
                    }}
                  />
                </Button>

            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={() => handleClose(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                PaperProps={{
                    sx: {
                        color: theme.palette.secondary[100],
                        backgroundColor: theme.palette.background.default// Set the background color here
                    },
                }}
              >

                {allAvailableFrontDesks.map(item => {
                      return (
                        <MenuItem key={item.entityId} onClick={() => handleClose(item)}>
                          {item.entityId === selectedFrontDesk.entityId ? (
                            <ListItemIcon sx={{ color: theme.palette.secondary[100] }}>
                              <Check />
                            </ListItemIcon>
                          ) : (
                            <ListItemIcon style={{ minWidth: '36px' }} /> // Empty ListItemIcon with the same padding
                          )}
                          {item.name}
                        </MenuItem>
                      );
                    })
                  }
              <Divider />
              <MenuItem  onClick={ () => globalSettings()}>
                  <ListItemIcon sx={{
                        color: theme.palette.secondary[100],
                      }} >
                  <Public fontSize="small" />
                </ListItemIcon>
                <ListItemText>Global Overview</ListItemText>
              </MenuItem>
              {/* { hasPermission("system.maintenance.frontdesk") && (
              <MenuItem>
                <ListItemIcon sx={{
                        color: theme.palette.secondary[100],
                      }} >
                  <AddCircle fontSize="small" />
                </ListItemIcon>
                <ListItemText>Add Location</ListItemText>
              </MenuItem>)
              } */}
              </Menu>


            {menuItems.map(renderMenuItem)}


        
          </Box>

          <Box position="absolute" bottom="1rem">
          <Typography
                  fontWeight="bold"
                  fontSize="0.6rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >V.{process.env.REACT_APP_VERSION}</Typography>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;