import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Grid,
  MenuItem,
  IconButton,
  Select,
  Typography,
  useTheme,
  Paper,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CssTextField } from 'components/StyledComponents';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hideLoading, showLoading } from 'state/loadingSlicer';
import { getWorkflowElementVisitorDataCustom, putWorkflowElementVisitorDataCustom } from 'hooks/HttpRequests';

const DialogEditCustomField = ({ open, onClose, onSave, customFieldEntity, currentEntity }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [languages, setLanguages] = useState([]);
  const [customFieldType, setCustomFieldType] = useState('text'); // Default to "Textfield"
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (open) {
      loadData(currentEntity.frontDeskEntityId, currentEntity.entityId, customFieldEntity.apiKey);
    }
  }, [open]);

  const loadData = async (frontDeskId, workflowId, apiKey) => {
    dispatch(showLoading());
    setDataLoaded(false);
    try {
      const result = await getWorkflowElementVisitorDataCustom(navigate)(frontDeskId, workflowId, apiKey);
      if (result.success) {
        const { entityField, translations } = result.data;
  
        const fieldType = entityField.customFieldType || 'text';
        setCustomFieldType(fieldType);
  
        const loadedLanguages = translations.reduce((acc, translation) => {
          const existing = acc.find((lang) => lang.code === translation.language) || {
            code: translation.language,
            title: { value: '', entityId: null },
            caption: { value: '', entityId: null },
          };
  
          if (translation.keyname.includes('_title')) {
            existing.title = { value: translation.value, entityId: translation.entityId };
          } else if (translation.keyname.includes('_caption')) {
            existing.caption = { value: translation.value, entityId: translation.entityId };
          }
  
          if (!acc.some((lang) => lang.code === translation.language)) {
            acc.push(existing);
          }
          return acc;
        }, []);
  
        setLanguages(loadedLanguages);
      }
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      dispatch(hideLoading());
      setDataLoaded(true); // Allow rendering
      
    }
  };  

  const updateValidationSchema = () => {
    return Yup.object(
      languages.reduce((schema, lang) => {
        schema[`${lang.code || `lang_${lang.index}`}_title`] = Yup.string().required(
          `${lang.code || 'New language'} title is required`
        );
        schema[`${lang.code || `lang_${lang.index}`}_caption`] = Yup.string().required(
          `${lang.code || 'New language'} caption is required`
        );
        if (!lang.code) {
          schema[`lang_${lang.index}_code`] = Yup.string().required('Language must be selected');
        }
        return schema;
      }, {})
    );
  };

// Handle Formik Initialization
const formik = useFormik({
  initialValues: languages.reduce((acc, lang, index) => {
    const keyPrefix = lang.code || `lang_${index}`;
    acc[`${keyPrefix}_title`] = lang.title.value || '';
    acc[`${keyPrefix}_caption`] = lang.caption.value || '';
    acc[`${keyPrefix}_code`] = lang.code || '';
    return acc;
  }, {}),
  enableReinitialize: true,
  validationSchema: updateValidationSchema(),
  onSubmit: (values) => {
    const updatedTranslations = languages.flatMap((lang, index) => {
      const keyPrefix = lang.code || `lang_${index}`;
      return [
        {
          language: values[`${keyPrefix}_code`],
          keyname: `${customFieldEntity.apiKey}_title`,
          value: values[`${keyPrefix}_title`],
          entityId: lang.title.entityId || null,
        },
        {
          language: values[`${keyPrefix}_code`],
          keyname: `${customFieldEntity.apiKey}_caption`,
          value: values[`${keyPrefix}_caption`],
          entityId: lang.caption.entityId || null,
        },
      ];
    });

    const payload = { customFieldType, translations: updatedTranslations };


    putWorkflowElementVisitorDataCustom(navigate)(currentEntity.frontDeskEntityId, currentEntity.entityId, customFieldEntity.apiKey, payload)
      .then((result) => {

          // Use the result.data as needed
          if(result.success){
              onSave()
          }
          
          // setGridLoading(false)

      })
      .catch((error) => {

          console.log(error);

          // setGridLoading(false)
      });


    // onSave(payload);
    // onClose();
  },
});


  const handleTypeChange = (event) => {
    setCustomFieldType(event.target.value);
  };

  const handleAddLanguage = () => {
    const newLanguage = {
      code: '',
      title: { value: '', entityId: null },
      caption: { value: '', entityId: null },
    };
    setLanguages([...languages, newLanguage]);
  };
  

  const handleRemoveLanguage = (index) => {
    const updatedLanguages = [...languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);
  };

  const handleLanguageChange = (index, newCode) => {
    const updatedLanguages = [...languages];
    updatedLanguages[index].code = newCode;
    setLanguages(updatedLanguages);
    // Set the Formik field value dynamically
    formik.setFieldValue(`lang_${index}_code`, newCode);
  };

  const renderLanguageFields = () =>
    languages.map((lang, index) => {
      const availableLanguages = ['EN', 'DE', 'FR'].filter(
        (code) => !languages.some((language) => language.code === code) || lang.code === code
      );

      return (
        <Grid container spacing={1} key={index} alignItems="center" sx={{ m: '0.5rem 0rem 0rem 0rem' }}>
          <Grid item xs={2}>
            <Select
              value={lang.code}
              onChange={(e) => handleLanguageChange(index, e.target.value)}
              fullWidth
              displayEmpty
              disabled={lang.code === 'EN'}
              error={formik.touched[`lang_${index}_code`] && Boolean(formik.errors[`lang_${index}_code`])}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              {availableLanguages.map((code) => (
                <MenuItem key={code} value={code}>
                  {code}
                </MenuItem>
              ))}
            </Select>
            {formik.touched[`lang_${index}_code`] && formik.errors[`lang_${index}_code`] && (
              <Typography variant="caption" color="error">
                {formik.errors[`lang_${index}_code`]}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <CssTextField
              label="Title"
              value={formik.values[`${lang.code || `lang_${index}`}_title`] || ''}
              onChange={formik.handleChange}
              name={`${lang.code || `lang_${index}`}_title`}
              fullWidth
              required
              error={
                formik.touched[`${lang.code || `lang_${index}`}_title`] &&
                Boolean(formik.errors[`${lang.code || `lang_${index}`}_title`])
              }
              helperText={
                formik.touched[`${lang.code || `lang_${index}`}_title`] &&
                formik.errors[`${lang.code || `lang_${index}`}_title`]
              }
              theme={theme}
            />
          </Grid>
          <Grid item xs={5}>
            <CssTextField
              label="Caption"
              value={formik.values[`${lang.code || `lang_${index}`}_caption`] || ''}
              onChange={formik.handleChange}
              name={`${lang.code || `lang_${index}`}_caption`}
              fullWidth
              required
              error={
                formik.touched[`${lang.code || `lang_${index}`}_caption`] &&
                Boolean(formik.errors[`${lang.code || `lang_${index}`}_caption`])
              }
              helperText={
                formik.touched[`${lang.code || `lang_${index}`}_caption`] &&
                formik.errors[`${lang.code || `lang_${index}`}_caption`]
              }
              theme={theme}
            />
          </Grid>
          <Grid item xs={1}>
            {lang.code !== 'EN' && (
              <IconButton onClick={() => handleRemoveLanguage(index)} color="error">
                <Delete />
              </IconButton>
            )}
          </Grid>
        </Grid>

      );
    });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: 'bold' }}>
          Edit Fields
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              m: '0rem',
              borderRadius: 1,
              borderColor: theme.palette.secondary[100],
              border: 0,
              p: 5,
              backgroundColor: theme.palette.background.alt,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Field Type
            </Typography>
            <Select
              value={customFieldType}
              onChange={handleTypeChange}
              fullWidth
              displayEmpty
              sx={{ mb: 3 }}
            >
              <MenuItem value="" disabled>
                Select Field Type
              </MenuItem>
              <MenuItem value="text">Textfield</MenuItem>
              {/* <MenuItem value="checkbox">Checkbox</MenuItem>
              <MenuItem value="date">Date</MenuItem> */}
            </Select>

            <Typography variant="h6" gutterBottom>
              Language Fields
            </Typography>
            {renderLanguageFields()}
            <Button
              startIcon={<Add />}
              onClick={handleAddLanguage}
              variant="outlined"
              fullWidth
              sx={{
                p: '1rem',
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontWeight: 'bold',
                mt: 2,
                '&:hover': {
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.secondary.light,
                },
              }}
            >
              Add Language
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={formik.handleSubmit} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogEditCustomField;
