import {  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CssTextField } from "components/StyledComponents";
import tm from 'components/TranslationManager';
import cookieManagerUtils from '../CookieManagerUtils';


const DialogPassportScannerSettings = ({ open, onClose, onSave }) => {

  const { palette } = useTheme();
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  ////////////////////////////////
  
  const [ipAddress, setIpAddress] = useState("ws://192.168.1.61:5010");


  useEffect(() => {
    
    var ipAddress = cookieManagerUtils.getWebsocketIp()
    setIpAddress(ipAddress)

  }, [open]);

  const handleReset = () => {

    // setSearchResults([]);
    // setSearchQuery("");

  }

  const handleClose = () => {

      handleReset()
      onClose();
  }

  const handleSave = () => {

    cookieManagerUtils.setWebsocketIp(ipAddress)
    onSave();
  }

  const handleIpAddressChange = (event) => {
    const ipAddressLocal = event.target.value;
    setIpAddress(ipAddressLocal);
  };
  

   

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>Passport Scanner Settings</DialogTitle>
        <DialogContent>

        <Box 
              sx={{ 
                    m: "0rem 0rem 0rem 0rem",
                    borderRadius: 1,
                    borderColor: theme.palette.secondary[100],
                    border: 0 , p: 5,
                    backgroundColor:theme.palette.background.alt,
             }}>  
            
            <CssTextField
                  label="IP Address"
                  fullWidth
                  value={ipAddress}
                  onChange={handleIpAddressChange}
                  required
                  theme={theme}
                />
            </Box>

          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {/* Are you sure you want to execute this action? */}
        
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}> 
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>

          {/* Left section: IconButton and Reader Status */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Button onClick={handleSave} color="primary" autoFocus sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
                {tm.translate('btnRest', "Rest Device")}
              </Button>
       
          </Box>

          {/* Right section: Other buttons */}
          <Box>
            <div>
              <Button onClick={handleSave} color="primary" autoFocus sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
                {tm.translate('btnOk', "OK")}
              </Button>
            </div>
          </Box>
        </Box>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogPassportScannerSettings