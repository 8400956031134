import React, { useEffect, useState } from "react";
import { Box, ListItemIcon, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {  Email} from "@mui/icons-material";
import IOSSwitch from "components/IOSSwitch";
import {  getSilentAlarmConfig } from "hooks/HttpRequests";
import * as yup from "yup";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useIntegrationConfig } from "components/IntegrationConfigManager";

const ProfileEmailNotifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { selectedFrontDesk } = useFrontDeskManager();
  const { setShowSilentAlarm } = useIntegrationConfig();

  const [loadedFormData, setLoadedFormData] = useState({ active: false });
  const [loading, setLoading] = useState(false);
  const [showConf, setShowConf] = useState(false);

  useEffect(() => {
    if (showConf) {
      loadData();
    }
  }, [showConf]);

  const registerSchema = yup.object().shape({
    // usersToNotify: yup.string().required("required"),
  });

  const initialValuesRegister = {
    phoneNumbers: "",
    emailAddresses: "",
    active: false,
  };

  const toggleShowConf = () => {
    setShowConf(!showConf);
  };

  const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const loadData = async () => {
    setLoading(true);

    getSilentAlarmConfig(navigate)(selectedFrontDesk.entityId)
      .then((result) => {
        if (result.success) {
          setLoadedFormData(result.data)
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleFormSubmit = (values) => {

    var submitData = {};
    submitData = values;

    dispatch(showLoading(""))

    
  };

  return (
    <Box sx={{ ...commonStyles, border: 1 }}>
      <Box display="flex" alignItems="center">
        <ListItemIcon>
          <Email fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem" }} />
        </ListItemIcon>
        <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem" }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h3" color={theme.palette.secondary[100]} fontWeight="bold">
              EMAIL
            </Typography>
          </Box>
          <Typography variant="h5" color={theme.palette.secondary[300]}>
            Receive email notifications
          </Typography>
        </Box>
        <Box ml="auto">
            <IOSSwitch
            sx={{ m: "1px 20px 1px 1px" }}
            checked={true}
            name="active"
            // onChange={(value) => {
            //   console.log(value.target.checked);
            //   loadedFormData["active"] = value.target.checked;
            // }}
            // onBlur={handleBlur}
            // onChange={handleChange}
            // onChange={(e) => setFieldValue("active", e.target.checked)}

          />
       
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileEmailNotifications;
