import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, FormControlLabel, IconButton, InputAdornment, ListItemIcon, Paper, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Close, HelpOutline, LockClock, Save } from "@mui/icons-material";
import { CssTextField } from "components/StyledComponents";
import IOSSwitch from "components/IOSSwitch";
import {  getDataPrivacyConfig, putDataPrivacyConfig } from "hooks/HttpRequests";
import * as yup from "yup";
import { Formik } from "formik";
import { Oval } from "react-loader-spinner";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useIntegrationConfig } from "components/IntegrationConfigManager";
import tm from 'components/TranslationManager';


const LocationPrivacyConfig = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { selectedFrontDesk } = useFrontDeskManager();
  const { setShowSilentAlarm } = useIntegrationConfig();

  const initialValuesRegister = {
    phoneNumbers: "",
    emailAddresses: "",
    active: false,
  };

  const [loadedFormData, setLoadedFormData] = useState({ initialValuesRegister });
  const [loading, setLoading] = useState(false);
  const [showConf, setShowConf] = useState(false);

  const defaultCleanUpType= { label: "Full Cleanup", value: "all" };
    const [selectedClenupType, setSelectedClenupType] = useState("all"); // Default value

    const cleanUpTypes = [
      defaultCleanUpType,
        { label: 'Selective Retention', value: "selective" },
    ]

  useEffect(() => {
    if (showConf) {
      loadData();
    }
  }, [showConf]);

  const registerSchema = yup.object().shape({
    // usersToNotify: yup.string().required("required"),
  });

  

  const toggleShowConf = () => {
    setShowConf(!showConf);
  };

  const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const loadData = async () => {
    setLoading(true);

    getDataPrivacyConfig(navigate)(selectedFrontDesk.entityId)
      .then((result) => {
        if (result.success) {
          setLoadedFormData(result.data)
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleFormSubmit = (values) => {

    var submitData = {};
    submitData["data"] = values;

    dispatch(showLoading(""))

    putDataPrivacyConfig(navigate)(selectedFrontDesk.entityId, submitData)
      .then((result) => {
        if (result.success) {
          setLoadedFormData(result.data);
          toggleShowConf()
          setShowSilentAlarm(result.data.active)
        }
        dispatch(hideLoading());
      })
      .catch((error) => {
        
        dispatch(hideLoading());
      });
  };

  return (
    <Box sx={{ ...commonStyles, border: 1 }}>
      <Box display="flex" alignItems="center">
        <ListItemIcon>
          <LockClock fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem" }} />
        </ListItemIcon>
        <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem" }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h3" color={theme.palette.secondary[100]} fontWeight="bold">
                Data Privacy
            </Typography>
          </Box>
          <Typography variant="h5" color={theme.palette.secondary[300]}>
            Configure the retention of the data which is saved.
          </Typography>
        </Box>
        <Box ml="auto">
          {!showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              EDIT
            </Button>
          )}
          {showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              <Close />
            </Button>
          )}
        </Box>
      </Box>

      {showConf && (
        <Box>
          {loading ? (
            <Box sx={{ width: "100%", height: "100%", padding: "20px", display: "flex", justifyContent: "center" }}>
            <Oval
                visible={true}
                height="80"
                width="80"
                color="#ffedc2"
                secondaryColor="#ffedc2"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Box>
          ) : (
            <Box sx={{ padding: "20px"}}>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={loadedFormData || initialValuesRegister}
                validationSchema={registerSchema}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Paper
                      elevation={3}
                      style={{
                        padding: 20,
                        backgroundColor: theme.palette.background.alt,
                        backgroundImage: "none",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                      }}
                      sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
                    >
                      <FormControlLabel
                        sx={{ mb: 2, gridColumn: "span 4" }}
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={values.active}
                            name="active"
                            // onChange={(value) => {
                            //   console.log(value.target.checked);
                            //   loadedFormData["active"] = value.target.checked;
                            // }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            // onChange={(e) => setFieldValue("active", e.target.checked)}

                          />
                        }
                        label={<span style={{ color: theme.palette.secondary.light }}>Active</span>}
                      />

                    <CssTextField
                        label="Clean-Up after N days"
                        onBlur={handleBlur}
                        type= "number"
                        min
                        onChange={handleChange}
                        value={values.cleanUpDays}
                        name="cleanUpDays"
                        error={Boolean(touched.cleanUpDays) && Boolean(errors.cleanUpDays)}
                        helperText={touched.cleanUpDays && errors.cleanUpDays}
                        sx={{ mb: 2, gridColumn: "span 4" }}
                        theme={theme}
                        InputProps={{
                          min: 0,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip  title={
                                  <Typography variant="body1" style={{ fontSize: '16px' }}>
                                    Enter the number of days after the information should be cleaned up
                                  </Typography>
                              }>
                                <IconButton>
                                  <HelpOutline />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <Autocomplete
                          disablePortal
                          disableClearable
                          fullWidth
                          options={cleanUpTypes}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.value === value}
                          onChange={(event, newValue) => {
                              setSelectedClenupType(newValue.value);
                              setLoadedFormData((prevData) => ({
                                  ...prevData,
                                  cleanUpType: newValue.value,
                              }));
                          }}
                          sx={{ 
                              width: 250,
                              mb: 2,
                              gridColumn: "span 4",
                              "& label.Mui-focused": {
                                  color: theme.palette.secondary[200],
                              },
                              "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
                                  color: theme.palette.secondary[300],
                              }
                          }}
                          value={cleanUpTypes.find((cleanUpType) => cleanUpType.value === loadedFormData.cleanUpType) || defaultCleanUpType}
                          renderInput={(params) => (
                              <CssTextField
                                  {...params}
                                  label={tm.translate('dspCleanUpType', 'Cleanup Type')}
                                  theme={theme}
                              />
                          )}
                      />
                      {values.cleanUpType === "selective" && (
                        <Box sx={{ mt: 2 }}>
                          <Typography variant="body1" color={theme.palette.secondary[300]}>
                            Selective Retention keeps specific fields such as the first name, last name.
                          </Typography>
                          {/* <CssTextField
                            label="Additional Fields to Retain (e.g., Company Name)"
                            name="retainFields"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.retainFields}
                            theme={theme}
                            fullWidth
                          /> */}
                        </Box>
                      )}
                          
                    </Paper>
                    
                        <Button
                          startIcon={<Save />}
                          type="submit"
                          sx={{
                            p: "1rem",
                            m: "1rem 0rem 0rem 0rem",
                            backgroundColor: palette.secondary.light,
                            color: palette.background.alt,
                            fontWeight: "bold",
                            "&:hover": {
                              color: palette.primary.main,
                              "&:hover": { color: theme.palette.secondary.dark },
                              fontWeight: "bold",
                            },
                          }}
                        >
                          Save
                        </Button>
                  </form>
                )}
              </Formik>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default LocationPrivacyConfig;
