import React, { useState, useEffect } from 'react';
import { MenuItem } from '@mui/material';

const MenuItemsAddWorkflowElementVisitorData = ({ allAvailableFields, usedFields, handleAddVisitorDataElement }) => {

  const [itemsToShow, setItemsToShow] = useState([]); // Track selected step


    useEffect(() => {
      
      // Filter out fields from allAvailableFields that are not in usedFields
      const filteredFields = allAvailableFields.filter(
        (field) => !usedFields.some((usedField) => usedField.apiKey === field.apiKey)
      );

      setItemsToShow(filteredFields);

  }, []);

  return (
    <div>
      {itemsToShow.length === 0 ? (
        <div style={{ padding: '8px', textAlign: 'center', color: 'gray' }}>
          No more elements available
        </div>
      ) : (
        itemsToShow.map((step, index) => (
          <MenuItem
            key={index}
            onClick={() => handleAddVisitorDataElement(step)}
            disableRipple
          >
            {step.fieldName}
          </MenuItem>
        ))
      )}
    </div>
  );
};

export default MenuItemsAddWorkflowElementVisitorData;
