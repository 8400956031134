import {   CloudUpload, FindInPage } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import React, {  useEffect } from 'react'
import { useState } from "react";
import { getSessionToken, getUri } from "hooks/HttpRequests";
import { enqueueSnackbar } from "notistack";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useDispatch } from "react-redux";


const DialogUploadAttachment = ({ open, onClose, onSave, entityPointerId, entityKeyname, entityTable  }) => {

  const maxUploadSize = 1024 * 1024 //1MB
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();

  var allowedTypes = ['image/png', 'image/jpeg'];

    const theme = useTheme();

    const [selectedFileName, setSelectedFileName] = useState("no file selected");
    const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    
    setSelectedFile(null)


  }, [open]);

  ///////////////////////////////
  const onFileChange = event => {

    const file = event.target.files[0];

    if( typeof file === "undefined"){
      return
    }

    // if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
    //   alert('Please select a PNG or JPEG image file.');
    //   return;
    // }

    // if (file.size > 1024 * 1024) {
    //   alert('File size should not exceed 1MB.');
    //   return;
    // }

    setSelectedFileName(file.name)

    setSelectedFile(file)

    // const reader = new FileReader();
    //     reader.onload = () => {
    //       setReadFile(reader.result);
    //     };
    // reader.readAsDataURL(file);

  };

  const handleSave = () => {

      if (!selectedFile) {

        enqueueSnackbar({
              message: "Please select a file first",
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
        return;
      }
      dispatch(showLoading( ""))

        // Create a FormData object to hold the form parameters and file
        const formData = new FormData();
        formData.append('entityId', entityPointerId);
        formData.append('entityTable', entityTable); 
        formData.append('entityKeyname',  entityKeyname); 
        formData.append('file', selectedFile);
        // formData.append('frontDeskEntityId', 456);

        // Configure Axios to send a POST request
        axios.post(getUri(getSessionToken())+'/attachment', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {

          dispatch(hideLoading());
          if(response.data.success){
            enqueueSnackbar({
              message: "File uploaded successfully",
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000,
                // persist: true
              
            });

            onClose()
          }else{
            enqueueSnackbar({
              message: "Error :"+response.data.message,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
          }
            

        })
        .catch(error => {
            console.error("Error uploading file:", error);
            dispatch(hideLoading());

            enqueueSnackbar({
              message: "Error uploading file:"+error,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
            onClose()
        });

}

  const handleReset = () => {


    setSelectedFileName("no file selected")

    setSelectedFile(null)

    document.getElementById("attachmentUploadFileInputDialog").value = null;

  }

  const handleClose = () => {

      handleReset()
      onClose();
  }



  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>Upload Document</DialogTitle>
        <DialogContent>

        <Box 
                sx={{ 
                    m: "0rem 0rem 0rem 0rem",
                    borderRadius: 1,
                    borderColor: theme.palette.secondary[100],
                    border: 0 , p: 2,
                    backgroundColor:theme.palette.background.alt,
                    ...(isNonMobile ? { // Apply flex styles only if it's not a mobile device
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      } : {}),
             }}>  
            
              <Typography
                variant="h5"
                color={theme.palette.secondary[300]}
                sx={{ ml: 'auto' }} // Right align the typography
              >
                {selectedFileName}
              </Typography>
                <Button
                    fullWidth
                    startIcon={<FindInPage style={{ fontSize: theme.typography.iconSizes.large }} />}
                    sx={{
                        p: "1rem",
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontWeight: "bold",
                        ml: 2,
                        maxWidth: 180,
                        "&:hover": {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.secondary.light,
                        fontWeight: "bold",
                        },
                        // Style for file input button
                        "& > input[type=file]": {
                        display: "none", // Hide the input element
                        },
                    }}
                    onClick={() => {
                        // Trigger click on the hidden file input element
                        document.getElementById("attachmentUploadFileInputDialog").click();
                    }}
                    >
                    Browse File
                    </Button>
                    <input
                        type="file"
                        id="attachmentUploadFileInputDialog"
                        style={{ display: 'none' }}
                        onChange={onFileChange} // Define your file change handler function
                        //multiple // Allow selecting multiple files
                    />
                <Button
                fullWidth
                startIcon={<CloudUpload style={{ fontSize: theme.typography.iconSizes.large }} />}
                type="submit"
                onClick={handleSave}
                sx={{
                    p: "1rem",
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontWeight: "bold",
                    ml: 2,
                    maxWidth: 180,
                    "&:hover": {
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.secondary.light,
                    fontWeight: "bold",
                    },
                }}
                >
                Upload File
                </Button>
            </Box>

          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {/* Are you sure you want to execute this action? */}
        
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}> 
          {/* {/* <Button onClick={onClearImage} color="primary">remove photo</Button> */}

          <div>
            <Button onClick={handleClose} color="primary" sx={{ color: theme.palette.background.alt }}>
              Cancel
            </Button>
          </div>  
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogUploadAttachment