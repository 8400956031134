import React, {  useEffect, useRef, useState } from "react";
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import {  useNavigate } from "react-router-dom";

import tm from "components/TranslationManager";

import { GoogleMap, Marker, InfoWindow, useLoadScript } from "@react-google-maps/api";
import { addHostToVisit, getListValuesPaging} from "hooks/HttpRequests";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { sadevioColors } from "components/Colors";
import GridCellVisitsActions from "components/GridCellVisitsActions";
import GridCellLocationRegion from "components/GridCellLocationRegion";
import { enqueueSnackbar } from "notistack";
import DialogLocationGroup from "components/dialog/DialogLocationGroup";


const GlobalLocations = ({ route }) => {

  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedFrontDesk, allAvailableFrontDesks } = useFrontDeskManager();

  const theme = useTheme();

  const [selectedMarker, setSelectedMarker] = useState(null);


  /////////////// DIALOG ADD/UDATE GROUP /////////////
  const [openDialogHostLookup, setOpenDialogHostLookup] = useState(false);

  const onCloseDialogHostLookup = (e) => {


    // setTriggerLoad(!triggerLoad);

    setOpenDialogHostLookup(false)


  }; 

  const onSaveDialogHostLookup = (e) => {


    // setTriggerLoad(!triggerLoad);

    setOpenDialogHostLookup(false)

    // const visitId = id;
    const hostId = e.entityId

    dispatch(showLoading(""))

    addHostToVisit(navigate)(null, hostId, selectedFrontDesk.entityId)
      .then((result) => {
      // Use the result.data as needed      
      dispatch(hideLoading());
          if(result.success){
              
            enqueueSnackbar({
              message: "Host was added to the visit",
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });

            // setTriggerRefresh(!triggerRefresh);

          }else{
            enqueueSnackbar({
              message: "Something went wrong during adding the host",
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
          }


    })
    .catch((error) => {
      dispatch(hideLoading());

      if (typeof error.action !== "undefined") {
        if (error.action == "relogin") {
          navigate("/");
        }
      }else if (error.hasOwnProperty('success')) {
        enqueueSnackbar({
          message: "Error "+error.message,
            variant: 'error',
            anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
            },
            autoHideDuration: 3000, 
            // persist: true
          
        });
      }

    });

  };

  /////////////// GRID GROUPS /////////////
  // values to be sent to the backend

  const [dataGroups, setDataGroups] = useState({ data: [], total_count: 0 });
  const [gridGroupsLoading, setGridGroupsLoading] = useState(false);
  const [columnVisibilityModelGroups, setColumnVisibilityModelGroups] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });

  const [searchGroups, setSearchGroups] = useState("");

  const [paginationModelGroups, setPaginationModelGroups] = useState({
    page: 0,
    limit: 25,
    sort: [],
    query:[]
  })


  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{
        "&.Mui-checked": { // Adjust the color when checked
          color: sadevioColors.hexColorInvited+' !important',
        },
        marginRight: '-14px',
      }}>
        <GridToolbarColumnsButton color="neutral"  sx={{
    "&.Mui-checked": { // Adjust the color when checked
      color: sadevioColors.hexColorInvited+' !important',
    },
    marginRight: '-14px',
  }} />
        <GridToolbarFilterButton  style={{ color: '#3f51b5' }}  />
      </GridToolbarContainer>
    );
  }

  
    useEffect(() => {
      // This code will run every time paginationModel changes
      loadGridDataGroups();

    }, [paginationModelGroups]);

    const handleFilterModelChangeGroups = (filterModel) => {
      // Update the filter model state when it changes
  
      console.log("handleFilterModelChange()")
  
      // Convert to the desired structure
      const desiredStructure = filterModel.items.map(item => {
        let mappedOperator = item.operator;
      
        let field = columns.find(column => column.field === item.field);
        var fieldType = "string";
  
        if(typeof field.type !== 'undefined'){
          fieldType = field.type;
        }
  
        // Map operators as needed
        if (item.operator === 'equals') {
          mappedOperator = 'eq';
        } else if (item.operator === 'contains') {
          mappedOperator = 'like';
        } // Add more conditions as needed
      
        return {
          property: item.field,
          value: item.value,
          type: fieldType, // You might need to adjust this based on your data types
          operator: mappedOperator
        };
      });
  
      setPaginationModelGroups((prevPaginationModel) => ({
        ...prevPaginationModel,
        query: desiredStructure,
        page: 0,
      }));
  
    };
  
    const handleSortModelChangeGroups = (sortModel) => {
      // Handle sorting changes and fetch data from the server
      //sort: [{"property":"lastname","direction":"ASC"}]
  
      console.log("handleSortModelChange()")
  
      const sortObject = sortModel.map(item => ({
        property: item.field,
        direction: item.sort.toUpperCase() // Assuming you want direction in uppercase (ASC or DESC)
      }));
  
      setPaginationModelGroups((prevPaginationModel) => ({
        ...prevPaginationModel,
        sort: sortObject,
        page: 0,
      }));
  
    };
  
    const handlePageChangeGroups= (newPage) => {
      // Handle page changes and fetch data from the server
  
      console.log("handlePageChange()")
  
      setPaginationModelGroups((prevPaginationModel) => ({
        ...prevPaginationModel,
        page: newPage.page,
        limit: newPage.pageSize,
      }));
  
    };

    const handleOptionClickGroups = (id, action, row) => {
        console.log(action)

        // if(action == "cancel_visit"){

        //     // setConfirmationOpen(true);
        //     cancelVisit(navigate)(row.visitId, {})
        //     .then((result) => {

        //       // setData(result)
        //       setGridLoading(false)

        //     })
        //     .catch((error) => {

        //       console.log(error);

        //       setGridLoading(false)
        //     });

        // }else if(action == "delete_visit"){

        //     setConfirmationOpen(true);
        //     setYesNoMessage("Do you really want to delete this visit?")
        //     row["action"] = "delete_visit";
        //     setYesNoDialogEntity(row)
        // }

    }
  
    const loadGridDataGroups = async () => {
  

      setGridGroupsLoading(true)

      getListValuesPaging(navigate)(paginationModel, "locationgroup", "")
      .then((result) => {

        setDataGroups(result)
        setGridGroupsLoading(false)

      })
      .catch((error) => {
        setGridGroupsLoading(false)
        
      });
  
    }

    const columnsGroups = [
    
      {
        field: "entityId",
        headerName: "Entity Id",
        flex: 1,
        type: 'number'
      },
      {
        field: "active",
        flex: 1,
        headerName: tm.translate('clmActive', "active"),
      },
      {
        field: "name",
        flex: 1,
        headerName: tm.translate('clmName', "Name"),
      },
      {
        field: "building",
        flex: 1,
        headerName: tm.translate('clmBuilding', "Building"),
      },
      {
        field: "region",
        headerName: tm.translate('clmRegion', "Region"),
        minWidth: 220,
        renderCell: (params) => (
          <GridCellLocationRegion rowData={params.row} />
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 80,
        sortable: false,
        renderCell: (params) => (
          <GridCellVisitsActions
            id={`${params.row.visitId}_${params.row.visitorId}`}
            row={params.row}
            onOptionClick={handleOptionClick}
          />
        ),
      },
    ];

  /////////////// GRID LOCATIONS /////////////
  // values to be sent to the backend

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });

  const [search, setSearch] = useState("");

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [],
    query:[]
  })


  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{
        "&.Mui-checked": { // Adjust the color when checked
          color: sadevioColors.hexColorInvited+' !important',
        },
        marginRight: '-14px',
      }}>
        <GridToolbarColumnsButton color="neutral"  sx={{
    "&.Mui-checked": { // Adjust the color when checked
      color: sadevioColors.hexColorInvited+' !important',
    },
    marginRight: '-14px',
  }} />
        <GridToolbarFilterButton  style={{ color: '#3f51b5' }}  />
      </GridToolbarContainer>
    );
  }

  
    useEffect(() => {
      // This code will run every time paginationModel changes
      loadGridData();

    }, [paginationModel]);

    const handleFilterModelChange = (filterModel) => {
      // Update the filter model state when it changes
  
      console.log("handleFilterModelChange()")
  
      // Convert to the desired structure
      const desiredStructure = filterModel.items.map(item => {
        let mappedOperator = item.operator;
      
        let field = columns.find(column => column.field === item.field);
        var fieldType = "string";
  
        if(typeof field.type !== 'undefined'){
          fieldType = field.type;
        }
  
        // Map operators as needed
        if (item.operator === 'equals') {
          mappedOperator = 'eq';
        } else if (item.operator === 'contains') {
          mappedOperator = 'like';
        } // Add more conditions as needed
      
        return {
          property: item.field,
          value: item.value,
          type: fieldType, // You might need to adjust this based on your data types
          operator: mappedOperator
        };
      });
  
      setPaginationModel((prevPaginationModel) => ({
        ...prevPaginationModel,
        query: desiredStructure,
        page: 0,
      }));
  
    };
  
    const handleSortModelChange = (sortModel) => {
      // Handle sorting changes and fetch data from the server
      //sort: [{"property":"lastname","direction":"ASC"}]
  
      console.log("handleSortModelChange()")
  
      const sortObject = sortModel.map(item => ({
        property: item.field,
        direction: item.sort.toUpperCase() // Assuming you want direction in uppercase (ASC or DESC)
      }));
  
      setPaginationModel((prevPaginationModel) => ({
        ...prevPaginationModel,
        sort: sortObject,
        page: 0,
      }));
  
    };
  
    const handlePageChange = (newPage) => {
      // Handle page changes and fetch data from the server
  
      console.log("handlePageChange()")
  
      setPaginationModel((prevPaginationModel) => ({
        ...prevPaginationModel,
        page: newPage.page,
        limit: newPage.pageSize,
      }));
  
    };

    const handleOptionClick = (id, action, row) => {
        console.log(action)

        // if(action == "cancel_visit"){

        //     // setConfirmationOpen(true);
        //     cancelVisit(navigate)(row.visitId, {})
        //     .then((result) => {

        //       // setData(result)
        //       setGridLoading(false)

        //     })
        //     .catch((error) => {

        //       console.log(error);

        //       setGridLoading(false)
        //     });

        // }else if(action == "delete_visit"){

        //     setConfirmationOpen(true);
        //     setYesNoMessage("Do you really want to delete this visit?")
        //     row["action"] = "delete_visit";
        //     setYesNoDialogEntity(row)
        // }

    }
  
    const loadGridData = async () => {
  
      // setGridLoading(true)
  
      // getVisitDashboardAll(navigate)( selectedFrontDesk.entityId, paginationModel, filterVisitorStatus, selectedVisitorType)
      //   .then((result) => {
  
      //     setData(result)
      //     setGridLoading(false)
  
      //   })
      //   .catch((error) => {
  
      //     console.log(error);
  
      //     setGridLoading(false)
      //   });
  
    }

    const columns = [
    
      {
        field: "entityId",
        headerName: "Entity Id",
        flex: 1,
        type: 'number'
      },
      {
        field: "active",
        headerName: tm.translate('clmActive', "Active"),
        type: 'boolean'
      },
      {
        field: "name",
        flex: 1,
        headerName: tm.translate('clmName', "Name"),
      },
      {
        field: "building",
        flex: 1,
        headerName: tm.translate('clmBuilding', "Building"),
      },
      {
        field: "region",
        headerName: tm.translate('clmRegion', "Region"),
        minWidth: 220,
        renderCell: (params) => (
          <GridCellLocationRegion rowData={params.row} />
        ),
      },
      // {
      //   field: 'actions',
      //   headerName: 'Actions',
      //   width: 80,
      //   sortable: false,
      //   renderCell: (params) => (
      //     <GridCellVisitsActions
      //       id={`${params.row.visitId}_${params.row.visitorId}`}
      //       row={params.row}
      //       onOptionClick={handleOptionClick}
      //     />
      //   ),
      // },
    ];

  ///////// google maps

  const [markers, setMarkers] = useState([]);

  const [coordinates, setCoordinates] = useState({ id: 123, lat: 38.8977, lng: -77.036560 });

  useEffect(() => {

    var localMarker = []

    if (allAvailableFrontDesks) {
      
      for (const element of allAvailableFrontDesks) {
        console.log(element);
        var marker = {}
        marker["id"] = element.entityId;
        marker["lat"] = element.latitude;
        marker["lng"] = element.longitude;
        marker["name"] = element.name;
        localMarker.push(marker);
      }

    }

    setMarkers(localMarker)

  }, []);

  const titleStyles = {
    fontSize: '14px',
    color: 'grey',
    cssText: 'color: grey !important;',
    fontWeight: 'bold',
  };

  const libraries = ["places"];

  const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      libraries

    });


  const mapRef = useRef();
  const onMapLoad = (map) => {
    mapRef.current = map;
    fitBounds(map);
  };

  const fitBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  useEffect(() => {
    if (mapRef.current) {
      fitBounds(mapRef.current);
    }
  }, [markers]);

  
  const buttons = [
  ];

  return (
    <Box m="1rem 2rem" 
        mt="0px"
        height="80vh">
      <Header title="Global Overview" subtitle="List of all locations" elements={buttons} />

      <DialogLocationGroup
          open={openDialogHostLookup}
          onClose={() => onCloseDialogHostLookup()}
          onSave={(entity) => onSaveDialogHostLookup(entity)}

      />
      {/* Groups */}

      {/* <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
          <Box>
            <Box
              display="flex"
              flexDirection="row" // Render elements horizontally
              alignItems="center"
            >
          
              <Typography
                variant="h4"
                color={theme.palette.secondary[100]}
                fontWeight="bold"
              >
              Groups
              </Typography>
            </Box>
            
          </Box>
            <Box
              display="flex"
              flexDirection="row" // Render elements horizontally
              alignItems="center"
            >
              <Button
                key={12}
                variant="contained"
                onClick={() => setOpenDialogHostLookup(true)}
                sx={{
                  backgroundColor: theme.palette.secondary.light,
                  color: theme.palette.background.alt,
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "12px 20px",
                  m: "0rem 0.5rem 0rem 0rem",
                  "&:hover": { color: theme.palette.secondary.light },
                }}
              >
                {"create group"}
              </Button>
            </Box>
    </Box> */}
    <Box
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },          
        }}
      >
        {/* <DataGridPro
          key={3445213}
          loading={gridLoading || !allAvailableFrontDesks}
          initialState={{
            pagination: { paginationModel: { pageSize: paginationModel.limit } },
          }}
          // getRowId={(row) => row.entityId}
          getRowId={(row) => `${row.entityId}`}
          // hideFooter={true}
          rows={allAvailableFrontDesks || []}
          columns={columns}
          rowCount={(allAvailableFrontDesks && allAvailableFrontDesks.length) || 0}
          rowsPerPageOptions={[25, 50, 100]}
          columnVisibilityModel={columnVisibilityModel}
          // onColumnVisibilityChange={handleColumnVisibilityChange}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }

          // checkboxSelection

          filterMode="server"
          onFilterModelChange={handleFilterModelChange}

          sortingMode="server"
          onSortModelChange={handleSortModelChange}

          paginationMode="server"
          pagination
          page={paginationModel.page}
          pageSize={paginationModel.limit}
          onPaginationModelChange={(paginationModel) => handlePageChange(paginationModel)} //setPageSize(paginationModel.pageSize)

          slots={{ toolbar: CustomToolbar }}
          // slots={{ toolbar: null, columnHeaders: () => null, }}
          
          slotProps={{
            filterPanel: {
              // Force usage of "And" operator
              logicOperators: [],
              // Display columns by ascending alphabetical order
              columnsSort: 'asc',
              
              sx: {
                '& .MuiButtonBase-root': { color: `${theme.palette.secondary[200]} !important` },
                '& .MuiFormLabel-root': { color: `${theme.palette.secondary[200]} !important` },
              },
            },
          }}
        /> */}
      </Box>

      {/* </Box> */}
      <Box
        // mt="0px"
        // height="80vh"
      >
        {loadError ? (
              <Typography>Error loading Google Maps</Typography>
          ) : (
          
                  <Paper elevation={3} style={{ height: 400 }}>
                  {isLoaded ? (
                      <GoogleMap
                      mapContainerStyle={{ height: '100%', width: '100%' }}
                      center={coordinates}
                      zoom={10}
                      onLoad={onMapLoad}
                      >
               
                      {markers.map(({ id, lat, lng, name }) => (
                        <Marker
                          key={id}
                          position={{ lat, lng }}
                          onClick={() => setSelectedMarker({ id, lat, lng, name })}
                        />
                      ))}

                      {selectedMarker && (
                        <InfoWindow
                          position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                          onCloseClick={() => setSelectedMarker(null)}
                        >
                          <div>
                            <h2 style={titleStyles}>{selectedMarker.name}</h2>
                            <p>Additional description can go here</p>
                          </div>
                        </InfoWindow>
                      )}
              
                      </GoogleMap>
                  ) : (
                      <Typography>Loading...</Typography>
                  )}
                  </Paper>
          )}
      </Box>
      {/*  locations */}

      <Box>
        <Typography
            variant="h4"
            color={theme.palette.secondary[100]}
            fontWeight="bold"
          >
            Location names
          </Typography>

        </Box>
        <Box
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },          
        }}
      >
        <DataGridPro
          loading={gridLoading || !allAvailableFrontDesks}
          initialState={{
            pagination: { paginationModel: { pageSize: paginationModel.limit } },
          }}
          // getRowId={(row) => row.entityId}
          getRowId={(row) => `${row.entityId}`}
          // hideFooter={true}
          rows={allAvailableFrontDesks || []}
          columns={columns}
          rowCount={(allAvailableFrontDesks && allAvailableFrontDesks.length) || 0}
          rowsPerPageOptions={[25, 50, 100]}
          columnVisibilityModel={columnVisibilityModel}
          // onColumnVisibilityChange={handleColumnVisibilityChange}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }

          // checkboxSelection

          filterMode="server"
          onFilterModelChange={handleFilterModelChange}

          sortingMode="server"
          onSortModelChange={handleSortModelChange}

          paginationMode="server"
          pagination
          page={paginationModel.page}
          pageSize={paginationModel.limit}
          onPaginationModelChange={(paginationModel) => handlePageChange(paginationModel)} //setPageSize(paginationModel.pageSize)

          slots={{ toolbar: CustomToolbar }}
          // slots={{ toolbar: null, columnHeaders: () => null, }}
          
          slotProps={{
            filterPanel: {
              // Force usage of "And" operator
              logicOperators: [],
              // Display columns by ascending alphabetical order
              columnsSort: 'asc',
              
              sx: {
                '& .MuiButtonBase-root': { color: `${theme.palette.secondary[200]} !important` },
                '& .MuiFormLabel-root': { color: `${theme.palette.secondary[200]} !important` },
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default GlobalLocations;