import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import {  putHost } from 'hooks/HttpRequests';
import * as yup from "yup";

import { useUserProfileManager } from 'components/UserProfileProvider';
import ProfileEmailNotifications from './profileEmailNotifications';
import ProfileSMSNotifications from './profileSMSNotifications';


const UserProfileNotificatins = ({globalType, navigate, id }) => {

const [confirmationOpen, setConfirmationOpen] = useState(false);
const [authType, setAuthType] = useState("app");






const theme = useTheme();
const {palette} = useTheme();
const isNonMobile = useMediaQuery("(min-width:600px)");
const defaultCategory = { label: "Airline", value: "airline" };

const [gridLoading, setGridLoading] = useState(false);

const [showReviewers, setShowReviewers] = useState(false);
const defaultAuthType = { label: "Application", value: "app" };
const { userProfile, userProfleLoading } = useUserProfileManager();


const toggleReviewers = () => {
    setShowReviewers(!showReviewers);
};

useEffect(() => {
    
    loadData()

  }, [userProfile]);

  const loadData = async () => {

        // getHost(navigate)(id)
        // .then((result) => {

        // console.log(result)
        // if(result.success){
            setLoadedFormData(userProfile.session.user)
            setAuthType(userProfile.session.user.authType)
        // }

        // })
        // .catch((error) => {

        //     console.log(error);

        // setGridLoading(false)
        // });
  };

    const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const registerSchema = yup.object().shape({
    lastname: yup.string().required("required"),
    firstname: yup.string().required("required"),

  });

  const initialValuesRegister = {
    lastname: "",
    firstname: "",
  };

  const [loadedFormData, setLoadedFormData] = useState(null);


  const handleSubmit = (values) => {

    loadedFormData["firstname"] = values.firstname
    loadedFormData["lastname"] = values.lastname
    loadedFormData["email"] = values.email
    loadedFormData["department"] = values.department
    loadedFormData["poNumber"] = values.poNumber
    loadedFormData["phone"] = values.phone
    loadedFormData["mobile"] = values.mobile


    var submitData= {}
    submitData["host"]= loadedFormData;

    putHost(submitData)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {
      
            if(result.success){
                //   setCopmanyName("- "+result.data.name);
                setLoadedFormData(result.data)
            }

        }
      })
      .catch((error) => {
        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }
      });

  };

  // Render loading state if loadedFormData is null
  if (userProfleLoading && loadedFormData != null) {
    return <div>Loading...</div>;
  }


  return (

    <Box>
    
        <Typography variant="h5" color={theme.palette.secondary[300]}>
          {"Choose where you would like to be notified."}
        </Typography>
        
        <ProfileEmailNotifications />

        {/*  -------------------------------   */}

        <ProfileSMSNotifications />

        {/*  -------------------------------   */}
            

    </Box>
  );
};

export default UserProfileNotificatins;
