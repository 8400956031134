import React, { useEffect, useRef, useState } from 'react';
import {  Badge as MuiBadge, Box, Typography, Button, useTheme,  Grid, Paper, useMediaQuery, Avatar, Menu, ListItem, ListItemButton, ListItemAvatar, ListItemText, List, IconButton, Tooltip, Checkbox, Autocomplete, FormControlLabel, CircularProgress, Stack, MenuItem } from '@mui/material';
import {  ArrowDropDown, Badge, Brush, CameraAlt, CheckCircle, Close, DeleteOutline, FlightLand, FlightTakeoff, KeyboardArrowDown, PersonAdd,  Print,  Save, Send,  Verified} from '@mui/icons-material';
import { addHostToVisit, addVisitorToVisit, checkDeniedPerson, getSessionToken, getUri, getVisit, getVisitorVisitCount, postHostNotification, postVisit, printLabelVisitor,  putVisitBulkCheckIn, putVisitBulkCheckOut, putVisitMeetingRoom, putVisitor, putVisitTimeChange, putVisitVisitorCheckIn, putVisitVisitorCheckOut, removeHostToVisit, removeVisitorToVisit, sendVisitorIncitation, visitCancel, visitDelete, visitFinish } from 'hooks/HttpRequests';
import {  CssTextField, stringAvatar, CssDateTimePicker} from "components/StyledComponents";
import { FaPassport} from "react-icons/fa"
import * as yup from "yup";
import { Formik } from 'formik';
import tm from 'components/TranslationManager';
import DialogTakePicture from 'components/dialog/DialogTakePicture';

import { sadevioColors } from 'components/Colors';
import DialogHostLookup from 'components/dialog/DialogHostLookup';
import { hideLoading, showLoading } from 'state/loadingSlicer';
import { enqueueSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useFrontDeskManager } from 'components/FrontDeskManager';
import FlexBetween from 'components/FlexBetween';
import dayjs from 'dayjs';
import printJS from 'print-js';
import MenuItemsVisitStatus from 'components/MenuItemsVisitStatus';
import MenuItemsVisitorBulkActions from 'components/MenuItemsVisitorBulkActions';
import { useParams } from 'react-router-dom';
import DialogAddVisitor from 'components/dialog/DialogAddVisitor';
import DialogBadgeHandlingOld from 'components/dialog/DialogBadgeHandlingOld';
import IOSSwitch from 'components/IOSSwitch';
import DialogYesNo from 'components/dialog/DialogYesNo';
import { getVisitorStatusColor, getVisitorStatusLabel, getVisitStatusColor, getVisitStatusLabel, transformApiKeyToCamelCase, transformApiKeyToCamelCaseUpperCase } from 'components/Utils';
import MenuItemsVisitorNotification from 'components/MenuItemsVisitorNotification';
import ScanPassportDialog from 'components/ScanPassportDialog';
import { useUserProfileManager } from 'components/UserProfileProvider';
import DialogShowPotentialMatches from 'components/dialog/DialogShowPotentialMatches';
import { Oval } from 'react-loader-spinner';


const VisitDetailGeneral = ({ globalType, navigate, id, visitorId }) => {


  const { palette } = useTheme();
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const { paramVisitId, paramVisitorId } = useParams();

  ////////////////////////////////

  const { selectedFrontDesk, selectedFrontDeskTerminalSettings, frontDeskLoading, convertToFrontDeskTimeZone, meetingRooms } = useFrontDeskManager();
  const { userProfile, userProfleLoading, hasPermission } = useUserProfileManager();

  /////////////////////

  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const [confirmationTakePictureOpen, setConfirmationTakePictureOpen] = useState(false);

  const [loadedFormData, setLoadedFormData] = useState(null); //the entire visit information

  const [selectedVisitor, setSelectedVisitor] = useState(null); //only the main selected visitor
  
  const [additionalFields, setAdditionalFields] = useState([]);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [yesNoMessage, setYesNoMessage] = useState("");
  const [yesNoDialogEntity, setYesNoDialogEntity] = useState({})

  const [validationSchema, setValidationSchema] = useState(yup.object());

  const [passportScannerOpen, setPassportScannerOpen] = useState(false);
  const [scanRequested, setScanRequested] = useState(false);

  const [openPotentialMatch, setOpenPotentialMatches] = useState(false);
  const [currentPotentialMatches, setCurrentPotentialMatches] = useState(false);


  ////////////////////////////
  //  loading visit information
  ////////////////////////////

  const [visitInformationLoading, setVisitInformationLoading] = useState(false);
  const [visitStatus, setVisitStatus] = useState("");
  const [visitStarted, setVisitStarted] = useState(false);
  const [meetingRoom, setMeetingRoom] = useState("");
  const [visitStatusColor, setVisitStatusColor] = useState("");
  const [visitStatusLabel, setVisitStatusLabel] = useState("");
  const [isVisitCountLoading, setIsVisitCountLoading] = useState(true);
  const [visitorVisitCount, setVisitorVisitCount] = useState(0);

  const [validFromDayJS, setValidFromDayJS] = useState(dayjs().set("hour", 9).startOf("hour"));
  const [validToDayJS, setValidToDayJS] = useState(dayjs().set("hour", 12).add(1, 'year'));

  const handleValidFromDateChange = (item) => {


    if (item.isAfter(validToDayJS)) {
      enqueueSnackbar({
        message: "Invalid time selection: 'Valid From' cannot be after 'Valid To'.",
        variant: 'error',
        anchorOrigin: {
          vertical: 'top', // 'top', 'bottom'
          horizontal: 'center', // 'left', 'center', 'right'
        },
        autoHideDuration: 3000,
      });

       // Temporarily force a re-render by setting to a placeholder
        const currentValidValue = validFromDayJS; // Save the current valid value
        setValidFromDayJS(dayjs()); // Temporary dummy value (current time)

        // Revert to the previous valid value
        setTimeout(() => setValidFromDayJS(currentValidValue), 0);


    }else{

        const localDateTimeFrom = validFromDayJS.format('MM/DD/YYYY hh:mm A');
        const localDateTimeTo = validToDayJS.format('MM/DD/YYYY hh:mm A');

        setValidFromDayJS(item)
      
        var submitJson = {};
        submitJson["frontDeskEntityId"] = selectedFrontDesk.entityId;
        submitJson["validFrom"] = item.format('MM/DD/YYYY hh:mm A');
        submitJson["validFromOriginal"] = localDateTimeFrom;
        submitJson["validTo"] = localDateTimeTo;
        

        if(id > 0){ //check need for new visit where visitId is -1
          putVisitTimeChange(navigate)(id, submitJson)
          .then((result) => {


            if(result.success){

              enqueueSnackbar({
                message: "Time was changed.",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }

          })
          .catch((error) => {
              console.log(error);

        });
      }

    }

    
  
  
  }

  const handleValidToDateChange = (item) => {


    if (item.isBefore(validFromDayJS)) {
      enqueueSnackbar({
        message: "Invalid time selection: 'Valid To' cannot be before 'Valid From'.",
        variant: 'error',
        anchorOrigin: {
          vertical: 'top', // 'top', 'bottom'
          horizontal: 'center', // 'left', 'center', 'right'
        },
        autoHideDuration: 3000,
      });

       // Temporarily force a re-render by setting to a placeholder
        const currentValidValue = validToDayJS; // Save the current valid value
        setValidToDayJS(dayjs()); // Temporary dummy value (current time)

        // Revert to the previous valid value
        setTimeout(() => setValidToDayJS(currentValidValue), 0);


    }else{

      const localDateTimeFrom = validFromDayJS.format('MM/DD/YYYY hh:mm A');
      const localDateTimeTo = validToDayJS.format('MM/DD/YYYY hh:mm A');

      setValidToDayJS(item)
    
      var submitJson = {};
      submitJson["frontDeskEntityId"] = selectedFrontDesk.entityId;
      submitJson["validFrom"] = localDateTimeFrom;
      submitJson["validTo"] =  item.format('MM/DD/YYYY hh:mm A');
      submitJson["validToOriginal"] = localDateTimeTo;
      

      putVisitTimeChange(navigate)(id, submitJson)
      .then((result) => {


        if(result.success){

          enqueueSnackbar({
            message: "Time was changed.",
              variant: 'success',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      })
      .catch((error) => {
        
        console.log(error);

    });

  }

  }

  useEffect(() => {
      

    if(globalType == "edit"){
      loadData(id)
    }else{
      id = -1;

      var defaultValues = {
        frontDeskEntityId : selectedFrontDesk.entityId,
        roomNumber: "",
        status: "pre_registered",
        visitId: -1,
        visitors: [],
        hosts: []
      }

      setLoadedFormData(defaultValues)
      setVisitStatus("pre_registered");
      setVisitStatusLabel(tm.translate('lblInvited', "Invited"));   
    }

    // const defaultVisitorType = { label: 'All Visitor Types', value: "all" };

    var visitorTypes = [];
    // visitorTypes.push(defaultVisitorType)
    for (const item of selectedFrontDeskTerminalSettings.checkInWorkflow) {
      var tmp = { label: item.visitorType, value: item.visitorType };
      visitorTypes.push(tmp)
    }

    setAllVisitorTypes(visitorTypes);

  }, [id, triggerRefresh]);

  ////////////////////////////
  //  loading visitor information
  ////////////////////////////

  const [visitorData, setVisitorData] = useState({ data: [], total_count: 0 });
  const [gridLoadingVisitor, setGridLoadingVisitor] = useState(false);
  const [selectedVisitorIndex, setSelectedVisitorIndex] = useState(-1);
  const formikRef = useRef();
  const resetButtonRef = useRef(null);
  const [visitorCheckInStatus, setVisitorCheckInStatus] = useState(true);


  const defaultVisitorType = { label: 'All Visitor Types', value: "all" };
  const [allVisitorTypes, setAllVisitorTypes] = useState([]);

  const [selectedVisitorType, setSelectedVisitorType] = useState(defaultVisitorType);


  useEffect(() => {
      
    if(visitorData.data.length > 0 && selectedVisitorIndex >= 0){

      var selectedVisitor = visitorData.data[selectedVisitorIndex];
      setSelectedVisitor(selectedVisitor)
      loadVisitorVisitCountData(selectedVisitor.visitorId)
      var visitorStatus= selectedVisitor.visitorStatus;

      let visitorTypeValue = selectedVisitor?.visitorType ? selectedVisitor.visitorType : 'Visitor'; // Default to 'Visitor' if empty

      if(selectedVisitorType != visitorTypeValue){
        var value = {}
        value["value"] = visitorTypeValue
        handleVisitorTypeSelectChange(null,value)
      }

      if(selectedVisitor.visitorStatus == "pre_registered" || selectedVisitor.visitorStatus == "checked_out" || selectedVisitor.visitorStatus == "pre_checked_in" || selectedVisitor.visitorStatus == "confirmed"){
        setVisitorCheckInStatus(true)
      }else{
        setVisitorCheckInStatus(false)
      }
      
      const newUrl = `/visit/${id}/visitor/${selectedVisitor.visitorId}/general`;
      window.history.pushState(null, '', newUrl);
      
    }
    

  }, [selectedVisitorIndex, visitInformationLoading]);


  ////////////////////////////
  //  loading host information
  ////////////////////////////

  const [hostData, setHostData] = useState({ data: [], total_count: 0 });
  const [gridLoadingHost, setGridLoadingHost] = useState(false);
  

  //////////////////////

  const loadData = async (id) => {

        setVisitInformationLoading(true);
        setGridLoadingHost(true);
        setGridLoadingVisitor(true);

        setSelectedVisitorIndex(-1)
        setSelectedVisitor(null)

        getVisit(navigate)(id)
            .then((result) => {


            if(result.success){

                setLoadedFormData(result.data)
                
                var visitStatus = result.data.status;
                var visitStatusLabel = result.data.status;


                if(visitStatus === 'started'){
                  setVisitStarted(true)
                }

                visitStatusLabel = getVisitStatusLabel(visitStatus);

                setVisitStatus(visitStatus);
                setVisitStatusLabel(visitStatusLabel);               
                setMeetingRoom(result.data.roomNumber)

                // const localDateTimeFrom = dayjs.utc(result.data.validFrom).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm');
                // const localDateTimeTo = dayjs.utc(result.data.validTo).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm')

                const localDateTimeFrom = convertToFrontDeskTimeZone(result.data.validFrom);
                const localDateTimeTo = convertToFrontDeskTimeZone(result.data.validTo);

                setValidFromDayJS(localDateTimeFrom)
                setValidToDayJS(localDateTimeTo)

                setMeetingRoom(result.data.roomNumber)

                var obj = { data: [], total_count: result.data.visitors.length };
                obj.data = result.data.visitors
                // setVisitorData(obj)

                obj.data.forEach(item => {
                  item.deniedStatus = -1;
                });                

                setVisitorData((prevVisitorData) => {
              
    
              
                  const index = obj.data.findIndex(entry => entry.visitorId === parseInt(paramVisitorId));
                  
                  if (index !== -1) {
                    // console.log(`Visitor ID ${paramVisitorId} found at index ${index}`);
                    setSelectedVisitorIndex(index)
                    var array = []
                    array.push(obj.data[index])
                    setChecked(array);
                  } else {
                    setSelectedVisitorIndex(0)
                    var array = []
                    array.push(obj.data[0])
                    setChecked(array);
                  } 

                  setSelectedVisitor(index >= 0 ? obj.data[index] : null);
              
                  return obj;
                });

                executeCheckDeniedPerson(obj.data)

                // if(obj.data.length > 0){
                
                //   const index = obj.data.findIndex(entry => entry.visitorId === parseInt(paramVisitorId));
                  
                  
                //   if (index !== -1) {
                //     console.log(`Visitor ID ${paramVisitorId} found at index ${index}`);
                //     setSelectedVisitorIndex(index)
                //     var array = []
                //     array.push(obj.data[index])
                //     setChecked(array);
                //   } else {
                //     setSelectedVisitorIndex(0)
                //     var array = []
                //     array.push(obj.data[0])
                //     setChecked(array);
                //   }                  
                // }
                

                var objHost = { data: [], total_count: result.data.hosts.length };
                objHost.data = result.data.hosts
                setHostData(objHost)

                

                
            }
            setVisitInformationLoading(false)
            setGridLoadingHost(false);
            setGridLoadingVisitor(false);

            })
            .catch((error) => {
              console.log(error)
            setVisitInformationLoading(false);
            setGridLoadingHost(false);
            setGridLoadingVisitor(false);
        });
  };

  const loadVisitorVisitCountData = async (id) => {

      setIsVisitCountLoading(true)

      getVisitorVisitCount(navigate)(id)
          .then((result) => {


          if(result.success){
              setVisitorVisitCount(result.data)
          }
          setIsVisitCountLoading(false)

          })
          .catch((error) => {
            setIsVisitCountLoading(false)

      });
  };

  // const getStatusColor = (status) => {
  //   switch (status) {
  //     case 'pre_registered':
  //       return sadevioColors.hexColorVisitInvited;
  //     case 'started':
  //       return sadevioColors.hexColorVisitStarted;
  //     case 'finished':
  //       return sadevioColors.hexColorVisitFinished;
  //     case 'canceled':
  //       return sadevioColors.hexColorVisitCanceled;
  //     case 'no_show':
  //       return sadevioColors.hexColorVisitNoShow;
  //     case 'expired':
  //       return sadevioColors.hexColorVisitExpired;
  //     default:
  //       return theme.palette.text.primary;
  //   }
  // };  

  const registerSchema = yup.object().shape({
    firstname: yup.string().required("First name is required"),
    lastname: yup.string().required("Last name is required"),
    visitorType: yup.string().required("Visitor type is required"),
    ...additionalFields.reduce((schema, field) => {
      schema[field.apiKey] = field.required
        ? yup.string().required(`${field.fieldName} is required`)
        : yup.string();
      return schema;
    }, {}),
  });
  

  const initialValuesRegister = {
    visitorType: "Visitor",
    lastname: "",
    firstname: "",
    birthday:  null,
    email: "",
    department: "",
    companyName: "",
    mobile: "",
    phone: "",
    licensePlate: "",
    deniedPerson: false,
    idNumber: "",
    idKey: "",
    c_1: "",
    c_2: "",
    c_3: "",
    c_4: "",
    c_5: "",
    
  };



  const handleSubmit = (values) => {

    selectedVisitor["deniedPerson"] = values.deniedPerson

    selectedVisitor["visitorType"] = values.visitorType

    selectedVisitor["email"] = values.email

    selectedVisitor["firstname"] = values.firstname
    selectedVisitor["lastname"] = values.lastname
    selectedVisitor["department"] = values.department
    selectedVisitor["mobile"] = values.mobile
    selectedVisitor["phone"] = values.phone
    selectedVisitor["companyName"] = values.companyName
    selectedVisitor["idNumber"] = values.idNumber
    
    selectedVisitor["licensePlate"] = values.licensePlate    

    selectedVisitor["c_1"] = values.c_1
    selectedVisitor["c_2"] = values.c_2
    selectedVisitor["c_3"] = values.c_3
    selectedVisitor["c_4"] = values.c_4
    selectedVisitor["c_5"] = values.c_5


    var submitData= {}
    submitData["data"]= selectedVisitor;

    putVisitor(navigate)(submitData)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {
      
            if(result.success){
                //   setCopmanyName("- "+result.data.name);
                setTriggerRefresh(!triggerRefresh);
                enqueueSnackbar({
                  message: "Entity was updated",
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
            }

        }
      })
      .catch((error) => {
        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }
      });

  };

  const onDeleteClicked = () => {
      console.error("onDeleteClicked")
  }

    /////////////// DIALOG Access Card /////////////
    const [openDialogAccessCard, setOpenDialogAccessCard] = useState(false);

    const onCloseDialogAccessCard = (e) => {


      // setTriggerLoad(!triggerLoad);
  
      setOpenDialogAccessCard(false)
  
  
    }; 

    const onSaveDialogAccessCard = (e) => {

      setOpenDialogAccessCard(false)

      setTriggerRefresh(!triggerRefresh);
  

    }

    /////////////// DIALOG HOST LOOKUP /////////////
    const [openDialogHostLookup, setOpenDialogHostLookup] = useState(false);

    const onCloseDialogHostLookup = (e) => {


      // setTriggerLoad(!triggerLoad);
  
      setOpenDialogHostLookup(false)
  
  
    }; 

    const onSaveDialogHostLookup = (element) => {


      // setTriggerLoad(!triggerLoad);
  
      setOpenDialogHostLookup(false)

      if( id == -1){

        setGridLoadingHost(true);

        setLoadedFormData((prevState) => {

          // Check if the visitor already exists
          const isExistingHost = prevState.hosts.some(
            (host) => host.entityId === element.entityId
          );

          // If the visitor already exists, return the current state without changes
          if (isExistingHost) {
            return prevState;
          }

          const updatedHosts = [...prevState.hosts, element];

          // Update visitorData after updating loadedFormData
          const newHostData = {
            data: updatedHosts,
            total_count: updatedHosts.length,
          };

          setHostData(newHostData);

          return {
            ...prevState,
            hosts: updatedHosts,
          };
        });

        setGridLoadingHost(false);
      }else{
        const visitId = id;
        const hostId = element.entityId
  
        dispatch(showLoading(""))
  
        addHostToVisit(navigate)(visitId, hostId, selectedFrontDesk.entityId)
          .then((result) => {
          // Use the result.data as needed      
          dispatch(hideLoading());
              if(result.success){
                  
                enqueueSnackbar({
                  message: "Host was added to the visit",
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
  
                setTriggerRefresh(!triggerRefresh);
  
              }else{
                enqueueSnackbar({
                  message: "Something went wrong during adding the host",
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
              }
  
  
        })
        .catch((error) => {
          dispatch(hideLoading());
  
          if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
              navigate("/");
            }
          }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
              message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
          }
  
        });

      }
      
  
    };

    


    const handleRemoveHostFromVisit = (entityId) => {
  

      const visitId = id;
      const hostId = entityId

      if(hostData.data.length <= 1){
        enqueueSnackbar({
          message: "A visit needs minimum one employee",
            variant: 'error',
            anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
            },
            autoHideDuration: 3000, 
            // persist: true
          
        });

      }else{

        if(id == -1){

          setLoadedFormData((prevState) => {
            const updatedHosts = prevState.hosts.filter(
              (host) => host.entityId !== entityId
            );
        
            return {
              ...prevState,
              hosts: updatedHosts,
            };
          });

          setHostData((prevHostData) => {
            const updatedHostData = prevHostData.data.filter(
              (host) => host.entityId !== entityId
            );
        
            const newHostData = {
              data: updatedHostData,
              total_count: updatedHostData.length,
            };
        
            return newHostData;
          });

        }else{

          dispatch(showLoading(""))

          removeHostToVisit(navigate)(visitId, hostId, selectedFrontDesk.entityId)
            .then((result) => {
            // Use the result.data as needed      
            dispatch(hideLoading());
                if(result.success){
                    
                  enqueueSnackbar({
                    message: "Host was removed from visit",
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });

                  setTriggerRefresh(!triggerRefresh);

                }else{
                  enqueueSnackbar({
                    message: "Something went wrong during adding the host",
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
                }


          })
          .catch((error) => {
            dispatch(hideLoading());

            if (typeof error.action !== "undefined") {
              if (error.action == "relogin") {
                navigate("/");
              }
            }else if (error.hasOwnProperty('success')) {
              enqueueSnackbar({
                message: "Error "+error.message,
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }

          });
        }
      }
  
    };

    /////////////// DIALOG HOST LOOKUP /////////////
    const [openDialogAddVisitor, setOpenDialogAddVisitor] = useState(false);

    const onCloseDialogAddVisitor = (e) => {


      setTriggerRefresh(!triggerRefresh);
  
      setOpenDialogAddVisitor(false)
  
  
    }; 

    const onSaveDialogAddVisitor = (element) => {


      // setTriggerLoad(!triggerLoad);
  
      setOpenDialogAddVisitor(false)
      setPassportScannerOpen(false);

      if(id > 0){
        
        const visitId = id;
        const visitorId = element.visitorId

        dispatch(showLoading(""))

        addVisitorToVisit(navigate)(visitId, visitorId, selectedFrontDesk.entityId)
          .then((result) => {
          // Use the result.data as needed      
          dispatch(hideLoading());
              if(result.success){
                  
                enqueueSnackbar({
                  message: "Host was added to the visit",
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });

                setTriggerRefresh(!triggerRefresh);

              }else{
                enqueueSnackbar({
                  message: "Something went wrong during adding the host",
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
              }


        })
        .catch((error) => {
          dispatch(hideLoading());

          if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
              navigate("/");
            }
          }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
              message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
          }

        });
      }else{

        // Check if the visitor already exists
        const isExistingVisitor = loadedFormData.visitors.some(
          (visitor) => visitor.visitorId === element.visitorId
        );

        // If the visitor already exists, return the current state without changes
        if (!isExistingVisitor) {
          
          setGridLoadingVisitor(true);
          setSelectedVisitorIndex(-1);
          setSelectedVisitor(null);

          setLoadedFormData((prevState) => {
            element["visitorStatus"] = "pre_registered";
            element["accessCardId"] = "";
            element["accessCardLevel"] = "";

            

            

            const updatedVisitors = [...prevState.visitors, element];

            // Update visitorData after updating loadedFormData
            const newVisitorData = {
              data: updatedVisitors,
              total_count: updatedVisitors.length,
            };

            setVisitorData(newVisitorData);

            // Use updatedVisitors directly to set selected visitor
            setSelectedVisitorIndex(updatedVisitors.length - 1);
            setSelectedVisitor(updatedVisitors[updatedVisitors.length - 1]);
            loadVisitorVisitCountData(updatedVisitors.visitorId)
            return {
              ...prevState,
              visitors: updatedVisitors,
            };
          });

          setGridLoadingVisitor(false);
        }else{
          enqueueSnackbar({
            message: "Already part of the visit",
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      }
  
    };

    ///////////////////////////////////////////////
    //  Visit Status Menu
    ///////////////////////////////////////////////

    const [anchorElVisitStatus, setAnchorElVisitStatus] = useState(null);
    const openMenuVisitStatus = Boolean(anchorElVisitStatus);
    // const handleClick = (event: MouseEvent<HTMLElement>) => {
    // //   setAnchorEl(event.currentTarget);
    // };
    const handleOpenVisitStatusClick = (event) => {
      setAnchorElVisitStatus(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorElVisitStatus(null);
    };

    const handleStartVisit = (visitStatu) => {
      setAnchorElVisitStatus(null);

      const visitId = id;

      var everythingOk = true;

      var submitJson = { ...loadedFormData };
      delete submitJson.hostsCount;
      delete submitJson.visitorsCount;
      delete submitJson.frontDesk;

      submitJson["validFrom"] = validFromDayJS.format('MM/DD/YYYY hh:mm A');
      submitJson["validTo"] = validToDayJS.format('MM/DD/YYYY hh:mm A');

      //this is needed for the new visit logic since front desk is not loaded
      if (loadedFormData.frontDesk?.entityId) {
        submitJson["frontDeskEntityId"] = loadedFormData.frontDesk.entityId;
      } else {
        // Fallback to selectedFrontDesk.entityId
        submitJson["frontDeskEntityId"] = selectedFrontDesk.entityId;
      }      
      
      submitJson["status"] = visitStatu

      submitJson["roomNumber"] = meetingRoom;

      if(selectedFrontDeskTerminalSettings.forceRoomNumber == true && meetingRoom == ""){
        everythingOk = false;
        enqueueSnackbar({
          message: "Room Number is required",
            variant: 'error',
            anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
            },
            autoHideDuration: 3000, 
            // persist: true
          
        });
      }

      if(submitJson.hosts.length == 0){
        everythingOk = false;
        enqueueSnackbar({
          message: "The visit needs minimum one host",
            variant: 'error',
            anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
            },
            autoHideDuration: 3000, 
            // persist: true
          
        });
      }

      if(submitJson.visitors.length == 0){
        everythingOk = false;
        enqueueSnackbar({
          message: "The visit needs minimum one visitor",
            variant: 'error',
            anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
            },
            autoHideDuration: 3000, 
            // persist: true
          
        });
      }

      var isTimeOk = isSelectedTimeOk();
      if(!isTimeOk){
        everythingOk = false;
      }


      if(everythingOk){
        dispatch(showLoading(""))

        postVisit(navigate)(submitJson)
          .then((result) => {
          // Use the result.data as needed      
          dispatch(hideLoading());
              if(result.success){
                  
                enqueueSnackbar({
                  message: "Visit was started",
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });

                if(id == -1){
                  navigate(`/visit/${result.data}/visitor/${submitJson.visitors[0].visitorId}`);
                }else{
                  setTriggerRefresh(!triggerRefresh);
                }

              }else{
                enqueueSnackbar({
                  message: "Something went wrong/",
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
              }


        })
        .catch((error) => {
          dispatch(hideLoading());

          if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
              navigate("/");
            }
          }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
              message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
          }

        });
    }

    };
    
    const handleFinishVisit = () => {
      setAnchorElVisitStatus(null);

      const visitId = id;

      dispatch(showLoading(""))

      visitFinish(navigate)(visitId)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Visit was finished",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

              setTriggerRefresh(!triggerRefresh);

            }else{
              enqueueSnackbar({
                message: "Something went wrong/",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    };

    const isSelectedTimeOk = () => {
    
        var isOk = false;
        const now = dayjs();
        // if (validFromDayJS.isBefore(dayjs())) {
  
          if(validFromDayJS.isAfter(validToDayJS)){
            
                // Ext.Msg.alert(td('errTltDateRange', 'Date range wrong.'), td('errDateRange', 'The valid to date cannot be smaller then the valid from date.'), Ext.emptyFn);
                enqueueSnackbar('The valid to date cannot be smaller then the valid from date.',{ 
                  variant: 'error', anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 5000, // 5000 milliseconds = 5 seconds
                  // persist: true
                })
    
          }else{
              return true;
          }
  
  
        // }else{
            // enqueueSnackbar('The valid to date cannot be smaller then the valid from date.',{ 
            //   variant: 'error', anchorOrigin: {
            //     vertical: 'top', // 'top', 'bottom'
            //     horizontal: 'center', // 'left', 'center', 'right'
            //   },
            //   autoHideDuration: 5000, // 5000 milliseconds = 5 seconds
            //   // persist: true
            // })
        // }
  
        
  
        return isOk;
      }

    const handleNowShowVisit = () => {
      setAnchorElVisitStatus(null);
    };

    const handleCancelVisit = () => {
      setAnchorElVisitStatus(null);

      const visitId = id;

      dispatch(showLoading(""))

      visitCancel(navigate)(visitId)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Visit was canceled",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

              // setTriggerRefresh(!triggerRefresh);
              navigate("/visit/logs");

            }else{
              enqueueSnackbar({
                message: "Something went wrong/",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    };

    const handleDeleteVisit = () => {
      setAnchorElVisitStatus(null);

      const visitId = id;

      dispatch(showLoading(""))

      visitDelete(navigate)(visitId)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Visit was deleted",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

              // setTriggerRefresh(!triggerRefresh);
              navigate("/visit/logs");

            }else{
              enqueueSnackbar({
                message: "Something went wrong/",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    };

    const handleReOpenVisit = () => {
      setAnchorElVisitStatus(null);
    };

    ///////////////////////////////////////////////
    //  Visit Status Menu
    ///////////////////////////////////////////////

    const [anchorElVisitorNotification, setAnchorElVisitorNotification] = useState(null);
    const openMenuVisitorNotification = Boolean(anchorElVisitorNotification);
    // const handleClick = (event: MouseEvent<HTMLElement>) => {
    // //   setAnchorEl(event.currentTarget);
    // };
    const handleVisitorNoticiationClick = (event) => {
      setAnchorElVisitorNotification(event.currentTarget);
    };
    const handleVisitorNoticiationClose = () => {
      setAnchorElVisitorNotification(null);
    };

    
    const handleSendInvitation = () => {
      setAnchorElVisitorNotification(null);

      const visitId = id;
      const visitorId = selectedVisitor.visitorId

      dispatch(showLoading(""))

      sendVisitorIncitation(navigate)(visitorId,visitId)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Invitation was send",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

              setTriggerRefresh(!triggerRefresh);

            }else{
              enqueueSnackbar({
                message: "Something went wrong/",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    };

    ///////////////////////////////////////////////
    //  Buld Actions Menu
    ///////////////////////////////////////////////
    
    const [showBulkActionsButton, setShowBulkActionsButton] = useState(false);

    const handleBulkCheckIn = () => {
      setOpenDialogAddVisitor(false);
      
      const visitId = id;

      var submitJson = {}
      submitJson["visit_id"]= id;
      submitJson["front_desk_entity_id"]= selectedFrontDesk.entityId;
      submitJson["application_location"]= "visit configuration page";

      submitJson["visitors"] = checked.map(visitor => visitor.visitorId);


      dispatch(showLoading(""))

      putVisitBulkCheckIn(navigate)(visitId, submitJson)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Visitors are checked-in",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

              setTriggerRefresh(!triggerRefresh);

            }else{
              enqueueSnackbar({
                message: "Something went wrong/",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    };

    const handleBulkCheckOut = () => {
      setOpenDialogAddVisitor(false);

      const visitId = id;

      var submitJson = {}
      submitJson["visit_id"]= id;
      submitJson["front_desk_entity_id"]= selectedFrontDesk.entityId;
      submitJson["application_location"]= "visit configuration page";

      submitJson["visitors"] = checked.map(visitor => visitor.visitorId);


      dispatch(showLoading(""))

      putVisitBulkCheckOut(navigate)(visitId, submitJson)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Visit was finished",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

              setTriggerRefresh(!triggerRefresh);

            }else{
              enqueueSnackbar({
                message: "Something went wrong/",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    };

    const handleBulkPrintBadge = () => {
      var submitJson = {};
				
        submitJson["visitors"] = checked.map(visitor => visitor.visitorId);
				submitJson['visit_id'] = loadedFormData.visitId;

        dispatch(showLoading(""))

        printLabelVisitor()(submitJson)
          .then((result) => {
          // Use the result.data as needed      

          if(result.success){

            var token = getSessionToken();

            printJS(getUri(token)+'attachment/'+result.data.attachmentId+'/file/'+result.data.fileName+'/true');

          }

          dispatch(hideLoading());

        })
        .catch((error) => {
          dispatch(hideLoading());

          if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
              message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
          }

        });

    }

    const handleRemoveVisitorFromVisit = (entityId) => {
  

      const visitId = id;
      const visitorId = selectedVisitor.visitorId

      if(loadedFormData.visitors.length <= 1){
        enqueueSnackbar({
          message: "A visit needs minimum one visitor",
            variant: 'error',
            anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
            },
            autoHideDuration: 3000, 
            // persist: true
          
        });

      }else{


        if(id == -1){

          setLoadedFormData((prevState) => {
            const updatedVisitors = prevState.visitors.filter(
              (visitor) => visitor.visitorId !== selectedVisitor.visitorId
            );
        
            return {
              ...prevState,
              visitors: updatedVisitors,
            };
          });

          setVisitorData((prevVisitorData) => {
            const updatedVisitorData = prevVisitorData.data.filter(
              (visitor) => visitor.visitorId !== selectedVisitor.visitorId
            );
        
            const newVisitorData = {
              data: updatedVisitorData,
              total_count: updatedVisitorData.length,
            };
        
            // Update selectedVisitor and selectedVisitorIndex
            const newIndex =
              updatedVisitorData.length > 0 ? Math.max(0, updatedVisitorData.length - 1) : -1;
        
            setSelectedVisitorIndex(newIndex);
            setSelectedVisitor(newIndex >= 0 ? updatedVisitorData[newIndex] : null);
        
            return newVisitorData;
          });

        }else{
          dispatch(showLoading(""))

          removeVisitorToVisit(navigate)(visitId, visitorId, selectedFrontDesk.entityId)
            .then((result) => {
            // Use the result.data as needed      
            dispatch(hideLoading());
                if(result.success){
                    
                  enqueueSnackbar({
                    message: "Visitor was removed from visit",
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
  
                  setTriggerRefresh(!triggerRefresh);
  
                }else{
                  enqueueSnackbar({
                    message: "Something went wrong during removing the host",
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
                }
  
  
          })
          .catch((error) => {
            dispatch(hideLoading());
  
            if (typeof error.action !== "undefined") {
              if (error.action == "relogin") {
                navigate("/");
              }
            }else if (error.hasOwnProperty('success')) {
              enqueueSnackbar({
                message: "Error "+error.message,
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }
  
          });
        }
        
      }
  
    };


    ///////////////////////////////////////////////

    const [checked, setChecked] = useState([]);

    const handleToggle = (value: number) => () => {

      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];


      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        //keep always one checkbox mim checked
        if(newChecked.length > 1){
          newChecked.splice(currentIndex, 1);
        }
      }
      
      if(newChecked.length > 1){
        setShowBulkActionsButton(true);
      }else{
        setShowBulkActionsButton(false);
      }
      setChecked(newChecked);
    };

    const handleVisitorRowClick = (value) => () => {


      formikRef.current?.resetForm();
      // formikRef.current?.resetForm();
      setSelectedVisitor(null)
      // resetButtonRef.current.click();

      let visitor = visitorData.data.find(entity => entity.visitorId === value.visitorId);

      const currentIndex = visitorData.data.indexOf(visitor);

      setSelectedVisitorIndex(currentIndex)
      setSelectedVisitor(visitor)
      loadVisitorVisitCountData(visitor.visitorId)

      var newChecked = []
      newChecked.push(value);
      setChecked(newChecked);

      const newUrl = `/visit/${id}/visitor/${visitor.visitorId}/general`;
      window.history.pushState(null, '', newUrl);

    };  

    const printVisitorLabel = () => {

        var submitJson = {};
				var badgesToPrint = [];
        badgesToPrint.push( selectedVisitor.visitorId )
				
				submitJson['visitors'] = badgesToPrint;
				submitJson['visit_id'] = loadedFormData.visitId;

      dispatch(showLoading(""))

      printLabelVisitor()(submitJson)
        .then((result) => {
        // Use the result.data as needed      

        if(result.success){

          var token = getSessionToken();

          printJS(getUri(token)+'attachment/'+result.data.attachmentId+'/file/'+result.data.fileName+'/true');

        }

        dispatch(hideLoading());

      })
      .catch((error) => {
        dispatch(hideLoading());

        if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    }

    const onCheckInVisitor = () => {

      var submitJson = {};
          submitJson['application_location'] = "visit configuration page";

      dispatch(showLoading(""))

      putVisitVisitorCheckIn(navigate)(loadedFormData.visitId, selectedVisitor.visitorId, submitJson)
        .then((result) => {
        // Use the result.data as needed      

        if(result.success){

          
          // setTriggerRefresh(!triggerRefresh);
          setVisitorCheckInStatus(false);

          setTriggerRefresh(!triggerRefresh);

          enqueueSnackbar({
            message: "Visitor checked-in",
              variant: 'success',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });

        }

        dispatch(hideLoading());

      })
      .catch((error) => {
        dispatch(hideLoading());

        if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    };  

    const onCheckOutVisitor = () => {


      var submitJson = {};
          submitJson['application_location'] = "visit configuration page";

      dispatch(showLoading(""))

      putVisitVisitorCheckOut(navigate)(loadedFormData.visitId, selectedVisitor.visitorId, submitJson)
        .then((result) => {
        // Use the result.data as needed      

        if(result.success){

          // setTriggerRefresh(!triggerRefresh);
          setVisitorCheckInStatus(true);
          setTriggerRefresh(!triggerRefresh);
          enqueueSnackbar({
            message: "Visitor checked-out",
              variant: 'success',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });

        }

        dispatch(hideLoading());

      })
      .catch((error) => {
        dispatch(hideLoading());

        if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    }; 
    
    const handleOnMeetingRoomChange = (event) => {

      const newMeetingRoom = event.target.value;
      setMeetingRoom(newMeetingRoom)
    }

    const handleOnMeetingRoomBlur = (event) => {

  
      let newMeetingRoom;

      if (typeof event === "string") {
        // If event is already a string
        newMeetingRoom = event;
      } else if (event && typeof event === "object" && event.target && typeof event.target.value === "string") {
        // If event is an object and has a target.value
        newMeetingRoom = event.target.value;
      } else {
        // Fallback case
        newMeetingRoom = "";
      }

      setMeetingRoom(newMeetingRoom)

      var submitJson = {};
          submitJson['application_location'] = "visit configuration page";
          submitJson['meetingRoom'] = newMeetingRoom;

          
      if(id > 0){
        dispatch(showLoading(""))

      putVisitMeetingRoom(navigate)(loadedFormData.visitId, submitJson)
        .then((result) => {
        // Use the result.data as needed      

        if(result.success){

          setTriggerRefresh(!triggerRefresh);
          enqueueSnackbar({
            message: "Meeting Room updated",
              variant: 'success',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });

        }

        dispatch(hideLoading());

      })
      .catch((error) => {
        dispatch(hideLoading());

        if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });
    }

    }

    const handleVisitorTypeSelectChange = (event, value) => {
      // Your existing logic
    
      const selectedValue = value?.value || "Visitor";
      setSelectedVisitorType(selectedValue);

      const workflowItem = selectedFrontDeskTerminalSettings.checkInWorkflow.find(
        (item) => item.visitorType === selectedValue
      );

      if (workflowItem) {
        // Extract visitor_data workflow elements
        const visitorDataElements = workflowItem.workflowElements.filter(
          (element) => element.engineType === "visitor_data"
        );
      
        // Parse additional terminal fields from configuration
        const additionalFieldsConfig = visitorDataElements.flatMap((element) => {
          const config = JSON.parse(element.configuration);
          return config?.additionalTerminalFields || [];
        });
      
        // Transform apiKey and prepare the final additionalFields array
        const transformedFields = additionalFieldsConfig.map((field) => {
          const updatedField = {
            ...field,
            apiKey: transformApiKeyToCamelCase(field.apiKey), // Transform apiKey
            fieldName: tm.translate("dsp" + transformApiKeyToCamelCaseUpperCase(field.apiKey, true), 'Field') === 'Field'
            ? field.fieldName
            : tm.translate("dsp" + transformApiKeyToCamelCaseUpperCase(field.apiKey, true), 'Field'),        
          };
      
          // Update fieldName for specific apiKeys
          if (["c_1", "c_2", "c_3", "c_4", "c_5"].includes(updatedField.apiKey)) {
            updatedField.fieldName = tm.translate(
              `${visitorDataElements[0].hash}_visitor_${updatedField.apiKey}_title`,
              `Custom ${updatedField.apiKey.slice(-1)}` // Default to "Custom <number>"
            );
          }
      
          return updatedField;
        });

   
      
        // Update state
        setAdditionalFields(transformedFields);
      } else {
        // Fallback if no workflowItem is available
        setAdditionalFields([]);
      }
      
    };

    const onRemoveVisitorClicked = () => {

      
      if(loadedFormData.visitors.length <= 1){
        enqueueSnackbar({
          message: "A visit needs minimum one visitor",
            variant: 'error',
            anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
            },
            autoHideDuration: 3000, 
            // persist: true
          
        });
      }else{
        // Handle delete action
        setConfirmationOpen(true);
        setYesNoMessage("Do you really want to remove this entity?")
        selectedVisitor["action"] = "removevisitor";
        setYesNoDialogEntity(selectedVisitor)
      }
      
            
          
    }

    const handleExecute = async (values) => {


      setConfirmationOpen(false)


      if(selectedVisitor.accessCardId !== ""){

        enqueueSnackbar({
                      message: "You cannot remove a visitor with an access card assigned",
                        variant: 'error',
                        anchorOrigin: {
                          vertical: 'top', // 'top', 'bottom'
                          horizontal: 'center', // 'left', 'center', 'right'
                        },
                        autoHideDuration: 3000, 
                        // persist: true
                      
                    });
                  

      }else{
        handleRemoveVisitorFromVisit(null)
      }

    };

    const onSaveNewVisit = async () => {

      handleStartVisit("pre_registered")

    };

  const executeCheckDeniedPerson = (visitors) => {


    try {


      const visitorIds = visitors.map(visitor => visitor.visitorId);
      var array = {}
      array["visitors"] = visitorIds;
      array["frontDeskEntityId"] = selectedFrontDesk.entityId;


      checkDeniedPerson(navigate)(array)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {
      
          var currentVisitorData = visitors;

          var resultArray = result.data;
          for(var n = 0; n < resultArray.length; n++){
            
            var tmVisitor = resultArray[n].visitor;
            
            const foundVisitor = currentVisitorData.find(visitor => visitor.visitorId === tmVisitor.visitorId);
            const index = currentVisitorData.findIndex(visitor => visitor.visitorId === tmVisitor.visitorId);

            if(typeof foundVisitor !== 'undefined'){

              currentVisitorData[index]["deniedStatus"] = resultArray[n].potentialMatches.length
              currentVisitorData[index]["potentialMatches"] = resultArray[n].potentialMatches

            }

            //     storeRecord.set('deniedstatus', obj.data[n].potentialMatches.length ) ;
            //     storeRecord.set('potentialMatches' , obj.data[n].potentialMatches );


          }

          var result = {}
          result["data"]= currentVisitorData;
          result["total_count"]= currentVisitorData.length;

          setVisitorData(result)


        }
      })
      .catch((error) => {
        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }
      });


      

    } catch (error) {
      if (error?.action === 'relogin') {
        // navigate('/');
      }
      console.error(error);
    } finally {

    }
  };

    
  const showPotentialMatches = (visitors) => {

      setCurrentPotentialMatches(visitors.potentialMatches)
      setOpenPotentialMatches(true);

  }
  
  useEffect(() => {
    if (scanRequested && selectedVisitor) {
        setPassportScannerOpen(true);
        setScanRequested(false); // Reset scan request after opening the scanner
    }
}, [scanRequested, selectedVisitor]); // Runs only when selectedVisitor is updated

    ////////////////////////
    // HOST NOTIFICATION
    ////////////////////////

    const [anchorElHostNotification, setAnchorElHostNotification] = useState(null);
  
    const handleMenuOpen = (event) => {
      setAnchorElHostNotification(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorElHostNotification(null);
    };
  
    const handleSendNotification = (entityId) => {
      // sendVisitorNotification(value.entityId);
      console.log(entityId)
      console.log(checked)
      
      var visitorId = [];
      let i = 0;
      while (i < checked.length) {
        var visitor = checked[i];
        visitorId.push(visitor.visitorId)
        i++;
    }

      var submitJson = {}
      submitJson["visitorIds"] = visitorId;


      postHostNotification(navigate)(entityId, id, submitJson)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Notification was sent",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

            }else{
              enqueueSnackbar({
                message: "Something went wrong",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

      handleMenuClose();
    
    }

    ///////////////////////////////////////////////

  // Render loading state if loadedFormData is null
  if (!loadedFormData) {
    return <div>Loading...</div>;
  }

  if (frontDeskLoading) {
    return <div>Loading...</div>;
  }

  return (

    <Box>
        <DialogTakePicture
            open={confirmationTakePictureOpen}
            onClose={() => setConfirmationTakePictureOpen(false)}
            onSave={(entity) => {setConfirmationTakePictureOpen(false);  selectedVisitor["picturePath"]= entity.picturePath;}}
            theme={theme}
            currentEntity ={selectedVisitor}
            entityTypeParam={"visitor"}
            visitId={loadedFormData.entityId}
        />
      <DialogHostLookup
          open={openDialogHostLookup}
          onClose={() => onCloseDialogHostLookup()}
          onSave={(entity) => onSaveDialogHostLookup(entity)}
          visitId={id}
      />
      <DialogAddVisitor
          open={openDialogAddVisitor}
          onClose={() => onCloseDialogAddVisitor()}
          onSave={(entity) => onSaveDialogAddVisitor(entity)}
          visitId={id}
          onScanPassport={(visitorData) => {
            setOpenDialogAddVisitor(false);
            if(id >0){
              visitorData["visitId"] = id;
            }else{
              visitorData["visitId"] = -1;
            }
            setSelectedVisitor(visitorData);
            setScanRequested(true); // Mark that the scan should open once selectedVisitor updates
          }}

      />
      {/* <DialogBadgeHandling
          open={openDialogAccessCard}
          onClose={() => onCloseDialogAccessCard()}
          onSave={(entity) => onSaveDialogAccessCard(entity)}
          currentEntity = {selectedVisitor}
          entityTypeParam = {"visitor"}
          visitInformation = {{visitId : loadedFormData.visitId}}
      /> */}
      <DialogBadgeHandlingOld
          open={openDialogAccessCard}
          onClose={() => onCloseDialogAccessCard()}
          onSave={(entity) => onSaveDialogAccessCard(entity)}
          currentEntity = {selectedVisitor}
          entityTypeParam = {"visitor"}
          visitInformation = {loadedFormData}
      />
      <DialogYesNo
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            onConfirm={handleExecute}
            theme={theme}
            message={yesNoMessage}
            entity={yesNoDialogEntity}
        />
        <ScanPassportDialog
            open={passportScannerOpen}
            onClose={() => setPassportScannerOpen(false)}
            onSave={(entity) => onSaveDialogAddVisitor(entity)}
            theme={theme}
            entity={selectedVisitor}
        />
        <DialogShowPotentialMatches
            open={openPotentialMatch}
            onClose={() => setOpenPotentialMatches(false)}
            onSave={() => setOpenPotentialMatches(false)}
            theme={theme}
            potentialMatches={currentPotentialMatches}
        />




      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="100px"
        gap="15px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
      {/* ----------- START TOP HORIZONTAL-------- */}
      <Box
          gridColumn="span 12"
          gridRow={
            isNonMediumScreens
              ? "span 1" // Desktop
              : isNonMobile
              ? "span 2" // Tablet
              : "span 3" // Mobile
          }          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
          
        >
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="start"
            flexDirection={isNonMobile ? "row" : "column"} // Switch to column on smaller screens
            gap={isNonMobile ? "2rem" : "1rem"} // Adjust gap for responsiveness
          >         
            {/* Left Box */}
            <Box
              sx={{
                display: "flex",
                flexDirection: isNonMobile ? "row" : "column", // Row on larger screens, column on smaller
                gap: "1rem", // Space between elements inside this box
                // flex: 1, // Allow this box to take available space
              }}
            >
              <CssDateTimePicker     
                      onAccept={handleValidFromDateChange}
                      value={validFromDayJS}
                      theme={theme}
                      label={tm.translate('dateValidFrom', "Valid From")} 
                      sx={{ minWidth: "220px", mr: 2 }} 
                      slotProps={{
                        textField: {
                          readOnly: !hasPermission("system.visit.edit")
                        }
                      }}
                      disableOpenPicker={!hasPermission("system.visit.edit")}
                      />
              <CssDateTimePicker 
                      onAccept={handleValidToDateChange}
                      value={validToDayJS}
                      theme={theme}
                      minDateTime={validFromDayJS}
                      label={tm.translate('dateValidTo', "Valid To")} 
                      sx={{ minWidth: "220px", mr: 2 }} 
                      slotProps={{
                        textField: {
                          readOnly: !hasPermission("system.visit.edit")
                        }
                      }}
                      disableOpenPicker={!hasPermission("system.visit.edit")}
                      />

              
              { meetingRooms?.length > 0 ? (
                  <Autocomplete
                    freeSolo // Allow manual input
                    options={ meetingRooms.map((room) => room.roomNumber)} // Provide dropdown options
                    value={meetingRoom} // Selected value
                    onChange={(event, newValue) => {
                      setMeetingRoom(newValue || ""); // Set value from dropdown
                      handleOnMeetingRoomBlur(newValue)
                    }}
                    onInputChange={(event, newValue) => {
                      setMeetingRoom(newValue); // Allow typing manually
                    }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        sx={{ minWidth: "220px", mr: 2 }} 
                        label="Meeting Room"
                        theme={theme}   
                        onBlur={handleOnMeetingRoomBlur}
                        InputProps={{
                          ...params.InputProps,
                          readOnly: true, // Prevents typing
                          endAdornment: hasPermission("system.visit.edit") ? params.InputProps.endAdornment : null,
                        }}
                      />
                    )}
                    disableClearable={!hasPermission("system.visit.edit")} 
                    disabled={!hasPermission("system.visit.edit")} 
                  />
                ) : (
                  <CssTextField
                    label={tm.translate('cmbRoomNumber', "Meeting Room")}  
                    // onBlur={handleBlur}
                    onChange={handleOnMeetingRoomChange}
                    onBlur={handleOnMeetingRoomBlur}
                    value={meetingRoom}
                    // value={loadedFormData?.name || values.name}
                    // value={values.firstname} // Use values from Formik
                    name="meetingRoom"
                    // error={ Boolean(touched.firstname) && Boolean(errors.firstname)}
                    // helperText={touched.firstname && errors.firstname}
                    // sx={{ mb: 2 , mr:1 , gridColumn: "span 4" }}
                    theme={theme}   
                    InputProps={{
                      readOnly: true, // Prevents manual typing
                      endAdornment: hasPermission("system.visit.edit") ? null : null, // Removes calendar icon if no permission
                    }}
                />
                )}
    
          </Box>

          <Box
              sx={{
                display: "flex", // Align buttons in a row
                flexDirection: isNonMobile ? "row" : "column", // Row on larger screens, column on smaller
                gap: "1rem", // Space between buttons
                flexShrink: 0, // Prevent shrinking for this box
                alignItems: "center", // Center buttons vertically
                // minWidth: "620px", // Standard width
                
              }}
            >
                    {
                        id == -1 && (
                          <Button
                              startIcon={<Save />}
                    
                              onClick={onSaveNewVisit}
                              type="submit"
                              sx={{
                                  p: "1rem",
                                  backgroundColor: palette.secondary.light,
                                  color: "white",
                                  fontWeight: "bold",
                                  backgroundColor: "red", // Set initial background color
                                  // mb: 1,
                                  ".MuiButton-startIcon": {
                                    color: "white",
                                  },
                                  "&:hover": {
                                  color: palette.primary.main,
                                  backgroundColor: "red", // Set initial background color
                                  fontWeight: "bold",
                                  },
                              }}
                              >
                              {tm.translate('btnSaveVisit', "Save")}
                          </Button>
                        )
                    }
                    
                    { hasPermission("system.visit.admin") && (
                      <>
                    <Button
                      id="demo-customized-button"
                      aria-controls={openMenuVisitStatus ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenuVisitStatus ? 'true' : undefined}
                      disableElevation
                      disabled = {(id == -1)}
                      onClick={handleOpenVisitStatusClick}
                      endIcon={<KeyboardArrowDown />}
                      sx={{
                        p: "1rem",
                        backgroundColor: palette.secondary.light,
                        color: palette.background.alt,
                        fontWeight: "bold",
                        "&:hover": {
                        color: palette.primary.main,
                        backgroundColor: palette.secondary.dark,
                        fontWeight: "bold",
                        },
                    }}
                    >
                      {tm.translate('btnVisitStatus', 'Visit Status')}  (<Typography variant="h6" fontWeight="bold" sx={{color: getVisitStatusColor(visitStatus)}}>{visitStatusLabel} </Typography>)
                    </Button>
                    <Menu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorElVisitStatus}
                      open={openMenuVisitStatus}
                      onClose={handleClose}
                      sx={
                        {
                          '& .MuiMenu-list': {
                            padding: '4px 0',
                            backgroundColor: palette.secondary.light,
                          },
                          '& .MuiDivider-root': {
                            backgroundColor:palette.primary.main,
                            color: palette.primary.main,
                          },
                          '& .MuiMenuItem-root': {
                            backgroundColor: palette.secondary.light,
                            color: palette.primary.main,
                            '& .MuiSvgIcon-root': {
                              fontSize: 18,
                              color: palette.primary.main,
                              backgroundColor: palette.secondary.light,
                              marginRight: 2,
                            },
                            '&:active': {
                              // backgroundColor: alpha(
                              //   theme.palette.primary.main,
                              //   theme.palette.action.selectedOpacity,
                              // ),
                            },
                          },
                        }
                      }
                    >
                        <MenuItemsVisitStatus
                            visitStatus={visitStatus}
                            handleStartVisit={handleStartVisit}
                            handleFinishVisit={handleFinishVisit}
                            handleNoShowVisit={handleNowShowVisit}
                            handleCancelVisit={handleCancelVisit}
                            handleDeleteVisit={handleDeleteVisit}
                            handleReOpenVisit={handleReOpenVisit}
                          />
                    </Menu>
                    </>
                    )}
                </Box>   
                </Box>                
            </Box>
            {/* ----------- END TOP HORIZONTAL-------- */}

            {/* ----------------------------------------------- */}
            {/* ----------- START VISITOR SECTION PART -------- */}
            {/* ----------------------------------------------- */}

            {/* ----------- VISITOR LIST START -------- */}
            <Box
                gridColumn="span 3"
                gridRow="span 5"
                backgroundColor={theme.palette.background.alt}
                p="1rem"
                borderRadius="0.55rem"
                display="flex"
                flexDirection="column"
              >
               
               <FlexBetween>
               
               { hasPermission("system.visit.edit") && (
                <Button
                    variant="contained"
                    startIcon={<PersonAdd />}
                    onClick={() => setOpenDialogAddVisitor(true)}
                    sx={{
                    backgroundColor: theme.palette.secondary.ligh,
                    color: theme.palette.secondary.ligh,
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    "&:hover": { color: theme.palette.secondary.light },
                    }}
                >
                    {tm.translate('btnAddVisitor', "Add Visitor")}
                </Button>
               )}
              
              { hasPermission("system.visit.edit") && (

                <MenuItemsVisitorBulkActions
                    visitStatus={visitStatus}
                    handleBulkCheckIn={handleBulkCheckIn}
                    handleBulkCheckOut={handleBulkCheckOut}
                    handleBulkPrintBadge={handleBulkPrintBadge}
                    showBuldActionVisitorButton={showBulkActionsButton}
                  />
              )}
                <Box>
                
              </Box>
            </FlexBetween>
      
            {
              gridLoadingVisitor ? (
                  "Loading..."
              ) : (
                visitorData.data && visitorData.data.length > 0 ? (
                    <List dense sx={{ 
                        gridColumn: "span 4" , 
                        // bgcolor: 'background.paper',
                        margin: '15px 0px 0px 0px',
                        overflowY: 'auto', // Add this to enable vertical scrolling
                        // maxHeight: '320px', // Set a maximum height for the container
                        flex:1
                        
                    }}>
                        {visitorData.data.map((value, index) => {

                            const labelId = `checkbox-list-visitor-label-${index}`;                            

                            return (
                              <ListItem
                                  key={labelId}
                                  secondaryAction={
                                    <Checkbox
                                      edge="start"
                                      onChange={handleToggle(value)}
                                      checked={checked.indexOf(value) !== -1}
                                      inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                  }
                                  disablePadding
                                  disableGutters
                                >
                                  <ListItemButton
                                    selected={index === selectedVisitorIndex}
                                    role={undefined}
                                    onClick={handleVisitorRowClick(value)}
                                    dense
                                  >
                                    <Avatar
                                      {...stringAvatar(value.fullName)}
                                      alt={value.fullName}
                                      src={`${getUri(getSessionToken())}/visitor/${value.visitorId}/picture/${value.picturePath}`}
                                    />
                                    <ListItemText
                                      secondary={
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: 1 }}>
                                          <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'primary' }}>
                                            {tm.translate('lblSignature', "Signature")}
                                          </Typography>
                                          <Typography variant="body1">
                                            {value.signature ? (

                                              <Tooltip
                                                title="Signature"
                                                arrow
                                                sx={{
                                                  '& .MuiTooltip-tooltip': {
                                                    fontSize: '1rem', // Bigger font size
                                                    padding: '10px',  // More padding
                                                  },
                                                }}
                                              > 
                                                  <Brush sx={{ color: 'green', ml: 0.5 }} fontSize="small" />
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                title="No Signature"
                                                arrow
                                                sx={{
                                                  '& .MuiTooltip-tooltip': {
                                                    fontSize: '1rem', // Bigger font size
                                                    padding: '10px',  // More padding
                                                  },
                                                }}
                                              > 
                                                <Brush sx={{ color: 'red', ml: 0.5 }} fontSize="small" />
                                              </Tooltip>
                                            )}
                                          </Typography>
                                          <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'secondary', ml: 1 }}>
                                            {' Status: '}
                                          </Typography>
                                          <Typography variant="body1" sx={{ ml: 0.5, color: getVisitorStatusColor(value.visitorStatus) }}>
                                            {getVisitorStatusLabel(value.visitorStatus)}
                                          </Typography>
                                        </Box>
                                      }
                                    >
                                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        <Typography variant="h5" fontWeight="bold" sx={{ ml: 1 }}>
                                          {value.fullName}
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {value.deniedStatus == -1 ? (
                                          <CircularProgress size={20} sx={{ color: 'white', ml: 1 }} />
                                        ) : value.deniedStatus == 0 ? (
                                          <Tooltip
                                            title="Verified"
                                            arrow
                                            sx={{
                                              '& .MuiTooltip-tooltip': {
                                                fontSize: '1rem', // Bigger font size
                                                padding: '10px',  // More padding
                                              },
                                            }}
                                          >
                                            <Verified sx={{ color: 'green', ml: 1 }} />
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            title="Not Verified"
                                            arrow
                                            sx={{
                                              '& .MuiTooltip-tooltip': {
                                                fontSize: '1rem', // Bigger font size
                                                padding: '10px',  // More padding
                                              },
                                            }}
                                            onClick={(e) => {
                                              // e.stopPropagation(); // Prevent triggering ListItemButton's onClick
                                              showPotentialMatches(value); // Your custom function
                                            }}
                                          >
                                            <Verified sx={{ color: 'red', ml: 1 }} />
                                          </Tooltip>
                                        )}
                                        </Box>
                                      </Box>
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>

                            );
                        })}
                    </List>
                ) : (
                    
                  <Typography sx={{ mt: 3 }}>No visitors assigned</Typography>

                )
            )
        }

            </Box>
            {/* ----------- VISITOR LIST END -------- */}

            {/* ----------- VISITOR IMAGE START -------- */}
            <Box
                gridColumn="span 2"
                gridRow="span 5"
                backgroundColor={theme.palette.background.alt}
                p="1rem"
                borderRadius="0.55rem"
                display="flex"
                flexDirection="column" // Stack children vertically
                alignItems="center" // Center children horizontally
                //justifyContent="center" // Center children vertically
              >
                <Box>
                  <Button
                      variant="outlined"
                      disableTouchRipple
                      disabled = {(!selectedVisitor)}
                      // onClick={handleAvatarClick}
                      onClick={() => setConfirmationTakePictureOpen(true)}

                      sx={{
                          position: 'relative',
                          border: 0,
                          "&:hover": { 
                              border: 0, 
                              color: theme.palette.secondary.light, 
                              backgroundImage: 'none',  
                              boxShadow: 'none', 
                              cursor: 'pointer',
                              backgroundColor: 'transparent', // Change background color to transparent
                          }
                      }}
                  >
                      <Avatar
                          {...stringAvatar(selectedVisitor && selectedVisitor.fullName)} 
                          sx={{ width: 150, height: 150, position: 'relative', zIndex: 0 }} // Ensure Avatar is positioned relatively
                          src={selectedVisitor && selectedVisitor.fullName && selectedVisitor.visitorId && selectedVisitor.picturePath ? `${getUri(getSessionToken())}visitor/${selectedVisitor.visitorId}/picture/${selectedVisitor.picturePath}` : undefined}
                          />
                      <Box
                          sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background: 'rgba(0, 0, 0, 0)',
                              zIndex: 1,
                              clipPath: 'ellipse(47% 50% at 50% 50%)',
                              WebkitClipPath: 'ellipse(45% 48% at 50% 50%)',
                              backgroundImage: 'linear-gradient(to bottom, transparent 80%, rgba(0,0,0,0.5) 60%)',
                              // backgroundClip: 'padding-box', // This property ensures that the background is clipped to the padding box
                          }}
                      />
                      
                      <Box sx={{ position: 'absolute', bottom: 3, left: '50%', transform: 'translateX(-50%)', zIndex: 2 }}>
                          <CameraAlt sx={{ fontSize: '30px', color: theme.palette.secondary.light }} />
                      </Box>
                  </Button>
                </Box>
                {/* <Divider sx={{ my: 2, width: "100%" }} /> */}
                

            </Box>
            {/* ----------- VISITOR IMAGE END -------- */}
            {/* ----------- VISITOR INFORMATION START -------- */}

            <Box
                gridColumn= {
                  hasPermission("system.visit.admin")
                    ? "span 5" 
                    : "span 7"
                }  
                gridRow="span 5"
                backgroundColor={theme.palette.background.alt}
                p="1rem"
                borderRadius="0.55rem"
                sx={{
                  overflowY: "auto",  // Enable horizontal scrolling
                }}
              >
                <Formik
                      onSubmit={handleSubmit}
                      initialValues={selectedVisitor || initialValuesRegister}
                      validationSchema={validationSchema}
                      enableReinitialize={true}
                      validateOnChange={false} // Disable validation on change
                      validateOnBlur={false}   // Disable validation on blur
                      innerRef={formikRef}
                  >
                  {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      resetForm,
                      }) => (
                      <form onSubmit={handleSubmit}>
                  <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', overflowX: "auto", }}
                      // sx={{
                      //     "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                      //   }}
                        sx={{
                          border: values?.deniedPerson == true
                            ? "2px solid red" // Green border for assigned card
                            : "0px dashed grey", // Grey dashed border for unassigned card
                          borderRadius: "8px", // Rounded corners for a button-like look
                          padding: "0.5rem", // Padding for better spacing
                          display: "inline-block", // Ensures it wraps the content snugly
                          textAlign: "center", // Centers the content
                        }}
                      >
                        {
                            hasPermission("system.visit.admin") &&(
                                <FormControlLabel
                                  sx={{ mb: 2, gridColumn: "span 4", backgroundColor: 'transparent', width: "100%"  }}
                                  control={
                                    <IOSSwitch
                                      sx={{ m: 1 }}
                                      checked={values.deniedPerson}
                                      onChange={(e) => setFieldValue("deniedPerson", e.target.checked)}
                                    />
                                  }
                                  label={<span style={{ color: theme.palette.secondary.light }}>{tm.translate('btnDeniedPerson', "Denied Person")} </span>}
                                />
                            )

                        }
                        
                        <Autocomplete
                          fullWidth
                          disablePortal
                          disableClearable
                          disableInput
                          autoSelect
                          // onChange={(event, value) => setFieldValue('visitorType', value ? value.value : '')}
                          onChange={(event, value) => {
                            // Update Formik field value
                            setFieldValue('visitorType', value ? value.value : '');
                        
                            // Call another function after setting the value
                            handleVisitorTypeSelectChange(event, value);
                          }}
                          value={allVisitorTypes.find(type => type.value === values.visitorType) || null}
                          options={allVisitorTypes}
                          getOptionLabel={(option) => option.label || ''}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          renderInput={(params) => (
                            <CssTextField
                              {...params}
                              required
                              name="visitorType"
                              label={tm.translate('dspVisitorType', 'Visitor Type')}
                              theme={theme}
                              sx={{
                                mb: 2, mr: 1, gridColumn: "span 4",
                                "& label.Mui-focused": {
                                  color: theme.palette.secondary[200],
                                },
                                "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
                                  color: theme.palette.secondary[300]
                                },
                              }}
                            />
                          )}
                        />
                        <Box
                            display="flex"
                            flexDirection="row"
                            width="100%"
                      
                            >
                        <CssTextField
                          label={tm.translate('dspFirstName', 'First Name')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          // value={loadedFormData?.name || values.name}
                          value={values.firstname} // Use values from Formik
                          name="firstname"
                          error={ Boolean(touched.firstname) && Boolean(errors.firstname)}
                          helperText={touched.firstname && errors.firstname}
                          sx={{ mb: 2 , mr:1 , gridColumn: "span 4" }}
                          theme={theme} 
                          fullWidth
                      />
                      <CssTextField
                          label={tm.translate('dspLastName', 'Last Name')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          // value={loadedFormData?.name || values.name}
                          value={values.lastname} // Use values from Formik
                          name="lastname"
                          error={ Boolean(touched.lastname) && Boolean(errors.lastname)}
                          helperText={touched.lastname && errors.lastname}
                          sx={{ mb: 2, ml:1 , gridColumn: "span 4" }}
                          theme={theme} 
                          fullWidth
                      />
                      </Box>

                      {additionalFields.map((item) => (
                        <CssTextField
                          key={item.apiKey}
                          label={item.fieldName}
                          name={`${item.apiKey}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          required={item.required}
                          sx={{ mb: 2, gridColumn: "span 4" }}
                          theme={theme}
                          value={values[item.apiKey] || ""}
                          error={Boolean(touched[item.apiKey] && errors[item.apiKey])}
                          helperText={touched[item.apiKey] && errors[item.apiKey]}
                        />
                      ))}
                      <Grid container spacing={1}>
                      <Grid item xs={9}>
                      { hasPermission("system.visitor.edit") && (
                          <Button
                          fullWidth
                          startIcon={<Save />}
                          disabled = {(!selectedVisitor)}
                          type="submit"
                          sx={{
                              p: "1rem",
                              backgroundColor: palette.secondary.light,
                              color: palette.background.alt,
                              fontWeight: "bold",
                              "&:hover": {
                              color: palette.primary.main,
                              backgroundColor: palette.secondary.light,
                              fontWeight: "bold",
                              },
                          }}
                          >
                          {tm.translate('btnSaveChanges', "Save")}  
                          </Button>
                      )}
                      </Grid>
                      {/* {!visitStarted && ( */}
                      <Grid item xs={3}>
                      { hasPermission("system.visit.edit") && (

                          <Button
                          fullWidth
                          startIcon={<DeleteOutline />}
                          onClick={onRemoveVisitorClicked}
                          disabled = {(!selectedVisitor)}
                          sx={{
                              p: "1rem",
                              backgroundColor: palette.secondary.light,
                              color: palette.background.alt,
                              fontWeight: "bold",
                              "&:hover": {
                              color: palette.primary.main,
                              backgroundColor: palette.secondary.light,
                              fontWeight: "bold",
                              },
                          }}
                          >
                          {tm.translate('btnRemove', "Remove")}  
                          </Button>
                      )}
                           
                          <button
                            type="button"
                            ref={resetButtonRef}
                            style={{ display: 'none' }}
                            onClick={() => resetForm()}
                          />
                           
                      </Grid>
                    {/* )} */}
                      </Grid>
                      {/* Add more text fields as needed */}
                  </Paper>
                      </form>
                  )}
              </Formik>

            </Box>
            {/* ----------- VISITOR INFORMATION END -------- */}
            {/* ----------- VISITOR ACTIONS START -------- */}
            { hasPermission("system.visit.admin") && (
            <Box
                gridColumn="span 2"
                gridRow="span 5"
                backgroundColor={theme.palette.background.alt}
                p="1rem"
                borderRadius="0.55rem"
              >
                <Button
                    fullWidth
                    startIcon={<FlightLand />}
                    disabled = {(!visitorCheckInStatus || !visitStarted || selectedVisitor?.visitorStatus == "under_review")}
                    onClick={onCheckInVisitor}
                    type="submit"
                    sx={{
                        p: "1rem",
                        backgroundColor: palette.secondary.light,
                        color: palette.background.alt,
                        fontWeight: "bold",
                        mb: 1,
                        ".MuiButton-startIcon": {
                          color: sadevioColors.hexColorCheckedIn,
                        },
                        "&:hover": {
                        color: palette.primary.main,
                        backgroundColor: palette.secondary.light,
                        fontWeight: "bold",
                        },
                    }}
                    >
                    {tm.translate('btnCheckIn', "Check-In")}
                    </Button>
                    <Button
                    fullWidth
                    startIcon={<FlightTakeoff />}
                    disabled = {(visitorCheckInStatus || !visitStarted || selectedVisitor?.visitorStatus == "under_review")}
                    onClick={onCheckOutVisitor}
                    type="submit"
                    sx={{
                        p: "1rem",
                        backgroundColor: palette.secondary.light,
                        color: palette.background.alt,
                        fontWeight: "bold",
                        ".MuiButton-startIcon": {
                          color: sadevioColors.hexColorCheckedOut,
                        },
                        mb: 1,
                        "&:hover": {
                        color: palette.primary.main,
                        backgroundColor: palette.secondary.light,
                        fontWeight: "bold",
                        },
                    }}
                    >
                    {tm.translate('btnCheckOut', "Check-Out")}
                    </Button>
                    

                    <Button
                    fullWidth
                    startIcon={<Print />}
                    type="submit"
                    disabled = {(!selectedVisitor || id == -1)}
                    onClick={ printVisitorLabel}
                    sx={{
                        p: "1rem",
                        backgroundColor: palette.secondary.light,
                        color: palette.background.alt,
                        fontWeight: "bold",
                        mb: 1,
                        "&:hover": {
                        color: palette.primary.main,
                        backgroundColor: palette.secondary.light,
                        fontWeight: "bold",
                        },
                    }}
                    >
                    {tm.translate('btnPrintBadges', "Print Badge")}
                </Button>
                <Button
                    id="button-visitor-notification"
                    aria-controls={openMenuVisitorNotification ? 'button-visitor-notification-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenuVisitorNotification ? 'true' : undefined}
                    disabled = {(!selectedVisitor || id == -1)}
                    fullWidth
                    startIcon={<Send />}
                    disableElevation
                    onClick={handleVisitorNoticiationClick}
                    endIcon={<KeyboardArrowDown />}
                    sx={{
                      p: "1rem",
                      backgroundColor: palette.secondary.light,
                      color: palette.background.alt,
                      fontWeight: "bold",
                      mb: 1,
                      "&:hover": {
                      color: palette.primary.main,
                      backgroundColor: palette.secondary.light,
                      fontWeight: "bold",
                      },
                  }}
                  >
                    {tm.translate('btnVisitorNotifications', 'Visitor Notification')}
                  </Button>
                  <Menu
                    id="button-visitor-notification-menu"
                    MenuListProps={{
                      'aria-labelledby': 'button-visitor-notification',
                    }}
                    anchorEl={anchorElVisitorNotification}
                    open={openMenuVisitorNotification}
                    onClose={handleVisitorNoticiationClose}
                    sx={
                      {
                        '& .MuiMenu-list': {
                          padding: '4px 0',
                          backgroundColor: palette.secondary.light,
                        },
                        '& .MuiDivider-root': {
                          backgroundColor:palette.primary.main,
                          color: palette.primary.main,
                        },
                        '& .MuiMenuItem-root': {
                            backgroundColor: palette.secondary.light,
                            color: palette.primary.main,
                          '& .MuiSvgIcon-root': {
                            fontSize: 18,
                            color: palette.primary.main,
                            backgroundColor: palette.secondary.light,
                            marginRight: 2,
                          },
                          '&:active': {
                            // backgroundColor: alpha(
                            //   theme.palette.primary.main,
                            //   theme.palette.action.selectedOpacity,
                            // ),
                          },
                        },
                      }
                    }
                  >
                      <MenuItemsVisitorNotification
                          handleSendInvitation={handleSendInvitation}
                        />
                  </Menu>
                <Button
                    fullWidth
                    startIcon={<Badge />}
                    type="submit"
                    disabled = {(!selectedVisitor || id == -1)}
                    onClick={() => setOpenDialogAccessCard(true)}
                    sx={{
                        p: "1rem",
                        backgroundColor: palette.secondary.light,
                        color: palette.background.alt,
                        fontWeight: "bold",
                        mb: 1,
                        "&:hover": {
                        color: palette.primary.main,
                        backgroundColor: palette.secondary.light,
                        fontWeight: "bold",
                        },
                    }}
                    >
                    {tm.translate('btnAccessCardtVisitor', "Access Card ")} 
                    </Button>
                    <Box
                      sx={{
                        border: selectedVisitor?.accessCardId?.trim() !== ""
                          ? "2px solid green" // Green border for assigned card
                          : "2px dashed grey", // Grey dashed border for unassigned card
                        borderRadius: "8px", // Rounded corners for a button-like look
                        padding: "0.5rem", // Padding for better spacing
                        display: "inline-block", // Ensures it wraps the content snugly
                        textAlign: "center", // Centers the content
                        width: "100%",
                        mb: 1,
                        backgroundColor: selectedVisitor?.accessCardId?.trim() !== ""
                          ? "rgba(0, 255, 0, 0.1)" // Light green background for assigned card
                          : "transparent", // No background for unassigned card
                      }}
                    >
                      {selectedVisitor && selectedVisitor.accessCardId?.trim() !== "" ? (
                        <Typography variant="body1">ID: {selectedVisitor.accessCardId}</Typography>
                      ) : (
                        <Typography variant="body1" color="text.secondary">
                          {selectedVisitor ? (tm.translate("lblNoBadge", "No Card")) : "Visitor not selected"}
                        </Typography>
                      )}
                    </Box>
                {/* <Button
                    fullWidth
                    startIcon={<LocalPolice />}
                    type="submit"
                    sx={{
                        p: "1rem",
                        backgroundColor: palette.secondary.light,
                        color: palette.background.alt,
                        fontWeight: "bold",
                        mb: 1,
                        ".MuiButton-startIcon": {
                          color: sadevioColors.hexColorCheckedIn,
                        },
                        "&:hover": {
                        color: palette.primary.main,
                        backgroundColor: palette.secondary.light,
                        fontWeight: "bold",
                        },
                    }}
                    >
                    Denied Check
                </Button> */}

                <Button
                    fullWidth
                    startIcon={<FaPassport />}
                    disabled = {(!selectedVisitor || id == -1)}
                    onClick={() => setPassportScannerOpen(true)}
                    type="submit"
                    sx={{
                        p: "1rem",
                        backgroundColor: palette.secondary.light,
                        color: palette.background.alt,
                        fontWeight: "bold",
                        mb: 1,
                        ".MuiButton-startIcon": {
                          // color: sadevioColors.hexColorCheckedIn,
                        },
                        "&:hover": {
                        color: palette.primary.main,
                        backgroundColor: palette.secondary.light,
                        fontWeight: "bold",
                        },
                    }}
                    >
                    ID Scan
                </Button>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="h6" fontWeight="bold" sx={{ color: theme.palette.secondary[100] }}>
                    Past Visit Count
                  </Typography>
                  {isVisitCountLoading && selectedVisitor ? (
                    <Oval
                      visible={true}
                      height={30}
                      width={30}
                      color="#ffedc2"
                      secondaryColor="#ffedc2"
                      ariaLabel="oval-loading"
                    />
                  ) : (
                    <Typography variant="h6" fontWeight="bold" sx={{ color: theme.palette.secondary[100] }}>
                      {visitorVisitCount}
                    </Typography>
                  )}
                </Box>
            </Box>
            )}
            {/* ----------- VISITOR ACTIONS END -------- */}


            {/* ----------------------------------------------- */}
            {/* ----------- END VISITOR SECTION PART ---------- */}
            {/* ----------------------------------------------- */}


            {/* ----------------------------------------------- */}
            {/* ----------- START HOST SECTION PART ----------- */}
            {/* ----------------------------------------------- */}
            <Box
              gridColumn="span 12"
              gridRow="span 3"
              backgroundColor={theme.palette.background.alt}
              p="1rem"
              borderRadius="0.55rem"
              display="flex"
              flexDirection="column"
            >

            <FlexBetween>
            { hasPermission("system.visit.edit") && (

                <Button
                    variant="contained"
                    onClick={() => setOpenDialogHostLookup(true)}
                    startIcon={<PersonAdd />}
                    sx={{
                      backgroundColor: theme.palette.secondary.ligh,
                      color: theme.palette.secondary.ligh,
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    "&:hover": { color: theme.palette.secondary.light },
                    }}
                >
                   {tm.translate('btnNewHost', "Add Host")} 
                </Button>
            )}
                <Box>
                    {/* <CssTextField
                        label={"Serach Badge"}
                    //   onChange={elemet.onChange}
                        sx={{ mr: 2 }}
                        InputProps={{
                        endAdornment: (
                            <Search />
                        ),
                        }}
                        theme={theme}
                    /> */}
                </Box>
            </FlexBetween>
      
            {
              gridLoadingHost ? (
                  "Loading..."
              ) : (
                hostData.data && hostData.data.length > 0 ? (
                    <List dense sx={{ 
                        gridColumn: "span 4" , 
                        // bgcolor: 'background.paper',
                        margin: '15px 0px 0px 0px',
                        overflowY: 'auto', // Add this to enable vertical scrolling
                        // maxHeight: '320px', // Set a maximum height for the container
                        flex:1
                        
                    }}>
                        {hostData.data.map((value, index) => {
                            const labelId = `checkbox-list-secondary-label-${index}`;
                            

                            return (
                                <ListItem key={labelId} 
                                        disablePadding
                                        disableGutters
                                        // selected={selectedBadgeItemIndex === index}
                                        // onClick={() => handleListItemClick(index)}
                                        >
                            
                                    {/* <ListItemButton  > */}
                                        <ListItemAvatar>
                                        <Avatar {...stringAvatar(value.fullName)} alt={value.fullName} 
                                                src={value && value.entityId && value.picturePath ? `${getUri(getSessionToken())}/host/${value.entityId}/picture/${value.picturePath}` : undefined}
                                                />
                                        </ListItemAvatar>
                                        <ListItemText  secondary={'email : '+value.email}>
                                              <Typography variant="h5" fontWeight="bold">
                                                  {value.fullName}
                                              </Typography>
                                          </ListItemText>
                                      
                                        <IconButton>
                                        {value.signature ? (
                                          <CheckCircle style={{ color: 'green' }} />
                                        ) : (
                                          <>
                                        <Typography variant="body1" style={{ fontSize: '16px'}} sx={{ mr: 2 }}>
                                            {tm.translate('lblNoSignature', "No signature")} 
                                          </Typography>
                                          <Brush style={{ color: 'red' }} />
                                          </>
                                        )}
                                      </IconButton>
                                      {/* Notification Dropdown */}
                                      <Button
                                          variant="contained"
                                          color="primary"
                                          startIcon={<Send />}
                                          endIcon={<ArrowDropDown />}
                                          onClick={handleMenuOpen}
                                        >
                                          Notification
                                        </Button>
                                        <Menu
                                          anchorEl={anchorElHostNotification}
                                          open={Boolean(anchorElHostNotification)}
                                          onClose={handleMenuClose}
                                        >
                                          <MenuItem onClick={() => handleSendNotification(value.entityId)}>
                                            Send Visitor Arrived Notification
                                          </MenuItem>
                                        </Menu>
                                        { hasPermission("system.visit.edit") && (

                                          <Tooltip title={
                                              <Typography variant="body1" style={{ fontSize: '16px' }}>
                                              {tm.translate('lblRemoveHost', "Remove from visit")}
                                              </Typography>
                                          }>

                                          <IconButton onClick={() => handleRemoveHostFromVisit(value.entityId)}>
                                              <Close style={{ color: theme.palette.secondary[100] }}/>
                                          </IconButton>
                                          </Tooltip>
                                        )}

                                    {/* <IconButton onClick={() => setOnConfirmDeleteBadgeOpen(true)}>
                                            <Clear style={{ color: theme.palette.secondary[100]}}/>
                                        </IconButton> */}
                                </ListItem>
                            );
                        })}
                    </List>
                ) : (
                    
                  <Typography sx={{ mt: 3 }}>No hosts assigned</Typography>

                )
            )
        }
          
        </Box>

            {/* ----------------------------------------------- */}
            {/* ----------- END HOST SECTION PART ------------- */}
            {/* ----------------------------------------------- */}

      </Box>


    </Box>
  );
};

export default VisitDetailGeneral;
