import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, useTheme } from "@mui/material";
import React, { useEffect } from 'react'
import { useState } from "react";
import { useFrontDeskManager } from "components/FrontDeskManager";
import BadgeHandlingOld from "components/badge/BadgeHandlingOld";
import tm from 'components/TranslationManager';


const DialogBadgeHandlingOld = ({ open, onClose, onSave, currentEntity, entityTypeParam, visitInformation = null  }) => {

  const theme = useTheme();

  const [entityType, setEntityType] = useState(entityTypeParam);
  const [visitInformationParam, setVisitInformationParam] = useState(visitInformation);

  const {  selectedFrontDeskTerminalSettings} = useFrontDeskManager();


  useEffect(() => {


    if(currentEntity != null){
      if (currentEntity.hasOwnProperty('visitorId')) {
        currentEntity["entityId"] = currentEntity.visitorId;
        setEntityType("visitor")
        setVisitInformationParam(visitInformation)
      }

      var accessProfiles = selectedFrontDeskTerminalSettings.accessProfiles;
      if(typeof accessProfiles !== 'undefined' && accessProfiles != null && accessProfiles.length > 0){


      }

    }
    

  }, [open]);


  const handleSave = () => {

    var values = {}

    onSave();


  }


  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>{tm.translate('lblBadgeHandling', "Access Card Handling")}</DialogTitle>
        <DialogContent>

        <BadgeHandlingOld isDialog={true} currentEntity={currentEntity} entityTypeParam={entityType} visitInformation={visitInformationParam}  onSave={onSave}   />
        </DialogContent>
        <DialogActions>  

              
                <Button onClick={handleSave} color="primary" autoFocus sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
                  OK
                </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogBadgeHandlingOld