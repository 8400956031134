import React, { useEffect, useState } from "react";
import Header from "components/Header";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { deleteApplicationLocalUninstall, getApplicationLocal,  postApplicationLocalInstall } from "hooks/HttpRequests";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { enqueueSnackbar } from "notistack";

const AppDetail = ({globalType }) => {
  
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedFrontDesk, selectedFrontDeskTerminalSettings, frontDeskLoading, convertToFrontDeskTimeZone } = useFrontDeskManager();
  const [loading, setLoading] = useState(true);
  const [loadedFormData, setLoadedFormData] = useState(null);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const [parsedBenefits, setParsedBenefits] = useState([]);

  const { id } = useParams();

  useEffect(() => {

    if (selectedFrontDesk) {
      loadData(id);
    }
  }, [selectedFrontDesk, triggerRefresh]);

  useEffect(() => {
    // Parse the JSON string from `benefits` field
    if (loadedFormData?.benefits) {
      try {
        const benefitsArray = JSON.parse(loadedFormData.benefits);
        setParsedBenefits(benefitsArray);
      } catch (error) {
        console.error('Failed to parse benefits JSON string:', error);
        setParsedBenefits([]); // Fallback to an empty array if parsing fails
      }
    }
  }, [loadedFormData]);

  const loadData = async (appId) => {

    setLoading(true)

    //do an autocomplete function

    getApplicationLocal(navigate)(selectedFrontDesk.entityId, id)
      .then((result) => {

        // Use the result.data as needed
        if(result.success){

          setLoadedFormData(result.data)

        }
        setLoading(false)

      })
      .catch((error) => {

        console.log(error);

        setLoading(false)
      });

  }

  const installApplication = async () => {

    // setGridLoading(true)
    dispatch(showLoading());

    postApplicationLocalInstall(navigate)(selectedFrontDesk.entityId, id)
    .then((result) => {

        // Use the result.data as needed
        if(result.success){
            // console.log(loadedFormData)
            // setLoadedFormData(result.data)
            enqueueSnackbar({
              message: "Application was installed",
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
            setTriggerRefresh(!triggerRefresh);
            routeConfigPage()

        }
        // setGridLoading(false)
        dispatch(hideLoading());


    })
    .catch((error) => {

        // setGridLoading(false)
        dispatch(hideLoading());

    });

  }

  const uninstallApplication = async () => {

    // setGridLoading(true)
    dispatch(showLoading());

    deleteApplicationLocalUninstall(navigate)(selectedFrontDesk.entityId, id)
    .then((result) => {

        // Use the result.data as needed
        if(result.success){
      
          
            enqueueSnackbar({
              message: "Application was uninstalled",
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
              
            });
            setTriggerRefresh(!triggerRefresh);

        }
        // setGridLoading(false)
        dispatch(hideLoading());


    })
    .catch((error) => {

        // setGridLoading(false)
        dispatch(hideLoading());

    });

  }

  const routeConfigPage = async () => {

    
      switch(loadedFormData.applicationType) {
        case "text_field_rules":
          navigate(`/apps/${id}/textfieldrulesconf`)
          break;
        case 'emergency_list':
          navigate(`/apps/${id}/emergencylistconf`)
          break;
        case 'hid_atom_reader':
          navigate(`/apps/${id}/hidatomconf`)
          break;
        case 'silent_alarm':
          navigate(`/apps/${id}/silentalarmconf`)
          break;
        case 'visual_compliance':
          navigate(`/apps/${id}/visualcomplianceconf`)
          break;
        default:
          // code block
      }

  }

  if ( loading || !loadedFormData || !selectedFrontDesk ) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="1rem 2rem">

      <Header title={loadedFormData.applicationName} subtitle={loadedFormData.shortDescription} backbutton={"/apps"} />

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        

        {/* --- LEFT START ---*/}
        <Box
          gridColumn="span 9"
          gridRow="span 5"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
          display="flex"
          flexDirection="column"
        >

            <Typography variant="h3" component="div"  color={theme.palette.secondary[100]} fontWeight="bold" sx={{ mt:3}}>
              {"About"}
            </Typography>

            <Typography variant="h5" component="div" sx={{
                }}><br />
              {loadedFormData.about}
              <br />
            </Typography>

            <Typography variant="h3" component="div"  color={theme.palette.secondary[100]} fontWeight="bold" sx={{ mt:3}}>
              {"Benefits"}
            </Typography>
            <Typography variant="h5" component="div" sx={{ mt: 1 }}>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                {parsedBenefits.map((benefit, index) => (
                  <li key={index} style={{ paddingBottom: '8px' }}>
                    {benefit}
                  </li>
                ))}
              </ul>
            </Typography>
          
        </Box>
        {/* --- LEFT END ---*/}

        {/* --- RIGHT START ---*/}

        <Box
            gridColumn="span 3"
            gridRow="span 5siemens_logo"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
            display="flex"
            flexDirection="column"
            >

                { !loadedFormData.installed ?(
                    <Button
                        variant="contained"
                        onClick={installApplication}
                        sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        
                        "&:hover": { color: theme.palette.secondary.dark },
                        }}
                    >
                        {"Install"}
                    </Button>
                  ):(
                    <>
                    <Button
                          variant="outlined"
                          color="error"
                          onClick={uninstallApplication}
                          sx={{
                            // backgroundColor: theme.palette.secondary.light,
                            // color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            mt:2,
                            // "&:hover": { color: theme.palette.secondary.dark },
                          }}
                          >
                          Uninstall
                    </Button>
                    <Button
                        variant="outlined"
                        color="default"
                        onClick={routeConfigPage}
                        sx={{
                          // backgroundColor: theme.palette.secondary.light,
                          // color: theme.palette.background.alt,
                          fontSize: "14px",
                          fontWeight: "bold",
                          padding: "10px 20px",
                          mt:2,
                          "&:hover": { color: theme.palette.secondary.light },
                        }}
                        >
                        Configure
                  </Button>
                  </>
                  )
                }
                
                  <Button
                      variant="contained"
                      onClick={() => {
                        if (loadedFormData?.userGuideUrl) {
                          window.open(loadedFormData.userGuideUrl, "_blank");
                        } else {
                          console.warn("User guide URL is not available");
                        }
                      }}
                      sx={{
                      backgroundColor: theme.palette.primary.dark,
                      color: theme.palette.secondary.alt,
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                      mt:2,
                      "&:hover": { color: theme.palette.secondary.light },
                      }}
                  >
                      {"User Guide"}
                  </Button>
                  <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      width="100%"
                      m= "4rem 0rem 1.0rem 0rem"
                      >
                    <img
                      src={ '/'+loadedFormData.iconUrl} 
                      alt="App Logo"
                      style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '10%',
                        background: 'transparent',
                      }}
                    />
                  </Box>
            
                  <Typography variant="h4" component="div"  color={theme.palette.secondary[100]} fontWeight="bold" sx={{ mt:3}}>
                   {"Category"}
                  </Typography>

                  <Typography variant="h6" component="div" sx={{
                      }}>
                   {loadedFormData.category}
                  </Typography>

                  <Typography variant="h4" component="div"  color={theme.palette.secondary[100]} fontWeight="bold" sx={{ mt:3}}>
                   {"Pricing"}
                  </Typography>

                  <Typography variant="h6" component="div" sx={{
                      }}>
                      {loadedFormData.pricing}
                  </Typography>

                 

                  <Typography variant="h4" component="div"  color={theme.palette.secondary[100]} fontWeight="bold" sx={{ mt:3}}>
                   {"Install Requirements"}
                  </Typography>

                  <Typography variant="h4" component="div"  color={theme.palette.secondary[100]} fontWeight="bold" sx={{ mt:3}}>
                   {"Support"}
                  </Typography>
                    

        </Box>
        {/* --- RIGHT END ---*/}

      
      </Box>
    </Box>
  );
};

export default AppDetail;