import React, { useEffect, useState } from 'react';
import { Box, Button, useTheme, useMediaQuery, Autocomplete } from '@mui/material';
import { CssTextField } from 'components/StyledComponents';
import { DeleteOutline } from '@mui/icons-material';
import { sadevioColors } from 'components/Colors';
import tm from 'components/TranslationManager';
import { transformApiKeyToCamelCase, transformApiKeyToCamelCaseUpperCase } from 'components/Utils';

const VisitorVisitInvite = ({ values, index, handleChange, handleBlur, removeVisitor, errors, touched, setFieldValue, selectedFrontDeskTerminalSettings }) => {
  const theme = useTheme();
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [allVisitorTypes, setAllVisitorTypes] = useState([{ label: 'Visitor', value: 'visitor' }]);
  const [defaultVisitorType, setDefaultVisitorType] = useState({ label: 'Visitor', value: 'visitor' });
  const [additionalFields, setAdditionalFields] = useState([]);


  useEffect(() => {
    var localDefaultType = "Visitor";

    if(selectedFrontDeskTerminalSettings.checkInWorkflow){

      var visitorTypes = [];

      for (const item of selectedFrontDeskTerminalSettings.checkInWorkflow) {
        var tmp = { label: item.visitorType, value: item.visitorType };
        visitorTypes.push(tmp)
        if(item.defaultType){
          setDefaultVisitorType(tmp)
          localDefaultType = tmp.value;
          updateAdditionalFields(localDefaultType)
          //find the additional fields
          for(const workflowElement of item.workflowElements){
            if(workflowElement.engineType == "visitor_data"){
              const configuration = JSON.parse(workflowElement.configuration);

              const additionalTerminalFields = configuration.additionalTerminalFields.filter(
                field => !field.hidePreReg // Keep only elements where hidePreReg is not true
              );
              setAdditionalFields(additionalTerminalFields);
            }
          }
        }

      }

      setAllVisitorTypes(visitorTypes);

    }else{
        setAllVisitorTypes([{ label: 'Visitor', value: 'Visitor' }])
        setDefaultVisitorType( { label: 'Visitor', value: 'Visitor' } );
    }

  }, [selectedFrontDeskTerminalSettings]);

  // Update form fields dynamically when visitor_visitor_type changes
  useEffect(() => {
    const currentVisitorType = values.visitors[index]?.visitor_visitor_type || defaultVisitorType.value;
    updateAdditionalFields(currentVisitorType);
  }, [values.visitors[index]?.visitor_visitor_type]);

  useEffect(() => {
    const currentType = values?.visitors?.[index]?.visitor_visitor_type;
    if (!currentType) {
      setFieldValue(`visitors.${index}.visitor_visitor_type`, defaultVisitorType.value);
    }
  }, [index, values.visitors, setFieldValue, defaultVisitorType]);

  const updateAdditionalFields = (visitorType) => {
    const workflow = selectedFrontDeskTerminalSettings?.checkInWorkflow?.find(
      (item) => item.visitorType === visitorType
    );

    if (workflow) {

      const workFlowElement = workflow.workflowElements?.filter((el) => el.engineType === 'visitor_data')

      const newFields = workflow.workflowElements
        ?.filter((el) => el.engineType === 'visitor_data')
        ?.flatMap((el) => JSON.parse(el.configuration).additionalTerminalFields || []);

        // Check if the required "visitor_email" field is present
        const emailFieldExists = newFields.some(field => field.apiKey === 'visitor_email');
        
        if (!emailFieldExists) {
          // Add the default email field if not present
          newFields.push({
            apiKey: 'visitor_email',
            fieldName: 'Email',
            fieldType: 'email',
            required: false,
            maxFieldLength: 255,
            custom: false,
            uniqueKey: false,
            entityType: 'visitor',
            tenantId: '',
            importer: true,
            fieldId: null, // Optional, depends on how you're using it
          });
        }

        const transformedFields = newFields.map((field) => {
          const updatedField = {
            ...field,
            // apiKey: transformApiKeyToCamelCase(field.apiKey), // Transform apiKey
            apiKey: field.apiKey, 
            fieldName: tm.translate("dsp" + transformApiKeyToCamelCaseUpperCase(field.apiKey, true), 'Field') === 'Field'
            ? field.fieldName
            : tm.translate("dsp" + transformApiKeyToCamelCaseUpperCase(field.apiKey, true), 'Field'),        
          };
      
          // Update fieldName for specific apiKeys
          if (["visitor_c_1", "visitor_c_2", "visitor_c_3", "visitor_c_4", "visitor_c_5"].includes(updatedField.apiKey)) {
            updatedField.fieldName = tm.translate(
              `${workFlowElement[0].hash}_${updatedField.apiKey}_title`,
              `Custom ${updatedField.apiKey.slice(-1)}` // Default to "Custom <number>"
            );
          }
      
          return updatedField;
        });

        const additionalTerminalFields = transformedFields.filter(
          field => !field.hidePreReg // Keep only elements where hidePreReg is not true
        );

      setAdditionalFields(additionalTerminalFields || []);
    } else {
      setAdditionalFields([]);
    }
  };


  return (
    <Box m="1.5rem 0rem" sx={{ border: 1 , p: isNonMobile ? 5 : 2, gridColumn: "span 4" }} >
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
         <Autocomplete
          disablePortal
          disableClearable
          options={allVisitorTypes}
          defaultValue={defaultVisitorType}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.label}
          onChange={(event, newValue) => setFieldValue(`visitors.${index}.visitor_visitor_type`, newValue ? newValue.label : '')}
          value={
            allVisitorTypes.find(option => option.label === values?.visitors?.[index]?.visitor_visitor_type) || null
          }
          sx={{ gridColumn: "span 4" }}
          renderInput={(params) => (
            <CssTextField
              {...params}
              required
              label={tm.translate('dspVisitorType', 'Visitor Type')}
              name={`visitors.${index}.visitor_visitor_type`}
              onBlur={handleBlur}
              error={Boolean(touched?.visitors?.[index]?.visitor_visitor_type) && Boolean(errors?.visitors?.[index]?.visitor_visitor_type)}
              helperText={touched?.visitors?.[index]?.visitor_visitor_type && errors?.visitors?.[index]?.visitor_visitor_type}
              theme={theme}
            />
          )}
        />
        <CssTextField
          label={tm.translate("dspFirstName", "First Name")}
          name={`visitors.${index}.visitor_first_name`}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          required
          sx={{ gridColumn: "span 2" }}
          theme={theme}
          value={values?.visitors?.[index]?.visitor_first_name || ''} // Use values from Formik
          error={Boolean(touched?.visitors?.[index]?.visitor_first_name) && Boolean(errors?.visitors?.[index]?.visitor_first_name)}
          helperText={touched?.visitors?.[index]?.visitor_first_name && errors?.visitors?.[index]?.visitor_first_name}
        />
        <CssTextField
          label={tm.translate("dspLastName", "Last Name")}
          name={`visitors.${index}.visitor_last_name`}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          required
          sx={{ gridColumn: "span 2" }}
          theme={theme}
          value={values?.visitors?.[index]?.visitor_last_name || ''} // Use values from Formik
          error={Boolean(touched?.visitors?.[index]?.visitor_last_name) && Boolean(errors?.visitors?.[index]?.visitor_last_name)}
          helperText={touched?.visitors?.[index]?.visitor_last_name && errors?.visitors?.[index]?.visitor_last_name}
        />

        {additionalFields.map((item) => (
          <CssTextField
            key={item.apiKey}
            label={item.fieldName}
            name={`visitors.${index}.${item.apiKey}`}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required={item.required}
            sx={{ gridColumn: "span 4" }}
            theme={theme}
            value={values?.visitors?.[index]?.[item.apiKey] || ''} // Use values from Formik
            error={Boolean(touched?.visitors?.[index]?.[item.apiKey]) && Boolean(errors?.visitors?.[index]?.[item.apiKey])}
            helperText={touched?.visitors?.[index]?.[item.apiKey] && errors?.visitors?.[index]?.[item.apiKey]}
          />
        ))}
        <Button
          startIcon={<DeleteOutline />}
          onClick={() => removeVisitor(index, setFieldValue, values)} // Pass setFieldValue explicitly
          sx={{
            p: "1rem",
            gridColumn: "span 4",
            backgroundColor: palette.secondary.light,
            color: palette.background.alt,
            fontWeight: "bold",
            "&:hover": {
              color: sadevioColors.hexColorCheckedOut,
              backgroundColor: palette.secondary.light,
              fontWeight: "bold",
            },
          }}
        >
         {tm.translate("btnRemove", "Remove")}
        </Button>
      </Box>
    </Box>
  );
};

export default VisitorVisitInvite;
