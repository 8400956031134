import {
  Box,
  Button,
  useMediaQuery,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import {  resetPasswordUser } from "hooks/HttpRequests";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { enqueueSnackbar } from "notistack";
import { CssTextField } from "components/StyledComponents";



const requiredSchma = yup.object().shape({
  password_1: yup.string().required("required"),
  password_2: yup.string().required("required"),
});


const initialValuesLogin = {
  password_1: "",
  password_2: "",
};

const ResetForm = () => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();

  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const token = searchParams.get("open");

  const login = async (values, onSubmitProps) => {
  
        // var submitJson = {};
  
        dispatch(showLoading( ""))
  
  
        values["email"] = email;
        values["open"] = token;

        if(values.password_1 != values.password_2){
          enqueueSnackbar({
            message: "Password does not match.",
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, // 5000 milliseconds = 5 seconds
              // persist: true
            
          });
        }else{

        resetPasswordUser()(values)
        .then((result) => {
          // Use the result.data as needed
          if (result.success) {
            
              navigate("/dashboard");
              
  
          }else{
            enqueueSnackbar('We have a problem!',{ 
              variant: 'error', anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 5000, // 5000 milliseconds = 5 seconds
              // persist: true
            })
  
          }
  
          
          dispatch(hideLoading());
  
        })
        .catch((error) => {
  
          
          if (typeof error.userMessage !== "undefined") {
            enqueueSnackbar({
              message: error.userMessage,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top', // 'top', 'bottom'
                  horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, // 5000 milliseconds = 5 seconds
                // persist: true
              
            });
          }
          dispatch(hideLoading());
  
        });
      }
        
    };

  const handleFormSubmit = async (values, onSubmitProps) => {
      await login(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={ initialValuesLogin }
      validationSchema={ requiredSchma}
    >
      {({ 
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
           
            <CssTextField
                label="Password"
                type="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password_1}
                name="password_1"
                error={Boolean(touched.password_1) && Boolean(errors.password_1)}
                helperText={touched.password_1 && errors.password_1}
                sx={{ gridColumn: "span 4" }}
                theme={theme} 
                />

              <CssTextField
                  label="Password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password_2}
                  name="password_2"
                  error={Boolean(touched.password_2) && Boolean(errors.password_2)}
                  helperText={touched.password_2 && errors.password_2}
                  sx={{ gridColumn: "span 4" }}
                  theme={theme} 
                  />
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "2rem 0",
                p: "1rem",
                backgroundColor: palette.secondary.light,
                color: palette.background.alt,
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: palette.secondary.light,
                  color: palette.background.alt,
                  fontWeight: 'bold'
                },
              }}
            >
             RESET PASSWORD
            </Button>
            <Typography
              onClick={() => {
                resetForm();
                navigate("/")
              }}
              sx={{
                textDecoration: "underline",
                color: palette.secondary.light,
                "&:hover": {
                  cursor: "pointer",
                  color: palette.secondary.light,
                  fontWeight: 'bold'
                },
              }}
            >
              Remember password? Login now.
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ResetForm;