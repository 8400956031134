import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControlLabel, ListItemIcon, Tooltip, Button, useTheme, RadioGroup, Radio, Autocomplete, TextField } from '@mui/material';
import {  Close, Translate } from '@mui/icons-material';
import { getWebUsers } from 'hooks/HttpRequests';

const WelcomeScreenMultipleLanguage = ({globalType, navigate, id }) => {

const theme = useTheme();
const [showReviewers, setShowReviewers] = useState(false);
const [availableEmployes, setAvailableEmployes] = useState([]);
const [gridLoading, setGridLoading] = useState(false);

const toggleReviewers = () => {
    setShowReviewers(!showReviewers);
    console.log(showReviewers)
  };

  useEffect(() => {
    
    loadVisitorTypes();

  }, []); // Empty dependency array to fetch options only once on component mount


  const handleInputChange = (event, value) => {
    
    console.log("handleInputChange "+value)
    
  };

  const loadVisitorTypes = async () => {

    setGridLoading(true)

    //do an autocomplete function

    getWebUsers(navigate)(1)
      .then((result) => {

        // Use the result.data as needed
        if(result.success){
          console.log(result)

        

         setAvailableEmployes(result.data)
        }
        setGridLoading(false)

      })
      .catch((error) => {

        console.log(error);

        setGridLoading(false)
      });

  }

const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  return (
    <Box>
        <Box sx={{ ...commonStyles, border: 1 }}>
            
        <Box display="flex" alignItems="center">
            <ListItemIcon>
                <Translate fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
            </ListItemIcon>
            <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                <Box display="flex" flexDirection="row" alignItems="center">

                    
                    <Typography
                    variant="h3"
                    color={theme.palette.secondary[100]}
                    fontWeight="bold"
                    >
                    Multiples Languages
                    </Typography>
                    {globalType && (
                    <Tooltip title={
                        <Typography variant="body1" style={{ fontSize: '16px' }}>
                        Global Visitor Type
                        </Typography>
                    }>
                        
                    </Tooltip>
                    )}
                </Box>

                <Typography variant="h5" color={theme.palette.secondary[300]}>
                    Let visitors sign-in with different languages.
                </Typography>
                </Box>

                <Box ml="auto">

                {!showReviewers && (
                    <Button
                        variant="contained"
                        onClick={toggleReviewers}
                        sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        m: "0rem 1rem 0rem 0rem",
                        "&:hover": { color: theme.palette.secondary.light },
                        }}
                    >
                        {"EDIT"}
                    </Button>
                )}
            
            {showReviewers && (
            <Button
                        variant="contained"
                        onClick={() => navigate(id+"/general")} 
                        sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        m: "0rem 1rem 0rem 0rem",
                        "&:hover": { color: theme.palette.secondary.light },
                        }}
                    >
                    {"SAVE"}
                </Button>
            )}

                {showReviewers && (
                <Button
                        variant="contained"
                        onClick={toggleReviewers}
                        sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        m: "0rem 1rem 0rem 0rem",
                        "&:hover": { color: theme.palette.secondary.light },
                        }}
                    >
                        <Close />
                    </Button>
                )}
            </Box>
        </Box>
        {/*     */}
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >

            {showReviewers && (

        <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem"}}>
            <Typography
                variant="h4"
                color={theme.palette.secondary[100]}
                fontWeight="bold"
                mt="3rem"
                >
                Authorised Reviewers
                </Typography>
            <Typography variant="h5" color={theme.palette.secondary[300]}>
                Select which person will be notified to review the visitor invitation.
            </Typography>
            <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    sx={{
                        "& .MuiDataGrid-root": {
                        border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light,
                        },
                        "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                        },
                        "& .MuiDataGrid-panel .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                        },
                    "& .MuiDataGrid-panelWrapper .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                        },
                        "& .MuiTab-root.MuiTab-textColorPrimary.Mui-selected": {
                        color: `${theme.palette.secondary[200]} !important`,
                        fontWeight: 'bold'
                        },
                        "& .MuiRadio-colorPrimary.Mui-checked": {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                        "& .MuiFormControlLabel-label": {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                    }}
                >
                    <FormControlLabel value="only_one" control={<Radio />} label="Only one reviewer need to confirm" />
                    <FormControlLabel value="all" control={<Radio />} label="All selected reviewers need to confirm" />
                </RadioGroup>

                <Autocomplete
                    multiple
                    sx={{ mb: 2, mt: 2 }}
                    id="tags-standard"
                    options={availableEmployes}
                    getOptionLabel={(option) => {
                        return option.firstname + " " + option.lastname+ " - " + option.email;
                    }}
                    onInputChange={handleInputChange}
                    defaultValue={[]} // Empty array as defaultValue
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        placeholder="Reviewers"
                    />
                    )}
                />

            </Box>
            )}
            </Box>
            
            <Box
            display="flex"
            flexDirection="row"
            alignItems="right"
            width="100%"
            >
                {showReviewers && (
                    <Button
                        variant="contained"
                        onClick={toggleReviewers}
                        sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        m: "0rem 1rem 0rem 0rem",
                        "&:hover": { color: theme.palette.secondary.light },
                        }}
                    >
                        {"DISABLE"}
                    </Button>
                )}
            
            </Box>

        </Box>

        {/*  -------------------------------   */}

    </Box>
  );
};

export default WelcomeScreenMultipleLanguage;
