import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, useTheme } from "@mui/material";
import React, { useEffect } from 'react'
import { useState } from "react";
import { assigneBadge, getBadgeProcessingStatus, getSessionToken, getUri } from "hooks/HttpRequests";
import { CssTextFieldReverted } from "components/StyledComponents";
import { useFrontDeskManager } from "components/FrontDeskManager";
import dayjs from "dayjs";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";


const DialogAssignBadgeOld = ({ open, onClose, onSave, entityTypeParam, currentEntity, visitInformation  }) => {

  const theme = useTheme();
  const dispatch = useDispatch();


  const [entityType, setEntityType] = useState(entityTypeParam);
  const [badgeIdInput, setBadgeIdInput] = useState("");
  const { selectedFrontDesk, frontDeskLoading, selectedFrontDeskTerminalSettings } = useFrontDeskManager();


  const [validFromDayJS, setValidFromDayJS] = useState(dayjs().set("hour", 9).startOf("hour"));
  const [validToDayJS, setValidToDayJS] = useState(dayjs().set("hour", 12).add(1, 'year'));

  useEffect(() => {

    if(currentEntity != null){

      if(entityTypeParam == "visitor"){
        // setEntityId(currentEntity.visitorId);
      }else{
        // setEntityId(currentEntity.entityId);
      }
      
    }
    

  }, [open]);

  const handleInputChange = (e) => {

    // Update the state based on the previous state
    setBadgeIdInput(e.target.value);


  };

  ///////////////////////////////



  const handleSave = () => {

    var values = {}

    let assignAccessLevels = "";

    const accessProfiles = selectedFrontDeskTerminalSettings.accessProfiles;
    if (Array.isArray(accessProfiles) && accessProfiles.length > 0) {
      // Filter profiles with defaultProfile set to true
      const defaultProfiles = accessProfiles
        .filter((level) => level.defaultProfile)
        .map((level) => level.accessProfileId);

      // Join the IDs with `;`
      assignAccessLevels = defaultProfiles.join(";");
    }

    values["access_card"] = badgeIdInput;
    values["access_levels"] = assignAccessLevels;
    values["visit_id"] = visitInformation.visitId;
    values["visitor_id"] = currentEntity.visitorId;
    values["self_check_in"] = false;

    
    if( badgeIdInput.length <= 45 && assignAccessLevels.length <= 45){
      assigneBadge()(values)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {
  
          getProcessingStatus(0, result.data.entity_id, values);
        }else{
          enqueueSnackbar({
              message: result.message,
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "center" },
              autoHideDuration: 3000,
            });
        }
        
  
      })
      .catch((error) => {
        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            // navigate("/");
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 4000, 
              // persist: true
            
          });
        }
        if (typeof error.userMessage !== "undefined") {
          enqueueSnackbar({
            message: error.userMessage,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 4000, // 5000 milliseconds = 5 seconds
              // persist: true
            
          });
        }
        dispatch(hideLoading());
  
      });
    }else{
      enqueueSnackbar({
        message: `badge id to long`,
          variant: 'error',
          anchorOrigin: {
            vertical: 'top', // 'top', 'bottom'
            horizontal: 'center', // 'left', 'center', 'right'
          },
          autoHideDuration: 3000, // 5000 milliseconds = 5 seconds
          // persist: true
        
      });
    }

    

  }

  

  const getProcessingStatus = (n, entityId, values) => {

    var endTime = 0;

    if(n == 0){
      dispatch(showLoading(""));
      var t = new Date();
      t.setSeconds(t.getSeconds() + 20);
      endTime = t.getTime();
    }else{
        endTime = n
    }
    
        var now = new Date();

        if(now.getTime() < endTime){
  
          getBadgeProcessingStatus()(visitInformation.visitId, entityId)
          .then((result) => {
            // Use the result.data as needed
            if (result.success) {
      
              if(result.data != null && result.data.entityId > 0){

                if(result.data.processingStatus.toLowerCase() === "finished"){

                  var accessSystemResponse = JSON.parse(result.data.processingData);
	    			        	 		
                  if(accessSystemResponse != null && accessSystemResponse.success){

                    if(result.data.action.toLowerCase() === 'assign'){

                      dispatch(hideLoading());
                      //was assigned
                      onSave(values);

                    }else if(result.data.action.toLowerCase() === 'unassign'){

                      dispatch(hideLoading());

                    }else{
                      //something wrong
                      dispatch(hideLoading());

                    }
                    
                  }else{
                    //something wrong
                    dispatch(hideLoading());
                    enqueueSnackbar({
                      message: `${accessSystemResponse.error_code} - ${accessSystemResponse.message}`,
                        variant: 'error',
                        anchorOrigin: {
                          vertical: 'top', // 'top', 'bottom'
                          horizontal: 'center', // 'left', 'center', 'right'
                        },
                        autoHideDuration: 3000, // 5000 milliseconds = 5 seconds
                        // persist: true
                      
                    });

                  }
                }else{
                    //wait a second and then call function again

                    setTimeout(() => getProcessingStatus(endTime, entityId, values), 1000);
                  }

              }else{
                // Something went wrong
                dispatch(hideLoading());

              }
                // onClose();
            }else{
              dispatch(hideLoading());

            }
            
      
          })
          .catch((error) => {
            dispatch(hideLoading());
            // if (typeof error.action !== "undefined") {
            //   if (error.action == "relogin") {
            //     navigate("/");
            //   }
            // }
            // if (typeof error.userMessage !== "undefined") {
            //   enqueueSnackbar({
            //     message: error.userMessage,
            //       variant: 'error',
            //       anchorOrigin: {
            //         vertical: 'top', // 'top', 'bottom'
            //         horizontal: 'center', // 'left', 'center', 'right'
            //       },
            //       autoHideDuration: 3000, // 5000 milliseconds = 5 seconds
            //       // persist: true
                
            //   });
            // }
            // dispatch(hideLoading());
      
          });

        }else{
          // msg : 'Access system took to long \n',
          dispatch(hideLoading());
            enqueueSnackbar({
              message: "Access System did not finish in time.",
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "center" },
              autoHideDuration: 3000,
            });
        }
  }

  const handleClose = () => {

      // onClearImage()
      onClose();
  }



  const handleValidFromDateChange = (item) => {
    setValidFromDayJS(item)
    }
    
    const handleValidToDateChange = (item) => {
    setValidToDayJS(item)
    }


  return (
    <Dialog open={open} onClose={onClose}>
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>Assign Access Card</DialogTitle>
        <DialogContent>

        <Grid container spacing={2} style={{ backgroundColor: 'transparent', padding: "2px 0px 2px 0px" }} >
          {/* Left Column */}
          {/* <Grid item xs={6} style={{ padding: 20, backgroundImage: 'transparent' }}> */}
            <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none'  }}>
            <CssTextFieldReverted
                    label={"Type Badge ID"}
                    onChange={handleInputChange}
                    sx={{ mr: 2 }}
                    theme={theme}
                />
            </Paper>
          {/* </Grid> */}

       
        </Grid>

          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {/* Are you sure you want to execute this action? */}
        
          </DialogContentText>
        </DialogContent>
        <DialogActions >  

            <Box>
                <Button onClick={handleClose} color="primary" sx={{ color: theme.palette.background.alt }}>
                  Cancel
                </Button>
                <Button onClick={handleSave} color="primary" autoFocus sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
                  Assign Access Card
                </Button>
            </Box>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogAssignBadgeOld