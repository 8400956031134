import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Tooltip,
  useTheme,
} from "@mui/material";
import tm from 'components/TranslationManager';
import { getListValuesPaging } from "hooks/HttpRequests";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';


const DialogLoadKioskSettingsFromDifferentDevice = ({
  open,
  onClose,
  onSave,
  onOverride,
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    

  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState([]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 200,
    sort: [{property:"entityId", direction:"DESC"}],
    query:[]
  })

  useEffect(() => {

    if(open){
      setLoading(true)

      getListValuesPaging(navigate)(paginationModel, "device", "")
        .then((result) => {
  
          if(result.success){
            setDevices(result.data)
          }
          setLoading(false)
  
        })
        .catch((error) => {
          setLoading(false)
        });
      setLoading(false)
    }
    
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave();
  };

  const handleOverride = (device) => {
    onOverride(device);
  };

  if (loading && !devices) {
    return <div>Loading...</div>;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ fontWeight: "bold", color: theme.palette.background.alt, }}>Available Devices</DialogTitle>
        <DialogContent>
          {devices.length === 0 ? (
            <Typography variant="body2" color="text.secondary" textAlign="center" mt={2}>
              No devices found.
            </Typography>
          ) : (
            <Box sx={{ maxHeight: "400px", overflowY: "auto", p: 2, borderRadius: 1, backgroundColor: theme.palette.background.alt, }}>
              <List>
                {devices.map((device, index) => (
                  <React.Fragment key={device.entityId}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar>{device.deviceName ? device.deviceName[0] : "?"}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Typography variant="h6">{device.deviceName || "Unknown Device"}</Typography>}
                        secondary={
                          <>
                            <Typography variant="body2" color="text.secondary">
                              Model: {device.model || "N/A"}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Type: {device.type || "N/A"}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Version: {device.version || "N/A"}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Last Settings Upload: {device.lastSuccessfulConfUpload || "N/A"}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: device.deviceLocalConfig ? "green" : "red",
                                fontWeight: "bold",
                              }}
                            >
                              {device.deviceLocalConfig ? "Config Available" : "No Config Available"}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Last Config Upload: {device.lastSuccessfulConfUpload ? dayjs(device.lastSuccessfulConfUpload).format('YYYY-MM-DD HH:mm:ss') : "N/A"}
                            </Typography>
                          </>
                        }
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!device.deviceLocalConfig}
                        onClick={() => handleOverride(device)}
                        sx={{ ml: 2 }}
                      >
                        Override Config
                      </Button>
                    </ListItem>
                    {index < devices.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" sx={{ fontWeight: "bold" }}>
            Close
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogLoadKioskSettingsFromDifferentDevice;