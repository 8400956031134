import React, { useEffect, useState } from 'react';
import { Box, Typography,  ListItemIcon, Tooltip, Button, useTheme,Link } from '@mui/material';
import { Close, Wallpaper, FormatColorFill, ColorLens, FormatColorText } from '@mui/icons-material';
import { deleteAttachment, getSessionToken, getUri,  getWorkflowConfiguration, postAttachment, putDesignConfiuration } from 'hooks/HttpRequests';
import {  SketchPicker } from 'react-color';
import { useFrontDeskManager } from 'components/FrontDeskManager';
import { enqueueSnackbar } from 'notistack';


const WelcomeScreenDesign = ({globalType, navigate, id }) => {

const theme = useTheme();

const { selectedFrontDesk } = useFrontDeskManager();
const [loading, setLoading] = useState(true);
const [loadedFormData, setLoadedFormData] = useState(null);


  ///////////////////////////////


    const [backgroundImageHomeAttachmentId, setBackgroundImageHomeAttachmentId] = useState(0); //background video


    const [triggerRefresh, setTriggerRefresh] = useState(false);

    //////////////////////////////
    // WELCOME IMAGE
    //////////////////////////////

    const [showWelcomeImage, setShowWelcomeImage] = useState(false);

    const [logoUrl, setLogoUrl] = useState("");
    const [logoAttachmentId, setLogoAttachmentId] = useState(0);
    const [selectedFileLogo, setSelectedFileLogo] = useState(null);
    const [selectedFileNameLogo, setSelectedFileNameLogo] = useState("no file selected");

    const toggleWelcomeImage = () => {
        setShowWelcomeImage(!showWelcomeImage);
    };

    useEffect(() => {
    
        getLogoSrc();

    }, [logoAttachmentId]);

    const onFileChangeLogo = event => {

        const file = event.target.files[0];

        if( typeof file === "undefined"){
        return
        }

        // if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        //   alert('Please select a PNG or JPEG image file.');
        //   return;
        // }

        setSelectedFileNameLogo(file.name)

        setSelectedFileLogo(file)


    };

    const uploadLogoAttachment = () => {
    
        if (!selectedFileLogo) {

            enqueueSnackbar({
                    message: "Please select a file first",
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                    
                });
            return;
        }

        postAttachment(navigate)(selectedFrontDesk.entityId, "front_desk", "terminal_logo",selectedFileLogo)
            .then((result) => {
                // Use the result.data as needed
                if (result.success) {
            
                    console.log(result)
                    if(result.success){

                        const newFormData = {
                            ...loadedFormData,
                            logoAttachmentId: result.data.attachmentId, // Reset the logoAttachmentId
                            logoFileName: result.data.fileName,    // Clear the file name
                        };
                    
                        // Update the state
                        setLoadedFormData(newFormData);
                        
                        loadedFormData.logoAttachmentId = result.data.attachmentId
                        loadedFormData.logoFileName = result.data.fileName;

                        handleSubmit()
                    }
                    setTriggerRefresh(!triggerRefresh);
                }
            })
            .catch((error) => {
                if (typeof error.action !== "undefined") {
                if (error.action === "relogin") {
                    navigate("/");
                }
                }
            });

    };

    const deleteLogoAttachment = () => {
    

        if(loadedFormData.logoAttachmentId > 0){

        const entityId = loadedFormData.logoAttachmentId ;

        const newFormData = {
            ...loadedFormData,
            logoAttachmentId: 0, // Reset the logoAttachmentId
            logoFileName: "",    // Clear the file name
        };
    
        // Update the state
        setLoadedFormData(newFormData);

        loadedFormData.logoAttachmentId = 0
        loadedFormData.logoFileName = ""

        deleteAttachment(navigate)(entityId)
            .then((result) => {
                // Use the result.data as needed
                if (result.success) {
            
                    if(result.success){
                        
                        handleSubmit()
                    }
                    setTriggerRefresh(!triggerRefresh);
                }
            })
            .catch((error) => {
                if (typeof error.action !== "undefined") {
                if (error.action === "relogin") {
                    navigate("/");
                }
                }
            });
        }

    };

    ///////////////////////////////
    // Backgorund IMAGE
     //////////////////////////////

    const [showBackgroundImage, setShowBackgroundImage] = useState(false);

    const [backgroundUrl, setBackgroundUrl] = useState("");
    const [backgroundImageAttachmentId, setBackgroundImageAttachmentId] = useState(0);
    const [selectedFileBackgrounImage, setSelectedFileBackgrounImage] = useState(null);
    const [selectedFileNamebackgroundImage, setSelectedFileNamebackgroundImage] = useState("no file selected");

    const toggleBackgroundImage = () => {
        setShowBackgroundImage(!showBackgroundImage);
    };

    useEffect(() => {
    
        getBackgroundImageSrc();

    }, [backgroundImageAttachmentId]);

    const onFileChangeBackgrounImage = event => {

        const file = event.target.files[0];

        if( typeof file === "undefined"){
        return
        }

        // if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        //   alert('Please select a PNG or JPEG image file.');
        //   return;
        // }

        setSelectedFileNamebackgroundImage(file.name)

        setSelectedFileBackgrounImage(file)

        // Reset the file input to allow selecting the same file again
        // fileInput.value = null;

  };

  const uploadBackgroundImageAttachment = () => {
    
    if (!selectedFileBackgrounImage) {

        enqueueSnackbar({
                message: "Please select a file first",
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
                
            });
        return;
    }

    postAttachment(navigate)(selectedFrontDesk.entityId, "front_desk", "terminal_background",selectedFileBackgrounImage)
        .then((result) => {
            // Use the result.data as needed
            if (result.success) {
        
                console.log(result)
                if(result.success){

                    const newFormData = {
                        ...loadedFormData,
                        backgroundImageAttachmentId: result.data.attachmentId, // Reset the logoAttachmentId
                        backgroundImageHomeFileName: result.data.fileName,    // Clear the file name
                    };
                
                    // Update the state
                    setLoadedFormData(newFormData);
            
                    loadedFormData.backgroundImageAttachmentId = result.data.attachmentId
                    loadedFormData.backgroundImageHomeFileName = result.data.fileName;

                    handleSubmit()
                }
                setTriggerRefresh(!triggerRefresh);
            }
        })
        .catch((error) => {
            if (typeof error.action !== "undefined") {
            if (error.action == "relogin") {
                navigate("/");
            }
            }
        });

    };

    const deleteBackgroundAttachment = () => {
    

        if(loadedFormData.backgroundImageAttachmentId > 0){

        const entityId = loadedFormData.backgroundImageAttachmentId ;
        const newFormData = {
            ...loadedFormData,
            backgroundImageAttachmentId: 0, // Reset the logoAttachmentId
            backgroundImageFileName: "",    // Clear the file name
        };
    
        // Update the state
        setLoadedFormData(newFormData);

        loadedFormData.backgroundImageAttachmentId = 0
        loadedFormData.backgroundImageFileName = ""

        deleteAttachment(navigate)(entityId)
            .then((result) => {
                // Use the result.data as needed
                if (result.success) {
            
                    if(result.success){
                        
                        handleSubmit()
                    }
                    setTriggerRefresh(!triggerRefresh);
                }
            })
            .catch((error) => {
                if (typeof error.action !== "undefined") {
                if (error.action === "relogin") {
                    navigate("/");
                }
                }
            });
        }

    };


    ///////////////////////////////
    // BACKGROUND COLOR
    ///////////////////////////////

    const [showBackGroundColorConf, setShowBackGroundColorConf] = useState(false);

    const toggleBackGroundColorConf = () => {
        setShowBackGroundColorConf(!showBackGroundColorConf);
    };

    const [displayBackgroundColorPicker, setDisplayBackgroundColorPicker] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState("#ffffff");

    const handleBackgroundColorClick = () => {
        setDisplayBackgroundColorPicker(!displayBackgroundColorPicker)
    };

    const  handleBackgroundColorClose = () => {
        setDisplayBackgroundColorPicker(false)
    };

    const handleBackgroundColorChange = (color, event) => {
        setBackgroundColor(color.hex)
        setLoadedFormData((prevData) => ({
            ...prevData, // Keep other properties unchanged
            backgroundColor: color.hex, // Update backgroundColor
        }));
    };

    ///////////////////////////////
    // FONT COLOR
    ///////////////////////////////

    const [showFontColorConf, setShowFontColorConf] = useState(false);

    const toggleFontColorConf = () => {
        setShowFontColorConf(!showFontColorConf);
    };

    const [displayFontColorPicker, setDisplayFontColorPicker] = useState(false);
    const [fontColor, setFontColor] = useState("#ffffff");

    const handleFontColorClick = () => {
        setDisplayFontColorPicker(!displayFontColorPicker)
    };

    const  handleFontColorClose = () => {
        setDisplayFontColorPicker(false)
    };

    const handleFontColorChange = (color, event) => {
        setFontColor(color.hex)
        setLoadedFormData((prevData) => ({
            ...prevData, // Keep other properties unchanged
            fontColor: color.hex, // Update backgroundColor
        }));
    };
  
    ///////////////////////////////
    // ACCENT COLOR
    ///////////////////////////////
    const [showAccentColorConf, setShowAccentColorConf] = useState(false);

    const toggleAccentColorConf = () => {
        setShowAccentColorConf(!showAccentColorConf);
    };

    const [displayAccentColorPicker, setDisplayAccentColorPicker] = useState(false);
    const [accentColor, setAccentColor] = useState("#ffffff");

    const handleAccentColorClick = () => {
        setDisplayAccentColorPicker(!displayAccentColorPicker)
    };

    const  handleAccentColorClose = () => {
        setDisplayAccentColorPicker(false)
    };

    const handleAccentColorChange = (color, event) => {
        setAccentColor(color.hex)
        setLoadedFormData((prevData) => ({
            ...prevData, // Keep other properties unchanged
            mainColor: color.hex, // Update backgroundColor
        }));
    };

    //////////////////////
   useEffect(() => {

    setSelectedFileLogo(null)
    setSelectedFileBackgrounImage(null)

    if (selectedFrontDesk) {
      loadData();
    }
  }, [selectedFrontDesk, triggerRefresh]);


  const loadData = async () => {

    setLoading(true)

    //do an autocomplete function

    getWorkflowConfiguration(navigate)(selectedFrontDesk.entityId)
      .then((result) => {

        // Use the result.data as needed
        if(result.success){
          console.log(result)

          setLoadedFormData(result.data)

          setBackgroundColor(result.data.backgroundColor);
          setFontColor(result.data.fontColor);
          setAccentColor(result.data.mainColor);
          setLogoAttachmentId(result.data.logoAttachmentId)
          setBackgroundImageAttachmentId(result.data.backgroundImageAttachmentId)
        //   setBackgroundImageAttachmentId(result.data.backgroundImageHomeAttachmentId)
        }
        setLoading(false)

      })
      .catch((error) => {

        console.log(error);

        setLoading(false)
      });

  }

  const handleSubmit = () => {
  

    var submitData= {}
    submitData["backgroundColor"] = loadedFormData.backgroundColor;
    submitData["backgroundImageAttachmentId"] = loadedFormData.backgroundImageAttachmentId;
    submitData["backgroundImageHomeFileName"] = loadedFormData.backgroundImageHomeFileName;
    submitData["backgroundImageHomeAttachmentId"] = loadedFormData.backgroundImageHomeAttachmentId;
    submitData["font"] = loadedFormData.font;
    submitData["fontColor"] = loadedFormData.fontColor;
    submitData["backgrlogoAttachmentIdoundColor"] = loadedFormData.backgrlogoAttachmentIdoundColor;
    submitData["logoAttachmentId"] = loadedFormData.logoAttachmentId;
    submitData["logoFileName"] = loadedFormData.logoFileName;
    submitData["mainColor"] = loadedFormData.mainColor;

    submitData["data"]= loadedFormData;

    putDesignConfiuration(navigate)(selectedFrontDesk.entityId, submitData)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {
      
            console.log(result)
            if(result.success){
                //   setCopmanyName("- "+result.data.name);
                setLoadedFormData(result.data)
                setShowWelcomeImage(false)
                setShowBackGroundColorConf(false)
                setShowBackGroundColorConf(false)
                setShowAccentColorConf(false)
                setShowFontColorConf(false)
            }
            setTriggerRefresh(!triggerRefresh);
        }
      })
      .catch((error) => {
        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }
      });

  };

  const getLogoSrc = () => {

    var url = "";
    if (logoAttachmentId > 0) {
      // Build the URL dynamically
      var token = getSessionToken();
      url = getUri(token)+`/attachment/${logoAttachmentId}/file`; // Replace with your API or file path
    }
    setLogoUrl(url)

  };

  const getBackgroundImageSrc = () => {

    var url = "";
    if (backgroundImageAttachmentId > 0) {
      // Build the URL dynamically
      var token = getSessionToken();
      url = getUri(token)+`/attachment/${backgroundImageAttachmentId}/file`; // Replace with your API or file path
    }
    setBackgroundUrl(url)
  };
    




const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

//   if (!loadingComplete || !loadedFormData || !selectedFrontDesk || !loadedAllUserdefinedFieldsData) {
//     return <div>Loading...</div>;
//   }

  if ( loading || !loadedFormData || !selectedFrontDesk ) {
    return <div>Loading...</div>;
  }

  return (
    <Box>


        {/*  -------------------------------   */}
        <Box sx={{ ...commonStyles, border: 1 }}>
            
        <Box display="flex" alignItems="center">
            <ListItemIcon>
                <Wallpaper fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
            </ListItemIcon>
            <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                <Box display="flex" flexDirection="row" alignItems="center">

                    
                    <Typography
                    variant="h3"
                    color={theme.palette.secondary[100]}
                    fontWeight="bold"
                    >
                    Logo
                    </Typography>
                </Box>

                <Typography variant="h5" color={theme.palette.secondary[300]}>
                    Choose your logo to present on the check-in terminal
                </Typography>
                </Box>

                <Box ml="auto">

                {!showWelcomeImage && (
                    <Button
                        variant="contained"
                        onClick={toggleWelcomeImage}
                        sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        m: "0rem 1rem 0rem 0rem",
                        "&:hover": { color: theme.palette.secondary.dark },
                        }}
                    >
                        {"EDIT"}
                    </Button>
                )}
            
            {/* {showWelcomeImage && (
            <Button
                        variant="contained"
                        onClick={() => navigate(id+"/general")} 
                        sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        m: "0rem 1rem 0rem 0rem",
                        "&:hover": { color: theme.palette.secondary.light },
                        }}
                    >
                    {"SAVE"}
                </Button>
            )} */}

                {showWelcomeImage && (
                <Button
                        variant="contained"
                        onClick={toggleWelcomeImage}
                        sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        m: "0rem 1rem 0rem 0rem",
                        "&:hover": { color: theme.palette.secondary.light },
                        }}
                    >
                        <Close />
                    </Button>
                )}
            </Box>
        </Box>
        {/*     */}
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >

            {showWelcomeImage && (

                <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem" }}>
                <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 3,
                    gap: 4, // Space between image and right-side content
                }}
                >
                {/* Left Section: Placeholder or Logo */}
                <Box
                    sx={{
                    width: 400,
                    height: 100,
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f0f0f0",
                    }}
                >
                    {loadedFormData.logoAttachmentId > 0 ? (
                    <Box
                        sx={{
                        width: 400,
                        height: 100,
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        overflow: "hidden",
                        }}
                    >
                        <img
                        src={logoUrl} // Ensure this is dynamically set
                        alt="Uploaded Logo"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                        />
                    </Box>
                    ) : (
                    <Box
                        sx={{
                        width: 400,
                        height: 100,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "2px dashed #ccc",
                        borderRadius: "8px",
                        backgroundColor: "#f9f9f9",
                        textAlign: "center",
                        }}
                    >
                        <Typography variant="subtitle1" color="black">
                            Upload Logo
                        </Typography>
                    </Box>
                    )}
                </Box>

                {/* Right Section */}
                <Box>
                    {/* Button Row */}
                    <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2, // Space between buttons
                        marginBottom: 1, // Add spacing below the button row
                    }}
                    >
                    {/* Select File Button */}
                    {loadedFormData.logoAttachmentId == 0 && (
                    <Button variant="contained" component="label"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontWeight: "bold",
                            ml: 2,
                            maxWidth: 180,
                            "&:hover": {
                            color: theme.palette.primary.main,
                            backgroundColor: theme.palette.secondary.light,
                            fontWeight: "bold",
                            },
                            // Style for file input button
                            "& > input[type=file]": {
                            display: "none", // Hide the input element
                            },
                        }}
                        onClick={() => {
                            // Trigger click on the hidden file input element
                            // document.getElementById("attachmentUploadLogo").click();
                        }}
                        >
                        Browse File
                        <input
                            type="file"
                            id="attachmentUploadLogo"
                            onChange={onFileChangeLogo}
                            style={{ display: 'none' }}
                            hidden
                            accept="*/*" // Change MIME type based on allowed files, e.g., 'image/*'
                        />
                    </Button>
                    
                    )}
                    {loadedFormData.logoAttachmentId == 0 && (
                        <Button
                            variant="outlined"
                            color="success"
                            onClick={uploadLogoAttachment}
                            >
                            Upload Logo
                        </Button>
                    
                    )} 
                    {/* Delete Button */}
                    {loadedFormData.logoAttachmentId > 0 && (
                        <Button
                        variant="outlined"
                        color="error"
                        onClick={deleteLogoAttachment}
                        >
                        Delete Logo
                        </Button>
                    )} 
                    </Box>

                    {/* Recommendations */}
                    <Typography variant="body2" color="textSecondary">
                    We recommend a resolution 4:1 like 1600 x 400
                    </Typography>

                    {/* Download Template */}
                    <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1, color: theme.palette.secondary.light }}>
                    Template:{" "}
                    <Link
                        href="/template_logo.psd"
                        download
                        sx={{ marginTop: 1, color: theme.palette.secondary.light }}
                        underline="hover"
                        target="_blank"
                    >
                        PSD
                    </Link>
                    </Typography>
                </Box>
                </Box>
                </Box>

            )}
            </Box>
        </Box>

        {/*  -------------------------------   */}
        {/*  -------------------------------   */}
        <Box sx={{ ...commonStyles, border: 1 }}>
            
        <Box display="flex" alignItems="center">
            <ListItemIcon>
                <Wallpaper fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
            </ListItemIcon>
            <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                <Box display="flex" flexDirection="row" alignItems="center">

                    
                    <Typography
                    variant="h3"
                    color={theme.palette.secondary[100]}
                    fontWeight="bold"
                    >
                    Background Image
                    </Typography>
                </Box>

                <Typography variant="h5" color={theme.palette.secondary[300]}>
                    Choose a graphic to appear on your welcome screen in the background.
                </Typography>
                </Box>

                <Box ml="auto">

                {!showBackgroundImage && (
                    <Button
                        variant="contained"
                        onClick={toggleBackgroundImage}
                        sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        m: "0rem 1rem 0rem 0rem",
                        "&:hover": { color: theme.palette.secondary.dark },
                        }}
                    >
                        {"EDIT"}
                    </Button>
                )}
            
                {showBackgroundImage && (
                <Button
                        variant="contained"
                        onClick={toggleBackgroundImage}
                        sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        m: "0rem 1rem 0rem 0rem",
                        "&:hover": { color: theme.palette.secondary.light },
                        }}
                    >
                        <Close />
                    </Button>
                )}
            </Box>
        </Box>
        {/*     */}
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >

            {showBackgroundImage && (

                <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem" }}>
                <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 3,
                    gap: 4, // Space between image and right-side content
                }}
                >
                {/* Left Section: Placeholder or Logo */}
                <Box
                    sx={{
                    width: 200,
                    height: 350,
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f0f0f0",
                    }}
                >
                    {loadedFormData.backgroundImageAttachmentId > 0 ? (
                    <Box
                        sx={{
                        width: 200,
                        height: 350,
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        overflow: "hidden",
                        }}
                    >
                        <img
                        src={backgroundUrl} // Ensure this is dynamically set
                        alt="Uploaded Logo"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                        />
                    </Box>
                    ) : (
                    <Box
                        sx={{
                        width: 200,
                        height: 350,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "2px dashed #ccc",
                        borderRadius: "8px",
                        backgroundColor: "#f9f9f9",
                        textAlign: "center",
                        }}
                    >
                        <Typography variant="subtitle1" color="black">
                            Upload Background
                        </Typography>
                    </Box>
                    )}
                </Box>

                {/* Right Section */}
                <Box>
                    {/* Button Row */}
                    <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2, // Space between buttons
                        marginBottom: 1, // Add spacing below the button row
                    }}
                    >
                    {/* Select File Button */}
                    {loadedFormData.backgroundImageAttachmentId == 0 && (
                    <Button variant="contained" component="label"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontWeight: "bold",
                            ml: 2,
                            maxWidth: 180,
                            "&:hover": {
                            color: theme.palette.primary.main,
                            backgroundColor: theme.palette.secondary.light,
                            fontWeight: "bold",
                            },
                            // Style for file input button
                            "& > input[type=file]": {
                            display: "none", // Hide the input element
                            },
                        }}
                        onClick={() => {
                            // Trigger click on the hidden file input element
                            // document.getElementById("attachmentUploadLogo").click();
                        }}
                        >
                        Browse File
                        <input
                            type="file"
                            id="attachmentUploadBackground"
                            onChange={onFileChangeBackgrounImage}
                            style={{ display: 'none' }}
                            hidden
                            accept="*/*" // Change MIME type based on allowed files, e.g., 'image/*'
                        />
                    </Button>
                    
                    )}
                    {loadedFormData.backgroundImageAttachmentId == 0 && (
                        <Button
                            variant="outlined"
                            color="success"
                            onClick={uploadBackgroundImageAttachment}
                            >
                            Upload Background
                        </Button>
                    
                    )} 
                    {/* Delete Button */}
                    {loadedFormData.backgroundImageAttachmentId > 0 && (
                        <Button
                        variant="outlined"
                        color="error"
                        onClick={deleteBackgroundAttachment}
                        >
                        Delete Background
                        </Button>
                    )} 
                    </Box>

                    {/* Recommendations */}
                    <Typography variant="body2" color="textSecondary">
                        We recommend to check what resolution you are showen on your tablet when you login to the admin section
                    </Typography>

                    {/* Download Template */}
                    <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1, color: theme.palette.secondary.light }}>
                    Template:{" "}
                    <Link
                        href="/template_welcome_backround.psd"
                        download
                        sx={{ marginTop: 1, color: theme.palette.secondary.light }}
                        underline="hover"
                        target="_blank"
                    >
                        PSD
                    </Link>
                    </Typography>
                </Box>
                </Box>
                </Box>

            )}
            </Box>
        </Box>

        {/*  -------------------------------   */}

        <Box sx={{ ...commonStyles, border: 1 }}>
            
            <Box display="flex" alignItems="center">
                <ListItemIcon>
                    <FormatColorFill fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
                </ListItemIcon>
                <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                    <Box display="flex" flexDirection="row" alignItems="center">
    
                        
                        <Typography
                        variant="h3"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        >
                        Background Color
                        </Typography>
                        {globalType && (
                        <Tooltip title={
                            <Typography variant="body1" style={{ fontSize: '16px' }}>
                            Global Visitor Type
                            </Typography>
                        }>
                            
                        </Tooltip>
                        )}
                    </Box>
    
                    <Typography variant="h5" color={theme.palette.secondary[300]}>
                        Choose a color which you want to use as the background color. We usually recommend a dark gray to have a good contrast to your accent color.
                    </Typography>
                    </Box>
                    
                    <Box ml="auto">    
                    {!showBackGroundColorConf && (
                        <Button
                            variant="contained"
                            onClick={toggleBackGroundColorConf}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.dark },
                            }}
                        >
                            {"EDIT"}
                        </Button>
                    )}
                
                {showBackGroundColorConf && (
                <Button
                            variant="contained"
                            onClick={handleSubmit} 
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                        {"SAVE"}
                    </Button>
                )}
    
                    {showBackGroundColorConf && (
                    <Button
                            variant="contained"
                            onClick={toggleBackGroundColorConf}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                            <Close />
                        </Button>
                    )}
                </Box>
            </Box>
            {/*     */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
    
                {showBackGroundColorConf && (
    
                    <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem"}}>
                        <Box position="relative" sx={{ ml: 10, mb: 3 }}>
                            
                            <Button
                                variant="contained"
                                onClick={handleBackgroundColorClick}
                                sx={{
                                backgroundColor: backgroundColor,
                                color: theme.palette.background.alt,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                m: "0rem 1rem 0rem 0rem",
                                "&:hover": { color: theme.palette.secondary.light },
                                }}
                            >
                                {"COLOR"}
                            </Button>
                        
                            {displayBackgroundColorPicker && (
    
                                <div style={ popover }>
                                <div style={ cover } onClick={ handleBackgroundColorClose }/>
                                <SketchPicker color={ loadedFormData.backgroundColor} onChange={ handleBackgroundColorChange } disableAlpha />
                                </div> 
                            )}
                    
                        </Box>
                    </Box>
                )}
                </Box>
    
            </Box>
            {/*  -------------------------------   */}

            <Box sx={{ ...commonStyles, border: 1 }}>
            
            <Box display="flex" alignItems="center">
                <ListItemIcon>
                    <FormatColorText fontSize="large"  sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
                </ListItemIcon>
                <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                    <Box display="flex" flexDirection="row" alignItems="center">
    
                        
                        <Typography
                        variant="h3"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        >
                        Font color
                        </Typography>
                        {globalType && (
                        <Tooltip title={
                            <Typography variant="body1" style={{ fontSize: '16px' }}>
                            Global Visitor Type
                            </Typography>
                        }>
                            
                        </Tooltip>
                        )}
                    </Box>
    
                    <Typography variant="h5" color={theme.palette.secondary[300]}>
                        Choose a color which we will use for the font.
                    </Typography>
                    </Box>
                    
                    <Box ml="auto">
    
    
                    {!showFontColorConf && (
                        <Button
                            variant="contained"
                            onClick={toggleFontColorConf}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.dark },
                            }}
                        >
                            {"EDIT"}
                        </Button>
                    )}
                
                {showFontColorConf && (
                <Button
                            variant="contained"
                            onClick={handleSubmit} 
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                        {"SAVE"}
                    </Button>
                )}
    
                    {showFontColorConf && (
                    <Button
                            variant="contained"
                            onClick={toggleFontColorConf}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                            <Close />
                        </Button>
                    )}
                </Box>
            </Box>
            {/*     */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
    
                {showFontColorConf && (
    
            <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem"}}>
               
                    <Box position="relative" sx={{ ml: 10, mb: 3 }}>
                            
                            <Button
                                variant="contained"
                                onClick={handleFontColorClick}
                                sx={{
                                backgroundColor: fontColor,
                                color: theme.palette.background.alt,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                m: "0rem 1rem 0rem 0rem",
                                "&:hover": { color: theme.palette.secondary.light },
                                }}
                            >
                                {"COLOR"}
                            </Button>
                        
                            {displayFontColorPicker && (

                                <div style={ popover }>
                                <div style={ cover } onClick={ handleFontColorClose }/>
                                <SketchPicker color={ loadedFormData.fontColor}  onChange={ handleFontColorChange } disableAlpha />
                                </div> 
                            )}
                    
                    </Box>
    
                </Box>
                
                )}
                </Box>
                
    
            </Box>

             {/*  -------------------------------   */}

             <Box sx={{ ...commonStyles, border: 1 }}>
            
            <Box display="flex" alignItems="center">
                <ListItemIcon>
                    <ColorLens fontSize="large"  sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
                </ListItemIcon>
                <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                    <Box display="flex" flexDirection="row" alignItems="center">
    
                        
                        <Typography
                        variant="h3"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        >
                        Accent color
                        </Typography>
                        {globalType && (
                        <Tooltip title={
                            <Typography variant="body1" style={{ fontSize: '16px' }}>
                            Global Visitor Type
                            </Typography>
                        }>
                            
                        </Tooltip>
                        )}
                    </Box>
    
                    <Typography variant="h5" color={theme.palette.secondary[300]}>
                        Choose a color which you want to use as main color. Usually something like your main corporate color or the main color of your logo.
                    </Typography>
                    </Box>
                    
                    <Box ml="auto">
    
    
                    {!showAccentColorConf && (
                        <Button
                            variant="contained"
                            onClick={toggleAccentColorConf}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.dark },
                            }}
                        >
                            {"EDIT"}
                        </Button>
                    )}
                
                {showAccentColorConf && (
                <Button
                            variant="contained"
                            onClick={handleSubmit} 
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                        {"SAVE"}
                    </Button>
                )}
    
                    {showAccentColorConf && (
                    <Button
                            variant="contained"
                            onClick={toggleAccentColorConf}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                            <Close />
                        </Button>
                    )}
                </Box>
            </Box>
            {/*     */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
    
                {showAccentColorConf && (
    
                <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem"}}>
                    <Box position="relative" sx={{ ml: 10, mb: 3 }}>
                            
                        <Button
                            variant="contained"
                            onClick={handleAccentColorClick}
                            sx={{
                            backgroundColor: accentColor,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                            {"COLOR"}
                        </Button>
                    
                        {displayAccentColorPicker && (

                            <div style={ popover }>
                            <div style={ cover } onClick={ handleAccentColorClose }/>
                            <SketchPicker color={ loadedFormData.mainColor} onChange={ handleAccentColorChange } disableAlpha />
                            </div> 
                        )}
                
                    </Box>
                </Box>
                )}
                </Box>
    
            </Box>

    </Box>
  );
};

export default WelcomeScreenDesign;
