import React, { useEffect, useState } from "react";
import { Box, Button, Link, Typography, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import {  getEmergencyListFinish } from "../../hooks/HttpRequests";
import {  useNavigate } from "react-router-dom";
import tm from "components/TranslationManager";
import { enqueueSnackbar } from "notistack";
import { useFrontDeskManager } from "components/FrontDeskManager";
import GridCellEmergencyStatus from "components/GridCellEmergencyStatus";
import { sadevioColors } from "components/Colors";
import SadevioDataGridPro from "components/SadevioDataGridPro";


const EmergencyList = ({ route }) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedFrontDesk, convertToFrontDeskTimeZoneDateTimeString } = useFrontDeskManager();

  const [topSearchFilter, setTopSearchFilter] = useState("");

  const theme = useTheme();

  /////////////// GRID /////////////
  // values to be sent to the backend

  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });



  
    useEffect(() => {
      setTriggerRefresh(!triggerRefresh)

    }, []);


    const handleFinish = (row) => {

      dispatch(showLoading(""));
  
      getEmergencyListFinish(navigate)(selectedFrontDesk.entityId,row.entityId)
      .then((result) => {
      // Use the result.data as needed
      if (result.success) {
      
          if(result.success){
              enqueueSnackbar({
                  message: "Emergency finished!",
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                });
          setTriggerRefresh((prev) => !prev);
          }else{
              
          }
          dispatch(hideLoading());
  
      }
      })
      .catch((error) => {
          dispatch(hideLoading());
  
      if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
          navigate("/");
          }
      }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
          message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
          
          });
      }
  
      });
    }

    const handleShowDetails = (row) => {

      navigate(`/emergencylist/${row.entityId}`);
    
    };
  

  const columns = [
    
    {
      field: "entityId",
      headerName: "Entity Id",
      flex: 1,
      type: 'number'
    },
    {
      field: "status",
      headerName: tm.translate('clmStatus', "Status"),
      minWidth: 220,
      renderCell: (params) => (
        <GridCellEmergencyStatus rowData={params.row} />
      ),
    },  
    {
      field: "totalInfo",
      headerName: "Visitors Info",
      flex: 1,
      renderCell: (params) => {
        const { totalConfirmed, totalVisitors } = params.row; // Ensure these keys exist in your row data
        return (
          <Link
              style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
              onClick={() => handleShowDetails(params.row)}

            >
          {`${totalConfirmed} / ${totalVisitors}`}
          </Link>
        );
      },
    },
    {
      field: "whenCreated",
      headerName: tm.translate('clmWhenCreated', "When Created") ,
      flex: "auto",
      minWidth: 150,
      renderCell: (params) => {
        const utcDateTime = params.value; // Assuming params.value contains the UTC timestamp
        // const localDateTime = dayjs.utc(utcDateTime).local().format('DD.MM.YYYY HH:mm'); // Convert UTC to local and format
        const localDateTime = convertToFrontDeskTimeZoneDateTimeString(utcDateTime)
        return <span>{localDateTime}</span>;
      }
    },
    {
      field: "whenFinished",
      headerName: tm.translate("clmWhenFinished", "When Finished"),
      flex: "auto",
      minWidth: 150,
      renderCell: (params) => {
        const utcDateTime = params.value;
        const localDateTime = utcDateTime ? convertToFrontDeskTimeZoneDateTimeString(utcDateTime) : "";
        return <span>{localDateTime}</span>;
      },
    },
    {
      field: "showDetails",
      headerName: "Show Emergency List",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="default"
          size="large"
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            "&:hover": { color: theme.palette.secondary.light },
          }}
          onClick={() => handleShowDetails(params.row)}
        >
          Show Details
        </Button>
      ),
    },
    {
      field: "finish",
      headerName: "Finish Emergency",
      width: 250,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          {/* Approve Button */}
          <Button
            variant="outlined"
            color="error"
            size="large"
            onClick={() => handleFinish(params.row)}
            disabled={params.row.status === "finished" || params.row.whenFinished}
            >
            Finish Emergency
          </Button>

        </Box>
      ),
    },
  ];


  const btnNewVisit = tm.translate('btnNewEmployee', 'New Employee');
  
  const buttons = [
    // { type:'search',label: "Search employees..." , onChange: handleSearchInputChange, iconName: ''},
    // { type:'button',label: btnNewVisit , onClick: () => { console.log("open"); 
    //           // openDrawer()
    //           navigate("/employees/new") 
    //           // setAddEmployeeOpen(true);
    //         }, iconName: ''},

  ];



  const rowTemplateMobile = (row) => {
 
    let powerIconColor;
    if (row.status == null) {
      powerIconColor = sadevioColors.hexColorKioskGray;
    } else if (row.status == "active") {
      powerIconColor = sadevioColors.hexColorKioskGreen;
    } else if (row.status == "finished") {
      powerIconColor = sadevioColors.hexColorKioskRed;
    } else {
      powerIconColor = sadevioColors.hexColorKioskGray;
    }
    const whenCreated = convertToFrontDeskTimeZoneDateTimeString(row.whenCreated)
    const whenFinished = row.whenFinished 
    ? convertToFrontDeskTimeZoneDateTimeString(row.whenFinished) 
    : "Not finished yet"; 
    const { totalConfirmed, totalVisitors } = row; // Ensure these keys exist in your row data

  
    return (
      <Box 
        display="flex" 
        alignItems="center" 
        justifyContent="space-between"
        padding="8px"
        borderRadius="8px"
      >
        {/* Left Side - Device Info */}
        <Box>
          <Typography variant="h6" style={{ fontWeight: "bold", color: powerIconColor }}>
            Status - {row.status}
          </Typography>  
          

          <Box display="flex" flexDirection="column">
            <Typography variant="caption" style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '14px' }}>
              When Created: {whenCreated}
            </Typography>

            <Typography variant="caption" style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '14px' }}>
              Total: {totalConfirmed} / {totalVisitors}
            </Typography>

            <Typography variant="caption" style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '14px' }}>
              When Finished: {whenFinished}
            </Typography>
          </Box>
        </Box>
  
        
      </Box>
    );
  };

  
  // Define row click event behavior
  const handleRowClick = (row) => {

    navigate(`/emergencylist/${row.entityId}`);


  };



  return (
    <Box m="1rem 2rem">
      <Header title="Emergency List" subtitle="All Emergency Lists" elements={buttons} />
        <SadevioDataGridPro columns ={columns} 
                    url={`frontdesk/${selectedFrontDesk.entityId}/emergencylist`} 
                    triggerRefresh= {triggerRefresh} 
                    mobileSupport={true} 
                    rowTemplateMobile={rowTemplateMobile} 
                    rowClickEvent={handleRowClick}
                    sortDataDESC={true}/>
    </Box>
  );
};

export default EmergencyList;