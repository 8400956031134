import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, FormControlLabel, useTheme } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import IOSSwitch from 'components/IOSSwitch';
import { hideLoading, showLoading } from 'state/loadingSlicer';
import { useDispatch } from 'react-redux';
import { getWorkflowEngineType, postWorkflowEngineType, putWorkflowEngineType } from 'hooks/HttpRequests';
import { useNavigate } from 'react-router-dom';
import { commonStyles } from 'components/StyledComponents';
import { enqueueSnackbar } from 'notistack';



const MeetingRoomConfig = ({ visitorType,frontDeskEntityId,initialValues, onSave }) => {

    const theme = useTheme();
    const {palette} = useTheme();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loadingComplete, setLoadingComplete] = useState(false);
    const [loadedFormData, setLoadedFormData] = useState(null);

    const validationSchema = yup.object().shape({
        allowBlank: yup.boolean(),
    });

    useEffect(() => {
        if(initialValues.entityId > 0){
            loadData(visitorType);
        }else{
            onAddWorkflowEngineClick(visitorType)
        }
    }, []);

    const handleSubmit = (values) => {

        // onSave(values); // Call the onSave function passed as a prop
        var submitJson = {}
        submitJson["saveToAll"] = false 
        submitJson["workflowType"] = initialValues.workflowType 
        submitJson["workflowTypeSub"] = visitorType 

        values["entityId"] = initialValues.entityId

        submitJson["data"] = values 

        console.log('Saving Take Picture Config:', submitJson);

        putWorkflowEngineType(navigate)(frontDeskEntityId, "meetingroom", submitJson)


            .then((result) => {
            // Use the result.data as needed
            
                if(result.success){

                    loadData(visitorType);

                    enqueueSnackbar({
                        message: "Entity was saved",
                          variant: 'success',
                          anchorOrigin: {
                            vertical: 'top', // 'top', 'bottom'
                            horizontal: 'center', // 'left', 'center', 'right'
                          },
                          autoHideDuration: 3000, 
                          // persist: true
                        
                      });

                }else{
                    enqueueSnackbar({
                        message: "Error "+result.message,
                            variant: 'error',
                            anchorOrigin: {
                            vertical: 'top', // 'top', 'bottom'
                            horizontal: 'center', // 'left', 'center', 'right'
                            },
                            autoHideDuration: 3000, 
                            // persist: true
                        
                        });
                }
    
            
            })
            .catch((error) => {
            dispatch(hideLoading());
    
            if (typeof error.action !== "undefined") {
                if (error.action == "relogin") {
                navigate("/");
                }
            }else if (error.hasOwnProperty('success')) {
                enqueueSnackbar({
                message: "Error "+error.message,
                    variant: 'error',
                    anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                
                });
            }
    
            });

    };

    const onAddWorkflowEngineClick = async (visitorType) => {
        
        
                var submitJSON = {}
        
                const data = {
                    entityId: "-1",
                    allowBlank: true,
                };
        
                submitJSON["data"] = data;
                submitJSON["saveToAll"] = false;
        
                const visitorTypeSubmit = {
                    visitorType: visitorType,
                };
        
                submitJSON["visitorType"] = visitorTypeSubmit;
        
                postWorkflowEngineType(navigate)(frontDeskEntityId, "meetingroom", submitJSON)
        
        
                    .then((result) => {
                    // Use the result.data as needed
                    if (result.success) {
                    
                        if(result.success){
                            //   setCopmanyName("- "+result.data.name);
                            setLoadedFormData(result.data)
                            loadData(visitorType)
                            onSave()
                            enqueueSnackbar({
                                message: "Entity was saved",
                                  variant: 'success',
                                  anchorOrigin: {
                                    vertical: 'top', // 'top', 'bottom'
                                    horizontal: 'center', // 'left', 'center', 'right'
                                  },
                                  autoHideDuration: 3000, 
                                  // persist: true
                                
                              });
        
                        }else{
                            enqueueSnackbar({
                                message: "Error "+result.message,
                                    variant: 'error',
                                    anchorOrigin: {
                                    vertical: 'top', // 'top', 'bottom'
                                    horizontal: 'center', // 'left', 'center', 'right'
                                    },
                                    autoHideDuration: 3000, 
                                    // persist: true
                                
                                });
                        }
            
                    }
                    })
                    .catch((error) => {
                    dispatch(hideLoading());
            
                    if (typeof error.action !== "undefined") {
                        if (error.action == "relogin") {
                        navigate("/");
                        }
                    }else if (error.hasOwnProperty('success')) {
                        enqueueSnackbar({
                        message: "Error "+error.message,
                            variant: 'error',
                            anchorOrigin: {
                            vertical: 'top', // 'top', 'bottom'
                            horizontal: 'center', // 'left', 'center', 'right'
                            },
                            autoHideDuration: 3000, 
                            // persist: true
                        
                        });
                    }
            
                    });
                // }
        
            }

    const loadData = async (visitorType) => {

        // setGridLoading(true)
        dispatch(showLoading());
        console.log(visitorType)

        getWorkflowEngineType(navigate)(frontDeskEntityId, initialValues.entityId)
        .then((result) => {

            // Use the result.data as needed
            if(result.success){
                var tmp = JSON.parse(result.data.configuration)
                setLoadedFormData(tmp)

            }
        setLoadingComplete(true)
            dispatch(hideLoading());


        })
        .catch((error) => {

                setLoadingComplete(true)
            dispatch(hideLoading());

        });

    }

    if (!loadingComplete || !loadedFormData) {
        return <div>Loading...</div>;
    }

    return (
        <Box sx={{ ...commonStyles,  border: 1 , p : 5}}>
        <Formik
            initialValues={loadedFormData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue}) => (
                <form onSubmit={handleSubmit}>
                    <Typography variant="h5" gutterBottom>
                        Meeting Configuration
                    </Typography>
                    <FormControlLabel
                        sx={{ mb: 2, gridColumn: "span 4", backgroundColor: 'transparent' }}
                        control={
                            <IOSSwitch
                            sx={{ m: 1 }}
                            checked={values.allowBlank}
                            onChange={(e) => setFieldValue("allowBlank", e.target.checked)}
                            />
                        }
                        label={<span style={{ color: theme.palette.secondary.light }}>Allow the meeting room field to be empty</span>}
                    />
                    <Button
                        fullWidth
                        type="submit"
                        sx={{
                            mt: 2,
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.dark,
                            },
                        }}
                    >
                        Save Changes
                    </Button>
                </form>
            )}
        </Formik>
        </Box>
    );
};

export default MeetingRoomConfig;
