import React, { useEffect, useState } from "react";
import { Box, CircularProgress, List, ListItem, ListItemText, useMediaQuery, useTheme } from "@mui/material";
import { useFrontDeskManager } from "./FrontDeskManager";
import { getListValuesPaging } from "hooks/HttpRequests";
import {  useNavigate } from "react-router-dom";
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { useInView } from "react-intersection-observer";
import { useCallback } from "react";

const SadevioDataGridPro = ({ columns, url, triggerRefresh, mobileSupport = false, rowTemplateMobile,  rowClickEvent, extraFilter = "", sortDataDESC = false}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const { selectedFrontDesk } = useFrontDeskManager();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [{ property: "entityId", direction: sortDataDESC ? "DESC" : "ASC" }],
    query:[]
  })

      /////////////// USE EFFECT /////////////

  useEffect(() => {
    loadGridData();
  }, [triggerRefresh]);


    useEffect(() => {
      // This code will run every time paginationModel changes
      loadGridData();
  
    }, [paginationModel]);
  

  const loadGridData = async () => {
  
    setGridLoading(true)

    var topSearchFilter = ""

    if(extraFilter.length > 0){
      topSearchFilter = "&extraFilter="+extraFilter
    }

    getListValuesPaging(navigate)(paginationModel, url, topSearchFilter)
      .then((result) => {

        setData(result)
        setGridLoading(false)

      })
      .catch((error) => {
        setGridLoading(false)
      });

  }

  

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton color="neutral" />
        <GridToolbarFilterButton  style={{ color: '#3f51b5' }}  />
      </GridToolbarContainer>
    );
  }


  const handleFilterModelChange = (filterModel) => {
    // Update the filter model state when it changes

    console.log("handleFilterModelChange()")

    // Convert to the desired structure
    const desiredStructure = filterModel.items.map(item => {
      let mappedOperator = item.operator;
    
      let field = columns.find(column => column.field === item.field);
      var fieldType = "string";

      if(typeof field.type !== 'undefined'){
        fieldType = field.type;
      }

      // Map operators as needed
      if (item.operator === 'equals') {
        mappedOperator = 'eq';
      } else if (item.operator === 'contains') {
        mappedOperator = 'like';
      } // Add more conditions as needed
    
      return {
        property: item.field,
        value: item.value,
        type: fieldType, // You might need to adjust this based on your data types
        operator: mappedOperator
      };
    });

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      query: desiredStructure,
      page: 0,
    }));

  };



  const handleSortModelChange = (sortModel) => {
    // Handle sorting changes and fetch data from the server
    //sort: [{"property":"lastname","direction":"ASC"}]

    console.log("handleSortModelChange()")

    const sortObject = sortModel.map(item => ({
      property: item.field,
      direction: item.sort.toUpperCase() // Assuming you want direction in uppercase (ASC or DESC)
    }));

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      sort: sortObject,
      page: 0,
    }));

      console.log(paginationModel)
  };

  const handlePageChange = (newPage) => {
    // Handle page changes and fetch data from the server

    console.log("handlePageChange()")

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPage.page,
      limit: newPage.pageSize,
    }));

  };

  if (mobileSupport && !isNonMobile) {
    return <InfiniteScrollList columns={columns} url={url} triggerRefresh={triggerRefresh} rowTemplateMobile={rowTemplateMobile} rowClickEvent={rowClickEvent} extraFilter={extraFilter} sortDataDESC={sortDataDESC}/>;
  }

  return (
     <Box
        mt="0px"
        height="80vh"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },          
        }}
      >
        <DataGridPro
          loading={gridLoading || !data}
          disableVirtualization={true} 
          initialState={{
            pagination: { paginationModel: { pageSize: paginationModel.limit } },
          }}
          // getRowId={(row) => row.entityId}
          getRowId={(row) => `${row.entityId}`}
          rowHeight={80} // Set the row height (e.g., 60px)
          rows={data?.data || []}
          columns={columns}
          rowCount={(data && data.total_count) || 0}
          rowsPerPageOptions={[25, 50, 100]}
          columnVisibilityModel={columnVisibilityModel}
          // onColumnVisibilityChange={handleColumnVisibilityChange}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }

          checkboxSelection

          filterMode="server"
          onFilterModelChange={handleFilterModelChange}

          sortingMode="server"
          onSortModelChange={handleSortModelChange}

          paginationMode="server"
          pagination
          page={paginationModel.page}
          pageSize={paginationModel.limit}
          onPaginationModelChange={(paginationModel) => handlePageChange(paginationModel)} //setPageSize(paginationModel.pageSize)

          slots={{ toolbar: CustomToolbar ,loadingOverlay: () => (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ), }}
          slotProps={{
            filterPanel: {
              // Force usage of "And" operator
              logicOperators: [],
              // Display columns by ascending alphabetical order
              columnsSort: 'asc',
              
              sx: {
                '& .MuiButtonBase-root': { color: `${theme.palette.secondary[200]} !important` },
                '& .MuiFormLabel-root': { color: `${theme.palette.secondary[200]} !important` },
              },
            },
          }}
        />
      </Box>
  );
};

export default SadevioDataGridPro;


const InfiniteScrollList = ({ columns, url, triggerRefresh, rowTemplateMobile, rowClickEvent, extraFilter, sortDataDESC }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { ref, inView } = useInView({ threshold: 1 });

  const isMobile = window.innerWidth < 768; // Detect mobile

  const formatCellValue = (col, row) => {
    const value = row[col.field];

    if (typeof value === "object" && value !== null) {
      return JSON.stringify(value); // Fallback for objects
    }

    return value ?? "N/A";
  };

  const loadMore = useCallback(async () => {
    if (!loading && hasMore) {
      setLoading(true);
      try {
        const paginationModel = {
          page,
          limit: 25,
          sort: [{ property: "entityId", direction:  sortDataDESC ? "DESC" : "ASC"  }],
          query: [],
        };

        var topSearchFilter = ""

        if(extraFilter.length > 0){
          topSearchFilter = "&extraFilter="+extraFilter
        }
        const result = await getListValuesPaging(navigate)(paginationModel, url, topSearchFilter);

        if (result?.data?.length === 0) {
          setHasMore(false);
        }

        setData((prev) => [...prev, ...(result?.data || [])]);
        setPage((prev) => prev + 1);
      } catch (error) {
        console.error("Error loading more data", error);
      } finally {
        setLoading(false);
      }
    }
  }, [page, loading, hasMore, navigate, url]);

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView]);

  return (
    <List>
      {data.length === 0 && !loading && (
        <Box textAlign="center" py={3} color="gray">
          No records found.
        </Box>
      )}

      {data.map((item, index) => (
        <ListItem
          key={item.entityId}
          button
          onClick={() => rowClickEvent ? rowClickEvent(item) : alert(`Clicked on ${item.deviceName}`)}
          sx={{
            backgroundColor: index % 2 === 0 ? theme.palette.grey[900] : theme.palette.grey[800],
            borderRadius: "8px",
            margin: "4px 0",
          }}
        >
          <ListItemText
            primary={isMobile && rowTemplateMobile ? rowTemplateMobile(item) : (
              columns.map((col) => `${col.headerName}: ${formatCellValue(col, item)}`).join(" • ")
            )}
          />
        </ListItem>
      ))}

      {hasMore && (
        <Box ref={ref} display="flex" justifyContent="center" py={2}>
          {loading && <CircularProgress />}
        </Box>
      )}
    </List>
  );
};
