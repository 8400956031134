import {  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import tm from 'components/TranslationManager';


const DialogPassportScannerDetailPicture = ({ open, onClose, onSave, image }) => {

  const { palette } = useTheme();
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  ////////////////////////////////
  


  useEffect(() => {
    

  }, [open]);

  const handleReset = () => {

    // setSearchResults([]);
    // setSearchQuery("");

  }

  const handleClose = () => {
      onClose();
  }

  const handleSave = () => {

      onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}></DialogTitle>
        <DialogContent>

        <Box
            border={1}
            borderColor="grey.500"
            width={542}
            height={372}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          >
            <img
              src={image || "/no_picture.png"}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Box>

          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {/* Are you sure you want to execute this action? */}
        
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}> 
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>

          {/* Left section: IconButton and Reader Status */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {/* <Button onClick={handleSave} color="primary" autoFocus sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
                {tm.translate('btnRest', "Rest Device")}
              </Button> */}
       
          </Box>

          {/* Right section: Other buttons */}
          <Box>
            <div>
              <Button onClick={handleSave} color="primary" autoFocus sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
                {tm.translate('btnOk', "Ok")}
              </Button>
            </div>
          </Box>
        </Box>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogPassportScannerDetailPicture