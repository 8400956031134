import React, { useEffect, useState } from "react";
import {  Box, Typography, useTheme } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Header from "components/Header";
import { useDispatch } from "react-redux";
import DialogYesNo from "components/dialog/DialogYesNo";
import {  getVisitorTypes } from "../../../hooks/HttpRequests";
import {   useLocation, useNavigate } from "react-router-dom";
import InvitationGeneral from "./invitation/invitationGeneral";
import InvitationEmailAttachments from "./invitation/invitationEmailAttachments";


function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const InvitationScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [visitorTypes, updateVisitorTypes] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);

  useEffect(() => {
    loadVisitorTypes();
  }, []);

  const loadVisitorTypes = async () => {
    setGridLoading(true);

    try {
      const result = await getVisitorTypes(1);
      if (result.success) {
        updateVisitorTypes(result.data);
      }
    } catch (error) {
      if (typeof error.action !== 'undefined' && error.action === 'relogin') {
        navigate('/');
      }
    } finally {
      setGridLoading(false);
    }
  };

  const tabs = [
    { label: 'General', path: '/general' , id: "tabGeneral"},
    { label: 'Email Attachment', path: '/attachments' , id: "tabEmailAttachment"},
  ];

  const currentPath = location.pathname;
  const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
  const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;

  const [value, setValue] = useState(selectedTabIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTab = tabs[newValue];
    navigate(`/visit/settings/invitation${selectedTab.path}`);
  };

  useEffect(() => {
    const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
    const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;
    setValue(selectedTabIndex);
  }, [currentPath, tabs]);

  return (
    <Box m="1rem 2rem">
      <Header title="Invitation Config" subtitle="" elements={null} backbutton />
      <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={() => setConfirmationOpen(false)}
        theme={theme}
      />
      <Box mt="10px" height="85vh" sx={{
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiDataGrid-panel .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
         "& .MuiDataGrid-panelWrapper .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiTab-root.MuiTab-textColorPrimary.Mui-selected": {
            color: `${theme.palette.secondary[200]} !important`,
            fontWeight: 'bold'
          },
          
        }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>

            {tab.id === 'tabGeneral' && (
              // <Typography>This is the Sign-In Workflow tab content</Typography>
              
              <InvitationGeneral
                description="Description"
                globalType={true}
                navigate={navigate}
                id={3}
                // theme={theme}
              />

            )}
            {tab.id === 'tabEmailAttachment' && (
              // <Typography>This is the Security tab content</Typography>
              <InvitationEmailAttachments
                description="Description"
                globalType={true}
                navigate={navigate}
                id={3}
                // theme={theme}
              />
            )}
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default InvitationScreen;

