import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  Avatar,
  Divider,
  Button,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHostVisitHistory, getSessionToken, getUri } from "hooks/HttpRequests";
import { getVisitStatusColor, getVisitStatusLabel } from "components/Utils";
import { stringAvatar } from "components/StyledComponents";
import { useFrontDeskManager } from "components/FrontDeskManager";

const EmployeeHistory = ({ globalType, id }) => {

  const { palette } = useTheme();
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

    const { convertToFrontDeskTimeZoneDateTimeString } = useFrontDeskManager();
  
  const [data, setData] = useState([]);
  const [gridLoading, setGridLoading] = useState(true);

  useEffect(() => {
    loadGridData();
  }, []);

  const loadGridData = async () => {

    setGridLoading(true);

    try {
      const result = await getHostVisitHistory(navigate)(id);
      if (result.success) {
        setData(result.data || []);
      }
    } catch (error) {
      console.error("Error loading visitor history:", error);
    } finally {
      setGridLoading(false);

    }
  };

  return (
    <Box m="0rem 0rem">
      <Box mt="0px" height="80vh">
        {gridLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress size={50} />
          </Box>
        ) : data.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <Typography variant="h6" color="textSecondary">
              No visits available.
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              overflowY: "auto",
              scrollbarWidth: "thin",
              backgroundColor: theme.palette.background.alt,
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.light,
                borderRadius: "6px",
              },
            }}
          >
            {data.map((visit, index) => (
              <Card key={visit.visitId || index} sx={{ mb: 2, borderRadius: "8px", p: 2, backgroundColor: theme.palette.background.alt, }}>
                {/* HEADER SECTION */}
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h6" fontWeight="bold">
                      Front Desk: {visit.frontDeskId}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Valid From:</strong> {convertToFrontDeskTimeZoneDateTimeString(visit.validFrom)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Valid To:</strong> {convertToFrontDeskTimeZoneDateTimeString(visit.validTo)}
                    </Typography>
                    <Typography variant="body2" color={getVisitStatusColor(visit.status)}>
                      <strong>Status:</strong> {getVisitStatusLabel(visit.status)}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Button
                        fullWidth
                        onClick={() => navigate(`/visit/${visit.visitId}`)}
                        // type="submit"
                        sx={{
                            p: "1rem",
                            backgroundColor: palette.secondary.light,
                            color: palette.background.alt,
                            fontWeight: "bold",
                            mb: 1,
                            ".MuiButton-startIcon": {
                              // color: sadevioColors.hexColorCheckedIn,
                            },
                            "&:hover": {
                            color: palette.primary.main,
                            backgroundColor: palette.secondary.light,
                            fontWeight: "bold",
                            },
                        }}
                        >
                      View Visit Details
                    </Button>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 2 }} />

                {/* VISITORS SECTION */}
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Visitors
                </Typography>

                {visit.visitors?.length > 0 ? (
                  visit.visitors.map((visitor) => (
                    <Box key={visitor.visitorId} display="flex" alignItems="center" gap={2} mb={1}>
                      {visitor.picturePath != null ? (
                          <Avatar {...stringAvatar(visitor.fullName)} alt={visitor.fullName} src={`${getUri(getSessionToken())}/visitor/${visitor.visitorId}/picture/${visitor.picturePath}`}          />
                
                        ) : (
                          <Avatar {...stringAvatar(visitor.fullName)} />
                        )}
                      <Box>
                        <Typography variant="body1">{visitor.fullName}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {visitor.companyName || "No company"} | {visitor.mobile || "No phone"}
                        </Typography>
                        <Typography variant="body2" color="secondary">
                          <strong>Type:</strong> {visitor.visitorType || "Not specified"}
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No visitors in this visit
                  </Typography>
                )}

                <Divider sx={{ my: 2 }} />

                {/* HOSTS SECTION */}
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Hosts
                </Typography>

                {visit.hosts?.length > 0 ? (
                  visit.hosts.map((host) => (
                    <Box key={host.entityId} display="flex" alignItems="center" gap={2} mb={1}>
                      {host.picturePath != null ? (
                          <Avatar {...stringAvatar(host.fullName)} alt={host.fullName} src={`${getUri(getSessionToken())}/host/${host.entityId}/picture/${host.picturePath}`}          />
                
                        ) : (
                          <Avatar {...stringAvatar(host.fullName)} />
                        )}
                      <Box>
                        <Typography variant="body1">{host.fullName}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {host.email || "No email"} | {host.phone || "No phone"}
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No hosts assigned to this visit
                  </Typography>
                )}
              </Card>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EmployeeHistory;
