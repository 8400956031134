import { sadevioColors } from './Colors';
import tm from 'components/TranslationManager';


export const transformApiKeyToCamelCase = (apiKey) => {
  return transformApiKeyToCamelCaseUpperCase(apiKey, false)
}

export const transformApiKeyToCamelCaseUpperCase = (apiKey, upperCase) => {
  // Handle custom fields
  if (/^visitor_c_\d+$/.test(apiKey)) {
    return apiKey.replace(/^visitor_/, ""); // Remove "visitor_" prefix
  }

  // Remove the prefix (e.g., "visitor_") and convert to camelCase
  const withoutPrefix = apiKey.replace(/^visitor_/, "");
  var valueString = withoutPrefix.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
  if(upperCase){
    return valueString.charAt(0).toUpperCase() + valueString.slice(1);
  }else{
    return valueString;
  }
};


export const getVisitorStatusColor = (status) => {
  switch (status) {
    case "checked_in":
      return { color: sadevioColors.hexColorCheckedIn, fontWeight: 'bold' };
    case "checked_out":
      return { color: sadevioColors.hexColorCheckedOut, fontWeight: 'bold' };
    case "pre_registered":
      return { color: sadevioColors.hexColorInvited , fontWeight: 'bold'};
    case "pre_checked_in":
      return { color: sadevioColors.hexColorWaiting , fontWeight: 'bold'};
    case "confirmed":
      return { color: sadevioColors.hexColorConfirmed , fontWeight: 'bold'};
    case "rejected":
      return { color: sadevioColors.hexColorRejected , fontWeight: 'bold'};
    case "denied":
      return { color: sadevioColors.hexColorDenied , fontWeight: 'bold'}
    case "under_review":
      return { color: sadevioColors.hexColorUnderReview , fontWeight: 'bold'}
    default:
      return { color: 'white' };
  }
};

export const getVisitorStatusLabel = (status) => {
  switch (status) {
    case "checked_in":
      return tm.translate("lblCheckedIn", "Checked-in");
    case "checked_out":
      return tm.translate("lblCheckedOut", "Checked-out");
    case "pre_registered":
      return tm.translate("lblPreRegistered", "Invited");
    case "pre_checked_in":
      return tm.translate("lblPreCheckedIn", "Waiting");
    case "confirmed":
      return tm.translate("lblConfirmed", "Confirmed");
    case "rejected":
      return tm.translate("lblRejected", "Rejected");
    case "denied":
      return tm.translate("lblDenied", "Denied");
    case "under_review":
      return tm.translate("lblUnderReview", "Under Review");
    default:
      return 'NA';
  }
};

export const getVisitStatusColor = (status) => {
  switch (status) {
    case 'pre_registered':
      return sadevioColors.hexColorVisitInvited;
    case 'started':
      return sadevioColors.hexColorVisitStarted;
    case 'finished':
      return sadevioColors.hexColorVisitFinished;
    case 'canceled':
      return sadevioColors.hexColorVisitCanceled;
    case 'no_show':
      return sadevioColors.hexColorVisitNoShow;
    case 'expired':
      return sadevioColors.hexColorVisitExpired;
    case 'rejected':
      return sadevioColors.hexColorRejected
    case 'denied':
      return sadevioColors.hexColorDenied
    default:
      return { color: 'white' };
  }
};

export const getVisitStatusLabel = (status) => {

  switch (status) {
    case "pre_registered":
      return tm.translate('lblInvited', "Invited"); 
      break;
    case "started":
      return tm.translate('lblStarted', "Started");
      break;
    case "finished":
      return tm.translate('lblFinished', "Finished");
      break;
    case "canceled":
      return tm.translate('lblCanceled', "Canceld");
      break;
    case "no_show":
      return tm.translate('lblVisitNoShow', "No Show");
      break;
    case "expired":
      return tm.translate('lblVisitExpired', "expired");
      break;
    case "rejected":
      return tm.translate('lblRejected', "Rejected");
      break;
    case "denied":
      return tm.translate('lblDenied', "Denied");
      break;
    default:
      return "Unknown Status";
      break;
  }
};

export const getEmergencyListStatusColor = (status) => {
  switch (status) {
    case "active":
      return { color: sadevioColors.hexColorCheckedIn, fontWeight: 'bold' };
    case "finished":
      return { color: sadevioColors.hexColorCheckedOut, fontWeight: 'bold' };
    default:
      return { color: 'white' };
  }
};