import React, { useEffect, useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Grid
} from "@mui/material";

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { getHostVisitDashboard } from "hooks/HttpRequests";
import tm from "components/TranslationManager";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useUserProfileManager } from "components/UserProfileProvider";
import VisitorStatistics from "./VisitorStatistics";
import { Oval } from "react-loader-spinner";

const Dashboard = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery("(min-width: 1200px)");
  const isMediumScreen = useMediaQuery("(min-width: 900px)");
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const navigate = useNavigate();
  const { selectedFrontDesk } = useFrontDeskManager();
  const { userProfile, userProfleLoading, hasPermission } = useUserProfileManager();

  useEffect(() => {
    loadDashboardVisitorsStatistics();
  }, [selectedFrontDesk]);

  const [paginationModel, setPaginationModel] = useState({
      page: 0,
      limit: 25,
      sort: [],
      query:[]
    })

  const [upcomingVisits, setUpcomingVisits] = useState([]);
  const [upcomingVisitsLoading, setUpcomingVisitsLoading] = useState(false);

  const [currentVisits, setCurrentVisits] = useState([]);
  const [currentVisitsLoading, setCurrentVisitsLoading] = useState(false);

  const [pastVisits, setPastVisits] = useState([]);
  const [pastVisitsLoading, setPastVisitsLoading] = useState([]);

  const loadDashboardVisitorsStatistics = async () => {
    setUpcomingVisitsLoading(true)
    getHostVisitDashboard(navigate)("upcomnig", selectedFrontDesk.entityId)
      .then((result) => {
        if(result.success) setUpcomingVisits(result.data);
        setUpcomingVisitsLoading(false)

      }).catch((error) => { console.error(error);
          setUpcomingVisitsLoading(false)
      });

    setCurrentVisitsLoading(true)
    getHostVisitDashboard(navigate)("current", selectedFrontDesk.entityId)
      .then((result) => {
        if(result.success) setCurrentVisits(result.data);
        setCurrentVisitsLoading(false)
      }).catch((error) => { console.error(error);
        setCurrentVisitsLoading(false)

       });

       setPastVisitsLoading(true)
    getHostVisitDashboard(navigate)("past", selectedFrontDesk.entityId, paginationModel)
      .then((result) => {
        if(result.success) setPastVisits(result.data);
        setPastVisitsLoading(false)
      }).catch((error) => { console.error(error) 
        setPastVisitsLoading(false)
      });
  };

  if (!selectedFrontDesk) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="1.5rem 2.5rem">
      {isNonMobile ? (
        <FlexBetween>
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        <Box
          display="flex"
          flexDirection={isNonMobile ? "row" : "column"}
          gap={2} // Adds spacing between buttons
        >
          { hasPermission("system.visit.new") && (
            <Button
              onClick={() => navigate("/visit/-1")}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                flex: isNonMobile ? "1" : "unset", // Makes buttons equal width in row layout
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 0rem 0rem 0.75rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              <GroupAddIcon sx={{ mr: "10px" }} />
              {tm.translate('btnNewVisit', 'New Visit')}
            </Button>
          )}
          <Button
            onClick={() => navigate("/visit/new/invitation")}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              flex: isNonMobile ? "1" : "unset", // Makes buttons equal width in row layout
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              m: "0rem 0rem 0rem 0.75rem",
              "&:hover": { color: theme.palette.secondary.dark },
            }}
          >
            <GroupAddIcon sx={{ mr: "10px" }} />
            {tm.translate('btnNewInvitation', 'New Invitation')}
          </Button>
        </Box>
      </FlexBetween>
      ) : (
        <>  
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        <Box
          display="flex"
          flexDirection={isNonMobile ? "row" : "column"}
          gap={2} // Adds spacing between buttons
        >
          { hasPermission("system.visit.new") && (
            <Button
              onClick={() => navigate("/visit/-1")}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                flex: isNonMobile ? "1" : "unset", // Makes buttons equal width in row layout
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "1rem 0rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              <GroupAddIcon sx={{ mr: "10px" }} />
              {tm.translate('btnNewVisit', 'New Visit')}
            </Button>
          )}
          <Button
            onClick={() => navigate("/visit/new/invitation")}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              flex: isNonMobile ? "1" : "unset", // Makes buttons equal width in row layout
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              m: "1rem 0rem 0rem 0rem",
              "&:hover": { color: theme.palette.secondary.dark },
            }}
          >
            <GroupAddIcon sx={{ mr: "10px" }} />
            {tm.translate('btnNewInvitation', 'New Invitation')}
          </Button>
        </Box>
       </ >

      )}

      {/* Responsive Grid for Visitor Statistics */}
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6} md={4}>
          {
            upcomingVisitsLoading ? (
              <Box sx={{ width: "100%", height: "100%", padding: "20px", display: "flex", justifyContent: "center" }}>
              <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#ffedc2"
                  secondaryColor="#ffedc2"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </Box>
            ):(
              <VisitorStatistics upcomingVisits={upcomingVisits} title="Upcoming Visits" />
            )
          }
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        {
            currentVisitsLoading ? (
              <Box sx={{ width: "100%", height: "100%", padding: "20px", display: "flex", justifyContent: "center" }}>
              <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#ffedc2"
                  secondaryColor="#ffedc2"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </Box>
            ):(
          <VisitorStatistics upcomingVisits={currentVisits} title="Current Visits" />
            )
          }
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        {
            pastVisitsLoading ? (
              <Box sx={{ width: "100%", height: "100%", padding: "20px", display: "flex", justifyContent: "center" }}>
              <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#ffedc2"
                  secondaryColor="#ffedc2"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </Box>
            ):(
          <VisitorStatistics upcomingVisits={pastVisits} title="Past Visits" />
            )
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
