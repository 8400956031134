import React, { useState, useEffect } from 'react';
import { MenuItem, Divider, Button, Menu } from '@mui/material';
import { FlightLand, FlightTakeoff, KeyboardArrowDown, Print } from '@mui/icons-material';
import { sadevioColors } from './Colors';
import { useTheme } from '@emotion/react';
import tm from 'components/TranslationManager';

const MenuItemsVisitorBulkActions = ({ visitStatus, handleBulkCheckIn, handleBulkCheckOut, handleBulkPrintBadge, showBuldActionVisitorButton = false }) => {

  const { palette } = useTheme();
  const theme = useTheme();

  const [anchorElVisitorBulkAction, setAnchorElVisitorBuldAction] = useState(null);
  const openMenuVisitorBulkAction = Boolean(anchorElVisitorBulkAction);

  const handleClickBulkActionsButton = (event) => {
    setAnchorElVisitorBuldAction(event.currentTarget);
  };

  const handleCloseBulkActionsButton = () => {
    setAnchorElVisitorBuldAction(null);
  };
  
  const [visitStatusMenuCheckIn, setVisitStatusMenuCheckIn] = useState(false);
  const [visitStatusMenuCheckOut, setVisitStatusMenuCheckOut] = useState(false);
  const [visitStatusMenuPrintBadge, setVisitStatusMenuPrintBadge] = useState(false);

  useEffect(() => {
    switch (visitStatus) {
     
    }
  }, [visitStatus]);

  useEffect(() => {
    // Update the state based on the prop
    if (showBuldActionVisitorButton) {
      // Show the button
      // You can also set other states if needed
    } else {
      // Hide the button
      // You can also set other states if needed
    }
  }, [showBuldActionVisitorButton]);

  return (
    <div>
      {/* {shouldShowButton && ( */}
                            <Button
                              id="demo-customized-button"
                              aria-controls={openMenuVisitorBulkAction ? 'demo-customized-menu' : undefined}
                              aria-haspopup="true"
                              hidden
                              aria-expanded={openMenuVisitorBulkAction ? 'true' : undefined}
                              variant="contained"
                              disableElevation
                              onClick={handleClickBulkActionsButton}
                              endIcon={<KeyboardArrowDown />}
                              sx={{
                                backgroundColor: theme.palette.secondary.ligh,
                                color: theme.palette.secondary.ligh,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                "&:hover": {
                                color: palette.primary.main,
                                backgroundColor: palette.secondary.light,
                                fontWeight: "bold",
                                },
                                opacity: showBuldActionVisitorButton ? 1 : 0, // Apply opacity based on condition
                                transition: 'opacity 0.3s ease-in-out', // Add transition effect
                                pointerEvents: showBuldActionVisitorButton ? 'auto' : 'none', // Enable/disable pointer events based on condition
                            }}
                            >
                              {tm.translate('btnBulkAction', "Bulk Actions")}
                            </Button>
                            {/* )} */}
                            <Menu
                              id="demo-customized-menu"
                              MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                              }}
                              anchorEl={anchorElVisitorBulkAction}
                              open={openMenuVisitorBulkAction}
                              onClose={handleCloseBulkActionsButton}
                              sx={
                                {
                                  '& .MuiMenu-list': {
                                    padding: '4px 0',
                                    backgroundColor: palette.secondary.light,
                                  },
                                  '& .MuiDivider-root': {
                                    backgroundColor:palette.primary.main,
                                    color: palette.primary.main,
                                  },
                                  '& .MuiMenuItem-root': {
                                     backgroundColor: palette.secondary.light,
                                     color: palette.primary.main,
                                    '& .MuiSvgIcon-root': {
                                      fontSize: 18,
                                      color: palette.primary.main,
                                      backgroundColor: palette.secondary.light,
                                      marginRight: 2,
                                    },
                                    '&:active': {
                                      // backgroundColor: alpha(
                                      //   theme.palette.primary.main,
                                      //   theme.palette.action.selectedOpacity,
                                      // ),
                                    },
                                  },
                                }
                              }
                            >
      <MenuItem disabled={visitStatusMenuCheckIn} onClick={() => {
            setAnchorElVisitorBuldAction(null); // Close the menu
            handleBulkCheckIn(); // Perform the check-out action
          }}
          disableRipple>
        <FlightLand style={{ color: sadevioColors.hexColorCheckedIn }}/> 
        {tm.translate('btnCheckIn', "Check-In")}
      </MenuItem>
      <MenuItem disabled={visitStatusMenuCheckOut} onClick={() => {
            setAnchorElVisitorBuldAction(null); // Close the menu
            handleBulkCheckOut(); // Perform the check-out action
          }} 
          disableRipple>
        <FlightTakeoff style={{ color: sadevioColors.hexColorCheckedOut }}/> 
        {tm.translate('btnCheckOut', "Check-Out")}
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem disabled={visitStatusMenuPrintBadge} onClick={() => {
            setAnchorElVisitorBuldAction(null); // Close the menu
            handleBulkPrintBadge(); // Perform the check-out action
          }}
          disableRipple>
        <Print />
        {tm.translate('btnPrintBadges', "Print Badge")}
      </MenuItem>
      </Menu>
    </div>
  );
};

export default MenuItemsVisitorBulkActions;
