import React from 'react';
import { Box, Typography } from '@mui/material';
import { Oval } from 'react-loader-spinner';
import { useTheme } from '@mui/material/styles';

const LoadingApplication = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        overflow: 'hidden', // Ensure no scrolling
        margin: 0,
        padding: 0,
      }}
    >
      <Typography fontWeight="bold" sx={{ color: theme.palette.secondary[100] }}>
        loading
      </Typography>
      <Oval
        visible={true}
        height="80"
        width="80"
        color="#ffedc2"
        secondaryColor="#ffedc2"
        ariaLabel="oval-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </Box>
  );
};

export default LoadingApplication;
