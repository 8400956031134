import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Divider, Button, useTheme, useMediaQuery } from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { getUserRole, getUserRoleHasPermission } from 'hooks/HttpRequests';
import Header from 'components/Header';
import { Formik } from 'formik';
import * as yup from "yup";
import { sadevioColors } from 'components/Colors';
import { useUserProfileManager } from 'components/UserProfileProvider';
import { useFrontDeskManager } from 'components/FrontDeskManager';
import { useNavigate, useParams } from 'react-router-dom';

const UserRolesDetail = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { palette } = useTheme();
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams(); // Accessing the id parameter from the URL

  const [headerButtons, setHeaderButtons] = useState([]);
  const [isReadonly, setIsReadonly] = useState(false);


  const [rolePermissions, setRolePermissions] = useState([]);
  const [groupedPermissions, setGroupedPermissions] = useState({});
  const [userRoleName, setUserRoleName] = useState("");
  const [userRoleDescription, setUserRoleDescription] = useState("");
  const [gridLoading, setGridLoading] = useState(false);

  const { userProfile, userProfleLoading } = useUserProfileManager();
  const { selectedFrontDesk, frontDeskLoading } = useFrontDeskManager();

  const groups = {
    'visitor': ['system.visitor', 'system.visitor.add', 'system.visitor.edit', 'system.visitor.delete', ],
    'visit': ['system.visit', 'system.visit.new', 'system.visit.edit', 'system.visit.delete', 'system.invitation.approval','system.visit.admin' ],
    'app': ['system.app.global', 'system.app.location'],
    'employee': ['system.host', 'system.host.add', 'system.host.edit', 'system.host.delete'],
    'analytics': ['system.analytics.visitor', 'system.analytics.employee', 'system.analytics.deliveries'],
    'approval': ['system.invitation.approval'],
    'global': ['system.global.admin'],

    // 'deliveries': ['system.host', 'system.host.add', 'system.host.edit', 'system.host.delete']
  
  };

  useEffect(() => {
    setGridLoading(true);
    fetchAllData();
  }, [id]);

  const fetchAllData = async () => {
    try {
      const [roleResult, rolePermissionsResult] = await Promise.all([
        getUserRole(navigate)(id),
        getUserRoleHasPermission(navigate)(id)
      ]);

      if (roleResult.success && rolePermissionsResult.success) {
        const roleData = roleResult.data;
        const rolePermissionsData = rolePermissionsResult.data;

        setUserRoleName(roleData.name);
        setUserRoleDescription(roleData.description);
        setRolePermissions(rolePermissionsData);
        groupPermissions(rolePermissionsData);

        if(roleData.userRoleId == 1 || roleData.userRoleId == 3){
          setIsReadonly(true);
          const buttons = [
            { type : 'readonly'}
          ]
          setHeaderButtons(buttons);
        }

      }
      setGridLoading(false);
    } catch (error) {
      console.error(error);
      setGridLoading(false);
    }
  };

  const groupPermissions = (rolePermissions) => {
    const permissionMap = new Map(rolePermissions.map(permission => [permission.name, permission]));
    const grouped = {};

    for (const [group, names] of Object.entries(groups)) {
      grouped[group] = names.map(name => {
        const perm = permissionMap.get(name) || { name, description: 'No description available', has_permission: false };
        return {
          ...perm,
          granted: perm.has_permission || false
        };
      });
    }

    setGroupedPermissions(grouped);
  };

  const handleSubmit = (values) => {
    // Perform your post request with the combined form values
  };

  const toggleStatus = (group, permissionName) => {

    if(!isReadonly){
      setGroupedPermissions(prevGroupedPermissions => {
        const updatedGroup = prevGroupedPermissions[group].map(permission =>
          permission.name === permissionName ? { ...permission, granted: !permission.granted } : permission
        );
        updateRolePermissions(permissionName, !prevGroupedPermissions[group].find(permission => permission.name === permissionName).granted);
        return { ...prevGroupedPermissions, [group]: updatedGroup };
      });
    }
  };

  const updateRolePermissions = (permissionName, newStatus) => {
    setRolePermissions(prevPermissions =>
      prevPermissions.map(permission =>
        permission.name === permissionName ? { ...permission, has_permission: newStatus } : permission
      )
    );
  };

  const saveChanges = () => {
    console.log(rolePermissions);
    // Perform save operation with rolePermissions
  };

  const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const visitorSchema = yup.object().shape({
    visitor_first_name: yup.string().required("First name is required"),
    visitor_last_name: yup.string().required("Last name is required"),
  });

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={`User Role - ${userRoleName}`} subtitle={userRoleDescription} elements={headerButtons} backbutton/>

      <Formik
        onSubmit={handleSubmit}
        initialValues={{}}
        validationSchema={visitorSchema}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="h4" sx={{ margin: "20px 0 20px 0", fontWeight: 'bold' }} color={theme.palette.secondary[300]}>
              Visit
            </Typography>
            <Box sx={{ ...commonStyles, border: 1, p: 5 }}>
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                {groupedPermissions.visit && groupedPermissions.visit.map((item, index) => (
                  <React.Fragment key={index}>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} sx={{ gridColumn: "span 4", }}>
                      <IconButton onClick={() => toggleStatus('visit', item.name)}>
                        {item.granted ? (
                          <CheckCircle style={{ color: sadevioColors.hexColorOk }} />
                        ) : (
                          <Cancel style={{ color: sadevioColors.hexColorGray }} />
                        )}
                      </IconButton>
                      <Typography variant="h6" color={theme.palette.secondary[300]} style={{ margin: '0px 0px 0px 20px' }}>
                        {item.description}
                      </Typography>
                    </Box>
                    <Divider style={{ gridColumn: "span 4", height: '1px', backgroundColor: theme.palette.secondary.light }} />
                  </React.Fragment>
                ))}
              </Box>
            </Box>

            {/* ---------------------------------- */}
            <Typography variant="h4" sx={{ margin: "20px 0 20px 0", fontWeight: 'bold' }} color={theme.palette.secondary[300]}>
              Visitors
            </Typography>
            <Box sx={{ ...commonStyles, border: 1, p: 5 }}>
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                {groupedPermissions.visitor && groupedPermissions.visitor.map((item, index) => (
                  <React.Fragment key={index}>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} sx={{ gridColumn: "span 4", }}>
                      <IconButton onClick={() => toggleStatus('visitor', item.name)}>
                        {item.granted ? (
                          <CheckCircle style={{ color: sadevioColors.hexColorOk }} />
                        ) : (
                          <Cancel style={{ color: sadevioColors.hexColorGray }} />
                        )}
                      </IconButton>
                      <Typography variant="h6" color={theme.palette.secondary[300]} style={{ margin: '0px 0px 0px 20px' }}>
                        {item.description}
                      </Typography>
                    </Box>
                    <Divider style={{ gridColumn: "span 4", height: '1px', backgroundColor: theme.palette.secondary.light }} />
                  </React.Fragment>
                ))}
              </Box>
            </Box>

            {/* ---------------------------------- */}

            <Typography variant="h3" sx={{ margin: "40px 0 20px 0" }} color={theme.palette.secondary[300]}>
              User Management
            </Typography>
            <Box sx={{ ...commonStyles, border: 1, p: 5 }}>
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                {groupedPermissions.employee && groupedPermissions.employee.map((item, index) => (
                  <React.Fragment key={index}>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} sx={{ gridColumn: "span 4", }}>
                      <IconButton onClick={() => toggleStatus('employee', item.name)}>
                        {item.granted ? (
                          <CheckCircle style={{ color: sadevioColors.hexColorOk }} />
                        ) : (
                          <Cancel style={{ color: sadevioColors.hexColorGray }} />
                        )}
                      </IconButton>
                      <Typography variant="h6" color={theme.palette.secondary[300]} style={{ margin: '0px 0px 0px 20px' }}>
                        {item.description}
                      </Typography>
                    </Box>
                    <Divider style={{ gridColumn: "span 4", height: '1px', backgroundColor: theme.palette.secondary.light }} />
                  </React.Fragment>
                ))}
              </Box>
            </Box>

            {/* ---------------------------------- */}

            <Typography variant="h3" sx={{ margin: "40px 0 20px 0" }} color={theme.palette.secondary[300]}>
              Analytics
            </Typography>
            <Box sx={{ ...commonStyles, border: 1, p: 5 }}>
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                {groupedPermissions.analytics && groupedPermissions.analytics.map((item, index) => (
                  <React.Fragment key={index}>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} sx={{ gridColumn: "span 4", }}>
                      <IconButton onClick={() => toggleStatus('analytics', item.name)}>
                        {item.granted ? (
                          <CheckCircle style={{ color: sadevioColors.hexColorOk }} />
                        ) : (
                          <Cancel style={{ color: sadevioColors.hexColorGray }} />
                        )}
                      </IconButton>
                      <Typography variant="h6" color={theme.palette.secondary[300]} style={{ margin: '0px 0px 0px 20px' }}>
                        {item.description}
                      </Typography>
                    </Box>
                    <Divider style={{ gridColumn: "span 4", height: '1px', backgroundColor: theme.palette.secondary.light }} />
                  </React.Fragment>
                ))}
              </Box>
            </Box>

            {/* ---------------------------------- */}

            <Typography variant="h3" sx={{ margin: "40px 0 20px 0" }} color={theme.palette.secondary[300]}>
              Apps
            </Typography>
            <Box sx={{ ...commonStyles, border: 1, p: 5 }}>
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                {groupedPermissions.app && groupedPermissions.app.map((item, index) => (
                  <React.Fragment key={index}>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} sx={{ gridColumn: "span 4", }}>
                      <IconButton onClick={() => toggleStatus('app', item.name)}>
                        {item.granted ? (
                          <CheckCircle style={{ color: sadevioColors.hexColorOk }} />
                        ) : (
                          <Cancel style={{ color: sadevioColors.hexColorGray }} />
                        )}
                      </IconButton>
                      <Typography variant="h6" color={theme.palette.secondary[300]} style={{ margin: '0px 0px 0px 20px' }}>
                        {item.description}
                      </Typography>
                    </Box>
                    <Divider style={{ gridColumn: "span 4", height: '1px', backgroundColor: theme.palette.secondary.light }} />
                  </React.Fragment>
                ))}
              </Box>
            </Box>
            {/* ---------------------------------- */}

            <Typography variant="h3" sx={{ margin: "40px 0 20px 0" }} color={theme.palette.secondary[300]}>
              Approval
            </Typography>
            <Box sx={{ ...commonStyles, border: 1, p: 5 }}>
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                {groupedPermissions.approval && groupedPermissions.approval.map((item, index) => (
                  <React.Fragment key={index}>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} sx={{ gridColumn: "span 4", }}>
                      <IconButton onClick={() => toggleStatus('approval', item.name)}>
                        {item.granted ? (
                          <CheckCircle style={{ color: sadevioColors.hexColorOk }} />
                        ) : (
                          <Cancel style={{ color: sadevioColors.hexColorGray }} />
                        )}
                      </IconButton>
                      <Typography variant="h6" color={theme.palette.secondary[300]} style={{ margin: '0px 0px 0px 20px' }}>
                        {item.description}
                      </Typography>
                    </Box>
                    <Divider style={{ gridColumn: "span 4", height: '1px', backgroundColor: theme.palette.secondary.light }} />
                  </React.Fragment>
                ))}
              </Box>
            </Box>
            {/* ---------------------------------- */}

            <Typography variant="h3" sx={{ margin: "40px 0 20px 0" }} color={theme.palette.secondary[300]}>
              Global
            </Typography>
            <Box sx={{ ...commonStyles, border: 1, p: 5 }}>
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                {groupedPermissions.global && groupedPermissions.global.map((item, index) => (
                  <React.Fragment key={index}>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} sx={{ gridColumn: "span 4", }}>
                      <IconButton onClick={() => toggleStatus('global', item.name)}>
                        {item.granted ? (
                          <CheckCircle style={{ color: sadevioColors.hexColorOk }} />
                        ) : (
                          <Cancel style={{ color: sadevioColors.hexColorGray }} />
                        )}
                      </IconButton>
                      <Typography variant="h6" color={theme.palette.secondary[300]} style={{ margin: '0px 0px 0px 20px' }}>
                        {item.description}
                      </Typography>
                    </Box>
                    <Divider style={{ gridColumn: "span 4", height: '1px', backgroundColor: theme.palette.secondary.light }} />
                  </React.Fragment>
                ))}
              </Box>
            </Box>
            {/* ---------------------------------- */}
            <Button
              fullWidth
              disabled={isReadonly}
              onClick={saveChanges}
              type="button"
              sx={{
                m: "2rem 0",
                p: "1rem",
                backgroundColor: palette.secondary.light,
                color: palette.background.alt,
                "&:hover": {
                  color: palette.primary.main,
                  backgroundColor: palette.secondary.light,
                  fontWeight: 'bold'
                },
              }}
            >
              {"SAVE"}
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default UserRolesDetail;
