import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Typography,
  MenuItem,
  IconButton,
  Grid,
  Paper,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import IOSSwitch from "components/IOSSwitch";
import { CssStepper, CssTextField } from "components/StyledComponents";
import { enqueueSnackbar } from "notistack";
import {  getWorkflowEngineAllUserDefinedFields, postTextFieldRule, putTextFieldRule } from "hooks/HttpRequests";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";


var ruleTypes = [
  { label: "Invitation", value: "invitation" },
  { label: "Check-In", value: "check_in" },
];

const operators = [
  { label: "Equals (==)", value: "==" },
  { label: "Not Equals (!=)", value: "!=" },
  { label: "Less Than (<)", value: "<" },
  { label: "Less Than or Equal (<=)", value: "<=" },
  { label: "Greater Than (>)", value: ">" },
  { label: "Greater Than or Equal (>=)", value: ">=" },
];

const actions = [
  { label: "Deny Entry", value: "deny_entry" },
  { label: "Send Notification", value: "notify" },
];

const availableFields = [
  { fieldName: "First Name", apiKey: "first_name" },
  { fieldName: "Last Name", apiKey: "last_name" },
  { fieldName: "Company", apiKey: "company" },
  { fieldName: "Visit Date", apiKey: "visit_date" },
  { fieldName: "Email", apiKey: "email" },
];

const DialogRuleWizard = ({ open, onClose, onSave, rule = null, availableRuleTypes }) => {

    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { selectedFrontDesk, selectedFrontDeskTerminalSettings, frontDeskLoading, convertToFrontDeskTimeZone } = useFrontDeskManager();
    const [filteredRuleTypes, setFilteredRuleTypes] = useState(ruleTypes);

  const [activeStep, setActiveStep] = useState(0);
  const [ruleDetails, setRuleDetails] = useState({
    ruleType: "",
    description: "",
    active: false,
  });
  const [conditions, setConditions] = useState([
    { field: "", operator: "", value: "", workflowType: "", actions: [] },
  ]);
  const [loading, setLoading] = useState(false);

  const [loadedAllUserdefinedFieldsData, setLoadedAllUserdefinedFieldsData] = useState(availableFields);


  const steps = ["1. Rule Details", "2. Conditions & Actions", "3. Overview & Save"];

  useEffect(() => {


    if (open && rule) {
        resetForm();
        const localFilteredRuleTypes = ruleTypes.filter(
            (type) => type.value === rule.ruleType
        );
        
        setFilteredRuleTypes(localFilteredRuleTypes);

        loadRule(rule);
    } else if (open) {
        const localFilteredRuleTypes = ruleTypes.filter(
            (type) => availableRuleTypes.includes(type.value)
        );
    
        setFilteredRuleTypes(localFilteredRuleTypes)

        resetForm();
    }
    loadDataAvailableUserdefinedFields();
  }, [open, rule]);

  const resetForm = () => {
    setRuleDetails({ ruleType: "", description: "", active: true, frontDeskEntityId: selectedFrontDesk.entityId });
    setConditions([{ field: "", operator: "", value: "", workflowType: "", actions: [] }]);
  };

  const handleNext = () => {

    const errors = [];

    if (activeStep === 0) {
      // Validate Rule Details tab
      if (ruleDetails.ruleType === "") {
        enqueueSnackbar({
          message: "Select a rule type",
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 3000,
        });
      } else {
        setActiveStep((prev) => prev + 1);
      }
    } else if (activeStep === 1) {

        if (conditions.length === 0) {
            errors.push("You must add at least one condition.");
          }
          conditions.forEach((condition, index) => {
            if (!condition.fieldName) errors.push(`Condition ${index + 1}: Field cannot be empty.`);
            if (!condition.operator) errors.push(`Condition ${index + 1}: Operator cannot be empty.`);
            if (condition.actions.length === 0) errors.push(`Condition ${index + 1}: Add at least one action.`);
          });
        
        if (errors.length > 0) {
          enqueueSnackbar({
            message: errors.join("\n"),
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
            autoHideDuration: 3000,
          });
          return;
        }
        setActiveStep((prev) => prev + 1);
      
      
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleAddCondition = () =>
    setConditions([
      ...conditions,
      { field: "", operator: "", value: "", workflowType: "", actions: [] },
    ]);

    const handleAddAction = (conditionIndex) => {
        setConditions((prev) => {
          const updatedConditions = [...prev];
          const condition = updatedConditions[conditionIndex];
      
          // Validate action type restrictions
          const hasDenyEntry = condition.actions.some(
            (action) => action.actionType === "deny_entry"
          );
          const hasNotify = condition.actions.some(
            (action) => action.actionType === "notify"
          );
      
          // Add action only if it doesn't already exist
          if (!hasDenyEntry) {
            condition.actions.push({ actionType: "deny_entry", parameters: "" });
          } else if (!hasNotify) {
            condition.actions.push({ actionType: "notify", parameters: "" });
          } else {
            enqueueSnackbar({
              message: "Each condition can only have one 'Deny Entry' and one 'Notify' action.",
              variant: "warning",
              anchorOrigin: { vertical: "top", horizontal: "center" },
              autoHideDuration: 3000,
            });
          }
      
          return updatedConditions;
        });
      };

  const handleSave = async () => {

    try {
      if (rule == null) {

        const submitData = {
            ruleType: ruleDetails.ruleType,
            frontDeskEntityId: ruleDetails.frontDeskEntityId,
            description: ruleDetails.description,
            active: ruleDetails.active,
            conditions,
          };

        postTextFieldRule(navigate)(submitData)
            .then((result) => {

                if (result.success) {
            
                    onSave();

                }
            })
            .catch((error) => {
                if (typeof error.action !== "undefined") {
                if (error.action == "relogin") {
                    navigate("/");
                }
                }
            });
      } else {

        const submitData = {
            entityId: rule.entityId,
            ruleType: ruleDetails.ruleType,
            frontDeskEntityId: ruleDetails.frontDeskEntityId,
            description: ruleDetails.description,
            active: ruleDetails.active,
            conditions,
          };

        putTextFieldRule(navigate)(submitData)
            .then((result) => {

                if (result.success) {
            
                    onSave();

                }
            })
            .catch((error) => {
                if (typeof error.action !== "undefined") {
                if (error.action == "relogin") {
                    navigate("/");
                }
                }
            });

      }
      
    } catch (error) {
      console.error("Failed to save rule:", error);
    }
  };

  const loadRule = async (rule) => {

    if(rule){
        
        setRuleDetails({
            ruleType: rule.ruleType,
            description: rule.description,
            active: rule.active,
            frontDeskEntityId: selectedFrontDesk.entityId
        });
        setConditions(rule.conditions || []);
    }

}

  const loadDataAvailableUserdefinedFields = async () => {


    if(open){

        getWorkflowEngineAllUserDefinedFields(navigate)(selectedFrontDesk.entityId)
        .then((result) => {

            // Use the result.data as needed
            if(result.success){

                var uiFields = []

                var visitor_visitor_type = {};
                visitor_visitor_type["apiKey"] = "visitor_visitor_type";
                visitor_visitor_type["entityType"] = "visitor";
                visitor_visitor_type["fieldName"] = "Visitor Type";
                visitor_visitor_type["fieldType"] = "visitor_visitor_type";
                visitor_visitor_type["maxFieldLength"] = 250;
                visitor_visitor_type["required"] = false;

                uiFields.push(visitor_visitor_type);
            
                var visitor_first_name = {};			
                visitor_first_name["apiKey"] = "visitor_first_name";
                visitor_first_name["entityType"] = "visitor";
                visitor_first_name["fieldName"] = "First name";
                visitor_first_name["fieldType"] = "string";
                visitor_first_name["maxFieldLength"] = 250;
                visitor_first_name["required"] = true;
                
                uiFields.push(visitor_first_name);
                
                var visitor_last_name = {};			
                visitor_last_name["apiKey"] = "visitor_last_name";
                visitor_last_name["entityType"] = "visitor";
                visitor_last_name["fieldName"] = "Last name";
                visitor_last_name["fieldType"] = "string";
                visitor_last_name["maxFieldLength"] = 250;
                visitor_last_name["required"] = true;
                
                uiFields.push(visitor_last_name);

                // Add uiFields at the beginning of result.data
                result.data.unshift(...uiFields);

                setLoadedAllUserdefinedFieldsData(result.data)

            }


        })
        .catch((error) => {

        });
    }

}

  const renderStepContent = (step) => {
    switch (step) {
      case 0: // Rule Details
        return (
          <Box display="flex" 
                flexDirection="column" gap={2}             
                backgroundColor={theme.palette.background.alt}
                p="1rem"
            >
            <FormControlLabel
                sx={{ mb: 2 , gridColumn: "span 4" }}
                control={
                    <IOSSwitch sx={{ m: 1 }} 
                                defaultChecked
                                value= {ruleDetails.active}
                                onChange={(e) => {
                                        // console.log(value.target.checked); 
                                        // ruleDetails["active"] = value.target.checked
                                        setRuleDetails({ ...ruleDetails, active: e.target.checked })
                                    }}
                    />
                }
                label={<span style={{ color: theme.palette.secondary.light }}>Active</span>} // Change label color to theme's secondary light color
            />
            <CssTextField
              select
              label="Rule Type"
              theme={theme}
              value={ruleDetails.ruleType}
              onChange={(e) =>
                setRuleDetails({ ...ruleDetails, ruleType: e.target.value })
              }
              fullWidth
              variant="outlined"
            >
              {filteredRuleTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </CssTextField>
            <CssTextField
              label="Description"
              theme={theme}
              value={ruleDetails.description}
              onChange={(e) =>
                setRuleDetails({ ...ruleDetails, description: e.target.value })
              }
              fullWidth
              variant="outlined"
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={ruleDetails.active}
                  onChange={(e) =>
                    setRuleDetails({ ...ruleDetails, active: e.target.checked })
                  }
                />
              }
              label="Active"
            /> */}
          </Box>
        );
      case 1: // Conditions & Actions
        return (
          <Box backgroundColor={theme.palette.background.alt}
                p="1rem">
            {conditions.map((condition, conditionIndex) => (
              <Paper
              key={conditionIndex}
              elevation={2}
              sx={{
                padding: 2,
                marginBottom: 2,
                position: "relative", // Enable positioning within this container
                backgroundColor: theme.palette.background.alt,
              }}
            >
              <Grid container spacing={2} alignItems="center" wrap="nowrap">
                <Grid item xs={3}>
                  <CssTextField
                    theme={theme}
                    select
                    label="Field"
                    value={condition.fieldName}
                    onChange={(e) =>
                      setConditions((prev) =>
                        prev.map((c, i) =>
                          i === conditionIndex ? { ...c, fieldName: e.target.value } : c
                        )
                      )
                    }
                    fullWidth
                    variant="outlined"
                  >
                    {loadedAllUserdefinedFieldsData.map((field) => (
                      <MenuItem key={field.apiKey} value={field.apiKey}>
                        {field.fieldName}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </Grid>
                <Grid item xs={3}>
                  <CssTextField
                    theme={theme}
                    select
                    label="Operator"
                    value={condition.operator}
                    onChange={(e) =>
                      setConditions((prev) =>
                        prev.map((c, i) =>
                          i === conditionIndex ? { ...c, operator: e.target.value } : c
                        )
                      )
                    }
                    fullWidth
                    variant="outlined"
                  >
                    {operators.map((op) => (
                      <MenuItem key={op.value} value={op.value}>
                        {op.label}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </Grid>
                <Grid item xs={3}>
                  <CssTextField
                    theme={theme}
                    label="Value"
                    value={condition.value}
                    onChange={(e) =>
                      setConditions((prev) =>
                        prev.map((c, i) =>
                          i === conditionIndex ? { ...c, value: e.target.value } : c
                        )
                      )
                    }
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <CssTextField
                    theme={theme}
                    label="Workflow Type"
                    value={condition.workflowType}
                    onChange={(e) =>
                      setConditions((prev) =>
                        prev.map((c, i) =>
                          i === conditionIndex ? { ...c, workflowType: e.target.value } : c
                        )
                      )
                    }
                    fullWidth
                    variant="outlined"
                  />
                  <IconButton
                    onClick={() =>
                      setConditions((prev) => prev.filter((_, i) => i !== conditionIndex))
                    }
                    // sx={{
                    //   color: theme.palette.error.main, // Optional: color for the delete button
                    // }}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            
              <Box mt={2}>
                <Typography variant="h6">Actions</Typography>
                {condition.actions.map((action, actionIndex) => (
                  <Box
                    key={actionIndex}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    <CssTextField
                      select
                      theme={theme}
                      label="Action Type"
                      value={action.actionType}
                      onChange={(e) =>
                        setConditions((prev) =>
                          prev.map((c, i) =>
                            i === conditionIndex
                              ? {
                                  ...c,
                                  actions: c.actions.map((a, j) =>
                                    j === actionIndex
                                      ? { ...a, actionType: e.target.value }
                                      : a
                                  ),
                                }
                              : c
                          )
                        )
                      }
                      fullWidth
                      variant="outlined"
                    >
                      {actions.map((action) => (
                        <MenuItem key={action.value} value={action.value}>
                          {action.label}
                        </MenuItem>
                      ))}
                    </CssTextField>
                    {action.actionType === "deny_entry" && (
                      <CssTextField
                        theme= {theme}
                        label="Deny Message"
                        value={action.parameters}
                        onChange={(e) =>
                          setConditions((prev) =>
                            prev.map((c, i) =>
                              i === conditionIndex
                                ? {
                                    ...c,
                                    actions: c.actions.map((a, j) =>
                                      j === actionIndex
                                        ? { ...a, parameters: e.target.value }
                                        : a
                                    ),
                                  }
                                : c
                            )
                          )
                        }
                        fullWidth
                        variant="outlined"
                      />
                    )}
                    {action.actionType === "notify" && (
                      <CssTextField
                        theme= {theme}
                        label="Email Address"
                        value={action.parameters}
                        onChange={(e) =>
                          setConditions((prev) =>
                            prev.map((c, i) =>
                              i === conditionIndex
                                ? {
                                    ...c,
                                    actions: c.actions.map((a, j) =>
                                      j === actionIndex
                                        ? { ...a, parameters: e.target.value }
                                        : a
                                    ),
                                  }
                                : c
                            )
                          )
                        }
                        fullWidth
                        variant="outlined"
                      />
                    )}
                    <IconButton
                        onClick={() =>
                            setConditions((prev) =>
                            prev.map((c, i) =>
                                i === conditionIndex
                                ? {
                                    ...c,
                                    actions: c.actions.filter((_, j) => j !== actionIndex),
                                    }
                                : c
                            )
                            )
                        }
                        // sx={{
                        //     color: theme.palette.error.main,
                        // }}
                        >
                        <Delete />
                    </IconButton>
                  </Box>
                ))}
                <Button
                    startIcon={<Add />}
                    onClick={() => handleAddAction(conditionIndex)}
                    variant="outlined"
                    color="default"
                    sx={{
                        color: theme.palette.secondary.light,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        mt: 2,
                        "&:hover": { color: theme.palette.secondary.light },
                    }}
                    >
                    Add Action
                </Button>
              </Box>
            </Paper>
                    
            ))}
            <Button
              startIcon={<Add />}
              onClick={handleAddCondition}
              variant="outlined"
              color="default"
              sx={{
                // backgroundColor: theme.palette.secondary.light,
                // color: theme.palette.secondary.light,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                mt:2,
                "&:hover": { color: theme.palette.secondary.light },
              }}
            >
              Add Condition
            </Button>
          </Box>
        );
      case 2: // Overview & Save
        return (
          <Box backgroundColor={theme.palette.background.alt}
                p="1rem">
            <Typography variant="h6">Overview</Typography>
            <Typography>
              <strong>Rule Type:</strong> {ruleDetails.ruleType}
            </Typography>
            <Typography>
              <strong>Description:</strong> {ruleDetails.description}
            </Typography>
            <Typography>
              <strong>Active:</strong> {ruleDetails.active ? "Yes" : "No"}
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Conditions & Actions:
            </Typography>
            <ul>
              {conditions.map((condition, index) => (
                <li key={index}>
                  <strong>{condition.field}</strong> {condition.operator}{" "}
                  {condition.value} {condition.workflowType && `(${condition.workflowType})`}
                  <ul>
                    {condition.actions.map((action, i) => (
                      <li key={i}>
                        {action.actionType}{" "}
                        {action.parameters && `(Email: ${action.parameters})`}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
            <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>{rule ? "Edit Rule" : "Add New Rule"}</DialogTitle>
            <DialogContent>
            <CssStepper
                activeStep={activeStep}
                alternativeLabel
                theme={theme}
                sx={{
                    mb: 3, // Margins for the Stepper
                }}
                >
                {steps.map((label, index) => (
                    <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
                </CssStepper>

                {renderStepContent(activeStep)}
            </DialogContent>
            <DialogActions>
                <Button disabled={activeStep === 0} onClick={handleBack} variant="contained">
                    Back
                </Button>
                {activeStep < steps.length - 1 ? (
                <Button onClick={handleNext} variant="contained" color="primary">
                    Next
                </Button>
                ) : (
                <Button onClick={handleSave} variant="contained" color="success">
                    Save
                </Button>
                )}
                <Button onClick={onClose} variant="outlined" color="secondary">
                Cancel
                </Button>
            </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogRuleWizard;
