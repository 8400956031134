import React, { useEffect, useState } from "react";
import { ResponsiveTreeMap } from "@nivo/treemap";
import { Box, useTheme } from "@mui/material";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { getCheckInsPerLocationPerDevice } from "hooks/HttpRequests";
import { useNavigate } from "react-router-dom";

const TreeMapChart = ({ isDashboard = false, validFrom, validTo, triggerRefresh }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);
  const { selectedFrontDesk } = useFrontDeskManager();
  

  useEffect(() => {

          loadData()
        
  }, []);

  useEffect(() => {

    loadData()
  
}, [triggerRefresh]);
  


  const loadData = async () => {
    setGridLoading(true);
    try {
      const result = await getCheckInsPerLocationPerDevice(navigate)(selectedFrontDesk.entityId, validFrom.valueOf(), validTo.valueOf());

       var data = {
        name: "Visitor Check-Ins",
        color: theme.palette.secondary[700] ,
      
      };

      var locations = result.data;

      // Function to add color properties
      locations.forEach(location => {
        // Add color to parent
        location.color = theme.palette.secondary[500];

        // Add color to each child
        location.children.forEach(child => {
            child.color = theme.palette.secondary[300];
        });
      });

      data["children"] = locations;

      setData(data);
    } catch (error) {

      console.error(error);
      
    } finally {
      setGridLoading(false);
    }
  };

  if (gridLoading) return "Loading...";

  return (
    <Box
      height={isDashboard ? "400px" : "100%"}
      width={undefined}
      minHeight={isDashboard ? "325px" : undefined}
      minWidth={isDashboard ? "325px" : undefined}
      position="relative"
      id="visitor_tree_map"
    >
      <ResponsiveTreeMap
        data={data}
        theme={{
          tooltip: {
            container: {
              color: theme.palette.primary.main,
            },
          },
        }}
        identity="name"
        value="value"
        innerPadding={10}
        outerPadding={3}
        margin={
          isDashboard
            ? { top: 10, right: 10, bottom: 10, left: 10 }
            : { top: 40, right: 80, bottom: 80, left: 80 }
        }
        label={(e) => `${e.id} (${e.formattedValue})`}
        labelSkipSize={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 3]],
        }}
        parentLabelTextColor={{
          from: 'color',
          modifiers: [['darker', 3]],
        }}
        colors={d => d.data.color} // Use custom colors from data
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.8]],
        }}
        nodeOpacity={1} // Set node opacity to 1
      />
    </Box>
  );
};

export default TreeMapChart;
