import {  getMenu } from 'hooks/HttpRequests';
import React, { createContext, useState, useContext } from 'react';
import { useFrontDeskManager } from './FrontDeskManager';

const MenuManagerContext = createContext();

export const useMenuManager = () => useContext(MenuManagerContext);

export const MenuManagerProvider = ({ children }) => {

  const [menuData, setMenuData] = useState([]);

  const [menuDataLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { selectedFrontDesk } = useFrontDeskManager();

  const fetchMenuData = async () => {
    try {

        setLoading(true);

        if(selectedFrontDesk != null){
          const menudata = await getMenu()(selectedFrontDesk.entityId);

          setMenuData(menudata);
        }
        setLoading(false);

    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  // useEffect(() => {

  //   fetchMenuData();

  //   // Cleanup function
  //   return () => {
  //     // Any cleanup code here if needed
  //   }
  // }, []);


  return (
    <MenuManagerContext.Provider value={{menuData, menuDataLoading, error, fetchMenuData}}>
      {children}
    </MenuManagerContext.Provider>
  );
};
