import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Link, Typography, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import DialogYesNo from "components/dialog/DialogYesNo";
import {  useNavigate } from "react-router-dom";
import tm from "components/TranslationManager";
import { stringAvatar } from "components/StyledComponents";
import {  getSessionToken, getUri, getVisitInvitationApproveVisitor, getVisitInvitationDenyVisitor } from "hooks/HttpRequests";
import { useFrontDeskManager } from "components/FrontDeskManager";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import GridCellVisitHosts from "components/GridCellVisitHosts";
import SadevioDataGridPro from "components/SadevioDataGridPro";


const Approvals = () => {
  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {  convertToFrontDeskTimeZone, convertToFrontDeskTimeZoneDateTimeString } = useFrontDeskManager();

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [topSearchFilter, setTopSearchFilter] = useState("");

  const theme = useTheme();

  /////////////// GRID /////////////
  // values to be sent to the backend

  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    visitorId: false,
  });

  const [search, setSearch] = useState("");

  /////////////// USE EFFECT /////////////

  useEffect(() => {
    setTriggerRefresh(!triggerRefresh)

  }, []);

  const columns = [
    
    {
      field: "visitorId",
      headerName: "Visitor Id",
      flex: 1,
      filterable: false,
      type: 'number'
    },
    {
      field: 'picturePath',
      filterable: false,
      headerName: 'Picture',
      width: 100,
      renderCell: (params) => (
        params.row.picturePath != null ? (
          <Avatar {...stringAvatar(params.row.fullName)} alt={params.row.fullName} src={`${getUri(getSessionToken())}/visitor/${params.row.visitorId}/picture/${params.row.picturePath}`}          />

        ) : (
          <Avatar {...stringAvatar(params.row.fullName)} />
        )
      ),
    },
    {
      field: "visitor",
      filterable: false,
      headerName: "Visitor",
      flex: 1,
      renderCell: (params) => {
        // const navigate = useNavigate();
  
        if(params.row.visitor != null){
          return (
            <Box>
              {/* Full Name */}
              <Link
                style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
                onClick={() => {
                  navigate(`/visitors/${params.row.visitor.visitorId}`);
                }}
              >
                {params.row.visitor.fullName + " ("+params.row.visitor.visitorType+")"}
              </Link>
              {/* Company Name */}
              <Typography
                variant="body2"
                style={{
                  fontSize: "14px",
                }}
              >
                {params.row.visitor.companyName || "N/A"}
              </Typography>
              {/* Company Name */}
              <Typography
                variant="body2"
                style={{
                  fontSize: "14px",
                }}
              >
                {params.row.visitor.email || "N/A"}
              </Typography>
            </Box>
          );
      }else{
        return (<Typography
          variant="error"
          style={{
            fontSize: "14px",
          }}
        >
          {"visitor was deleted"}
        </Typography> )
      }


      },
    },
    {
      field: "hosts",
      filterable: false,
      headerName: "Host",
      flex: 1,
      renderCell: (params) => (
        <GridCellVisitHosts visitId={params.row.visit.visitId} navigate={navigate} />
      ),
    },
    {
      field: "visitValidFrom",
      filterable: false,
      headerName: tm.translate('clmValidFrom', "Valid From") ,
      flex: "auto",
      minWidth: 150,
      renderCell: (params) => {

        if(params.row.visit != null){
          const utcDateTime = params.row.visit.validFrom; // Assuming params.value contains the UTC timestamp
          // const localDateTime = dayjs.utc(utcDateTime).local().format('DD.MM.YYYY HH:mm'); // Convert UTC to local and format
          const localDateTime = convertToFrontDeskTimeZoneDateTimeString(utcDateTime)
          return <span>{localDateTime}</span>;
        }else{
          return <span>{}</span>;
        }
        
      }
    },
    {
      field: "visitValidTo",
      filterable: false,
      headerName: tm.translate('clmValidTo', "Valid To"),
      flex: "auto",
      minWidth: 150,
      renderCell: (params) => {

        if(params.row.visit != null){

          const utcDateTime = params.row.visit.validTo;  // Assuming params.value contains the UTC timestamp
          // const localDateTime = dayjs.utc(utcDateTime).local(); // Convert UTC to local
          const localDateTimeString = convertToFrontDeskTimeZoneDateTimeString(utcDateTime)
          const localDateTime = convertToFrontDeskTimeZone(utcDateTime)
          const isPast = localDateTime.isBefore(dayjs()); // Check if the date is in the past
          return (
            <span
              style={{
                color: isPast ? 'red' : 'inherit',
                fontWeight: isPast ? 'bold' : 'normal',
              }}
            >
              {localDateTimeString}
            </span>
          );
        }else{
          return <span>{}</span>;
        }
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        let statusTranslation = "";
        switch(params.row.status) {
          case "under_review":
            statusTranslation = tm.translate("lblUnderReview", "Under Review");
            break;
          case "denied":
            statusTranslation = tm.translate("lblDenied", "Denied");
            break;
          case "approved":
            statusTranslation = tm.translate("lblApproved", "Approved");
            break;
          default:
            statusTranslation = params.row.status;
            break;
        }
        return <span>{statusTranslation}</span>;
      }
    },
    {
      field: "approve",
      filterable: false,
      headerName: "Approve",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          {/* Approve Button */}
          <Button
            variant="outlined"
            color="success"
            size="large"
            onClick={() => handleApprove(params.row)}
            disabled={params.row.status === "approved" || params.row.status === "denied"}
          >
            ✅ Approve
          </Button>

        </Box>
      ),
    },
    {
      field: "deny",
      filterable: false,
      headerName: "Deny",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" gap={1}>

          {/* Deny Button */}
          <Button
            variant="outlined"
            color="error"
            size="large"
            onClick={() => handleDeny(params.row)}
            disabled={params.row.status === "approved" || params.row.status === "denied"}
          >
            ❌ Deny
          </Button>
        </Box>
      ),
    },
    {
      field: "visit",
      filterable: false,
      headerName: "Visit Detail",
      width: 180,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          {/* Approve Button */}
          <Button
            variant="outlined"
            color="white"
            size="large"
            onClick={() => {

              if(params.row.visit != null && params.row.visitor != null){
                navigate(`/visit/${params.row.visit.visitId}/visitor/${params.row.visitor.visitorId}`);
              }else{

              }
            }}          >
            Visit Detail
          </Button>

        </Box>
      ),
    },
  ];

  // Approve handler
  const handleApprove = (row) => {

    dispatch(showLoading(""));
    
    getVisitInvitationApproveVisitor(navigate)(row.entityId)
          .then((result) => {
              // Use the result.data as needed
              dispatch(hideLoading());
              if (result.success) {
                setTriggerRefresh(!triggerRefresh);
              }else{
                enqueueSnackbar({
                  message: result.message,
                  variant: "error",
                  anchorOrigin: { vertical: "top", horizontal: "center" },
                  autoHideDuration: 3000,
                });
              }
          })
          .catch((error) => {
              if (typeof error.action !== "undefined") {
                if (error.action === "relogin") {
                  navigate("/");
                }
              }else if (error.hasOwnProperty('success')) {
                enqueueSnackbar({
                  message: "Error "+error.message,
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
              }
              dispatch(hideLoading());
          });

  };

  // Deny handler
  const handleDeny = (row) => {

    dispatch(showLoading(""));
    
    getVisitInvitationDenyVisitor(navigate)(row.entityId)
          .then((result) => {
              // Use the result.data as needed
              dispatch(hideLoading());
              if (result.success) {
                setTriggerRefresh(!triggerRefresh);
              }else{
                enqueueSnackbar({
                  message: result.message,
                  variant: "error",
                  anchorOrigin: { vertical: "top", horizontal: "center" },
                  autoHideDuration: 3000,
                });
              }
          })
          .catch((error) => {
              if (typeof error.action !== "undefined") {
                if (error.action === "relogin") {
                  navigate("/");
                }
              }else if (error.hasOwnProperty('success')) {
                enqueueSnackbar({
                  message: "Error "+error.message,
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
              }
              dispatch(hideLoading());
          });
  };

  const rowTemplateMobile = (row) => {
   
      let powerIconColor;
      // if (row.status == null) {
      //   powerIconColor = sadevioColors.hexColorKioskGray;
      // } else if (row.status == "active") {
      //   powerIconColor = sadevioColors.hexColorKioskGreen;
      // } else if (row.status == "finished") {
      //   powerIconColor = sadevioColors.hexColorKioskRed;
      // } else {
      //   powerIconColor = sadevioColors.hexColorKioskGray;
      // }
      const whenCreated = convertToFrontDeskTimeZoneDateTimeString(row.whenCreated)
      const whenFinished = row.whenFinished 
      ? convertToFrontDeskTimeZoneDateTimeString(row.whenFinished) 
      : "Not finished yet"; 
      const { totalConfirmed, totalVisitors } = row; // Ensure these keys exist in your row data
  
    
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="space-between"
          padding="8px"
          borderRadius="8px"
        >
          {/* Left Side - Device Info */}
          <Box>
            <Typography variant="h6" style={{ fontWeight: "bold", color: powerIconColor }}>
              Status - {row.status}
            </Typography>  
            
  
            <Box display="flex" flexDirection="column">
              <Typography variant="caption" style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '14px' }}>
                When Created: {whenCreated}
              </Typography>
  
              <Typography variant="caption" style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '14px' }}>
                Total: {totalConfirmed} / {totalVisitors}
              </Typography>
  
              <Typography variant="caption" style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '14px' }}>
                When Finished: {whenFinished}
              </Typography>
            </Box>
          </Box>
    
          
        </Box>
      );
    };
  
    
    // Define row click event behavior
    const handleRowClick = (row) => {
  
      navigate(`/emergencylist/${row.entityId}`);
  
  
    };


  const buttons = [
    // { label: "Button 1" },
    { label: "Start Sync" , onClick: () => setConfirmationOpen(true) , iconName: 'sync'},

  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="APPROVALS" subtitle="List of approvals" buttons={buttons} />
      <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        // onConfirm={handleExecute}
        theme={theme}
      />
          <SadevioDataGridPro columns ={columns} url={`invitation_approval`} triggerRefresh= {triggerRefresh} mobileSupport={false} rowTemplateMobile={rowTemplateMobile} rowClickEvent={handleRowClick}/>
    </Box>
  );
};

export default Approvals;