import React from 'react';
import { Paper, Grid, Button, useTheme, TextField, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Checkbox, Typography, Autocomplete, useMediaQuery } from '@mui/material';
import { Save, DeleteOutline } from '@mui/icons-material';
import { Formik, Form } from 'formik';
import { CssInputLabel, CssSelect, CssTextField } from "components/StyledComponents";
import * as yup from "yup";
import dayjs from 'dayjs';
import IOSSwitch from 'components/IOSSwitch';
import { useFrontDeskManager } from 'components/FrontDeskManager';
import tm from 'components/TranslationManager';

// Device type options with associated images
const DEVICE_TYPES = [
  { label: "iOS Kiosk", value: "iOs Kiosk", image: "/images/apple_logo.png" },
  { label: "Kiosk Tablet", value: "kiosk tablet", image: "/images/android_logo.png" },
  { label: "Kiosk V1.1", value: "kiosk 1_1", image: "/images/kiosk_v1.png" },
  { label: "Kiosk V2.1", value: "kiosk 2_1", image: "/images/kiosk_v2.png" },
  { label: "Kiosk V2.2", value: "kiosk 2_2", image: "/images/kiosk_v2_2.png" },
  { label: "Kiosk V3.1", value: "kiosk 3_1", image: "/images/kiosk_v3.png" },
];

const DeviceForm = ({
  onSubmit,
  loadedFormData = {},
  onDeleteClicked,
  isAddMode = false
}) => {

  const theme = useTheme();
  const {palette} = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNonMediumScreens = useMediaQuery("(min-width: 900px)");

  const { allAvailableFrontDesks } = useFrontDeskManager();
  

  // Get the selected device type and corresponding image
  const selectedDevice = DEVICE_TYPES.find(d => d.value === loadedFormData.type) || DEVICE_TYPES[0];

  // Validation schema
  const validationSchema = yup.object().shape({
    deviceName: yup.string().required("Device Name is required"),
    localPin: yup.string().required("PIN required"),
    macAddress: yup.string().required("MAC Address is required"),
    frontDesk: yup.object().nullable().required("Front Desk is required"),
  });


  var test =  allAvailableFrontDesks?.find((desk) => desk.entityId === loadedFormData.frontDesk?.entityId)

  return (
    <Formik 
    
      initialValues={{
        active: loadedFormData.active || false,
        deviceName: loadedFormData.deviceName || "",
        ipAddress: loadedFormData.ipAddress || "",
        macAddress: loadedFormData.macAddress || "",
        localPin: loadedFormData.localPin || "",
        cardIdProcessor: loadedFormData.cardIdProcessor || "",
        lockScreen: loadedFormData.lockScreen || false,
        lockMessage: loadedFormData.lockMessage || "",
        type: loadedFormData.type || "kiosk tablet",
        frontDesk: loadedFormData.frontDesk || null,
      }}
      validationSchema={validationSchema} 
      onSubmit={onSubmit} enableReinitialize
    >
      {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
        <Form>
          <Paper elevation={3} sx={{ p: isNonMobile ? 3 : 2, m: isNonMobile ? 3 : 1, backgroundColor: 'transparent' }}>
            
            {/* Active Toggle */}
            <FormControlLabel
              sx={{ mb: 2 ,mt: 2, ml: 1}}
              control={
                <IOSSwitch
                  checked={values.active}
                  onChange={(e) => setFieldValue("active", e.target.checked)}
                />
              }
              label={<Typography color={theme.palette.secondary.light} sx={{ml: 1}}>Active</Typography>}
            />

            {/* Location Selection */}
            <Grid container spacing={isNonMobile ? 2 : 1}>
              <Grid item xs={12}>
                <Autocomplete
                  required
                  options={allAvailableFrontDesks || []}
                  getOptionLabel={(option) => option?.name || ""}
                  value={values.frontDesk || null} // Use Formik's state
                  onChange={(_, newValue) => setFieldValue("frontDesk", newValue || null)} // Store full object
                  isOptionEqualToValue={(option, value) => option.entityId === value?.entityId}
                  renderInput={(params) => (
                    <CssTextField {...params} label="Location" fullWidth sx={{ mb: 1 }} theme={theme} required/>
                  )}
                />
              </Grid>

              {/* Read-Only Fields */}
              {[
                { label: "Model", value: loadedFormData.model || "N/A" },
                { label: "OS Version", value: loadedFormData.operationSysVersion || "N/A" },
                { label: "Version", value: loadedFormData.version || "N/A" },
                { label: "Serial", value: loadedFormData.serial || "N/A" },
                { label: "Last Config Upload", value: loadedFormData.lastSuccessfulConfUpload ? dayjs(loadedFormData.lastSuccessfulConfUpload).format('YYYY-MM-DD HH:mm:ss') : "N/A" },
                { label: "Last Config Download", value: loadedFormData.lastSuccessfulConfDownload ? dayjs(loadedFormData.lastSuccessfulConfDownload).format('YYYY-MM-DD HH:mm:ss') : "N/A" },
                { label: "Last Alive", value: loadedFormData.lastAlive ? dayjs(loadedFormData.lastAlive).format('YYYY-MM-DD HH:mm:ss') : "N/A" },
              ].map((field, index) => (
                <Grid key={index} item xs={12} md={6}>
                  <CssTextField label={field.label} value={field.value} fullWidth disabled theme={theme}/>
                </Grid>
              ))}

              {/* Editable Fields */}
              <Grid item xs={12} >
                <CssTextField
                  label="Device Name"
                  name="deviceName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.deviceName}
                  theme={theme}
                  error={Boolean(touched.deviceName) && Boolean(errors.deviceName)}
                  helperText={touched.deviceName && errors.deviceName}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth sx={{
                    width: "100%",
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: theme.palette.secondary[300],
                    },
                    "&:hover .MuiInputLabel-root": {
                      color: theme.palette.secondary[300],
                    },
                  }}>
                  <CssInputLabel id="demo-multiple-checkbox-label" theme={theme}>{tm.translate('dspDeviceType', 'Device Type')}</CssInputLabel>
                  <CssSelect id="demo-multiple-checkbox-label" name="type"label={tm.translate('dspDeviceType', 'Device Type')} value={values.type} onChange={handleChange} onBlur={handleBlur} theme={theme}>
                    {DEVICE_TYPES.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </CssSelect>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <CssTextField label="PIN" required name="localPin" onBlur={handleBlur} onChange={handleChange} value={values.localPin} fullWidth theme={theme}/>
              </Grid>

              <Grid item xs={12} md={6}>
                <CssTextField label="IP Address" name="ipAddress" onBlur={handleBlur} onChange={handleChange} value={values.ipAddress} fullWidth theme={theme}/>
              </Grid>

              <Grid item xs={12} md={6}>
                <CssTextField label="MAC Address" name="macAddress" onBlur={handleBlur} onChange={handleChange} value={values.macAddress} fullWidth theme={theme} />
              </Grid>

            <FormControlLabel
              sx={{ mb: 2 ,mt: 2, ml: 1}}
              control={
                <IOSSwitch
                  checked={values.lockScreen}
                  onChange={(e) => setFieldValue("lockScreen", e.target.checked)}
                />
              }
              label={<Typography color={theme.palette.secondary.light} sx={{ml: 1}}>Lock Kiosk Screen</Typography>}
            />

              <Grid item xs={12}>
                <CssTextField label="Lock Message" name="lockMessage" onBlur={handleBlur} onChange={handleChange} value={values.lockMessage} fullWidth theme={theme}/>
              </Grid>
            </Grid>

            {/* Save & Delete Buttons */}
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12} md={9}>
                <Button
                  fullWidth
                  startIcon={<Save />}
                  type="submit" // Submits the form and triggers validation
                  sx={{
                    p: "1rem",
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontWeight: "bold",
                    "&:hover": {
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.secondary.light,
                      fontWeight: "bold",
                    },
                  }}
                >
                  {tm.translate("btnSave", "Save")}
                </Button>
              </Grid>
              {!isAddMode && (
                <Grid item xs={12} md={3}>
                  <Button fullWidth variant="outlined" color="error" startIcon={<DeleteOutline />} onClick={onDeleteClicked} 
                  sx={{
                    p: "1rem",
                    fontWeight: "bold",
                    "&:hover": {
                      fontWeight: "bold",
                    },
                  }}>Delete</Button>
                </Grid>
              )}
            </Grid>

          </Paper>
        </Form>
      )}
    </Formik>
  );
};


export default DeviceForm;
