import { Box, Divider, IconButton, Typography } from "@mui/material";
import { ApartmentOutlined, MoreHoriz } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { getListValuesPaging } from "hooks/HttpRequests";
import { useEffect, useState } from "react";

const UserRoles = () => {

    const theme = useTheme();
    const navigate = useNavigate();

    /////////////// GRID /////////////
    // values to be sent to the backend

    const [data, setData] = useState({ data: [], total_count: 0 });
    const [gridLoading, setGridLoading] = useState(true);
    const [topSearchFilter, setTopSearchFilter] = useState("");

    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      limit: 25,
      sort: [],
      query:[]
    })

    useEffect(() => {
      // This code will run every time paginationModel changes
      loadGridData();
  
    }, [paginationModel]);

    const loadGridData = async () => {

    setGridLoading(true)

    var extraFilter = "";
    if(topSearchFilter.length > 0){
      extraFilter = "&extraFilter="+topSearchFilter
    }

    getListValuesPaging(navigate)(paginationModel, "userrole", extraFilter)
      .then((result) => {

        if(result.success){
          setData(result.data)
        }
        setGridLoading(false)

      })
      .catch((error) => {

        console.log(error);

        setGridLoading(false)
      });

  }

  const buttons = [
    { type:'search',label: "Search user roles..." , onChange: (e) => console.log(e.target.value), iconName: ''},
    { type:'button',label: "New Role" , onClick: () => { console.log("open"); Navigate("/manage/userrole/new") }, iconName: ''}
  ];

  const handleClick = () => {
    // Handle click event
    console.log("clicked it")
  }

    // Render loading state if loadedFormData is null
    if (gridLoading) {
      return <div>Loading...</div>;
    }

  return (
    <Box m="20px">
      <Header
        title="USER ROLES"
        subtitle="List of User Roles"
        elements={buttons}
      />
      <Box
        m="40px 10px 0 10px"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            // color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            // backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            // backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            // backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            // color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            // color: `${colors.grey[100]} !important`,
          },
        }}
      >

          {data.map((item, index) => (
            <> 
            {/*  -------------------------------  */}
            <Box key={`user-role-${item.userRoleId}`} width="100%"> {/* Ensure that the parent container allows full width */}
                <Box onClick={handleClick}>
                      <Box display="flex" alignItems="center"> {/* Align items vertically center */}
                          <Box marginRight={2}>
                              <ApartmentOutlined style={{ fontSize: 40 }} /> {/* Adjust the fontSize */}
                          </Box>
                          <Box flex="1" width="100%" sx={{
                                      color: theme.palette.secondary.light,
                                      "&:hover": {
                                      cursor: "pointer",
                                      color: theme.palette.secondary.light,
                                      fontWeight: 'bold'
                                      },
                                  }} > {/* Take all available width */}
                              <Typography 
                                  variant="h2" 
                                  color={theme.palette.secondary[100]} 
                                  fontWeight="bold" 
                                  onClick={() => { 
                                    navigate("/manage/roles/"+item.userRoleId); 
                                  }}
                                >
                                  {item.name}
                                </Typography>
                              <Typography variant="h5" color={theme.palette.secondary[300]}>
                                 {item.description}
                              </Typography>
                          </Box>
                          <IconButton>
                              <MoreHoriz />
                          </IconButton>
                          <IconButton>
                              <MoreHoriz />
                          </IconButton>
                      </Box>
                  </Box>
              </Box>
              <Divider style={{ margin: '20px 0', height: '1px', backgroundColor: theme.palette.secondary.light }} />
            
            </>
          ))}
        {/*  -------------------------------  */}
    
      </Box>
    </Box>
  );
};

export default UserRoles;
