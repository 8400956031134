import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControlLabel, ListItemIcon, Tooltip, Button, useTheme, Radio, Autocomplete, Grid, Paper, useMediaQuery } from '@mui/material';
import {  AccountBalance, AirplaneTicket, Close } from '@mui/icons-material';
import { getCompany, putCompany } from 'hooks/HttpRequests';
import {CssDateRangePicker, CssRadioGroup, CssTextField} from "components/StyledComponents";
import { GoogleMap,  Marker, useLoadScript } from "@react-google-maps/api";
import * as yup from "yup";
import { Formik } from 'formik';
import IOSSwitch from 'components/IOSSwitch';
import dayjs from 'dayjs';


const CompanyDetailGeneral = ({globalType, navigate, id }) => {

const theme = useTheme();
const {palette} = useTheme();
const isNonMobile = useMediaQuery("(min-width:600px)");
const defaultCategory = { label: "Airline", value: "airline" };



// const validFromDayJS = dayjs().set("hour", 9).startOf("hour");
// const validToDayJS = dayjs().set("hour", 12).add(1, 'year');

const [validFromDayJS, setValidFromDayJS] = useState(dayjs().set("hour", 9).startOf("hour"));
const [validToDayJS, setValidToDayJS] = useState(dayjs().set("hour", 12).add(1, 'year'));

const [availableEmployes, setAvailableEmployes] = useState([]);
const [gridLoading, setGridLoading] = useState(false);

const [showReviewers, setShowReviewers] = useState(false);



const toggleReviewers = () => {
    setShowReviewers(!showReviewers);
};

///////// google maps

const [coordinates, setCoordinates] = useState({ lat: 38.8977, lng: -77.036560 });

const libraries = ["places"];

const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries

  });

// AIRPORT ADVANCE SECTION
const [showAirpotAdvanceConf, setShowAirpotAdvanceConf] = useState(false);

const toggleAirportAdvanceConf = () => {
    setShowAirpotAdvanceConf(!showAirpotAdvanceConf);
};

const [displayBackgroundColorPicker, setDisplayBackgroundColorPicker] = useState(false);
const [backgroundColor, setBackgroundColor] = useState("#ffffff");

  const handleBackgroundColorClick = () => {
    setDisplayBackgroundColorPicker(!displayBackgroundColorPicker)
  };

  const  handleBackgroundColorClose = () => {
    setDisplayBackgroundColorPicker(false)
  };

  const handleBackgroundColorChange = (color, event) => {
    setBackgroundColor(color.hex)
  };

/////////// google autocomplete



useEffect(() => {
    
    loadCompanyData(id)

  }, [id]);

  const loadCompanyData = async (id) => {
        getCompany(navigate)(id)
        .then((result) => {

        console.log(result)
        if(result.success){
            //   setCopmanyName("- "+result.data.name);
            setLoadedFormData(result.data)

            const dateObject = dayjs(result.data.validFrom);
            setValidFromDayJS(dateObject);
            const dateObjectTo = dayjs(result.data.validTo);
            setValidToDayJS(dateObjectTo);

            setCoordinates({ lat: result.data.latitude, lng: result.data.longitude })

        }
        // setData(result)
        // setGridLoading(false)

        })
        .catch((error) => {

            console.log(error);
            setGridLoading(false)
        });
  };

const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const registerSchema = yup.object().shape({
    name: yup.string().required("required"),
    // status: yup.string().required("required"),
    // address1: yup.string().required("required"),
    // category: yup.object().nullable().required("required"), // Adjusted validation for category

    // badgeRenewalPeriod: yup.string().required("required"),
    // minAuthSignatoryCount: yup.number().required("required"),
    // maxAuthSignatoryCount: yup.number().required("required"),

  });

  const initialValuesRegister = {
    status: "active",
    name: "",
    status: "pending",
    category: "",
    active: false,
    address1: "",
    address2: "",
    city: "",
    zip: "",
    phone: "",
    badgeRenewalPeriod: "",
    category: null,
    minAuthSignatoryCount: 1,
    maxAuthSignatoryCount: 1,
    exemptChrc: false,
    exemptSta: false,

  };

  const [loadedFormData, setLoadedFormData] = useState(null);


  const handleSubmit = (values) => {
    // Perform your post request with the combined form values
    console.log(values);

    loadedFormData["active"] = values.active
    loadedFormData["category"] = values.category

    loadedFormData["name"] = values.name
    loadedFormData["status"] = values.status

    loadedFormData["address1"] = values.address1
    loadedFormData["city"] = values.city
    loadedFormData["zip"] = values.zip
    loadedFormData["phone"] = values.phone


    loadedFormData["validFrom"] = validFromDayJS.$d.getTime()
    loadedFormData["validTo"] = validToDayJS.$d.getTime()

    loadedFormData["minAuthSignatoryCount"] = values.minAuthSignatoryCount
    loadedFormData["maxAuthSignatoryCount"] = values.maxAuthSignatoryCount

    var submitData= {}
    submitData["data"]= loadedFormData;

    putCompany(navigate)(submitData)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {
      
            console.log(result)
            if(result.success){
                //   setCopmanyName("- "+result.data.name);
                setLoadedFormData(result.data)

                setCoordinates({ lat: result.data.latitude, lng: result.data.longitude })
            }

        }
      })
      .catch((error) => {
        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }
      });

  };

  const handleValidTimeRangeDateChange = (item) => {
    console.log(item);
    console.log(validFromDayJS);
    setValidFromDayJS(item[0])
    setValidToDayJS(item[1])
  }

  const statusOptions = [
    { label: "Pending", value: "pending" },
    { label: "Active", value: "active" },
  ];

  return (

    <Box>
        <Box sx={{ ...commonStyles, border: 1 }}>
            
        <Box>
    {/* Top Row */}
    <Box display="flex" alignItems="center" mb={1} ml={1} mt={3}>
        <Box ml={2}>
            <Typography variant="h3" fontWeight="bold" color={theme.palette.secondary[100]}>
                General Information
            </Typography>
        </Box>
    </Box>

        {/* Bottom Rows */}
        <Formik
            onSubmit={handleSubmit}
            initialValues={loadedFormData || initialValuesRegister}
            validationSchema={registerSchema}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} style={{ backgroundColor: 'transparent', padding: "2px 0px 2px 0px" }}>
                        {/* Left Column */}
                        <Grid item xs={6} style={{ padding: 20, backgroundImage: 'transparent' }}>
                            <Paper
                                elevation={3}
                                style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}
                                sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
                            >
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    options={statusOptions}
                                    getOptionLabel={(option) => option.label || ""} // Show the label
                                    value={statusOptions.find(option => option.value === values.status) || null} // Map value to the label
                                    isOptionEqualToValue={(option, value) => option.value === value.value} // Compare based on value
                                    onChange={(event, newValue) => {
                                        setFieldValue('status', newValue ? newValue.value : ""); // Store only the value in Formik
                                    }}
                                    sx={{ mb: 2, gridColumn: "span 4" }}
                                    renderInput={(params) => (
                                        <CssTextField
                                        {...params}
                                        required
                                        label="Status"
                                        name="status"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(touched.status) && Boolean(errors.status)}
                                        helperText={touched.status && errors.status}
                                        theme={theme}
                                        />
                                    )}
                                    />
                                <CssTextField
                                    label="Company Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={Boolean(touched.name) && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                    sx={{ mb: 2, gridColumn: "span 4" }}
                                    theme={theme}
                                    fullWidth
                                />
                                
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    onChange={(event, newValue) => setFieldValue('category', newValue)}
                                    value={values.category}
                                    options={[
                                        defaultCategory,
                                        { label: "Concessionaire", value: "concessionaire" },
                                    ]}
                                    
                                    defaultValue={defaultCategory}
                                    sx={{ mb: 2, gridColumn: "span 4" }}
                                    renderInput={(params) => (
                                        <CssTextField
                                            {...params}
                                            required
                                            label="Category"
                                            name="category"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.category}
                                            error={Boolean(touched.category) && Boolean(errors.category)}
                                            helperText={touched.category && errors.category}
                                            theme={theme}
                                        />
                                    )}
                                />
                                <CssDateRangePicker
                                    defaultValue={[validFromDayJS, validToDayJS]}
                                    value={[validFromDayJS, validToDayJS]}
                                    onBlur={handleBlur}
                                    onChange={handleValidTimeRangeDateChange}
                                    slotProps={{
                                        textField: ({ position }) => ({
                                            label: position === "start" ? "Valid From" : "Valid To",
                                        }),
                                    }}
                                    sx={{ mb: 2,gridColumn: "span 4" }}
                                    theme={theme}
                                />
                                <CssTextField
                                    name="phone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phone}
                                    label="Phone"
                                    variant="outlined"
                                    fullWidth
                                    theme={theme}
                                    sx={{ mb: 2, gridColumn: "span 4" }}
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <CssTextField
                                            name="minAuthSignatoryCount"
                                            type= "number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.minAuthSignatoryCount}
                                            label="Authorized Signatory Min Count"
                                            variant="outlined"
                                            fullWidth
                                            theme={theme}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CssTextField
                                            name="maxAuthSignatoryCount"
                                            type= "number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.maxAuthSignatoryCount}
                                            label="Authorized Signatory Max Count"
                                            variant="outlined"
                                            fullWidth
                                            theme={theme}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* Right Column */}
                        <Grid item xs={6}>
                            <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none' }}>
                                {loadError ? (
                                    <Typography>Error loading Google Maps</Typography>
                                ) : (
                                    <Paper elevation={3} style={{ height: 400 }}>
                                        {isLoaded ? (
                                            <GoogleMap mapContainerStyle={{ height: '100%', width: '100%' }} center={coordinates} zoom={10}>
                                                <Marker position={coordinates} />
                                            </GoogleMap>
                                        ) : (
                                            <Typography>Loading...</Typography>
                                        )}
                                    </Paper>
                                )}
                            </Paper>

                            {/* Address Fields under Google Maps, but still part of the Formik form */}
                            <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', marginTop: '20px' }}>
                                <CssTextField
                                    name="address1"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.address1}
                                    label="Address 1"
                                    variant="outlined"
                                    fullWidth
                                    theme={theme}
                                    sx={{ mb: 2 }}
                                />
                                <CssTextField
                                    name="address2"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.address2}
                                    label="Address 2"
                                    variant="outlined"
                                    fullWidth
                                    theme={theme}
                                    sx={{ mb: 2 }}
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <CssTextField
                                            name="city"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.city}
                                            label="City"
                                            variant="outlined"
                                            fullWidth
                                            theme={theme}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CssTextField
                                            name="zip"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.zip}
                                            label="Zip"
                                            variant="outlined"
                                            fullWidth
                                            theme={theme}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Button
                        fullWidth
                        type="submit"
                        sx={{
                            m: "2rem 0",
                            p: "1rem",
                            backgroundColor: palette.secondary.light,
                            color: palette.background.alt,
                            fontWeight: "bold",
                            "&:hover": {
                                color: palette.primary.main,
                                backgroundColor: palette.secondary.light,
                                fontWeight: "bold",
                            },
                        }}
                    >
                        Save Company Info
                    </Button>
                </form>
            )}
        </Formik>
    </Box>
        {/*     */}
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >

            {showReviewers && (

        <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem"}}>
            <Typography
                variant="h4"
                color={theme.palette.secondary[100]}
                fontWeight="bold"
                mt="3rem"
                >
                Authorised Reviewers
                </Typography>
            <Typography variant="h5" color={theme.palette.secondary[300]}>
                Select which person will be notified to review the visitor invitation.
            </Typography>
            <CssRadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    theme={theme} 
                >
                    <FormControlLabel value="only_one" control={<Radio />} label="Only one reviewer need to confirm" />
                    <FormControlLabel value="all" control={<Radio />} label="All selected reviewers need to confirm" />
            </CssRadioGroup>


            </Box>
            )}
            </Box>
            
            <Box
            display="flex"
            flexDirection="row"
            alignItems="right"
            width="100%"
            >
                {showReviewers && (
                    <Button
                        variant="contained"
                        onClick={toggleReviewers}
                        sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        m: "0rem 1rem 0rem 0rem",
                        "&:hover": { color: theme.palette.secondary.light },
                        }}
                    >
                        {"DISABLE"}
                    </Button>
                )}
            
            </Box>

        </Box>

        {/*  -------------------------------   */}

        <Box sx={{ ...commonStyles, border: 1 }}>
            
            <Box display="flex" alignItems="center">
                <ListItemIcon>
                    <AccountBalance fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
                </ListItemIcon>
                <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                    <Box display="flex" flexDirection="row" alignItems="center">
    
                        
                        <Typography
                        variant="h3"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        >
                        Billing Information
                        </Typography>
                        {globalType && (
                        <Tooltip title={
                            <Typography variant="body1" style={{ fontSize: '16px' }}>
                            Global Visitor Type
                            </Typography>
                        }>
                            
                        </Tooltip>
                        )}
                    </Box>
    
                    <Typography variant="h5" color={theme.palette.secondary[300]}>
                        Choose a color which you want to use as the background color. We usually recommend a dark gray to have a good contrast to your accent color.
                    </Typography>
                    </Box>
                    
                    <Box ml="auto">    
       
                </Box>
            </Box>
            {/*     */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                
    
              
                </Box>
                
                <Box
                display="flex"
                flexDirection="row"
                alignItems="right"
                width="100%"
                >
                    {showReviewers && (
                        <Button
                            variant="contained"
                            onClick={toggleReviewers}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                            {"DISABLE"}
                        </Button>
                    )}
                
                </Box>
    
            </Box>
            {/*  -------------------------------   */}

            {/*  -------------------------------   */}

        <Box sx={{ ...commonStyles, border: 1 }}>
            
            <Box display="flex" alignItems="center">
                <ListItemIcon>
                    <AirplaneTicket fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
                </ListItemIcon>
                <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                    <Box display="flex" flexDirection="row" alignItems="center">
    
                        
                        <Typography
                        variant="h3"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        >
                        Airport Advance
                        </Typography>
                        {globalType && (
                        <Tooltip title={
                            <Typography variant="body1" style={{ fontSize: '16px' }}>
                            Global Visitor Type
                            </Typography>
                        }>
                            
                        </Tooltip>
                        )}
                    </Box>
    
                    <Typography variant="h5" color={theme.palette.secondary[300]}>
                        Configure Aiport requiments for company
                    </Typography>
                    </Box>
                    
                    <Box ml="auto">    
                    {!showAirpotAdvanceConf && (
                        <Button
                            variant="contained"
                            onClick={toggleAirportAdvanceConf}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                            {"EDIT"}
                        </Button>
                    )}
                
                    {showAirpotAdvanceConf && (
                    <Button
                                variant="contained"
                                onClick={() => navigate(id+"/general")} 
                                sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                m: "0rem 1rem 0rem 0rem",
                                "&:hover": { color: theme.palette.secondary.light },
                                }}
                            >
                            {"SAVE"}
                        </Button>
                    )}
    
                    {showAirpotAdvanceConf && (
                    <Button
                            variant="contained"
                            onClick={toggleAirportAdvanceConf}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                            <Close />
                        </Button>
                    )}
                    </Box>
            </Box>
            {/*     */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
    
                {showAirpotAdvanceConf && (
    
                    <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem"}}>
                        <Box position="relative" sx={{ ml: 10, mb: 3 }}>
                            
                        <FormControlLabel
                            sx={{ mb: 2 , gridColumn: "span 4" }}
                            control={
                                <IOSSwitch sx={{ m: 1 }} 
                                            defaultChecked
                                            value= {loadedFormData.active}
                                            onChange={(value) => {console.log(value.target.checked); loadedFormData["active"] = value.target.checked }}
                                />
                            }
                            label={<span style={{ color: theme.palette.secondary.light }}>Company exempt from STA</span>} // Change label color to theme's secondary light color
                        />
                        <FormControlLabel
                            sx={{ mb: 2 , gridColumn: "span 4" }}
                            control={
                                <IOSSwitch sx={{ m: 1 }} 
                                            defaultChecked
                                            value= {loadedFormData.active}
                                            onChange={(value) => {console.log(value.target.checked); loadedFormData["active"] = value.target.checked }}
                                />
                            }
                            label={<span style={{ color: theme.palette.secondary.light }}>Company exempt from CHRC</span>} // Change label color to theme's secondary light color
                        />
                        <FormControlLabel
                            sx={{ mb: 2 , gridColumn: "span 4" }}
                            control={
                                <IOSSwitch sx={{ m: 1 }} 
                                            defaultChecked
                                            value= {loadedFormData.active}
                                            onChange={(value) => {console.log(value.target.checked); loadedFormData["active"] = value.target.checked }}
                                />
                            }
                            label={<span style={{ color: theme.palette.secondary.light }}>CBP Seal</span>} // Change label color to theme's secondary light color
                        />
                        <FormControlLabel
                            sx={{ mb: 2 , gridColumn: "span 4" }}
                            control={
                                <IOSSwitch sx={{ m: 1 }} 
                                            defaultChecked
                                            value= {loadedFormData.active}
                                            onChange={(value) => {console.log(value.target.checked); loadedFormData["active"] = value.target.checked }}
                                />
                            }
                            label={<span style={{ color: theme.palette.secondary.light }}>LEO Designation</span>} // Change label color to theme's secondary light color
                        />
                        <FormControlLabel
                            sx={{ mb: 2 , gridColumn: "span 4" }}
                            control={
                                <IOSSwitch sx={{ m: 1 }} 
                                            defaultChecked
                                            value= {loadedFormData.active}
                                            onChange={(value) => {console.log(value.target.checked); loadedFormData["active"] = value.target.checked }}
                                />
                            }
                            label={<span style={{ color: theme.palette.secondary.light }}>AOE Parking Decal</span>} // Change label color to theme's secondary light color
                        />
                    
                        </Box>
                    </Box>
                )}
                </Box>
            </Box>
            {/*  -------------------------------   */}

            

    </Box>
  );
};

export default CompanyDetailGeneral;
