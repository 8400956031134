import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  FormControl,
  MenuItem,
  useTheme,
  useMediaQuery,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { CssInputLabel, CssSelect, CssTextField } from "./StyledComponents";
import tm from "components/TranslationManager";
import { Settings } from "@mui/icons-material";
import DialogPassportScannerSettings from "./dialog/DialogPassportScannerSettings";
import cookieManagerUtils from './CookieManagerUtils';
import DialogPassportScannerDetailPicture from "./dialog/DialogPassportScannerDetailPicture";
import { useNavigate } from "react-router-dom";
import { useFrontDeskManager } from "./FrontDeskManager";
import { useDispatch } from "react-redux";
import { postAtomReaderDocumentUpdateVisitor, putAtomReaderDocumentUpdateVisitor } from "hooks/HttpRequests";
import { hideLoading, showLoading } from "state/loadingSlicer";


const ScanPassportDialog = ({ open, onSave, onClose, entity = null }) => {
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const { selectedFrontDesk } = useFrontDeskManager();

  const [readerStatus, setReaderStatus] = useState("not ready");
  const [webSocket, setWebSocket] = useState(null);
  const [webSocketUrl, setWebSocketUrl] = useState("ws://192.168.1.61:5010");

  const [selectedImage, setSelectedImage] = useState("");
  const [updateMode, setUpdateMode] = useState(false);
  const [entityName, setEntityName] = useState("");
  
  

  const formValuesDefault = {
    documentNumber: "",
    documentType: "",
    issuer: "",
    expiryDate: "",
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
  }

  const [formValues, setFormValues] = useState(formValuesDefault);

  const imageDataDefault = {
    color: "",
    uv: "",
    ir: "",
  };

  const [imageData, setImageData] = useState(imageDataDefault);

  const faceImageDefault = {
    rfid: "",
    face: "",
  };

  const [faceImage, setFaceImage] = useState(faceImageDefault);

  useEffect(() => {
    let ws;

    if (open) {

      //if a entity is passed to the component we want to update a visitor
      if(entity.visitorId > 0 && entity.visitId > 0){
        setUpdateMode(true);
        setEntityName(" - "+entity.fullName)
      }else{
        setUpdateMode(false)
      }

      const ipAddress = cookieManagerUtils.getWebsocketIp()
      setWebSocketUrl(ipAddress)
      setImageData(imageDataDefault);
      setFaceImage(faceImageDefault);
      setFormValues(formValuesDefault);

      try {
        ws = new WebSocket(ipAddress);

        ws.onopen = () => {
          console.log("Connected to server");
          setReaderStatus("Connected");

          // Send initial message after connection is established
          const initMessage = JSON.stringify({ action: "passport_reader_init", data: "" });
          ws.send(initMessage);
          console.log("Sent initial message:", initMessage);
        };

        ws.onmessage = (event) => {
          try {

            const data = JSON.parse(event.data);
            console.log("WebSocket message received:", data);
            handleWebSocketData(data);
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
          }
        };

        ws.onerror = (error) => {
          console.error("WebSocket error:", error);
          enqueueSnackbar({
            message: "Failed to connect to WebSocket server.",
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
            autoHideDuration: 3000,
          });
          setReaderStatus("error");
        };

        ws.onclose = (event) => {
          console.log("WebSocket connection closed");
          setReaderStatus("not ready");
          var reason;
              // See https://www.rfc-editor.org/rfc/rfc6455#section-7.4.1
              if (event.code == 1000)
                    reason = ""
              else if(event.code == 1001)
                  reason = "An endpoint is \"going away\", such as a server going down or a browser having navigated away from a page.";
              else if(event.code == 1002)
                  reason = "An endpoint is terminating the connection due to a protocol error";
              else if(event.code == 1003)
                  reason = "An endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).";
              else if(event.code == 1004)
                  reason = "Reserved. The specific meaning might be defined in the future.";
              else if(event.code == 1005)
                  reason = "No status code was actually present.";
              else if(event.code == 1006)
                 reason = "The connection was closed abnormally, e.g., without sending or receiving a Close control frame";
              else if(event.code == 1007)
                  reason = "An endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [https://www.rfc-editor.org/rfc/rfc3629] data within a text message).";
              else if(event.code == 1008)
                  reason = "An endpoint is terminating the connection because it has received a message that \"violates its policy\". This reason is given either if there is no other sutible reason, or if there is a need to hide specific details about the policy.";
              else if(event.code == 1009)
                 reason = "An endpoint is terminating the connection because it has received a message that is too big for it to process.";
              else if(event.code == 1010) // Note that this status code is not used by the server, because it can fail the WebSocket handshake instead.
                  reason = "An endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn't return them in the response message of the WebSocket handshake. <br /> Specifically, the extensions that are needed are: " + event.reason;
              else if(event.code == 1011)
                  reason = "A server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.";
              else if(event.code == 1015)
                  reason = "The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).";
              else
                  reason = "Unknown close reason";

                if(reason !== ""){
                  enqueueSnackbar({
                    message: reason,
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "center" },
                    autoHideDuration: 3000,
                  });
                }
        };

        setWebSocket(ws);
      } catch (error) {
        console.error("WebSocket initialization error:", error);
        enqueueSnackbar({
          message: "WebSocket initialization failed.",
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
          autoHideDuration: 3000,
        });
        setReaderStatus("error");
      }
    }

    // Cleanup function to close the WebSocket connection
    return () => {
      if (ws) {
        console.log("Closing WebSocket connection...");
        ws.close();
        setWebSocket(null);
      }
    };
  }, [open]); // Only runs when the `open` state changes

  const handleWebSocketData = (response) => {
    if (response && response.action) {
      switch (response.action) {
        case "passport_reader_status":

        if(response.data != null){
	        			
          if(response.data === 'success_true'){
            setReaderStatus(
              response.data === "success_true" ? "Ready for scanning" : "Not ready"
            );
          }else{
            // const initMessage = JSON.stringify({ action: "passport_reader_status", data: "" });
            // ws.send(initMessage);
            // setReaderStatus(
            //   'Initializing...'
            // );
          }
              
        }

          break;
        case "passport_reader_init":
            console.log("Color image received:", response.data);
            var data = JSON.parse(response.data)
            if(data.success != null && data.success == true){
              setReaderStatus(
                 "Ready for scanning"
              );
              
            }else{

            // var websocket = util.WebSocketHandler.getWebSocket();	
            //   websocket.send ('passport_reader_status', '');
            //   form.setValue('Initializing...');
            //   form.setValue('not ready');
            }

            
          case "passport_reader_init_ready":
            var data = JSON.parse(response.data)

            break;

          case "passport_personal_data":
            const content = JSON.parse(response.data);
            if (content) {
              setFormValues({
                ...formValues,
                documentNumber: content.documentNumber || "",
                documentType: content.documentType || "",
                issuer: content.issuer === "D" ? "DEU" : content.issuer || "",
                expiryDate: content.expiryDate || "",
                firstName: content.firstName || "",
                lastName: content.lastName || "",
                gender: content.gender || "N",
                dob: content.dob || "",
              });
            }
            break;

            case "passport_image_face":
              const faceData = response.data;
              if (faceData) {
                setFaceImage((prev) => ({
                  ...prev,
                  face: `data:image/jpeg;base64,${faceData}`,
                }));
              } else {
                console.error("Invalid face data received:", response.data);
              }
              break;
            case "passport_image_rfid":
              setFaceImage((prev) => ({
                ...prev,
                rfid: `data:image/jpeg;base64,${response.data}`,
              }));
              break;
            case "passport_image_color":
                setImageData((prev) => ({
                  ...prev,
                  color: `data:image/jpeg;base64,${response.data}`,
                }));
              break;
            case "passport_image_uv":
              setImageData((prev) => ({
                ...prev,
                uv: `data:image/jpeg;base64,${response.data}`,
              }));
              break;
            case "passport_image_ir":
                setImageData((prev) => ({
                  ...prev,
                  ir: `data:image/jpeg;base64,${response.data}`,
                }));
                break;
            case "passport_flip_card":
              enqueueSnackbar({
                message: "Please flip document",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
              break;
          
        // Handle other WebSocket messages here
        default:{
          if(response.action !== "conn_id"){
            console.log("Unhandled WebSocket action:", response.action);
          }

        }
      }
    }
  };

  const handleInputChange = (field) => (e) => {
    setFormValues({ ...formValues, [field]: e.target.value });
  };

  const handleSaveVisitor = () => {
    console.log("Saving visitor:", formValues);
    console.log("Saving imageData:", imageData);
    console.log("Saving faceImag:", faceImage);
    console.log("Saving faceImag:", entity);


    if(formValues.documentNumber === "" || formValues.firstName === "" || formValues.lastName === "" || formValues.documentType === "" || formValues.issuer === ""){
      enqueueSnackbar({
        message: "Please check your provided information.",
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        autoHideDuration: 3000,
      });
    }else{

      var submitJson = {};
        submitJson["visitorEntity"] = entity;
        submitJson["identificationDocument"] = formValues;
        submitJson["identificationDocumentPictures"]= { ...imageData, ...faceImage };

        dispatch(showLoading(""));

        postAtomReaderDocumentUpdateVisitor(navigate)(selectedFrontDesk.entityId, submitJson)
              .then((result) => {
                  // Use the result.data as needed
                  dispatch(hideLoading());
                  if (result.success) {
                    onSave(result.data)
                  }else{
                    enqueueSnackbar({
                      message: result.message,
                      variant: "error",
                      anchorOrigin: { vertical: "top", horizontal: "center" },
                      autoHideDuration: 3000,
                    });
                  }
              })
              .catch((error) => {
                  if (typeof error.action !== "undefined") {
                    if (error.action === "relogin") {
                      navigate("/");
                    }
                  }else if (error.hasOwnProperty('success')) {
                    enqueueSnackbar({
                      message: "Error "+error.message,
                        variant: 'error',
                        anchorOrigin: {
                          vertical: 'top', // 'top', 'bottom'
                          horizontal: 'center', // 'left', 'center', 'right'
                        },
                        autoHideDuration: 3000, 
                        // persist: true
                      
                    });
                  }
                  dispatch(hideLoading());
              });

      }


  };

  const handleUpdateVisitor = () => {
    // console.log("UPDATE visitor:", formValues);
    // console.log("UPDATE imageData:", imageData);
    // console.log("UPDATE faceImag:", faceImage);
    // console.log("UPDATE faceImag:", entity);

    if(formValues.documentNumber === "" || formValues.firstName === "" || formValues.lastName === "" || formValues.documentType === "" || formValues.issuer === ""){
      enqueueSnackbar({
        message: "Please check your provided information.",
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        autoHideDuration: 3000,
      });
    }else{

      dispatch(showLoading(""));
      
      var submitJson = {};
      submitJson["visitorEntity"] = entity;
      submitJson["identificationDocument"] = formValues;
      submitJson["identificationDocumentPictures"]= { ...imageData, ...faceImage };


      putAtomReaderDocumentUpdateVisitor(navigate)(selectedFrontDesk.entityId, submitJson)
            .then((result) => {
                // Use the result.data as needed
                dispatch(hideLoading());
                if (result.success) {
                  onClose()
                }else{
                  enqueueSnackbar({
                    message: result.message,
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "center" },
                    autoHideDuration: 3000,
                  });
                }
            })
            .catch((error) => {
                if (typeof error.action !== "undefined") {
                  if (error.action === "relogin") {
                    navigate("/");
                  }
                }else if (error.hasOwnProperty('success')) {
                  enqueueSnackbar({
                    message: "Error "+error.message,
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
                }
                dispatch(hideLoading());
            });
        }

    // Implement save logic
  };

  const handleCancel = () => {
    console.log("Closing WebSocket and dialog");
    onClose();
  };

  /////////////// DIALOG HOST LOOKUP /////////////
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false); // State for settings dialog

  const handleOpenSettingsDialog = () => {
    setOpenSettingsDialog(true);
  };

  const handleCloseSettingsDialog = () => {
    setOpenSettingsDialog(false);
  };

  /////////////// DIALOG HOST LOOKUP /////////////
  const [openDialogDetailPicture, setOpenDialogDetailPicture] = useState(false); // State for settings dialog

  const handleOpenDetailPictureDialog = () => {
    setOpenDialogDetailPicture(true);
  };

  const handleCloseDetailPictureDialog = () => {
    setOpenDialogDetailPicture(false);
  };

  const handleImageClick = (pictureData) => {
    
      if(pictureData != ""){
        setSelectedImage(pictureData)
        handleOpenDetailPictureDialog();
      }

  };

  

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
      <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>Passport{entityName}</DialogTitle>
      {/* Dialog for Passport Scanner Settings */}
      <DialogPassportScannerSettings
        open={openSettingsDialog}
        onClose={handleCloseSettingsDialog} // Close only the child dialog
        onSave={handleCloseSettingsDialog}
      />
      <DialogPassportScannerDetailPicture
        open={openDialogDetailPicture}
        onClose={handleCloseDetailPictureDialog} // Close only the child dialog
        image={selectedImage}        
      />
      <DialogContent>
        {/* Parent Grid Layout */}
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
          }}
        >
          {/* LEFT SECTION */}
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            {/* Images */}
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
              {["rfid", "face"].map((type) => (
                <Box
                  key={type}
                  border={1}
                  borderColor="grey.500"
                  width={140}
                  height={180}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={faceImage[type] || "/icons/images/user-profile/no_picture.png"}
                    alt={`${type} Image`}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    onError={(e) => {
                      e.target.src =  "/icons/images/user-profile/no_picture.png"; // Replace with your fallback image path
                      e.target.onerror = null; // Prevent infinite loop in case fallback image also fails
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
  
          {/* RIGHT SECTION */}
          <Box
            gridColumn="span 8"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            {/* Two Columns for Text Fields */}
            <Box display="flex" flexDirection="row" justifyContent="space-between" gap={2}>
              {/* Left Column */}
              <Box flex={1} display="flex" flexDirection="column" gap={2}>
                <CssTextField
                  label="Document #"
                  fullWidth
                  value={formValues.documentNumber}
                  onChange={handleInputChange("documentNumber")}
                  required
                  theme={theme}
                />
                <CssTextField
                  label="Document Type"
                  fullWidth
                  value={formValues.documentType}
                  onChange={handleInputChange("documentType")}
                  required
                  theme={theme}
                />
                <CssTextField
                  label="Issuer"
                  fullWidth
                  value={formValues.issuer}
                  onChange={handleInputChange("issuer")}
                  required
                  theme={theme}
                />
                <CssTextField
                  label="Expiry Date"
                  fullWidth
                  value={formValues.expiryDate}
                  onChange={handleInputChange("expiryDate")}
                  theme={theme}
                />
              </Box>
  
              {/* Right Column */}
              <Box flex={1} display="flex" flexDirection="column" gap={2}>
                <CssTextField
                  label="First Name"
                  fullWidth
                  value={formValues.firstName}
                  onChange={handleInputChange("firstName")}
                  required
                  theme={theme}
                />
                <CssTextField
                  label="Last Name"
                  fullWidth
                  value={formValues.lastName}
                  onChange={handleInputChange("lastName")}
                  required
                  theme={theme}
                />
                <FormControl fullWidth>
                  <CssInputLabel id="select-gender-label" theme={theme}>{tm.translate('dspGender', 'Gender')}</CssInputLabel>
                  <CssSelect
                    label={tm.translate('dspGender', 'Gender')}
                    labelId="gender-customized-select-label"
                    value={formValues.gender}
                    name="gender"
                    onChange={handleInputChange("gender")}
                    theme={theme}
                  >
                    <MenuItem value="N">None</MenuItem>
                    <MenuItem value="M">Male</MenuItem>
                    <MenuItem value="F">Female</MenuItem>
                  </CssSelect>
                </FormControl>
                <CssTextField
                  label="DOB"
                  fullWidth
                  value={formValues.dob}
                  onChange={handleInputChange("dob")}
                  theme={theme}
                />
              </Box>
            </Box>
          </Box>
  
          {/* BOTTOM SECTION */}
          <Box
            gridColumn="span 12"
            gridRow="span 2"
            display="flex"
            flexDirection="row"
            backgroundColor={theme.palette.background.alt}
            justifyContent="space-around" // Space items evenly
            alignItems="center" // Vertically center items
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "5rem",
              },
            }}
          >
            {["color", "uv", "ir"].map((type) => (
              <Box
                key={type}
                border={1}
                borderColor="grey.500"
                width={275}
                height={189}
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the event from propagating to the parent dialog
                  handleImageClick(imageData[type]);
                }}
              >
                {imageData[type] ? (
                  <img
                    src={imageData[type]}
                    alt={`${type} Image`}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    // onError={(e) => {
                    //   e.target.style.display = "none"; // Hide the image if it can't load
                    // }}
                  />
                ) : null}
              </Box>
            ))}
          </Box>

        </Box>

      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
          {/* Left section: IconButton and Reader Status */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Tooltip title="Settings">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the event from propagating to the parent dialog
                  handleOpenSettingsDialog();
                }}
              > <Settings />
              </IconButton>
            </Tooltip>
            <Typography variant="subtitle1">
              Reader status: {readerStatus}
            </Typography>
          </Box>

          {/* Right section: Other buttons */}
          <Box>
          {updateMode ? (
              <Button onClick={handleUpdateVisitor} color="primary">
                Update Visitor
              </Button>
            ) : (
              <Button onClick={handleSaveVisitor} color="primary">
                Add Visitor
              </Button>
            )}
            <Button onClick={handleCancel} color="secondary">
              Cancel
            </Button>
          </Box>
        </Box>
      </DialogActions>
      </Paper>
    </Dialog>
  );
  
  
};

export default ScanPassportDialog;
