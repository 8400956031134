import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
  FormControlLabel,
  Button,
} from "@mui/material";
import IOSSwitch from "components/IOSSwitch";
import { useFrontDeskManager } from "components/FrontDeskManager";
import {  getSilentAlarmConfig, putSilentAlarm, putSilentAlarmConfig } from "hooks/HttpRequests";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "components/Header";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { useIntegrationConfig } from "components/IntegrationConfigManager";
import EmailManager from "components/EmailManager";
import PhoneNumberManager from "components/PhoneNumberManager";
import { Save } from "@mui/icons-material";

const SilentAlarmConfig = () => {

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const { selectedFrontDesk } = useFrontDeskManager();
  const [loading, setLoading] = useState(true);
  const [loadedFormData, setLoadedFormData] = useState({ active: false });
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const { setShowEmergencyList } = useIntegrationConfig();

  const registerSchema = yup.object().shape({
    // usersToNotify: yup.string().required("required"),
  });

  const initialValuesRegister = {
    phoneNumbers: "",
    emailAddresses: "",
    active: false,
  };

  useEffect(() => {
    if (selectedFrontDesk) {
      loadData();
    }
  }, [selectedFrontDesk, triggerRefresh]);

  const loadData = async () => {
    setLoading(true);

    getSilentAlarmConfig(navigate)(selectedFrontDesk.entityId)
      .then((result) => {
        if (result.success) {
          setLoadedFormData(result.data)
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleFormSubmit = (values) => {

    var submitData = {};
    submitData = values;

    dispatch(showLoading(""))

    putSilentAlarmConfig(navigate)(selectedFrontDesk.entityId, submitData)
      .then((result) => {
        if (result.success) {
          setTriggerRefresh(!triggerRefresh)
          setShowEmergencyList(result.data.active);
        }
        dispatch(hideLoading());
      })
      .catch((error) => {
        
        dispatch(hideLoading());
      });
  };

  const buttons = [
    // { type:'button',label: `Add Rule` , onClick: () => { openRuleEditor() }, iconName: ''},

  ];

  if (loading || !selectedFrontDesk) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="1rem 2rem" >
    <Header title={`Silent Alarm Configuration`} subtitle={`Configure Sielent Alarm handling`} backbutton={true} elements={buttons}/>
      <Box mt="20px">
      <Formik
                onSubmit={handleFormSubmit}
                initialValues={loadedFormData || initialValuesRegister}
                validationSchema={registerSchema}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Paper
                      elevation={3}
                      style={{
                        padding: 20,
                        backgroundColor: theme.palette.background.alt,
                        backgroundImage: "none",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                      }}
                      sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
                    >
                      <FormControlLabel
                        sx={{ mb: 2, gridColumn: "span 4" }}
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={values.active}
                            name="active"
                            // onChange={(value) => {
                            //   console.log(value.target.checked);
                            //   loadedFormData["active"] = value.target.checked;
                            // }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            // onChange={(e) => setFieldValue("active", e.target.checked)}

                          />
                        }
                        label={<span style={{ color: theme.palette.secondary.light }}>Active</span>}
                      />

                      <EmailManager
                        title= {"Email Addresses To Be Notified"}
                        value={values.emailAddresses}
                        onChange={(newValue) => setFieldValue('emailAddresses', newValue)}
                        sx={{ mb: 2, gridColumn: "span 4" }}
                      />
                      {touched.emailAddresses && errors.emailAddresses && (
                        <Typography variant="caption" color="error">
                          {errors.emailAddresses}
                        </Typography>
                      )}


                      <PhoneNumberManager
                        title= {"Phone Numbers To Be Notified"}
                        value={values.phoneNumbers}
                        onChange={(newValue) => setFieldValue('phoneNumbers', newValue)}
                        sx={{ mb: 2, gridColumn: "span 4" }}
                      />
                      {touched.phoneNumbers && errors.phoneNumbers && (
                        <Typography variant="caption" color="error">
                          {errors.phoneNumbers}
                        </Typography>
                      )}
                          
                    </Paper>
                    
                        <Button
                          startIcon={<Save />}
                          type="submit"
                          sx={{
                            p: "1rem",
                            m: "1rem 0rem 0rem 0rem",
                            backgroundColor: palette.secondary.light,
                            color: palette.background.alt,
                            fontWeight: "bold",
                            "&:hover": {
                              color: palette.primary.main,
                              backgroundColor: palette.secondary.dark,
                              fontWeight: "bold",
                            },
                          }}
                        >
                          Save
                        </Button>
                  </form>
                )}
              </Formik>
      </Box>
      
    </Box>
  );
};

export default SilentAlarmConfig;
