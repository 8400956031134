import React, { useEffect, useState } from "react";
import {  Box, useTheme } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Header from "components/Header";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import { getHost, getUserRoleHasPermission } from "hooks/HttpRequests";
import EmployeeDetailGeneral from "./details/employeeDetailGeneral";
import EmployeeBadging from "./details/badgehandling/employeeBadging";
import EmployeeDocuments from "./details/employeeDocuments";
import EmployeeHistory from "./details/employeeHistory";
import EmployeeTimeline from "./details/employeeTimeline";
import EmployeeFrontDeskFilter from "./details/employeeFrontDeskFilter";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EmployeeDetail = ({ route }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [copmanyName, setCopmanyName] = useState("");

  const [gridLoading, setGridLoading] = useState(false);
  const { id } = useParams(); // Accessing the id parameter from the URL

  useEffect(() => {
    
    if (route !== "new") {

      setGridLoading(true)

      getHost(navigate)(id)
      .then((result) => {

        if(result.success){
            setCopmanyName("- "+result.data.fullName);
        }
        // setData(result)
        setGridLoading(false)

      })
      .catch((error) => {

        setGridLoading(false)
      });
    } else {
        //do nothing
    }

    

  }, [id]);


  const tabs = [
    { label: 'General', path: '/general', id: "tabGeneral" },
    ...(route === "update" ? [
      // { label: 'Badging', path: '/badging' , id: "tabBadging"},
      { label: 'Timeline', path: '/timeline', id: "tabTimeline" },
      { label: 'Visit History', path: '/history', id: "tabHistory" },
      { label: 'Documents', path: '/documents', id: "tabDocuments" },
      ...(getUserRoleHasPermission("system.host.edit") ? [{ label: 'Location Filter', path: '/locationfilter', id: "tabLocationFilter" }] : [])
    ] : []),
  ];
  

  const currentPath = location.pathname;
  const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
  const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;

  const [value, setValue] = useState(selectedTabIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTab = tabs[newValue];
    navigate(`/employees/${id}${selectedTab.path}`);
  };

  useEffect(() => {
    const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
    const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;
    setValue(selectedTabIndex);
  }, [currentPath, tabs]);

  return (
    <Box m="0rem 2rem">
      <Header title={`Employee Details ${copmanyName}`} subtitle="" elements={null} backbutton={"/employees"} />

      <Box height="90vh" sx={{
          "& .MuiTab-root.MuiTab-textColorPrimary.Mui-selected": {
            color: `${theme.palette.secondary[200]} !important`,
            fontWeight: 'bold'
          },
          
        }}>

        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.id === 'tabGeneral' && (
              <EmployeeDetailGeneral
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                // theme={theme}
              />
            )}
            {tab.id === 'tabBadging' && (
              // <Typography>This is the Security tab content</Typography>
              <EmployeeBadging
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                // theme={theme}
              />
            )}
            {tab.id === 'tabTimeline' && (
              // <Typography>This is the Security tab content</Typography>
              <EmployeeTimeline
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                // theme={theme}
              />
            )}
            {tab.id === 'tabHistory' && (
              // <Typography>This is the Security tab content</Typography>
              <EmployeeHistory
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                // theme={theme}
              />
            )}
            {tab.id === 'tabDocuments' && (
              // <Typography>This is the Security tab content</Typography>
              <EmployeeDocuments
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                // theme={theme}
              />
            )}
            {tab.id === 'tabLocationFilter' && (
              // <Typography>This is the Security tab content</Typography>
              <EmployeeFrontDeskFilter
                visitorType="Location Filter"
                description="Location Filter"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                // theme={theme}
              />
            )}

          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default EmployeeDetail;

