import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControlLabel,  Button, useTheme,  Autocomplete, TextField, useMediaQuery, List, ListItem, ListItemButton, ListItemAvatar, Avatar, ListItemText, IconButton, Divider } from '@mui/material';
import {  Add, Clear,  } from '@mui/icons-material';
import { autocompleteHost, fileUpload, getSessionToken, getUri, inviteVisitor } from 'hooks/HttpRequests';
import Header from 'components/Header';
import { Formik } from 'formik';
import * as yup from "yup";
import tm from 'components/TranslationManager';
import { DateTimePicker, MobileDateTimePicker } from '@mui/x-date-pickers-pro';

import dayjs from 'dayjs';
import { sadevioColors } from 'components/Colors';
import { CssDateTimePicker, CssMobileDateTimePicker, CssTextField, stringAvatar} from "components/StyledComponents";
import IOSSwitch from 'components/IOSSwitch';
import VisitorVisitInvite from './visitors/newVisitorVisitInvite';
import { useUserProfileManager } from 'components/UserProfileProvider';
import { useFrontDeskManager } from 'components/FrontDeskManager';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DialogUploadCvs from 'components/dialog/DialogUploadCvs';
import EmailManager from 'components/EmailManager';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'state/loadingSlicer';

const VisitNewInvitation = ({globalType, id }) => {

const { palette } = useTheme();
const theme = useTheme();
const navigate = useNavigate();
const { enqueueSnackbar, closeSnackbar } = useSnackbar();
const dispatch = useDispatch();

const isNonMobile = useMediaQuery("(min-width:600px)");
const { userProfile, userProfleLoading, hasPermission } = useUserProfileManager();
const { selectedFrontDesk, setSelectedFrontDeskEntityId, allAvailableFrontDesks, selectedFrontDeskTerminalSettings, triggerLocationTooltip, getTodayBusinessHours } = useFrontDeskManager();

const [searchQuery, setSearchQuery] = useState();
const [searchResults, setSearchResults] = useState([]);
const [selectedItems, setSelectedItems] = useState([]);
const [selectedHosts, setSelectedHosts] = useState([]);

const [validMinNowJS, setValidMinNowJS] = useState(dayjs().set("hour", 8).startOf("hour"));

const [validFromDayJS, setValidFromDayJS] = useState(dayjs().set("hour", 8).startOf("hour"));
const [validToDayJS, setValidToDayJS] = useState(dayjs().set("hour", 18).set("minute", 0).add(1, 'year'));


const [initialValuesRegister, setInitialValuesRegister] = useState([]);
const [initialValuesReady, setInitialValuesReady] = useState(false);

const [validationSchema, setValidationSchema] = useState(yup.object());

const [uploadFileOpen, setUploadFileOpen] = useState(false);


  //////////////////////
  useEffect(() => {


          triggerLocationTooltip();
          
          var defaultTime = getTodayBusinessHours()
          // set default time
          setValidFromDayJS(dayjs().set("hour", defaultTime.m_h).set("minute", defaultTime.m_m).startOf("hour"))

          let validToDate = dayjs().set("hour", defaultTime.e_h).set("minute", defaultTime.e_m).startOf("hour");

          // Check if the computed date/time is in the past
          if (validToDate.isBefore(dayjs())) {
            validToDate = validToDate.add(1, "day");
          }
          
          // Set the value
          setValidToDayJS(validToDate);

          var localDefaultType = "Visitor";

          if(selectedFrontDeskTerminalSettings.checkInWorkflow){

            var visitorTypes = [];

            for (const item of selectedFrontDeskTerminalSettings.checkInWorkflow) {
              var tmp = { label: item.visitorType, value: item.visitorType };
              visitorTypes.push(tmp)
              if(item.defaultType){
                localDefaultType = tmp.value;
              }

            }

          }

          const initialValuesRegister = {
            visitSummaryTo: "",
            sendEmailToHosts: false,
            internalNote: "",
            visitLocationEntityId : selectedFrontDesk.entityId,
            visitors: [
              // { visitor_visitor_type: localDefaultType, visitor_first_name: '', visitor_last_name: '' }
            ],
          };        

          var visitorModel = {}

          var uiFields = []
            
          var visitor_first_name = {};			
          visitorModel["visitor_first_name"] = "";
          visitor_first_name["apiKey"] = "visitor_first_name";
          visitor_first_name["entityType"] = "visitor";
          visitor_first_name["fieldName"] = "First name";
          visitor_first_name["fieldType"] = "string";
          visitor_first_name["maxFieldLength"] = 250;
          visitor_first_name["required"] = true;
          
          uiFields.push(visitor_first_name);
          
          var visitor_last_name = {};			
          visitorModel["visitor_last_name"] = "";
          visitor_last_name["apiKey"] = "visitor_last_name";
          visitor_last_name["entityType"] = "visitor";
          visitor_last_name["fieldName"] = "Last name";
          visitor_last_name["fieldType"] = "string";
          visitor_last_name["maxFieldLength"] = 250;
          visitor_last_name["required"] = true;
          
          uiFields.push(visitor_last_name);

          var visitor_id = {};
          visitor_id["apiKey"] = "visitorId";
          visitor_id["entityType"] = "visitor";
          visitor_id["fieldName"] = "Visitor id";
          visitor_id["fieldType"] = "visitor_id";
          visitor_id["maxFieldLength"] = 250;
          visitor_id["required"] = false;

          uiFields.push(visitor_id);

          var visitor_visitor_type = {};
          visitorModel["visitor_visitor_type"] = localDefaultType;
          visitor_visitor_type["apiKey"] = "visitor_visitor_type";
          visitor_visitor_type["entityType"] = "visitor";
          visitor_visitor_type["fieldName"] = "visitor_visitor_type id";
          visitor_visitor_type["fieldType"] = "visitor_visitor_type";
          visitor_visitor_type["maxFieldLength"] = 250;
          visitor_visitor_type["required"] = false;

          uiFields.push(visitor_visitor_type);

        initialValuesRegister.visitors.push(visitorModel)

        setInitialValuesRegister(initialValuesRegister);

        const validationSchemaTmp = generateValidationSchema(uiFields);
        setValidationSchema(validationSchemaTmp)

        setInitialValuesReady(true)


    }, []); // Empty dependency array to fetch options only once on component mount

  useEffect(() => {
    if (!userProfleLoading) {

        if(userProfile != null){

          var alreadyIn = false;

          for (const tmpHost of selectedHosts) {
              if(userProfile.session.user.userId === tmpHost.entityId){
                alreadyIn = true;
                break;
              }
          }

          if(!alreadyIn){
            var host = {}
            host["entityId"] = userProfile.session.user.userId;
            host["email"] = userProfile.session.user.email;
            host["fullName"] = userProfile.session.user.firstname+" "+userProfile.session.user.lastname;
            // host["fullName"] = "ME"
            host["picturePath"] = userProfile.session.user.picturePath;
            host["isLoggedInUser"] = true;
            // handleSelectOption(host)
  
  
  
            setSelectedHosts([...selectedHosts, host]);
          }

        }else{

        }

    }
    }, [userProfleLoading]);

const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

   // Custom email validator function
   const myEmailValidator = (value) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the value matches the email format
    if (!emailRegex.test(value)) {
      // If not, return an error message
      return new yup.ValidationError('Invalid email format', value, 'email');
    }

    // If the value is valid, return true
    return true;
  };

  // Custom email validation function
  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = 'Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  };

  const generateValidationSchema = (fields) => {


      var visitorValidation = {};

        // Loop through each field and define its validation rules
      fields.forEach((field) => {

        if(field.required && field.fieldType == "email"){
          visitorValidation[field.apiKey] = yup.string().email("invalid email").required('required');
        }else if(field.required){
          visitorValidation[field.apiKey] = yup.string().required('required');
        }else if(field.fieldType == "email"){
          visitorValidation[field.apiKey] = yup.string().email("invalid email");
        }
      });

      const validationSchema = yup.object().shape({
        visitors: yup.array().of(
          yup.object().shape(visitorValidation)
        ),
      });

      return validationSchema;
  };

  const today = dayjs();
    const yesterday = dayjs().subtract(1, 'day');
    const todayStartOfTheDay = today.startOf('day');
    const todayAtNoon = dayjs().set('hour', 12).startOf('hour');
    const todayAt9AM = dayjs().set('hour', 9).startOf('hour');

  // Function to handle adding an item to selectedItems
  const handleAddItem = (item) => {
    setSelectedItems([...selectedItems, item]);
    setSearchQuery(null);
    setSearchResults([])
  };

  const handleValidFromDateChange = (item) => {

    // if (item.isAfter(validToDayJS)) {
    //   enqueueSnackbar({
    //     message: "Invalid time selection: 'Valid From' cannot be after 'Valid To'.",
    //     variant: 'error',
    //     anchorOrigin: {
    //       vertical: 'top', // 'top', 'bottom'
    //       horizontal: 'center', // 'left', 'center', 'right'
    //     },
    //     autoHideDuration: 3000,
    //   });

    //    // Temporarily force a re-render by setting to a placeholder
    //     const currentValidValue = validFromDayJS; // Save the current valid value
    //     setValidFromDayJS(dayjs()); // Temporary dummy value (current time)

    //     // Revert to the previous valid value
    //     setTimeout(() => setValidFromDayJS(currentValidValue), 0);


    // }else{
      setValidFromDayJS(item)
    // }
  }

  const handleValidToDateChange = (item) => {
    // if (item.isBefore(validFromDayJS)) {
    //   enqueueSnackbar({
    //     message: "Invalid time selection: 'Valid To' cannot be before 'Valid From'.",
    //     variant: 'error',
    //     anchorOrigin: {
    //       vertical: 'top', // 'top', 'bottom'
    //       horizontal: 'center', // 'left', 'center', 'right'
    //     },
    //     autoHideDuration: 3000,
    //   });

    //    // Temporarily force a re-render by setting to a placeholder
    //     const currentValidValue = validToDayJS; // Save the current valid value
    //     setValidToDayJS(dayjs()); // Temporary dummy value (current time)

    //     // Revert to the previous valid value
    //     setTimeout(() => setValidToDayJS(currentValidValue), 0);


    // }else{
      setValidToDayJS(item)
    // }
  }


  // const addVisitorHandle = () => {
  //   setVisitors([...visitors, { entityId: visitors.length }]);
  // }

  const addVisitorHandle = (setFieldValue, values) => {
    const newVisitor = {
      visitor_first_name: '',
      visitor_last_name: '',
      visitor_visitor_type: '',
    };
  
    // Prepend the new visitor to the array
    const updatedVisitors = [newVisitor, ...(values.visitors || [])];
  
    // Update Formik values
    setFieldValue('visitors', updatedVisitors);
  };

  const removeVisitor = (indexToRemove, setFieldValue, values) => {
    // Create a new array without the visitor at the specified index
    const updatedVisitors = values.visitors.filter((_, index) => index !== indexToRemove);
  
    // Update Formik's visitors field
    setFieldValue('visitors', updatedVisitors);
  
  };
  
  

  const visitorSchema = yup.object().shape({
    visitor_first_name: yup.string().required("First name is required"),
    visitor_last_name: yup.string().required("Last name is required"),
    // Add validation rules for other fields
  });

  const validateVisitor = async (values) => {
    try {
      await visitorSchema.validate(values, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (errors) {
      const validationErrors = {};
      errors.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      return { isValid: false, errors: validationErrors };
    }
  };



  ////////////

  const [inputValue, setInputValue] = useState('');
  const [inputValueFrontDesk, setInputValueFrontDesk] = useState('');

  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [options, setOptions] = useState([]);

  const handleSearchQueryChange = (e, newValue) => {

    setInputValue(newValue);
    // Make backend request here based on newValue
    if (newValue) {

        if(newValue.length >= 2){
          autocompleteHost(navigate)(newValue)
                .then((result) => {

            // Use the result.data as needed
            if(result.success){
              
                setOptions(result.data);
            }
            })
            .catch((error) => {

              console.log(error);

            });
        }

    }
  };

  const handleOptionSelect = (event, newValue) => {
    if (newValue) {

      var alreadyIn = false;

      for (const tmpHost of selectedHosts) {
          if(newValue.entityId === tmpHost.entityId){
            alreadyIn = true;
            break;
          }
      }


      if(!alreadyIn){
        setSelectedValues([...selectedValues, newValue]);
        setSelectedHosts([...selectedHosts, newValue]);
      }
      setOptions([]);
      setSelectedValue(null);
      setInputValue('');
    }
  };

  //////////////////////

  const handleSubmit = (values) => {
      // Perform your post request with the combined form values

      
      try{
     
          var submitData= {}

          ///////////////////////////
          ///// HOST DATA 
          var hosts = []

          for (const tmpHost of selectedHosts) {
              var host = {}
              host["entityId"] = tmpHost.entityId;
              host["email_address"] = tmpHost.email;

              hosts.push(host);
          }

          values["hosts"] = hosts;

          // ///////////////////////////
          // ///// VISITOR DATA 

          if(values.visitors.length == 0){

            throw new Error("Please add minimum one visitor");

          }

          

          // //////////////////////////
          // ///// TIME RANGE

          var timeRangeOk = isSelectedTimeOk();

          values["visitFrom"] = validFromDayJS.format('MM/DD/YYYY hh:mm A');
          values["visitTo"] = validToDayJS.format('MM/DD/YYYY hh:mm A');

          // //////////////////////////
          // ///// INTERNAL NOTE

          // values["internalNote"] =  values.internalNote;

          // //////////////////////////
          // ///// CURRENT LOCATION

          var isLocationOk = false;
          
          if(values.visitLocationEntityId != ""){
            values["visitLocationEntityId"] = values.visitLocationEntityId
            isLocationOk = true;
          }

          // //////////////////////////
          // ///// EMAIL NOTIFICATIONS

          values["sendEmail"] = true
          // values["sendEmailToHosts"] = values.sendEmailToHosts;
          // values["visitSummaryTo"] = values.visitSummaryTo;


          // submitData["data"]= values;

          if(isLocationOk){

          if(timeRangeOk){

            dispatch(showLoading(""))

            inviteVisitor(navigate)(values)
            .then((result) => {
              // Use the result.data as needed
              if (result.success) {

                enqueueSnackbar({
                  message: "Visit registered successfully",
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
                if(hasPermission("system.visit")){
                  navigate("/visit/logs")
                }else{
                  navigate("/dashboard")
                }
              }else{
                enqueueSnackbar(result.message,{ 
                  variant: 'error', anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 5000, // 5000 milliseconds = 5 seconds
                  // persist: true
                })

              }
              dispatch(hideLoading())

            })
            .catch((error) => {
              dispatch(hideLoading())
              if (typeof error.action !== "undefined") {
                if (error.action == "relogin") {
                  navigate("/");
                }
              }else if (error.hasOwnProperty('success')) {
                enqueueSnackbar({
                  message: "Error "+error.message,
                      variant: 'error',
                      anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 5000, 
                      // persist: true
                  
                  });
              }
            });
          }

        }else{
          enqueueSnackbar({
            message: "Please select a location",
                variant: 'error',
                anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 5000, 
                // persist: true
            
            });
        }
      
      }catch(error){
        enqueueSnackbar(error.message,{ 
          variant: 'error', anchorOrigin: {
            vertical: 'top', // 'top', 'bottom'
            horizontal: 'center', // 'left', 'center', 'right'
          },
          autoHideDuration: 5000, // 5000 milliseconds = 5 seconds
          // persist: true
        })
      }

    };

    // const onFileChange = (event, values) => {
    const onFileChange = (event, values, setFieldValue) => {

      const file = event.target.files[0];

      setUploadFileOpen(false)
  
      if( typeof file === "undefined"){
        return
      }
  
      if (file.type !== 'text/csv') {

        enqueueSnackbar('Please select a CSV file',{ 
          variant: 'error', anchorOrigin: {
            vertical: 'top', // 'top', 'bottom'
            horizontal: 'center', // 'left', 'center', 'right'
          },
          autoHideDuration: 5000, // 5000 milliseconds = 5 seconds
          // persist: true
        })
        return;
      }
  
      if (file.size > 1024 * 1024) {
        alert('File size should not exceed 1MB.');
        return;
      }
  
      // setSelectedFile(file)

      // const formikContext = useFormikContext();
      // console.log(formikContext.values)
  
      const reader = new FileReader();
          reader.onloadend = (event) => {
            // setReadFile(reader.result);
                var binaryString = '',
                    bytes = new Uint8Array(event.target.result),
                    length = bytes.byteLength,
                    i,
                    base64String;
        
                // convert to binary string
                for (i = 0; i < length; i++) {
                    binaryString += String.fromCharCode(bytes[i]);
                }
        
                // convert to base64
                base64String = btoa(binaryString);

                var submitJson = {};
                submitJson["fileName"] = event.target.fileName;
                submitJson["fileType"] = event.target.fileType;
                submitJson["data"] = base64String;

                fileUpload(navigate)(submitJson)
                  .then((result) => {

                    if (result.success) {

                      for(let tmpVisitor of result.data){
                        // setVisitors([...visitors, tmpVisitor]);
                        // values.visitors.push(tmpVisitor)
                        const updatedVisitors = [...result.data, ...(values.visitors || [])];
                        setFieldValue('visitors', updatedVisitors);

                      }
                      setInitialValuesRegister(values)
                    }
                    
                    
                    document.getElementById("fileInput").value = null;
                  })
                  .catch((error) => {
                    document.getElementById("fileInput").value = null;
                  });


          };

      reader.fileName = file.name
			reader.fileType = file.type
			reader.readAsArrayBuffer(file);
  
    };

    const isSelectedTimeOk = () => {
        
        var isOk = false;
        const now = dayjs();
        // if (validFromDayJS.isBefore(dayjs())) {
  
          if(validFromDayJS.isAfter(validToDayJS)){
            
                // Ext.Msg.alert(td('errTltDateRange', 'Date range wrong.'), td('errDateRange', 'The valid to date cannot be smaller then the valid from date.'), Ext.emptyFn);
                enqueueSnackbar('The valid to date cannot be smaller then the valid from date.',{ 
                  variant: 'error', anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 5000, // 5000 milliseconds = 5 seconds
                  // persist: true
                })
    
          }else{
              return true;
          }
  
  
        // }else{
        //   // Ext.Msg.alert(td('errTltDateRange', 'Date range wrong.'), td('errDateRange', 'The valid to date cannot be smaller then the valid from date.'), Ext.emptyFn);
        //     enqueueSnackbar('The valid to date cannot be smaller then the valid from date.',{ 
        //       variant: 'error', anchorOrigin: {
        //         vertical: 'top', // 'top', 'bottom'
        //         horizontal: 'center', // 'left', 'center', 'right'
        //       },
        //       autoHideDuration: 5000, // 5000 milliseconds = 5 seconds
        //       // persist: true
        //     })
        // }
  
        
  
        return isOk;
      }

  return (
    <Box m={isNonMobile ? "1.5rem 2.5rem" : "1rem 1rem"}>
      
      <Header title={tm.translate("btnNewVisit", "New Visit")} subtitle="" buttons={null} backbutton/>
        
      {initialValuesReady ? (
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValuesRegister}
        validationSchema={ validationSchema}
        enableReinitialize={false} // Prevent resetting the form when initialValuesRegister changes
        validateOnChange={false} // Disable validation on change
        validateOnBlur={false}   // Disable validation on blur
      >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (

        

        <form onSubmit={handleSubmit}>

        <DialogUploadCvs
            open={uploadFileOpen}
            onClose={() => setUploadFileOpen(false)}
            onSave={(entity) => {   setUploadFileOpen(false);  
                                    // setLoadedFormData({
                                    //     ...loadedFormData,
                                    //     picturePath: entity.data.picturePath
                                    // });
                            }}
            theme={theme}
            onFileChange={(event) => onFileChange(event, values, setFieldValue)} // Pass setFieldValue and values
            values={values} // Pass Formik values
        />



        {/*   --------Location----------   */}

        <Typography variant="h5" color={theme.palette.secondary[300]} mt="15px">
          {tm.translate("lblRegisterVisitorWhere", "Where is the visit?")}
        </Typography>

        <Box sx={{ ...commonStyles, border: isNonMobile ? 1 : 0 , p: isNonMobile ? 5 : 2 }}>   
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                    >

                    <Autocomplete
                      options={allAvailableFrontDesks}
                      value={allAvailableFrontDesks.find(fd => fd.entityId === values.visitLocationEntityId) || null}
                      onChange={(event, newValue) => {
                        setFieldValue('visitLocationEntityId', newValue ? newValue.entityId : '');
                        if(newValue != null){
                          setSelectedFrontDeskEntityId(newValue.entityId);
                        }

                      }}
                      isOptionEqualToValue={(option, value) => option.entityId === value.entityId}
                      getOptionLabel={(option) =>
                        typeof option === 'string'
                          ? option
                          : `${option.fullName}`
                      }
                      inputValue={inputValueFrontDesk}
                      onInputChange={(event, newInputValue) => {
                        setInputValueFrontDesk(newInputValue);
                      }}
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          label={tm.translate("cmbFrontDesk", "Location")}
                          theme={theme}
                        />
                      )}
                      sx={{ gridColumn: "span 4" }}
                    />


                    </Box>
            
        </Box>

        {/*   --------DATE RANGE----------   */}

        <Typography variant="h5" color={theme.palette.secondary[300]} mt="15px">
          {tm.translate("lblRegisterVisitorWhen", "When is the visit?")}
        </Typography>

        <Box sx={{ ...commonStyles, border: isNonMobile ? 1 : 0 , p: isNonMobile ? 5 : 2 }}>   
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                    >

                      {isNonMobile ? (
                          <>
                          
                              <CssMobileDateTimePicker onAccept={handleValidFromDateChange}
                                                    theme={theme}
                                                    minDateTime={validMinNowJS}
                                                    value={validFromDayJS}
                                                    label={tm.translate('dateValidFrom', "Valid From")} 
                                                    sx={{ gridColumn: "span 2" }} />
                              <CssMobileDateTimePicker onAccept={handleValidToDateChange}
                                                    theme={theme}
                                                    minDateTime={validMinNowJS}
                                                    value={validToDayJS}
                                                    label={tm.translate('dateValidTo', "Valid To")} 
                                                    sx={{ gridColumn: "span 2" }} />
                          </>
                      ) : (
                          <>
                              <CssDateTimePicker onAccept={handleValidFromDateChange}
                                              theme={theme}
                                              minDateTime={validMinNowJS}
                                              value={validFromDayJS}
                                              label={tm.translate('dateValidFrom', "Valid From")} 
                                              sx={{ gridColumn: "span 2" }} />
                              <CssDateTimePicker onAccept={handleValidToDateChange}
                                              theme={theme}
                                              minDateTime={validMinNowJS}
                                              value={validToDayJS}
                                              label={tm.translate('dateValidTo', "Valid To")} 
                                              sx={{ gridColumn: "span 2" }} />
                          </>
                      )}

                    </Box>
            
        </Box>

        {/*   --------ALL VISITORS----------   */}

        <Typography variant="h5" color={theme.palette.secondary[300]}>
          {tm.translate("pnlAllVisitVisitors", "All Visitors")}
        </Typography>

        <Box sx={{ ...commonStyles, border: isNonMobile ? 1 : 0 , p: isNonMobile ? 5 : 2 }}>   

          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

        <Box display="flex"
                flexDirection="row" // Render elements horizontally
                alignItems="center"
                sx={{
                  gridColumn: "span 4",
                  
                }}>
            <Button
              fullWidth
              onClick={() => addVisitorHandle(setFieldValue, values)} // Pass setFieldValue and values explicitly
              sx={{
                m: isNonMobile ? "1rem 1rem 1rem 0rem" : "0rem", // Different margin based on isNonMobile
                p: "1rem",
                backgroundColor: palette.secondary.light,
                color: palette.background.alt,
                gridColumn: "span 2",
                fontWeight: 'bold',
                "&:hover": { 
                  color: sadevioColors.hexColorCheckedIn,
                  backgroundColor: palette.secondary.light,
                  fontWeight: 'bold'
                  
                },
              }}
            >
            {tm.translate('btnAddVisitor', "Add Visitor")}
            </Button>
            { isNonMobile && (
            <Button
              fullWidth
              onClick={() => setUploadFileOpen(true)} // Pass setFieldValue and values explicitly
              sx={{
                m: isNonMobile ? "1rem 1rem 1rem 0rem" : "0rem", // Different margin based on isNonMobile
                p: "1rem",
                backgroundColor: palette.secondary.dark,
                color: palette.background.alt,
                gridColumn: "span 2",
                fontWeight: 'bold',
                "&:hover": { 
                  color: sadevioColors.hexColorCheckedIn,
                  backgroundColor: palette.secondary.light,
                  fontWeight: 'bold'
                  
                },
              }}
            >
              { "IMPORT"}
            </Button>
            )}
            {/* {isNonMobile && (
              
              <div>           
                  <CSVFileUpload
                      onFileChange={onFileChange}
                      buttonText="IMPORT"
                      values={values}
                    />
            </div>
              
              )} */}
          </Box>

          { isNonMobile && (
            <Typography
                onClick={() => {
                  window.location.href = "/resources/importer_template/importer_example_template_preregvisitor.csv";
                }}
                sx={{
                  textDecoration: "underline",
                  color: palette.secondary.light,
                  gridColumn: "span 4",
                  "&:hover": {
                    cursor: "pointer",
                    color: palette.secondary.light,
                    fontWeight: 'bold'
                  },
                }}
              >
                {tm.translate("lblPreregTemplateDownload", "Download template file. First name and last name are required")}
              </Typography>
          )}

            <Divider style={{ gridColumn: "span 4", margin: '20px 0', height: '1px', backgroundColor: theme.palette.secondary.light }} />

            {/* Render VisitorVisitInvite for each visitor */}
            {values.visitors?.map((visitor, index) => (
              
              <VisitorVisitInvite
                key={index}
                values={values}
                index={index}
                handleChange={handleChange}
                handleBlur={handleBlur}
                removeVisitor={removeVisitor}
                errors={errors}  // Pass the errors prop obtained from Formik
                touched={touched} // Pass the touched prop obtained from Formik
                // additionalFields={additionalFields}
                // defaultVisitorType={defaultVisitorType}
                // allVisitorTypes={allVisitorTypes}
                setFieldValue={setFieldValue}
                selectedFrontDeskTerminalSettings={selectedFrontDeskTerminalSettings}
              />
            ))}



          </Box>

          <Divider style={{ margin: '20px 0', height: '1px', backgroundColor: theme.palette.secondary.light }} />

          </Box>

            {/*   --------ALL HOSTS----------   */}



            <Typography variant="h5" color={theme.palette.secondary[300]}>
              {tm.translate("lblAllHosts", "All Hosts")}
            </Typography>

  <Box sx={{ ...commonStyles, border: isNonMobile ? 1 : 0 , p: isNonMobile ? 5 : 2 }}>   

      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >

        <Autocomplete
          freeSolo
          options={options}
          value={selectedValue}
          sx={{ gridColumn: "span 4" }}
          filterOptions={(x) => x}
          onChange={handleOptionSelect}
          inputValue={inputValue}
          onInputChange={handleSearchQueryChange}
          getOptionLabel={(option) =>
            option.fullName + " " + option.email
          }
          renderInput={(params) => <CssTextField {...params} label={tm.translate("lblSearchHost", "Search employee...")} theme={theme} />}
          renderOption={(props, option) => (
              <Box {...props} display="flex" key={option.entityId || option.email} alignItems="center" justifyContent="space-between">
                  <ListItemText primary={`${option.fullName} (${option.email})`} />
                  <IconButton onClick={() => handleAddItem(option)} edge="end" aria-label="add">
                      <Add />
                  </IconButton>
              </Box>
          )}          
        />

        <List dense sx={{ gridColumn: "span 4" , bgcolor: 'background.paper' }}>
                {selectedHosts.map((value, index) => {
                    const labelId = `checkbox-list-secondary-label-${index}`;

                    var isLoggedInUser = false
                    if(typeof value.isLoggedInUser !== "undefined"){
                        isLoggedInUser = true;
                    }

                    const handleRemoveHost = () => {
                        // Create a new array without the item to be removed
                        const updatedHosts = [...selectedHosts.slice(0, index), ...selectedHosts.slice(index + 1)];
                        // Update the state with the new array
                        setSelectedHosts(updatedHosts);

                         // Create a new array without the item to be removed
                         const updatedValue = [...selectedValues.slice(0, index), ...selectedValues.slice(index + 1)];
                         // Update the state with the new array
                         setSelectedValues(updatedValue);
                    };

                    const picturePath = value.picturePath ? value.picturePath : ""; // Check if picturePath is null
                    return (
                    <ListItem
                        key={labelId}
                        // secondaryAction={
                        // <IconButton>
                        //     <Clear style={{ color: sadevioColors.hexColorInvited }}/> 
                        // </IconButton>
                        // }
                        disablePadding
                    >
                        <ListItemButton>
                        <ListItemAvatar>
                            <Avatar  {...stringAvatar(value.fullName)}
                            alt={`Avatar n°${value.fullName}`}
                            src={`${getUri(getSessionToken())}/host/${value.entityId}/picture/${picturePath}`}
                            />
                           
                        </ListItemAvatar >
                            <ListItemText primary={value.fullName} />
                        </ListItemButton>
                        {!isLoggedInUser && (
                            <IconButton onClick={handleRemoveHost}>
                                <Clear style={{ color: sadevioColors.hexColorInvited }}/> 
                            </IconButton>
                        )}
                    </ListItem>
                    );
                })}
        </List>
        </Box>

        </Box>


        <Typography variant="h5" color={theme.palette.secondary[300]}>
              {tm.translate("lblRegisterVisitorInternalNote", "Internal notes for front desk")}
        </Typography>

        <Box sx={{ ...commonStyles, border: isNonMobile ? 1 : 0 , p: isNonMobile ? 5 : 2 }}>   

          <CssTextField
              multiline  // Enable multiline input
              rows={3}   // Initial number of rows to display
              label={tm.translate("txtAreaFrontDesk", "Internal Notes")}
              value={values.internalNote}
              name="internalNote"
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth  // Take up full width of the container
              variant="outlined" // or "filled", depending on your design preference
              sx={{ gridColumn: "span 4", }}
              theme={theme} 
          />

        </Box>



        <Typography variant="h5" color={theme.palette.secondary[300]}>
          {tm.translate("lblNotificatios", "Notifications")}
        </Typography>

        <Box sx={{ ...commonStyles, border: isNonMobile ? 1 : 0 , p: isNonMobile ? 5 : 2 }}>   
        <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
          <FormControlLabel
              sx={{ gridColumn: "span 4" }}
              control={
                  <IOSSwitch sx={{ m: 1 }} 
                              value={values.sendEmailToHosts}
                              onChange={handleChange}
                              name="sendEmailToHosts"
                              // onChange={(value) => {  
                              //                         // console.log(value.target.checked); 
                              //                         setSendEmailToHosts(value.target.checked )
                              //                       }
                              //                     }
                  />
              }
              label={<span style={{ color: theme.palette.secondary.light }}>{tm.translate("chkSendInviteToHosts", "Send summary to host")}</span>}
          />

            <EmailManager
                title={tm.translate("txtVisitSummaryTo", "Send summary to email addresss")}
                value={values.visitSummaryTo}
                onChange={(newValue) => setFieldValue('visitSummaryTo', newValue)}
                sx={{ mb: 2, gridColumn: "span 4" }}
              />
              {touched.visitSummaryTo && errors.visitSummaryTo && (
                <Typography variant="caption" color="error">
                  {errors.visitSummaryTo}
                </Typography>
              )}
        </Box>
        </Box>

        <Button
              fullWidth
              type="submit"
              sx={{
                m: "2rem 0",
                p: "1rem",
                backgroundColor: palette.secondary.light,
                color: palette.background.alt,
                "&:hover": { 
                  color: palette.primary.main,
                  backgroundColor: palette.secondary.light,
                  fontWeight: 'bold'
                },
              }}
            >
              {tm.translate("btnFinishRegistration", "FINISH REGISTRATION")}
            </Button>

        </form>
      )}
    </Formik>

    ) : (
      // Optional: Render a loading indicator while waiting
      <Typography variant="h6" color="primary">
        Init...
      </Typography>
    )}
    
    </Box>
  );
};

export default VisitNewInvitation;
