class CookieManager {
    static instance;
    initialData = { 
    //   username: '',
      // Add more variables as needed,
      visitorStatusFilter: {filterInvited: true, filterWaiting: true, filterConfirmed: true, filterCheckedIn : true, filterCheckedOut: true, filterUnderReview: false, filterDenied: false, filterRejected: false }
    };
  
    constructor() {
      if (CookieManager.instance) {
        return CookieManager.instance;
      }
  
      this.cookieName = 'sadevio_dashboard_v2';
      CookieManager.instance = this;
  
      // Initialize the cookie with initial data if it doesn't exist
      const existingData = this.readValue();
      if (!existingData) {
        this.saveValue(this.initialData);
      }
    }
  
    // Helper function to set a cookie value
    setCookie(name, value, days) {
      const expires = new Date();
      expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
      const cookieOptions = `expires=${expires.toUTCString()};path=/;SameSite=strict;`;
      document.cookie = `${name}=${JSON.stringify(value)};${cookieOptions}`;
    }
  
    // Helper function to get a cookie value
    getCookie(name) {
      const cookies = document.cookie.split(';');
      for (let cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split('=');
        if (cookieName.trim() === name) {
          return JSON.parse(cookieValue);
        }
      }
      return null;
    }
  
    // Function to save value to cookie
    saveValue(value) {
      const mergedData = { ...this.initialData, ...value };
      this.setCookie(this.cookieName, mergedData, 100); // 100 days expiration
    }
  
    // Function to read value from cookie
    readValue() {
      return this.getCookie(this.cookieName);
    }
  }
  
  // Usage:
  const cookieManager = new CookieManager();
  
  export default cookieManager;
  