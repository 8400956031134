import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControlLabel,
  ListItemIcon,
  Tooltip,
  Button,
  useTheme,
  RadioGroup,
  Radio,
  Autocomplete,
  TextField,
  styled,
  useMediaQuery,
  List,
  ListItem,
  Checkbox,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
} from "@mui/material";
import {
  Add,
  Clear,
} from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { autocompleteHost, getWebUsers, postCompany } from "hooks/HttpRequests";
import Header from "components/Header";
import { Formik } from "formik";
import * as yup from "yup";
import tm from "components/TranslationManager";
import dayjs from "dayjs";
import { sadevioColors } from "components/Colors";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import {CssDateRangePicker, CssTextField} from "components/StyledComponents";
import { useNavigate } from "react-router-dom";

const CompanyNew = ({ globalType, id }) => {

  const navigate = useNavigate();

  const { palette } = useTheme();
  const theme = useTheme();
  const [availableEmployes, setAvailableEmployes] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const defaultCompanyStatus = { label: "Active", value: "active" };
  const defaultRenewalPeriod = { label: "Always Active", value: "always" };
  const defaultCategory = { label: "Airline", value: "airline" };

  const [searchQuery, setSearchQuery] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedHosts, setSelectedHosts] = useState([]);

  const [showReviewers, setShowReviewers] = useState(false);

  const toggleReviewers = () => {
    setShowReviewers(!showReviewers);
  };


  //////////////////////
  useEffect(() => {
    loadVisitorTypes();
  }, []); // Empty dependency array to fetch options only once on component mount

  const handleInputChange = (event, value) => {
    console.log("handleInputChange " + value);
  };

  const loadVisitorTypes = async () => {
    setGridLoading(true);

    //do an autocomplete function

    getWebUsers(navigate)(1)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {
          console.log(result);

          setAvailableEmployes(result.data);
        }
        setGridLoading(false);
      })
      .catch((error) => {
        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }

        setGridLoading(false);
      });
  };

  const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const [requireCBP, setRequireCBP] = useState(false);
  const [requireLEO, setRequireLEO] = useState(false);
  const [requireBilling, setRequireBilling] = useState(false);
  const [requireAOA, setRequireAOA] = useState(false);
  const [exemptChrc, setExemptChrc] = useState(false);
  const [exemptSta, setExemptSta] = useState(false);

  const registerSchema = yup.object().shape({
    name: yup.string().required("required"),
    status: yup.string().required("required"),
    address1: yup.string().required("required"),
    // category: yup.object().nullable().required("required"), // Adjusted validation for category

    // badgeRenewalPeriod: yup.string().required("required"),
    minAuthSignatoryCount: yup.number().required("required"),
    maxAuthSignatoryCount: yup.number().required("required"),

  });

  const initialValuesRegister = {
    status: "active",
    name: "",
    address1: "",
    badgeRenewalPeriod: "",
    category: null,
    minAuthSignatoryCount: 1,
    maxAuthSignatoryCount: 1,
    exemptChrc: false,
    exemptSta: false,

  };

  const today = dayjs();
  const yesterday = dayjs().subtract(1, "day");
  const todayStartOfTheDay = today.startOf("day");
  const todayOneYear = dayjs().set("hour", 12).add(1, 'year');
  const todayAt9AM = dayjs().set("hour", 9).startOf("hour");

  const [checkedBadgeTypes, setChecked] = React.useState([]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checkedBadgeTypes.indexOf(value);
    const newChecked = [...checkedBadgeTypes];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    

    setChecked(newChecked);

  };

  // Function to handle search query change
  const handleSearchQueryChange = (event, newValue) => {
    setSearchQuery(newValue);
    // Call your API to fetch search results based on the newValue
    // Update setSearchResults with the response from the server
    console.log(newValue);

    autocompleteHost(navigate)(newValue)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {
          // if(lastChangesLoaded !== result.data){
          //     lastChangesLoaded = result.data;
          //     loadGridData();
          // }
          setSearchResults(result.data);
        }
      })
      .catch((error) => {
        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }
      });
  };

  const handleSelectOption = (newValue) => {
    if (newValue != null) {
      var tmp = selectedHosts;
      var tmp = selectedHosts;
      tmp.push(newValue);
      setSelectedHosts(tmp);
      setSearchQuery(null);
    } else {
      setSearchQuery(null);
    }
  };
  // Function to handle adding an item to selectedItems
  const handleAddItem = (item) => {
    setSelectedItems([...selectedItems, item]);
    // You may also want to update the server/database with the added item
  };

  const handleDateChange = (item) => {
    console.log(item);
  }

  const handleSubmit = (values) => {
    // Perform your post request with the combined form values
    console.log(values);

    values["minAuthSignatoryCount"] = parseInt(values.minAuthSignatoryCount)
    values["maxAuthSignatoryCount"] = parseInt(values.maxAuthSignatoryCount)


    values["exemptSta"] = exemptSta;
    values["exemptChrc"] = exemptChrc

    values["validFrom"] = "";
    values["validTo"] = "";

    values["frontDeskEntityId"] = 1;

    console.log(checkedBadgeTypes)
    console.log(requireAOA)
    console.log(requireBilling)
    console.log(requireCBP)
    console.log(requireLEO)

    values["cbpAccess"] = false;

    var submitData= {}
    submitData["data"]= values;

    postCompany(navigate)(submitData)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {
          // if(lastChangesLoaded !== result.data){
          //     lastChangesLoaded = result.data;
          //     loadGridData();
          // }
          // setSearchResults(result.data);

          navigate('/identity/company/'+result.data.entityId)

        }
      })
      .catch((error) => {
        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }
      });

  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="New Company" subtitle="" buttons={null} />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValuesRegister}
        validationSchema={registerSchema}
        enableReinitialize={true} 
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
          setValues
        }) => (
          <form onSubmit={handleSubmit}>
            {/*  --------------  company details  */}
            <Typography variant="h5" color={theme.palette.secondary[300]}>
              Company details
            </Typography>
            <Box sx={{ ...commonStyles, border: 1, p: 5 }}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <Autocomplete
                  disablePortal
                  disableClearable
                  disableInput
                  autoSelect // Automatically selects the highlighted option on blu
                  options={[
                    defaultCompanyStatus,
                    { label: "Pending", value: "pending" },
                    { label: "Deactivated", value: "deactivated" },
                  ]}
                  defaultValue={defaultCompanyStatus}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  sx={{ gridColumn: "span 4" }}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      required
                      name="status"
                      label={tm.translate("dspCompanyStatys", "Copmany Status")}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.status}
                      error={ Boolean(touched.status) && Boolean(errors.status)}
                      helperText={touched.status && errors.status}
                      theme={theme} 
                    />
                  )}
                />
                <CssTextField
                  label="Company Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={ Boolean(touched.name) && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 4" }}
                  theme={theme} 
                />
                <CssTextField
                  label="Address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address1}
                  name="address1"
                  error={Boolean(touched.address1) && Boolean(errors.address1)}
                  helperText={touched.address1 && errors.address1}
                  sx={{ gridColumn: "span 4" }}
                  theme={theme} 
                />

                <CssDateRangePicker
                  defaultValue={[todayAt9AM, todayOneYear]}
                  onBlur={handleBlur}
                  onChange={handleDateChange}
                  slotProps={{
                    textField: ({ position }) => ({
                      label: position === "start" ? "Valid From" : "Valid To",
                    }),
                  }}
                  sx={{ gridColumn: "span 4" }}
                  theme={theme} 
                />
                <Autocomplete
                  key={1124}
                  disablePortal
                  disableClearable
                  disableInput
                  onChange={(event, newValue) => setFieldValue('category', newValue.value)} // Update category value
                  value={values.category}
                  autoSelect // Automatically selects the highlighted option on blu
                  options={[
                    defaultCategory,
                    { label: "Concessionaire", value: "concessionaire" },
                  ]}
                  defaultValue={defaultCategory}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  sx={{ gridColumn: "span 4" }}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      required
                      label={tm.translate("dspCategory", "Category")}
                      sx={{
                        "& label.Mui-focused": {
                          color: theme.palette.secondary[200],
                        },
                        "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root":
                          {
                            color: theme.palette.secondary[300],
                          },
                      }}
                      name="category"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.category}
                      error={ Boolean(touched.category) && Boolean(errors.category)}
                      helperText={touched.category && errors.category}
                      theme={theme} 
                    />
                  )}
         
                />
                <FormControlLabel sx={{gridColumn: "span 2", }}
                  control={
                    <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                      <Checkbox 
                          onChange={(event) => setExemptChrc(event.target.checked)}
                          sx={{
                            "&.Mui-checked": { // Adjust the color when checked
                              color: sadevioColors.hexColorInvited+' !important',
                            },
                          }}
                        />
                    </Box>
                    
                  }
                  label={" "+tm.translate('lblCHRCexempt', "Is CHRC exempt (criminal history records checks)")}
                />
                <FormControlLabel sx={{gridColumn: "span 2", }}
                  control={
                    <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                      <Checkbox 
                          onChange={(event) => setExemptSta(event.target.checked)}
                          sx={{
                            "&.Mui-checked": { // Adjust the color when checked
                              color: sadevioColors.hexColorInvited+' !important',
                            },
                          }}
                        />
                    </Box>
                    
                  }
                  label={" "+tm.translate('lblSTAexempt', "is STA exempt (Security Threat Assessment)")}
                />
                <FormControlLabel sx={{gridColumn: "span 4", }}
                  control={
                    <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                      <Checkbox 
                          onChange={(event) => setRequireBilling(event.target.checked)}
                          sx={{
                            "&.Mui-checked": { // Adjust the color when checked
                              color: sadevioColors.hexColorInvited+' !important',
                            },
                          }}
                        />
                    </Box>
                  }
                  label={" "+tm.translate('lblDirectBillinf', "Direct Billing")}
                />
              </Box>
            </Box>

            {/*  --------------  Signatories  */}

            <Typography variant="h5" color={theme.palette.secondary[300]}>
              Authorized Signatories
            </Typography>
            <Box sx={{ ...commonStyles, border: 1, p: 5 }}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >

                <CssTextField
                  label="Min Authorized Signatory Count"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.minAuthSignatoryCount}
                  name="minAuthSignatoryCount"
                  error={ Boolean(touched.minAuthSignatoryCount) && Boolean(errors.minAuthSignatoryCount)}
                  helperText={touched.minAuthSignatoryCount && errors.minAuthSignatoryCount}
                  sx={{ gridColumn: "span 2" }}
                  theme={theme} 
                />

                <CssTextField
                  label="Max Authorized Signatory Count"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.maxAuthSignatoryCount}
                  name="maxAuthSignatoryCount"
                  error={ Boolean(touched.maxAuthSignatoryCount) && Boolean(errors.maxAuthSignatoryCount)}
                  helperText={touched.maxAuthSignatoryCount && errors.maxAuthSignatoryCount}
                  sx={{ gridColumn: "span 2" }}
                  theme={theme} 
                />
                <Autocomplete
                  freeSolo
                  options={searchResults}
                  inputValue={searchQuery}
                  value={searchQuery}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  onChange={(event, selectedOption) =>
                    handleSelectOption(selectedOption)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Search host..." />
                  )}
                  getOptionLabel={(option) =>
                    option.fullName + " " + option.email
                  }
                  onInputChange={handleSearchQueryChange}
                  sx={{ gridColumn: "span 4" }}
                  renderOption={(props, option) => (
                    <Box
                      {...props}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <ListItemText
                        primary={`${option.fullName} (${option.email})`}
                      />
                      <IconButton
                        onClick={() => handleAddItem(option)}
                        edge="end"
                        aria-label="add"
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  )}
                  clearOnBlur={true}
                />

                <List
                  dense
                  sx={{ gridColumn: "span 4", bgcolor: "background.paper" }}
                >
                  {selectedHosts.map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value}`;
                    return (
                      <ListItem
                        key={value}
                        secondaryAction={
                          <IconButton>
                            <Clear
                              style={{ color: sadevioColors.hexColorInvited }}
                            />
                          </IconButton>
                        }
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemAvatar>
                            <Avatar
                              alt={`Avatar n°${value + 1}`}
                              src={`/static/images/avatar/${value + 1}.jpg`}
                            />
                          </ListItemAvatar>
                          <ListItemText id={labelId} primary={value.fullName} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Box>

            {/*  --------------  nadging  */}
            <Typography variant="h5" color={theme.palette.secondary[300]}>
              Access cards
            </Typography>
            <Box sx={{ ...commonStyles, border: 1, p: 5 }}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
               
                <Autocomplete
                  key={3}
                  disablePortal
                  disableClearable
                  disableInput
                  onChange={(event, newValue) => setFieldValue('badgeRenewalPeriod', newValue.value)} // Update category value
                  value={values.badgeRenewalPeriod}
                  autoSelect // Automatically selects the highlighted option on blu
                  options={[
                    defaultRenewalPeriod,
                    { label: "3 month", value: "3_m" },
                    { label: "6 month", value: "6_m" },
                    { label: "1 year", value: "1_y" },
                    { label: "2 year", value: "2_y" },
                  ]}
                  defaultValue={defaultRenewalPeriod}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  sx={{ gridColumn: "span 4" }}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      required
                      name="badgeRenewalPeriod"
                      label={tm.translate("dspBadgeRenewalPeriod", "Renewal Period")}
                      theme={theme} 
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.badgeRenewalPeriod}
                      error={ Boolean(touched.badgeRenewalPeriod) && Boolean(errors.badgeRenewalPeriod)}
                      helperText={touched.badgeRenewalPeriod && errors.badgeRenewalPeriod}
                    />
                  )}
                />
                <Typography variant="h5" color={theme.palette.secondary[300]}>
                  Authorized Badge Types
                </Typography>
                <List
                  sx={{
                    gridColumn: "span 4",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  {["Red", "Yellow", "White", "Green", "Pink", "Blue"].map(
                    (value) => {
                      const labelId = `checkbox-list-label-${value}`;

                      return (
                        <ListItem
                          key={value}
                          // secondaryAction={
                          //   <IconButton edge="end" aria-label="comments">
                          //     <CommentIcon />
                          //   </IconButton>
                          // }
                          disablePadding
                        >
                          <ListItemButton
                            role={undefined}
                            onClick={handleToggle(value)}
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={checkedBadgeTypes.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value}`} />
                          </ListItemButton>
                        </ListItem>
                      );
                    }
                  )}
                </List>
              </Box>
            </Box>
            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
            <FormControlLabel sx={{gridColumn: "span 4", }}
              control={
                <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                  <Checkbox 
                      onChange={(event) => setRequireCBP(event.target.checked)}
                      sx={{
                        "&.Mui-checked": { // Adjust the color when checked
                          color: sadevioColors.hexColorInvited+' !important',
                        },
                      }}
                    />
                </Box>
                
              }
              label={" "+tm.translate('lblRequrieCBP', "Does require CBP (Customs Border Protection) access")}
            />
            <FormControlLabel sx={{gridColumn: "span 4", }}
              control={
                <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                  <Checkbox 
                      onChange={(event) => setRequireLEO(event.target.checked)}
                      sx={{
                        "&.Mui-checked": { // Adjust the color when checked
                          color: sadevioColors.hexColorInvited+' !important',
                        },
                      }}
                    />
                </Box>
              }
              label={" "+tm.translate('lblRequrieLEO', "Does require LEO (Law Enforcement Officer) designation")}
            />
            <FormControlLabel sx={{gridColumn: "span 4", }}
              control={
                <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                  <Checkbox 
                      onChange={(event) => setRequireAOA(event.target.checked)}
                      sx={{
                        "&.Mui-checked": { // Adjust the color when checked
                          color: sadevioColors.hexColorInvited+' !important',
                        },
                      }}
                    />
                </Box>
              }
              label={" "+tm.translate('lblRequrieAOA', "Does require AOA (Airport Operations Area) parking")}
            />
            </Box>

            <Button
              fullWidth
              type="submit"
              sx={{
                m: "2rem 0",
                p: "1rem",
                backgroundColor: palette.secondary.light,
                color: palette.background.alt,
                "&:hover": {
                  color: palette.primary.main,
                  backgroundColor: palette.secondary.light,
                  fontWeight: "bold",
                },
              }}
            >
              {"CREATE COMPANY"}
            </Button>
          </form>
        )}
      </Formik>

      
      
    </Box>
  );
};

export default CompanyNew;
