import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@mui/material";
import React from 'react'
import tm from 'components/TranslationManager';


const DialogYesNo = ({ open, onClose, onConfirm, theme, message = "", entity }) => {
  
  return (
    <Dialog open={open} onClose={onClose}>
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>{tm.translate('lblConfirmed', "Confirm")}</DialogTitle>
        <DialogContent>
          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" sx={{ color: theme.palette.background.alt }}>
            {tm.translate('btnNo', "No")}
          </Button>
          <Button 
              onClick={() => onConfirm(entity)} 
              color="primary" 
              autoFocus sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
            {tm.translate('btnYes', "Yes")}
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogYesNo