import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { InputLabel, RadioGroup, Select, Stepper } from '@mui/material';
import { DatePicker, DateRangePicker, DateTimePicker, MobileDateTimePicker } from '@mui/x-date-pickers-pro';
import MuiPhoneNumber from 'mui-phone-number';

export const commonStyles = (theme) => ({
  m: '0.5rem 0rem 0.5rem 0rem',
  borderRadius: 1,
  borderColor: theme.palette.secondary[100],
});


const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  if(typeof string ==='undefined' || string == null || string == ""){
    return "#ffff"
  }

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

export const stringAvatar = (name: string) => {

  var generateInitials = "";

  if(typeof name !== "undefined" && name != null){
    const splitName = name.toUpperCase().split(' ');
  
    if (splitName.length === 1) {
        generateInitials = splitName[0][0];
    } else {
        generateInitials = splitName[0][0]+splitName[1][0];
    }
  }

  const generatedColor = stringToColor(name);

  return {
    sx: {
      bgcolor: generatedColor,
    },
    // children: `${name.toUpperCase().split(' ')[0][0]}${name.toUpperCase().split(' ')[1][0]}`,
    children: `${generateInitials}`,
  };
}

export const CssInputLabel = styled(InputLabel)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: "gray", // Default label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: theme.palette.secondary[300],
  },
  "&:hover .MuiInputLabel-root": {
    color: theme.palette.secondary[300],
  },
}));



export const CssTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.secondary[200],
  },
  "&:hover label": {
    color: theme.palette.secondary[300],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
      
    },
    "&:hover fieldset": {
      borderColor: theme.palette.secondary[300],
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.secondary[200],
    },
    "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
      color: theme.palette.secondary[300],
    },
    
  },
}));

export const CssDatePicker = styled(DatePicker)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.secondary[200],
  },
  "&:hover label": {
    color: theme.palette.secondary[300],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
      
    },
    "&:hover fieldset": {
      borderColor: theme.palette.secondary[300],
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.secondary[200],
    },
    "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
      color: theme.palette.secondary[300],
    },
    
  },
}));

export const CssStepper = styled(Stepper)(({ theme }) => ({
  '.MuiStepLabel-label': {
    color: 'gray', // Default color
    
    '&.Mui-active': {
      color: theme.palette.background.alt, // Active step color
      fontWeight: 'bold'
    },
    '&.Mui-completed': {
      color: 'green', // Completed step color
      fontWeight: 'bold'
    },
  },
  '.MuiStepIcon-root': {
    color: 'gray', // Default step icon color
    '&.Mui-active': {
      color: theme.palette.background.alt, // Active step icon color
    },
    '&.Mui-completed': {
      color: 'green', // Completed step icon color
    },
  },
}));

export const CssDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.secondary[200],
  },
  "&:hover label": {
    color: theme.palette.secondary[300],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
      
    },
    "&:hover fieldset": {
      borderColor: theme.palette.secondary[300],
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.secondary[200],
    },
    "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
      color: theme.palette.secondary[300],
    },
    
  },
}));

export const CssMobileDateTimePicker = styled(MobileDateTimePicker)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.secondary[200],
  },
  "&:hover label": {
    color: theme.palette.secondary[300],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
      
    },
    "&:hover fieldset": {
      borderColor: theme.palette.secondary[300],
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.secondary[200],
    },
    "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
      color: theme.palette.secondary[300],
    },
    
  },
}));




export const CssSelect = styled(Select)(({ theme }) => ({
  // 'label + &': {
  //   color: "red !important", // Label color
  // },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E0E3E7 !important`,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: `${theme.palette.secondary[300]} !important`,
  },
  "& label.Mui-focused": {
    color: theme.palette.secondary[200],
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: `${theme.palette.secondary[200]} !important`,
  },
  "& .MuiInputLabel-root": {
    color: "red !important", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "green !important", // Focused label color
  },
}));




export const CssMuiPhoneNumber = styled(MuiPhoneNumber)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.secondary[200],
  },
  "&:hover label": {
    color: theme.palette.secondary[300],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
      
    },
    "&:hover fieldset": {
      borderColor: theme.palette.secondary[300],
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.secondary[200],
    },
    "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
      color: theme.palette.secondary[300],
    },
    
  },
}));

export const CssTextFieldReverted = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.primary[600],
  },
  "& label": {
    color:  theme.palette.primary[400],
  },
  "&:hover label": {
    color: theme.palette.primary[500],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.primary[600],
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primary[600],
      
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary[600],
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary[800],
    },
    "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
      color: theme.palette.primary[600],
    },
    "& .MuiInputBase-input": { 
      color:  theme.palette.primary[600],
    },
  },
}));

export const CssDateRangePicker = styled(DateRangePicker)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.secondary[200],
  },
  "&:hover label": {
    color: theme.palette.secondary[300],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.secondary[300],
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.secondary[200],
    },
    "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
      color: theme.palette.secondary[300],
    },
  },
}));


export const CssRadioGroup = styled(RadioGroup)(({ theme }) => ({
  "& .MuiDataGrid-root": {
    border: "none",
    },
    "& .MuiDataGrid-cell": {
    borderBottom: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
    backgroundColor: theme.palette.background.alt,
    color: theme.palette.secondary[100],
    borderBottom: "none",
    },
    "& .MuiDataGrid-virtualScroller": {
    backgroundColor: theme.palette.primary.light,
    },
    "& .MuiDataGrid-footerContainer": {
    backgroundColor: theme.palette.background.alt,
    color: theme.palette.secondary[100],
    borderTop: "none",
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
    color: `${theme.palette.secondary[200]} !important`,
    },
    "& .MuiDataGrid-panel .MuiButton-text": {
    color: `${theme.palette.secondary[200]} !important`,
    },
    "& .MuiDataGrid-panelWrapper .MuiButton-text": {
    color: `${theme.palette.secondary[200]} !important`,
    },
    "& .MuiTab-root.MuiTab-textColorPrimary.Mui-selected": {
    color: `${theme.palette.secondary[200]} !important`,
    fontWeight: 'bold'
    },
    "& .MuiRadio-colorPrimary.Mui-checked": {
        color: `${theme.palette.secondary[200]} !important`,
    },
    "& .MuiFormControlLabel-label": {
        color: `${theme.palette.secondary[200]} !important`,
    },
}));

export const ReadOnlyLabel = styled('div')({
  backgroundColor: 'lightcoral',
  color: 'white',
  fontWeight: 'bold',
  borderRadius: '20px',
  padding: '5px 10px',
  display: 'inline-block',
  textTransform: 'none',
});