import axios from 'axios';
import { getSessionToken, getUri } from 'hooks/HttpRequests';

  const commonHeaders = {
    'Content-Type': 'application/json',
  };

class TranslationManager {
  translations = {}; // Store translations
  languageCode = ''; // Global language code

  // Initialize translations
  async init(languageCode, frontDeskEntityId) {
    try {
      const token = getSessionToken();

      if (token === '') {
        throw { success: false, action: 'relogin' };
      }

      var url = getUri(token) + `translate?application=main&language=${languageCode}`;
      if(frontDeskEntityId > 0){
        url = getUri(token) + `translate?application=main&language=${languageCode}&front_desk_entity_id=${frontDeskEntityId}`;
      }

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        this.translations = response.data.data.reduce((acc, cur) => {
          acc[cur.keyname] = cur.value;
          return acc;
        }, {});
      } else {
        throw response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async initPreregister(languageCode, frontDeskEntityId) {
    try {
      var token = getSessionToken();

      if (token === '') {
        token = "123"
      }

      var url = getUri(token) + `translate/preregister?application=main&language=${languageCode}`;
      if(frontDeskEntityId > 0){
        url = getUri(token) + `translate/preregister?application=main&language=${languageCode}&front_desk_entity_id=${frontDeskEntityId}`;
      }

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        this.translations = response.data.data.reduce((acc, cur) => {
          acc[cur.keyname] = cur.value;
          return acc;
        }, {});
      } else {
        throw response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  

  

  // Translate function
  translate(keyname, defaultValue = '') {
    return this.translations[keyname] || defaultValue;
  }

  getLanguageCode() {
    if (!this.languageCode) {
      // Fallback to determining language code dynamically (if needed)
      const browserLanguages = navigator.languages || [navigator.language];
      const primaryLanguage = browserLanguages[0];
      const parts = primaryLanguage.split("-");
      this.languageCode = parts[0] ? parts[0].toUpperCase() : "EN"; // Default to EN
    }
    return this.languageCode;
  }
  
}

// Create singleton instance
const tm = new TranslationManager();

export default tm;

// Export translationManager globally
window.tm = tm;