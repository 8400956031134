import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, useTheme, useMediaQuery, Avatar, Link } from '@mui/material';
import {  DirectionsRun, Diversity3, EmojiPeople, Group,} from '@mui/icons-material';
import {  getDashboardVisitorsStatistics, getListValuesPaging, getSessionToken, getUri } from 'hooks/HttpRequests';
import { stringAvatar} from "components/StyledComponents";
import tm from 'components/TranslationManager';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useNavigate } from 'react-router-dom';
import { useFrontDeskManager } from 'components/FrontDeskManager';
import GridCellVisitorStatus from 'components/GridCellVisitorStatus';
import Header from 'components/Header';
import StatBox from 'components/StatBox';
import FlexBetween from 'components/FlexBetween';


const EmployeeBadge = ({ route }) => {

  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  // const { data, isLoading } = useGetDashboardQuery();
  const navigate = useNavigate();
  const { selectedFrontDesk } = useFrontDeskManager();


  useEffect(() => {
    loadDashboardVisitorsStatistics();

  }, []);


  const [totalVisits, setTotalVisits] = useState(0);
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [upcomingVisitors, setUpcomingVisitors] = useState(0);
  const [currentVisits, setCurrentVisits] = useState(0);


  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [],
    query:[]
  })


  const columns = [
    {
      field: "entityId",
      headerName: "Entity Id",
      flex: 1,
      type: 'number'
    },
    {
      field: 'picturePath',
      headerName: 'Picture',
      width: 100,
      renderCell: (params) => (
        params.row.picturePath != null ? (
          <Avatar {...stringAvatar(params.row.fullName)} alt={params.row.fullName} src={`${getUri(getSessionToken())}/host/${params.row.entityId}/picture/${params.row.picturePath}`}          />

        ) : (
          <Avatar {...stringAvatar(params.row.fullName)} />
        )
      ),
    },   
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/employees/${params.row.entityId}`)
          }}
        >
        {params.row.fullName}
      </Link>
      ),
    },
    {
      field: "visitStatus",
      headerName: tm.translate('clmVisitStatus', "Visit Status") ,
      flex: "auto",
      minWidth: 120,
      renderCell: (params) => {
        let statusTranslation = "";
        switch(params.row.visitStatus) {
          case "pre_registered":
            statusTranslation = tm.translate(params.row.visitStatus, "Invited");
            break;
          case "started":
            statusTranslation = tm.translate(params.row.visitStatus, "Started");
            break;
          case "finished":
            statusTranslation = tm.translate(params.row.visitStatus, "Finished");
            break;
          case "canceled":
            statusTranslation = tm.translate(params.row.visitStatus, "Canceled");
              break;
          default:
            statusTranslation = "";
            break;
        }
        return <span>{statusTranslation}</span>;
      }
    },
    {
      field: "visitorStatus",
      headerName: tm.translate('clmVisitorStatus', "Visitor Status"),
      minWidth: 220,
      renderCell: (params) => (
        <GridCellVisitorStatus rowData={params.row} />
      ),
    },
  ];

  const handleFilterModelChange = (filterModel) => {
    // Update the filter model state when it changes

    console.log("handleFilterModelChange()")

    // Convert to the desired structure
    const desiredStructure = filterModel.items.map(item => {
      let mappedOperator = item.operator;
    
      let field = columns.find(column => column.field === item.field);
      var fieldType = "string";

      if(typeof field.type !== 'undefined'){
        fieldType = field.type;
      }

      // Map operators as needed
      if (item.operator === 'equals') {
        mappedOperator = 'eq';
      } else if (item.operator === 'contains') {
        mappedOperator = 'like';
      } // Add more conditions as needed
    
      return {
        property: item.field,
        value: item.value,
        type: fieldType, // You might need to adjust this based on your data types
        operator: mappedOperator
      };
    });

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      query: desiredStructure,
      page: 0,
    }));

  };

  const handleSortModelChange = (sortModel) => {
    // Handle sorting changes and fetch data from the server
    //sort: [{"property":"lastname","direction":"ASC"}]


    const sortObject = sortModel.map(item => ({
      property: item.field,
      direction: item.sort.toUpperCase() // Assuming you want direction in uppercase (ASC or DESC)
    }));

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      sort: sortObject,
      page: 0,
    }));

      console.log(paginationModel)
  };

  const handlePageChange = (newPage) => {
    // Handle page changes and fetch data from the server

    console.log("handlePageChange()")

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPage.page,
      limit: newPage.pageSize,
    }));

  };

  useEffect(() => {
    // This code will run every time paginationModel changes
    loadGridDataMyVisits();

  }, [paginationModel]);

  const loadGridDataMyVisits = async () => {

    console.log("loadGridData()")
    setGridLoading(true)

    var extraFilter = "";
    // if(topSearchFilter.length > 0){
    //   extraFilter = "&extraFilter="+topSearchFilter
    // }

    getListValuesPaging(navigate)(paginationModel, "host", extraFilter)
      .then((result) => {

        setData(result)
        setGridLoading(false)

      })
      .catch((error) => {
        setGridLoading(false)
        
      });

  }

  const loadDashboardVisitorsStatistics = async () => {

    console.log("loadVisitorsStatistics")

    getDashboardVisitorsStatistics(navigate)(selectedFrontDesk.entityId)
      .then((result) => {

        // setData(result)

        if(result.success){
          setTotalVisits(result.data.totalCheckedIn)
          setTotalVisitors(result.data.totalCheckedIn)
          setUpcomingVisitors(result.data.totalCheckedIn)
          setCurrentVisits(result.data.totalCheckedIn)
        }else{
          setTotalVisits(0)
          setTotalVisitors(0)
          setUpcomingVisitors(0)
          setCurrentVisits(0)
        }
        

      })
      .catch((error) => {
        
      });

  }

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="My Bade" subtitle="" />

        <Box>
          <Button
            onClick={() => navigate("/visit/new")}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              m: "0rem 0rem 0rem 0.75rem" ,
              "&:hover": { color: theme.palette.secondary.light },
            }}
          >
            <GroupAddIcon sx={{ mr: "10px" }} />
            New Visit
          </Button>
        </Box>
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        
        {/* ROW 1 */}
        <StatBox
          title="Total Visits"
          // value={data && data.totalCustomers}
          value={totalVisits}
          increase=""
          description=""
          icon={
            <Diversity3
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Total Visitors"
          // value={data && data.todayStats.totalSales}
          value={totalVisitors}
          increase=""
          description=""
          icon={
            <EmojiPeople
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Upconing Visits"
          // value={data && data.thisMonthStats.totalSales}
          value={upcomingVisitors}
          increase=""
          description=""
          icon={
            <DirectionsRun
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Current Visits"
          // value={data && data.yearlySalesTotal}
          value={currentVisits}
          increase=""
          description=""
          icon={
            <Group
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            My Badge
          </Typography>

        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            My Badge Application
          </Typography>

        </Box>
        {/* ROW 2 */}
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            My Appointments
          </Typography>

        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            My Training
          </Typography>

        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeBadge;
