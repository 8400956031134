class CookieManagerUtils {
    static instance;
  
    initialData = {

      visit_history_show_utc: false,
      visitor_visit_history_show_utc: false,
      passport_websocket_ip: "ws://192.168.1.21:5010",
      dashboard_list_view: true,
      last_login_type: "",
      last_selected_front_desk_entity_id: -1,
      loaded_last_selected_front_desk_entity_id: false,
    };
  
    constructor() {
      if (CookieManagerUtils.instance) {
        return CookieManagerUtils.instance;
      }
  
      this.cookieName = 'sadevio.dashboard.v2';
      CookieManagerUtils.instance = this;
  
      // Initialize the cookie with default data if it doesn't exist
      const existingData = this.readValue();
      if (!existingData) {
        this.saveValue(this.initialData);
      }
    }
  
    setCookie(name, value, days) {
      const expires = new Date();
      expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
      const cookieOptions = `expires=${expires.toUTCString()};path=/;SameSite=strict;`;
      document.cookie = `${name}=${JSON.stringify(value)};${cookieOptions}`;
    }
  
    getCookie(name) {
      const cookies = document.cookie.split(';');
      for (let cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split('=');
        if (cookieName.trim() === name) {
          return JSON.parse(cookieValue);
        }
      }
      return null;
    }
  
    saveValue(value) {
      const mergedData = { ...this.initialData, ...value };
      this.setCookie(this.cookieName, mergedData, 100); // 100 days expiration
    }
  
    readValue() {
      return this.getCookie(this.cookieName);
    }
  
    getField(key) {
      const data = this.readValue() || this.initialData;
      return data[key] ?? this.initialData[key];
    }
  
    setField(key, value) {
      const data = this.readValue() || this.initialData;
      data[key] = value;
      this.saveValue(data);
    }
  
    // Specific methods to mimic ExtJS functions
    getVisitHistoryShowUtc() {
      return this.getField("visit_history_show_utc");
    }
  
    setVisitHistoryShowUtc(show) {
      this.setField("visit_history_show_utc", show);
    }
  
    getVisitorVisitHistoryShowUtc() {
      return this.getField("visitor_visit_history_show_utc");
    }
  
    setVisitorVisitHistoryShowUtc(show) {
      this.setField("visitor_visit_history_show_utc", show);
    }
  
    getWebsocketIp() {
      return this.getField("passport_websocket_ip");
    }
  
    setWebsocketIp(ip) {
      this.setField("passport_websocket_ip", ip);
    }
  
    getDashboardShowListView() {
      return this.getField("dashboard_list_view");
    }
  
    setDashboardShowListView(show) {
      this.setField("dashboard_list_view", show);
    }
  
    getLastLoginType() {
      return this.getField("last_login_type");
    }
  
    setLastLoginType(lastLoginType) {
      this.setField("last_login_type", lastLoginType);
    }
  
    getLastSelectedFrontDeskEntityId() {
      return this.getField("last_selected_front_desk_entity_id");
    }
  
    setLastSelectedFrontDeskEntityId(id) {
      this.setField("last_selected_front_desk_entity_id", id);
    }
  
    getLoadedLastSelectedFrontDeskEntityId() {
      return this.getField("loaded_last_selected_front_desk_entity_id");
    }
  
    setLoadedLastSelectedFrontDeskEntityId(value) {
      this.setField("loaded_last_selected_front_desk_entity_id", value);
    }
  }
  
  // Usage
  const cookieManager = new CookieManagerUtils();
  export default cookieManager;
  