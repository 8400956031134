import {  DoorSliding } from "@mui/icons-material";
import {  Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Typography, useTheme } from "@mui/material";
import React, {  useEffect } from 'react'
import { useState } from "react";
import {  assigneBadgeDoorLevels, getListValuesPaging } from "hooks/HttpRequests";
import tm from "components/TranslationManager";
import FlexBetween from "components/FlexBetween";


const DialogAssignDoorLevel = ({ open, onClose, onSave, entityTypeParam, currentEntity  }) => {

  const theme = useTheme();
  const [entityType, setEntityType] = useState(entityTypeParam);
  const [badgeIdInput, setBadgeIdInput] = useState("");

  const [assignedDoorLevels, setaAssignedDoorLevels] = useState([]);
  const [doorLevelsLoading, setDoorLevelsLoading] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 200,
    sort: [],
    query:[]
  })

  useEffect(() => {
    
      loadAssignedDoorLevels();
    

  }, []);

  const loadAssignedDoorLevels = async () => {

    setDoorLevelsLoading(true)

    getListValuesPaging()(paginationModel, "access_levels", "")
      .then((result) => {

        setaAssignedDoorLevels(result.data)
        if(result.total_count > 0){
          // setSelectedBadgeItemIndex(0);
          // setSelectedBadgeItem(result.data[0]);
        }
        setDoorLevelsLoading(false)

      })
      .catch((error) => {

        console.log(error);

        setDoorLevelsLoading(false)
      });

  }

  useEffect(() => {
    

    if(currentEntity != null){

      if (currentEntity.hasOwnProperty('visitorId')) {
          currentEntity["entityId"] = currentEntity.visitorId;
          setEntityType("visitor")
      }

    }

  }, [open]);

  ///////////////////////////////

  const handleSave = () => {

    var values = {};
    var valuesEntityIds = []

    valuesEntityIds.push(1);

    values["data"] = valuesEntityIds;

    assigneBadgeDoorLevels(currentEntity.entityId, values)
    .then((result) => {
      // Use the result.data as needed
      if (result.success) {

          onClose();
      }
       

    })
    .catch((error) => {
     

    });

  }

  const handleClose = () => {

      // onClearImage()
      onClose();
  }


  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" 
    >
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>{tm.translate('lblAssignDoorLevel', "Assign Door Level")}</DialogTitle>
        <DialogContent>

        <Grid container spacing={2} style={{ backgroundColor: theme.palette.background.alt, padding: "2px 0px 2px 0px" }} >
          {/* Left Column */}
          {/* <Grid item xs={6} style={{ padding: 20, backgroundImage: 'transparent' }}> */}
            <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none'  }}>

            {
        doorLevelsLoading ? (
            "Loading..."
        ) : (
            assignedDoorLevels && assignedDoorLevels.length > 0 ? (
                <List dense sx={{ 
                        gridColumn: "span 4" , 
                        margin: '15px 0px 0px 0px',
                        overflowY: 'auto', // Add this to enable vertical scrolling
                        flex: 1 
                       // maxHeight: '310px', // Set a maximum height for the container 
                        
                    }}>
                        {assignedDoorLevels.map((value, index) => {
                            const labelId = `checkbox-list-secondary-label-${index}`;

                            var isLoggedInUser = false
                            if(typeof value.isLoggedInUser !== "undefined"){
                                isLoggedInUser = true;
                            }

                            const handleRemoveHost = () => {
                                // // Create a new array without the item to be removed
                                // const updatedHosts = [...assignedDoorLevels.slice(0, index), ...assignedDoorLevels.slice(index + 1)];
                                // // Update the state with the new array
                                // setSelectedHosts(updatedHosts);

                                //  // Create a new array without the item to be removed
                                //  const updatedValue = [...selectedValues.slice(0, index), ...selectedValues.slice(index + 1)];
                                //  // Update the state with the new array
                                //  setSelectedValues(updatedValue);
                            };

                            const picturePath = value.picturePath ? value.picturePath : ""; // Check if picturePath is null
                          return (
                            <ListItem
                                        key={labelId}
                                        // secondaryAction={
                                        // <IconButton>
                                        //     <Clear style={{ color: sadevioColors.hexColorInvited }}/> 
                                        // </IconButton>
                                        // }
                                        disablePadding
                                    >
                                      <Checkbox
                                        edge="start"
                                        // checked={selectedBadgeItemIndex === index}
                                        // tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        sx={{ 
                                          m: "0rem 0rem 0rem 0.5rem",
                                          }}
                                      />
                                        <ListItemButton>
                                        <ListItemAvatar>
                                          <DoorSliding sx={{ color: theme.palette.secondary.light, fontSize: 24 }}/>
                                        </ListItemAvatar>
                                        <ListItemText primary={value.name} secondary={value.description} 
                                          sx={{
                                            '& .MuiTypography-root': {
                                              color: theme.palette.secondary.light, // Change to the color you prefer
                                            },
                                          }}
                                        />
                                        </ListItemButton>
                                    </ListItem>
                                    );
                                })}
                        </List>
                        ) : (
                
                         <Typography sx={{ color: theme.palette.primary[400], mt: 3 }}>No door levels configured</Typography>
                          )
                      )
                  }
                
                 
            </Paper>
          
        </Grid>

          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {/* Are you sure you want to execute this action? */}
        
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>  
        <FlexBetween>


            <Box>
                <Button onClick={handleClose} color="primary" sx={{ color: theme.palette.background.alt }}>
                  {tm.translate('btnCancel', "Cancel")}
                </Button>
                <Button onClick={handleSave} color="primary" autoFocus sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
                  {tm.translate('btnAssignDoorLevel', "Assign Selected Door Levels")}
                </Button>
            </Box>
        </FlexBetween>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogAssignDoorLevel