import {  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@mui/material";
import React, {  useEffect } from 'react'
import { useState } from "react";
import CSVFileUpload from "scenes/visitors/visitors/CSVFileUpload";


const DialogUploadCvs = ({ open, onClose,  onFileChange, theme, values, setFieldValue  }) => {


  useEffect(() => {
    
    setReadFile(null)
    setSelectedFile(null)
    
  

  }, [open]);


  ///////////////////////////////
  const [selectedFile, setSelectedFile] = useState(null);
  const [readFile, setReadFile] = useState(null);

  const handleClose = () => {

      onClose();
  }


  return (
    <Dialog open={open} onClose={onClose}>
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>Upload Document</DialogTitle>
        <DialogContent>

        <CSVFileUpload
                      onFileChange={onFileChange}
                      buttonText="IMPORT"
                      values={values}
                      setFieldValue={setFieldValue}
                    />

          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {/* Are you sure you want to execute this action? */}
        
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>  

          <div>
            <Button onClick={handleClose} color="primary" sx={{ color: theme.palette.background.alt }}>
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogUploadCvs