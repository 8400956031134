// SilentAlarmContext.js
import React, { createContext, useState, useContext } from 'react';

const IntegrationConfigContext = createContext();

export const SilentAlarmProvider = ({ children }) => {
  const [showSilentAlarm, setShowSilentAlarm] = useState(false);
  const [showEmergencyList, setShowEmergencyList] = useState(false);
  const [showVisitorNotification, setShowVisitorNotification] = useState(false);

  return (
    <IntegrationConfigContext.Provider value={{ showSilentAlarm, setShowSilentAlarm, showEmergencyList, setShowEmergencyList, showVisitorNotification, setShowVisitorNotification }}>
      {children}
    </IntegrationConfigContext.Provider>
  );
};

export const useIntegrationConfig = () => useContext(IntegrationConfigContext);
