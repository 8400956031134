import { Box, useTheme, useMediaQuery } from "@mui/material";
import Form from "./login";
import tm from "components/TranslationManager";


const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  var logo =  tm.translate('imgLogoLogin', "logos/sadevio_white.png");

  return (
    <Box>
      <Box
        height="8vh"
        width="100%"  
        backgroundColor={theme.palette.background.alt}
        p="1rem 6%"
        textAlign="center"
      >
        <img src={logo} alt="Logo" 
          style={{
            maxHeight: "100%",   // Fit inside parent height
            maxWidth: "100%",    // Avoid overflow horizontally
            objectFit: "contain", // Keeps aspect ratio
          }}
        />
        {/* <Typography fontWeight="bold" fontSize="32px" color="secondary">
          MSMM
        </Typography> */}
      </Box>

      <Box
        width={isNonMobileScreens ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={theme.palette.background.alt}
      >
        {/* <Typography fontWeight="500" variant="h5" sx={{ mb: "1.5rem" }}>
          Welcome to Socipedia, the Social Media for Sociopaths! asfajf
        </Typography> */}
        <Form />
      </Box>
    </Box>
  );
};

export default LoginPage;