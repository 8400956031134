import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Link, Typography, useTheme } from "@mui/material";
import Header from "components/Header";
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import DialogYesNo from "components/dialog/DialogYesNo";
import {  useNavigate } from "react-router-dom";
import tm from "components/TranslationManager";
import { stringAvatar } from "components/StyledComponents";
import { deleteDeniedPerson, getListValuesPaging, getSessionToken, getUri, getVisitHosts, getVisitInvitationApproveVisitor, getVisitInvitationDenyVisitor } from "hooks/HttpRequests";
import { useFrontDeskManager } from "components/FrontDeskManager";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import SadevioDataGridPro from "components/SadevioDataGridPro";
import GridCellActions from "components/GridCellActions";


const DeniedPerson = () => {
  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { convertToFrontDeskTimeZone, convertToFrontDeskTimeZoneDateTimeString } = useFrontDeskManager();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [yesNoMessage, setYesNoMessage] = useState("");
  const [yesNoDialogEntity, setYesNoDialogEntity] = useState({});

  const [topSearchFilter, setTopSearchFilter] = useState("");

  const theme = useTheme();

  /////////////// GRID /////////////
  // values to be sent to the backend

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const [search, setSearch] = useState("");

  /////////////// USE EFFECT /////////////


  useEffect(() => {
    // This code will run every time paginationModel changes
    setTriggerRefresh(!triggerRefresh)

  }, []);


  const handleOptionCellActionClick = (id, action, row) => {
    if (action === 'edit') {
      // Handle edit action
      console.log('Edit record:', row);
      navigate(`/visit/settings/deniedperson/${row.entityId}`)
    } else if (action === 'delete') {
      // Handle delete action
      // console.log('Delete record:', row);
      setConfirmationOpen(true);
      setYesNoMessage("Do you really want to delete this entity?")
      row["action"] = "delete";
      setYesNoDialogEntity(row)
      
    }
  };

  const columns = [
    
    {
      field: "entityId",
      headerName: "Entity Id",
      filterable: false,
      type: 'number'
    },
    {
      field: 'picturePath',
      filterable: false,
      headerName: 'Picture',
      width: 100,
      renderCell: (params) => (
        params.row.picturePath != null ? (
          <Avatar {...stringAvatar(params.row.name)} alt={params.row.name} src={`${getUri(getSessionToken())}/denied_person/${params.row.entityId}/picture/${params.row.picturePath}`}          />

        ) : (
          <Avatar {...stringAvatar(params.row.name)} />
        )
      ),
    },
    {
        field: "name",
        headerName: "Name",
        flex: 1,
        renderCell: (params) => (
          <Link
            style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
            onClick={() => {
              navigate(`/visit/settings/deniedperson/${params.row.entityId}`)
            }}
          >
          {params.row.name}
        </Link>
        ),
      },
      {
        field: 'reason',
        filterable: true,
        headerName: 'Reason',
      },
      {
        field: 'source',
        filterable: true,
        headerName: 'Source',
      },
    {
      field: 'address',
      filterable: true,
      headerName: 'Address',
    },
    {
      field: 'city',
      filterable: true,
      headerName: 'City',
    },
    {
      field: 'zip',
      filterable: true,
      headerName: 'Zip',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      filterable: false,
      width: 80,
      sortable: false,
      renderCell: (params) => (
        <GridCellActions
          id={params.row.entityId}
          row={params.row}
          onOptionClick={handleOptionCellActionClick}
        />
      ),
    },
  ];

  const handleExecute = async (values) => {
  
  
        setConfirmationOpen(false)
    
        dispatch(showLoading(""))
    
        if(values.action == "delete"){
  
          deleteDeniedPerson(navigate)(values.entityId)
            .then((result) => {
            // Use the result.data as needed      
            dispatch(hideLoading());
                if(result.success){
                    
                  enqueueSnackbar({
                    message: "Was deleted successfully",
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
    
                  setTriggerRefresh(!triggerRefresh);
    
                }else{
  
  
  
                  enqueueSnackbar({
                    message: "Something went wrong during request",
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
                }
    
    
          })
          .catch((error) => {
            dispatch(hideLoading());
    
            if (typeof error.action !== "undefined") {
              if (error.action == "relogin") {
                navigate("/");
              }else if(error.action == "force_delete"){
                  console.log("force delete")
                  setConfirmationOpen(true);
                  setYesNoMessage("This entity has still referecnes to visits. Do you want to force the delete?")
                  yesNoDialogEntity["action"] = "force_delete";
                  setYesNoDialogEntity(yesNoDialogEntity)
              }
            }else if (error.hasOwnProperty('success')) {
              enqueueSnackbar({
                message: "Error "+error.message,
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }
    
          });
        }
    
          
    
      };


  const btnNewVisit = tm.translate('btnNewDeniedPerson', 'New Denied Person');
  
  const buttons = [
    // { type:'search',label: "Search employees..." , onChange: handleSearchInputChange, iconName: ''},
    { type:'button',label: btnNewVisit , onClick: () => {
              // openDrawer()
              navigate("/visit/settings/deniedperson/new") 
              // setAddEmployeeOpen(true);
            }, iconName: '', btnId: 'btnNewDeniedPerson'},

  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Denied Person" subtitle="List of denied person's" elements={buttons} />
      <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleExecute}
        theme={theme}
        message={yesNoMessage}
        entity={yesNoDialogEntity}
      />
      <SadevioDataGridPro columns ={columns} url={'denied_person'} triggerRefresh= {triggerRefresh} />
    </Box>
  );
};

export default DeniedPerson;