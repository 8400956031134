import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Divider,
  useTheme,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { deleteTextFieldRule, getTextFieldRulesFrontDesk } from "hooks/HttpRequests";
import { useNavigate } from "react-router-dom";
import DialogRuleWizard from "components/dialog/DialogRuleWizard";
import Header from "components/Header";
import { enqueueSnackbar } from "notistack";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useDispatch } from "react-redux";

const TextFieldRulesConf = () => {

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rules, setRules] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [editRule, setEditRule] = useState(null);
  const { selectedFrontDesk } = useFrontDeskManager();
  const [loading, setLoading] = useState(true);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  useEffect(() => {
    if (selectedFrontDesk) {
      loadData();
    }
  }, [selectedFrontDesk, triggerRefresh]);

  const loadData = async () => {
    setLoading(true);
    try {
      const result = await getTextFieldRulesFrontDesk(navigate)(selectedFrontDesk.entityId);
      if (result.success) {
        setRules(result.data);
      }
    } catch (error) {
      console.error("Failed to load rules:", error);
    } finally {
      setLoading(false);
    }
  };

  const saveRule = async (rule) => {
    try {
      if (rule.entityId) {
        await axios.put(`/api/textfieldrules/${rule.entityId}`, rule);
      } else {
        await axios.post("/api/textfieldrules", rule);
      }
      setTriggerRefresh(!triggerRefresh);
      setOpenEditor(false);
    } catch (error) {
      console.error("Failed to save rule", error);
    }
  };

  const deleteRule = async (id) => {
    try {
        dispatch(showLoading(""));

        const result = await deleteTextFieldRule(navigate)(id);
        if (result.success) {
            // setRules(result.data);
        }      
        dispatch(hideLoading());
        setTriggerRefresh(!triggerRefresh);
    } catch (error) {
        dispatch(hideLoading());
      console.error("Failed to delete rule", error);
    }
  };

  const openRuleEditor = (rule = null) => {
    if (!rule) {
      // Adding a new rule
      const existingTypes = rules.map((r) => r.ruleType);

      if (existingTypes.includes("check_in") && existingTypes.includes("invitation")) {
        enqueueSnackbar({
            message: "You can only add one rule of each type (check_in and invitation).",
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 3000,
          });
        return;
      }
    }
    setEditRule(rule);
    setTimeout(() => setOpenEditor(true), 0);
  };

  const closeRuleEditor = () => {
    setEditRule(null);
    setOpenEditor(false);
  };

  const saveRuleEditor = () => {
    setTriggerRefresh(!triggerRefresh);
    setOpenEditor(false);
  };

  const buttons = [
    { type:'button',label: `Add Rule` , onClick: () => { openRuleEditor() }, iconName: ''},

  ];

  if (loading || !selectedFrontDesk) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="1rem 2rem" >
    <Header title={`Text Field Rules Configuration`} subtitle={`Define your custom rules`} backbutton={true} elements={buttons}/>
      <Box mt="20px">
      {rules.length === 0 ? (
          <Typography variant="body1" align="center" color={theme.palette.text.secondary}>
            No rules configured. Use the "Add Rule" button to create a new rule.
          </Typography>
        ) : (
        rules.map((rule) => (
          <Box
            key={rule.entityId}
            p={2}
            mb={2}
            border={1}
            sx={{ backgroundColor: theme.palette.background.alt }}
            borderRadius="8px"
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>

              <Typography variant="h5" component="div" color={theme.palette.secondary[300]}>
                {rule.ruleType}
              </Typography>
              <Box>
                <IconButton onClick={() => openRuleEditor(rule)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => deleteRule(rule.entityId)}>
                  <Delete />
                </IconButton>
              </Box>
            </Box>
            <Typography>
              <strong>Description:</strong> {rule.description}
            </Typography>
            <Typography>
              <strong>Active:</strong> {rule.active ? "Yes" : "No"}
            </Typography>
            <Divider sx={{ my: 2 }} />

            {/* Conditions */}
            <Typography variant="subtitle1">Conditions:</Typography>
            {rule.conditions.map((condition) => (
              <Box
                key={condition.entityId}
                p={2}
                mb={2}
                border={1}
                borderColor={theme.palette.grey[200]}
                borderRadius="8px"
              >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1} >
                  <Typography>
                    <strong>Field:</strong> {condition.fieldName}{" "}
                    <strong>Operator:</strong> {condition.operator}{" "}
                    <strong>Value:</strong> {condition.value}
                  </Typography>
                  <Box>
                  </Box>
                </Box>
                <Typography>
                  <strong>Workflow Type:</strong> {condition.workflowType || "N/A"}
                </Typography>

                {/* Actions */}
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle2">Actions:</Typography>
                {condition.actions.map((action) => (
                  <Box
                    key={action.entityId}
                    p={1}
                    mb={1}
                    border={1}
                    borderColor={theme.palette.grey[100]}
                    borderRadius="4px"
                  >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography>
                        <strong>Type:</strong> {action.actionType}
                      </Typography>
                     
                    </Box>
                    {action.parameters && (
                      <Typography>
                        <strong>Parameters:</strong> {action.parameters}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        ))
        )}
      </Box>

      {/* Rule Editor Dialog */}
      <DialogRuleWizard
        open={openEditor}
        onClose={closeRuleEditor}
        onSave={saveRuleEditor}
        rule={editRule} // Pass the rule ID if editing
        availableRuleTypes={["check_in", "invitation"].filter(
            (type) => !rules.some((rule) => rule.ruleType === type)
          )} // Pass only missing types
        />
      
    </Box>
  );
};

export default TextFieldRulesConf;
