import React, { useEffect, useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import { useNavigate } from "react-router-dom";
import {
  Diversity3,
  EmojiPeople,
  DirectionsRun,
  Group,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Avatar,
  Link,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import BreakdownChart from "components/BreakdownChart";
import OverviewChart from "components/OverviewChart";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import StatBox from "components/StatBox";
import { getListValuesPaging, getSessionToken, getUri } from "hooks/HttpRequests";
import { stringAvatar } from "components/StyledComponents";
import tm from "components/TranslationManager";
import GridCellVisitorStatus from "components/GridCellVisitorStatus";

const GlobalEmployeesAnalytics = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  // const { data, isLoading } = useGetDashboardQuery();
  const navigate = useNavigate();

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [],
    query:[]
  })


  const columns = [
    {
      field: "entityId",
      headerName: "Entity Id",
      flex: 1,
      type: 'number'
    },
    {
      field: 'picturePath',
      headerName: 'Picture',
      width: 100,
      renderCell: (params) => (
        params.row.picturePath != null ? (
          <Avatar {...stringAvatar(params.row.fullName)} alt={params.row.fullName} src={`${getUri(getSessionToken())}/host/${params.row.entityId}/picture/${params.row.picturePath}`}          />

        ) : (
          <Avatar {...stringAvatar(params.row.fullName)} />
        )
      ),
    },   
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/employees/${params.row.entityId}`)
          }}
        >
        {params.row.fullName}
      </Link>
      ),
    },
    {
      field: "visitStatus",
      headerName: tm.translate('clmVisitStatus', "Visit Status") ,
      flex: "auto",
      minWidth: 120,
      renderCell: (params) => {
        let statusTranslation = "";
        switch(params.row.visitStatus) {
          case "pre_registered":
            statusTranslation = tm.translate(params.row.visitStatus, "Invited");
            break;
          case "started":
            statusTranslation = tm.translate(params.row.visitStatus, "Started");
            break;
          case "finished":
            statusTranslation = tm.translate(params.row.visitStatus, "Finished");
            break;
          case "canceled":
            statusTranslation = tm.translate(params.row.visitStatus, "Canceled");
              break;
          default:
            statusTranslation = "";
            break;
        }
        return <span>{statusTranslation}</span>;
      }
    },
    {
      field: "visitorStatus",
      headerName: tm.translate('clmVisitorStatus', "Visitor Status"),
      minWidth: 220,
      renderCell: (params) => (
        <GridCellVisitorStatus rowData={params.row} />
      ),
    },
  ];

  const handleFilterModelChange = (filterModel) => {
    // Update the filter model state when it changes

    console.log("handleFilterModelChange()")

    // Convert to the desired structure
    const desiredStructure = filterModel.items.map(item => {
      let mappedOperator = item.operator;
    
      let field = columns.find(column => column.field === item.field);
      var fieldType = "string";

      if(typeof field.type !== 'undefined'){
        fieldType = field.type;
      }

      // Map operators as needed
      if (item.operator === 'equals') {
        mappedOperator = 'eq';
      } else if (item.operator === 'contains') {
        mappedOperator = 'like';
      } // Add more conditions as needed
    
      return {
        property: item.field,
        value: item.value,
        type: fieldType, // You might need to adjust this based on your data types
        operator: mappedOperator
      };
    });

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      query: desiredStructure,
      page: 0,
    }));

  };

  const handleSortModelChange = (sortModel) => {
    // Handle sorting changes and fetch data from the server
    //sort: [{"property":"lastname","direction":"ASC"}]


    const sortObject = sortModel.map(item => ({
      property: item.field,
      direction: item.sort.toUpperCase() // Assuming you want direction in uppercase (ASC or DESC)
    }));

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      sort: sortObject,
      page: 0,
    }));

      console.log(paginationModel)
  };

  const handlePageChange = (newPage) => {
    // Handle page changes and fetch data from the server

    console.log("handlePageChange()")

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPage.page,
      limit: newPage.pageSize,
    }));

  };

  useEffect(() => {
    // This code will run every time paginationModel changes
    loadGridDataMyVisits();

  }, [paginationModel]);

  const loadGridDataMyVisits = async () => {

    console.log("loadGridData()")
    setGridLoading(true)

    var extraFilter = "";
    // if(topSearchFilter.length > 0){
    //   extraFilter = "&extraFilter="+topSearchFilter
    // }

    getListValuesPaging(navigate)(paginationModel, "host", extraFilter)
      .then((result) => {

        setData(result)
        setGridLoading(false)

      })
      .catch((error) => {

        console.log(error);

        setGridLoading(false)
      });

  }

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        <Box>
          <Button
            onClick={() => navigate("/visit/new")}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              m: "0rem 0rem 0rem 0.75rem" ,
              "&:hover": { color: theme.palette.secondary.light },
            }}
          >
            <GroupAddIcon sx={{ mr: "10px" }} />
            New Visit
          </Button>
        </Box>
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
        <StatBox
          title="Total Visits"
          // value={data && data.totalCustomers}
          value={27}
          increase="+14%"
          description="Since last month"
          icon={
            <Diversity3
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Total Visitors"
          // value={data && data.todayStats.totalSales}
          value={36}
          increase="+21%"
          description="Since last month"
          icon={
            <EmojiPeople
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Visitors By Month
          </Typography>
          <OverviewChart view="sales" isDashboard={true} />
        </Box>
        <StatBox
          title="Upconing Visits"
          // value={data && data.thisMonthStats.totalSales}
          value={0}
          increase="-5%"
          description="Since last month"
          icon={
            <DirectionsRun
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Current Visits"
          // value={data && data.yearlySalesTotal}
          value={0}
          increase="-43%"
          description="Since last month"
          icon={
            <Group
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 3"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={gridLoading || !data}
            initialState={{
              pagination: { paginationModel: { pageSize: paginationModel.limit } },
            }}
            columnVisibilityModel={columnVisibilityModel}
            getRowId={(row) => `${row.entityId}`}
            rows={data?.data || []}
            rowCount={(data && data.total_count) || 0}
            rowsPerPageOptions={[25, 50, 100]}
            columns={columns}
          />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Visitors By type
          </Typography>
          <BreakdownChart isDashboard={true} />
        </Box>
      </Box>
    </Box>
  );
};

export default GlobalEmployeesAnalytics;