import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, useTheme, Grid, Paper, useMediaQuery, Avatar } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';
import {  deleteVisitor, deleteVisitorForce, getSessionToken, getUri, getVisitor, postVisitor, putVisitor } from 'hooks/HttpRequests';
import {  stringAvatar, commonStyles} from "components/StyledComponents";
import * as yup from "yup";
import DialogTakePicture from 'components/dialog/DialogTakePicture';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { hideLoading, showLoading } from 'state/loadingSlicer';
import VisitorForm from './visitorForm';
import DialogYesNo from 'components/dialog/DialogYesNo';
import dayjs from "dayjs";


const VisitorDetailGeneral = ({globalType, navigate, id, route }) => {

    // var isAuth = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();

    const theme = useTheme();
    const {palette} = useTheme();
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [takePictureOpen, setTakePictureOpen] = useState(false);

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [yesNoMessage, setYesNoMessage] = useState("");
    const [yesNoDialogEntity, setYesNoDialogEntity] = useState({})

    const [gridLoading, setGridLoading] = useState(false);

    const [showReviewers, setShowReviewers] = useState(false);

    const toggleReviewers = () => {
        setShowReviewers(!showReviewers);
    };

    const [isAddMode, setIsAddMode] = useState(false);


    useEffect(() => {

        // loadUserRole();

        setIsAddMode(route === "new");
        if (route !== "new") {
            loadData(id);
        } else {
            //do nothing
        }

        if(route === "new"){
            toggleReviewers()
        }

    }, [id, route]);

    const loadData = async (id) => {

        getVisitor(navigate)(id)
            .then((result) => {

            console.log(result)
            if(result.success){

                // if(typeof result.data.password === "undefined"){
                //     if(isAddMode){
                //         result.data.password = ""
                //     }else{
                //         result.data.password = "_placeholder_"
                        
                //     }
                // }

                setLoadedFormData(result.data)

            }

            })
            .catch((error) => {
                setGridLoading(false)
            });
    };

  const registerSchema = yup.object().shape({
    lastname: yup.string().required("required"),
    firstname: yup.string().required("required"),

  });


  const [loadedFormData, setLoadedFormData] = useState({ 
                visitorId: null, 
                lastname: "", 
                firstname: "", 
                visitorType: "Visitor",
                // birthday: null,
                fullName: "",
                email: "", 
                companyName: "", 
                phone: "",
                mobile: "",
                idNumber: "",
                idKey: "",
                gender: "",
                c_1: "",
                c_2: "",
                c_3: "",
                c_4: "",
                c_5: "", 
                });


  const handleSubmit = (values) => {

    delete loadedFormData.entityId;

    loadedFormData["deniedPerson"] = values.deniedPerson

    loadedFormData["visitorType"] = values.visitorType

    loadedFormData["email"] = values.email

    loadedFormData["firstname"] = values.firstname
    loadedFormData["lastname"] = values.lastname

    if (dayjs.isDayjs(values.birthday)) {
      loadedFormData["birthday"] = values.birthday.valueOf()
    } else if (typeof values.birthday === "number") {
      loadedFormData["birthday"] = values.birthday
    } else {
      loadedFormData["birthday"] = values.birthday
    }

    loadedFormData["mobile"] = values.mobile
    loadedFormData["phone"] = values.phone
    loadedFormData["companyName"] = values.companyName
    loadedFormData["idNumber"] = values.idNumber
    
    loadedFormData["licensePlate"] = values.licensePlate    

    loadedFormData["c_1"] = values.c_1
    loadedFormData["c_2"] = values.c_2
    loadedFormData["c_3"] = values.c_3
    loadedFormData["c_4"] = values.c_4
    loadedFormData["c_5"] = values.c_5



    var submitData= {}
    submitData["data"]= loadedFormData;

    var everythingOK = true;

    if(isAddMode){


        // if(selectedAuthType == "app" && loadedFormData.password == ""){
        //     everythingOK = false
        //     enqueueSnackbar({
        //         message: "Password can't be empty",
        //           variant: 'error',
        //           anchorOrigin: {
        //             vertical: 'top', // 'top', 'bottom'
        //             horizontal: 'center', // 'left', 'center', 'right'
        //           },
        //           autoHideDuration: 3000, 
        //           // persist: true
                
        //       });
        // }
    }else{
        // // if update an password is empty have the placeholder since it was not changed
        // if(selectedAuthType == "app" && loadedFormData.password == ""){
            
        //     loadedFormData.password = "_placeholder_";
        // }

    }

    

    if(everythingOK){

    
        if(isAddMode){

            dispatch(showLoading(""));

            postVisitor(navigate)(submitData)
            .then((result) => {
            // Use the result.data as needed
            if (result.success) {
            
                if(result.success){
                    navigate(`/visitors/${result.data.visitorId}`)
                }else{
                    
                }
                dispatch(hideLoading());
            }
            })
            .catch((error) => {
            dispatch(hideLoading());
        
            if (typeof error.action !== "undefined") {
                if (error.action === "relogin") {
                navigate("/");
                }
            }else if (error.hasOwnProperty('success')) {
                enqueueSnackbar({
                message: "Error "+error.message,
                    variant: 'error',
                    anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                
                });
            }
        
            });

        }else{

          dispatch(showLoading(""));
            putVisitor(navigate)(submitData)
            .then((result) => {
            // Use the result.data as needed
            if (result.success) {
            
                if(result.success){
                    //   setCopmanyName("- "+result.data.name);
                    setLoadedFormData(result.data)

                    enqueueSnackbar({
                        message: "Entity was saved",
                          variant: 'success',
                          anchorOrigin: {
                            vertical: 'top', // 'top', 'bottom'
                            horizontal: 'center', // 'left', 'center', 'right'
                          },
                          autoHideDuration: 3000, 
                          // persist: true
                        
                      });

                }else{
                    enqueueSnackbar({
                        message: "Error "+result.message,
                            variant: 'error',
                            anchorOrigin: {
                            vertical: 'top', // 'top', 'bottom'
                            horizontal: 'center', // 'left', 'center', 'right'
                            },
                            autoHideDuration: 3000, 
                            // persist: true
                        
                        });
                }
                dispatch(hideLoading());

            }
            })
            .catch((error) => {
            dispatch(hideLoading());
    
            if (typeof error.action !== "undefined") {
                if (error.action === "relogin") {
                navigate("/");
                }
            }else if (error.hasOwnProperty('success')) {
                enqueueSnackbar({
                message: "Error "+error.message,
                    variant: 'error',
                    anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                
                });
            }
    
            });
        }
    }

  };

  const onDeleteClicked = () => {

    // Handle delete action
    setConfirmationOpen(true);
    setYesNoMessage("Do you really want to delete this entity?")
    loadedFormData["action"] = "delete";
    setYesNoDialogEntity(loadedFormData)
          
        
  }

  const handleExecute = async (values) => {


    setConfirmationOpen(false)

    dispatch(showLoading(""))

    if(values.action === "delete"){

      deleteVisitor(navigate)(values.visitorId)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Was deleted successfully",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

            navigate("/visit/visitors");

            }else{

              enqueueSnackbar({
                message: "Something went wrong during request",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action === "relogin") {
            navigate("/");
          }else if(error.action === "force_delete"){
              console.log("force delete")
              setConfirmationOpen(true);
              setYesNoMessage("This entity has still referecnes to visits. Do you want to force the delete?")
              yesNoDialogEntity["action"] = "force_delete";
              setYesNoDialogEntity(yesNoDialogEntity)
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    }else if(values.action === "force_delete"){

      deleteVisitorForce(navigate)(values.visitorId)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Was deleted successfully",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

              navigate("/visit/visitors");

            }else{



              enqueueSnackbar({
                message: "Something went wrong during request",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action === "relogin") {
            navigate("/");
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    }
  };


  // Render loading state if loadedFormData is null
  if (!loadedFormData) {
    return <div>Loading...</div>;
  }


  return (

    <Box>
        <DialogYesNo
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            onConfirm={handleExecute}
            theme={theme}
            message={yesNoMessage}
            entity={yesNoDialogEntity}
        />
        <DialogTakePicture
            open={takePictureOpen}
            onClose={() => setTakePictureOpen(false)}
            onSave={(entity) => {   setTakePictureOpen(false);  
                                    setLoadedFormData({
                                        ...loadedFormData,
                                        picturePath: entity.data.picturePath
                                    });
                            }}
            theme={theme}
            currentEntity ={loadedFormData}
            entityTypeParam={"visitor"}
        />
        <Box sx={{ ...commonStyles(theme), border: 1 }}>
            
        <Box>
            {/* Top Row */}
            <Box display="flex" alignItems="center" mb={1} ml={1} mt={3}>
                <Box ml={2}>
                <Typography variant="h3" fontWeight="bold" color={theme.palette.secondary[100]}>
                    General Information
                </Typography>
                </Box>
            </Box>

            {/* Bottom Rows */}
            <Grid container spacing={2} style={{ backgroundColor: 'transparent', padding: "2px 0px 2px 0px" }} >
                {/* Left Column */}

                <Grid item xs={6} style={{ padding: 20, backgroundImage: 'transparent' }}>   
                    <VisitorForm
                        initialValues={loadedFormData}
                        validationSchema={registerSchema}
                        onSubmit={handleSubmit}
                        loadedFormData={loadedFormData}
                        onDeleteClicked={onDeleteClicked}
                        theme={theme}
                        palette={palette}
                        isNonMobile={isNonMobile}
                        isAddMode={isAddMode}
                    />
                </Grid>

                {/* Right Column */}
                <Grid item xs={6} sx={{ backgroundColor: 'transparent', backgroundImage: 'none',}}>
                {!isAddMode && (
                    <Paper elevation={3} style={{ padding: 20, backgroundImage: 'none',   boxShadow: 'none',  backgroundColor: 'transparent'}}>
                                        <Button
                                            variant="outlined"
                                            disableTouchRipple
                                            onClick={() => setTakePictureOpen(true)}

                                            sx={{
                                                position: 'relative',
                                                border: 0,
                                                "&:hover": { 
                                                    border: 0, 
                                                    color: theme.palette.secondary.light, 
                                                    backgroundImage: 'none',  
                                                    boxShadow: 'none', 
                                                    cursor: 'pointer',
                                                    backgroundColor: 'transparent', // Change background color to transparent
                                                }
                                            }}
                                        >
                                            <Avatar
                                                {...stringAvatar(loadedFormData?.fullName || "Unknown User")}// Default to "Unknown User" if fullName is undefined
                                                sx={{ width: 250, height: 250, position: 'relative', zIndex: 0 }} // Ensure Avatar is positioned relatively
                                                src={
                                                    loadedFormData?.picturePath
                                                        ? `${getUri(getSessionToken())}/visitor/${loadedFormData.visitorId}/picture/${loadedFormData.picturePath}`
                                                        : undefined // No src if picturePath is empty or null
                                                }
                                            />
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    background: 'rgba(0, 0, 0, 0)',
                                                    zIndex: 1,
                                                    clipPath: 'ellipse(47% 50% at 50% 50%)',
                                                    WebkitClipPath: 'ellipse(45% 48% at 50% 50%)',
                                                    backgroundImage: 'linear-gradient(to bottom, transparent 80%, rgba(0,0,0,0.5) 60%)',
                                                    // backgroundClip: 'padding-box', // This property ensures that the background is clipped to the padding box
                                                }}
                                            />
                                            
                                            <Box sx={{ position: 'absolute', bottom: 3, left: '50%', transform: 'translateX(-50%)', zIndex: 2 }}>
                                                <CameraAlt sx={{ fontSize: '30px', color: theme.palette.secondary.light }} />
                                            </Box>
                                        </Button>
                    </Paper>)}
                </Grid>
            </Grid>
            
        </Box>
        {/*     */}
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >

            </Box>
        </Box>

        {/*  -------------------------------   */}


            

    </Box>
  );
};

export default VisitorDetailGeneral;
