import {  GetApp } from "@mui/icons-material";
import {  IconButton, Tooltip,useTheme } from "@mui/material";
import React, {  useEffect } from 'react'
import { useState } from "react";
import { deleteAttachment, getListValuesPaging, getSessionToken, getUri } from "hooks/HttpRequests";
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { BsFilesAlt, BsFiletypeCss, BsFiletypeDoc, BsFiletypeDocx, BsFiletypeHtml, BsFiletypeJpg, BsFiletypeJs, BsFiletypeMp4, BsFiletypePdf, BsFiletypePng, BsFiletypeTxt, BsFiletypeXls, BsFiletypeXlsx } from "react-icons/bs";
import tm from "components/TranslationManager";
import { useNavigate } from "react-router-dom";
import DialogYesNo from "./dialog/DialogYesNo";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import ActionColumn from "./ActionColumn";


const ListAttachments = ({ onTriggerLoad , entityIdPointer, entityTable, entityKeyname, searchFilter = "" }) => {


  const [localTriggerLoad, setLocalTriggerLoad] = useState(false);
  const [selectedRowEntityId, setSelectedRowEntityId] = useState(0);


  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

 
  useEffect(() => {
    if (onTriggerLoad  || localTriggerLoad) {
      loadGridData();
      setLocalTriggerLoad(false);
    }
  }, [onTriggerLoad, searchFilter, localTriggerLoad]);

  /////////////// GRID /////////////
  // values to be sent to the backend

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    attachmentId: false,
  });

  const [search, setSearch] = useState("");

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [],
    query:[]
  })

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton color="neutral" />
        <GridToolbarFilterButton  style={{ color: '#3f51b5' }}  />
      </GridToolbarContainer>
    );
  }

  
    useEffect(() => {
      // This code will run every time paginationModel changes
      loadGridData();

    }, [paginationModel]);


  const handleFilterModelChange = (filterModel) => {
    // Update the filter model state when it changes

    // Convert to the desired structure
    const desiredStructure = filterModel.items.map(item => {
      let mappedOperator = item.operator;
    
      let field = columns.find(column => column.field === item.field);
      var fieldType = "string";

      if(typeof field.type !== 'undefined'){
        fieldType = field.type;
      }

      // Map operators as needed
      if (item.operator === 'equals') {
        mappedOperator = 'eq';
      } else if (item.operator === 'contains') {
        mappedOperator = 'like';
      } // Add more conditions as needed
    
      return {
        property: item.field,
        value: item.value,
        type: fieldType, // You might need to adjust this based on your data types
        operator: mappedOperator
      };
    });

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      query: desiredStructure,
      page: 0,
    }));

  };

  const handleSortModelChange = (sortModel) => {
    // Handle sorting changes and fetch data from the server
    //sort: [{"property":"lastname","direction":"ASC"}]

    console.log("handleSortModelChange()")

    const sortObject = sortModel.map(item => ({
      property: item.field,
      direction: item.sort.toUpperCase() // Assuming you want direction in uppercase (ASC or DESC)
    }));

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      sort: sortObject,
      page: 0,
    }));

      console.log(paginationModel)
  };

  const handlePageChange = (newPage) => {
    // Handle page changes and fetch data from the server

    console.log("handlePageChange()")

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPage.page,
      limit: newPage.pageSize,
    }));

  };

  const loadGridData = async () => {

    setGridLoading(true)

    const extraFilter = searchFilter ? `&extraFilter=${searchFilter}` : "";

    getListValuesPaging(navigate)(paginationModel, "attachment/"+entityIdPointer+ "/"+ entityTable+ "/"+entityKeyname, extraFilter)      .then((result) => {

        setData(result)
        setGridLoading(false)

      })
      .catch((error) => {

        console.log(error);
        setGridLoading(false)
      });

  }

  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const handleActionClick = (action, record) => {
    if (action === 'edit') {
      // Handle edit action
      console.log('Edit record:', record);
    } else if (action === 'delete') {
      // Handle delete action
      console.log('Delete record:', record);
      setSelectedRowEntityId(record.row.attachmentId)
      // onOptionClick(id, option, row);
      handleMenuClose();
      setDeleteDialogOpen(true)
    }
  };

  const columns = [
    {
      field: "attachmentId",
      headerName: "Entity Id",
      flex: 1,
      type: 'number'
    },
    {
        field: 'fileType',
        headerName: 'File Type',
        width: 100,
        renderCell: (params) => {
          const fileType = params.row.fileType.toLowerCase();
    
          switch (fileType) {
            case 'jpg':
            case 'jpeg':
                return (
                    <>
                        <BsFiletypeJpg size="2em" />&nbsp;&nbsp;{params.row.fileType}
                    </>
                  );
            case 'png':
              return (
                <>
                  <BsFiletypePng size="2em" />&nbsp;&nbsp;{params.row.fileType}
                </>
              );
            case 'xls':
                return (
                    <>
                        <BsFiletypeXls size="2em" 
                        style={{
                          color: 'green',
                        }}/>&nbsp;&nbsp;{params.row.fileType}
                    </>
                  );
            case 'xlsx':
              return (
                <>
                    <BsFiletypeXlsx size="2em" 
                    style={{
                      color: 'green',
                    }}/>&nbsp;&nbsp;{params.row.fileType}
                </>
              );
            case 'pdf':
              return (
                <>
                    <BsFiletypePdf
                      size="2em"
                      style={{
                        color: 'red',
                      }}
                    />&nbsp;&nbsp;{params.row.fileType}
                </>
              );
            case 'doc':
                return (
                    <>
                    <BsFiletypeDoc size="2em" 
                          style={{
                            color: 'blue',
                          }}/>&nbsp;&nbsp;{params.row.fileType}
                    </>
                  );
            case 'docx':
              return (
                <>
                <BsFiletypeDocx size="2em" 
                style={{
                  color: 'blue',
                }}/>&nbsp;&nbsp;{params.row.fileType}
                </>
              );
            case 'txt':
              return (
                <>
                <BsFiletypeTxt size="2em" />&nbsp;&nbsp;{params.row.fileType}
                </>
              );
            case 'mpeg':
            case 'mp4':
            case 'wav':
              return (
                <>
                    <BsFiletypeMp4 size="2em" />&nbsp;&nbsp;{params.row.fileType}
                </>
              );
            case 'htm':
            case 'html':
                return (
                    <>
                        <BsFiletypeHtml size="2em" />&nbsp;&nbsp;{params.row.fileType}
                    </>
                    );
            case 'js':
                return (
                    <>
                        <BsFiletypeJs size="2em" />&nbsp;&nbsp;{params.row.fileType}
                    </>
                  );
            case 'css':
              return (
                <>
                    <BsFiletypeCss size="2em" />&nbsp;&nbsp;{params.row.fileType}
                </>
              );
            default:
              return (
                <>
                    <BsFilesAlt size="2em" />&nbsp;&nbsp;{params.row.fileType}
                </>
              );
          }
        },
      },   
    {
        field: 'download',
        headerName: 'Download',
        width: 150,
        renderCell: (params) => {            

            const handleDownload = () => {
                
                var token = getSessionToken();
      
                const url = getUri(token) 
    
                var win = window.open(url+'attachment/'+params.row.attachmentId+'/file' , '_blank');
                if(win){
                    //Browser has allowed it to be opened
                    win.focus();
                }else{
                    //Broswer has blocked it
                    alert('Please allow popups for this site');
                }
              };

            return (
            <Tooltip title="Download File">
                <IconButton onClick={handleDownload}>
                <GetApp />
                </IconButton>
            </Tooltip>
            );
        },
        },
    {
      field: "fileName",
      headerName: "Name",
      flex: 1,
    },
    
    {
      field: "fileSize",
      headerName: tm.translate('clmFileSize', "File Size") ,
      flex: "auto",
      minWidth: 120,
      renderCell: (params) => {
        var fileSize = '';
        
        if(params.row.fileSize != null){
            fileSize = params.row.fileSize+' MB';
        }
        return <span>{fileSize+' MB'}</span>;
      }
    },
    {
      field: "whoCreated",
      headerName: tm.translate('clmWhoCreated', "Who Created"),
      minWidth: 220,
    },
    {
        field: "whenCreated",
        headerName: tm.translate('clmWhenCreated', "When Created"),
        minWidth: 220,
        renderCell: (params) => {
  
          if(params.value == null){
            return ""
          }
          const utcDateTime = params.value; // Assuming params.value contains the UTC timestamp
          const localDateTime = dayjs.utc(utcDateTime).local().format('DD.MM.YYYY HH:mm'); // Convert UTC to local and format
          return <span>{localDateTime}</span>;
        }
        
    },
      {
        field: 'action2',
        headerName: 'Actions',
         // flex: 1,
        width: 80,
        sortable: false,
        renderCell: (params) => (
          <ActionColumn record={params} handleActionClick={handleActionClick} />
        ),
      },

    
  ];

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteRow = () => {

    setDeleteDialogOpen(false)
    dispatch(showLoading( ""))

    if(selectedRowEntityId > 0){
      deleteAttachment(navigate)(selectedRowEntityId)
      .then((result) => {
  
        if(result.success){
  
        }
        setLocalTriggerLoad(!localTriggerLoad);
  
        dispatch(hideLoading());
  
      })
      .catch((error) => {
  
        console.log(error);
  
        dispatch(hideLoading());
      });
    }
    
  }

  return (
    <>
    <DialogYesNo
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => handleDeleteRow()}
        theme={theme}
        message={"do you really want to delete the document."}
        entity= {{}}
      />
   
    <DataGridPro
          loading={gridLoading || !data}
          initialState={{
            pagination: { paginationModel: { pageSize: paginationModel.limit } },
          }}
          // getRowId={(row) => row.entityId}
          getRowId={(row) => `${row.attachmentId}`}
          rows={data?.data || []}
          columns={columns}
          rowCount={(data && data.total_count) || 0}
          rowsPerPageOptions={[25, 50, 100]}
          columnVisibilityModel={columnVisibilityModel}
          // onColumnVisibilityChange={handleColumnVisibilityChange}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }

        //   checkboxSelection

          filterMode="server"
          onFilterModelChange={handleFilterModelChange}

          sortingMode="server"
          onSortModelChange={handleSortModelChange}

          paginationMode="server"
          pagination
          page={paginationModel.page}
          pageSize={paginationModel.limit}
          onPaginationModelChange={(paginationModel) => handlePageChange(paginationModel)} //setPageSize(paginationModel.pageSize)

          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            filterPanel: {
              // Force usage of "And" operator
              logicOperators: [],
              // Display columns by ascending alphabetical order
              columnsSort: 'asc',
              
              sx: {
                '& .MuiButtonBase-root': { color: `${theme.palette.secondary[200]} !important` },
                '& .MuiFormLabel-root': { color: `${theme.palette.secondary[200]} !important` },
              },
            },
          }}
        />
         </>
  );
};

export default ListAttachments