import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { Upload, Download, Refresh, Settings } from '@mui/icons-material';
import { getExternalDevice, getApplicationToken, getOverRideSettings } from 'hooks/HttpRequests';
import tm from 'components/TranslationManager';
import ApplicationModeConfig from './config/ApplicationModeConfig';
import CameraSettingsConfig from './config/CameraSettingsConfig';
import PrinterSettingsConfig from './config/PrinterSettingsConfig';
import StackerVisitorConfig from './config/StackerVisitorConfig';
import StackerHostAuthConfig from './config/StackerHostAuthConfig';
import StackerCardReplacementConfig from './config/StackerCardReplacementConfig';
import { hideLoading, showLoading } from 'state/loadingSlicer';
import { useDispatch } from 'react-redux';
import DialogLoadKioskSettingsFromDifferentDevice from 'components/dialog/DialogLoadKioskSettingsFromDifferentDevice';
import { useUserProfileManager } from 'components/UserProfileProvider';
import { enqueueSnackbar } from 'notistack';

const DeviceConfiguration = ({ globalType, navigate, id, route }) => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();
  const [loadedFormData, setLoadedFormData] = useState({});
  const [loadedApplicationTokenData, setLoadedApplicationTokenData] = useState({});
  const [openLoadDifferentSettings, setOpenLoadDifferentSettings] = useState(false);
  const [loading, setLoading] = useState(false);
  const ws = useRef(null); // WebSocket reference
  const { userProfile } = useUserProfileManager();
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [triggerRefresh]);

  const loadData = async () => {
    setLoading(true);
    dispatch(showLoading(""));

    getExternalDevice(navigate)(id)
      .then((result) => {
        if (result.success) {
          setLoadedFormData(result.data);
          loadApplicationToken(result.data.applicationToken);
        }
        setLoading(false);
        dispatch(hideLoading());
      })
      .catch(() => {
        dispatch(hideLoading());
        setLoading(false);
      });
  };

  const loadApplicationToken = async (applicationTokenId) => {
    setLoading(true);
    dispatch(showLoading(""));

    getApplicationToken(navigate)(applicationTokenId)
      .then((result) => {
        if (result.success) setLoadedApplicationTokenData(result.data);
        setLoading(false);
        dispatch(hideLoading());
      })
      .catch(() => {
        dispatch(hideLoading());
        setLoading(false);
      });
  };

  const onOverride = async (device) => {
    setOpenLoadDifferentSettings(false);
    const targetDeviceId = id;
    const sourceDeviceId = device.entityId;
    dispatch(showLoading(""));

    getOverRideSettings(navigate)(targetDeviceId, sourceDeviceId)
      .then((result) => {
        if (result.success) setLoadedApplicationTokenData(result.data);
        setLoading(false);
        dispatch(hideLoading());
      })
      .catch(() => {
        dispatch(hideLoading());
        setLoading(false);
      });
  };

  const onUploadConfiguration = () => {
    if (!userProfile || !userProfile.session) {
      console.error("User profile not loaded.");
      return;
    }

    const from = btoa(`secs-${userProfile.session.sessionid}`);
    const to = `${loadedApplicationTokenData.applicationId}:${loadedApplicationTokenData.applicationSecret}`;
    const wsUrl = `${window.location.protocol === "https:" ? "wss" : "ws"}://${window.location.host}/${process.env.REACT_APP_WEBSOCKET}/socket/${from}/external_device`;
    // const wsUrl = `ws://localhost:8085/sadevio_socket/socket/${from}/external_device`; // Hardcoded URL for debugging

    console.log("Opening WebSocket:", wsUrl);
    
    ws.current = new WebSocket(wsUrl);

    ws.current.onopen = () => {
      console.log("WebSocket Connected");
      dispatch(showLoading("Uploading configuration..."));

      const message = JSON.stringify({ action: "upload_config", from, to });
      ws.current.send(message);
      console.log("Sent:", message);
    };

    ws.current.onmessage = (event) => {
      console.log("Received:", event.data);
      try {
        const response = JSON.parse(event.data);

        if (response.action === "upload_config_error") {
          console.error("Upload Config Error:", response.message);
          enqueueSnackbar({
                          message: response.message,
                            variant: 'error',
                            anchorOrigin: {
                              vertical: 'top', // 'top', 'bottom'
                              horizontal: 'center', // 'left', 'center', 'right'
                            },
                            autoHideDuration: 3000, 
                            // persist: true
                          
                        });
        }else if (response.action === "upload_config_uploaded") {

          enqueueSnackbar({
                          message: "Upload Config completed",
                            variant: 'success',
                            anchorOrigin: {
                              vertical: 'top', // 'top', 'bottom'
                              horizontal: 'center', // 'left', 'center', 'right'
                            },
                            autoHideDuration: 3000, 
                            // persist: true
                          
                        });
                        setTriggerRefresh(!triggerRefresh)
        }
      } catch (e) {
        console.error("Invalid WebSocket response:", event.data);
      }

      dispatch(hideLoading());
    };
    

    ws.current.onerror = (error) => {
      console.error("WebSocket Error:", error);
      dispatch(hideLoading());
    };

    ws.current.onclose = () => {
      console.log("WebSocket Disconnected");
    };
  };

  const onDownloadConfiguration = () => {
    if (!userProfile || !userProfile.session) {
      console.error("User profile not loaded.");
      return;
    }

    const from = btoa(`secs-${userProfile.session.sessionid}`);
    const to = `${loadedApplicationTokenData.applicationId}:${loadedApplicationTokenData.applicationSecret}`;
    const wsUrl = `${window.location.protocol === "https:" ? "wss" : "ws"}://${window.location.host}/${process.env.REACT_APP_WEBSOCKET}/socket/${from}/external_device`;
    // const wsUrl = `ws://localhost:8085/sadevio_socket/socket/${from}/external_device`; // Hardcoded URL for debugging

    console.log("Opening WebSocket:", wsUrl);
    
    ws.current = new WebSocket(wsUrl);

    ws.current.onopen = () => {
      console.log("WebSocket Connected");
      dispatch(showLoading("Uploading configuration..."));

      const message = JSON.stringify({ action: "download_config", from, to });
      ws.current.send(message);
      console.log("Sent:", message);
    };

    ws.current.onmessage = (event) => {
      console.log("Received:", event.data);
      try {
        const response = JSON.parse(event.data);

        if (response.action === "download_config_error") {
          console.error("Upload Config Error:", response.message);
          enqueueSnackbar({
                          message: response.message,
                            variant: 'error',
                            anchorOrigin: {
                              vertical: 'top', // 'top', 'bottom'
                              horizontal: 'center', // 'left', 'center', 'right'
                            },
                            autoHideDuration: 3000, 
                            // persist: true
                          
                        });
        }else if (response.action === "download_config_uploaded_error") {

          console.error("Upload Config Error:", response.message);
          enqueueSnackbar({
                          message: response.message,
                            variant: 'error',
                            anchorOrigin: {
                              vertical: 'top', // 'top', 'bottom'
                              horizontal: 'center', // 'left', 'center', 'right'
                            },
                            autoHideDuration: 3000, 
                            // persist: true
                          
                        });
        }else if (response.action === "download_config_uploaded") {

          enqueueSnackbar({
                          message: "Upload Config completed",
                            variant: 'success',
                            anchorOrigin: {
                              vertical: 'top', // 'top', 'bottom'
                              horizontal: 'center', // 'left', 'center', 'right'
                            },
                            autoHideDuration: 3000, 
                            // persist: true
                          
                        });
                        setTriggerRefresh(!triggerRefresh)

        }
      } catch (e) {
        console.error("Invalid WebSocket response:", event.data);
      }

      dispatch(hideLoading());
    };
    

    ws.current.onerror = (error) => {
      console.error("WebSocket Error:", error);
      dispatch(hideLoading());
    };

    ws.current.onclose = () => {
      console.log("WebSocket Disconnected");
    };
  };

  const onRefreshWorkflow = () => {
    if (!userProfile || !userProfile.session) {
      console.error("User profile not loaded.");
      return;
    }

    const from = btoa(`secs-${userProfile.session.sessionid}`);
    const to = `${loadedApplicationTokenData.applicationId}:${loadedApplicationTokenData.applicationSecret}`;
    const wsUrl = `${window.location.protocol === "https:" ? "wss" : "ws"}://${window.location.host}/${process.env.REACT_APP_WEBSOCKET}/socket/${from}/external_device`;
    // const wsUrl = `ws://localhost:8085/sadevio_socket/socket/${from}/external_device`; // Hardcoded URL for debugging

    console.log("Opening WebSocket:", wsUrl);
    
    ws.current = new WebSocket(wsUrl);

    ws.current.onopen = () => {
      console.log("WebSocket Connected");
      dispatch(showLoading("Uploading configuration..."));

      const message = JSON.stringify({ action: "sync_config", from, to });
      ws.current.send(message);
      console.log("Sent:", message);
    };

    ws.current.onmessage = (event) => {
      console.log("Received:", event.data);
      try {
        const response = JSON.parse(event.data);

        if (response.action === "sync_config_error") {
          console.error("Upload Config Error:", response.message);
          enqueueSnackbar({
                          message: response.message,
                            variant: 'error',
                            anchorOrigin: {
                              vertical: 'top', // 'top', 'bottom'
                              horizontal: 'center', // 'left', 'center', 'right'
                            },
                            autoHideDuration: 3000, 
                            // persist: true
                          
                        });
        }else if (response.action === "sync_config_uploaded_error") {

          console.error("Upload Config Error:", response.message);
          enqueueSnackbar({
                          message: response.message,
                            variant: 'error',
                            anchorOrigin: {
                              vertical: 'top', // 'top', 'bottom'
                              horizontal: 'center', // 'left', 'center', 'right'
                            },
                            autoHideDuration: 3000, 
                            // persist: true
                          
                        });
        }else if (response.action === "sync_config_uploaded") {

          enqueueSnackbar({
                          message: "Upload Config completed",
                            variant: 'success',
                            anchorOrigin: {
                              vertical: 'top', // 'top', 'bottom'
                              horizontal: 'center', // 'left', 'center', 'right'
                            },
                            autoHideDuration: 3000, 
                            // persist: true
                          
                        });
                        setTriggerRefresh(!triggerRefresh)

        }
      } catch (e) {
        console.error("Invalid WebSocket response:", event.data);
      }

      dispatch(hideLoading());
    };
    

    ws.current.onerror = (error) => {
      console.error("WebSocket Error:", error);
      dispatch(hideLoading());
    };

    ws.current.onclose = () => {
      console.log("WebSocket Disconnected");
    };
  };

  return (
    <Box m="20px">
      <DialogLoadKioskSettingsFromDifferentDevice
        open={openLoadDifferentSettings}
        onClose={() => setOpenLoadDifferentSettings(false)}
        onSave={() => setOpenLoadDifferentSettings(false)}
        theme={theme}
        onOverride={onOverride}
      />

      <Box display="flex" flexDirection={isNonMobile ? "row" : "column"} gap={2}>
        <Button
          startIcon={<Upload />}
          onClick={onUploadConfiguration}
          sx={{
            flex: isNonMobile ? "1" : "unset",
            p: "1rem",
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontWeight: "bold",
            "&:hover": {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.secondary.light,
            },
          }}
        >
          {tm.translate("btnUploadSettings", "Upload Kiosk Settings to Server")}
        </Button>

        <Button
          startIcon={<Download />}
          onClick={onDownloadConfiguration}
          sx={{
            flex: isNonMobile ? "1" : "unset",
            p: "1rem",
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontWeight: "bold",
            "&:hover": {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.secondary.light,
            },
          }}
        >
          {tm.translate("btnDownloadSettings", "Download Kiosk Settings to Kiosk")}
        </Button>

        <Button
          startIcon={<Refresh />}
          onClick={onRefreshWorkflow}
          sx={{
            flex: isNonMobile ? "1" : "unset",
            p: "1rem",
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontWeight: "bold",
            "&:hover": {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.secondary.light,
            },
          }}
        >
          {tm.translate("btnDownloadConfiguration", "Refresh Workflow Configuration On Kiosk")}
        </Button>

        <Button
          startIcon={<Settings />}
          onClick={() => setOpenLoadDifferentSettings(true)}
          sx={{
            flex: isNonMobile ? "1" : "unset",
            p: "1rem",
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.secondary.light,
            fontWeight: "bold",
            "&:hover": {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.secondary.light,
            },
          }}
        >
          {tm.translate("btnLoadSettings", "Load Settings From Different Device")}
        </Button>
      </Box>

      <ApplicationModeConfig id={id} />
      <CameraSettingsConfig id={id} />
      <PrinterSettingsConfig id={id} />
      <StackerVisitorConfig id={id} />
      <StackerHostAuthConfig id={id} />
      <StackerCardReplacementConfig id={id} />
    </Box>
  );
};

export default DeviceConfiguration;
