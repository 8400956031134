import React, { useEffect,  useState } from 'react';
import { Box, Typography,  ListItemIcon,  Button, useTheme, Grid, Paper, useMediaQuery, Divider, IconButton, Menu } from '@mui/material';
import { ArrowDownward, Clear, DragHandle, KeyboardArrowDown } from '@mui/icons-material';
import { changeWorkflowElementsOrder, deleteWorkflowElement, getVisitorTypesWorkflow, getVisitorTypeWorkflow, updateWorkflowElementVisitorData } from 'hooks/HttpRequests';

import * as yup from "yup";
import { useFrontDeskManager } from 'components/FrontDeskManager';
import Header from 'components/Header';
import { hideLoading, showLoading } from 'state/loadingSlicer';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TakePictureConfig from './TakePictureConfig';
import MeetingRoomConfig from './MeetingRoomConfig';
import ForceEscortConfig from './ForceEscortConfig';
import HostLookUpConfig from './HostLookUpConfig';
import VisitorDataConfig from './VisitorDataConfig';
import DialogYesNo from 'components/dialog/DialogYesNo';
import { enqueueSnackbar } from 'notistack';
import MenuItemsAddWorkflowElement from 'components/MenuItemsAddWorkflowElement';
import DriverLicenseUSConfig from './DriverLicenseUSConfig';


const WorkflowSettingsGeneral = ({  }) => {

const theme = useTheme();
const {palette} = useTheme();
const isNonMobile = useMediaQuery("(min-width:600px)");

const dispatch = useDispatch();
const navigate = useNavigate();

const { id } = useParams(); // Extract the 'id' parameter from the URL

const [triggerRefresh, setTriggerRefresh] = useState(false);

const [confirmationOpen, setConfirmationOpen] = useState(false);
const [yesNoMessage, setYesNoMessage] = useState("");
const [yesNoDialogEntity, setYesNoDialogEntity] = useState({})

const { selectedFrontDesk, frontDeskLoading } = useFrontDeskManager();

const [loadingComplete, setLoadingComplete] = useState(false);
const [loadedFormData, setLoadedFormData] = useState(null);
const [loadedVisitorTypeData, setLoadedVisitorTypeData] = useState(null);

const [selectedStep, setSelectedStep] = useState(null); // Track selected step

    ///////////////////////////////////////////////
    //  Add Element
    ///////////////////////////////////////////////

    const [anchorElVisitStatus, setAnchorElVisitStatus] = useState(null);
    const openMenuVisitStatus = Boolean(anchorElVisitStatus);
    // const handleClick = (event: MouseEvent<HTMLElement>) => {
    // //   setAnchorEl(event.currentTarget);
    // };
    const handleClick = (event) => {
      setAnchorElVisitStatus(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorElVisitStatus(null);
    };

    useEffect(() => {
        if (id && selectedFrontDesk) {
            loadVisitorTypes(id);
            loadVisitorType(id)
        }
    }, [id, selectedFrontDesk, triggerRefresh]);

    useEffect(() => {
        if (loadedFormData && loadedFormData.length > 0) {
            setSelectedStep(loadedFormData[0]); // Automatically select the first element
        }
    }, [loadedFormData]);

  const loadVisitorTypes = async (visitorType) => {

        // setGridLoading(true)
        dispatch(showLoading());
        console.log(visitorType)

        getVisitorTypesWorkflow(navigate)(selectedFrontDesk.entityId, visitorType)
        .then((result) => {

            // Use the result.data as needed
            if(result.success){
                console.log(loadedFormData)
                setLoadedFormData(result.data)

            }
            // setGridLoading(false)
            dispatch(hideLoading());


        })
        .catch((error) => {

            // setGridLoading(false)
            dispatch(hideLoading());

        });

    }

    const loadVisitorType = async (visitorType) => {

        // setGridLoading(true)
        dispatch(showLoading());
        console.log(visitorType)

        getVisitorTypeWorkflow(navigate)(selectedFrontDesk.entityId, visitorType)
        .then((result) => {

            // Use the result.data as needed
            if(result.success){
                console.log(loadedFormData)
                setLoadedVisitorTypeData(result.data)

            }
            // setGridLoading(false)
            dispatch(hideLoading());


        })
        .catch((error) => {

            // setGridLoading(false)
            dispatch(hideLoading());

        });

    }

    if (frontDeskLoading || !loadedFormData) {
        return <div>Loading...</div>;
    }

const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const registerSchema = yup.object().shape({
    name: yup.string().required("required"),
    street: yup.string().required("required"),
    city: yup.string().required("required"),

  });

  const initialValuesRegister = {
    status: "active",
    name: "",
    category: "",
    active: false,
    street: "",
    city: "",
    zip: "",
    phone: "",
    badgeRenewalPeriod: "",
    category: null,
    minAuthSignatoryCount: 1,
    maxAuthSignatoryCount: 1,
    exemptChrc: false,
    exemptSta: false,

  };

  function handleDragEnd(result, test) {

    if (!result.destination) return;

    // Offset for fixed elements at the beginning of the array
    const offset = 1; // Adjust this based on the number of fixed elements

    // Calculate the actual source and destination indices in loadedFormData
    const sourceIndex = result.source.index + offset;
    const destinationIndex = result.destination.index + offset;

    const draggedItem = loadedFormData[result.source.index+ offset];
    const dropTargetItem = loadedFormData[result.destination.index+ offset];

    const dropPosition = result.destination.index > result.source.index ? 'after' : 'before';


    // Reorder items in the state based on the adjusted indices
    const items = Array.from(loadedFormData);
    const [reorderedItem] = items.splice(sourceIndex, 1); // Remove the item
    items.splice(destinationIndex, 0, reorderedItem); // Insert the item

    // Update the state with the new order
    setLoadedFormData(items);

    // Extract required data for the backend
    const draggedVisitorType = draggedItem.entityId;
    const dropTargetVisitorType = dropTargetItem.entityId

    changeWorkflowElementsOrder(navigate)(selectedFrontDesk.entityId , draggedVisitorType, dropPosition , dropTargetVisitorType, id)
      .then((result) => {

        // Use the result.data as needed
        if(result.success){

        }
         
        // setGridLoading(false)

      })
      .catch((error) => {

        console.log(error);

        // setGridLoading(false)
      });

  }

  const handleElementClick = (step) => {
        console.log("Element clicked:", step);
        // Perform desired action, e.g., navigate to another page or open a modal
        setSelectedStep(step); // Update the selected step

    };

    const handleSave = (step) => {
        // console.log("Element clicked:", step);
        // // Perform desired action, e.g., navigate to another page or open a modal
        // setSelectedStep(step); // Update the selected step
        setTriggerRefresh(!triggerRefresh);
    };

    const handleDeleteElement = (element) => {
        console.log("delete entity"+element)
    
        // Handle delete action
        setConfirmationOpen(true);
        setYesNoMessage("Do you really want to delete this entity?")
        setYesNoDialogEntity(element)
              
            
      }

      const handleExecuteDelete = async (values) => {


        setConfirmationOpen(false)
    
        dispatch(showLoading(""))
    
        deleteWorkflowElement(navigate)(selectedFrontDesk.entityId, values.entityId)
            .then((result) => {
            // Use the result.data as needed      
            dispatch(hideLoading());
                if(result.success){
                    
                  enqueueSnackbar({
                    message: "Was deleted successfully",
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
    
                  setTriggerRefresh(!triggerRefresh);
    
                }else{
    
    
    
                  enqueueSnackbar({
                    message: "Something went wrong during request",
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
                }
    
    
          })
          .catch((error) => {
            dispatch(hideLoading());
    
            if (typeof error.action !== "undefined") {
              if (error.action == "relogin") {
                navigate("/");
              }else if(error.action == "force_delete"){
                  console.log("force delete")
                  setConfirmationOpen(true);
                  setYesNoMessage("This entity has still referecnes to visits. Do you want to force the delete?")
                  yesNoDialogEntity["action"] = "force_delete";
                  setYesNoDialogEntity(yesNoDialogEntity)
              }
            }else if (error.hasOwnProperty('success')) {
              enqueueSnackbar({
                message: "Error "+error.message,
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }
    
          });
    
        
        }

        
    const handleAddWorkflowElement = async (values) => {

        setAnchorElVisitStatus(null);

        var errorMessage = '';
                    
        
        for(var n = 0; n < loadedFormData.length; n++){
            
            var tmp = loadedFormData[n];
            
            if(tmp.engineType == 'take_picture' && values.engine_type == tmp.engineType){		
                errorMessage = 'The selected module is already added to your workflow.';	
                break;
            }
            
            if(tmp.engineType == 'meeting_room' && values.engine_type == tmp.engineType){		
                errorMessage = 'The selected module is already added to your workflow.';
                break;	
            }
            
            if(tmp.engineType == 'sign_document' && tmp.title == 'Sign Document' && values.engine_type == tmp.engineType){		
                errorMessage = 'You already have a document with the title "Sign Document". Please rename first your existing document title.';	
                break;
            }
            
            if(tmp.engineType == 'general_info' && tmp.title == 'General Info' && values.engine_type == tmp.engineType){		
                errorMessage = 'You already have general information with the title "General Info". Please rename first your existing information title.';	
                break;
            }
            
            if(tmp.engineType == 'youtube_video' && tmp.title == 'YouTube Video' && values.engine_type == tmp.engineType){		
                errorMessage = 'You already have a YouTube video with the title "YouTube Video". Please rename first your existing information title.';
                break;	
            }

            if(tmp.engineType == 'workflow_video' && tmp.title == 'Workflow Video' && values.engine_type == tmp.engineType){
                errorMessage = 'You already have a Workflow video with the title "Workflow Video". Please rename first your existing information title.';
                break;
            }

            if(tmp.engineType == 'question_yes_no' && tmp.title == 'Question Yes/No' && values.engine_type == tmp.engineType){		
                errorMessage = 'You already have a question with the title "Question Yes/No". Please rename first your existing information title.';
                break;
            }

            if(tmp.engineType == 'question_multiple' && tmp.title == 'Question Multiple' && values.engine_type == tmp.engineType){		
                errorMessage = 'You already have a question with the title "Question Multiple". Please rename first your existing information title.';	
                break;
            }
            if(tmp.engineType == 'driver_license_us' && values.engine_type == tmp.engineType){		
                errorMessage = 'The selected module is already added to your workflow.';	
                break;
            }
            
        }

        if(errorMessage != ""){
            enqueueSnackbar({
                message: "Your selected element is already installed",
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
                
            });
            return;
        }else{

            var newEntry = {};
			
				newEntry["configuration"] = '';
				newEntry["engineType"] = values.engine_type;
				newEntry["entityId"] = -1
				newEntry["frontDeskEntityId"] = selectedFrontDesk.entityId
				newEntry["title"] = values.text;
				newEntry["workflowType"] = "visitor_check_in";
				newEntry["workflowTypeSub"] = loadedVisitorTypeData.visitorType;


                setLoadedFormData((prevData) => {
                    if (!prevData || prevData.length === 0) return [newEntry]; // Handle empty array
                
                    return [
                        ...prevData.slice(0, prevData.length - 1), // Keep everything except the last item
                        newEntry, // Insert new entry before the last item
                        prevData[prevData.length - 1] // Append the last item back
                    ];
                });

                setSelectedStep(newEntry)
        }

        

        
    }


    

    if (frontDeskLoading || !loadedFormData || !loadedVisitorTypeData) {
        return <div>Loading...</div>;
    }

  return (

    <Box m="20px">
        <DialogYesNo
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            onConfirm={handleExecuteDelete}
            theme={theme}
            message={yesNoMessage}
            entity={yesNoDialogEntity}
        />
        <Header title={`Workflow - ${id}`} subtitle="" elements={null} backbutton={"/visit/settings/visitor-types"} />

        {/* main box */}
        <Box sx={{ ...commonStyles,height: '85vh', border: 1 }}>
            
        <Box
            sx={{
                height: '100%', // Fill parent height
                overflow: 'auto', // Enable scrolling
            }}
        >
       
            {/* Top Row */}
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} ml={1} mt={3}>
                {/* Left-aligned Add Element button */}
                <Box>
                    <Button
                        id="demo-customized-button"
                        aria-controls={openMenuVisitStatus ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenuVisitStatus ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDown />}
                        sx={{
                            p: "1rem",
                            ml: 1,
                            backgroundColor: palette.secondary.light,
                            color: palette.background.alt,
                            fontWeight: "bold",
                            "&:hover": {
                                color: palette.primary.main,
                                backgroundColor: palette.secondary.light,
                                fontWeight: "bold",
                            },
                        }}
                    >
                        Add Workflow Element
                    </Button>
                    <Menu
                        id="demo-customized-menu-bla"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorElVisitStatus}
                        open={openMenuVisitStatus}
                        onClose={handleClose}
                        sx={{
                            '& .MuiMenu-list': {
                                padding: '4px 0',
                                backgroundColor: palette.secondary.light,
                            },
                            '& .MuiDivider-root': {
                                backgroundColor: palette.primary.main,
                                color: palette.primary.main,
                            },
                            '& .MuiMenuItem-root': {
                                backgroundColor: palette.secondary.light,
                                color: palette.primary.main,
                                '& .MuiSvgIcon-root': {
                                    fontSize: 18,
                                    color: palette.primary.main,
                                    backgroundColor: palette.secondary.light,
                                    marginRight: 2,
                                },
                            },
                        }}
                    >
                        <MenuItemsAddWorkflowElement
                            handleAddWorkflowElement={handleAddWorkflowElement}
                        />
                    </Menu>
                </Box>

                {/* Right-aligned Save Configuration button */}
                <Box>
                    <Button
                        id="demo-customized-save-button"
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        sx={{
                            p: "1rem",
                            mr: 2,
                            backgroundColor: palette.secondary.light,
                            color: palette.background.alt,
                            fontWeight: "bold",
                            "&:hover": {
                                color: palette.primary.main,
                                backgroundColor: palette.secondary.light,
                                fontWeight: "bold",
                            },
                        }}
                    >
                        Save Configuration To Different Front Desk
                    </Button>
                </Box>
            </Box>


            {/* Bottom Rows */}
            <Grid container spacing={2} style={{ backgroundColor: 'transparent', padding: "2px 0px 2px 0px" }} >
                {/* Left Column */}
                
                    <Grid item xs={3} style={{ padding: 10, backgroundImage: 'transparent' }}>
                  
                        <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)'  }}
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                              }}
                            >
                                
                                <Box
                                    sx={{
                                        ...commonStyles,
                                        cursor: "pointer", // Indicate clickability
                     
                                    }}
                                    onClick={() => handleElementClick(loadedFormData[0])} // Trigger function on click
                                    >
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        {/* Bordered Content */}
                                        <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            border: 1,
                                            borderColor: theme.palette.secondary[500], // Custom border for this section
                                            borderRadius: 1,
                                            padding: "0.5rem",
                                            flexGrow: 1, // Allow it to take up space
                                            marginRight: "1rem", // Space between this box and the simulated ListItemIcon
                                            backgroundColor:
                                                selectedStep?.entityId ===
                                                loadedFormData[0].entityId
                                                    ? theme.palette.primary.light // Highlight selected
                                                    : theme.palette.background.paper, // Default background
                                                color:
                                                selectedStep?.entityId ===
                                                loadedFormData[0].entityId
                                                    ? theme.palette.primary.contrastText
                                                    : theme.palette.text.primary,
                                            borderColor: theme.palette.secondary[500], // Custom border for this section

                                        }}
                                        >
                                        <Typography
                                            variant="h5"
                                            color={theme.palette.secondary[100]}
                                        >
                                            Host lookup
                                        </Typography>
                                        </Box>

                                        {/* Simulated Drag Handle */}
                                        <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexShrink: 0,
                                            justifyContent: "flex-end",
                                            width: "auto",
                                            minWidth: "24px", // Match size of dynamic ListItemIcon
                                        }}
                                        />
                                    </Box>
                                </Box>

                                    <Divider sx={{ my: 0.5 }} />

                                    <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center", // Center horizontally
                                        alignItems: "center", // Center vertically
                                        width: "100%", // Full width
                                        my: 1, // Add vertical margin
                                    }}
                                    >
                                    <ArrowDownward
                                        sx={{
                                        fontSize: 20,
                                        color: theme.palette.secondary[300],
                                        }}
                                    />
                                    </Box>

                                    {/* -------------------------- */}
                                    {/* START DYNAMIC DRAG DROP */}
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId="workflowSteps">
                                        {(provided) => (
                                        <Box
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            sx={{
                                            display: "flex",
                                            flexDirection: "column", // Stack elements vertically
                                            }}
                                        >
                                            {loadedFormData
                                            .filter(
                                                (step) =>
                                                step.engineType !== "host_lookup" &&
                                                step.engineType !== "force_escort"
                                            ) // Skip unwanted types
                                            .map((step, index) => (
                                                <Box key={`${step.entityId}-${index}`}>
                                                <Draggable
                                                    key={`${step.entityId}-${index}`}
                                                    draggableId={`${step.entityId}-${index}`}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                    <Box
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        sx={{
                                                        ...commonStyles,
                                                        cursor: "pointer", // Indicate clickability
                                                        }}
                                                        onClick={() => handleElementClick(step)} // Trigger function on click
                                                    >
                                                        <Box display="flex" flexDirection="row" alignItems="center">
                                                        <Box
                                                            sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            border: 1,
                                                            borderColor: theme.palette.secondary[500], // Custom border for this section
                                                            borderRadius: 1,
                                                            padding: "0.5rem",
                                                            flexGrow: 1,
                                                            marginRight: "1rem", // Space between this box and ListItemIcon
                                                            backgroundColor:
                                                                selectedStep?.entityId === step.entityId
                                                                ? theme.palette.primary.light // Highlight selected
                                                                : theme.palette.background.paper, // Default background
                                                            color:
                                                                selectedStep?.entityId === step.entityId
                                                                ? theme.palette.primary.contrastText
                                                                : theme.palette.text.primary,
                                                            }}
                                                        >
                                                            <Typography
                                                            variant="h5"
                                                            color={theme.palette.secondary[100]}
                                                            sx={{ flexGrow: 1 }} // Ensure title takes space
                                                            >
                                                            {step.title}
                                                            </Typography>
                                                            <IconButton
                                                            size="small"
                                                            sx={{
                                                                display:
                                                                step.engineType === "visitor_data"
                                                                    ? "none"
                                                                    : "inline-flex",
                                                                marginLeft: "0.5rem", // Space between title and delete button
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevent click from propagating
                                                                handleDeleteElement(step); // Call delete function
                                                            }}
                                                            >
                                                            <Clear />
                                                            </IconButton>
                                                        </Box>

                                                        {/* Drag Handle */}
                                                        <Box
                                                            sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            flexShrink: 0, // Prevent shrinking
                                                            justifyContent: "flex-end", // Align content to the right
                                                            width: "auto", // Ensure it does not take excessive space
                                                            }}
                                                        >
                                                            <ListItemIcon
                                                            sx={{
                                                                color: theme.palette.secondary[300],
                                                                minWidth: 0, // Remove extra padding inside ListItemIcon
                                                                padding: "0", // Ensure compact size
                                                            }}
                                                            >
                                                            <DragHandle />
                                                            </ListItemIcon>
                                                        </Box>
                                                        </Box>
                                                    </Box>
                                                    )}
                                                </Draggable>
                                                <Box
                                                    sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: "100%",
                                                    my: 1,
                                                    }}
                                                >
                                                    <ArrowDownward
                                                    sx={{
                                                        fontSize: 20,
                                                        color: theme.palette.secondary[300],
                                                    }}
                                                    />
                                                </Box>
                                                </Box>
                                            ))}
                                            {provided.placeholder}
                                        </Box>
                                        )}
                                    </Droppable>
                                    </DragDropContext>
                                    {/* END DYNAMIC DRAG DROP */}
                                    {/* -------------------------- */}

                                    <Divider sx={{ my: 0.5 }} />

                                    <Box
                                    sx={{
                                        ...commonStyles,
                                        cursor: "pointer", 
                                    }}
                                    onClick={() =>
                                        handleElementClick(loadedFormData[loadedFormData.length - 1])
                                    } // Trigger function on click
                                    >
                                    <Box display="flex" alignItems="center">
                                        <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            border: 1,
                                            borderColor: theme.palette.secondary[500], // Custom border for this section
                                            borderRadius: 1,
                                            padding: "0.5rem",
                                            flexGrow: 1,
                                            marginRight: "1rem",
                                            backgroundColor:
                                                selectedStep?.entityId ===
                                                loadedFormData[loadedFormData.length - 1].entityId
                                                    ? theme.palette.primary.light // Highlight selected
                                                    : theme.palette.background.paper, // Default background
                                                color:
                                                selectedStep?.entityId ===
                                                loadedFormData[loadedFormData.length - 1].entityId
                                                    ? theme.palette.primary.contrastText
                                                    : theme.palette.text.primary,
                                                borderColor: theme.palette.secondary[500], // Custom border for this section

                                        }}
                                        >
                                        <Typography
                                            variant="h5"
                                            color={theme.palette.secondary[100]}
                                        >
                                            Confirm by Host (force escort)
                                        </Typography>
                                        </Box>
                                        <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexShrink: 0,
                                            justifyContent: "flex-end",
                                            width: "auto",
                                            minWidth: "24px", // Match size of ListItemIcon
                                        }}
                                        />
                                    </Box>
                                    </Box>


                        </Paper>
                       

                    </Grid>

                {/* Right Column START*/}
                {/* Right Column START */}
                <Grid item xs={9}>
                <Paper
                    elevation={3}
                    style={{
                        padding: 20,
                        backgroundColor: 'transparent',
                        backgroundImage: 'none',
                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                        height: '100%', // Full height of parent
                    }}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%', // Full height of parent
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                >

                        {selectedStep ? (
                                        <Box>
                                        {/* Render form fields based on engineType */}
                                        {selectedStep.engineType === 'take_picture' && (
                                            <TakePictureConfig
                                                visitorType={id}
                                                frontDeskEntityId={selectedFrontDesk.entityId}
                                                initialValues={selectedStep}
                                                onSave={handleSave}
                                                visitorTypeEntity= {loadedVisitorTypeData}
                                            />
                                        )}
                                        {selectedStep.engineType === 'meeting_room' && (
                                            <MeetingRoomConfig
                                                visitorType={id}
                                                frontDeskEntityId={selectedFrontDesk.entityId}
                                                initialValues={selectedStep}
                                                onSave={handleSave}
                                                visitorTypeEntity= {loadedVisitorTypeData}
                                            />
                                        )}
                                        {selectedStep.engineType === 'force_escort' && (
                                            <ForceEscortConfig
                                                visitorType={id}
                                                frontDeskEntityId={selectedFrontDesk.entityId}
                                                initialValues={selectedStep}
                                                onSave={handleSave}
                                                visitorTypeEntity= {loadedVisitorTypeData}
                                            />
                                        )}
                                        {selectedStep.engineType === 'host_lookup' && (
                                            <HostLookUpConfig
                                                visitorType={id}
                                                frontDeskEntityId={selectedFrontDesk.entityId}
                                                initialValues={selectedStep}
                                                onSave={handleSave}
                                                visitorTypeEntity= {loadedVisitorTypeData}
                                            />
                                        )}
                                        {selectedStep.engineType === 'visitor_data' && (
                                            <VisitorDataConfig
                                                visitorType={id}
                                                frontDeskEntityId={selectedFrontDesk.entityId}
                                                initialValues={selectedStep}
                                                onSave={handleSave}
                                                visitorTypeEntity= {loadedVisitorTypeData}
                                            />
                                        )}
                                         {selectedStep.engineType === 'driver_license_us' && (
                                            <DriverLicenseUSConfig
                                                visitorType={id}
                                                frontDeskEntityId={selectedFrontDesk.entityId}
                                                initialValues={selectedStep}
                                                onSave={handleSave}
                                                visitorTypeEntity= {loadedVisitorTypeData}
                                            />
                                        )}
                                        {/* Default form if engineType doesn't match */}
                                        {![ 'take_picture', 'meeting_room', 'force_escort', 'host_lookup', 'visitor_data', 'driver_license_us'].includes(
                                            selectedStep.engineType
                                        ) && (
                                            <Typography color="textSecondary">
                                                No specific configuration available for this workflow step.
                                            </Typography>
                                        )}
                                        
                                        </Box>
                        ) : (
                            <Typography color="textSecondary">
                                Please select a workflow step to configure.
                            </Typography>
                        )}
                    </Paper>
                </Grid>
                {/* Right Column END */}

                {/* Right Column END*/}
            </Grid>
            
            </Box>   
        </Box>

        {/*  -------------------------------   */}    

         

    </Box>
  );
};

export default WorkflowSettingsGeneral;
