// Create a separate component for the Host cell
import React, { useState, useEffect } from "react";
import { Box, Link, Typography, CircularProgress } from "@mui/material";
import { getVisitHosts } from "hooks/HttpRequests";

const GridCellVisitHosts = ({ visitId, navigate }) => {
  const [hosts, setHosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const maxHostsToShow = 3;

  useEffect(() => {
    // Assume getVisitHosts returns a promise that fetches the data
    getVisitHosts(navigate)(visitId)
      .then((result) => {
        if (result.success) {
          setHosts(result.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching hosts:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [visitId, navigate]);

  if (loading) {
    return <CircularProgress size={20} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {hosts.slice(0, maxHostsToShow).map((host) => (
        <Box key={host.entityId} mb={1} mt={1}>
          <Link
            sx={{
              cursor: "pointer",
              fontWeight: "bold",
              fontSize: "16px",
              textDecoration: "none",
            }}
            onClick={() => navigate(`/employees/${host.entityId}`)}
          >
            {`${host.fullName} (${host.email})`}
          </Link>
        </Box>
      ))}
      {hosts.length > maxHostsToShow && (
        <Typography variant="body2" color="textSecondary">
          +{hosts.length - maxHostsToShow} more
        </Typography>
      )}
    </Box>
  );
};

export default GridCellVisitHosts;
