import { getFrontDeskTerminalSettings, getListValuesPaging, getMeetingRooms, getSessionToken } from 'hooks/HttpRequests';
import React, { createContext, useState, useEffect, useContext } from 'react';
import cookieManagerUtils from './CookieManagerUtils';
import dayjs from 'dayjs';

const FrontDeskManagerContext = createContext();

export const useFrontDeskManager = () => useContext(FrontDeskManagerContext);

export const FrontDeskManagerProvider = ({ children }) => {

  const [selectedFrontDeskEntityId, setSelectedFrontDeskEntityId] = useState(-1);
  const [selectedFrontDesk, setFrontDeskData] = useState(null);
  const [selectedFrontDeskTerminalSettings, setSelectedFrontDeskTerminalSettings] = useState(null);
  const [allAvailableFrontDesks, setAllAvailableFrontDesks] = useState([]);
  const [systemDateTimeFormat, setSystemDateTimeFormat] = useState('MM/DD/YYYY hh:mm A');
  const [meetingRooms, setMeetingRooms] = useState([]);

  const [frontDeskLoading, setLoading] = useState(false);
  const [meetingRoomsLoading, setMeetingRoomsLoading] = useState(false);
  const [frontDeskLoadingError, setError] = useState(null);

  const [externalLocationTooltipTrigger, setExternalLocationTooltipTrigger] = useState(false);

  const triggerLocationTooltip = () => {
    setExternalLocationTooltipTrigger(prev => !prev); // toggles between true/false
  };

  const fetchFrontDeskData = async () => {
    try {

        setLoading(true);
        setError(null);
        const token = getSessionToken();

        const requestParams = {
            "page": 0,
            "limit": 100,
            "sort": [],
            "query": []
        }

        if (token === '') {
          throw { success: false, action: 'relogin' };
        }

        getListValuesPaging()(requestParams, "frontdesk/user", "")
          .then((response) => {

            if (response.success) {

              var frontDeskArray = response.data;

              setAllAvailableFrontDesks(frontDeskArray)

              var localEntityId = -1;
              // const cookieData = cookieManager.readValue();
              // if (cookieData && cookieData.lastSelectedFrontDeskEntityId) {
                localEntityId = cookieManagerUtils.getLastSelectedFrontDeskEntityId();
                setSelectedFrontDeskEntityId(cookieManagerUtils.getLastSelectedFrontDeskEntityId());
              // }

              if(localEntityId < 0){

                setSelectedFrontDeskEntityId(response.data[0].entityId)
                setFrontDeskData(response.data[0]);
                cookieManagerUtils.setLastSelectedFrontDeskEntityId(response.data[0].entityId);

              }else{

                let foundItem = frontDeskArray.find(item => item.entityId === localEntityId);

                if (foundItem) {
                  setFrontDeskData(foundItem);
                } else {
                  console.log("Item not found - select first front dek");
                  setSelectedFrontDeskEntityId(response.data[0].entityId)
                  setFrontDeskData(response.data[0]);
                  cookieManagerUtils.setLastSelectedFrontDeskEntityId(response.data[0].entityId);
                }
              }

              //load terminal settings
              const frontDeskTerminalSettings = getFrontDeskTerminalSettings()(localEntityId).then((response) => {

                    if (response.success) {
        
                      setSelectedFrontDeskTerminalSettings(response.data)
        
                    }
        
                    setLoading(false);
        
        
                  })
                  .catch((error) => {
        
                    setLoading(false);

                });
            
            }

          })
          .catch((error) => {

            setLoading(false);
            setError(error);

        });
      
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  // Function to set selectedFrontDeskEntityId from outside
  const setSelectedFrontDeskId = (id) => {
    setSelectedFrontDeskEntityId(id);
    // cookieManager.saveValue({ lastSelectedFrontDeskEntityId: id });
    cookieManagerUtils.setLastSelectedFrontDeskEntityId(id);

  };

  const getLastSelectedFrontDesk = () => {
    // const cookieData = cookieManager.readValue();
    // return cookieData?.lastSelectedFrontDeskEntityId || -1;
    return cookieManagerUtils.getLastSelectedFrontDeskEntityId()
  };

  // Function to convert UTC time to local time of selected front desk
  const convertToFrontDeskTimeZone = (timeInMillis) => {
    if (selectedFrontDesk) {
      return dayjs.utc(timeInMillis).tz(selectedFrontDesk.timeZone);
    }
    // If selectedFrontDesk is not available, return null or handle the case as per your requirement
    return null;
  };

// Function to convert UTC time to local time of selected front desk
const convertToUTCDateString = (timeInMillis) => {
  if (selectedFrontDesk) {
    return dayjs.utc(timeInMillis).format(selectedFrontDesk.country.dayjsDateFormat);
  }else{
    return dayjs.utc(timeInMillis).format("MM/DD/YYYY");
  }
};

  // Function to convert UTC time to local time of selected front desk
  const convertToFrontDeskTimeZoneDateString = (timeInMillis) => {
    if (selectedFrontDesk) {
      return dayjs.utc(timeInMillis).tz(selectedFrontDesk.timeZone).format(selectedFrontDesk.country.dayjsDateFormat);
    }else{
      return dayjs.utc(timeInMillis).tz(selectedFrontDesk.timeZone).format("MM/DD/YYYY");
    }
  };

  // Function to convert UTC time to local time of selected front desk
  const convertToFrontDeskTimeZoneDateTimeString = (timeInMillis) => {
    if (selectedFrontDesk) {
      return dayjs.utc(timeInMillis).tz(selectedFrontDesk.timeZone).format(selectedFrontDesk.country.dayjsDateFormat+ " "+selectedFrontDesk.country.dayjsTimeFormat);
    }else{
      return dayjs.utc(timeInMillis).tz(selectedFrontDesk.timeZone).format("MM/DD/YYYY HH:mm A");
    }
  };

  const convertToTimeZoneDateTimeString = (timeInMillis, frontDesk) => {
    if (frontDesk) {
      return dayjs.utc(timeInMillis).tz(frontDesk.timeZone).format(frontDesk.country.dayjsDateFormat+ " "+frontDesk.country.dayjsTimeFormat);
    }else{
      return dayjs.utc(timeInMillis).tz(selectedFrontDesk.timeZone).format("MM/DD/YYYY HH:mm A");
    }
  };

  const getTodayBusinessHours = () => {

    if(selectedFrontDesk){
        if(selectedFrontDesk.businessHours == null){
            var businessHours = {};
                businessHours["m_h"] = 8;
                businessHours["m_m"] = 0;
                businessHours["e_h"] = 17;
                businessHours["e_m"] = 0;
            return businessHours;
        }else{
            var hours = JSON.parse(selectedFrontDesk.businessHours)
            var Days = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
            var currentDay = new Date();
            var day = Days[currentDay.getDay()];
            var morning = hours[day+"M"];
            var evening = hours[day+"E"];
            var businessHours = {};

                var [time, period] = morning.split(' ');

                // Split the time into hours and minutes
                var [hours, minutes] = time.split(':').map(Number);

                // Convert to 24-hour format
                if (period === 'PM' && hours !== 12) {
                    hours += 12; // Add 12 for PM, except for 12 PM which is noon
                } else if (period === 'AM' && hours === 12) {
                    hours = 0; // Convert 12 AM (midnight) to 0
                }

                businessHours["m_h"] = hours;
                businessHours["m_m"] = minutes

                
                var [time, period] = evening.split(' ');

                // Split the time into hours and minutes
                var [hours, minutes] = time.split(':').map(Number);

                // Convert to 24-hour format
                if (period === 'PM' && hours !== 12) {
                    hours += 12; // Add 12 for PM, except for 12 PM which is noon
                } else if (period === 'AM' && hours === 12) {
                    hours = 0; // Convert 12 AM (midnight) to 0
                }

                businessHours["e_h"] = hours;
                businessHours["e_m"] = minutes

            return businessHours;
        }
    }else{
        var businessHours = {};
          businessHours["m_h"] = 8;
          businessHours["m_m"] = 0;
          businessHours["e_h"] = 17;
          businessHours["e_m"] = 0;
        return businessHours;
    }
 };

 const fetchMeetingRoomData = async () => {
  try {

    if (selectedFrontDeskEntityId < 0) return;

      setMeetingRoomsLoading(true);
      // setError(null);
      const token = getSessionToken();

      if (token === '') {
        throw { success: false, action: 'relogin' };
      }

      getMeetingRooms()(selectedFrontDeskEntityId)
        .then((response) => {

          if (response.success) {
              setMeetingRooms(response.data)
          }
          setMeetingRoomsLoading(false);
        })
        .catch((error) => {

          // setLoading(false);
          // setError(error);
          setMeetingRoomsLoading(false);
        });
    
  } catch (error) {
    // setError(error);
    // setLoading(false);
    setMeetingRoomsLoading(false);

  }
};


  useEffect(() => {

    fetchFrontDeskData();
    if (selectedFrontDeskEntityId >= 0) {
      fetchMeetingRoomData();
    }

    // Cleanup function
    return () => {
      // Any cleanup code here if needed
    }
  }, [selectedFrontDeskEntityId]);

  useEffect(() => {

    fetchFrontDeskData();

    // Cleanup function
    return () => {
      // Any cleanup code here if needed
    }
  }, []);


  return (
    <FrontDeskManagerContext.Provider value={{  selectedFrontDesk,
                                                systemDateTimeFormat,
                                                selectedFrontDeskTerminalSettings, 
                                                frontDeskLoading: frontDeskLoading || meetingRoomsLoading, 
                                                allAvailableFrontDesks, 
                                                meetingRooms,
                                                frontDeskLoadingError,
                                                externalLocationTooltipTrigger,
                                                fetchFrontDeskData, 
                                                setSelectedFrontDeskEntityId: setSelectedFrontDeskId,
                                                convertToFrontDeskTimeZone: convertToFrontDeskTimeZone,
                                                convertToUTCDateString: convertToUTCDateString, 
                                                convertToFrontDeskTimeZoneDateString: convertToFrontDeskTimeZoneDateString,
                                                convertToFrontDeskTimeZoneDateTimeString: convertToFrontDeskTimeZoneDateTimeString,
                                                convertToTimeZoneDateTimeString: convertToTimeZoneDateTimeString,
                                                getTodayBusinessHours: getTodayBusinessHours,
                                                triggerLocationTooltip: triggerLocationTooltip,
                                                getLastSelectedFrontDesk

                                                }}>
      {children}
    </FrontDeskManagerContext.Provider>
  );
};
