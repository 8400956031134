import React, { useEffect, useState } from "react";
import {  Avatar, Box, Drawer, Link,  Typography,  useTheme } from "@mui/material";
import Header from "components/Header";
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import DialogYesNo from "components/dialog/DialogYesNo";
import {  deleteVisitor, deleteVisitorForce, getListValuesPaging, getSessionToken, getUri } from "../../hooks/HttpRequests";
import {  useNavigate } from "react-router-dom";
import tm from "components/TranslationManager";
import CompanyNew from "scenes/company/companyNew";
import {  stringAvatar } from "components/StyledComponents";
import GridCellActions from "components/GridCellActions";
import { enqueueSnackbar } from "notistack";
import { sadevioColors } from "components/Colors";
import { Power } from "@mui/icons-material";


const VisitorsDirectory = ({ route }) => {

  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [yesNoMessage, setYesNoMessage] = useState("");
  const [yesNoDialogEntity, setYesNoDialogEntity] = useState({});

  const [topSearchFilter, setTopSearchFilter] = useState("");

  const theme = useTheme();

  /////////////// GRID /////////////
  // values to be sent to the backend

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    visitorId: false,
  });

  const [search, setSearch] = useState("");

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [],
    query:[]
  })

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton color="neutral" />
        <GridToolbarFilterButton  style={{ color: '#3f51b5' }}  />
      </GridToolbarContainer>
    );
  }

    /////////////// USE EFFECT /////////////

    useEffect(() => {
      // This code will run every time paginationModel changes
      loadGridData();
  
    }, [paginationModel]);

    useEffect(() => {
      // This code will run every time paginationModel changes
      loadGridData("");

    }, [triggerRefresh]);
  

  const handleFilterModelChange = (filterModel) => {
    // Update the filter model state when it changes

    console.log("handleFilterModelChange()")

    // Convert to the desired structure
    const desiredStructure = filterModel.items.map(item => {
      let mappedOperator = item.operator;
    
      let field = columns.find(column => column.field === item.field);
      var fieldType = "string";

      if(typeof field.type !== 'undefined'){
        fieldType = field.type;
      }

      // Map operators as needed
      if (item.operator === 'equals') {
        mappedOperator = 'eq';
      } else if (item.operator === 'contains') {
        mappedOperator = 'like';
      } // Add more conditions as needed
    
      return {
        property: item.field,
        value: item.value,
        type: fieldType, // You might need to adjust this based on your data types
        operator: mappedOperator
      };
    });

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      query: desiredStructure,
      page: 0,
    }));

  };

  const handleSortModelChange = (sortModel) => {
    // Handle sorting changes and fetch data from the server
    //sort: [{"property":"lastname","direction":"ASC"}]

    console.log("handleSortModelChange()")

    const sortObject = sortModel.map(item => ({
      property: item.field,
      direction: item.sort.toUpperCase() // Assuming you want direction in uppercase (ASC or DESC)
    }));

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      sort: sortObject,
      page: 0,
    }));

      console.log(paginationModel)
  };

  const handlePageChange = (newPage) => {
    // Handle page changes and fetch data from the server

    console.log("handlePageChange()")

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPage.page,
      limit: newPage.pageSize,
    }));

  };

  const loadGridData = async () => {

    setGridLoading(true)

    var extraFilter = "";
    if(topSearchFilter.length > 0){
      extraFilter = "&extraFilter="+topSearchFilter
    }

    getListValuesPaging(navigate)(paginationModel, "visitor", extraFilter)
      .then((result) => {

        setData(result)
        setGridLoading(false)

      })
      .catch((error) => {
        setGridLoading(false)
      });

  }


  const handleExecute = async (values) => {


    setConfirmationOpen(false)

    dispatch(showLoading(""))

    if(values.action == "delete"){

      deleteVisitor(navigate)(values.visitorId)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Was deleted successfully",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

              setTriggerRefresh(!triggerRefresh);

            }else{



              enqueueSnackbar({
                message: "Something went wrong during request",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }else if(error.action == "force_delete"){

            setConfirmationOpen(true);
              setYesNoMessage("This entity has still referecnes to visits. Do you want to force the delete?")
              yesNoDialogEntity["action"] = "force_delete";
              setYesNoDialogEntity(yesNoDialogEntity)
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    }else if(values.action == "force_delete"){

      deleteVisitorForce(navigate)(values.visitorId)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Was deleted successfully",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

              setTriggerRefresh(!triggerRefresh);

            }else{



              enqueueSnackbar({
                message: "Something went wrong during request",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    }

      

  };

  const handleOptionCellActionClick = (id, action, row) => {
    if (action === 'edit') {
      // Handle edit action

      navigate(`/visitors/${row.visitorId}`)
    } else if (action === 'delete') {
      // Handle delete action

      setConfirmationOpen(true);
      setYesNoMessage("Do you really want to delete this entity?")
      row["action"] = "delete";
      setYesNoDialogEntity(row)
      
    }
  };

  const columns = [
    
    {
      field: "visitorId",
      headerName: "Visitor Id",
      flex: 1,
      type: 'number'
    },
    {
      field: 'picturePath',
      headerName: 'Picture',
      width: 100,
      renderCell: (params) => (
        params.row.picturePath != null ? (
          <Avatar {...stringAvatar(params.row.fullName)} alt={params.row.fullName} src={`${getUri(getSessionToken())}/visitor/${params.row.visitorId}/picture/${params.row.picturePath}`}          />

        ) : (
          <Avatar {...stringAvatar(params.row.fullName)} />
        )
      ),
    },
    {
      field: "firstname",
      headerName: "First Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/visitors/${params.row.visitorId}`)
          }}
        >
        {params.row.firstname}
      </Link>
      ),
    },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/visitors/${params.row.visitorId}`)
          }}
        >
        {params.row.lastname}
      </Link>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/visitors/${params.row.visitorId}`)
          }}
        >
        {params.row.email}
      </Link>
      ),
    },
    {
      field: "companyName",
      headerName: tm.translate('clmCompany', "Company") ,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "visitorType",
      headerName:  tm.translate('clmVisitorType', "Visitor Type") ,
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      filterable: false,
      width: 80,
      sortable: false,
      renderCell: (params) => (
        <GridCellActions
          id={params.row.visitorId}
          row={params.row}
          onOptionClick={handleOptionCellActionClick}
        />
      ),
    },
  ];

  const handleSearchInputChange = (e) => {

    // Update the state based on the previous state
    setTopSearchFilter(e.target.value);
    // // Perform other actions if needed
    loadGridData()

  };

  const btnNewVisit = tm.translate('btnNewVisitor', 'New Visitor');
  
  const buttons = [
    { type:'search',label: "Search visitor..." , onChange: handleSearchInputChange, iconName: '', searchElementId: 'searchVisitor'},
    { type:'button',label: btnNewVisit , onClick: () => { console.log("open"); 
              // openDrawer()
              navigate("/visitors/new")  }, iconName: '', btnId: 'btnNewVisitor'},

  ];


  return (
    <Box m="1rem 2rem">
      <Header title="Visitor Directory" subtitle="List of visitors" elements={buttons} />
      <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleExecute}
        theme={theme}
        message={yesNoMessage}
        entity={yesNoDialogEntity}
      />
      {/* </Box> */}
      <Box
        mt="0px"
        height="80vh"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },          
        }}
      >
        <DataGridPro
          loading={gridLoading || !data}
          initialState={{
            pagination: { paginationModel: { pageSize: paginationModel.limit } },
          }}
          // getRowId={(row) => row.entityId}
          getRowId={(row) => `${row.visitorId}`}
          rows={data?.data || []}
          columns={columns}
          rowCount={(data && data.total_count) || 0}
          rowsPerPageOptions={[25, 50, 100]}
          columnVisibilityModel={columnVisibilityModel}
          // onColumnVisibilityChange={handleColumnVisibilityChange}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }

          checkboxSelection

          filterMode="server"
          onFilterModelChange={handleFilterModelChange}

          sortingMode="server"
          onSortModelChange={handleSortModelChange}

          paginationMode="server"
          pagination
          page={paginationModel.page}
          pageSize={paginationModel.limit}
          onPaginationModelChange={(paginationModel) => handlePageChange(paginationModel)} //setPageSize(paginationModel.pageSize)

          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            filterPanel: {
              // Force usage of "And" operator
              logicOperators: [],
              // Display columns by ascending alphabetical order
              columnsSort: 'asc',
              
              sx: {
                '& .MuiButtonBase-root': { color: `${theme.palette.secondary[200]} !important` },
                '& .MuiFormLabel-root': { color: `${theme.palette.secondary[200]} !important` },
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default VisitorsDirectory;