import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControlLabel, ListItemIcon, Tooltip, Button, useTheme, Radio, Grid, Paper, useMediaQuery } from '@mui/material';
import { LockClock } from '@mui/icons-material';
import { putFrontDesk } from 'hooks/HttpRequests';
import { CssRadioGroup, CssTextField} from "components/StyledComponents";
import { GoogleMap,  Marker, useLoadScript } from "@react-google-maps/api";
import * as yup from "yup";
import { Formik } from 'formik';
import dayjs from 'dayjs';
import { useFrontDeskManager } from 'components/FrontDeskManager';
import Header from 'components/Header';
import LocationPrivacyConfig from './locationDataPrivacyConfig';


const LocationDetailGeneral = ({globalType, navigate, id }) => {

const theme = useTheme();
const {palette} = useTheme();
const isNonMobile = useMediaQuery("(min-width:600px)");
const defaultCategory = { label: "Airline", value: "airline" };

const { selectedFrontDesk, frontDeskLoading } = useFrontDeskManager();

const [loadingComplete, setLoadingComplete] = useState(false);
const [loadedFormData, setLoadedFormData] = useState(null);


// const validFromDayJS = dayjs().set("hour", 9).startOf("hour");
// const validToDayJS = dayjs().set("hour", 12).add(1, 'year');

const [validFromDayJS, setValidFromDayJS] = useState(dayjs().set("hour", 9).startOf("hour"));
const [validToDayJS, setValidToDayJS] = useState(dayjs().set("hour", 12).add(1, 'year'));

const [availableEmployes, setAvailableEmployes] = useState([]);
const [gridLoading, setGridLoading] = useState(false);

const [showReviewers, setShowReviewers] = useState(false);
const toggleReviewers = () => {
    setShowReviewers(!showReviewers);
};

const [showDataPrivacy, setShowDataPrivacy] = useState(false);
const toggleDataPrivacy = () => {
    setShowDataPrivacy(!showDataPrivacy);
};

///////// google maps

const [coordinates, setCoordinates] = useState({ lat: 38.8977, lng: -77.036560 });

const libraries = ["places"];

const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries

  });

/////////// google autocomplete


  useEffect(() => {    

    if (!frontDeskLoading) {
        setLoadedFormData(selectedFrontDesk);
        setLoadingComplete(true);

        setCoordinates({ lat: selectedFrontDesk.latitude, lng: selectedFrontDesk.longitude })
    }
    }, [frontDeskLoading, selectedFrontDesk]);

    if (frontDeskLoading || !loadingComplete) {
        return <div>Loading...</div>;
    }

const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const registerSchema = yup.object().shape({
    name: yup.string().required("required"),
    street: yup.string().required("required"),
    city: yup.string().required("required"),

  });

  const initialValuesRegister = {
    status: "active",
    name: "",
    category: "",
    active: false,
    street: "",
    city: "",
    zip: "",
    phone: "",
    badgeRenewalPeriod: "",
    category: null,
    minAuthSignatoryCount: 1,
    maxAuthSignatoryCount: 1,
    exemptChrc: false,
    exemptSta: false,

  };


  const handleSubmit = (values) => {
    // Perform your post request with the combined form values
    console.log(values);

    loadedFormData["building"] = values.building
    loadedFormData["street"] = values.street
    loadedFormData["city"] = values.city
    loadedFormData["zip"] = values.zip
    loadedFormData["phone"] = values.phone

    loadedFormData["businessHours"] = JSON.parse(loadedFormData.businessHours);

    var submitData= {}
    submitData["data"]= loadedFormData;

    putFrontDesk()(submitData)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {
      
            console.log(result)
            if(result.success){
                //   setCopmanyName("- "+result.data.name);
                setLoadedFormData(result.data)

                setCoordinates({ lat: result.data.latitude, lng: result.data.longitude })
            }

        }
      })
      .catch((error) => {
        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }
      });

  };

  return (

    <Box m="20px">
        <Header
            title="Locations Settings"
            subtitle="Local location settings"
            elements={null}
        />
        <Box sx={{ ...commonStyles, border: 1 }}>
            
        <Box>
            {/* Top Row */}
            <Box display="flex" alignItems="center" mb={1} ml={1} mt={3}>
                {/* <ListItemIcon>
                    <Circle fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
                </ListItemIcon> */}
                <Box ml={2}>
                <Typography variant="h3" fontWeight="bold" color={theme.palette.secondary[100]}>
                    General Information
                </Typography>
                {/* <Typography variant="h5" color={theme.palette.secondary[300]} >
                    Choose a graphic to appear on your welcome screen in the background.
                </Typography> */}
                </Box>
            </Box>

            {/* Bottom Rows */}
            <Grid container spacing={2} style={{ backgroundColor: 'transparent', padding: "2px 0px 2px 0px" }} >
                {/* Left Column */}
                
                    <Grid item xs={6} style={{ padding: 20, backgroundImage: 'transparent' }}>
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={loadedFormData || initialValuesRegister}
                        validationSchema={registerSchema}
                        enableReinitialize={true}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            resetForm,
                            }) => (
                            <form onSubmit={handleSubmit}>
                        <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)'  }}
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                              }}
                            >
                            <CssTextField
                                label="Building Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                // value={loadedFormData?.name || values.name}
                                value={values.building} // Use values from Formik
                                name="building"
                                error={ Boolean(touched.building) && Boolean(errors.building)}
                                helperText={touched.building && errors.building}
                                sx={{ mb: 2 , gridColumn: "span 4" }}
                                theme={theme} 
                                fullWidth
                            />
                            <CssTextField 
                                name="street"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.street} // Use values from Formik
                                label="Street" variant="outlined" fullWidth theme={theme} sx={{ mb: 2, gridColumn: "span 2" }} />
                            <CssTextField 
                                name="city"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.city} // Use values from Formik
                                label="City" variant="outlined" fullWidth theme={theme} sx={{ mb: 2, gridColumn: "span 2" }} />
                            <CssTextField 
                                name="zip" 
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.zip} // Use values from Formik
                                label="Zip" variant="outlined" fullWidth theme={theme} sx={{ mb: 2 , gridColumn: "span 2"}} />
                            <CssTextField 
                                name="phone" 
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.phone} // Use values from Formik
                                label="Phone" variant="outlined" fullWidth theme={theme} sx={{ mb: 2 }} />
                            <Button
                                fullWidth
                                type="submit"
                                sx={{
                                    m: "2rem 0",
                                    p: "1rem",
                                    backgroundColor: palette.secondary.light,
                                    color: palette.background.alt,
                                    fontWeight: "bold",
                                    "&:hover": {
                                        color: palette.primary.main,
                                        backgroundColor: palette.secondary.light,
                                        fontWeight: "bold",
                                    },
                                }}
                                >
                                {"Save"}
                                </Button>
                            {/* Add more text fields as needed */}
                        </Paper>
                            </form>
                        )}
                    </Formik>

                    </Grid>

                {/* Right Column */}
                <Grid item xs={6}>
                    <Paper elevation={3} style={{ padding: 20, backgroundColor: 'transparent', backgroundImage: 'none', }}>
                        {/* <Typography variant="h6" gutterBottom>
                        Right Column
                        </Typography> */}
                        {loadError ? (
                                <Typography>Error loading Google Maps</Typography>
                            ) : (
                            
                                    <Paper elevation={3} style={{ height: 400 }}>
                                    {isLoaded ? (
                                        <GoogleMap
                                        mapContainerStyle={{ height: '100%', width: '100%' }}
                                        center={coordinates}
                                        zoom={10}
                                        >
                                        <Marker position={coordinates} />
                                
                                        </GoogleMap>
                                    ) : (
                                        <Typography>Loading...</Typography>
                                    )}
                                    </Paper>
                            )}
                    </Paper>
                </Grid>
            </Grid>
            
        </Box>
        {/*     */}
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >

            {showReviewers && (

        <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem"}}>
            <Typography
                variant="h4"
                color={theme.palette.secondary[100]}
                fontWeight="bold"
                mt="3rem"
                >
                Authorised Reviewers
                </Typography>
            <Typography variant="h5" color={theme.palette.secondary[300]}>
                Select which person will be notified to review the visitor invitation.
            </Typography>
            <CssRadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    theme={theme} 
                >
                    <FormControlLabel value="only_one" control={<Radio />} label="Only one reviewer need to confirm" />
                    <FormControlLabel value="all" control={<Radio />} label="All selected reviewers need to confirm" />
            </CssRadioGroup>


            </Box>
            )}
            </Box>
            
            <Box
            display="flex"
            flexDirection="row"
            alignItems="right"
            width="100%"
            >
                {showReviewers && (
                    <Button
                        variant="contained"
                        onClick={toggleReviewers}
                        sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        m: "0rem 1rem 0rem 0rem",
                        "&:hover": { color: theme.palette.secondary.light },
                        }}
                    >
                        {"DISABLE"}
                    </Button>
                )}
            
            </Box>

        </Box>

        {/*  -------------------------------   */}

        <Box sx={{ ...commonStyles, border: 1 }}>
            
            <Box display="flex" alignItems="center">
                <ListItemIcon>
                    <LockClock fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
                </ListItemIcon>
                <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                    <Box display="flex" flexDirection="row" alignItems="center">
    
                        
                        <Typography
                        variant="h3"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        >
                        Business Hours
                        </Typography>
                        {globalType && (
                        <Tooltip title={
                            <Typography variant="body1" style={{ fontSize: '16px' }}>
                            Global Visitor Type
                            </Typography>
                        }>
                            
                        </Tooltip>
                        )}
                    </Box>
    
                    <Typography variant="h5" color={theme.palette.secondary[300]}>
                        Enter the time the building is open.
                    </Typography>
                    </Box>
                    
                    <Box ml="auto">    
       
                </Box>
            </Box>
            {/*     */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
    
              
                </Box>
                
                <Box
                display="flex"
                flexDirection="row"
                alignItems="right"
                width="100%"
                >
                    {showReviewers && (
                        <Button
                            variant="contained"
                            onClick={toggleReviewers}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                            {"DISABLE"}
                        </Button>
                    )}
                
                </Box>
    
            </Box>
            {/*  -------------------------------   */}   
            {/*  -------------------------------   */}

            <LocationPrivacyConfig />
            {/*  -------------------------------   */}      
            {/*  -------------------------------   */}

            
         

    </Box>
  );
};

export default LocationDetailGeneral;
