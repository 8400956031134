import React, { useEffect, useState } from "react";
import Header from "components/Header";
import {
  Box,
  Button,
  Typography,
  Checkbox,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getEmergencyListDetails, getEmergencyListFinish, getEmergencyListVisitorSafe, getSessionToken, getUri, postApplicationLocalInstall } from "hooks/HttpRequests";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "state/loadingSlicer";
import { enqueueSnackbar } from "notistack";
import { stringAvatar } from "components/StyledComponents";
import DialogYesNo from "components/dialog/DialogYesNo";

const EmergencyListDetail = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { selectedFrontDesk , convertToFrontDeskTimeZoneDateTimeString} = useFrontDeskManager();
  const [loading, setLoading] = useState(true);
  const [loadedFormData, setLoadedFormData] = useState(null);
  const [lastModified, setLastModified] = useState(null);

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [yesNoMessage, setYesNoMessage] = useState("");
    const [yesNoDialogEntity, setYesNoDialogEntity] = useState({})

  useEffect(() => {
    if (selectedFrontDesk) {
      loadData(id);
    }
  }, [selectedFrontDesk]);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshData();
    }, 2000);

    return () => clearInterval(interval);
  }, [lastModified]);

  const loadData = async (appId) => {
    setLoading(true);
    try {
      const result = await getEmergencyListDetails(navigate)(selectedFrontDesk.entityId, id);
      if (result.success) {
        setLoadedFormData(result.data);
        setLastModified(result.data.whenModified);
      }
    } catch (error) {
      console.error("Error loading emergency list details:", error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = async () => {
    try {
      const result = await getEmergencyListDetails(navigate)(selectedFrontDesk.entityId, id);
      if (result.success && result.data.whenModified !== lastModified) {
        setLoadedFormData(result.data);
        setLastModified(result.data.whenModified);
      }
    } catch (error) {
      console.error("Error refreshing emergency list details:", error);
    }
  };

  const confirmVisitor = async (visitor) => {
    dispatch(showLoading());
    try {
      const result = await getEmergencyListVisitorSafe(navigate)(selectedFrontDesk.entityId, id, visitor.visitorId, "safe");
      if (result.success) {

        enqueueSnackbar({
            message: "Visitor marked as safe.",
              variant: 'success',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        loadData(id);
      }
    } catch (error) {
      enqueueSnackbar({
        message: "Failed to mark visitor as safe.",
          variant: 'error',
          anchorOrigin: {
            vertical: 'top', // 'top', 'bottom'
            horizontal: 'center', // 'left', 'center', 'right'
          },
          autoHideDuration: 3000, 
          // persist: true
        
      });
    } finally {
      dispatch(hideLoading());
    }
  };

  const onFinishVisit = () => {
    console.log("delete entity")

    // Handle delete action
    setConfirmationOpen(true);
    setYesNoMessage("Do you really want to finish this emergency status?")
    setYesNoDialogEntity(loadedFormData)
               
  }

  const onFinishVisitExecute = (values) => {

    dispatch(showLoading(""));
    setConfirmationOpen(false);

    getEmergencyListFinish(navigate)(selectedFrontDesk.entityId,loadedFormData.entityId)
    .then((result) => {
    // Use the result.data as needed
    if (result.success) {
    
        if(result.success){
            enqueueSnackbar({
                message: "Emergency finished!",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
              });

        }else{
            
        }
        dispatch(hideLoading());

    }
    })
    .catch((error) => {
        dispatch(hideLoading());

    if (typeof error.action !== "undefined") {
        if (error.action == "relogin") {
        navigate("/");
        }
    }else if (error.hasOwnProperty('success')) {
        enqueueSnackbar({
        message: "Error "+error.message,
            variant: 'error',
            anchorOrigin: {
            vertical: 'top', // 'top', 'bottom'
            horizontal: 'center', // 'left', 'center', 'right'
            },
            autoHideDuration: 3000, 
            // persist: true
        
        });
    }

    });
  }

  if (loading || !loadedFormData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const { visitors, totalVisitors, totalConfirmed } = loadedFormData;

  return (
    <Box m="1rem 2rem" >
      <Header title="Emergency List Details" subtitle="Mark visitors as safe" backbutton/>
      <DialogYesNo
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            onConfirm={onFinishVisitExecute}
            theme={theme}
            message={yesNoMessage}
            entity={yesNoDialogEntity}
        />
        <Box mb={2}>
            <Typography variant="h5">General Details</Typography>
            <Typography mt={2}>Total Visitors: {totalVisitors}</Typography>
            <Typography>Total Confirmed Safe: {totalConfirmed}</Typography>
        </Box>
        { loadedFormData.status == "finished" && (
        <Box mb={2}>
            <Typography variant="h5">When started: {convertToFrontDeskTimeZoneDateTimeString(loadedFormData.whenCreated)}</Typography>
            <Typography variant="h5">When finished: {convertToFrontDeskTimeZoneDateTimeString(loadedFormData.whenFinished)}</Typography>
            <Typography variant="h5" mt={2}>Who Finished: {loadedFormData.whoFinished}</Typography>
        </Box>
        )}
        { loadedFormData.status != "finished" && (
            <Button
                variant="outlined"
                color="error"
                fullWidth
                onClick={onFinishVisit}
                sx={{
                // backgroundColor: theme.palette.secondary.light,
                // color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                mt:2,
                mb:2.
                // "&:hover": { color: theme.palette.secondary.dark },
                }}
                >
                Finish Emergency
        </Button>
        )}
        
      <Box>
        <Box
          display="grid"
          gridTemplateColumns={isNonMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)"}
          gap={2}
        >
          {visitors.map((visitorWrapper) => {
            const { entityId, visitor, status, confirmedBy } = visitorWrapper;
            const isSafe = status === "safe";

            return (
              <Box
                key={entityId}
                p={2}
                border={`1px solid ${theme.palette.grey[300]}`}
                borderRadius="8px"
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Avatar {...stringAvatar(visitor.fullName)} alt={visitor.fullName} src={`${getUri(getSessionToken())}/visitor/${visitor.visitorId}/picture/${visitor.picturePath}`}/>
                <Typography
                    sx={{
                      fontWeight: isSafe ? 'bold' : 'normal',
                      fontSize: 25,
                      color: isSafe ? theme.palette.success.main : undefined,
                    }}
                  >
                    <strong>Name:</strong> {visitor.fullName}</Typography>
                <Typography
                    sx={{
                      fontWeight:  'bold' ,
                      fontSize: 25,
                    }}
                  ><strong>Company:</strong> {visitor.companyName}</Typography>
                <Typography><strong>Visitor Type:</strong> {visitor.visitorType}</Typography>
                <Typography><strong>Email:</strong> {visitor.email || "N/A"}</Typography>
                <Typography><strong>Status:</strong> {isSafe ? "safe" : "pending"}</Typography>

                { loadedFormData.status != "finished" ? (

                <Box mt={1} display="flex" alignItems="center">
                  <Checkbox
                    checked={isSafe}
                    disabled={isSafe}
                    onChange={() => confirmVisitor(visitor)}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 42,
                      },
                      color: isSafe ? theme.palette.success.main : undefined,
                      '&.Mui-checked': {
                        color: 'green',
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: isSafe ? 'bold' : 'normal',
                      color: isSafe ? theme.palette.success.main : undefined,
                    }}
                  >
                    {isSafe ? "Marked Safe" : "Mark as Safe"}
                  </Typography>
                </Box>
                ) : (
                <Typography
                    sx={{
                      fontWeight: isSafe ? 'bold' : 'normal',
                      color: isSafe ? theme.palette.success.main : undefined,
                    }}
                  >
                    {isSafe ? "Marked as Safe by "+confirmedBy : "Was not Safe"}
                  </Typography>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default EmergencyListDetail;
