import React from 'react';
import { Paper, Grid, FormControlLabel, Button, useTheme, Typography } from '@mui/material';
import { Save, DeleteOutline } from '@mui/icons-material';
import { Formik, Form } from 'formik';
import { CssDatePicker, CssTextField } from "components/StyledComponents";
import IOSSwitch from 'components/IOSSwitch';
import * as yup from "yup";
import dayjs from 'dayjs';
import EmailManager from 'components/EmailManager';

const EmployeeForm = ({
  initialValues = {}, 
  onSubmit, 
  loadedFormData = {},  
  onDeleteClicked,
  isNonMobile,
  isAddMode = false
}) => {

  const theme = useTheme();

  // Merge loadedFormData with initialValues, using fallback defaults
  const formInitialValues = {
    firstname: loadedFormData.firstname || initialValues.firstname || "",
    lastname: loadedFormData.lastname || initialValues.lastname || "",
    birthday: loadedFormData.birthday ? dayjs.utc(loadedFormData.birthday) : null,
    email: loadedFormData.email || initialValues.email || "",
    department: loadedFormData.department || initialValues.department || "",
    companyName: loadedFormData.companyName || initialValues.companyName || "",
    poNumber: loadedFormData.poNumber || initialValues.poNumber || "",
    adUser: loadedFormData.adUser || initialValues.adUser || "",
    mobile: loadedFormData.mobile || initialValues.mobile || "",
    phone: loadedFormData.phone || initialValues.phone || "",
    active: loadedFormData.active !== undefined ? loadedFormData.active : initialValues.active || true,
    hideKiosk: loadedFormData.hideKiosk !== undefined ? loadedFormData.hideKiosk : initialValues.hideKiosk || false,
    remoteConfirmation: loadedFormData.remoteConfirmation !== undefined ? loadedFormData.remoteConfirmation : initialValues.remoteConfirmation || false,
    manager: loadedFormData.manager || initialValues.manager || "",

  };

  // Validation schema for required fields
  const registerSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    firstname: yup.string().required("First name is required"),
    lastname: yup.string().required("Last name is required"),
  });

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={registerSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
      }) => (
        <Form>
          <Paper
            elevation={0}
            style={{ padding: 20, backgroundColor: 'transparent', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}
            sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
          >
            {/* Active Switch */}
            <FormControlLabel
              sx={{ mb: 2, gridColumn: "span 4", backgroundColor: 'transparent' }}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={values.active}
                  onChange={(e) => setFieldValue("active", e.target.checked)}
                />
              }
              label={<span style={{ color: theme.palette.secondary.light }}>Active</span>}
            />

            {/* Email Field */}
            <CssTextField
              label="Email"
              name="email"
              required
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              variant="outlined"
              fullWidth
              theme={theme}
              sx={{ mb: 2 }}
            />

            {/* First and Last Name Fields */}
            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssTextField
                  label="First Name"
                  name="firstname"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstname}
                  error={Boolean(touched.firstname) && Boolean(errors.firstname)}
                  helperText={touched.firstname && errors.firstname}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                />
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  label="Last Name"
                  name="lastname"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastname}
                  error={Boolean(touched.lastname) && Boolean(errors.lastname)}
                  helperText={touched.lastname && errors.lastname}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssTextField
                  label="Staff ID"
                  name="poNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.poNumber}
                  error={Boolean(touched.poNumber) && Boolean(errors.poNumber)}
                  helperText={touched.poNumber && errors.poNumber}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                />
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  label="AD User"
                  name="adUser"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.adUser}
                  error={Boolean(touched.adUser) && Boolean(errors.adUser)}
                  helperText={touched.adUser && errors.adUser}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

            {/* Company and Department Fields */}
            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssTextField
                  label="Company"
                  name="companyName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyName}
                  error={Boolean(touched.companyName) && Boolean(errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                />
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  label="Department"
                  name="department"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.department}
                  error={Boolean(touched.department) && Boolean(errors.department)}
                  helperText={touched.department && errors.department}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

            

            {/* Mobile and Phone Fields */}
            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssTextField
                  label="Mobile"
                  name="mobile"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mobile}
                  error={Boolean(touched.mobile) && Boolean(errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                  theme={theme}
                  fullWidth
                  sx={{ pr: '1em' }}
                />
              </Grid>
              <Grid item xs={6}>
                <CssTextField
                  label="Phone"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  error={Boolean(touched.phone) && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                />
              </Grid>
            </Grid>

            {/* Mobile and Phone Fields */}
            <Grid container spacing={0} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <CssDatePicker
                  label="Date of Birth"
                  name="birthday"
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue("birthday", value ? value.valueOf() : null);
                  }}
                  value={values.birthday ? dayjs(values.birthday) : null}
                  fullWidth
                  theme={theme}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      error={Boolean(touched.birthday) && Boolean(errors.birthday)}
                      helperText={touched.birthday && errors.birthday}
                      fullWidth
                      theme={theme}
                    />
                  )}
                  sx={{ width: '95%' }} 
                />
              </Grid>
              <Grid item xs={6}>
                {/* <CssTextField
                  label="Manager"
                  name="manager"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.manager}
                  error={Boolean(touched.manager) && Boolean(errors.manager)}
                  helperText={touched.manager && errors.manager}
                  theme={theme}
                  fullWidth
                  sx={{ m: 0 }}
                /> */}
              </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mb: 2 }}>
               
              <Grid item xs={12}>
              <EmailManager
                  title={"Manager email addresses"}
                  value={values.manager}
                  onChange={(newValue) => setFieldValue('manager', newValue)}
                />
                {touched.manager && errors.manager && (
                  <Typography variant="caption" color="error">
                    {errors.manager}
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* Kiosk Visibility Switch */}
            <FormControlLabel
              sx={{ mb: 2, gridColumn: "span 4", backgroundColor: 'transparent' }}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={!values.hideKiosk}
                  onChange={(e) => setFieldValue("hideKiosk", !e.target.checked)}
                />
              }
              label={<span style={{ color: theme.palette.secondary.light }}>Show employee as a host on the visitor kiosk</span>}
            />

            <FormControlLabel
              sx={{ mb: 2, gridColumn: "span 4", backgroundColor: 'transparent' }}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={values.remoteConfirmation}
                  onChange={(e) => setFieldValue("remoteConfirmation", e.target.checked)}
                />
              }
              label={<span style={{ color: theme.palette.secondary.light }}>Allow employee to remote confirm visitors</span>}
            />

        

            {/* Save and Delete Buttons */}
            <Grid container spacing={1} sx={{ mt: 2 }}>
              <Grid item xs={9}>
                <Button
                  fullWidth
                  startIcon={<Save />}
                  type="submit" // Submits the form and triggers validation
                  sx={{
                    p: "1rem",
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontWeight: "bold",
                    "&:hover": {
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.secondary.light,
                      fontWeight: "bold",
                    },
                  }}
                >
                  Save
                </Button>
              </Grid>
              { !isAddMode && (
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    startIcon={<DeleteOutline />}
                    onClick={onDeleteClicked} // Trigger delete action
                    sx={{
                      p: "1rem",
                      backgroundColor: theme.palette.secondary.light,
                      color: theme.palette.background.alt,
                      fontWeight: "bold",
                      "&:hover": {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.secondary.light,
                        fontWeight: "bold",
                      },
                    }}
                  >
                    DELETE
                  </Button>
                </Grid>
              )}
              
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default EmployeeForm;
