import React, { useEffect, useState } from 'react';
import { Box, Typography,  ListItemIcon, Button, useTheme, Autocomplete, Grid, Paper, useMediaQuery, Avatar, Divider } from '@mui/material';
import { CameraAlt, LocationOn, Password, Badge, Close } from '@mui/icons-material';
import { FaPassport} from "react-icons/fa"
import {  deleteHost, deleteHostForce, getHost, getListValuesPaging, getSessionToken, getUri, postHost, putHost } from 'hooks/HttpRequests';
import {  CssTextField, stringAvatar, commonStyles} from "components/StyledComponents";
import * as yup from "yup";
import tm from 'components/TranslationManager';
import DialogTakePicture from 'components/dialog/DialogTakePicture';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { hideLoading, showLoading } from 'state/loadingSlicer';
import { useFrontDeskManager } from 'components/FrontDeskManager';
import EmployeeForm from './employeeForm';
import DialogYesNo from 'components/dialog/DialogYesNo';
import dayjs from "dayjs";


const EmployeeDetailGeneral = ({globalType, navigate, id, route }) => {

    // var isAuth = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();

    const theme = useTheme();
    const {palette} = useTheme();
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const {  frontDeskLoading, allAvailableFrontDesks } = useFrontDeskManager();

    const [takePictureOpen, setTakePictureOpen] = useState(false);

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [yesNoMessage, setYesNoMessage] = useState("");
    const [yesNoDialogEntity, setYesNoDialogEntity] = useState({})

    const [gridLoading, setGridLoading] = useState(false);

    const defaultUserRole = { userRoleId: 3, name: "Employee" };


    const [userRoleLoading, setUserRoleLoading] = useState(false);
    const [userRoleData, setUserRoleData] = useState([defaultUserRole]);

    // Authentication configuration 
    const [showReviewers, setShowReviewers] = useState(false);

    const toggleReviewers = () => {
        setShowReviewers(!showReviewers);
    };

    const defaultAuthType = { label: "Application", value: "app" };
    const [selectedAuthType, setSelectedAuthType] = useState("app"); // Default value

    const authTypes = [
        defaultAuthType,
        { label: 'Staff ID', value: "staff_id" },
        { label: 'LDAP', value: "ldap" },
        { label: 'Azure AD', value: "azure_ad" }
    ]

// Access card configuration 
const [showAccessCardConfig, setShowAccessCardConfig] = useState(false);

const toggleAccessCardConfig = () => {
    setShowAccessCardConfig(!showAccessCardConfig);
};

// Access card configuration 
const [showIdDocConfig, setShowIdDocConfig] = useState(false);

const toggleIdDocConfig = () => {
    setShowIdDocConfig(!showIdDocConfig);
};

// location information configuration 
const [showLocationInfoConfig, setShowLocationInfoConfig] = useState(false);

const toggleLocationInfoConfig = () => {
    setShowLocationInfoConfig(!showLocationInfoConfig);
};

const [isAddMode, setIsAddMode] = useState(false);

const [loadedFormData, setLoadedFormData] = useState({ entityId: null, 
            lastname: "", 
            firstname: "", 
            authType: "app",
            fullName: "",
            email: "", 
            department: "", 
            companyName: "", 
            poNumber: "", 
            phone: "",
            mobile: "",
            roomNumber: "",
            roomDirection: "",
            accessCardId: "",
            accessCardIdTmp: "",
            password: "", 
            manager: "",
            roleIds: defaultUserRole.userRoleId
            });



    useEffect(() => {

        loadUserRole();

        setIsAddMode(route === "new");
        if (route !== "new") {
            loadData(id);
        } else {
            //do nothing
        }

        if(route === "new"){
            toggleReviewers()
        }

    }, [id, route]);

  const loadData = async (id) => {

        getHost(navigate)(id)
        .then((result) => {

        if(result.success){

            if(typeof result.data.password === "undefined"){
                if(isAddMode){
                    if(result.data.entityId > 0){
                        if(result.data.authType == "app"){
                            result.data.password = "_placeholder_"
                        }
                    }else{
                        result.data.password = ""
                    }
                }else{
                    result.data.password = "_placeholder_"
                    
                }
            }else{
                if(result.data.entityId > 0){
                    if(result.data.authType == "app"){
                        result.data.password = "_placeholder_"
                    }
                }
            }

            setLoadedFormData(result.data)

        }

        })
        .catch((error) => {
            setGridLoading(false)
        });
  };

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 100,
    sort: [],
    query:[]
  })

  const loadUserRole = async () => {

    setUserRoleLoading(true)

    var extraFilter = "";

    getListValuesPaging(navigate)(paginationModel, "userrole", extraFilter)
      .then((result) => {

        if(result.success){
            // setUserRoleData(result.data)
            // Merge defaultUserRole if not already in result data
            const roles = result.data.some(role => role.userRoleId === defaultUserRole.userRoleId)
            ? result.data
            : [defaultUserRole, ...result.data];
    
            setUserRoleData(roles);
        }
        setUserRoleLoading(false)

      })
      .catch((error) => {

        setUserRoleLoading(false)
      });

    //   try {
    //     const result = await getListValuesPaging(navigate)(paginationModel, "userrole", "");
    //     if (result.success) {
    //       // Merge defaultUserRole if not already in result data
    //       const roles = result.data.some(role => role.userRoleId === defaultUserRole.userRoleId)
    //         ? result.data
    //         : [defaultUserRole, ...result.data];
    
    //       setUserRoleData(roles);
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   } finally {
    //     setUserRoleLoading(false);
    //   }

  }


  const registerSchema = yup.object().shape({
    lastname: yup.string().required("required"),
    firstname: yup.string().required("required"),

  });

  const initialValuesRegister = {
    lastname: "",
    firstname: "",
  };


  const handleSubmit = (values) => {

    loadedFormData["active"] = values.active

    loadedFormData["email"] = values.email

    loadedFormData["firstname"] = values.firstname
    loadedFormData["lastname"] = values.lastname

    if (dayjs.isDayjs(values.birthday)) {
        loadedFormData["birthday"] = values.birthday.valueOf()
    } else if (typeof values.birthday === "number") {
        loadedFormData["birthday"] = values.birthday
    } else {
        loadedFormData["birthday"] = values.birthday
    }

    loadedFormData["email"] = values.email
    loadedFormData["poNumber"] = values.poNumber
    loadedFormData["adUser"] = values.adUser


    loadedFormData["department"] = values.department
    loadedFormData["companyName"] = values.companyName
    loadedFormData["poNumber"] = values.poNumber
    loadedFormData["phone"] = values.phone
    loadedFormData["mobile"] = values.mobile
    
    loadedFormData["hideKiosk"] = values.hideKiosk
    loadedFormData["remoteConfirmation"] = values.remoteConfirmation

    loadedFormData["manager"] = values.manager



    var submitData= {}
    submitData["host"]= loadedFormData;

    var everythingOK = true;

    if(isAddMode){


        if(selectedAuthType == "app" && loadedFormData.password == ""){
            everythingOK = false
            enqueueSnackbar({
                message: "Password can't be empty",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
        }
    }else{
        // if update an password is empty have the placeholder since it was not changed
        if(selectedAuthType == "app" && loadedFormData.password == ""){
            
            loadedFormData.password = "_placeholder_";
        }

    }

    

    if(everythingOK){

    
        if(isAddMode){

            dispatch(showLoading(""));

            postHost(navigate)(submitData)
            .then((result) => {
                if (result.success) {
            
                    setIsAddMode(false);
                    navigate(`/employees/${result.data.entityId}`)
               
                }
            dispatch(hideLoading()); 
            })
            .catch((error) => {
            dispatch(hideLoading());
        
            if (typeof error.action !== "undefined") {
                if (error.action === "relogin") {
                navigate("/");
                }
            }else if (error.hasOwnProperty('success')) {
                enqueueSnackbar({
                message: "Error "+error.message,
                    variant: 'error',
                    anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                
                });
            }
        
            });

        }else{
            dispatch(showLoading(""));

            putHost(navigate)(submitData)
            .then((result) => {
            // Use the result.data as needed
            if (result.success) {
            
                if(result.success){
                    //   setCopmanyName("- "+result.data.name);
                    setLoadedFormData(result.data)

                    enqueueSnackbar({
                        message: "Entity was saved",
                          variant: 'success',
                          anchorOrigin: {
                            vertical: 'top', // 'top', 'bottom'
                            horizontal: 'center', // 'left', 'center', 'right'
                          },
                          autoHideDuration: 3000, 
                          // persist: true
                        
                      });

                }else{
                    enqueueSnackbar({
                        message: "Error "+result.message,
                            variant: 'error',
                            anchorOrigin: {
                            vertical: 'top', // 'top', 'bottom'
                            horizontal: 'center', // 'left', 'center', 'right'
                            },
                            autoHideDuration: 3000, 
                            // persist: true
                        
                        });
                }
                dispatch(hideLoading());

            }
            })
            .catch((error) => {
            dispatch(hideLoading());
    
            if (typeof error.action !== "undefined") {
                if (error.action === "relogin") {
                navigate("/");
                }
            }else if (error.hasOwnProperty('success')) {
                enqueueSnackbar({
                message: "Error "+error.message,
                    variant: 'error',
                    anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                
                });
            }
    
            });
        }
    }
    
  };

  const onDeleteClicked = () => {

    // Handle delete action
    setConfirmationOpen(true);
    setYesNoMessage("Do you really want to delete this entity?")
    loadedFormData["action"] = "delete";
    setYesNoDialogEntity(loadedFormData)
          
        
  }

  const handleExecute = async (values) => {


    setConfirmationOpen(false)

    dispatch(showLoading(""))

    if(values.action == "delete"){

      deleteHost(navigate)(values.entityId)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Was deleted successfully",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

            navigate("/employees");

            }else{

              enqueueSnackbar({
                message: "Something went wrong during request",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action === "relogin") {
            navigate("/");
          }else if(error.action == "force_delete"){
              console.log("force delete")
              setConfirmationOpen(true);
              setYesNoMessage("This entity has still referecnes to visits. Do you want to force the delete?")
              yesNoDialogEntity["action"] = "force_delete";
              setYesNoDialogEntity(yesNoDialogEntity)
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    }else if(values.action === "force_delete"){

      deleteHostForce(navigate)(values.entityId)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Was deleted successfully",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

              navigate("/employees");

            }else{

              enqueueSnackbar({
                message: "Something went wrong during request",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action === "relogin") {
            navigate("/");
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

    }
  };


  // Render loading state if loadedFormData is null
  if (!loadedFormData && userRoleLoading) {
    return <div>Loading...</div>;
  }

  return (

    <Box>
        <DialogYesNo
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            onConfirm={handleExecute}
            theme={theme}
            message={yesNoMessage}
            entity={yesNoDialogEntity}
        />
        <DialogTakePicture
            open={takePictureOpen}
            onClose={() => setTakePictureOpen(false)}
            onSave={(entity) => {   setTakePictureOpen(false);  
                                    setLoadedFormData({
                                        ...loadedFormData,
                                        picturePath: entity.data.picturePath
                                    });
                            }}
            theme={theme}
            currentEntity ={loadedFormData}
            entityTypeParam={"host"}
        />
        <Box sx={{ ...commonStyles(theme), border: 1 }}>
            
        <Box style={{ backgroundColor: 'transparent'}}>
            {/* Top Row */}
            <Box display="flex" alignItems="center" mb={1} ml={1} mt={3}>
                <Box ml={2}>
                <Typography variant="h3" fontWeight="bold" color={theme.palette.secondary[100]}>
                    General Information
                </Typography>
                </Box>
            </Box>

            {/* Bottom Rows */}
            <Grid container spacing={2} style={{ backgroundColor: 'transparent', padding: "2px 0px 2px 0px" }} >
                {/* Left Column */}
                
                <Grid item xs={6} style={{ padding: 20, backgroundImage: 'transparent' }}>
                    <EmployeeForm
                        initialValues={loadedFormData}
                        validationSchema={registerSchema}
                        onSubmit={handleSubmit}
                        loadedFormData={loadedFormData}
                        onDeleteClicked={onDeleteClicked}
                        theme={theme}
                        palette={palette}
                        isNonMobile={isNonMobile}
                        isAddMode={isAddMode}
                    />

                </Grid>

                {/* Right Column */}
                <Grid item xs={6} sx={{ backgroundColor: 'transparent', backgroundImage: 'none',}}>
                {!isAddMode && (
                    <Paper elevation={3} style={{ padding: 20, backgroundImage: 'none',   boxShadow: 'none',  backgroundColor: 'transparent'}}>
                                        <Button
                                            variant="outlined"
                                            disableTouchRipple
                                            onClick={() => setTakePictureOpen(true)}

                                            sx={{
                                                position: 'relative',
                                                border: 0,
                                                "&:hover": { 
                                                    border: 0, 
                                                    color: theme.palette.secondary.light, 
                                                    backgroundImage: 'none',  
                                                    boxShadow: 'none', 
                                                    cursor: 'pointer',
                                                    backgroundColor: 'transparent', // Change background color to transparent
                                                }
                                            }}
                                        >
                                            <Avatar
                                                {...stringAvatar(loadedFormData?.fullName || "Unknown User")}// Default to "Unknown User" if fullName is undefined
                                                sx={{ width: 250, height: 250, position: 'relative', zIndex: 0 }} // Ensure Avatar is positioned relatively
                                                src={
                                                    loadedFormData?.picturePath
                                                        ? `${getUri(getSessionToken())}/host/${loadedFormData.entityId}/picture/${loadedFormData.picturePath}`
                                                        : undefined // No src if picturePath is empty or null
                                                }
                                            />
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    background: 'rgba(0, 0, 0, 0)',
                                                    zIndex: 1,
                                                    clipPath: 'ellipse(47% 50% at 50% 50%)',
                                                    WebkitClipPath: 'ellipse(45% 48% at 50% 50%)',
                                                    backgroundImage: 'linear-gradient(to bottom, transparent 80%, rgba(0,0,0,0.5) 60%)',
                                                    // backgroundClip: 'padding-box', // This property ensures that the background is clipped to the padding box
                                                }}
                                            />
                                            
                                            <Box sx={{ position: 'absolute', bottom: 3, left: '50%', transform: 'translateX(-50%)', zIndex: 2 }}>
                                                <CameraAlt sx={{ fontSize: '30px', color: theme.palette.secondary.light }} />
                                            </Box>
                                        </Button>
                    </Paper>)}

                </Grid>
            </Grid>
            
        </Box>
        {/*     */}
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >

            </Box>
        </Box>

        {/*  -------------------------------   */}

        <Box sx={{ ...commonStyles(theme), border: 1 }}>
            
            <Box display="flex" alignItems="center">
                <ListItemIcon>
                    <Password fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
                </ListItemIcon>
                <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                    <Box display="flex" flexDirection="row" alignItems="center">
    
                        
                        <Typography
                        variant="h3"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        >
                        Authentication
                        </Typography>
                        
                    </Box>
    
                    <Typography variant="h5" color={theme.palette.secondary[300]}>
                        Set the authetnication type
                    </Typography>
                    </Box>
                    
                    <Box ml="auto">    
                    {!showReviewers && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between" // Adjusts spacing between items
                        >
                            
                            <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                                <Box display="flex" flexDirection="row" alignItems="center">
        
                                <Typography
                                variant="h5"
                                color={theme.palette.secondary[100]}
                                fontWeight="bold"
                                >
                                Authentication Type
                                </Typography>
                            </Box>

                            <Typography variant="h5" color={theme.palette.secondary[300]}>
                                {
                                    loadedFormData.authType && loadedFormData.authType !== ""
                                        ? authTypes.find((type) => type.value === loadedFormData.authType)?.label || "Not assigned"
                                        : "Not assigned"
                                }
                            </Typography>


                            </Box>
                            <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                                <Box display="flex" flexDirection="row" alignItems="center">
        
                                <Typography
                                variant="h5"
                                color={theme.palette.secondary[100]}
                                fontWeight="bold"
                                >
                                User Role
                                </Typography>
                            </Box>
            
                            <Typography variant="h5" color={theme.palette.secondary[300]}>
                                {
                                    loadedFormData.roleIds && loadedFormData.roleIds !== ""
                                        ? userRoleData.find((role) => String(role.userRoleId) === loadedFormData.roleIds)?.name || "Not assigned"
                                        : "Not assigned"
                                }
                            </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                onClick={toggleReviewers}
                                selenium_id={"btnEditAuthentication"}
                                sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                m: "0rem 1rem 0rem 0rem",
                                "&:hover": { color: theme.palette.secondary.dark },
                                }}
                            >
                                {"EDIT"}
                            </Button>
                        </Box>
                    )}
                    {/* {(!isAddMode && showReviewers && loadedFormData) && (
                        <Button
                                    variant="contained"
                                    onClick={saveAuthConfig}
                                    sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px",
                                    m: "0rem 1rem 0rem 0rem",
                                    "&:hover": { color: theme.palette.secondary.light },
                                    }}
                                >
                                {"SAVE"}
                            </Button>
                        )} */}
                    {showReviewers && (
                        <Button
                            variant="contained"
                            onClick={toggleReviewers}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.dark },
                            }}
                        >
                            <Close />
                        </Button>
                    )}
                    </Box>
            </Box>
            
            {/*     */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
    
              
            </Box>
                
                <Box
                display="flex"
                flexDirection="row"
                alignItems="right"
                width="100%"
                >
                    {showReviewers && (
                        <Box>
                        <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem"}}>
                        <Autocomplete
                            disablePortal
                            disableClearable
                            name="userRole"
                            autoSelect
                            options={userRoleData}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) => 
                                String(option.userRoleId) === String(value?.userRoleId) // Ensure safe comparison
                            }
                            onChange={(event, newValue) => {
                                setLoadedFormData((prevData) => ({
                                    ...prevData,
                                    roleIds: newValue ? String(newValue.userRoleId) : String(defaultUserRole.userRoleId) // Ensure it's stored as a string
                                }));
                            }}
                            onBlur={(event) => {
                                if (!loadedFormData.roleIds) {
                                    setLoadedFormData((prevData) => ({
                                        ...prevData,
                                        roleIds: String(defaultUserRole.userRoleId) // Ensure string type
                                    }));
                                }
                            }}
                            value={
                                userRoleData.find((role) => String(role.userRoleId) === String(loadedFormData.roleIds)) || defaultUserRole
                            }
                            renderInput={(params) => (
                                <CssTextField
                                    {...params}
                                    label={tm.translate('dspUserRole', 'User Role')}
                                    theme={theme}
                                />
                            )}
                            loading={userRoleLoading}
                        />

                    </Box>
                    <Divider sx={{ my: 2 }} /> {/* Adds a horizontal line with vertical spacing */}
                        <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem"}}>

                            <Autocomplete
                                disablePortal
                                disableClearable
                                name="authTypes"
                                options={authTypes}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) => option.value === value}
                                onChange={(event, newValue) => {
                                    setSelectedAuthType(newValue.value);
                                    setLoadedFormData((prevData) => ({
                                        ...prevData,
                                        authType: newValue.value,
                                    }));
                                }}
                                sx={{ 
                                    width: 250,
                                    mb: 2,
                                    gridColumn: "span 4",
                                    "& label.Mui-focused": {
                                        color: theme.palette.secondary[200],
                                    },
                                    "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
                                        color: theme.palette.secondary[300],
                                    }
                                }}
                                value={authTypes.find((authType) => authType.value === loadedFormData.authType) || defaultAuthType}
                                renderInput={(params) => (
                                    <CssTextField
                                        {...params}
                                        label={tm.translate('dspAuthType', 'Authentication Type')}
                                        theme={theme}
                                    />
                                )}
                            />


                            {selectedAuthType === "app" && (
                            <CssTextField
                                label="Password"
                                name="password"
                                type="password"
                                required
                                // onBlur={handleBlur}
                                onChange={(e) => {
                                    // Update the accessCardIdTmp in loadedFormData with the new value
                                    if (e.target) {
                                        setLoadedFormData({
                                            ...loadedFormData,
                                            password: e.target.value || "" 
                                        });
                                    }
                                }}
                                value={loadedFormData.password || ""} 
                                error={!loadedFormData.password && selectedAuthType === "app"} // Error if empty and authType is "app"
                                variant="outlined"
                                fullWidth
                                theme={theme}
                                sx={{ mb: 2 }}
                                />)}
                        </Box>
                        </Box>
                    )}
                
                </Box>
    
            </Box>
            {/*  -------------------------------   */}

            <Box sx={{ ...commonStyles(theme), border: 1 }}>
            
            <Box display="flex" alignItems="center">
                <ListItemIcon>
                    <Badge fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
                </ListItemIcon>
                <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                    <Box display="flex" flexDirection="row" alignItems="center">
    
                        
                        <Typography
                        variant="h3"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        >
                        Access Card
                        </Typography>
                        
                    </Box>
    
                    <Typography variant="h5" color={theme.palette.secondary[300]}>
                        The employees access card
                    </Typography>
                </Box>
                    
                <Box ml="auto">    
                    {!showAccessCardConfig && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between" // Adjusts spacing between items
                        >
                            <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                                <Box display="flex" flexDirection="row" alignItems="center">
        
                                <Typography
                                variant="h5"
                                color={theme.palette.secondary[100]}
                                fontWeight="bold"
                                >
                                Access Card ID
                                </Typography>
                            </Box>
            
                            <Typography variant="h5" color={theme.palette.secondary[300]}>
                                {loadedFormData.accessCardId || "Not assigned"}
                            </Typography>
                            </Box>
                            <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                                <Box display="flex" flexDirection="row" alignItems="center">
        
                                <Typography
                                variant="h5"
                                color={theme.palette.secondary[100]}
                                fontWeight="bold"
                                >
                                Access Card ID TMP
                                </Typography>
                            </Box>
            
                            <Typography variant="h5" color={theme.palette.secondary[300]}>
                                {loadedFormData.accessCardIdTmp || "Not assigned"}
                            </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                onClick={toggleAccessCardConfig}
                                sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                m: "0rem 1rem 0rem 0rem",
                                "&:hover": { color: theme.palette.secondary.dark },
                                }}
                            >
                                {"EDIT"}
                            </Button>
                        </Box>
                    )}
                    {/* {(!isAddMode && showAccessCardConfig && loadedFormData) && (
                        <Button
                                    variant="contained"
                                    onClick={saveAuthConfig}
                                    sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px",
                                    m: "0rem 1rem 0rem 0rem",
                                    "&:hover": { color: theme.palette.secondary.light },
                                    }}
                                >
                                {"SAVE"}
                            </Button>
                        )} */}
                    {showAccessCardConfig && (
                        <Button
                            variant="contained"
                            onClick={toggleAccessCardConfig}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.dark },
                            }}
                        >
                            <Close />
                        </Button>
                    )}
                    </Box>
            </Box>
            
            {/*     */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
    
              
            </Box>
                
            <Box
                display="flex"
                flexDirection="row"
                alignItems="right"
                width="100%"
                >
                    {showAccessCardConfig && (
                        <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem"}}>
                           <Grid container spacing={0} sx={{ mb: 2 }}>
                            <Grid item xs={6}>
                                <CssTextField
                                label="Access Card ID"
                                name="accessCardId"
                                // onBlur={handleBlur}
                                onChange={(e) => {
                                    // Update the accessCardIdTmp in loadedFormData with the new value
                                    setLoadedFormData({
                                        ...loadedFormData,
                                        accessCardId: e.target.value
                                    });
                                }}
                                value={loadedFormData.accessCardId || ""} 
                                // error={Boolean(touched.accessCardId) && Boolean(errors.accessCardId)}
                                // helperText={touched.accessCardId && errors.accessCardId}
                                theme={theme}
                                fullWidth
                                sx={{ pr: '1em' }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CssTextField
                                label="Access Card ID Temp"
                                name="accessCardIdTmp"
                                // onBlur={handleBlur}
                                onChange={(e) => {
                                    // Update the accessCardIdTmp in loadedFormData with the new value
                                    setLoadedFormData({
                                        ...loadedFormData,
                                        accessCardIdTmp: e.target.value
                                    });
                                }}
                                value={loadedFormData.accessCardIdTmp || ""} 
                                // error={Boolean(touched.accessCardIdTmp) && Boolean(errors.accessCardIdTmp)}
                                // helperText={touched.accessCardIdTmp && errors.accessCardIdTmp}
                                theme={theme}
                                fullWidth
                                sx={{ m: 0 }}
                                />
                            </Grid>
                            </Grid>
                        </Box>

                    )}
                
                </Box>
    
            </Box>
            {/*  -------------------------------   */}

            {/*  -------------------------------   */}

            <Box sx={{ ...commonStyles(theme), border: 1 }}>
            
            <Box display="flex" alignItems="center">
                <ListItemIcon>
                    <LocationOn fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
                </ListItemIcon>
                <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography
                        variant="h3"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        >
                        Location information
                        </Typography>
                        
                    </Box>
    
                    <Typography variant="h5" color={theme.palette.secondary[300]}>
                        The employees location information
                    </Typography>
                    </Box>
                    
                    <Box ml="auto">    
                    {!showLocationInfoConfig && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between" // Adjusts spacing between items
                        >
                            <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                                <Box display="flex" flexDirection="row" alignItems="center">
        
                                <Typography
                                variant="h5"
                                color={theme.palette.secondary[100]}
                                fontWeight="bold"
                                >
                                Location
                                </Typography>
                            </Box>

            
                            <Typography variant="h5" color={theme.palette.secondary[300]}>
                            {
                                loadedFormData.frontDeskEntityId && loadedFormData.frontDeskEntityId !== 0
                                    ? allAvailableFrontDesks.find((desk) => desk.entityId === loadedFormData.frontDeskEntityId)?.name || "Not assigned"
                                    : "Not assigned"
                            }
                            </Typography>
                            </Box>

                            <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                                <Box display="flex" flexDirection="row" alignItems="center">
        
                                <Typography
                                variant="h5"
                                color={theme.palette.secondary[100]}
                                fontWeight="bold"
                                >
                                Building
                                </Typography>
                            </Box>
            
                            <Typography variant="h5" color={theme.palette.secondary[300]}>
                                {loadedFormData.building || "Not assigned"}
                            </Typography>
                            </Box>

                            <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                                <Box display="flex" flexDirection="row" alignItems="center">
        
                                <Typography
                                variant="h5"
                                color={theme.palette.secondary[100]}
                                fontWeight="bold"
                                >
                                Room
                                </Typography>
                            </Box>
            
                            <Typography variant="h5" color={theme.palette.secondary[300]}>
                                {loadedFormData.roomNumber || "Not assigned"}
                            </Typography>
                            </Box>
                            <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                                <Box display="flex" flexDirection="row" alignItems="center">
        
                                <Typography
                                variant="h5"
                                color={theme.palette.secondary[100]}
                                fontWeight="bold"
                                >
                                Room Direction
                                </Typography>
                            </Box>
            
                            <Typography variant="h5" color={theme.palette.secondary[300]}>
                                {loadedFormData.roomDirection || "Not assigned"}
                            </Typography>
                            </Box>

                            <Button
                                variant="contained"
                                onClick={toggleLocationInfoConfig}
                                sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                m: "0rem 1rem 0rem 0rem",
                                "&:hover": { color: theme.palette.secondary.dark },
                                }}
                            >
                                {"EDIT"}
                            </Button>
                        </Box>
                    )}
                    {/* {(!isAddMode && showAccessCardConfig && loadedFormData) && (
                        <Button
                                    variant="contained"
                                    onClick={saveAuthConfig}
                                    sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px",
                                    m: "0rem 1rem 0rem 0rem",
                                    "&:hover": { color: theme.palette.secondary.light },
                                    }}
                                >
                                {"SAVE"}
                            </Button>
                        )} */}
                    {showLocationInfoConfig && (
                        <Button
                            variant="contained"
                            onClick={toggleLocationInfoConfig}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.dark },
                            }}
                        >
                            <Close />
                        </Button>
                    )}
                    </Box>
            </Box>
            
            {/*     */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
    
              
            </Box>
                
                <Box
                display="flex"
                flexDirection="row"
                alignItems="right"
                width="100%"
                >
                    {showLocationInfoConfig && (
                        <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem"}}>
                            
                            <Grid container spacing={0} sx={{ mb: 2 }}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={allAvailableFrontDesks} // Array of available front desks
                                        getOptionLabel={(option) => option.name || ""} // Display the name as the label
                                        isOptionEqualToValue={(option, value) => option.entityId === value.entityId} // Ensure the correct selection
                                        onChange={(event, newValue) => {
                                            setLoadedFormData({
                                                ...loadedFormData,
                                                frontDeskEntityId: newValue ? newValue.entityId : null // Save to frontDeskEntityId
                                            });
                                        }}
                                        value={allAvailableFrontDesks.find((desk) => desk.entityId === loadedFormData.frontDeskEntityId) || null} // Set the selected value based on frontDeskEntityId
                                        renderInput={(params) => (
                                            <CssTextField
                                            {...params}
                                            label={tm.translate('dspFrontDesk', 'Location')}
                                            theme={theme}
                                            />
                                        )}
                                        loading={frontDeskLoading} // Display loading state if front desks are still loading
                                    />
                                </Grid>
                                
                            </Grid>
                           <Grid container spacing={0} sx={{ mb: 2 }}>
                                <Grid item xs={6}>
                                    <CssTextField
                                    label="Building"
                                    name="building"
                                    // onBlur={handleBlur}
                                    onChange={(e) => {
                                        // Update the accessCardIdTmp in loadedFormData with the new value
                                        if (e.target) {
                                            setLoadedFormData({
                                                ...loadedFormData,
                                                building: e.target.value || "" 
                                            });
                                        }
                                    }}
                                    value={loadedFormData.building || ""} 
                                    // error={Boolean(touched.building) && Boolean(errors.building)}
                                    // helperText={touched.building && errors.building}
                                    theme={theme}
                                    fullWidth
                                    sx={{ pr: '1em' }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <CssTextField
                                    label="Phone"
                                    name="phone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phone}
                                    error={Boolean(touched.phone) && Boolean(errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                    theme={theme}
                                    fullWidth
                                    sx={{ m: 0 }}
                                    /> */}
                                </Grid>
                            </Grid>

                            {/* Room NUmber and Room Directions Fields */}
                            <Grid container spacing={0} sx={{ mb: 2 }}>
                            <Grid item xs={6}>
                                <CssTextField
                                label="Room Number"
                                name="roomNumber"
                                // onBlur={handleBlur}
                                onChange={(e) => {
                                    // Update the accessCardIdTmp in loadedFormData with the new value
                                    if (e.target) {
                                        setLoadedFormData({
                                            ...loadedFormData,
                                            roomNumber: e.target.value || "" 
                                        });
                                    }
                                }}
                                value={loadedFormData.roomNumber || ""} 
                                // error={Boolean(touched.roomNumber) && Boolean(errors.roomNumber)}
                                // helperText={touched.roomNumber && errors.roomNumber}
                                theme={theme}
                                fullWidth
                                sx={{ pr: '1em' }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CssTextField
                                label="Room Direction"
                                name="roomDirection"
                                // onBlur={handleBlur}
                                onChange={(e) => {
                                    // Update the accessCardIdTmp in loadedFormData with the new value
                                    if (e.target) {
                                        setLoadedFormData({
                                            ...loadedFormData,
                                            roomDirection: e.target.value || "" 
                                        });
                                    }
                                }}
                                value={loadedFormData.roomDirection || ""} 
                                // value={values.roomDirection}
                                // error={Boolean(touched.roomDirection) && Boolean(errors.roomDirection)}
                                // helperText={touched.roomDirection && errors.roomDirection}
                                theme={theme}
                                fullWidth
                                sx={{ m: 0 }}
                                />
                            </Grid>
                            </Grid>
                        </Box>
                    )}
                
                </Box>
    
            </Box>

            {/*  -------------------------------   */}

            <Box sx={{ ...commonStyles(theme), border: 1 }}>
            
            <Box display="flex" alignItems="center">
            <ListItemIcon>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: "1.5rem 0 1.5rem 1.5rem" }}>
                    <FaPassport style={{ fontSize: "1.5rem" }} />
                </Box>
            </ListItemIcon>

                <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                    <Box display="flex" flexDirection="row" alignItems="center">
    
                        
                        <Typography
                        variant="h3"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        >
                        ID Document
                        </Typography>
                        
                    </Box>
    
                    <Typography variant="h5" color={theme.palette.secondary[300]}>
                        The employees identification document
                    </Typography>
                    </Box>
                    
                    <Box ml="auto">    
                    {!showIdDocConfig && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between" // Adjusts spacing between items
                        >
                            <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                                <Box display="flex" flexDirection="row" alignItems="center">
        
                                <Typography
                                variant="h5"
                                color={theme.palette.secondary[100]}
                                fontWeight="bold"
                                >
                                ID Document
                                </Typography>
                            </Box>
            
                            <Typography variant="h5" color={theme.palette.secondary[300]}>
                                {loadedFormData.idDocument || "Not assigned"}
                            </Typography>
                            </Box>
                           
                            <Button
                                variant="contained"
                                onClick={toggleIdDocConfig}
                                sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                m: "0rem 1rem 0rem 0rem",
                                "&:hover": { color: theme.palette.secondary.dark },
                                }}
                            >
                                {"EDIT"}
                            </Button>
                        </Box>
                    )}
                    {showIdDocConfig && (
                        <Button
                            variant="contained"
                            onClick={toggleIdDocConfig}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.dark },
                            }}
                        >
                            <Close />
                        </Button>
                    )}
                    </Box>
            </Box>
            
            {/*     */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
    
              
            </Box>
                
                <Box
                display="flex"
                flexDirection="row"
                alignItems="right"
                width="100%"
                >
                    {showIdDocConfig && (
                        <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem"}}>
                           <Grid container spacing={0} sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <CssTextField
                                label="ID Document"
                                name="idDocument"
                                // onBlur={handleBlur}
                                onChange={(e) => {
                                    // Update the accessCardIdTmp in loadedFormData with the new value
                                    setLoadedFormData({
                                        ...loadedFormData,
                                        idDocument: e.target.value
                                    });
                                }}
                                value={loadedFormData.idDocument || ""} 
                                // value={values.accessCardId}
                                // error={Boolean(touched.accessCardId) && Boolean(errors.accessCardId)}
                                // helperText={touched.accessCardId && errors.accessCardId}
                                theme={theme}
                                fullWidth
                                sx={{ pr: '1em' }}
                                />
                            </Grid>
                        
                            </Grid>
                        </Box>

                    )}
                
                </Box>
    
            </Box>

            {/*  -------------------------------   */}

            

    </Box>
  );
};

export default EmployeeDetailGeneral;
