import { Delete, MoreVert } from "@mui/icons-material";
import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useState } from "react";


const ActionColumn = ({ record, handleActionClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleAction = (action) => {
      handleActionClick(action, record);
      handleClose();
    };
  
    return (
      <>
        <IconButton size="small" onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={() => handleAction('edit')}>Edit</MenuItem> */}
          <MenuItem onClick={() => handleAction('delete')}>
            <ListItemIcon>
                    <Delete /> {/* Icon component */}
                </ListItemIcon>
                Delete</MenuItem>
        </Menu>
      </>
    );
  };

  export default ActionColumn