import React, { useState, useEffect } from 'react';
import { MenuItem } from '@mui/material';

const MenuItemsAddWorkflowElement = ({ handleAddWorkflowElement }) => {

  const [itemsToShow, setItemsToShow] = useState([]); // Track selected step


    useEffect(() => {
      
      var menuWorkflowTypes = [];
									
			menuWorkflowTypes.push({
			    	            	text: 'Question Yes/No', 
			    	            	engine_type: 'question_yes_no',
			    	            	handler: 'onMenuItemSelected'
			    	            })
	
			menuWorkflowTypes.push({
			    	            	text: 'Visitor Picture',
			    	            	engine_type: 'take_picture',
			    	            	handler: 'onMenuItemSelected'
			    	            });
			
			
			menuWorkflowTypes.push({
			    	            	text: 'Document to Sign',
			    	            	engine_type: 'sign_document',
			    	            	handler: 'onMenuItemSelected'
			    	            });
			
			menuWorkflowTypes.push({
                        text: 'General Information', 
                        engine_type: 'general_info',
                        handler: 'onMenuItemSelected'
                      });
			
			
      menuWorkflowTypes.push({
                      text: 'YouTube Video',
                      engine_type: 'youtube_video',
                      handler: 'onMenuItemSelected'
                  });
			

      menuWorkflowTypes.push({
                      text: 'Workflow Video',
                      engine_type: 'workflow_video',
                      handler: 'onMenuItemSelected'
                  });
            
			
			menuWorkflowTypes.push({
			    	            	text: 'Question Multiple', 
			    	            	engine_type: 'question_multiple',
			    	            	handler: 'onMenuItemSelected'
			    	            });
			
			
			menuWorkflowTypes.push({
			    	            	text: 'Driver License US', 
			    	            	engine_type: 'driver_license_us',
			    	            	handler: 'onMenuItemSelected'
			    	            });
			
			
			menuWorkflowTypes.push({
			    	            	text: 'Meeting Room',
                          iconId: "question", 
			    	            	engine_type: 'meeting_room',
			    	            });
			
			
      
      setItemsToShow(menuWorkflowTypes);

  }, []);

  return (
    <div>
      {itemsToShow.length === 0 ? (
        <div style={{ padding: '8px', textAlign: 'center', color: 'gray' }}>
          No more elements available
        </div>
      ) : (
        itemsToShow.map((step, index) => (
          <MenuItem
            key={index}
            onClick={() => handleAddWorkflowElement(step)}
            disableRipple
          >
            {step.text}
          </MenuItem>
        ))
      )}
    </div>
  );
};

export default MenuItemsAddWorkflowElement;
