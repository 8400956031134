import React, { useEffect, useState } from 'react';
import { Box, Typography,  ListItemIcon, Tooltip, Button, useTheme} from '@mui/material';
import {  Close, Preview } from '@mui/icons-material';
import Header from 'components/Header';
import SecurityDeniedPersonConfig from './security/SecurityDeniedPersonConfig';
import { commonStyles } from 'components/StyledComponents';



const VisitorSecurity = ({globalType, navigate, id }) => {

const theme = useTheme();

const [gridLoading, setGridLoading] = useState(false);

//////////////////////
useEffect(() => {
    
  loadFormData();

}, []); // Empty dependency array to fetch options only once on component mount


const loadFormData = async () => {

  setGridLoading(true)

  setGridLoading(false)

}

//////////////////////
const [showReviewers, setShowReviewers] = useState(false);

const toggleReviewers = () => {
    setShowReviewers(!showReviewers);
};

// BACKGROUND COLOR
const [showBackGroundColorConf, setShowBackGroundColorConf] = useState(false);

const toggleBackGroundColorConf = () => {
    setShowBackGroundColorConf(!showBackGroundColorConf);
};

const [displayBackgroundColorPicker, setDisplayBackgroundColorPicker] = useState(false);
const [backgroundColor, setBackgroundColor] = useState("#ffffff");

  const handleBackgroundColorClick = () => {
    setDisplayBackgroundColorPicker(!displayBackgroundColorPicker)
  };

  const  handleBackgroundColorClose = () => {
    setDisplayBackgroundColorPicker(false)
  };

  const handleBackgroundColorChange = (color, event) => {
    setBackgroundColor(color.hex)
  };


  // Silent Alarm
    const [showSilentAlarmConf, setShowSilentAlarmConf] = useState(false);

    const toggleSilentAlarmConf = () => {
        setShowSilentAlarmConf(!showSilentAlarmConf);
    };




  return (
    <Box m="1.5rem 2.5rem">
      <Header title="SECURITY" subtitle="Add extra security to your sign-in by screening visitors and connecting the tools that keep your workplace safe." buttons={null} />

        {/*  -------------------------------   */}

        <Box sx={{ ...commonStyles, border: 1 }}>
            
            <Box display="flex" alignItems="center">
                <ListItemIcon>
                    <Preview fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
                </ListItemIcon>
                <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem"}}>
                    <Box display="flex" flexDirection="row" alignItems="center">
    
                        
                        <Typography
                        variant="h3"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        >
                        Watch list
                        </Typography>
                        {globalType && (
                        <Tooltip title={
                            <Typography variant="body1" style={{ fontSize: '16px' }}>
                            Global Visitor Type
                            </Typography>
                        }>
                            
                        </Tooltip>
                        )}
                    </Box>
    
                    <Typography variant="h5" color={theme.palette.secondary[300]}>
                        Create a watch list to notify your team when certain people—like VIPs—sign-in.
                    </Typography>
                    </Box>
                    
                    <Box ml="auto">    
                    {!showBackGroundColorConf && (
                        <Button
                            variant="contained"
                            onClick={toggleBackGroundColorConf}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                            {"EDIT"}
                        </Button>
                    )}
                
                {showBackGroundColorConf && (
                <Button
                            variant="contained"
                            onClick={() => navigate(id+"/general")} 
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                        {"SAVE"}
                    </Button>
                )}
    
                    {showBackGroundColorConf && (
                    <Button
                            variant="contained"
                            onClick={toggleBackGroundColorConf}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                            <Close />
                        </Button>
                    )}
                </Box>
            </Box>
            {/*     */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
    
                {showBackGroundColorConf && (
    
                    <Box sx={{ m: "0.5rem 0.5rem 0.5rem 1rem"}}>
                        <Box position="relative" sx={{ ml: 10, mb: 3 }}>
                            
                            Coming soon
                    
                        </Box>
                    </Box>
                )}
                </Box>
                
                <Box
                display="flex"
                flexDirection="row"
                alignItems="right"
                width="100%"
                >
                    {showReviewers && (
                        <Button
                            variant="contained"
                            onClick={toggleReviewers}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                            m: "0rem 1rem 0rem 0rem",
                            "&:hover": { color: theme.palette.secondary.light },
                            }}
                        >
                            {"DISABLE"}
                        </Button>
                    )}
                
                </Box>
    
            </Box>
            {/*  -------------------------------   */}

            

            {/*  -------------------------------   */}

            <SecurityDeniedPersonConfig />
        
            {/*  -------------------------------   */}

    </Box>
  );
};

export default VisitorSecurity;
