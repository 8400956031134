import {
  
  useTheme,
  useMediaQuery,
 
} from "@mui/material";
import BadgeHandling from "components/badge/BadgeHandling";

const EmployeeBadging = ({globalType, navigate, id }) => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  
  return (
      <BadgeHandling globalType={globalType} navigate={navigate} id={id} entityType={"host"} />
  );
};

export default EmployeeBadging;