import { getConfigData, getSessionFromTmpToken, getSessionToken } from 'hooks/HttpRequests';
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setLogin } from 'state/auth';
import { useFrontDeskManager } from './FrontDeskManager';

const UserProfleManagerContext = createContext();

let externalSetAuthenticated;

export const useUserProfileManager = () => useContext(UserProfleManagerContext);

export const UserProfileManagerProvider = ({ children }) => {

  const location = useLocation(); // To get the current location and query parameters
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isAuth, setAuthenticated] = useState(true);

  const [userProfile, setUserProfile] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const [userProfleLoading, setLoading] = useState(true);
  const [authenticatedError, setError] = useState(null);

  externalSetAuthenticated = setAuthenticated;

  const { fetchFrontDeskData, getLastSelectedFrontDesk } = useFrontDeskManager();


  const fetchUserProfilekData = async () => {
    try {

      setLoading(true)
  
      var token = getSessionToken();

      if(typeof token !== "undefined" && token != null && token != ""){

        getConfigData()()
        .then((result) => {
  
          if(result.success){
            setUserProfile(result.data);
            setAuthenticated(true);
            setPermissions(result.data.session.user.userRole.permission);
          }else{
            // checkTmpToken()
            setAuthenticated(false);
            setUserProfile(null);
          }

          setLoading(false);
        })
        .catch((error) => {
  
          console.log(error);
          setAuthenticated(false);
          setUserProfile(null);
          setLoading(false);
        });

        
      }else{
        checkTmpToken()
        // setAuthenticated(false);
        // setUserProfile(null);
      }
      
            
    } catch (error) {
      setError(error);
      setLoading(false);
      setUserProfile(null);
    }finally {
      // setLoading(false);
    }
  };


  // Use a local variable to prevent multiple executions within the same function call
  let isAlreadyRunning = false

  const checkTmpToken = () =>{

    const queryParams = new URLSearchParams(location.search);
    const tmpToken = queryParams.get("tmp_token");

    if (tmpToken) {

      if(!isAlreadyRunning){
        isAlreadyRunning = true;
        console.log(tmpToken+"-----------CHECK TMP TOKEN-------------------");
        getSessionFromTmpToken()(tmpToken)
          .then((result) => {


            if(result.success){

              var user = {};

              localStorage.setItem('token', result.session_id);
              setAuthenticatedOutside(true);

              dispatch(
                setLogin({
                  user: user,
                  token: result.session_id,
                })
              );
  
              setLoading(false);
              fetchUserProfilekData();
                fetchFrontDeskData(); // Call this function whenever you want to trigger a reload of data
            }
           
            removeTmpToken();
            isAlreadyRunning = false;

          })
          .catch((error) => {

              console.log(error);
              setLoading(false);
              setAuthenticated(false);
              setUserProfile(null);
              removeTmpToken();
              isAlreadyRunning = false;

        });
      }
    }else{
      console.info("not token")
      setLoading(false);
      setAuthenticated(false);
      setUserProfile(null);
    }

    

  }

  const removeTmpToken = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if(queryParams.get('tmp_token') != null){
      queryParams.delete('tmp_token');
    
      const newUrl =
        window.location.pathname + (queryParams.toString() ? `?${queryParams.toString()}` : '');
    
      // Update the URL without reloading the page
      window.history.replaceState(null, '', newUrl);
    }
  };

  useEffect(() => {

    fetchUserProfilekData();

    // Cleanup function
    return () => {
      // Any cleanup code here if needed
    }
  }, []);

  useEffect(() => {

    if(typeof userProfile  === "undefined" || userProfile == null){
      fetchUserProfilekData();
    }

    // Cleanup function
    return () => {
      // Any cleanup code here if needed
    }
  }, [isAuth]);
  

  const hasPermission = ( permissionName ) => {

    for( const permission of permissions){
      if( permission.name === permissionName){
        return true;
      }
    }

		return false;
  }


  return (
    <UserProfleManagerContext.Provider value={{isAuth, setAuthenticated, authenticatedError, userProfile, userProfleLoading, fetchUserProfilekData, hasPermission}}>
      {children}
    </UserProfleManagerContext.Provider>
  );
};

// Export the external function
export const setAuthenticatedOutside = (value) => {
  if (externalSetAuthenticated) {
    externalSetAuthenticated(value);
  } else {
    console.warn('setAuthenticated is not initialized yet');
  }
};
