import React, { useEffect, useState } from 'react';
import { Box, Typography, ListItemIcon, Tooltip, Button, useTheme, Link, Card, CardContent, Chip } from '@mui/material';
import { Gavel } from '@mui/icons-material';
import { getLicense } from 'hooks/HttpRequests';
import { useUserProfileManager } from 'components/UserProfileProvider';

const License = ({ globalType, navigate, id }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [loadedFormData, setLoadedFormData] = useState(null);
  const { isAuth, userProfile, userProfleLoading } = useUserProfileManager();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);

    getLicense(navigate)()
      .then((result) => {
        if (result.success) {
          setLoadedFormData(result.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  if (loading || !loadedFormData) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ padding: 3 }}>
      {/* Card Layout for Better Presentation */}
      <Card sx={{ ...commonStyles, border: 1, boxShadow: 3 }}>
        <CardContent>
          <Box display="flex" alignItems="center">
            <ListItemIcon>
              <Gavel fontSize="large" sx={{ m: "1rem" }} />
            </ListItemIcon>
            <Box>
              <Typography variant="h4" color={theme.palette.secondary[100]} fontWeight="bold">
                License Information
              </Typography>
            </Box>
          </Box>

          {/* Location Count */}
          <Box mt={2}>
            <Typography variant="body1" color="textSecondary">
              <strong>Location Count: </strong>  {loadedFormData.locationCount === 0 ? "Unlimited" : loadedFormData.locationCount}
            </Typography>
          </Box>

          {/* Valid To Date */}
          <Box mt={2}>
            <Typography variant="body1" color="textSecondary">
              <strong>Valid To: </strong>{loadedFormData.validTo}
            </Typography>
          </Box>

          {/* Server Keys Section */}
          {userProfile.saas === false && (
            <Box mt={2}>
              <Typography variant="body1" color="textSecondary">
                <strong>Server Keys: </strong>
                {loadedFormData.serverKeys.split(';').map((key, index) => (
                  <Chip key={index} label={key} variant="outlined" color="secondary" sx={{ margin: "0.2rem" }} />
                ))}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default License;
