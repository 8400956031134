import {   AccessTime, Apartment, AttachFile, Badge, Brush, CameraAlt, DoneAll, Email, FlightLand, FlightTakeoff, HelpOutline, Message, OndemandVideo, PersonAdd, PlayCircleOutline, QrCode, RadioButtonUnchecked, Warning, Webhook } from "@mui/icons-material";
import { Box, Paper,Typography,useTheme } from "@mui/material";
import React from 'react'
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import dayjs from "dayjs";
import { useFrontDeskManager } from "./FrontDeskManager";


const TimeLineItemMessage = ({ index, entity, isLastElement }) => {

  const theme = useTheme();

  const { convertToFrontDeskTimeZoneDateTimeString } = useFrontDeskManager();


  const localDateTime = convertToFrontDeskTimeZoneDateTimeString(entity.whenCreated)
  // const localDateTime = dayjs.utc(utcDateTime).tz('America/Los_Angeles').format('DD.MM.YYYY HH:mm');

  const description = entity.content.replace(/##/g, '')

  console.log(entity)

  return (
    <TimelineItem key={entity.entityId}>
      <TimelineOppositeContent sx={{ maxWidth: 150, alignItems: "center"}}>
        <Typography variant="body2" color="textSecondary">
          {localDateTime}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot sx={{ backgroundColor: theme.palette.secondary.light }}>
          <Message sx={{ color: theme.palette.primary.main }} />
        </TimelineDot>
        {isLastElement ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} sx={{ backgroundColor: theme.palette.background.alt, padding: '6px 16px' }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
          <Box>
            <Typography variant="h6">
              {description}
            </Typography>
            
            </Box>  
            <Typography variant="h5" sx={{ fontWeight: "bold", color: theme.palette.secondary.main }}>
              {entity.whoCreated}            
              </Typography>      
          </Box>
          
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

export default TimeLineItemMessage

