import React, { useEffect, useState } from "react";
import {  Box, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Timeline } from "@mui/lab";
import { getListValuesPaging } from "hooks/HttpRequests";
import TimeLineItemVisitHistory from "components/TimeLineItemVisitHistory";
import { useFrontDeskManager } from "components/FrontDeskManager";


const VisitorTimeline= ({ globalType, navigate, id }) => {

  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const theme = useTheme();

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [isLoading, setGridLoading] = useState(false);

  useEffect(() => {
    // This code will run every time paginationModel changes
    loadGridData();

  }, []);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [],
    query:[]
  })

  const loadGridData = async () => {

    console.log("loadGridData()")
    setGridLoading(true)

    var extraFilter = "";

    getListValuesPaging(navigate)(paginationModel, "visitor/"+id+"/timeline", extraFilter)
      .then((result) => {

        setData(result.data)
        setGridLoading(false)

      })
      .catch((error) => {
        console.log(error);
        setGridLoading(false)
      });

  }

  if (!data || isLoading) return "Loading...";

  return (
      <Box m="0rem 2rem">
        {/* <Header title="History" subtitle="" elements={buttons} /> */}
        {/* </Box> */}
        <Box
          mt="0px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
          }}
        >
    <Timeline align="alternate">
        {data && data.length > 0 ? (
              data.map((value, index) => (
                <TimeLineItemVisitHistory 
                    key={index} 
                    entity={value} 
                    isLastElement={index === data.length - 1} //is neeeded for the last element to dont add another connector line
                />
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No records available
              </Typography>
            )}

      </Timeline>
        </Box>
      </Box>
    );
  };

export default VisitorTimeline;