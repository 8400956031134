// withErrorHandling.js

import { handleRequestError } from './HttpRequests'; // Adjust the path as needed

export const withErrorHandling = (apiFunction, navigate) => {
    return (...args) => {
      return new Promise((resolve, reject) => {
        apiFunction(...args)
          .then(resolve)
          .catch((error) => {
            handleRequestError(error, reject, navigate);
          });
      });
    };
  };