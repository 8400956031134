import React, { useEffect, useState } from "react";
import {  Box, Typography, useTheme } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Header from "components/Header";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import ProfileDetailGeneral from "./profileDetails";
import UserProfileNotificatins from "./profileNotifications";


function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant="h3" >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const UserProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { id } = useParams(); // Accessing the id parameter from the URL

  useEffect(() => {
    
    // getHost(navigate)(id)
    // .then((result) => {


    //   if(result.success){

    //   }
    //   // setData(result)
    //   // setGridLoading(false)

    // })
    // .catch((error) => {

    //   console.log(error);

    // });

  }, [id]);

  

  const tabs = [
    { label: 'General', path: '/general', id: "tabGeneral" },
    // { label: 'Notifications', path: '/notifications' , id: "tabNotifications"}

  ];

  const currentPath = location.pathname;
  const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
  const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;

  const [value, setValue] = useState(selectedTabIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTab = tabs[newValue];
    navigate(`/profile${selectedTab.path}`);
  };

  useEffect(() => {
    const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
    const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;
    setValue(selectedTabIndex);
  }, [currentPath, tabs]);

  return (
    <Box m="1rem 2rem">
      <Header title={`My Profile`} subtitle="" elements={null} />
      {/* <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        theme={theme}
      /> */}
      <Box mt="10px" height="85vh" sx={{
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiDataGrid-panel .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
         "& .MuiDataGrid-panelWrapper .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiTab-root.MuiTab-textColorPrimary.Mui-selected": {
            color: `${theme.palette.secondary[200]} !important`,
            fontWeight: 'bold'
          },
          
        }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.id === 'tabGeneral' && (
              <ProfileDetailGeneral
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                // theme={theme}
              />
            )}
            {tab.id === 'tabNotifications' && (
              // <Typography>This is the Security tab content</Typography>
              <UserProfileNotificatins
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={3}
                // theme={theme}
              />
            )}
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default UserProfile;

