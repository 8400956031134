import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import { sadevioColors } from './Colors';

const GridCellLocationRegion = ({ rowData, regionValues }) => {
  
  const [columnValue, setColumnValue] = useState(rowData.region || ''); // Assuming 'age' is a property in rowData

  const [isReadOnly, setIsReadOnly] = useState(false); // Control read-only state

  const handleChange = (event) => {
    setColumnValue(event.target.value);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "checked_in":
        return { color: sadevioColors.hexColorCheckedIn, fontWeight: 'bold' };
      case "checked_out":
        return { color: sadevioColors.hexColorCheckedOut, fontWeight: 'bold' };
      case "pre_registered":
        return { color: sadevioColors.hexColorInvited , fontWeight: 'bold'};
      case "pre_checked_in":
        return { color: sadevioColors.hexColorWaiting , fontWeight: 'bold'};
      case "confirmed":
        return { color: sadevioColors.hexColorConfirmed , fontWeight: 'bold'};
      default:
        return { color: 'white' };
    }
  };

  const selectStyles = getStatusColor(columnValue);



  return (
    <FormControl sx={{ width: '100%', margin: '1px', height: '38px' }}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        readOnly= {isReadOnly}
        IconComponent={isReadOnly ? () => null : undefined} // Conditionally remove the icon
        value={columnValue}
        onChange={handleChange}
        style={selectStyles}
        sx={{ width: '100%', height: '100%', padding: '8px' }} // Adjust height here
      >

        {regionValues && regionValues.length > 0 ? (
            regionValues.map((value, index) => (
              <MenuItem value={value.region} >{value.region}</MenuItem>
            ))
          ) : (
            <MenuItem value={"global"} >{"global"}</MenuItem>
          )}
      </Select>
    </FormControl>
  );
};

export default GridCellLocationRegion;
