import {   Add} from "@mui/icons-material";
import { Autocomplete, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItemAvatar,  ListItemText, Paper, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from 'react'
import { useState } from "react";
import { autocompleteHost, getSessionToken, getUri } from "hooks/HttpRequests";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CssTextField, stringAvatar } from "components/StyledComponents";
import tm from 'components/TranslationManager';


const DialogHostLookup = ({ open, onClose, onSave, entityPointerId, entityKeyname, entityTable  }) => {

  const { palette } = useTheme();
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  ////////////////////////////////
  
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedHosts, setSelectedHosts] = useState([]);

  useEffect(() => {
    



  }, [open]);

  const handleReset = () => {

    setSearchResults([]);
    setSearchQuery("");

  }

  const handleClose = () => {

      handleReset()
      onClose();
  }

    // Function to handle adding an item to selectedItems
    const handleAddItem = (item) => {
      setSelectedItems([...selectedItems, item]);
      // You may also want to update the server/database with the added item
    };

    const handleSelectOption = (newValue) => {
      if (newValue != null) {
        var tmp = selectedHosts;
        tmp.push(newValue);
        setSelectedHosts(tmp);
        setSearchQuery("");
        
        handleReset()
        onSave(newValue);

      } else {
        setSearchQuery("");
      }
    };

      // Function to handle search query change
  const handleSearchQueryChange = (event, newValue) => {

    if(typeof newValue !== 'undefined' && newValue != null){

      autocompleteHost(navigate)(newValue)
      .then((result) => {
        // Use the result.data as needed
        if (result.success) {

          if(result.total_count > 0){
            setSearchResults(result.data);
          }else{
            setSearchResults([]);
          }
        }
      })
      .catch((error) => {
        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }
      });

    }else{
      setSearchQuery(""); // Clear searchQuery when newValue is undefined
      setSearchResults([]); // Clear searchResults as well
    }

    
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>Lookup Employee</DialogTitle>
        <DialogContent>

        <Box 
              sx={{ 
                    m: "0rem 0rem 0rem 0rem",
                    borderRadius: 1,
                    borderColor: theme.palette.secondary[100],
                    border: 0 , p: 5,
                    backgroundColor:theme.palette.background.alt,
             }}>  
            
            <Autocomplete
                  freeSolo
                  options={!searchResults ? [{label:"Loading...", value:0}] : searchResults }
                  value={searchQuery || null}
                  isOptionEqualToValue={(option, value) => {
                      if (value === null || value === undefined) {
                          // Handle null or undefined values
                          return option.value === null || option.value === undefined;
                      }
                      return option.value === value.value;
                  }}
                  onChange={(event, selectedOption) =>
                    handleSelectOption(selectedOption)
                  }
                  renderInput={(params) => (
                    <CssTextField {...params} label={tm.translate("lblSearchHost", "Search employee...") }theme={theme}/>
                  )}
                  getOptionLabel={(option) =>
                    option.fullName + " " + option.email
                  }
                  onInputChange={handleSearchQueryChange}
                  sx={{ gridColumn: "span 4" }}
                  renderOption={(props, option) => (
                    <Box
                      {...props}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <ListItemAvatar>
                        <Avatar
                        {...stringAvatar(option.fullName)} 
                        sx={{ width: 40, height: 40, position: 'relative', zIndex: 0 }} // Ensure Avatar is positioned relatively
                        src={option.fullName && option.entityId && option.picturePath ? `${getUri(getSessionToken())}host/${option.entityId}/picture/${option.picturePath}` : undefined}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${option.fullName} (${option.email})`}
                      />
                      <IconButton
                        onClick={() => handleAddItem(option)}
                        edge="end"
                        aria-label="add"
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  )}
                  clearOnBlur={true}
                />
            </Box>

          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            {/* Are you sure you want to execute this action? */}
        
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}> 
          {/* {/* <Button onClick={onClearImage} color="primary">remove photo</Button> */}

          <div>
            
            <Button onClick={handleClose} color="primary" sx={{ color: theme.palette.background.alt }}>
              {tm.translate( "btnCancel", 'Cancel')}
            </Button>
          </div>  
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogHostLookup