import React, { useEffect, useState } from "react";
import {  Box,  useTheme } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Header from "components/Header";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import WelcomeScreenDesign from "scenes/visitors/settings/welcomescreen/welcomescreenDesign";
import {  getVisitor } from "hooks/HttpRequests";
import VisitorDetailGeneral from "./details/visitorDetailGeneral";
import VisitorDocuments from "./details/visitorDocuments";
import VisitorIDDocuments from "./details/visitorIDDocuments";
import VisitorHistory from "./details/visitorHistory";
import VisitorTimeline from "./details/visitorTimeline";
import ConfigData from "components/ConfigData";
import ApplicationMessages from "components/ApplicationMessages";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const VisitorDetail = ({ route }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [copmanyName, setCopmanyName] = useState("");

  const [gridLoading, setGridLoading] = useState(false);
  const { id } = useParams(); // Accessing the id parameter from the URL

  useEffect(() => {
    
    if (route !== "new") {

      setGridLoading(true)

      getVisitor(navigate)(id)
      .then((result) => {
  
        console.log(result)
        if(result.success){
            setCopmanyName("- "+result.data.fullName);
        }
        // setData(result)
        setGridLoading(false)
  
      })
      .catch((error) => {
    
        setGridLoading(false)
      });
    } else {
        //do nothing
    }

  }, [id]);


  const tabs = [
    { label: 'General', path: '/general', id: "tabGeneral" },
    ...(route === "update" ? [
      // { label: 'Badging', path: '/badging' , id: "tabBadging"},
      { label: 'Timeline', path: '/timeline', id: "tabTimeline" },
      { label: 'Visit test History', path: '/history', id: "tabVisitorHistory" },
      { label: 'Documents', path: '/documents', id: "tabDocuments" },
      { label: 'ID Documents', path: '/iddocuments', id: "tabIdDocuments" },
      { label: 'Notes', path: '/messages', id: "tabMessages" },

    ] : []),
  ];

  const currentPath = location.pathname;
  const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
  const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;

  const [value, setValue] = useState(selectedTabIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTab = tabs[newValue];
    navigate(`/visitors/${id}${selectedTab.path}`);
  };

  useEffect(() => {
    const selectedTab = tabs.find(tab => currentPath.includes(tab.path));
    const selectedTabIndex = selectedTab ? tabs.indexOf(selectedTab) : 0;
    setValue(selectedTabIndex);
  }, [currentPath, tabs]);

  return (
    <Box m="1rem 2rem">
      <Header title={`Visitor Details ${copmanyName}`} subtitle="" elements={null} backbutton={"/visit/visitors"} />

      <Box height="90vh" sx={{
          "& .MuiTab-root.MuiTab-textColorPrimary.Mui-selected": {
            color: `${theme.palette.secondary[200]} !important`,
            fontWeight: 'bold'
          },
          
        }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.id === 'tabGeneral' && (
              <VisitorDetailGeneral
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                // theme={theme}
              />
            )}
            {tab.id === 'tabBadging' && (
              // <Typography>This is the Security tab content</Typography>
              <WelcomeScreenDesign
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                // theme={theme}
              />
            )}
            {tab.id === 'tabTimeline' && (
              // <Typography>This is the Security tab content</Typography>
              <VisitorTimeline
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                // theme={theme}
              />
            )}
            {tab.id === 'tabDocuments' && (
              // <Typography>This is the Security tab content</Typography>
              <VisitorDocuments
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                keyname = {ConfigData.attachmentKeynames.ATTACHMENT_KEYNAME_VISITOR_ATTACHMENT}
                table = {ConfigData.attachmentTables.ATTACHMENT_TABLE_VISITOR}
                // theme={theme}
              />
            )}
            {tab.id === 'tabIdDocuments' && (
              // <Typography>This is the Security tab content</Typography>
              <VisitorIDDocuments
                visitorType="Visitor Type"
                description="ID Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                // theme={theme}
              />
            )}
            {tab.id === 'tabVisitorHistory' && (
              // <Typography>This is the Security tab content</Typography>
              <VisitorHistory
                visitorType="Visitor test Type"
                description="Visitor history"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                // theme={theme}
              />
            )}
            {tab.id === 'tabMessages' && (
              // <Typography>This is the Security tab content</Typography>
              <ApplicationMessages
                visitorType="Visitor Type"
                description="Description"
                globalType={true}
                navigate={navigate}
                id={id}
                route={route}
                entityKeyname = {ConfigData.attachmentKeynames.ATTACHMENT_KEYNAME_VISITOR_NOTE}
                entityTable = {ConfigData.attachmentTables.ATTACHMENT_TABLE_VISITOR}
                // theme={theme}
              />
            )}



          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default VisitorDetail;

