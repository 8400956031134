import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHostLocationFilter, putHostLocationFilter } from "hooks/HttpRequests";
import { useFrontDeskManager } from "components/FrontDeskManager";

const EmployeeFrontDeskFilter = ({ id }) => {
  const { palette } = useTheme();
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { allAvailableFrontDesks } = useFrontDeskManager();
  
  const [data, setData] = useState([]); // Assigned locations
  const [gridLoading, setGridLoading] = useState(true);

  useEffect(() => {
    loadGridData();
  }, []);

  const loadGridData = async () => {
    setGridLoading(true);
    try {
      const result = await getHostLocationFilter(navigate)(id);
      if (result.success) {
        setData(result.data || []);
      }
    } catch (error) {
      console.error("Error loading assigned locations:", error);
    } finally {
      setGridLoading(false);
    }
  };

  const updateFilterData = async (updatedData) => {
    var entityIds = updatedData.map((loc) => loc.entityId).join(","); // Convert to "1,9" format

    var submitData = {};
    submitData["data"] = entityIds;


    try {
      const result = await putHostLocationFilter(navigate)(id, submitData);
      if (result.success) {
        setData(updatedData); // Update UI state on success
      }
    } catch (error) {
      console.error("Error updating filter:", error);
      if (error.action === "relogin") {
        navigate("/");
      }
    }
  };

  // Filter available locations (remove assigned ones)
  const availableLocations = allAvailableFrontDesks.filter(
    (location) => !data.some((assigned) => assigned.entityId === location.entityId)
  );

  return (
    <Box m="1rem">
      {/* Info Label */}
      <Typography variant="body1" sx={{ mb: 2, color: "red", fontWeight: 'bold'}}>
        If no front desk is assigned, the host can see all locations.
        If you add a particular front desk, the host can only see those particular locations.
      </Typography>

    <Box display="flex" gap={2} >
      {/* LEFT: Available Locations */}
      <Box flex={1} sx={{ overflowY: "auto", height: "80vh", backgroundColor: palette.background.alt }}>
        <Typography variant="h6" mb={2}>Available Locations</Typography>
        {gridLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress size={50} />
          </Box>
        ) : availableLocations.length === 0 ? (
          <Typography variant="body1" color="textSecondary">No available locations.</Typography>
        ) : (
          availableLocations.map((location) => (
            <Card key={location.entityId} sx={{ mb: 2, p: 2, backgroundColor: theme.palette.background.alt }}>
              <Typography variant="h6">{location.fullName}</Typography>
              <Typography variant="body2" color="textSecondary"><strong>Address:</strong> {location.fullAddressLineBreak}</Typography>
              <Typography variant="body2" color="textSecondary"><strong>Time Zone:</strong> {location.timeZone}</Typography>
              <Button
                fullWidth
                sx={{ mt: 1, backgroundColor: palette.secondary.light, color: palette.background.alt }}
                onClick={() => updateFilterData([...data, location])}
              >
                Add to Filter
              </Button>
            </Card>
          ))
        )}
      </Box>

      {/* RIGHT: Assigned Filters */}
      <Box flex={1} sx={{ overflowY: "auto", height: "80vh", backgroundColor: palette.background.alt }}>
        <Typography variant="h6" mb={2}>Assigned Locations</Typography>
        {data.length === 0 ? (
          <Typography variant="body1" color="textSecondary">No locations assigned.</Typography>
        ) : (
          data.map((location) => (
            <Card key={location.entityId} sx={{ mb: 2, p: 2, backgroundColor: palette.background.alt }}>
              <Typography variant="h6">{location.fullName}</Typography>
              <Typography variant="body2" color="textSecondary"><strong>Address:</strong> {location.fullAddressLineBreak}</Typography>
              <Typography variant="body2" color="textSecondary"><strong>Time Zone:</strong> {location.timeZone}</Typography>
              <Button
                fullWidth
                sx={{ mt: 1, backgroundColor: palette.error.light, color: palette.background.alt }}
                onClick={() => updateFilterData(data.filter((item) => item.entityId !== location.entityId))}
              >
                Remove from Filter
              </Button>
            </Card>
          ))
        )}
      </Box>
    </Box>
    </Box>
  );
};

export default EmployeeFrontDeskFilter;
