import React, { useEffect, useState } from "react";
import { Autocomplete, Avatar, Box, Checkbox, CircularProgress, Drawer, FormControlLabel, IconButton, InputAdornment, Link, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import Header from "components/Header";
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from "react-redux";
import DialogYesNo from "components/dialog/DialogYesNo";
import { cancelVisit, getCurrentVisitorStats, getLastVisitorChanges, getSessionToken, getUri, getVisitDashboardAll } from "../../hooks/HttpRequests";
import {  useNavigate } from "react-router-dom";
import { ArrowCircleDown, ArrowCircleUp, Badge, CalendarToday, FindInPage, HourglassEmpty, ThumbUpAlt, Upcoming } from "@mui/icons-material";
import GridCellVisitorStatus from "components/GridCellVisitorStatus";
import tm from "components/TranslationManager";
import { sadevioColors } from "components/Colors";
import dayjs from "dayjs";
import VisitNew from "./visitNew";
import { stringAvatar } from "components/StyledComponents";
import cookieManager from "components/CookieManager";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useUserProfileManager } from "components/UserProfileProvider";


const VisitorsVisits = ({ route }) => {
  
  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedFrontDesk, selectedFrontDeskTerminalSettings, convertToFrontDeskTimeZone, convertToFrontDeskTimeZoneDateTimeString } = useFrontDeskManager();
  const { hasPermission } = useUserProfileManager();

  const [initReady, setInitReady] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);


  const [confirmationOpen, setConfirmationOpen] = useState(false);

  let lastChangesLoaded = 0; // Declare as a variable

  const [countCheckedIn, setCountCheckedIn] = useState("0");
  const [countCheckedOut, setCountCheckedOut] = useState("0");
  const [countWaiting, setCountWaiting] = useState("0");
  const [countInvited, setCountInvited] = useState("0");
  const [countConfirmed, setCountConfirmed] = useState("0");
  const [countUnderReview, setCountUnderReview] = useState("0");
  const [countRejected, setCountRejected] = useState("0");
  const [countDenied, setCountDenied] = useState("0");

  const [yesNoMessage, setYesNoMessage] = useState("");
  const [yesNoDialogEntity, setYesNoDialogEntity] = useState({});


  const defaultVisitorType = { label: 'All Visitor Types', value: "all" };


  const [allVisitorTypes, setAllVisitorTypes] = useState([]);


  const [selectedVisitorType, setSelectedVisitorType] = useState(defaultVisitorType);

  const handleVisitorTypeChange = (event, value) => {
    if(initReady){
      setSelectedVisitorType(value);
    }
  };
  const defaultVisitStatus = { label: 'All', value: "all" };

  const [filterVisitorStatus, setFilterVisitorStatus] = useState({ filterInvited: true, filterWaiting: true, filterConfirmed: true, filterCheckedIn : true, filterCheckedOut: true, filterUnderReview: false, filterDenied: false, filterRejected: false  });
  const [filterVisitorStatusInit, setFilterVisitorStatusInit] = useState(false);


  const theme = useTheme();

  /////////////// GRID /////////////
  // values to be sent to the backend

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [],
    query:[]
  })
    
    // useEffect(() => {
      
    //   const cookieData = cookieManager.readValue();
    //   if (cookieData && cookieData.visitorStatusFilter) {
    //       setFilterVisitorStatus(cookieData.visitorStatusFilter);
    //   }else{
    //     cookieManager.saveValue({ visitorStatusFilter: filterVisitorStatus });
    //   }

    //   const defaultVisitorType = { label: 'All Visitor Types', value: "all" };

    //   var visitorTypes = [];
    //   visitorTypes.push(defaultVisitorType)
    //   for (const item of selectedFrontDeskTerminalSettings.checkInWorkflow) {
    //     var tmp = { label: item.visitorType, value: item.visitorType };
    //     visitorTypes.push(tmp)
    //   }

    //   setAllVisitorTypes(visitorTypes);
      

    //   const intervalId = setInterval(() => {

    //       getLastVisitorChanges(navigate)(selectedFrontDesk.entityId)
    //         .then((result) => {

    //       // Use the result.data as needed
    //       if(result.success){

    //         if(lastChangesLoaded !== result.data){
    //             lastChangesLoaded = result.data;
    //             setTriggerRefresh((prev) => !prev);
    //           }
    //       }
    //       setInitReady(true);

    //     })
    //     .catch((error) => {

    //       console.log(error);
    //     });
        
    //   }, 1000);

    //   // Cleanup function to clear the interval when the component unmounts
    //   return () => clearInterval(intervalId);
    // }, [selectedFrontDesk]);

    useEffect(() => {
      const cookieData = cookieManager.readValue();
      
      let initialFilter = {
        filterInvited: true,
        filterWaiting: true,
        filterConfirmed: true,
        filterCheckedIn: true,
        filterCheckedOut: true,
        filterUnderReview: false,
        filterDenied: false,
        filterRejected: false
      };
    
      if (cookieData && cookieData.visitorStatusFilter) {
        initialFilter = cookieData.visitorStatusFilter;
      } else {
        cookieManager.saveValue({ visitorStatusFilter: initialFilter });
      }
    
      setFilterVisitorStatus(initialFilter); // Set the state synchronously
      setFilterVisitorStatusInit(true)

      // Initialize visitor types
      const defaultVisitorType = { label: "All Visitor Types", value: "all" };
      let visitorTypes = [defaultVisitorType];
    
      if (selectedFrontDeskTerminalSettings?.checkInWorkflow) {
        visitorTypes = [
          defaultVisitorType,
          ...selectedFrontDeskTerminalSettings.checkInWorkflow.map((item) => ({
            label: item.visitorType,
            value: item.visitorType
          }))
        ];
      }
    
      setAllVisitorTypes(visitorTypes);
    
      const intervalId = setInterval(() => {
        getLastVisitorChanges(navigate)(selectedFrontDesk.entityId)
          .then((result) => {
            if (result.success && lastChangesLoaded !== result.data) {
              lastChangesLoaded = result.data;
              setTriggerRefresh((prev) => !prev);
            }
          })
          .catch((error) => console.log(error));
    
        setInitReady(true);
      }, 1000);
    
      return () => clearInterval(intervalId);
    }, [selectedFrontDesk]);
    

    useEffect(() => {

      if (!initReady || !filterVisitorStatusInit) return;

      loadGridData();
    }, [paginationModel, filterVisitorStatus, selectedVisitorType, triggerRefresh]);

    useEffect(() => {

      if (!initReady) return;
      loadGridData();

    }, [triggerRefresh]);

    const loadGridData = async () => {

      setGridLoading(true)
  
      getVisitDashboardAll(navigate)( selectedFrontDesk.entityId, paginationModel, filterVisitorStatus, selectedVisitorType)
        .then((result) => {
  
          setData(result)
          setGridLoading(false)
  
        })
        .catch((error) => {
  
          console.log(error);
  
          setGridLoading(false)
        });
  
    }

    useEffect(() => {

      
      const intervalId = setInterval(() => {
        
        // const frontdesks = frontDesk.getSelectedFrontDesk()

        // getCurrentVisitorStats(frontdesks.entityId)
        getCurrentVisitorStats(navigate)(selectedFrontDesk.entityId)
          .then((result) => {

        // Use the result.data as needed
        if(result.success){

          let countCheckedIn = result.data.find(item => item.visitorStatus === 'checked_in').count;
          setCountCheckedIn(countCheckedIn)
          let countCheckedOut = result.data.find(item => item.visitorStatus === 'checked_out').count;
          setCountCheckedOut(countCheckedOut);
          let countWaiting = result.data.find(item => item.visitorStatus === 'pre_checked_in').count;
          setCountWaiting(countWaiting)
          let countInvited = result.data.find(item => item.visitorStatus === 'pre_registered').count;
          setCountInvited(countInvited);
          let countConfirmed= (result.data.find(item => item.visitorStatus === 'confirmed')?.count) || 0;
          setCountConfirmed(countConfirmed);
          let countUnderReview= (result.data.find(item => item.visitorStatus === 'under_review')?.count) || 0;
          setCountUnderReview(countUnderReview);
          let countDenied= (result.data.find(item => item.visitorStatus === 'denied')?.count) || 0;
          setCountDenied(countDenied);
          let countRejected= (result.data.find(item => item.visitorStatus === 'rejected')?.count) || 0;
          setCountRejected(countRejected);
        }
      })
      .catch((error) => {

        console.log(error);

      });
        
      }, 5000);

      // Cleanup function to clear the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }, [selectedFrontDesk]);

    function CustomToolbar() {
      return (
        <GridToolbarContainer sx={{
          "&.Mui-checked": { // Adjust the color when checked
            color: sadevioColors.hexColorInvited+' !important',
          },
          marginRight: '-14px',
        }}>
          <GridToolbarColumnsButton color="neutral"  sx={{
      "&.Mui-checked": { // Adjust the color when checked
        color: sadevioColors.hexColorInvited+' !important',
      },
      marginRight: '-14px',
    }} />
          <GridToolbarFilterButton  style={{ color: '#3f51b5' }}  />
        </GridToolbarContainer>
      );
    }

  const handleFilterModelChange = (filterModel) => {
    // Update the filter model state when it changes


    // Convert to the desired structure
    const desiredStructure = filterModel.items.map(item => {
      let mappedOperator = item.operator;
    
      let field = columns.find(column => column.field === item.field);
      var fieldType = "string";

      if(typeof field.type !== 'undefined'){
        fieldType = field.type;
      }

      // Map operators as needed
      if (item.operator === 'equals') {
        mappedOperator = 'eq';
      } else if (item.operator === 'contains') {
        mappedOperator = 'like';
      } // Add more conditions as needed
    
      return {
        property: item.field,
        value: item.value,
        type: fieldType, // You might need to adjust this based on your data types
        operator: mappedOperator
      };
    });

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      query: desiredStructure,
      page: 0,
    }));

  };

  const handleSortModelChange = (sortModel) => {
    // Handle sorting changes and fetch data from the server
    //sort: [{"property":"lastname","direction":"ASC"}]

    console.log("handleSortModelChange()")

    const sortObject = sortModel.map(item => ({
      property: item.field,
      direction: item.sort.toUpperCase() // Assuming you want direction in uppercase (ASC or DESC)
    }));

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      sort: sortObject,
      page: 0,
    }));

  };

  const handlePageChange = (newPage) => {
    // Handle page changes and fetch data from the server

    console.log("handlePageChange()")

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPage.page,
      limit: newPage.pageSize,
    }));

  };

  

  const handleOptionClick = (id, action, row) => {
      console.log(action)

      if(action == "cancel_visit"){

          // setConfirmationOpen(true);
          cancelVisit(navigate)(row.visitId, {})
          .then((result) => {

            // setData(result)
            setGridLoading(false)

          })
          .catch((error) => {

            console.log(error);

            setGridLoading(false)
          });

      }else if(action == "delete_visit"){

          setConfirmationOpen(true);
          setYesNoMessage("Do you really want to delete this visit?")
          row["action"] = "delete_visit";
          setYesNoDialogEntity(row)
      }

  }

  const statusOptions = ["Pending", "Completed", "In Progress"];

  // Convert HSL to RGBA with transparency
    const hslToRgba = (hue, saturation = 75, lightness = 65, alpha = 0.1) => {
      const chroma = (1 - Math.abs(2 * (lightness / 100) - 1)) * (saturation / 100);
      const x = chroma * (1 - Math.abs(((hue / 60) % 2) - 1));
      const m = lightness / 100 - chroma / 2;
      let r = 0, g = 0, b = 0;

      if (hue >= 0 && hue < 60) { r = chroma; g = x; b = 0; }
      else if (hue >= 60 && hue < 120) { r = x; g = chroma; b = 0; }
      else if (hue >= 120 && hue < 180) { r = 0; g = chroma; b = x; }
      else if (hue >= 180 && hue < 240) { r = 0; g = x; b = chroma; }
      else if (hue >= 240 && hue < 300) { r = x; g = 0; b = chroma; }
      else { r = chroma; g = 0; b = x; }

      return `rgba(${Math.round((r + m) * 255)}, ${Math.round((g + m) * 255)}, ${Math.round((b + m) * 255)}, ${alpha})`;
    };

    // Generate a unique color based on visitId + timestamps
    const getColorFromData = (visitId, visitValidFrom, visitValidTo) => {
      const combinedString = `${visitId}-${visitValidFrom}-${visitValidTo}`;
      let hash = 0;
      for (let i = 0; i < combinedString.length; i++) {
        hash = combinedString.charCodeAt(i) + ((hash << 5) - hash);
      }
      const hue = (hash % 360 + 360) % 360; // Keep hue in valid HSL range
      return hslToRgba(hue, 75, 65, 0.1); // Use transparent background effect
    };

  const columns = [
    
    {
      field: "entityId",
      headerName: "Entity Id",
      filterable: false,
      flex: 1,
      type: 'number'
    },
    {
      field: "visitId",
      headerName: "Visit ID",
      width: 100,
      renderCell: (params) => {
        const row = params.row;
        const color = getColorFromData(row.visitId, row.visitValidFrom, row.visitValidTo);
        return (
          <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            ml: 1,
            border: `2px solid ${color.replace("0.1", "1")}`, // Full opacity border
            borderRadius: "8px", // Rounded corners for button-like appearance
            padding: "0.3rem",
            width: "100%",
            textAlign: "center",
            backgroundColor: `${color}`, // Light background (30% opacity)
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", textAlign: "center", flexGrow: 1 }} 
            >
            {params.value}
          </Typography>
        </Box>
        );
      },
    },
    {
      field: 'picturePath',
      filterable: false,
      headerName: tm.translate('clmPicture', "Picture") ,
      width: 100,
      renderCell: (params) => (
        params.row.picturePath != null ? (
          <Avatar {...stringAvatar(params.row.fullName)} alt={params.row.fullName} src={`${getUri(getSessionToken())}/visitor/${params.row.visitorId}/picture/${params.row.picturePath}`}          />

        ) : (
          <Avatar {...stringAvatar(params.row.fullName)} />
        )
      ),
    },
    {
      field: "firstname",
      headerName: tm.translate('clmFirstName', "First Name") ,
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/visit/${params.row.visitId}/visitor/${params.row.visitorId}`)
          }}
        >
        {params.row.firstname}
      </Link>
      ),
    },
    {
      field: "lastname",
      headerName: tm.translate('clmLastName', "Last Name") ,
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/visit/${params.row.visitId}/visitor/${params.row.visitorId}`)
          }}
        >
        {params.row.lastname}
      </Link>
      ),
    },
    {
      field: "visitValidFrom",
      headerName: tm.translate('clmValidFrom', "Valid From") ,
      flex: "auto",
      minWidth: 150,
      type: "date",
      valueGetter: (params) => {
        // Ensure the value is transformed into a Date object
        return new Date(params.value);
      },
      valueSetter: (params) => {
        // Convert selected date to YYYY-MM-DD for server requests
        return { ...params.row, visitValidFrom: dayjs(params.value).format("YYYY-MM-DD") };
      },
      renderCell: (params) => {
        const utcDateTime = params.value; // Assuming params.value contains the UTC timestamp
        // const localDateTime = dayjs.utc(utcDateTime).local().format('DD.MM.YYYY HH:mm'); // Convert UTC to local and format
        const localDateTime = convertToFrontDeskTimeZoneDateTimeString(utcDateTime)
        return <span>{localDateTime}</span>;
      }
    },
    {
      // [{"property":"visitValidFrom","value":"2025-02-28","type":"date","operator":"eq"}]
      field: "visitValidTo",
      headerName: tm.translate('clmValidTo', "Valid To"),
      flex: "auto",
      minWidth: 150,
      type: "date",
      valueGetter: (params) => {
        // Ensure the value is transformed into a Date object
        return new Date(params.value);
      },
      valueSetter: (params) => {
        // Convert selected date to YYYY-MM-DD for server requests
        return { ...params.row, visitValidTo: dayjs(params.value).format("YYYY-MM-DD") };
      },
      renderCell: (params) => {
        const utcDateTime = params.value; // Assuming params.value contains the UTC timestamp
        // const localDateTime = dayjs.utc(utcDateTime).local(); // Convert UTC to local
        const localDateTimeString = convertToFrontDeskTimeZoneDateTimeString(utcDateTime)
        const localDateTime = convertToFrontDeskTimeZone(utcDateTime)
        const isPast = localDateTime.isBefore(dayjs()); // Check if the date is in the past
        return (
          <span
            style={{
              color: isPast ? 'red' : 'inherit',
              fontWeight: isPast ? 'bold' : 'normal',
            }}
          >
            {localDateTimeString}
          </span>
        );
      },
     

    },
    {
      field: "visitStatus",
      headerName: tm.translate('clmVisitStatus', "Visit Status") ,
      flex: "auto",
      minWidth: 120,
      renderCell: (params) => {
        let statusTranslation = "";
        switch(params.row.visitStatus) {
          case "pre_registered":
            statusTranslation = tm.translate("lblInvited", "Invited");
            break;
          case "started":
            statusTranslation = tm.translate("lblStarted", "Started");
            break;
          case "finished":
            statusTranslation = tm.translate("lblFinished", "Finished");
            break;
          case "canceled":
            statusTranslation = tm.translate("lblCanceled", "Canceled");
              break;
          case "rejected":
            statusTranslation = tm.translate("lblRejected", "Rejected");
              break;
          default:
            statusTranslation = params.row.visitStatus;
            break;
        }
        return <span>{statusTranslation}</span>;
      },
      // filterOperators: [
      //   {
      //     label: "Status is",
      //     value: "equals",
      //     getApplyFilterFn: (filterItem) => {
      //       if (!filterItem.value) return null;
      //       return (params) => params.value === filterItem.value;
      //     },
      //     InputComponent: (props) => {
      //       const { item, applyValue } = props;
      //       return (
      //         <Select
      //           value={item.value || ""}
      //           onChange={(event) => applyValue({ ...item, value: event.target.value })}
      //           fullWidth
      //         >
      //           {statusOptions.map((option) => (
      //             <MenuItem key={option} value={option}>
      //               {option}
      //             </MenuItem>
      //           ))}
      //         </Select>
      //       );
      //     },
      //   },
      // ],
    },
    {
      field: "visitorStatus",
      headerName: tm.translate('clmVisitorStatus', "Visitor Status"),
      minWidth: 220,
      renderCell: (params) => (
        <GridCellVisitorStatus rowData={params.row} />
      ),
    },
    {
      field: "visitorType",
      headerName: tm.translate('clmVisitorType', "Visitor Type"),
    },
    {
      field: "companyName",
      headerName: tm.translate('clmCompany', "Company"),
    },
    {
      field: "accessCardId",
      headerName: tm.translate('clmAccessCard', "Access Card"),
      minWidth: 180,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: 1,
          border: params.row.accessCardId?.trim() !== ""
                          ? "2px solid green" // Green border for assigned card
                          : "2px dashed grey", // Grey dashed border for unassigned card
                        borderRadius: "8px", // Rounded corners for a button-like look
                        padding: "0.3rem",
                        width: '100%',
                        textAlign: "center", // Centers the content
                        backgroundColor: params.row.accessCardId?.trim() !== ""
                          ? "rgba(0, 255, 0, 0.1)" // Light green background for assigned card
                          : "transparent", // No background for unassigned card
         }}>
          {params.row.accessCardId ? (
            <>
              <Badge sx={{ color: 'green', fontSize: 20, mr: 1 }} />
              <Typography variant="body1">{params.row.accessCardId}</Typography>
            </>
          ) : (
            <>
              <Badge sx={{ color: 'lightgrey', fontSize: 20, mr: 1 }} />
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>{tm.translate("lblNoBadge", "No Card")}</Typography>
            </>
          )}
        </Box>
      ),
    },
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   width: 80,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <GridCellVisitsActions
    //       id={`${params.row.visitId}_${params.row.visitorId}`}
    //       row={params.row}
    //       onOptionClick={handleOptionClick}
    //     />
    //   ),
    // },
  ];

  const btnNewVisit = tm.translate('btnNewVisit', 'New Visit');
  const btnNewInvitation = tm.translate('btnNewInvitation', 'New Invitation');
  const lblHomeStatWaiting = tm.translate('lblHomeStatWaiting', 'Waiting');

  const buttons = [
    // { 
    //   type: 'search', 
    //   label: tm.translate("lblSearchVisitor", "Search visitors..."), 
    //   onChange: (e) => console.log(e.target.value), 
    //   iconName: '' 
    // },
    ...(hasPermission("system.visit.new") 
      ? [{
          type: 'button',
          label: btnNewVisit,
          onClick: () => navigate("/visit/-1"),
          iconName: ''
        }] 
      : []), // Add the btnNewVisit button only if hasPermission is true
    { 
      type: 'button', 
      label: btnNewInvitation, 
      onClick: () => navigate("/visit/new/invitation"), 
      iconName: '' 
    },
  ];
  

  // if(hasPermission("system.qrscanner")){
  //   buttons.push({ type:'button',label: "Scan QR" , onClick: () => setConfirmationOpen(true) , iconName: 'qr_code_2'});
  // }

  const [drawerOpen, setDrawerOpen] = useState(false);

  // Function to handle opening the Drawer
  const openDrawer = () => {
    setDrawerOpen(true);
  };

  // Function to handle closing the Drawer
  const closeDrawer = () => {
    setDrawerOpen(false);
    navigate("/visit/logs")
  };

  const handleCheckboxVisitorStatusChange = (event) => {

    console.log("handleCheckboxVisitorStatusChange")
    if(initReady){
      const { name, checked } = event.target;
      setFilterVisitorStatus(prevState => ({
        ...prevState,
        [name]: checked
      }));
  
      cookieManager.saveValue({ visitorStatusFilter: { ...filterVisitorStatus, [name]: checked } });

    }

  };


  return (
    <Box m="1rem 2rem">
      <Header title="Visits" subtitle="List of visitors" elements={buttons} />
      <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={() => setConfirmationOpen(false)}
        // onConfirm={handleExecuteYesNo}
        theme={theme}
        message={yesNoMessage}
        entity={yesNoDialogEntity}
      />

      {/* FILTER */}
      <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      m= "10px 0px 0px 0px"
    >
      <Box>
          {/* push filter buttons to back */}
      </Box>
      <Box
        display="flex"
        flexDirection="row" // Render elements horizontally
        alignItems="center"
      >
          
            <FormControlLabel
              control={
                <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                  <Checkbox 
                      checked={  filterVisitorStatus.filterInvited}
                      onChange={handleCheckboxVisitorStatusChange}
                      name="filterInvited"
                      sx={{
                        "&.Mui-checked": { // Adjust the color when checked
                          color: sadevioColors.hexColorInvited+' !important',
                        },
                        marginRight: '-14px',
                      }}
                    />
                  <IconButton>
                    <Upcoming style={{ color: sadevioColors.hexColorInvited }}/> 
                  </IconButton>
                </Box>
              }
              label={countInvited+" "+tm.translate('lblPreRegistered', "Invited")}
            />
            <FormControlLabel
              control={
                <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                  <Checkbox 
                      checked={  filterVisitorStatus.filterWaiting}
                      onChange={handleCheckboxVisitorStatusChange}
                      name="filterWaiting"
                      sx={{
                        "&.Mui-checked": { // Adjust the color when checked
                          color: sadevioColors.hexColorWaiting+' !important',
                        },
                        marginRight: '-14px',
                      }}
                    />
                  <IconButton>
                    <HourglassEmpty style={{ color: sadevioColors.hexColorWaiting }}/> 
                  </IconButton>
                </Box>
              }
              label={countWaiting+" "+lblHomeStatWaiting}
            />
            <FormControlLabel
              control={
                <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                  <Checkbox 
                      checked={  filterVisitorStatus.filterConfirmed}
                      onChange={handleCheckboxVisitorStatusChange}
                      name="filterConfirmed"
                      sx={{
                        "&.Mui-checked": { // Adjust the color when checked
                          color: sadevioColors.hexColorConfirmed+' !important',
                        },
                        marginRight: '-14px',
                      }}
                    />
                  <IconButton>
                    <ThumbUpAlt style={{ color: sadevioColors.hexColorConfirmed }}/> 
                  </IconButton>
                </Box>
              }
              label={countConfirmed+" "+tm.translate('lblConfirmed', "Confirmed")}
            />
            <FormControlLabel
              control={
                <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                  <Checkbox 
                      checked={  filterVisitorStatus.filterCheckedIn}
                      onChange={handleCheckboxVisitorStatusChange}
                      name="filterCheckedIn"
                      sx={{
                        "&.Mui-checked": { // Adjust the color when checked
                          color: sadevioColors.hexColorCheckedIn+' !important',
                        },
                        marginRight: '-14px',
                      }}
                    />
                  <IconButton>
                    <ArrowCircleDown style={{ color: sadevioColors.hexColorCheckedIn }}/> 
                  </IconButton>
                </Box>
              }
              label= {countCheckedIn+" "+tm.translate('lblCheckedIn', "Checked-In")}
            />
            <FormControlLabel
              control={
                <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                  <Checkbox 
                      checked={  filterVisitorStatus.filterCheckedOut}
                      onChange={handleCheckboxVisitorStatusChange}
                      name="filterCheckedOut"
                      sx={{
                        "&.Mui-checked": { // Adjust the color when checked
                          color: sadevioColors.hexColorCheckedOut+' !important',
                        },
                        marginRight: '-14px',
                      }}
                    />
                  <IconButton>
                    <ArrowCircleUp style={{ color: sadevioColors.hexColorCheckedOut }}/> 
                  </IconButton>
                </Box>
              }
              label={countCheckedOut+" "+tm.translate('lblCheckedOut', "Checked-Out")} 
            />    
            <FormControlLabel
              control={
                <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                  <Checkbox 
                      checked={  filterVisitorStatus.filterUnderReview}
                      onChange={handleCheckboxVisitorStatusChange}
                      name="filterUnderReview"
                      sx={{
                        "&.Mui-checked": { // Adjust the color when checked
                          color: sadevioColors.hexColorUnderReview+' !important',
                        },
                        marginRight: '-14px',
                      }}
                    />
                  <IconButton>
                    <FindInPage style={{ color: sadevioColors.hexColorUnderReview }}/> 
                  </IconButton>
                </Box>
              }
              label={countUnderReview+" "+tm.translate('lblUnderReview', "Under Review")} 
            />    
            <FormControlLabel
              control={
                <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                  <Checkbox 
                      checked={  filterVisitorStatus.filterDenied}
                      onChange={handleCheckboxVisitorStatusChange}
                      name="filterDenied"
                      sx={{
                        "&.Mui-checked": { // Adjust the color when checked
                          color: sadevioColors.hexColorDenied+' !important',
                        },
                        marginRight: '-14px',
                      }}
                    />
                  <IconButton>
                    <FindInPage style={{ color: sadevioColors.hexColorDenied }}/> 
                  </IconButton>
                </Box>
              }
              label={countDenied+" "+tm.translate('lblDenied', "Denied")} 
            /> 
            <FormControlLabel
              control={
                <Box display="flex" alignItems="center" sx={{marginLeft: '10px', }}> 
                  <Checkbox 
                      checked={ filterVisitorStatus.filterRejected}
                      onChange={handleCheckboxVisitorStatusChange}
                      name="filterRejected"
                      sx={{
                        "&.Mui-checked": { // Adjust the color when checked
                          color: sadevioColors.hexColorRejected+' !important',
                        },
                        marginRight: '-14px',
                      }}
                    />
                  <IconButton>
                    <FindInPage style={{ color: sadevioColors.hexColorRejected }}/> 
                  </IconButton>
                </Box>
              }
              label={countRejected+" "+tm.translate('lblRejected', "Rejected")} 
            />
            <Autocomplete
              disablePortal
              disableClearable
              disableInput
              autoSelect // Automatically selects the highlighted option on blu
              onChange={handleVisitorTypeChange}
              value={selectedVisitorType}
              options={allVisitorTypes}
              // options={[
              //   defaultVisitorType,
              //   { label: 'Visitor', value: 'Visitor' },
              //   { label: 'Contractor', value: 'Contractor' },
              // ]}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              defaultValue={defaultVisitorType}
              sx={{ width: 200, padding: "5px 5px" }}
              renderInput={(params) => <TextField {...params} required label={tm.translate('dspVisitorType', 'Visitor Type')} 
              sx={{ 
                        "& label.Mui-focused": {
                          color: theme.palette.secondary[200],
                        },
                        "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
                          color: theme.palette.secondary[300]
                        },
                    }}
              />}
            />
          {/* <Autocomplete
            key={2}
            disablePortal
            disableClearable
            disableInput
            autoSelect
            options={[
              defaultVisitStatus,
              { label: tm.translate('lblStarted', "Started"), value: "started" },
              { label: 'Finished', value: "finished" },
              { label: 'Invited', value: "pre_registered" },
              { label: 'Expired', value: "expired" },
              { label: 'Canceled', value: "canceled" }
            ]}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            defaultValue={defaultVisitStatus}
            sx={{ width: 200, padding: "5px 5px" }}
            renderInput={(params) => <TextField {...params} required label={tm.translate('clmVisitStatus', "Visit Status")} 
            sx={{ 
                      "& label.Mui-focused": {
                        color: theme.palette.secondary[200],
                      },
                      "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
                        color: theme.palette.secondary[300]
                      },
                  }}
                  
            />}
          />     */}

      </Box>
    </Box>


      {/* </Box> */}
      <Box
        mt="0px"
        height="80vh"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },          
        }}
      >
        <DataGridPro
          loading={gridLoading || !data}
          initialState={{
            pagination: { paginationModel: { pageSize: paginationModel.limit } },
          }}
          // getRowId={(row) => row.entityId}
          getRowId={(row) => `${row.visitId}-${row.visitorId}`}
          // hideFooter={true}
          rows={data?.data || []}
          columns={columns}
          rowCount={(data && data.total_count) || 0}
          rowsPerPageOptions={[25, 50, 100]}
          columnVisibilityModel={columnVisibilityModel}
          // onColumnVisibilityChange={handleColumnVisibilityChange}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }

          checkboxSelection

          filterMode="server"
          onFilterModelChange={handleFilterModelChange}

          sortingMode="server"
          onSortModelChange={handleSortModelChange}

          paginationMode="server"
          pagination
          page={paginationModel.page}
          pageSize={paginationModel.limit}
          onPaginationModelChange={(paginationModel) => handlePageChange(paginationModel)} //setPageSize(paginationModel.pageSize)

          // slots={{ toolbar: null, columnHeaders: () => null, }}
          slots={{ toolbar: CustomToolbar ,loadingOverlay: () => (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            ), }}
          slotProps={{
            filterPanel: {
              // Force usage of "And" operator
              logicOperators: [],
              // Display columns by ascending alphabetical order
              columnsSort: 'asc',
              
              sx: {
                '& .MuiButtonBase-root': { color: `${theme.palette.secondary[200]} !important` },
                '& .MuiFormLabel-root': { color: `${theme.palette.secondary[200]} !important` },
              },
            },
          }}
        />
      </Box>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={closeDrawer}
        // Adjust the width based on your requirement (2/3 of the space)
        sx={{   
          width: '66vw', 
          flexShrink: 0,
        }}
        PaperProps={{
          sx: {
            backgroundColor: `${theme.palette.primary[500]} !important`,
            backgroundImage: "none",
            borderLeft: 1
            // Other Paper styles if needed
          },
        }}
        
        variant="temporary"
      >
        <VisitNew />
        {/* Content of the Drawer */}
        {/* Add your detailed/edit view components here */}
        {/* For example: <EditForm /> */}
      </Drawer>
    </Box>
  );
};

export default VisitorsVisits;