import React, { useEffect, useState } from "react";
import { Box, Button, FormControlLabel,  ListItemIcon, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Close, Save,  Visibility } from "@mui/icons-material";
import IOSSwitch from "components/IOSSwitch";
import {  getPreRegManagerApprovalConfig, putPreRegManagerApprovalConfig } from "hooks/HttpRequests";
import * as yup from "yup";
import { Formik } from "formik";
import { Oval } from "react-loader-spinner";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import EmailManager from "components/EmailManager";

const InvitationReviewApproval = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { selectedFrontDesk } = useFrontDeskManager();

  var defaultValues = { 
                          active: false, 
                          visitorTypeFilter: "",
                          sendInvitation:true
                        }

  const [loadedFormData, setLoadedFormData] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [showConf, setShowConf] = useState(false);

  useEffect(() => {
    if (showConf) {
      loadData();
    }
  }, [showConf]);

  const registerSchema = yup.object().shape({
    // usersToNotify: yup.string().required("required"),
  });

  const initialValuesRegister = {
    active: false, 
    visitorTypeFilter: "", 
    sendInvitation: true
  };

  const toggleShowConf = () => {
    setShowConf(!showConf);
  };

  const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const loadData = async () => {
    setLoading(true);

    getPreRegManagerApprovalConfig(navigate)(selectedFrontDesk.entityId)
      .then((result) => {
        if (result.success) {
          setLoadedFormData(result.data)
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleFormSubmit = (values) => {

    var submitData = {};
    submitData["data"] = values;

    dispatch(showLoading(""))

    putPreRegManagerApprovalConfig(navigate)(selectedFrontDesk.entityId, submitData)
      .then((result) => {
        if (result.success) {
          setLoadedFormData(result.data);
          toggleShowConf()
        }
        dispatch(hideLoading());
      })
      .catch((error) => {
        
        dispatch(hideLoading());
      });
  };

  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Function to calculate color based on the slider value
  const getGradientColor = (value) => {
    const red = Math.min(255, Math.max(0, Math.floor(255 - (value - 1) * 28.3)));
    const green = Math.min(255, Math.max(0, Math.floor((value - 1) * 28.3)));
    return `rgb(${red}, ${green}, 0)`;
  };

  return (
    <Box sx={{ ...commonStyles, border: 1 }}>
      <Box display="flex" alignItems="center">
        <ListItemIcon>
          <Visibility fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem"}}/>
        </ListItemIcon>
        <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem" }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h3" color={theme.palette.secondary[100]} fontWeight="bold">
              Invitation Review and Approval
            </Typography>
          </Box>
          <Typography variant="h5" color={theme.palette.secondary[300]}>
            Customize and manage the review and approval process for invitations.
          </Typography>
        </Box>
        <Box ml="auto">
          {!showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              EDIT
            </Button>
          )}
          {showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              <Close />
            </Button>
          )}
        </Box>
      </Box>

      {showConf && (
        <Box>
          {loading ? (
            <Box sx={{ width: "100%", height: "100%", padding: "20px", display: "flex", justifyContent: "center" }}>
            <Oval
                visible={true}
                height="80"
                width="80"
                color="#ffedc2"
                secondaryColor="#ffedc2"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Box>
          ) : (
            
            
            <Box sx={{ padding: "20px"}}>
              
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={loadedFormData || initialValuesRegister}
                validationSchema={registerSchema}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Paper
                      elevation={3}
                      style={{
                        backgroundColor: "transparent",
                        backgroundImage: "none",
                      }}
                      sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
                    >
                  {/* first section -------------------------- */}
                  <Typography variant="h4" color={theme.palette.secondary[300]} x={{ 
                              margin: 5,
                            }}>
                      Manager Review
                  </Typography>
                  <Typography variant="h5" color={theme.palette.secondary[300]} x={{ 
                              margin: 5,
                            }}>
                      If a manager is configured for the employee, the invitation will be under review.
                  </Typography>
                  <Box sx={{ 
                              borderRadius: 1,
                              padding: 5,
                              mt: 3,
                              backgroundColor: theme.palette.background.alt,
                              mb:3,
                              borderColor: theme.palette.secondary[100],   
                            }}>
                      <FormControlLabel
                        sx={{ mb: 2, gridColumn: "span 4" }}
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={values.active}
                            name="active"
                            // onChange={(value) => {
                            //   console.log(value.target.checked);
                            //   loadedFormData["active"] = value.target.checked;
                            // }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            // onChange={(e) => setFieldValue("active", e.target.checked)}

                          />
                        }
                        label={<span style={{ color: theme.palette.secondary.light }}>Active</span>}
                      />
                      <EmailManager
                        title= {"Visitor Type Filter - keep empty for all visitor types"}
                        fieldTitle = {"Add Visitor Type"}
                        value={values.visitorTypeFilter}
                        onChange={(newValue) => setFieldValue('visitorTypeFilter', newValue)}
                        sx={{ mb: 2, gridColumn: "span 4" }}
                      />
                      {touched.visitorTypeFilter && errors.visitorTypeFilter && (
                        <Typography variant="caption" color="error">
                          {errors.visitorTypeFilter}
                        </Typography>
                      )}
                      <FormControlLabel
                        sx={{ mb: 2, gridColumn: "span 4" }}
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={values.sendInvitation}
                            name="sendInvitation"
                            // onChange={(value) => {
                            //   console.log(value.target.checked);
                            //   loadedFormData["active"] = value.target.checked;
                            // }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            // onChange={(e) => setFieldValue("active", e.target.checked)}

                          />
                        }
                        label={<span style={{ color: theme.palette.secondary.light }}>Send invitation to visitor after approval</span>}
                      />
                    

                      </Box>
                          
                    </Paper>
                    
                        <Button
                          startIcon={<Save />}
                          type="submit"
                          sx={{
                            p: "1rem",
                            m: "1rem 0rem 0rem 0rem",
                            backgroundColor: palette.secondary.light,
                            color: palette.background.alt,
                            fontWeight: "bold",
                            "&:hover": {
                              color: palette.primary.main,
                              "&:hover": { color: theme.palette.secondary.dark },
                              fontWeight: "bold",
                            },
                          }}
                        >
                          Save
                        </Button>
                  </form>
                )}
              </Formik>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default InvitationReviewApproval;
