import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Tooltip,
  useTheme,
} from "@mui/material";
import tm from 'components/TranslationManager';

// Helper functions
const getDistanceColor = (distance) => {
  if (distance <= 5) {
    return `rgb(${255}, ${Math.round((255 * distance) / 5)}, 0)`;
  }
  return `rgb(${Math.round(255 - (255 * (distance - 5)) / 5)}, 255, 0)`;
};

const getDistanceColorVisualCompliance = (distance) => {
  // if (distance <= 5) {
  //   return `rgb(${255}, ${Math.round((255 * distance) / 5)}, 0)`;
  // }
  // return `rgb(${Math.round(255 - (255 * (distance - 5)) / 5)}, 255, 0)`;
  return "red"
};

const truncateText = (text, maxLength = 30) =>
  text && text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

const formatDate = (timestamp) => {
  if (!timestamp) return "N/A";
  const date = new Date(timestamp);
  return date.toLocaleDateString();
};

const DialogShowPotentialMatches = ({
  open,
  onClose,
  onSave,
  potentialMatches,
}) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState([]);

  useEffect(() => {

    if(open){
      setLoading(true)
      console.log(potentialMatches)
      setMatches(Array.isArray(potentialMatches) ? potentialMatches : []);

      for (var n = 0; n < potentialMatches.length; n++) {
        if(potentialMatches[n].extraInfo != null && potentialMatches[n].extraInfo != "" && typeof potentialMatches[n].extraInfo === "string"){
          potentialMatches[n].extraInfo = JSON.parse(potentialMatches[n].extraInfo)
        }
      }
      setMatches(potentialMatches);
      setLoading(false)
    }
    
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave();
  };

  if (loading && !matches) {
    return <div>Loading...</div>;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Paper
        sx={{
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.text.primary,
        }}
      >
        <DialogTitle
          sx={{
            color: theme.palette.background.alt,
            fontWeight: "bold",
          }}
        >
          Potential Matches
        </DialogTitle>
        <DialogContent>
          {matches.length === 0 ? (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textAlign: "center", mt: 2 }}
            >
              No potential matches found.
            </Typography>
          ) : (
            <Box
              sx={{
                maxHeight: "400px",
                overflowY: "auto",
                p: 2,
                borderRadius: 1,
                backgroundColor: theme.palette.background.alt,
              }}
            >
              <List>
                {matches.map((match, index) => (
                  <React.Fragment key={index}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt={match.name || "Unknown"}
                          src={match.picturePath || ""}
                        >
                          {match.name ? match.name[0] : "?"}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="h6" color="text.primary">
                            {match.name || "Unknown"}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ mt: 0.5 }}
                            >
                              {match.company ? `Company: ${match.company}` : ""}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ mt: 0.5 }}
                            >
                              {`Country: ${formatDate(match.country)}`}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ mt: 0.5 }}
                            >
                              {match.city || match.stateCode || match.zip || match.address
                                ? `Location: ${[
                                    match.address,
                                    match.city,
                                    match.stateCode,
                                    match.zip,
                                    match.countryCode,
                                  ]
                                    .filter(Boolean)
                                    .join(", ")}`
                                : ""}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ mt: 0.5 }}
                            >
                              {`Birthday: ${formatDate(match.birthday)}`}
                            </Typography>
                            {match.source && (
                              <Tooltip title={match.source} arrow>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{
                                    mt: 0.5,
                                  }}
                                >
                                  Source: {truncateText(match.source)}
                                </Typography>
                              </Tooltip>
                            )}
                            {/* {match.extraInfo && (
                              <Tooltip
                                title={
                                  <div>
                                    {match.extraInfo.map((info, index) => (
                                      <Typography key={index} variant="body2">
                                        {info}
                                      </Typography>
                                    ))}
                                  </div>
                                }
                                arrow
                              >
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{
                                    mt: 0.5,
                                    textDecoration: "underline dotted",
                                    cursor: "pointer",
                                  }}
                                >
                                  Source: {truncateText(match.extraInfo)}
                                </Typography>
                              </Tooltip>
                            )} */}
                            {
                              match.extraInfo && (
                                <>
                            <Typography variant="h6" color="text.primary">
                                {"Extra Info"}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                      mt: 0.5,
                                    }}
                                  >
                                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                      {match.extraInfo.map((infoData, index) => (
                                        <li key={index} style={{ paddingBottom: '8px' }}>
                                          <span dangerouslySetInnerHTML={{ __html: infoData }} />
                                        </li>
                                      ))}
                                    </ul>
                                  </Typography>
                                  </>
                              )
                            }

                            {match.reason && (
                              <Tooltip title={match.reason} arrow>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{
                                    mt: 0.5,
                                    textDecoration: "underline dotted",
                                    cursor: "pointer",
                                  }}
                                >
                                  Reason: {truncateText(match.reason)}
                                </Typography>
                              </Tooltip>
                            )}
                            {match.notes && (
                              <Tooltip title={match.notes} arrow>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{
                                    mt: 0.5,
                                    textDecoration: "underline dotted",
                                    cursor: "pointer",
                                  }}
                                >
                                  Notes: {truncateText(match.notes)}
                                </Typography>
                              </Tooltip>
                            )}
                          </>
                        }
                      />
                      <Box
                        sx={{
                          ml: 2,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          minWidth:"6em"
                        }}
                      >
                        
                        {match.source === "sadevio" ? (
                            <Box
                              sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                backgroundColor: getDistanceColor(match.distance),
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              {match.distance}
                            </Box>
                          ) : match.source === "visualcompliance" ? (
                            <Box
                              sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                backgroundColor: getDistanceColorVisualCompliance(match.distance),
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              {match.distance}
                            </Box>
                          ) : null}
                        <Typography variant="body2" sx={{ mt: 1 }}>
                        {match.source === "sadevio" ? (
                            match.distance === 0 ? (
                              "Exact Match"
                            ) : match.distance <= 3 ? (
                              "Close Match"
                            ) : (
                              "Approximate"
                            )
                          ) : match.source === "visualcompliance" ? (
                            "Alert Level"
                          ) : null}
                        </Typography>
                      </Box>
                    </ListItem>
                    {index < matches.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Button
            onClick={handleSave}
            color="primary"
            autoFocus
            sx={{
              color: theme.palette.background.alt,
              fontWeight: "bold",
            }}
          >
            {tm.translate('btnOk', "OK")}
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogShowPotentialMatches;
