import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import Header from 'components/Header';
import VisitorEmailInvitationConfig from './visitoremails/VisitorEmailInvitationConfig';
import VisitorEmailCheckOutReminderConfig from './visitoremails/VisitorEmailCheckOutReminderConfig';
import VisitorEmailWelcomeConfig from './visitoremails/VisitorEmailWelcomeConfig';


const VisitorEmailTemplate = ({globalType, navigate, id }) => {

const theme = useTheme();

const [gridLoading, setGridLoading] = useState(false);

//////////////////////
  useEffect(() => {
    
    loadFormData();

  }, []); // Empty dependency array to fetch options only once on component mount


  const loadFormData = async () => {

    setGridLoading(true)

    setGridLoading(false)

  }


  return (
    <Box m="1.5rem 2.5rem">
      <Header title="VISITOR EMAILS" subtitle="Customize specific visitor notification messages." buttons={null} />
      

            <VisitorEmailWelcomeConfig />

            {/*  -------------------------------   */}

            <VisitorEmailInvitationConfig />

            {/*  -------------------------------   */}

            <VisitorEmailCheckOutReminderConfig />

    </Box>
  );
};

export default VisitorEmailTemplate;
