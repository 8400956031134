import React, { useEffect, useState } from "react";
import FlexBetween from "components/FlexBetween";
import { useNavigate } from "react-router-dom";
import {
  Print,
  Clear,
  Badge,
  Search,
  DoorSliding,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Avatar,
  Link,
  List,
  ListItem,
  ListItemButton,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Autocomplete,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { deleteBadge, getAssignedBadges, getAssignedDoorLevels, getSessionToken, getUri, unassigneBadgeDoorLevels } from "hooks/HttpRequests";
import { CssTextField, stringAvatar } from "components/StyledComponents";
import tm from "components/TranslationManager";
import GridCellVisitorStatus from "components/GridCellVisitorStatus";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import DialogAssignBadge from "components/badge/DialogAssignBadge";
import DialogAssignDoorLevel from "components/badge/DialogAssignDoorLevel";
import DialogYesNo from "components/dialog/DialogYesNo";

const BadgeHandling = ({ isDialog = false, currentEntity, entityTypeParam, visitInformation = null}) => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const [entityId, setEntityId] = useState(-1);

  const [searchBadge, setSearchBadge] = useState("");
  const [selectedBadgeItem, setSelectedBadgeItem] = useState(null);
  const [selectedBadgeItemIndex, setSelectedBadgeItemIndex] = useState(null);

  
  const [loadedFormData, setLoadedFormData] = useState({});

  useEffect(() => {
    
    if(entityTypeParam == "visitor"){
      setEntityId(currentEntity.visitorId);
    }else{
      setEntityId(currentEntity.entityId);
    }

  }, []);

  const [entityType, setEntityTypeParam] = useState(entityTypeParam);

  const [triggerLoad, setTriggerLoad] = useState(false);

  const handleTriggerLoad = () => {
    setTriggerLoad(!triggerLoad);
  };

  useEffect(() => {
    
    // This code will run every time paginationModel changes
    loadAssignedBadges();

  }, [entityId, triggerLoad]);

  ////////////////////////////////////////////////
  // ASSIGN BADGE PART
  ////////////////////////////////////////////////
  const [openDialogAssigneBadge, setOpenDialogAssigneBadge] = useState(false);
  const [onConfirmDeleteBadgeOpen, setOnConfirmDeleteBadgeOpen] = useState(false);

  ////////////////////////////////////////////////
  // ASSIGN DOOR LEVEL PART
  ////////////////////////////////////////////////
  const [openDialogAssigneDoorLevel, setOpenDialogAssigneDoorLevel] = useState(false);
  const [onConfirmDeleteDoorLevel, setOnConfirmDeleteDoorLevel] = useState(false);

  const defaultBadgeStatus = { label: 'Active', value: "active" };

  const defaultBadgeType = { label: 'Default', value: "default" };

  const [validFromDayJS, setValidFromDayJS] = useState(dayjs().set("hour", 9).startOf("hour"));
  const [validToDayJS, setValidToDayJS] = useState(dayjs().set("hour", 12).add(1, 'year'));



  ////////////////////////////////////////////////
  // ACCESS CARD HISTORY PART
  ////////////////////////////////////////////////

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [],
    query:[]
  })
  const [selectedHosts, setSelectedHosts] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [assignedBadges, setAssignedBadges] = useState([]);

  const [assignedDoorLevels, setaAssignedDoorLevels] = useState([]);
  const [doorLevelsLoading, setDoorLevelsLoading] = useState(false);

  const columns = [
    {
      field: "entityId",
      headerName: "Entity Id",
      flex: 1,
      type: 'number'
    },
    {
      field: 'picturePath',
      headerName: 'Picture',
      width: 100,
      renderCell: (params) => (
        params.row.picturePath != null ? (
          <Avatar {...stringAvatar(params.row.fullName)} alt={params.row.fullName} src={`${getUri(getSessionToken())}/host/${params.row.entityId}/picture/${params.row.picturePath}`}          />

        ) : (
          <Avatar {...stringAvatar(params.row.fullName)} />
        )
      ),
    },   
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/employees/${params.row.entityId}`)
          }}
        >
        {params.row.fullName}
      </Link>
      ),
    },
    {
      field: "visitStatus",
      headerName: tm.translate('clmVisitStatus', "Visit Status") ,
      flex: "auto",
      minWidth: 120,
    },
    {
      field: "visitorStatus",
      headerName: tm.translate('clmVisitorStatus', "Visitor Status"),
      minWidth: 220,
      renderCell: (params) => (
        <GridCellVisitorStatus rowData={params.row} />
      ),
    },
  ];

  const handleFilterModelChange = (filterModel) => {
    // Update the filter model state when it changes

    console.log("handleFilterModelChange()")

    // Convert to the desired structure
    const desiredStructure = filterModel.items.map(item => {
      let mappedOperator = item.operator;
    
      let field = columns.find(column => column.field === item.field);
      var fieldType = "string";

      if(typeof field.type !== 'undefined'){
        fieldType = field.type;
      }

      // Map operators as needed
      if (item.operator === 'equals') {
        mappedOperator = 'eq';
      } else if (item.operator === 'contains') {
        mappedOperator = 'like';
      } // Add more conditions as needed
    
      return {
        property: item.field,
        value: item.value,
        type: fieldType, // You might need to adjust this based on your data types
        operator: mappedOperator
      };
    });

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      query: desiredStructure,
      page: 0,
    }));

  };

  const handleSortModelChange = (sortModel) => {
    // Handle sorting changes and fetch data from the server
    //sort: [{"property":"lastname","direction":"ASC"}]


    const sortObject = sortModel.map(item => ({
      property: item.field,
      direction: item.sort.toUpperCase() // Assuming you want direction in uppercase (ASC or DESC)
    }));

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      sort: sortObject,
      page: 0,
    }));

      console.log(paginationModel)
  };

  const handlePageChange = (newPage) => {
    // Handle page changes and fetch data from the server

    console.log("handlePageChange()")

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPage.page,
      limit: newPage.pageSize,
    }));

  };

  useEffect(() => {



    // This code will run every time paginationModel changes
    loadAssignedBadges();

  }, [paginationModel]);



  const loadAssignedBadges = async () => {


    var extraFilter = "";
    // if(topSearchFilter.length > 0){
    //   extraFilter = "&extraFilter="+topSearchFilter
    // }
    
    if(entityId > 0){

      setGridLoading(true)

      getAssignedBadges()( entityType, entityType, entityId)
      .then((result) => {

        setAssignedBadges(result.data)
        if(result.total_count > 0){
          handleListItemClick(0);
          // loadAssignedDoorLevels(result.data[0].entityId)
        }
        setGridLoading(false)

      })
      .catch((error) => {

        console.log(error);

        setGridLoading(false)
      });

    }
    

  }

  useEffect(() => {
    
    if(assignedBadges.length > 0){
      loadAssignedDoorLevels(assignedBadges[selectedBadgeItemIndex].entityId);
    }

  }, [selectedBadgeItemIndex]);

  const loadAssignedDoorLevels = async (entityId) => {

    setDoorLevelsLoading(true)

    getAssignedDoorLevels(navigate)( entityId)
      .then((result) => {

        setaAssignedDoorLevels(result.data)
        if(result.total_count > 0){
          // setSelectedBadgeItemIndex(0);
          // setSelectedBadgeItem(result.data[0]);
        }
        setDoorLevelsLoading(false)

      })
      .catch((error) => {

        console.log(error);

        setDoorLevelsLoading(false)
      });

  }

  const toggleReviewers = () => {
    // setShowReviewers(!showReviewers);
};

const handleValidFromDateChange = (item) => {
    setValidFromDayJS(item)
    }
    
    const handleValidToDateChange = (item) => {
    setValidToDayJS(item)
    }

  const handleListItemClick = (index) => {
      
    setSelectedBadgeItemIndex(index);
    var tmpCard = assignedBadges[index];
    setSelectedBadgeItem(assignedBadges[index])

    // loadAssignedDoorLevels(tmpCard.entityId)

  };

  const handleExecuteDeleteBadge = (index) => {
      

    deleteBadge(navigate)( selectedBadgeItem.entityId)
      .then((result) => {

        handleTriggerLoad();
        setOnConfirmDeleteBadgeOpen(false)

      })
      .catch((error) => {

        console.log(error);

        setDoorLevelsLoading(false)
      });

  };

  const handleExecuteDeleteDoorLevel = (index) => {
      
    var doorLevels = [];

    unassigneBadgeDoorLevels(navigate)( selectedBadgeItem.entityId, doorLevels )
      .then((result) => {

        handleTriggerLoad();
        setOnConfirmDeleteDoorLevel(false)

      })
      .catch((error) => {

        console.log(error);

        setDoorLevelsLoading(false)
      });

  };
  
  
  return (
    <Box m="0.5rem 0.5rem">

        <DialogAssignBadge
            open={openDialogAssigneBadge}
            onClose={(entity) => { 
                            handleTriggerLoad();
                            setOpenDialogAssigneBadge(false)
                              }}
            onSave={(entity) => { 
                             handleTriggerLoad();
                             setOpenDialogAssigneBadge(false)
                                }}
            entityTypeParam={entityType}
            currentEntity ={currentEntity}
        />
        <DialogAssignDoorLevel
            open={openDialogAssigneDoorLevel}
            onClose={() =>  {handleTriggerLoad();
                            setOpenDialogAssigneDoorLevel(false)}}
            onSave={(entity) => { 
                                    handleTriggerLoad();
                                    setOpenDialogAssigneDoorLevel(false) 
                                }}
            theme={theme}
            entityTypeParam={entityType}
            currentEntity ={selectedBadgeItem}
        />
        <DialogYesNo
          open={onConfirmDeleteBadgeOpen}
          onClose={() => setOnConfirmDeleteBadgeOpen(false)}
          onConfirm={() => handleExecuteDeleteBadge}
          theme={theme}
          message={"Do you want to unassign the badge?"}
        />
        <DialogYesNo
          open={onConfirmDeleteDoorLevel}
          onClose={() => setOnConfirmDeleteDoorLevel(false)}
          onConfirm={() => handleExecuteDeleteDoorLevel}
          theme={theme}
          message={"Do you want to unassign the door level?"}
        />

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* --- LEFT START ---*/}
        <Box
          gridColumn="span 5"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
          display="flex"
          flexDirection="column"
        >
            <FlexBetween>
            <Button
                variant="contained"
                onClick={() => setOpenDialogAssigneBadge(true)}
                sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                "&:hover": { color: theme.palette.secondary.light },
                }}
            >
                {"Add Badge"}
            </Button>
            <Box>
                <CssTextField
                    label={"Serach Badge"}
                //   onChange={elemet.onChange}
                    sx={{ mr: 2 }}
                    InputProps={{
                    endAdornment: (
                        <Search />
                    ),
                    }}
                    theme={theme}
                />
            </Box>
        </FlexBetween>
      
      {
        gridLoading ? (
            "Loading..."
        ) : (
            assignedBadges && assignedBadges.length > 0 ? (
                <List dense sx={{ 
                    gridColumn: "span 4" , 
                    // bgcolor: 'background.paper',
                    margin: '15px 0px 0px 0px',
                    overflowY: 'auto', // Add this to enable vertical scrolling
                    // maxHeight: '320px', // Set a maximum height for the container
                    flex:1
                    
                }}>
                    {assignedBadges.map((value, index) => {
                        const labelId = `checkbox-list-secondary-label-${index}`;
                        const isLoggedInUser = typeof value.isLoggedInUser !== "undefined" && value.isLoggedInUser;
                        const handlePrint = () => {
                            // const updatedHosts = [...assignedBadges.slice(0, index), ...assignedBadges.slice(index + 1)];
                            // setSelectedHosts(updatedHosts);

                            // const updatedValue = [...selectedValues.slice(0, index), ...selectedValues.slice(index + 1)];
                            // setSelectedValues(updatedValue);

                            setOnConfirmDeleteBadgeOpen(true)


                        };
                        const picturePath = value.picturePath ? value.picturePath : "";

                        return (
                            <ListItem key={labelId} 
                                    disablePadding
                                    selected={selectedBadgeItemIndex === index}
                                    onClick={() => handleListItemClick(index)}
                                    >
                         
                                <ListItemButton>
                                    <ListItemAvatar>
                                    <Badge sx={{ color: theme.palette.secondary[100], fontSize: 24 }}/>
                                    </ListItemAvatar>
                                    <ListItemText>
                                            <Typography variant="h5" fontWeight="bold">
                                                {value.cardId}
                                            </Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                <IconButton onClick={handlePrint}>
                                    <Print style={{ color: theme.palette.secondary[100] }}/>
                                </IconButton>
                                <IconButton onClick={() => setOnConfirmDeleteBadgeOpen(true)}>
                                        <Clear style={{ color: theme.palette.secondary[100]}}/>
                                    </IconButton>
                            </ListItem>
                        );
                    })}
                </List>
            ) : (
                
              <Typography sx={{ mt: 3 }}>No credetnials</Typography>

            )
        )
    }
          
        </Box>
        {/* --- LEFT END ---*/}

        {/* --- RIGHT START ---*/}

        <Box
            gridColumn="span 7" // Set to half of the grid width
            gridRow="span 3" // Spanning 3 rows
            display="flex"
            flexDirection="column" // Stacking components vertically
            >
        {/* ROW 1 */}
        <Box sx={{ 
                m: "0rem 0rem 1rem 0rem",
                borderRadius: 1,
                borderColor: theme.palette.secondary[100],
                border: 0 , p: 2,
                backgroundColor:theme.palette.background.alt,
                display:"flex",
                                  flexDirection:"row"
         }}>   
                    
            <Autocomplete
                disablePortal
                disableClearable
                disableInput
                autoSelect // Automatically selects the highlighted option on blu
                options={[
                    defaultBadgeStatus,
                    { label: 'Deactivated', value: 'deactivated' },
                    { label: 'Lost', value: 'lost' },
                ]}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                defaultValue={defaultBadgeStatus}
                sx={{ width: 200, padding: "5px 5px" }}
                renderInput={(params) => <TextField {...params} required label={tm.translate('dspBadgeStatus', 'Status')} 
                sx={{ 
                        "& label.Mui-focused": {
                            color: theme.palette.secondary[200],
                        },
                        "& .Mui-expanded.MuiFormLabel-root.MuiInputLabel-root": {
                            color: theme.palette.secondary[300]
                        },
                    }}
                />}
            />

                <DateTimePicker onChange={handleValidFromDateChange}
                                                defaultValue={validFromDayJS}
                                                label="Valid From"
                                                sx={{ padding: "5px 5px" }} 
                                                />
                <DateTimePicker onChange={handleValidToDateChange}
                                defaultValue={validToDayJS}
                                label="Valid To" 
                                sx={{ padding: "5px 5px" }} 
                                />
        </Box>

        {/* door levels section */}
        <Box
            display="flex"
            flexDirection="column"
            sx={{ 
            m: "0rem 0rem 0rem 0rem",
            borderRadius: 1,
            borderColor: theme.palette.secondary[100],
            border: 0 , p: 2,
            backgroundColor:theme.palette.background.alt,
            height: "80%"
          }}>   

            <FlexBetween>
              <Button
                  variant="contained"
                  onClick={() => setOpenDialogAssigneDoorLevel(true)}
                  sx={{
                  backgroundColor: theme.palette.secondary.light,
                  color: theme.palette.background.alt,
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  "&:hover": { color: theme.palette.secondary.light },
                  }}
              >
                  {"Add Door Level"}
              </Button>
              <Box>
                  <CssTextField
                      label={"Serach Door Level"}
                  //   onChange={elemet.onChange}
                      sx={{ mr: 2 }}
                      InputProps={{
                      endAdornment: (
                          <Search />
                      ),
                      }}
                      theme={theme}
                  />
              </Box>
            </FlexBetween>
                {
        doorLevelsLoading ? (
            "Loading..."
        ) : (
            assignedDoorLevels && assignedDoorLevels.length > 0 ? (
                <List dense sx={{ 
                        gridColumn: "span 4" , 
                        margin: '15px 0px 0px 0px',
                        overflowY: 'auto', // Add this to enable vertical scrolling
                        flex: 1 
                       // maxHeight: '310px', // Set a maximum height for the container 
                        
                    }}>
                        {assignedDoorLevels.map((value, index) => {
                        const labelId = `checkbox-list-secondary-label-${index}`;

                    var isLoggedInUser = false
                    if(typeof value.isLoggedInUser !== "undefined"){
                        isLoggedInUser = true;
                    }

                    const handleRemoveHost = () => {
                        // Create a new array without the item to be removed
                        const updatedHosts = [...assignedDoorLevels.slice(0, index), ...assignedDoorLevels.slice(index + 1)];
                        // Update the state with the new array
                        setSelectedHosts(updatedHosts);

                         // Create a new array without the item to be removed
                         const updatedValue = [...selectedValues.slice(0, index), ...selectedValues.slice(index + 1)];
                         // Update the state with the new array
                         setSelectedValues(updatedValue);
                    };

                    const picturePath = value.picturePath ? value.picturePath : ""; // Check if picturePath is null
                    return (
                    <ListItem
                                        key={labelId}
                                        // secondaryAction={
                                        // <IconButton>
                                        //     <Clear style={{ color: sadevioColors.hexColorInvited }}/> 
                                        // </IconButton>
                                        // }
                                        disablePadding
                                    >
                                        <ListItemButton>
                                        <ListItemAvatar>
                                          <DoorSliding sx={{ color: theme.palette.secondary[100], fontSize: 24 }}/>
                                        </ListItemAvatar>
                                        <ListItemText primary={value.name} secondary={value.description} />
                                        </ListItemButton>
                                        <IconButton onClick={() => setOnConfirmDeleteDoorLevel(true)}>
                                            <Clear style={{ color: theme.palette.secondary[100]}}/>
                                        </IconButton>
                                    </ListItem>
                                    );
                                })}
                        </List>
                        ) : (
                
                         <Typography sx={{ mt: 3 }}>No door levels assigned</Typography>
                          )
                      )
                  }
                    
                </Box>
                {/*  ----------------   */}
        </Box>
        {/* --- LEFT END ---*/}

        {/* BOTTOM START */}
        {!isDialog && (
        <Box
        
          gridColumn="span 12"
          gridRow="span 2"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
        <Typography variant="h5" color={theme.palette.secondary[300]}>
            {"history"}
        </Typography>
          <DataGrid
            loading={gridLoading || !data}
            initialState={{
              pagination: { paginationModel: { pageSize: paginationModel.limit } },
            }}
            columnVisibilityModel={columnVisibilityModel}
            getRowId={(row) => `${row.entityId}`}
            rows={data?.data || []}
            rowCount={(data && data.total_count) || 0}
            rowsPerPageOptions={[25, 50, 100]}
            columns={columns}
          />
        </Box>
        )}
      </Box>
        
    </Box>
  );
};

export default BadgeHandling;