import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, FormControlLabel, IconButton, InputAdornment, ListItemIcon, MenuItem, Paper, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {  Badge, Close, HelpOutline, Save } from "@mui/icons-material";
import { CssInputLabel, CssSelect, CssTextField } from "components/StyledComponents";
import IOSSwitch from "components/IOSSwitch";
import {  getExternalDevice, putExternalDevice } from "hooks/HttpRequests";
import * as yup from "yup";
import { Formik } from "formik";
import { Oval } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import tm from 'components/TranslationManager';
import { enqueueSnackbar } from "notistack";


const StackerHostAuthConfig = ({id}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const { palette } = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const initialValuesRegister = {
    active: true,
    card_count: 130,
    access_system_wait_time: 30,
    card_type: "mifare_1k",
    sector: "0",
    read_format: "hex",
    card_processor_active: false,
    card_processor: "",
    serial_number: "",
    websocket_ip: "",
    auth_type: "email_password"
  };

  const [loadedFormData, setLoadedFormData] = useState({ initialValuesRegister });
  const [entireConfigData, setEntireConfigData] = useState({ });
  const [entityData, setEntityData] = useState({ });

  const [loading, setLoading] = useState(false);
  const [showConf, setShowConf] = useState(false);


  useEffect(() => {
    if (showConf) {
      loadData();
    }
  }, [showConf]);

  const registerSchema = yup.object().shape({
    // usersToNotify: yup.string().required("required"),
  });

  // Device type options with associated images
  const CARD_TYPES = [
    { label: "Mifare 1k - dispenser", value: "mifare_1k" },
    { label: "Sadevio Reader Stacker", value: "rf_ideas_keystroke" },
    { label: "Sadevio Reader External", value: "rf_ideas_keystroke_external" },
    { label: "WebSocket Internal Reader", value: "web_socket" },
    { label: "WebSocket External Reader", value: "web_socket_external"},
  ];

  const READ_FORMAT = [
    { label: "HEX", value: "hex" },
    { label: "ASCII", value: "ascii" },
    { label: "OCTAL", value: "oct" },
  ];

  const AUTH_TYPE = [
    { label: "Email and password", value: "email_password" },
    { label: "Tmp password", value: "tmp_password" },
    { label: "Tmp password & Identification Document", value: "tmp_password_id" },
    { label: "2-Step Verification", value: "2fa" },
  ];

  const toggleShowConf = () => {
    setShowConf(!showConf);
  };

  const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const loadData = async () => {
    setLoading(true);

    getExternalDevice(navigate)(id)
      .then((result) => {
        if (result.success) {
          if(result.data.deviceLocalConfig != ""){

            // Parse the string into an object
            let deviceConfig = {};
            try {
              deviceConfig = JSON.parse(result.data.deviceLocalConfig);
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
            if(typeof deviceConfig.hostAuthDispenser != 'undefined'){
              setLoadedFormData(deviceConfig.hostAuthDispenser)
            }
            setEntityData(result.data)
            
            setEntireConfigData(deviceConfig)
          }
        }
        setLoading(false);

      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleFormSubmit = (values) => {

    var submitData = {};
    entireConfigData.hostAuthDispenser = values;
    const deviceLocalConfigString = JSON.stringify(entireConfigData);
    entityData.deviceLocalConfig = deviceLocalConfigString;

    entityData["frontDeskEntityId"] = entityData.frontDesk.entityId
    delete entityData.frontDesk

    submitData["data"] = entityData;

    dispatch(showLoading(""))

    putExternalDevice(navigate)(submitData)
      .then((result) => {
      // Use the result.data as needed
      if (result.success) {
      
          if(result.success){
              //   setCopmanyName("- "+result.data.name);
              // setLoadedFormData(result.data)

              enqueueSnackbar({
                  message: "Entity was saved",
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                    },
                    autoHideDuration: 3000, 
                    // persist: true
                  
                });
                loadData();
          }else{
              enqueueSnackbar({
                  message: "Error "+result.message,
                      variant: 'error',
                      anchorOrigin: {
                      vertical: 'top', // 'top', 'bottom'
                      horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                  
                  });
          }
          dispatch(hideLoading());

      }
      })
      .catch((error) => {
      dispatch(hideLoading());

      if (typeof error.action !== "undefined") {
          if (error.action === "relogin") {
          navigate("/");
          }
      }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
          message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
              vertical: 'top', // 'top', 'bottom'
              horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
          
          });
      }

      });
  };

  return (
    <Box sx={{ ...commonStyles, border: 1 }}>
      <Box display="flex" alignItems="center">
        <ListItemIcon>
          <Badge fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem" }} />
        </ListItemIcon>
        <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem" }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h3" color={theme.palette.secondary[100]} fontWeight="bold">
                Card Stacker - Host Authentication
            </Typography>
          </Box>
          <Typography variant="h5" color={theme.palette.secondary[300]}>
            Configure all needed application modes
          </Typography>
        </Box>
        <Box ml="auto">
          {!showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              EDIT
            </Button>
          )}
          {showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              <Close />
            </Button>
          )}
        </Box>
      </Box>

      {showConf && (
        <Box>
          {loading ? (
            <Box sx={{ width: "100%", height: "100%", padding: "20px", display: "flex", justifyContent: "center" }}>
            <Oval
                visible={true}
                height="80"
                width="80"
                color="#ffedc2"
                secondaryColor="#ffedc2"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Box>
          ) : (
            <Box sx={{ padding: "20px"}}>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={loadedFormData || initialValuesRegister}
                validationSchema={registerSchema}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Paper
                      elevation={3}
                      style={{
                        padding: 20,
                        backgroundColor: theme.palette.background.alt,
                        backgroundImage: "none",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={2} // Ensures space between switches
                        sx={{ width: "100%" }} // Full width for mobile
                      >
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.active}
                              name="active"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>Active</span>}
                        />

                        <CssTextField
                            label="Serial Number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.serial_number}
                            name="serial_number"
                            error={Boolean(touched.serial_number) && Boolean(errors.serial_number)}
                            helperText={touched.serial_number && errors.serial_number}
                            sx={{ mb: 2, gridColumn: "span 4" }}
                            theme={theme}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip  title={
                                      <Typography variant="body1" style={{ fontSize: '16px' }}>
                                        Serial Number
                                      </Typography>
                                  }>
                                    <IconButton>
                                      <HelpOutline />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />

                        <CssTextField
                            label="Card Count"
                            onBlur={handleBlur}
                            type= "number"
                            min
                            onChange={handleChange}
                            value={values.card_count}
                            name="card_count"
                            error={Boolean(touched.card_count) && Boolean(errors.card_count)}
                            helperText={touched.card_count && errors.card_count}
                            sx={{ mb: 2, gridColumn: "span 4" }}
                            theme={theme}
                            InputProps={{
                              min: 0,
                              max: 255,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip  title={
                                      <Typography variant="body1" style={{ fontSize: '16px' }}>
                                        Card Count
                                      </Typography>
                                  }>
                                    <IconButton>
                                      <HelpOutline />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />

                        <CssTextField
                            label="Access System Wait Time"
                            onBlur={handleBlur}
                            type= "number"
                            min
                            onChange={handleChange}
                            value={values.access_system_wait_time}
                            name="access_system_wait_time"
                            error={Boolean(touched.access_system_wait_time) && Boolean(errors.access_system_wait_time)}
                            helperText={touched.access_system_wait_time && errors.access_system_wait_time}
                            sx={{ mb: 2, gridColumn: "span 4" }}
                            theme={theme}
                            InputProps={{
                              min: 0,
                              max: 30,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip  title={
                                      <Typography variant="body1" style={{ fontSize: '16px' }}>
                                        Access System Wait Time
                                      </Typography>
                                  }>
                                    <IconButton>
                                      <HelpOutline />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />

                        <CssTextField
                            label="Card Type"
                            onBlur={handleBlur}
                            min
                            onChange={handleChange}
                            value={values.card_type}
                            name="card_type"
                            error={Boolean(touched.card_type) && Boolean(errors.card_type)}
                            helperText={touched.card_type && errors.card_type}
                            sx={{ mb: 2, gridColumn: "span 4" }}
                            theme={theme}
                            InputProps={{
                              min: 0,
                              max: 30,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip  title={
                                      <Typography variant="body1" style={{ fontSize: '16px' }}>
                                        Card Type
                                      </Typography>
                                  }>
                                    <IconButton>
                                      <HelpOutline />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />

                          <FormControl fullWidth sx={{
                            width: "100%",
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: theme.palette.secondary[300],
                            },
                            "&:hover .MuiInputLabel-root": {
                              color: theme.palette.secondary[300],
                            },
                          }}>
                          <CssInputLabel id="demo-multiple-checkbox-label" theme={theme}>{tm.translate('dspDeviceType', 'Device Type')}</CssInputLabel>
                          <CssSelect id="demo-multiple-checkbox-label" name="card_type"label={tm.translate('dspDeviceType', 'Device Type')} value={values.card_type} onChange={handleChange} onBlur={handleBlur} theme={theme}>
                            {CARD_TYPES.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </CssSelect>
                        </FormControl>

                          <CssTextField
                            label="Sector"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.sector}
                            name="sector"
                            error={Boolean(touched.sector) && Boolean(errors.sector)}
                            helperText={touched.sector && errors.sector}
                            sx={{ mb: 2, gridColumn: "span 4" }}
                            theme={theme}
                            InputProps={{
                              min: 0,
                              max: 30,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip  title={
                                      <Typography variant="body1" style={{ fontSize: '16px' }}>
                                        Sector
                                      </Typography>
                                  }>
                                    <IconButton>
                                      <HelpOutline />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />
                        <FormControl fullWidth sx={{
                            width: "100%",
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: theme.palette.secondary[300],
                            },
                            "&:hover .MuiInputLabel-root": {
                              color: theme.palette.secondary[300],
                            },
                          }}>
                          <CssInputLabel id="multiple-checkbox-label-read-format" theme={theme}>{tm.translate('dspReadFormat', 'Read Format')}</CssInputLabel>
                          <CssSelect id="multiple-checkbox-label-read-format" name="read_format"label={tm.translate('dspReadFormat', 'Read Format')} value={values.read_format} onChange={handleChange} onBlur={handleBlur} theme={theme}>
                            {READ_FORMAT.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </CssSelect>
                        </FormControl>

                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={values.card_processor_active}
                              name="card_processor_active"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }
                          label={<span style={{ color: theme.palette.secondary.light }}>Card Processor Active</span>}
                        />

                        <CssTextField
                            label="Card Processor"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.card_processor}
                            name="card_processor"
                            multiline
                            rows={4}
                            error={Boolean(touched.card_processor) && Boolean(errors.card_processor)}
                            helperText={touched.card_processor && errors.card_processor}
                            sx={{ mb: 2, gridColumn: "span 4" }}
                            theme={theme}
                            InputProps={{
                              min: 0,
                              max: 30,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip  title={
                                      <Typography variant="body1" style={{ fontSize: '16px' }}>
                                        Card Processor
                                      </Typography>
                                  }>
                                    <IconButton>
                                      <HelpOutline />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />

                          <CssTextField
                            label="Websocket IP"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.websocket_ip}
                            name="websocket_ip"
                            error={Boolean(touched.websocket_ip) && Boolean(errors.websocket_ip)}
                            helperText={touched.websocket_ip && errors.websocket_ip}
                            sx={{ mb: 2, gridColumn: "span 4" }}
                            theme={theme}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip  title={
                                      <Typography variant="body1" style={{ fontSize: '16px' }}>
                                        Websocket IP
                                      </Typography>
                                  }>
                                    <IconButton>
                                      <HelpOutline />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />
                        <FormControl fullWidth sx={{
                            width: "100%",
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: theme.palette.secondary[300],
                            },
                            "&:hover .MuiInputLabel-root": {
                              color: theme.palette.secondary[300],
                            },
                          }}>
                          <CssInputLabel id="multiple-checkbox-label-auth-type" theme={theme}>{tm.translate('dspAuthType', 'Auth Type')}</CssInputLabel>
                          <CssSelect id="multiple-checkbox-label-auth-type" name="auth_type"label={tm.translate('dspAuthType', 'Auth Type')} value={values.auth_type} onChange={handleChange} onBlur={handleBlur} theme={theme}>
                            {AUTH_TYPE.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </CssSelect>
                        </FormControl>

                        
                      </Box>
                    </Paper>
                    
                        <Button
                          startIcon={<Save />}
                          type="submit"
                          sx={{
                            p: "1rem",
                            m: "1rem 0rem 0rem 0rem",
                            backgroundColor: palette.secondary.light,
                            color: palette.background.alt,
                            fontWeight: "bold",
                            "&:hover": {
                              color: palette.primary.main,
                              "&:hover": { color: theme.palette.secondary.dark },
                              fontWeight: "bold",
                            },
                          }}
                        >
                          Save
                        </Button>
                  </form>
                )}
              </Formik>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default StackerHostAuthConfig;
