import React, { useEffect, useState } from "react";
import {  Box, Drawer,  Link, useTheme } from "@mui/material";
import Header from "components/Header";
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import DialogYesNo from "components/dialog/DialogYesNo";
import { deleteCompany, getListValuesPaging, } from "../../hooks/HttpRequests";
import {  useNavigate } from "react-router-dom";
import tm from "components/TranslationManager";
import dayjs from "dayjs";
import CompanyNew from "./companyNew";
import GridCellActions from "components/GridCellActions";
import { enqueueSnackbar } from "notistack";


const Companies = ({ route }) => {

  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [yesNoMessage, setYesNoMessage] = useState("");
  const [yesNoDialogEntity, setYesNoDialogEntity] = useState({});


  const [topSearchFilter, setTopSearchFilter] = useState("");

  const [countCheckedIn, setCountCheckedIn] = useState("0");
  const [countCheckedOut, setCountCheckedOut] = useState("0");
  const [countWaiting, setCountWaiting] = useState("0");
  const [countInvited, setCountInvited] = useState("0");
  const [countConfirmed, setCountConfirmed] = useState("0");

  const defaultVisitorType = { label: 'All Visitor Types', value: "all" };
  const defaultVisitStatus = { label: 'All', value: "all" };


  const theme = useTheme();

  /////////////// GRID /////////////
  // values to be sent to the backend

  const [data, setData] = useState({ data: [], total_count: 0 });
  const [gridLoading, setGridLoading] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });

  const [search, setSearch] = useState("");

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [],
    query:[]
  })

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton color="neutral" />
        <GridToolbarFilterButton  style={{ color: '#3f51b5' }}  />
      </GridToolbarContainer>
    );
  }

  
    useEffect(() => {
      // This code will run every time paginationModel changes
      loadGridData("");

    }, [paginationModel]);

    useEffect(() => {
      // This code will run every time paginationModel changes
      loadGridData("");

    }, [triggerRefresh]);

    useEffect(() => {

      if(route == "new"){
        openDrawer();
      }else if(route == "details"){
        openDrawer();
      }

    }, [route]); 

  const handleFilterModelChange = (filterModel) => {
    // Update the filter model state when it changes

    console.log("handleFilterModelChange()")

    // Convert to the desired structure
    const desiredStructure = filterModel.items.map(item => {
      let mappedOperator = item.operator;
    
      let field = columns.find(column => column.field === item.field);
      var fieldType = "string";

      if(typeof field.type !== 'undefined'){
        fieldType = field.type;
      }

      // Map operators as needed
      if (item.operator === 'equals') {
        mappedOperator = 'eq';
      } else if (item.operator === 'contains') {
        mappedOperator = 'like';
      } // Add more conditions as needed
    
      return {
        property: item.field,
        value: item.value,
        type: fieldType, // You might need to adjust this based on your data types
        operator: mappedOperator
      };
    });

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      query: desiredStructure,
      page: 0,
    }));

  };

  const handleSortModelChange = (sortModel) => {
    // Handle sorting changes and fetch data from the server
    //sort: [{"property":"lastname","direction":"ASC"}]

    console.log("handleSortModelChange()")

    const sortObject = sortModel.map(item => ({
      property: item.field,
      direction: item.sort.toUpperCase() // Assuming you want direction in uppercase (ASC or DESC)
    }));

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      sort: sortObject,
      page: 0,
    }));

      console.log(paginationModel)
  };

  const handlePageChange = (newPage) => {
    // Handle page changes and fetch data from the server

    console.log("handlePageChange()")

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPage.page,
      limit: newPage.pageSize,
    }));

  };

  const loadGridData = async (localExtraFilter) => {

    setGridLoading(true)

    getListValuesPaging(navigate)(paginationModel, "company", localExtraFilter)
      .then((result) => {

        setData(result)
        setGridLoading(false)

      })
      .catch((error) => {

        console.log(error);

        setGridLoading(false)
      });

  }

  const handleExecute = async (values) => {


    setConfirmationOpen(false)

    dispatch(showLoading(""))

      deleteCompany(navigate)(values.entityId)
        .then((result) => {
        // Use the result.data as needed      
        dispatch(hideLoading());
            if(result.success){
                
              enqueueSnackbar({
                message: "Was deleted successfully",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

              setTriggerRefresh(!triggerRefresh);

            }else{
              enqueueSnackbar({
                message: "Something went wrong during request",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
            }


      })
      .catch((error) => {
        dispatch(hideLoading());

        if (typeof error.action !== "undefined") {
          if (error.action == "relogin") {
            navigate("/");
          }
        }else if (error.hasOwnProperty('success')) {
          enqueueSnackbar({
            message: "Error "+error.message,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
              },
              autoHideDuration: 3000, 
              // persist: true
            
          });
        }

      });

      

  };
  
  const handleOptionCellActionClick = (id, action, row) => {
    console.log(action)

    if(action == "edit"){

      navigate(`/identity/company/${id}`)

    }else if(action == "delete"){


        setConfirmationOpen(true);
        setYesNoMessage("Do you really want to delete this company?")
        row["action"] = "delete";
        setYesNoDialogEntity(row)
    }

}

  const columns = [
    
    {
      field: "entityId",
      headerName: "Entity Id",
      flex: 1,
      type: 'number'
    },
    {
      field: 'picturePath',
      headerName: 'Logo',
      width: 100,
      
    },    
    {
      field: "status",
      headerName: tm.translate('clmCompanyStatus', "Status") ,
      flex: "auto",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ cursor: 'pointer', fontWeight: "bold", fontSize: '18px' }}
          onClick={() => {
            navigate(`/identity/company/${params.row.entityId}`)
          }}
        >
        {params.row.name}
      </Link>
      ),
    },
    {
      field: "validFrom",
      headerName: tm.translate('clmValidFrom', "Valid From") ,
      flex: "auto",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {

        if(params.value == null){
          return ""
        }
        const utcDateTime = params.value; // Assuming params.value contains the UTC timestamp
        const localDateTime = dayjs.utc(utcDateTime).local().format('DD.MM.YYYY HH:mm'); // Convert UTC to local and format
        return <span>{localDateTime}</span>;
      }
    },
    {
      field: "validTo",
      headerName:  tm.translate('clmValidTo', "Valid To") ,
      flex: "auto",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {

        if(params.value == null){
          return ""
        }
        const utcDateTime = params.value; // Assuming params.value contains the UTC timestamp
        const localDateTime = dayjs.utc(utcDateTime).local().format('DD.MM.YYYY HH:mm'); // Convert UTC to local and format
        return <span>{localDateTime}</span>;
      }
    },
    {
      field: "category",
      flex: 1,
      headerName: tm.translate('clmCategory', "Category"),
    },
    {
      field: 'actions',
      headerName: 'Actions',
       // flex: 1,
      width: 80,
      sortable: false,
      renderCell: (params) => (
        <GridCellActions
          id={params.row.entityId}
          row={params.row}
          onOptionClick={handleOptionCellActionClick}
        />
      ),
    },
  ];

  const handleSearchInputChange = (e) => {

    // Update the state based on the previous statehandleSearchInputChange
    setTopSearchFilter(e.target.value);
    // // Perform other actions if needed
    // loadGridData("&extraFilter=" + e.target.value);
    if(e.target.value.length > 0){
      loadGridData("&extraFilter=" + topSearchFilter)
    }else{
      loadGridData("")
    }
  };

  const btnNewVisit = tm.translate('btnNewCompany', 'New Company');
  
  const buttons = [
    { type:'search',label: "Search companies..." , onChange: handleSearchInputChange, value:topSearchFilter, iconName: ''},
    { type:'button',label: btnNewVisit , onClick: () => { console.log("open"); 
              // openDrawer()
              navigate("/identity/company/new") }, iconName: ''},

  ];

  const [drawerOpen, setDrawerOpen] = useState(false);

  // Function to handle opening the Drawer
  const openDrawer = () => {
    setDrawerOpen(true);
  };

  // Function to handle closing the Drawer
  const closeDrawer = () => {
    setDrawerOpen(false);
    navigate("/identity/company")
  };

  return (
    <Box m="1rem 2rem">
      <Header title="Companies" subtitle="List of companies" elements={buttons} />
      <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleExecute}
        theme={theme}
        message={yesNoMessage}
        entity={yesNoDialogEntity}
      />
      {/* </Box> */}
      <Box
        mt="0px"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiDataGrid-panel .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
         "& .MuiDataGrid-panelWrapper .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .Mui-checked.MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiLink-root.MuiLink-underlineAlways": {
            color: `#ffffff!important`,
          },
          
          
          
        }}
      >
        <DataGridPro
          loading={gridLoading || !data}
          initialState={{
            pagination: { paginationModel: { pageSize: paginationModel.limit } },
          }}
          // getRowId={(row) => row.entityId}
          getRowId={(row) => `${row.entityId}`}
          rows={data?.data || []}
          columns={columns}
          rowCount={(data && data.total_count) || 0}
          rowsPerPageOptions={[25, 50, 100]}
          columnVisibilityModel={columnVisibilityModel}
          // onColumnVisibilityChange={handleColumnVisibilityChange}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }

          checkboxSelection

          filterMode="server"
          onFilterModelChange={handleFilterModelChange}

          sortingMode="server"
          onSortModelChange={handleSortModelChange}

          paginationMode="server"
          pagination
          page={paginationModel.page}
          pageSize={paginationModel.limit}
          onPaginationModelChange={(paginationModel) => handlePageChange(paginationModel)} //setPageSize(paginationModel.pageSize)

          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            filterPanel: {
              // Force usage of "And" operator
              logicOperators: [],
              // Display columns by ascending alphabetical order
              columnsSort: 'asc',
              
              sx: {
                '& .MuiButtonBase-root': { color: `${theme.palette.secondary[200]} !important` },
                '& .MuiFormLabel-root': { color: `${theme.palette.secondary[200]} !important` },
              },
            },
          }}
        />
      </Box>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={closeDrawer}
        // Adjust the width based on your requirement (2/3 of the space)
        sx={{   
          width: '66vw', 
          flexShrink: 0,
        }}
        PaperProps={{
          sx: {
            backgroundColor: `${theme.palette.primary[500]} !important`,
            backgroundImage: "none",
            borderLeft: 1
            // Other Paper styles if needed
          },
        }}
        
        variant="temporary"
      >
        <CompanyNew />
        {/* Content of the Drawer */}
        {/* Add your detailed/edit view components here */}
        {/* For example: <EditForm /> */}
      </Drawer>
    </Box>
  );
};

export default Companies;