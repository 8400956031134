import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, FormControl, FormControlLabel, IconButton, InputAdornment, ListItemIcon, MenuItem, Paper, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Apps, CameraAlt, Close, HelpOutline, LockClock, Print, Save } from "@mui/icons-material";
import { CssInputLabel, CssSelect, CssTextField } from "components/StyledComponents";
import IOSSwitch from "components/IOSSwitch";
import {  getDataPrivacyConfig, getExternalDevice, putDataPrivacyConfig, putExternalDevice } from "hooks/HttpRequests";
import * as yup from "yup";
import { Formik } from "formik";
import { Oval } from "react-loader-spinner";
import { useFrontDeskManager } from "components/FrontDeskManager";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import { useIntegrationConfig } from "components/IntegrationConfigManager";
import tm from 'components/TranslationManager';
import { enqueueSnackbar } from "notistack";


const PrinterSettingsConfig = ({id}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const { palette } = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const initialValuesRegister = {
    active: false,
    connection_type: "USB",
    ip_address: "",
    printer_model: "QL_820NWB",
    printer_label: "W60H86",
    mac_address: ""
  };

  const [loadedFormData, setLoadedFormData] = useState({ initialValuesRegister });
  const [entireConfigData, setEntireConfigData] = useState({ });
  const [entityData, setEntityData] = useState({ });

  const [loading, setLoading] = useState(false);
  const [showConf, setShowConf] = useState(false);


  useEffect(() => {
    if (showConf) {
      loadData();
    }
  }, [showConf]);

  const registerSchema = yup.object().shape({
    // usersToNotify: yup.string().required("required"),
  });

  const PRINTER_TYPES = [
    { label: "Brother - QL-720NW", value: "QL_720NW" },
    { label: "Brother - QL-820NWB", value: "QL_820NWB" },
    { label: "Brother - QL-800", value: "QL_800" },
  ];

  const PRINTER_LABEL = [
    { label: "W60H86", value: "W60H86" },
    { label: "W29H90", value: "W29H90" },
    { label: "W62", value: "W62" },
    { label: "W62RB", value: "W62RB" },
    { label: "W50", value: "W50" },
  ];

  const PRINTER_CONNECTION_TYPE = [
    { label: "USB", value: "USB" },
    { label: "NETWORK", value: "NETWORK" },
    { label: "BLUETOOTH", value: "BLUETOOTH" },
  ];



  const toggleShowConf = () => {
    setShowConf(!showConf);
  };

  const commonStyles = {
    bgcolor: theme.palette.primary[600],
    m: "1rem 0rem 1rem 0rem",
    borderRadius: 1,
    borderColor: theme.palette.secondary[100],
  };

  const loadData = async () => {
      setLoading(true);
  
      getExternalDevice(navigate)(id)
        .then((result) => {
          if (result.success) {
            if(result.data.deviceLocalConfig != ""){
  
              // Parse the string into an object
              let deviceConfig = {};
              try {
                deviceConfig = JSON.parse(result.data.deviceLocalConfig);
              } catch (error) {
                console.error("Error parsing JSON:", error);
              }
              if(typeof deviceConfig.printerSettings != 'undefined'){
                setLoadedFormData(deviceConfig.printerSettings)
              }
              setEntityData(result.data)
              
              setEntireConfigData(deviceConfig)
            }
          }
          setLoading(false);
  
        })
        .catch((error) => {
          setLoading(false);
        });
    };
  
    const handleFormSubmit = (values) => {
  
      var submitData = {};
      entireConfigData.printerSettings = values;
      const deviceLocalConfigString = JSON.stringify(entireConfigData);
      entityData.deviceLocalConfig = deviceLocalConfigString;
  
      entityData["frontDeskEntityId"] = entityData.frontDesk.entityId
      delete entityData.frontDesk
  
      submitData["data"] = entityData;
  
      dispatch(showLoading(""))
  
      putExternalDevice(navigate)(submitData)
        .then((result) => {
        // Use the result.data as needed
        if (result.success) {
        
            if(result.success){
                //   setCopmanyName("- "+result.data.name);
                // setLoadedFormData(result.data)
  
                enqueueSnackbar({
                    message: "Entity was saved",
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                      },
                      autoHideDuration: 3000, 
                      // persist: true
                    
                  });
                  loadData();
            }else{
                enqueueSnackbar({
                    message: "Error "+result.message,
                        variant: 'error',
                        anchorOrigin: {
                        vertical: 'top', // 'top', 'bottom'
                        horizontal: 'center', // 'left', 'center', 'right'
                        },
                        autoHideDuration: 3000, 
                        // persist: true
                    
                    });
            }
            dispatch(hideLoading());
  
        }
        })
        .catch((error) => {
        dispatch(hideLoading());
  
        if (typeof error.action !== "undefined") {
            if (error.action === "relogin") {
            navigate("/");
            }
        }else if (error.hasOwnProperty('success')) {
            enqueueSnackbar({
            message: "Error "+error.message,
                variant: 'error',
                anchorOrigin: {
                vertical: 'top', // 'top', 'bottom'
                horizontal: 'center', // 'left', 'center', 'right'
                },
                autoHideDuration: 3000, 
                // persist: true
            
            });
        }
  
        });
    };

  return (
    <Box sx={{ ...commonStyles, border: 1 }}>
      <Box display="flex" alignItems="center">
        <ListItemIcon>
          <Print fontSize="large" sx={{ m: "1.5rem 0rem 1.5rem 1.5rem" }} />
        </ListItemIcon>
        <Box sx={{ m: "1.5rem 1.5rem 1.5rem 1.5rem" }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h3" color={theme.palette.secondary[100]} fontWeight="bold">
                Printer Settings
            </Typography>
          </Box>
          <Typography variant="h5" color={theme.palette.secondary[300]}>
            Configure all printer settings
          </Typography>
        </Box>
        <Box ml="auto">
          {!showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              EDIT
            </Button>
          )}
          {showConf && (
            <Button
              variant="contained"
              onClick={toggleShowConf}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                m: "0rem 1rem 0rem 0rem",
                "&:hover": { color: theme.palette.secondary.dark },
              }}
            >
              <Close />
            </Button>
          )}
        </Box>
      </Box>

      {showConf && (
        <Box>
          {loading ? (
            <Box sx={{ width: "100%", height: "100%", padding: "20px", display: "flex", justifyContent: "center" }}>
            <Oval
                visible={true}
                height="80"
                width="80"
                color="#ffedc2"
                secondaryColor="#ffedc2"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Box>
          ) : (
            <Box sx={{ padding: "20px"}}>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={loadedFormData || initialValuesRegister}
                validationSchema={registerSchema}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Paper
                      elevation={3}
                      style={{
                        padding: 20,
                        backgroundColor: theme.palette.background.alt,
                        backgroundImage: "none",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={2} // Ensures space between switches
                        sx={{ width: "100%" }} // Full width for mobile
                      >
                      <FormControlLabel
                        sx={{ mb: 2, gridColumn: "span 4" }}
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={values.active}
                            name="active"
                            onBlur={handleBlur}
                            onChange={handleChange}

                          />
                        }
                        label={<span style={{ color: theme.palette.secondary.light }}>Active</span>}
                      />

                      <FormControl fullWidth sx={{
                        width: "100%",
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: theme.palette.secondary[300],
                        },
                        "&:hover .MuiInputLabel-root": {
                          color: theme.palette.secondary[300],
                        },
                      }}>
                      <CssInputLabel id="multiple-checkbox-label-printer-model" theme={theme}>{tm.translate('dspPrinterModel', 'Printer Model')}</CssInputLabel>
                      <CssSelect id="multiple-checkbox-label-printer-model" name="printer_model"label={tm.translate('dspPrinterModel', 'Printer Model')} value={values.printer_model} onChange={handleChange} onBlur={handleBlur} theme={theme}>
                        {PRINTER_TYPES.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </CssSelect>
                    </FormControl>

                    <FormControl fullWidth sx={{
                        width: "100%",
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: theme.palette.secondary[300],
                        },
                        "&:hover .MuiInputLabel-root": {
                          color: theme.palette.secondary[300],
                        },
                      }}>
                      <CssInputLabel id="multiple-checkbox-label-printer-label" theme={theme}>{tm.translate('dspPrinterLabel', 'Printer Label')}</CssInputLabel>
                      <CssSelect id="multiple-checkbox-label-printer-label" name="printer_label"label={tm.translate('dspPrinterLabel', 'Printer Label')} value={values.printer_label} onChange={handleChange} onBlur={handleBlur} theme={theme}>
                        {PRINTER_LABEL.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </CssSelect>
                    </FormControl>

                    <FormControl fullWidth sx={{
                        width: "100%",
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: theme.palette.secondary[300],
                        },
                        "&:hover .MuiInputLabel-root": {
                          color: theme.palette.secondary[300],
                        },
                      }}>
                      <CssInputLabel id="multiple-checkbox-label-connection-type" theme={theme}>{tm.translate('dspConnectionType', 'Connection Type')}</CssInputLabel>
                      <CssSelect id="multiple-checkbox-label-connection-type" name="connection_type"label={tm.translate('dspConnectionType', 'Connection Type')} value={values.connection_type} onChange={handleChange} onBlur={handleBlur} theme={theme}>
                        {PRINTER_CONNECTION_TYPE.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </CssSelect>
                    </FormControl>

                    {values.connection_type === "NETWORK" && (
                      <CssTextField
                        label="IP Address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ip_address}
                        name="ip_address"
                        error={Boolean(touched.ip_address) && Boolean(errors.ip_address)}
                        helperText={touched.ip_address && errors.ip_address}
                        sx={{ mb: 2, gridColumn: "span 4" }}
                        theme={theme}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title={<Typography variant="body1" style={{ fontSize: '16px' }}>IP Address of the printer</Typography>}>
                                <IconButton>
                                  <HelpOutline />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                    )}
                
                        {values.connection_type === "BLUETOOTH" && (
                          <CssTextField
                            label="Mac Address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.mac_address}
                            name="mac_address"
                            error={Boolean(touched.mac_address) && Boolean(errors.mac_address)}
                            helperText={touched.mac_address && errors.mac_address}
                            sx={{ mb: 2, gridColumn: "span 4" }}
                            theme={theme}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title={<Typography variant="body1" style={{ fontSize: '16px' }}>Bluetooth MAC Address of printer</Typography>}>
                                    <IconButton>
                                      <HelpOutline />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />
                        )}
                      </Box>
                    </Paper>
                    
                        <Button
                          startIcon={<Save />}
                          type="submit"
                          sx={{
                            p: "1rem",
                            m: "1rem 0rem 0rem 0rem",
                            backgroundColor: palette.secondary.light,
                            color: palette.background.alt,
                            fontWeight: "bold",
                            "&:hover": {
                              color: palette.primary.main,
                              "&:hover": { color: theme.palette.secondary.dark },
                              fontWeight: "bold",
                            },
                          }}
                        >
                          Save
                        </Button>
                  </form>
                )}
              </Formik>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default PrinterSettingsConfig;
