import React, { useEffect, useState } from "react";
import {
  Menu as MenuIcon,
  ArrowDropDownOutlined,
  AccountCircle,
  HelpOutline,
  NotificationsActive,
  Campaign,
  Send,

} from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import { useDispatch } from "react-redux";
// import profileImage from "assets/profile.jpeg";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
  ListItemIcon,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import { setLogout } from "state/auth";
import { useFrontDeskManager } from "./FrontDeskManager";
import { useUserProfileManager } from "./UserProfileProvider";
import { getEmergencyListConfig, getEmergencyListTrigger, getSessionToken, getSilentAlarmConfig, getSilentAlarmExecuteTrigger, getUri, setSessionToken } from "hooks/HttpRequests";
import { useIntegrationConfig } from "./IntegrationConfigManager";
import { enqueueSnackbar } from "notistack";

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const [profileImage, setPofileImage] = useState("icons/images/user-profile/no_picture.png");


  const { selectedFrontDesk, frontDeskLoading } = useFrontDeskManager();
  const { userProfile, userProfleLoading, hasPermission } = useUserProfileManager();

  const { showSilentAlarm, setShowSilentAlarm, showEmergencyList, setShowEmergencyList, showVisitorNotification, setShowVisitorNotification  } = useIntegrationConfig();


  useEffect(() => {
    if (!userProfleLoading) {

        if(userProfile != null && userProfile.session.user.picturePath != null && userProfile.session.user.picturePath != ''){
            setPofileImage(`${getUri(getSessionToken())}/host/${userProfile.session.user.userId}/picture/${userProfile.session.user.picturePath}`);
        }

    }
    }, [userProfile]);

    useEffect(() => {
      
      if(hasPermission("system.emergency.list")){
        if(isNonMobile){
          loadEmergencyListConfig();
        }
      }else{
        setShowEmergencyList(false)
      }
      if(hasPermission("system.silentalarm")){
        if(isNonMobile){
          loadSilentAlarmConfig();
        }
      }else{
        setShowSilentAlarm(false)
      }

      if(hasPermission("system.visitor.notifications")){
        if(isNonMobile){
          setShowVisitorNotification(true)
        }
      }else{
        setShowVisitorNotification(false)
      }

      
      }, []);

    const loadSilentAlarmConfig = async () => {
    
      if(typeof selectedFrontDesk !== "undefined" && selectedFrontDesk !== null && selectedFrontDesk.entityId != null ){
        getSilentAlarmConfig(navigate)(selectedFrontDesk.entityId)
        .then((result) => {
          if (result.success) {
              if(result.data.active && isNonMobile){
                setShowSilentAlarm(true);
              }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      }
        
    };
  
    const loadEmergencyListConfig = async () => {
    
      if(typeof selectedFrontDesk !== "undefined" && selectedFrontDesk !== null && selectedFrontDesk.entityId != null ){
        getEmergencyListConfig(navigate)(selectedFrontDesk.entityId)
          .then((result) => {
            if (result.success) {
                if(result.data.active && isNonMobile){
                  setShowEmergencyList(true);
                }
            }
          })
          .catch((error) => {
            console.log(error);
          });
        }
    };

  const handleLogout = async (values, onSubmitProps) => {

    setSessionToken("");
    dispatch(
      setLogout()
    );
    navigate("/");
  };

  

  const onSilentAlarmClicked = async () => {

    getSilentAlarmExecuteTrigger(navigate)(selectedFrontDesk.entityId)
            .then((result) => {


            if(result.success){
              enqueueSnackbar({
                message: "Alarm was executed",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

                
            }else{

              enqueueSnackbar({
                message: "Something went wrong during execution",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

            }


            })
            .catch((error) => {
              console.log(error)
              enqueueSnackbar({
                message: "Something went wrong during execution",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
        });

  }

  const onEmergencyListClicked = async () => {

    getEmergencyListTrigger(navigate)(selectedFrontDesk.entityId)
            .then((result) => {


            if(result.success){
              enqueueSnackbar({
                message: "Emergency list was executed",
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

              navigate(`/emergencylist/${result.data.entityId}`)

                
            }else{

              enqueueSnackbar({
                message: "Something went wrong during execution",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });

            }


            })
            .catch((error) => {
              console.log(error)
              enqueueSnackbar({
                message: "Something went wrong during execution",
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top', // 'top', 'bottom'
                    horizontal: 'center', // 'left', 'center', 'right'
                  },
                  autoHideDuration: 3000, 
                  // persist: true
                
              });
        });

  }

  const openHelpPage = () => {
    window.open("https://help.sadevio.com", "_blank", "noopener,noreferrer");
  };

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
          {/* <FlexBetween
            backgroundColor={theme.palette.background.alt}
            borderRadius="9px"
            gap="3rem"
            p="0.1rem 1.5rem"
          >
            <InputBase placeholder="Search..." />
            <IconButton>
              <Search />
            </IconButton>
          </FlexBetween> */}
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">



          {/* <IconButton onClick={() => dispatch(setMode())}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton> */}
      <Box display="flex" flexDirection={isNonMobile ? "row" : "column"} gap={2} 
              sx={{
                margin: isNonMobile ? "0px" : "0px 0px 10px 0px",
                }}
            >
          {showEmergencyList &&(
          <Button
              variant="contained"
              onClick={onEmergencyListClicked}
              startIcon={<NotificationsActive />}
              sx={{
              backgroundColor: theme.palette.background.alt,
              flex: isNonMobile ? "1" : "unset",
              color: theme.palette.secondary.light,
              minWidth:  "250px",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "5px 0px",
              margin: isNonMobile ? "0px" : "10px 0px 0px 0px",
              "&:hover": { color: theme.palette.secondary.light },
              }}
          >
              {"Emergency List"}
        </Button>
          )}
          {/* ----------------- */}
          {
          showSilentAlarm && (
          <Button
                variant="contained"
                onClick={onSilentAlarmClicked}
                startIcon={<Send />}
                sx={{
                backgroundColor: theme.palette.background.alt,
                flex: isNonMobile ? "1" : "unset",
                color: theme.palette.secondary.light,
                minWidth:  "250px",
                fontSize: "14px",
                fontWeight: "bold",
                margin: isNonMobile ? "0px" : "10px 0px 0px 0px",
                padding: "5px 0px",
                "&:hover": { color: theme.palette.secondary.light },
                }}
            >
                {"Silent Alarm"}
          </Button>)
        }

          {/* ----------------- */}
          {showVisitorNotification &&(
          <Button
              variant="contained"
              // onClick={toggleReviewers}
              startIcon={<Campaign />}
              sx={{
              backgroundColor: theme.palette.background.alt,
              flex: isNonMobile ? "1" : "unset",
              color: theme.palette.secondary.light,
              minWidth:  "250px",
              fontSize: "14px",
              fontWeight: "bold",
              margin: isNonMobile ? "0px" : "10px 0px 0px 0px",
              padding: "5px 0px",
               "&:hover": { color: theme.palette.secondary.light },
              }}
          >
              {"Visitor Notification"}
          </Button>
          )}
          </Box>

          <Tooltip
              title={
                <Typography sx={{ fontSize: '20px' }}>
                  Help
                </Typography>
              }
            >
            <IconButton onClick={openHelpPage}>
                <HelpOutline sx={{ fontSize: "25px" }} />
            </IconButton>
          </Tooltip>
          {/* <Tooltip
              title={
                <Typography sx={{ fontSize: '20px' }}>
                  Dark Mode
                </Typography>
              }
            >
            <IconButton onClick={() => dispatch(setMode())}>
              {theme.palette.mode === "dark" ? (
                <LightMode sx={{ fontSize: "25px" }} />
              ) : (
                <DarkMode sx={{ fontSize: "25px" }} />
              )}
            </IconButton>
          </Tooltip> */}


          <FlexBetween>
            <Button
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              <Box
                component="img"
                alt="profile"
                src={profileImage}
                height="32px"
                width="32px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {userProfile && userProfile.fullName}
                </Typography>
                {/* <Typography
                  fontSize="0.75rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user.occupation}
                </Typography> */}
              </Box>
              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem onClick={() => navigate("/profile")} >
                <ListItemIcon>
                  <AccountCircle /> {/* Add the icon component here */}
                </ListItemIcon>
                Profile {/* Add your menu item text here */}
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon /> {/* Add the icon component here */}
                </ListItemIcon>
                Log Out {/* Add your menu item text here */}
              </MenuItem>

            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;